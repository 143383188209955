import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  FetchAccountMembersQuery,
  FetchAccountMembersQueryVariables,
  MemberFilter,
  MemberSortFields,
  SortDirection,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { ExecuteOperationOptions, executeOperationSafely } from '../../common';
import { AppDispatch } from '../../../redux/store';
import { Paging } from '../../../components/table/gqlTableHelper';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../common/pagination';

const FETCH_ACCOUNT_MEMBERS = gql`
  query FetchAccountMembers($paging: CursorPaging, $sorting: [MemberSort!], $filter: MemberFilter) {
    members(paging: $paging, sorting: $sorting, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          memberStatus
          email
          user {
            name
            lastSeenAt
            profileImageUrl
          }
          userId
          accessRoles {
            accountRole
            productionRole
            nonProductionRole
          }
        }
        cursor
      }
    }
  }
`;

export type FetchMembersProps = {
  paging?: Paging;
  search?: string | null;
} & ExecuteOperationOptions;

async function fetchAccountMembers(props: FetchMembersProps, { dispatch }: { dispatch: AppDispatch }) {
  let { search, paging } = props;
  if (search === '') {
    search = null;
  }

  if (!paging) {
    paging = { first: DEFAULT_TABLE_PAGE_SIZE };
  }

  const filter: MemberFilter = search
    ? {
        or: [{ email: { iLike: `%${props.search}%` } }, { user: { name: { iLike: `%${props.search}%` } } }],
      }
    : {};

  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query<FetchAccountMembersQuery, FetchAccountMembersQueryVariables>({
        query: FETCH_ACCOUNT_MEMBERS,
        fetchPolicy: 'network-only',
        variables: {
          paging,
          sorting: { field: MemberSortFields.Id, direction: SortDirection.Asc },
          filter,
        },
      });

      return response.data.members;
    },
    {
      failureMessageHandler: () => t('accounts.api.failFetchMem'),
    },
    dispatch,
  );
}

export { fetchAccountMembers };
