import {
  CouponFragment,
  CouponType,
  SubscriptionCouponDataFragment,
  SubscriptionCouponDiscountInput,
  SubscriptionCouponInput,
} from '@stigg-types/apiTypes';
import { FormRenderProps } from '@stigg-components';
import { CustomCoupon, SubscriptionFormFields } from '../../SubscriptionForm.types';
import { CouponDuration } from '../../../../../../../coupons/components/createCoupon/CreateCouponForm';
import { AdditionalInputActionParams } from '../AdditionalInputLayout';
import { CouponStartOption } from './FutureStartInput';

export const getCouponForPreview = (
  customerCoupon: CouponFragment | null | undefined,
  subscriptionCoupon: CouponFragment | SubscriptionCouponDataFragment | undefined,
  customCoupon: CustomCoupon | undefined,
) => {
  if (customCoupon && customCoupon.type && customCoupon.discountValue) {
    return {
      id: '',
      name: 'Discount', // extract to const,
      type: customCoupon.type,
      discountValue: customCoupon.discountValue,
      durationInMonths:
        customCoupon.duration === CouponDuration.MultipleMonths ? customCoupon.durationInMonths : undefined,
    } as CouponFragment;
  }

  return customerCoupon || subscriptionCoupon;
};

export const resetCouponFields = ({ setFieldValue, setFieldTouched }: AdditionalInputActionParams) => {
  setFieldValue('coupon', undefined, false);
  setFieldValue('couponRefId', undefined, false);
  setFieldValue(
    'couponConfiguration',
    {
      startConfiguration: CouponStartOption.CurrentBillingCycle,
      startDate: undefined,
    },
    false,
  );
  setFieldValue('customCoupon', undefined, true);
  setTimeout(() => setFieldTouched('couponRefId', false, false));
  setTimeout(() => setFieldTouched('customCoupon', false, false));
};

export const copyCustomCouponToState = ({ values, setFieldValue }: FormRenderProps<SubscriptionFormFields>) => {
  const { type, discountValue, durationInMonths, amountsOff, percentOff } = values.coupon || {};

  setFieldValue(
    'customCoupon',
    {
      type: type || CouponType.Fixed,
      discountValue,
      amountsOff,
      percentOff,
      durationInMonths: durationInMonths || undefined,
      duration: durationInMonths ? CouponDuration.MultipleMonths : CouponDuration.Forever,
    },
    true,
  );
};

export const buildCustomCouponInput = (
  customCoupon: CustomCoupon | undefined,
): SubscriptionCouponDiscountInput | undefined => {
  if (!customCoupon) {
    return undefined;
  }

  const { amountsOff, percentOff, duration, durationInMonths } = customCoupon;

  return {
    amountsOff,
    percentOff,
    durationInMonths: duration === CouponDuration.MultipleMonths ? durationInMonths : undefined,
  };
};

/*
 * Builds a coupon input for the subscription form
 * If a custom coupon is provided, it will be used instead of the couponRefId
 * If a couponRefId is provided, it will be used instead of the existing coupon
 * If no couponRefId is provided, we should remove the coupon by providing an empty couponId
 */
export const buildAppliedCouponForUpdate = (
  values: SubscriptionFormFields,
  existingCoupon: SubscriptionCouponDataFragment | undefined | null,
): SubscriptionCouponInput => {
  const { customCoupon, couponRefId, couponConfiguration } = values;

  const customCouponInput = buildCustomCouponInput(customCoupon);
  return {
    couponId: !customCouponInput ? couponRefId || (existingCoupon?.refId ? '' : undefined) : undefined,
    discount: customCouponInput,
    configuration: {
      startDate: couponConfiguration?.startDate,
    },
  };
};
