import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { EnvironmentPermissionActions } from '@stigg-permissions';
import { GridFlex, Icon, PageCard, Tabs, TabProps, Text, Loader, Button, PermissionProtected } from '@stigg-components';
import { useAppDispatch } from '../../../../../redux/store';
import { fetchCustomerSubscriptionsAction, fetchNonActiveSubscriptionsCountAction } from '../../../customersSlice';
import { CustomerSubscriptionProps, CustomerSubscriptions } from './CustomerSubscriptions';
import { CustomerPageSubscriptionsTabs } from '../../../../navigation/tabs';
import { useSubscriptions, useSubscriptionActions, useSubscriptionTabSelector } from './customerSubscriptionsHooks';

type CustomerSubscriptionsCardProps = Pick<CustomerSubscriptionProps, 'customer'>;

function useIsComponentLoaded(customerId: string) {
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const payingCustomerId = customerId;
      await Promise.all([
        dispatch(fetchCustomerSubscriptionsAction({ customerId })),
        dispatch(fetchCustomerSubscriptionsAction({ payingCustomerId })),
        dispatch(fetchNonActiveSubscriptionsCountAction({ customerId })),
        dispatch(fetchNonActiveSubscriptionsCountAction({ payingCustomerId })),
      ]);

      setIsLoaded(true);
    };

    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  return { isLoaded };
}

export function CustomerSubscriptionsCard({ customer }: CustomerSubscriptionsCardProps) {
  const { isLoaded } = useIsComponentLoaded(customer.id);
  const { hasSubscriptions, hasDelegatedSubscriptions } = useSubscriptions(isLoaded);
  const hasAnySubscriptions = hasSubscriptions || hasDelegatedSubscriptions;

  const { currentTab } = useSubscriptionTabSelector({ isLoaded, hasSubscriptions, hasDelegatedSubscriptions });

  const { createSubscriptionDialog, onCreateSubscriptionClick, onUpdateSubscriptionClick } = useSubscriptionActions({
    customer,
  });

  const subscriptionActionsProps = {
    onCreateSubscriptionClick,
    onUpdateSubscriptionClick,
  };

  const customersSubscriptions = (
    <CustomerSubscriptions customer={customer} {...subscriptionActionsProps} hasSubscriptions={hasSubscriptions} />
  );
  const customersDelegatedSubscriptions = (
    <CustomerSubscriptions
      customer={customer}
      {...subscriptionActionsProps}
      isDelegatedSubscriptions
      hasSubscriptions={hasDelegatedSubscriptions}
    />
  );

  const tabs: TabProps[] = [
    {
      title: t('customers.subscriptionTabs.subscriptionsTitle'),
      url: CustomerPageSubscriptionsTabs.Subscriptions,
      content: customersSubscriptions,
    },
    {
      title: t('customers.subscriptionTabs.delegatedSubscriptionsTitle'),
      url: CustomerPageSubscriptionsTabs.DelegatedSubscriptions,
      content: customersDelegatedSubscriptions,
    },
  ];

  const content = hasDelegatedSubscriptions ? <Tabs data={tabs} /> : customersSubscriptions;

  return (
    <>
      <PageCard>
        <GridFlex.Column>
          <GridFlex.RowSpaceBetween>
            <GridFlex.Column>
              <Text.H3 mb={2}>{t('customers.subscriptionsSectionTitle')}</Text.H3>
              <Text.Sub2 mb={2}>{t('customers.subscriptionsSectionSubtitle')}</Text.Sub2>
            </GridFlex.Column>
            <PermissionProtected permission={EnvironmentPermissionActions.SubscriptionWrite}>
              {!hasAnySubscriptions ||
              (currentTab === CustomerPageSubscriptionsTabs.Subscriptions && !hasSubscriptions) ? (
                <GridFlex.Column>
                  <Button
                    color="primary"
                    $outlined
                    onClick={onCreateSubscriptionClick}
                    data-testid="button-add-subscription">
                    <Icon icon="Add" />
                    {t('customers.addSubscription')}
                  </Button>
                </GridFlex.Column>
              ) : null}
            </PermissionProtected>
          </GridFlex.RowSpaceBetween>
          {isLoaded ? content : <Loader size={18} />}
        </GridFlex.Column>
      </PageCard>
      {createSubscriptionDialog}
    </>
  );
}
