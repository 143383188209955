import { gql } from '@apollo/client';
import { RESET_PERIOD_CONFIGURATION } from '../../../entitlements/queries/resetPeriodConfigurationFragment';
import { PACKAGE_CHANGES_FRAGMENT } from '../../plans/queries/packageChangesFragment';
import { PRICE_FRAGMENT } from '../../plans/queries/priceFragment';
import { OVERAGE_PRICE_FRAGMENT } from '../../plans/queries/overagePriceFragment';
import { MINIMUM_SPEND_FRAGMENT } from '../../plans/queries/minimumSpendFragment';

export const ADDON_FRAGMENT = gql`
  fragment AddonFieldsFragment on Addon {
    id
    refId
    displayName
    description
    billingId
    billingLinkUrl
    createdAt
    updatedAt
    status
    type
    versionNumber
    isLatest
    hiddenFromWidgets
    additionalMetaData
    maxQuantity
    hasSubscriptions
    syncStates {
      vendorIdentifier
      status
      error
    }
    draftSummary {
      version
      updatedAt
      updatedBy
    }
    draftDetails {
      customersAffected
      changes {
        ...PackageChangesFragment
      }
    }
    entitlements {
      packageId
      id
      featureId
      environmentId
      usageLimit
      hasUnlimitedUsage
      hasSoftLimit
      hiddenFromWidgets
      displayNameOverride
      resetPeriod
      behavior
      resetPeriodConfiguration {
        __typename
        ... on YearlyResetPeriodConfig {
          ...YearlyResetPeriodConfigFragment
        }
        ... on MonthlyResetPeriodConfig {
          ...MonthlyResetPeriodConfigFragment
        }
        ... on WeeklyResetPeriodConfig {
          ...WeeklyResetPeriodConfigFragment
        }
      }
      feature {
        id
        displayName
        refId
        description
        featureStatus
        updatedAt
        environmentId
        featureType
        meterType
        featureUnits
        featureUnitsPlural
        hasMeter
      }
    }
    product {
      id
      displayName
      refId
      awsMarketplaceProductId
      awsMarketplaceProductCode
      productSettings {
        subscriptionEndSetup
        subscriptionCancellationTime
      }
    }
    prices {
      ...PriceFragment
    }
    overagePrices {
      ...OveragePriceFragment
    }
    overageBillingPeriod
    pricingType
  }

  fragment AddonFragment on Addon {
    ...AddonFieldsFragment

    dependencies {
      ...AddonFieldsFragment
    }
  }
  ${PRICE_FRAGMENT}
  ${OVERAGE_PRICE_FRAGMENT}
  ${RESET_PERIOD_CONFIGURATION}
  ${MINIMUM_SPEND_FRAGMENT}
  ${PACKAGE_CHANGES_FRAGMENT}
`;
