import { t } from 'i18next';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import * as Yup from 'yup';
import { Environment } from '@stigg-types/apiTypes';
import { Text, Grid } from '@stigg-components';
import { Form } from '../../../../components/form';

type DeleteEnvironmentForm = {
  deletedEnvName: string;
};

export function DeleteEnvironment({
  onSubmit,
  environment,
  onCancel,
}: {
  onSubmit: () => void;
  environment?: Environment;
  onCancel: () => void;
}) {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        deletedEnvName: Yup.string()
          .oneOf([environment?.displayName], t('accounts.deleteEnvError'))
          .required(t('accounts.deleteEnvError')),
      }),
    [environment?.displayName],
  );

  const initialValues: DeleteEnvironmentForm = {
    deletedEnvName: '',
  };

  if (!environment) {
    return null;
  }
  return (
    <Grid container flexDirection="column">
      <Grid item>
        <Text.B2 whiteSpace="pre-line">
          <Trans i18nKey="accounts.deleteEnvironmentDialogContent" values={{ envName: environment.displayName }} />
        </Text.B2>
      </Grid>

      <Grid item mt={4} mb={2}>
        <Text.B2 color="primary">{t('accounts.confirmDeleteEnv')}</Text.B2>
      </Grid>
      <Form
        onSubmit={onSubmit}
        submitButtonText={t('sharedComponents.delete')}
        saveButtonColor="error"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onCancel={onCancel}
        fields={[
          {
            type: 'text',
            id: 'deletedEnvName',

            placeholder: environment.displayName,
          },
        ]}
      />
    </Grid>
  );
}
