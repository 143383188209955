import { BooleanEntitlementFallback, useBooleanEntitlement as useStiggBooleanEntitlement } from '@stigg/react-sdk';
import { StiggFeature } from '../StiggFeature';

const BOOLEAN_ENTITLEMENT_FALLBACK: BooleanEntitlementFallback = {
  hasAccess: true,
};

export function useBooleanEntitlement(feature: StiggFeature) {
  return useStiggBooleanEntitlement<StiggFeature>({
    featureId: feature,
    options: { fallback: BOOLEAN_ENTITLEMENT_FALLBACK },
  });
}
