export enum PermissionResource {
  Account = 'ACCOUNT',
  Environment = 'ENVIRONMENT',
}

export enum AccountPermissionActions {
  BillingAccess = 'billing-permission',
  InviteUser = 'invite-user',
  CreateProductionEnvironment = 'create-production-environment',
  CreateNonProductionEnvironment = 'create-environment',
  DeleteEnvironment = 'delete-environment',
}

export enum EnvironmentPermissionActions {
  ReadEnvironment = 'read',
  WriteEnvironment = 'write',
  CouponWrite = 'coupon-write',
  CustomerWrite = 'customer-write',
  SubscriptionWrite = 'subscription-write',
  ReadApiKeys = 'read-api-keys',
}

export type PermissionAction = AccountPermissionActions | EnvironmentPermissionActions;

export type EnvironmentPermissions = Record<keyof typeof EnvironmentPermissionActions, boolean>;
