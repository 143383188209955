import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { GridFlex, Text, Link, IconButton, CollapsableSectionIcon, Collapse, Box } from '@stigg-components';
import { GridProps } from '@stigg-components/types';
import { PreviewNextInvoiceFragment, Money } from '@stigg-types/apiTypes';
import { currencyPriceFormatter } from '../../../../../../packages/pricing/components/currency/currencyUtils';

type NestedChargeBreakdownProps = {
  breakdown: PreviewNextInvoiceFragment['lines'][0]['lines'];
  spacing?: number;
};

type ChargeRowProps = {
  description: string;
  descriptionCaption?: string | null;
  costDescription?: string;
  charge?: Money;
  chargeItem?: PreviewNextInvoiceFragment['lines'][0];
  breakdown?: PreviewNextInvoiceFragment['lines'][0]['lines'];
  bold?: boolean;
  sx?: GridProps['sx'];
  dataTestId?: string;
};

function NestedChargeBreakdown({ breakdown, spacing = 2 }: NestedChargeBreakdownProps) {
  if (!breakdown || isEmpty(breakdown)) {
    return null;
  }

  return (
    <GridFlex.Column gap={spacing}>
      {breakdown.map(({ description, costDescription }, index) => (
        <GridFlex.RowSpaceBetween key={index} gap={spacing}>
          <Text.B2 color="tertiary">{description}</Text.B2>
          <Text.B2 color="tertiary">{costDescription}</Text.B2>
        </GridFlex.RowSpaceBetween>
      ))}
    </GridFlex.Column>
  );
}

export function ChargeRow({
  description,
  descriptionCaption,
  costDescription,
  charge,
  chargeItem,
  breakdown,
  bold,
  sx,
  dataTestId,
}: ChargeRowProps) {
  const [isNestedBreakdownOpen, setIsNestedBreakdownOpen] = useState(false);
  const toggleNestedBreakdown = () => setIsNestedBreakdownOpen((prev) => !prev);

  let chargeText = costDescription;
  if (charge) {
    chargeText = currencyPriceFormatter({
      ...charge,
      options: { withCodePostfix: true },
    });

    if (chargeItem) {
      chargeText = `${chargeItem.quantity} x ${currencyPriceFormatter({ ...chargeItem.unitPrice! })} = ${chargeText}`;
    }
  }

  let title = (
    <Text.B2 color="secondary" $bold={bold}>
      {description}
    </Text.B2>
  );

  const subTitle = descriptionCaption ? (
    <Text.Caption color="secondary" $bold={bold}>
      {descriptionCaption}
    </Text.Caption>
  ) : null;

  let nestedBreakdown: React.ReactNode;
  if (breakdown) {
    title = (
      <Link onClick={toggleNestedBreakdown} underline="none">
        {title}
      </Link>
    );
    nestedBreakdown = <NestedChargeBreakdown breakdown={breakdown} spacing={2} />;
  }

  return (
    <GridFlex.Column container sx={sx}>
      <GridFlex.RowSpaceBetween item>
        <GridFlex.Column>
          <GridFlex.RowCenter gap={1}>
            {title}
            {nestedBreakdown ? (
              <IconButton onClick={toggleNestedBreakdown} sx={{ padding: 0 }}>
                <CollapsableSectionIcon $isOpen={isNestedBreakdownOpen} $size={16} />
              </IconButton>
            ) : null}
          </GridFlex.RowCenter>

          {subTitle}
        </GridFlex.Column>

        <Text.B2 sx={{ textAlign: 'end', alignSelf: 'baseline' }} $bold={bold} data-testid={dataTestId}>
          {chargeText}
        </Text.B2>
      </GridFlex.RowSpaceBetween>
      {nestedBreakdown && (
        <Collapse in={isNestedBreakdownOpen}>
          <Box ml={4} pt={4}>
            {nestedBreakdown}
          </Box>
        </Collapse>
      )}
    </GridFlex.Column>
  );
}
