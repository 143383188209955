import React from 'react';
import { GetBooleanEntitlement } from '@stigg/js-client-sdk';
import { useBooleanEntitlement } from '../hooks';

type BooleanEntitlementGuardProps = GetBooleanEntitlement & {
  noAccessComponent?: React.ReactNode;
  children: React.ReactElement;
};

export function BooleanEntitlementGuard({
  noAccessComponent,
  children,
  ...rest
}: BooleanEntitlementGuardProps): JSX.Element {
  const { hasAccess } = useBooleanEntitlement(rest);

  if (!hasAccess) {
    return <>{noAccessComponent ?? null}</>;
  }

  return children;
}
