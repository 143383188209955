import { Auth0CredentialsFragment, VendorIdentifier, IntegrationListItemFragment } from '@stigg-types/apiTypes';
import { isAuth0IntegrationCompleted } from './auth0/isAuth0IntegrationCompleted';

export function isIntegrationActive(integration?: IntegrationListItemFragment | null) {
  if (integration?.vendorIdentifier === VendorIdentifier.Auth0) {
    return isAuth0IntegrationCompleted(integration?.credentials as Auth0CredentialsFragment | undefined)
      .isSetupCompleted;
  }

  return !!integration;
}
