import { gql } from '@apollo/client';
import { t } from 'i18next';
import { executeOperationSafely } from '@stigg-common';
import { UsageHistoryQuery, UsageHistoryQueryVariables } from '@stigg-types/apiTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { RootState } from '../../../redux/store';
import { apolloClient } from '../../../ApolloClient';

const FETCH_USAGE_HISTORY = gql`
  query UsageHistory($input: UsageHistoryV2Input!) {
    usageHistoryV2(input: $input) {
      ...UsageHistoryFragment
    }
  }
  fragment UsageHistoryFragment on UsageHistoryV2 {
    series {
      tags {
        key
        value
      }
      points {
        ...UsageHistoryPoint
      }
    }
    markers {
      ...UsageHistoryMarker
    }
  }

  fragment UsageHistoryPoint on UsageHistoryPoint {
    timestamp
    value
  }

  fragment UsageHistoryMarker on UsageMarker {
    type
    timestamp
  }
`;

export const useGetUsageHistory = ({
  featureId,
  customerId,
  resourceId,
  startDate,
  groupBy,
  lastUsageReported,
}: {
  featureId: string;
  customerId: string;
  resourceId?: string;
  startDate: Date;
  groupBy?: string[];
  lastUsageReported?: Date | null;
}) => {
  const dispatch = useDispatch();
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;

  return useQuery({
    queryKey: ['usageHistory', customerId, resourceId, featureId, startDate, groupBy, lastUsageReported],
    queryFn: () =>
      executeOperationSafely(
        () =>
          apolloClient.query<UsageHistoryQuery, UsageHistoryQueryVariables>({
            query: FETCH_USAGE_HISTORY,
            fetchPolicy: 'network-only',
            variables: {
              input: {
                customerId,
                featureId,
                resourceId,
                startDate,
                groupBy,
                environmentId,
              },
            },
          }),
        { failureMessageHandler: () => t('customers.api.failUsageMeasurementsFetch') },
        dispatch,
      ),
    select: (res) => res.data.usageHistoryV2,
    retry: 0, // we automatically retry in apollo client
  });
};
