export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ConnectionCursor: { input: any; output: any };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
};

/** DenyReason of get access policy */
export enum AccessDeniedReason {
  BudgetExceeded = 'BudgetExceeded',
  CustomerIsArchived = 'CustomerIsArchived',
  CustomerNotFound = 'CustomerNotFound',
  CustomerResourceNotFound = 'CustomerResourceNotFound',
  FeatureNotFound = 'FeatureNotFound',
  NoActiveSubscription = 'NoActiveSubscription',
  NoFeatureEntitlementInSubscription = 'NoFeatureEntitlementInSubscription',
  RequestedUsageExceedingLimit = 'RequestedUsageExceedingLimit',
  Unknown = 'Unknown',
}

/** access roles */
export type AccessRoles = {
  __typename?: 'AccessRoles';
  /** Account level access of the user */
  accountRole: AccountAccessRole;
  /** Non-production environment level access of the user */
  nonProductionRole: EnvironmentAccessRole;
  /** Production environment level access of the user */
  productionRole: EnvironmentAccessRole;
};

export type Account = {
  __typename?: 'Account';
  accountEmailDomain?: Maybe<Scalars['String']['output']>;
  accountStatus?: Maybe<AccountStatus>;
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  samlEnabled?: Maybe<Scalars['Boolean']['output']>;
  subscriptionBillingAnchor?: Maybe<BillingAnchor>;
  subscriptionProrationBehavior?: Maybe<ProrationBehavior>;
  timezone?: Maybe<Scalars['String']['output']>;
};

/** Account access role of the user */
export enum AccountAccessRole {
  Member = 'MEMBER',
  Owner = 'OWNER',
}

export type AccountNotFoundError = {
  __typename?: 'AccountNotFoundError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

export type AddCompatibleAddonsToPlanInput = {
  /** The id of the record. */
  id: Scalars['String']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['String']['input']>;
};

export type AdditionalMetaDataChange = {
  __typename?: 'AdditionalMetaDataChange';
  after?: Maybe<Scalars['JSON']['output']>;
  before?: Maybe<Scalars['JSON']['output']>;
  changeType?: Maybe<ChangeType>;
};

export type Addon = {
  __typename?: 'Addon';
  additionalMetaData?: Maybe<Scalars['JSON']['output']>;
  billingId?: Maybe<Scalars['String']['output']>;
  billingLinkUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** List of addons this addon is dependant on */
  dependencies?: Maybe<Array<Addon>>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  draftDetails?: Maybe<PackageDraftDetails>;
  draftSummary?: Maybe<PackageDraftSummary>;
  entitlements?: Maybe<Array<PackageEntitlement>>;
  environment: Environment;
  environmentId: Scalars['String']['output'];
  /** Indicates if the addon has subscriptions */
  hasSubscriptions: Scalars['Boolean']['output'];
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  id: Scalars['String']['output'];
  isLatest?: Maybe<Scalars['Boolean']['output']>;
  /** The maximum quantity of this addon that can be added to a subscription */
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  overageBillingPeriod?: Maybe<OverageBillingPeriod>;
  overagePrices?: Maybe<Array<Price>>;
  prices?: Maybe<Array<Price>>;
  pricingType?: Maybe<PricingType>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']['output']>;
  refId: Scalars['String']['output'];
  status: PackageStatus;
  syncStates?: Maybe<Array<SyncState>>;
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionNumber: Scalars['Int']['output'];
};

export type AddonPricesArgs = {
  filter?: InputMaybe<PriceFilter>;
  sorting?: InputMaybe<Array<PriceSort>>;
};

export type AddonAggregateGroupBy = {
  __typename?: 'AddonAggregateGroupBy';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isLatest?: Maybe<Scalars['Boolean']['output']>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

/** Archive addon input */
export type AddonArchiveInput = {
  /** Environment id */
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** Addon id */
  refId: Scalars['String']['input'];
};

/** List of active entities associated with an addon */
export type AddonAssociatedEntities = {
  __typename?: 'AddonAssociatedEntities';
  /** List of package groups that have this addon */
  packageGroups: Array<AddonAssociatedPackageGroup>;
  /** List of published or draft plans */
  plans: Array<AddonAssociatedPlan>;
};

/** Query associated entities input */
export type AddonAssociatedEntitiesInput = {
  /** Environment id */
  environmentId: Scalars['String']['input'];
  /** Addon id */
  refId: Scalars['String']['input'];
};

/** List of package groups associated with an addon */
export type AddonAssociatedPackageGroup = {
  __typename?: 'AddonAssociatedPackageGroup';
  /** Package group display name */
  displayName: Scalars['String']['output'];
  /** Package group id */
  packageGroupId: Scalars['String']['output'];
};

/** List of plans associated with an addon */
export type AddonAssociatedPlan = {
  __typename?: 'AddonAssociatedPlan';
  /** Plan display name */
  displayName: Scalars['String']['output'];
  /** Plan refId */
  refId: Scalars['String']['output'];
  /** Plan status */
  status: PackageStatus;
  /** Plan version */
  versionNumber: Scalars['Float']['output'];
};

export type AddonAvgAggregate = {
  __typename?: 'AddonAvgAggregate';
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type AddonChangeVariables = {
  __typename?: 'AddonChangeVariables';
  addonRefId: Scalars['String']['output'];
  newQuantity: Scalars['Float']['output'];
};

export type AddonConnection = {
  __typename?: 'AddonConnection';
  /** Array of edges. */
  edges: Array<AddonEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type AddonCountAggregate = {
  __typename?: 'AddonCountAggregate';
  billingId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['Int']['output']>;
  displayName?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isLatest?: Maybe<Scalars['Int']['output']>;
  pricingType?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  refId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type AddonCreateInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  awsMarketplacePlanDimension?: InputMaybe<Scalars['String']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  /** List of addon ids this addon should be dependant on */
  dependencies?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
  hiddenFromWidgets?: InputMaybe<Array<WidgetType>>;
  /** The maximum quantity of this addon that can be added to a subscription */
  maxQuantity?: InputMaybe<Scalars['Float']['input']>;
  pricingType?: InputMaybe<PricingType>;
  productId: Scalars['String']['input'];
  refId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PackageStatus>;
};

/** Addon dependency changed */
export type AddonDependencyChange = {
  __typename?: 'AddonDependencyChange';
  /** Addon after change */
  after?: Maybe<Addon>;
  /** Addon before change */
  before?: Maybe<Addon>;
  /** Type of change */
  changeType?: Maybe<ChangeType>;
};

export type AddonEdge = {
  __typename?: 'AddonEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Addon */
  node: Addon;
};

export type AddonFilter = {
  and?: InputMaybe<Array<AddonFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<AddonFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type AddonMaxAggregate = {
  __typename?: 'AddonMaxAggregate';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type AddonMinAggregate = {
  __typename?: 'AddonMinAggregate';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type AddonPriceOverrideChangeVariables = {
  __typename?: 'AddonPriceOverrideChangeVariables';
  addonRefId: Scalars['String']['output'];
  featureId?: Maybe<Scalars['String']['output']>;
};

export type AddonSort = {
  direction: SortDirection;
  field: AddonSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AddonSortFields {
  BillingId = 'billingId',
  CreatedAt = 'createdAt',
  Description = 'description',
  DisplayName = 'displayName',
  EnvironmentId = 'environmentId',
  Id = 'id',
  IsLatest = 'isLatest',
  PricingType = 'pricingType',
  ProductId = 'productId',
  RefId = 'refId',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  VersionNumber = 'versionNumber',
}

export type AddonSumAggregate = {
  __typename?: 'AddonSumAggregate';
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type AddonUpdateInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  /** List of addon ids this addon should be dependant on */
  dependencies?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  hiddenFromWidgets?: InputMaybe<Array<WidgetType>>;
  id: Scalars['String']['input'];
  /** The maximum quantity of this addon that can be added to a subscription */
  maxQuantity?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<PackageStatus>;
};

export type Address = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type AggregatedEventsByCustomer = {
  __typename?: 'AggregatedEventsByCustomer';
  aggregatedUsage: Array<CustomerAggregatedUsage>;
};

export type AggregatedEventsByCustomerInput = {
  aggregation: MeterAggregation;
  customerId?: InputMaybe<Scalars['String']['input']>;
  environmentId: Scalars['String']['input'];
  filters: Array<MeterFilterDefinitionInput>;
};

export type Aggregation = {
  __typename?: 'Aggregation';
  field?: Maybe<Scalars['String']['output']>;
  function: AggregationFunction;
};

/** Aggregation function */
export enum AggregationFunction {
  Avg = 'AVG',
  Count = 'COUNT',
  Max = 'MAX',
  Min = 'MIN',
  Sum = 'SUM',
  Unique = 'UNIQUE',
}

/** Alignment */
export enum Alignment {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export type ApiKey = {
  __typename?: 'ApiKey';
  id: Scalars['String']['output'];
  keyType: ApiKeyType;
  token: Scalars['String']['output'];
};

export type ApiKeyFilter = {
  and?: InputMaybe<Array<ApiKeyFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ApiKeyFilter>>;
};

export type ApiKeySort = {
  direction: SortDirection;
  field: ApiKeySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ApiKeySortFields {
  Id = 'id',
}

export enum ApiKeyType {
  Client = 'CLIENT',
  Salesforce = 'SALESFORCE',
  Server = 'SERVER',
}

export type ApplySubscription = {
  __typename?: 'ApplySubscription';
  entitlements?: Maybe<Array<Entitlement>>;
  subscription?: Maybe<CustomerSubscription>;
};

export type ApplySubscriptionInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  /** Coupon input */
  appliedCoupon?: InputMaybe<SubscriptionCouponInput>;
  awaitPaymentConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  billableFeatures?: InputMaybe<Array<BillableFeatureInput>>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  billingInformation?: InputMaybe<SubscriptionBillingInfo>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  /** Budget configuration */
  budget?: InputMaybe<BudgetConfigurationInput>;
  customerId: Scalars['String']['input'];
  /** The minimum spend configuration */
  minimumSpend?: InputMaybe<SubscriptionMinimumSpendValueInput>;
  payingCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** Subscription payment collection method */
  paymentCollectionMethod?: InputMaybe<PaymentCollectionMethod>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['String']['input'];
  /** Override the price of the subscription */
  priceOverrides?: InputMaybe<Array<PriceOverrideInput>>;
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  scheduleStrategy?: InputMaybe<ScheduleStrategy>;
  skipTrial?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subscriptionEntitlements?: InputMaybe<Array<SubscriptionEntitlementInput>>;
  trialOverrideConfiguration?: InputMaybe<TrialOverrideConfigurationInput>;
  unitQuantity?: InputMaybe<Scalars['Float']['input']>;
};

export type ArchiveCouponInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
  refId: Scalars['String']['input'];
};

export type ArchiveCustomerInput = {
  customerId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
};

export type ArchiveEnvironmentInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type ArchiveFeatureInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

/** The input type for archiving package group */
export type ArchivePackageGroup = {
  /** The environment id to get the package group in */
  environmentId: Scalars['String']['input'];
  /** The id of the package group */
  packageGroupId: Scalars['String']['input'];
};

export type ArchivePlanInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type AsyncTaskResult = {
  __typename?: 'AsyncTaskResult';
  taskId: Scalars['String']['output'];
};

export type AttachCustomerPaymentMethodInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId: Scalars['String']['input'];
  refId?: InputMaybe<Scalars['String']['input']>;
  vendorIdentifier: VendorIdentifier;
};

export type Auth0ApplicationDto = {
  __typename?: 'Auth0ApplicationDTO';
  appId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Auth0ApplicationType>;
};

/** Auth0 application type. */
export enum Auth0ApplicationType {
  Both = 'BOTH',
  Individual = 'INDIVIDUAL',
  Organization = 'ORGANIZATION',
}

export type Auth0Credentials = {
  __typename?: 'Auth0Credentials';
  applicationId: Scalars['String']['output'];
  applicationName: Scalars['String']['output'];
  applicationType: Auth0ApplicationType;
  clientDomain: Scalars['String']['output'];
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  individualInitialPlanId?: Maybe<Scalars['String']['output']>;
  individualSubscriptionStartSetup?: Maybe<SubscriptionStartSetup>;
  organizationInitialPlanId?: Maybe<Scalars['String']['output']>;
  organizationSubscriptionStartSetup?: Maybe<SubscriptionStartSetup>;
};

export type Auth0CredentialsInput = {
  applicationId: Scalars['String']['input'];
  applicationName: Scalars['String']['input'];
  applicationType: Auth0ApplicationType;
  clientDomain: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  individualInitialPlanId?: InputMaybe<Scalars['String']['input']>;
  individualSubscriptionStartSetup?: InputMaybe<SubscriptionStartSetup>;
  organizationInitialPlanId?: InputMaybe<Scalars['String']['input']>;
  organizationSubscriptionStartSetup?: InputMaybe<SubscriptionStartSetup>;
};

/** Auto cancellation rule - when subscription for source plan is canceled, other subscriptions to target plan would be cancelled as well */
export type AutoCancellationRule = {
  __typename?: 'AutoCancellationRule';
  /** The source plan. */
  sourcePlan: Plan;
  /** The target plan. */
  targetPlan: Plan;
};

/** The input of the auto cancellation rule. */
export type AutoCancellationRuleInput = {
  /** The id of the source plan. */
  sourcePlanId: Scalars['String']['input'];
  /** The id of the target plan. */
  targetPlanId: Scalars['String']['input'];
};

export type AwsDimension = {
  __typename?: 'AwsDimension';
  description: Scalars['String']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stiggPlanId?: Maybe<Scalars['String']['output']>;
  stiggPlanName?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  unit: Scalars['String']['output'];
};

export type AwsMarketplaceCredentials = {
  __typename?: 'AwsMarketplaceCredentials';
  awsRoleArn: Scalars['String']['output'];
};

export type AwsMarketplaceCredentialsInput = {
  awsRoleArn: Scalars['String']['input'];
};

export type AwsProduct = {
  __typename?: 'AwsProduct';
  description: Scalars['String']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  productCode: Scalars['String']['output'];
  productId: Scalars['String']['output'];
  stiggProductId?: Maybe<Scalars['String']['output']>;
  stiggProductRefId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  visibility: Scalars['String']['output'];
};

export type BaseError = {
  __typename?: 'BaseError';
  code: Scalars['String']['output'];
};

export type BasePlanChange = {
  __typename?: 'BasePlanChange';
  after?: Maybe<Addon>;
  before?: Maybe<Addon>;
  changeType?: Maybe<ChangeType>;
};

export type BigQueryCredentials = {
  __typename?: 'BigQueryCredentials';
  airbyteConnectionId?: Maybe<Scalars['String']['output']>;
  airbyteDestinationId?: Maybe<Scalars['String']['output']>;
  credentialsJson: Scalars['String']['output'];
  datasetId: Scalars['String']['output'];
  datasetLocation: Scalars['String']['output'];
  gcsBucketName: Scalars['String']['output'];
  gcsBucketPath: Scalars['String']['output'];
  hmacKeyAccessId: Scalars['String']['output'];
  hmacKeySecret: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
};

export type BigQueryCredentialsInput = {
  credentialsJson: Scalars['String']['input'];
  datasetId: Scalars['String']['input'];
  datasetLocation: Scalars['String']['input'];
  gcsBucketName: Scalars['String']['input'];
  gcsBucketPath: Scalars['String']['input'];
  hmacKeyAccessId: Scalars['String']['input'];
  hmacKeySecret: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type BillableFeature = {
  __typename?: 'BillableFeature';
  featureId: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
};

export type BillableFeatureInput = {
  featureId: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
};

export type BillingAddress = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export enum BillingAnchor {
  StartOfTheMonth = 'START_OF_THE_MONTH',
  SubscriptionsConsolidateBilling = 'SUBSCRIPTIONS_CONSOLIDATE_BILLING',
  SubscriptionStart = 'SUBSCRIPTION_START',
}

/** Billing Cadence. */
export enum BillingCadence {
  OneOff = 'ONE_OFF',
  Recurring = 'RECURRING',
}

export type BillingCadenceFilterComparison = {
  eq?: InputMaybe<BillingCadence>;
  gt?: InputMaybe<BillingCadence>;
  gte?: InputMaybe<BillingCadence>;
  iLike?: InputMaybe<BillingCadence>;
  in?: InputMaybe<Array<BillingCadence>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<BillingCadence>;
  lt?: InputMaybe<BillingCadence>;
  lte?: InputMaybe<BillingCadence>;
  neq?: InputMaybe<BillingCadence>;
  notILike?: InputMaybe<BillingCadence>;
  notIn?: InputMaybe<Array<BillingCadence>>;
  notLike?: InputMaybe<BillingCadence>;
};

/** Billing model. */
export enum BillingModel {
  FlatFee = 'FLAT_FEE',
  MinimumSpend = 'MINIMUM_SPEND',
  PerUnit = 'PER_UNIT',
  UsageBased = 'USAGE_BASED',
}

export type BillingModelFilterComparison = {
  eq?: InputMaybe<BillingModel>;
  gt?: InputMaybe<BillingModel>;
  gte?: InputMaybe<BillingModel>;
  iLike?: InputMaybe<BillingModel>;
  in?: InputMaybe<Array<BillingModel>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<BillingModel>;
  lt?: InputMaybe<BillingModel>;
  lte?: InputMaybe<BillingModel>;
  neq?: InputMaybe<BillingModel>;
  notILike?: InputMaybe<BillingModel>;
  notIn?: InputMaybe<Array<BillingModel>>;
  notLike?: InputMaybe<BillingModel>;
};

/** Billing period. */
export enum BillingPeriod {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
}

export type BillingPeriodChangeVariables = {
  __typename?: 'BillingPeriodChangeVariables';
  billingPeriod?: Maybe<BillingPeriod>;
};

export type BillingPeriodFilterComparison = {
  eq?: InputMaybe<BillingPeriod>;
  gt?: InputMaybe<BillingPeriod>;
  gte?: InputMaybe<BillingPeriod>;
  iLike?: InputMaybe<BillingPeriod>;
  in?: InputMaybe<Array<BillingPeriod>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<BillingPeriod>;
  lt?: InputMaybe<BillingPeriod>;
  lte?: InputMaybe<BillingPeriod>;
  neq?: InputMaybe<BillingPeriod>;
  notILike?: InputMaybe<BillingPeriod>;
  notIn?: InputMaybe<Array<BillingPeriod>>;
  notLike?: InputMaybe<BillingPeriod>;
};

/** Billing vendor identifiers */
export enum BillingVendorIdentifier {
  /** Stripe billing vendor */
  Stripe = 'STRIPE',
}

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Budget configuration */
export type BudgetConfiguration = {
  __typename?: 'BudgetConfiguration';
  /** Indicates the behavior of the budget when the limit is exceeded. If true, the limit is a soft limit, if false, the limit is a hard limit. Default is false. */
  hasSoftLimit: Scalars['Boolean']['output'];
  /** The maximum spending limit */
  limit: Scalars['Float']['output'];
};

/** Budget configuration input */
export type BudgetConfigurationInput = {
  /** Indicates the behavior of the budget when the limit is exceeded. If true, the limit is a soft limit, if false, the limit is a hard limit. Default is false. */
  hasSoftLimit: Scalars['Boolean']['input'];
  /** The maximum spending limit */
  limit: Scalars['Float']['input'];
};

export type CannotDeleteCustomerError = {
  __typename?: 'CannotDeleteCustomerError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  refId: Scalars['String']['output'];
};

export type CannotDeleteFeatureError = {
  __typename?: 'CannotDeleteFeatureError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  refId: Scalars['String']['output'];
};

export enum ChangeType {
  Added = 'ADDED',
  Deleted = 'DELETED',
  Modified = 'MODIFIED',
  Reordered = 'REORDERED',
}

export type ChangingPayingCustomerIsNotSupportedError = {
  __typename?: 'ChangingPayingCustomerIsNotSupportedError';
  code: Scalars['String']['output'];
  currentPayingCustomerId: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  newPayingCustomerId: Scalars['String']['output'];
};

/** Input for triggering an immediate usage charge for a subscription */
export type ChargeSubscriptionUsage = {
  __typename?: 'ChargeSubscriptionUsage';
  /** The ID of the invoice in the billing integration. If null then no invoice was created */
  invoiceBillingId?: Maybe<Scalars['String']['output']>;
  /** The date from which the usage was calculated to */
  periodEnd: Scalars['DateTime']['output'];
  /** The date from which the usage was calculated from */
  periodStart: Scalars['DateTime']['output'];
  /** The subscription reference id for which the usage was charged */
  subscriptionId: Scalars['String']['output'];
  /** An array of the usage items which were charged */
  usageCharged: Array<UsageCharged>;
};

/** Input for triggering an immediate usage charge for a subscription */
export type ChargeSubscriptionUsageInput = {
  /** The id of the subscriptions environment */
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The subscription reference id to charge usage for */
  subscriptionId: Scalars['String']['input'];
  /** The end date until which to calculate the usage */
  untilDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CheckoutBillingIntegration = {
  __typename?: 'CheckoutBillingIntegration';
  billingIdentifier: BillingVendorIdentifier;
  credentials: CheckoutCredentials;
};

export type CheckoutColorPalette = {
  __typename?: 'CheckoutColorPalette';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  borderColor?: Maybe<Scalars['String']['output']>;
  primary?: Maybe<Scalars['String']['output']>;
  summaryBackgroundColor?: Maybe<Scalars['String']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
};

export type CheckoutConfiguration = {
  __typename?: 'CheckoutConfiguration';
  content?: Maybe<CheckoutContent>;
  customCss?: Maybe<Scalars['String']['output']>;
  palette?: Maybe<CheckoutColorPalette>;
  typography?: Maybe<TypographyConfiguration>;
};

export type CheckoutConfigurationInput = {
  content?: InputMaybe<CheckoutContentInput>;
  customCss?: InputMaybe<Scalars['String']['input']>;
  palette?: InputMaybe<CheckoutPaletteInput>;
  typography?: InputMaybe<TypographyConfigurationInput>;
};

export type CheckoutContent = {
  __typename?: 'CheckoutContent';
  collectPhoneNumber?: Maybe<Scalars['Boolean']['output']>;
};

export type CheckoutContentInput = {
  collectPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CheckoutCredentials = {
  __typename?: 'CheckoutCredentials';
  accountId: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
};

export type CheckoutOptions = {
  allowPromoCodes?: InputMaybe<Scalars['Boolean']['input']>;
  allowTaxIdCollection?: InputMaybe<Scalars['Boolean']['input']>;
  cancelUrl: Scalars['String']['input'];
  collectBillingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  collectPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
  successUrl: Scalars['String']['input'];
};

export type CheckoutPaletteInput = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  borderColor?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['String']['input']>;
  summaryBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  textColor?: InputMaybe<Scalars['String']['input']>;
};

export type CheckoutState = {
  __typename?: 'CheckoutState';
  activeSubscription?: Maybe<CustomerSubscription>;
  billingIntegration: CheckoutBillingIntegration;
  configuration?: Maybe<CheckoutConfiguration>;
  customer: Customer;
  plan: Plan;
  resource?: Maybe<CustomerResource>;
  setupSecret: Scalars['String']['output'];
};

export type CheckoutStateInput = {
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['String']['input'];
  planId: Scalars['String']['input'];
  resourceId?: InputMaybe<Scalars['String']['input']>;
};

export type ClearCustomerPersistentCacheInput = {
  customerId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
};

/** Condition operation */
export enum ConditionOperation {
  Equals = 'EQUALS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  NotEquals = 'NOT_EQUALS',
}

export type Coupon = {
  __typename?: 'Coupon';
  /** Additional metadata associated with the coupon */
  additionalMetaData?: Maybe<Scalars['JSON']['output']>;
  /** Fixed amount discounts in different currencies */
  amountsOff?: Maybe<Array<Money>>;
  /** ID of the coupon in the billing system */
  billingId?: Maybe<Scalars['String']['output']>;
  /** URL to the coupon in the billing system */
  billingLinkUrl?: Maybe<Scalars['String']['output']>;
  /** Date when the coupon was created */
  createdAt: Scalars['DateTime']['output'];
  customers?: Maybe<Array<Customer>>;
  /** Description of the coupon */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * Discount value of the coupon (deprecated)
   * @deprecated Use percentOff or amountsOff fields instead
   */
  discountValue: Scalars['Float']['output'];
  /** Duration of the coupon validity in months */
  durationInMonths?: Maybe<Scalars['Float']['output']>;
  environment?: Maybe<Environment>;
  /** ID of the environment the coupon belongs to */
  environmentId: Scalars['String']['output'];
  /** Unique identifier of the coupon */
  id: Scalars['String']['output'];
  /** Name of the coupon */
  name: Scalars['String']['output'];
  /** Percentage discount off the original price */
  percentOff?: Maybe<Scalars['Float']['output']>;
  /** Customer-given identifier of the coupon */
  refId: Scalars['String']['output'];
  /** The source of the coupon */
  source: CouponSource;
  /** Current status of the coupon */
  status: CouponStatus;
  syncStates?: Maybe<Array<SyncState>>;
  /** Type of the coupon (percentage or fixed amount) */
  type: CouponType;
  /** Date when the coupon was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type CouponCustomersArgs = {
  filter?: InputMaybe<CustomerFilter>;
  sorting?: InputMaybe<Array<CustomerSort>>;
};

export type CouponAggregateGroupBy = {
  __typename?: 'CouponAggregateGroupBy';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  source?: Maybe<CouponSource>;
  status?: Maybe<CouponStatus>;
  type?: Maybe<CouponType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Coupon change variables */
export type CouponChangeVariables = {
  __typename?: 'CouponChangeVariables';
  /** Coupon reference ID */
  couponId: Scalars['String']['output'];
};

export type CouponConnection = {
  __typename?: 'CouponConnection';
  /** Array of edges. */
  edges: Array<CouponEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CouponCountAggregate = {
  __typename?: 'CouponCountAggregate';
  billingId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  refId?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type CouponEdge = {
  __typename?: 'CouponEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Coupon */
  node: Coupon;
};

export type CouponFilter = {
  and?: InputMaybe<Array<CouponFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customers?: InputMaybe<CouponFilterCustomerFilter>;
  description?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CouponFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  source?: InputMaybe<CouponSourceFilterComparison>;
  status?: InputMaybe<CouponStatusFilterComparison>;
  type?: InputMaybe<CouponTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CouponFilterCustomerFilter = {
  and?: InputMaybe<Array<CouponFilterCustomerFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmHubspotCompanyId?: InputMaybe<StringFieldComparison>;
  crmHubspotCompanyUrl?: InputMaybe<StringFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CouponFilterCustomerFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  searchQuery?: InputMaybe<CustomerSearchQueryFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CouponMaxAggregate = {
  __typename?: 'CouponMaxAggregate';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  source?: Maybe<CouponSource>;
  status?: Maybe<CouponStatus>;
  type?: Maybe<CouponType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CouponMinAggregate = {
  __typename?: 'CouponMinAggregate';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  source?: Maybe<CouponSource>;
  status?: Maybe<CouponStatus>;
  type?: Maybe<CouponType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CouponSort = {
  direction: SortDirection;
  field: CouponSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CouponSortFields {
  BillingId = 'billingId',
  CreatedAt = 'createdAt',
  Description = 'description',
  EnvironmentId = 'environmentId',
  Id = 'id',
  Name = 'name',
  RefId = 'refId',
  Source = 'source',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
}

/** The source of the coupon */
export enum CouponSource {
  Stigg = 'STIGG',
  StiggAdhoc = 'STIGG_ADHOC',
  Stripe = 'STRIPE',
}

export type CouponSourceFilterComparison = {
  eq?: InputMaybe<CouponSource>;
  in?: InputMaybe<Array<CouponSource>>;
};

/** The status of the coupon */
export enum CouponStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export type CouponStatusFilterComparison = {
  eq?: InputMaybe<CouponStatus>;
  gt?: InputMaybe<CouponStatus>;
  gte?: InputMaybe<CouponStatus>;
  iLike?: InputMaybe<CouponStatus>;
  in?: InputMaybe<Array<CouponStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<CouponStatus>;
  lt?: InputMaybe<CouponStatus>;
  lte?: InputMaybe<CouponStatus>;
  neq?: InputMaybe<CouponStatus>;
  notILike?: InputMaybe<CouponStatus>;
  notIn?: InputMaybe<Array<CouponStatus>>;
  notLike?: InputMaybe<CouponStatus>;
};

/** The type of the coupon */
export enum CouponType {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE',
}

export type CouponTypeFilterComparison = {
  eq?: InputMaybe<CouponType>;
  gt?: InputMaybe<CouponType>;
  gte?: InputMaybe<CouponType>;
  iLike?: InputMaybe<CouponType>;
  in?: InputMaybe<Array<CouponType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<CouponType>;
  lt?: InputMaybe<CouponType>;
  lte?: InputMaybe<CouponType>;
  neq?: InputMaybe<CouponType>;
  notILike?: InputMaybe<CouponType>;
  notIn?: InputMaybe<Array<CouponType>>;
  notLike?: InputMaybe<CouponType>;
};

export type CreateCouponInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  /** Discount flat fee amounts off */
  amountsOff?: InputMaybe<Array<MoneyInputDto>>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountValue?: InputMaybe<Scalars['Float']['input']>;
  /** The duration in months for which the coupon remains active. */
  durationInMonths?: InputMaybe<Scalars['Float']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** Discount percent off */
  percentOff?: InputMaybe<Scalars['Float']['input']>;
  refId: Scalars['String']['input'];
  type?: InputMaybe<CouponType>;
};

export type CreateEnvironment = {
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  hardenClientAccessEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  provisionStatus?: InputMaybe<EnvironmentProvisionStatus>;
  slug?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EnvironmentType>;
};

export type CreateEnvironmentOptions = {
  createDefaultProduct?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateExperimentInput = {
  controlGroupName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  productId: Scalars['String']['input'];
  productSettings?: InputMaybe<ProductSettingsInput>;
  variantGroupName: Scalars['String']['input'];
  variantPercentage: Scalars['Float']['input'];
};

export type CreateHook = {
  /** A JSON containing the configuration for each event log type */
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  eventLogTypes?: InputMaybe<Array<EventLogType>>;
  id?: InputMaybe<Scalars['String']['input']>;
  secretKey?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<HookStatus>;
};

export type CreateIntegrationInput = {
  auth0Credentials?: InputMaybe<Auth0CredentialsInput>;
  awsMarketplaceCredentials?: InputMaybe<AwsMarketplaceCredentialsInput>;
  bigQueryCredentials?: InputMaybe<BigQueryCredentialsInput>;
  environmentId: Scalars['String']['input'];
  hubspotCredentials?: InputMaybe<HubspotCredentialsInput>;
  openFGACredentials?: InputMaybe<OpenFgaCredentialsInput>;
  salesforceCredentials?: InputMaybe<SalesforceCredentialsInput>;
  snowflakeCredentials?: InputMaybe<SnowflakeCredentialsInput>;
  stripeCredentials?: InputMaybe<StripeCredentialsInput>;
  vendorIdentifier: VendorIdentifier;
  zuoraCredentials?: InputMaybe<ZuoraCredentialsInput>;
};

export type CreateManyPackageEntitlementsInput = {
  /** Array of records to create */
  packageEntitlements: Array<PackageEntitlementInput>;
};

export type CreateManyPromotionalEntitlementsInput = {
  /** Array of records to create */
  promotionalEntitlements: Array<PromotionalEntitlementInput>;
};

export type CreateMeter = {
  aggregation: MeterAggregation;
  filters: Array<MeterFilterDefinitionInput>;
};

export type CreateOneEnvironmentInput = {
  environment: CreateEnvironment;
  options?: InputMaybe<CreateEnvironmentOptions>;
};

export type CreateOneHookInput = {
  /** The record to create */
  hook: CreateHook;
};

export type CreateOneIntegrationInput = {
  /** The record to create */
  integration: CreateIntegrationInput;
};

export type CreateOneProductInput = {
  /** The record to create */
  product: ProductCreateInput;
};

export type CreateOrUpdateAwsMarketplaceProductInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  /** Auto cancellation rules */
  autoCancellationRules?: InputMaybe<Array<AutoCancellationRuleInput>>;
  awsDimensionsMapping: Array<DimensionsMappingInput>;
  awsProductId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  multipleSubscriptions?: InputMaybe<Scalars['Boolean']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  productSettings?: InputMaybe<ProductSettingsInput>;
  refId?: InputMaybe<Scalars['String']['input']>;
  /** Usage reset cutoff rule */
  usageResetCutoffRule?: InputMaybe<SubscriptionUpdateUsageResetCutoffRuleInput>;
};

/** The input type for creating an package group */
export type CreatePackageGroup = {
  /** The description of the package group */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The display name of the package group */
  displayName: Scalars['String']['input'];
  /** The environment id to create the package group in */
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the package group */
  packageGroupId: Scalars['String']['input'];
  /** The id of the product */
  productId: Scalars['String']['input'];
};

export type Credentials =
  | Auth0Credentials
  | AwsMarketplaceCredentials
  | BigQueryCredentials
  | HubspotCredentials
  | OpenFgaCredentials
  | SalesforceCredentials
  | SnowflakeCredentials
  | StripeCredentials
  | ZuoraCredentials;

/** Currency */
export enum Currency {
  Aed = 'AED',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bsd = 'BSD',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Gbp = 'GBP',
  Gel = 'GEL',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hrk = 'HRK',
  Htg = 'HTG',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sek = 'SEK',
  Sgd = 'SGD',
  Sle = 'SLE',
  Sll = 'SLL',
  Sos = 'SOS',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uzs = 'UZS',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xof = 'XOF',
  Xpf = 'XPF',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
}

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Customer = {
  __typename?: 'Customer';
  additionalMetaData?: Maybe<Scalars['JSON']['output']>;
  awsMarketplaceCustomerId?: Maybe<Scalars['String']['output']>;
  billingCurrency?: Maybe<Currency>;
  billingId?: Maybe<Scalars['String']['output']>;
  billingLinkUrl?: Maybe<Scalars['String']['output']>;
  coupon?: Maybe<Coupon>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crmHubspotCompanyId?: Maybe<Scalars['String']['output']>;
  crmHubspotCompanyUrl?: Maybe<Scalars['String']['output']>;
  crmId?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  defaultPaymentExpirationMonth?: Maybe<Scalars['Int']['output']>;
  defaultPaymentExpirationYear?: Maybe<Scalars['Int']['output']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']['output']>;
  defaultPaymentMethodLast4Digits?: Maybe<Scalars['String']['output']>;
  defaultPaymentMethodType?: Maybe<PaymentMethodType>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  eligibleForTrial?: Maybe<Array<EligibleForTrial>>;
  email?: Maybe<Scalars['String']['output']>;
  environment?: Maybe<Environment>;
  environmentId: Scalars['String']['output'];
  excludeFromExperiment?: Maybe<Scalars['Boolean']['output']>;
  experiment?: Maybe<Experiment>;
  experimentInfo?: Maybe<ExperimentInfo>;
  hasActiveResource: Scalars['Boolean']['output'];
  hasActiveSubscription: Scalars['Boolean']['output'];
  hasPaymentMethod: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  promotionalEntitlements: Array<PromotionalEntitlement>;
  /** @deprecated Renamed to customerId */
  refId: Scalars['String']['output'];
  salesforceId?: Maybe<Scalars['String']['output']>;
  statistics?: Maybe<CustomerStatistics>;
  subscriptions?: Maybe<Array<CustomerSubscription>>;
  syncStates?: Maybe<Array<SyncState>>;
  totalActivePromotionalEntitlements: Scalars['Float']['output'];
  totalActiveSubscription: Scalars['Float']['output'];
  trialedPlans?: Maybe<Array<TrialedPlan>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CustomerPromotionalEntitlementsArgs = {
  filter?: InputMaybe<PromotionalEntitlementFilter>;
  sorting?: InputMaybe<Array<PromotionalEntitlementSort>>;
};

export type CustomerSubscriptionsArgs = {
  filter?: InputMaybe<CustomerSubscriptionFilter>;
  sorting?: InputMaybe<Array<CustomerSubscriptionSort>>;
};

export type CustomerAggregateGroupBy = {
  __typename?: 'CustomerAggregateGroupBy';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crmHubspotCompanyId?: Maybe<Scalars['String']['output']>;
  crmHubspotCompanyUrl?: Maybe<Scalars['String']['output']>;
  crmId?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  searchQuery?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerAggregatedUsage = {
  __typename?: 'CustomerAggregatedUsage';
  customerId: Scalars['String']['output'];
  usage: Scalars['Float']['output'];
};

export type CustomerBillingInfo = {
  billingAddress?: InputMaybe<Address>;
  currency?: InputMaybe<Currency>;
  customerName?: InputMaybe<Scalars['String']['input']>;
  invoiceCustomFields?: InputMaybe<Scalars['JSON']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<Address>;
  taxIds?: InputMaybe<Array<TaxExempt>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  /** Array of edges. */
  edges: Array<CustomerEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CustomerCountAggregate = {
  __typename?: 'CustomerCountAggregate';
  billingId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  crmHubspotCompanyId?: Maybe<Scalars['Int']['output']>;
  crmHubspotCompanyUrl?: Maybe<Scalars['Int']['output']>;
  crmId?: Maybe<Scalars['Int']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  refId?: Maybe<Scalars['Int']['output']>;
  searchQuery?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Customer */
  node: Customer;
};

export type CustomerFilter = {
  and?: InputMaybe<Array<CustomerFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmHubspotCompanyId?: InputMaybe<StringFieldComparison>;
  crmHubspotCompanyUrl?: InputMaybe<StringFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerFilter>>;
  promotionalEntitlements?: InputMaybe<CustomerFilterPromotionalEntitlementFilter>;
  refId?: InputMaybe<StringFieldComparison>;
  searchQuery?: InputMaybe<CustomerSearchQueryFilterComparison>;
  subscriptions?: InputMaybe<CustomerFilterCustomerSubscriptionFilter>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomerFilterCustomerSubscriptionFilter = {
  and?: InputMaybe<Array<CustomerFilterCustomerSubscriptionFilter>>;
  billingCycleAnchor?: InputMaybe<DateFieldComparison>;
  billingId?: InputMaybe<StringFieldComparison>;
  cancelReason?: InputMaybe<SubscriptionCancelReasonFilterComparison>;
  cancellationDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  crmLinkUrl?: InputMaybe<StringFieldComparison>;
  currentBillingPeriodEnd?: InputMaybe<DateFieldComparison>;
  currentBillingPeriodStart?: InputMaybe<DateFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  effectiveEndDate?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  oldBillingId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerFilterCustomerSubscriptionFilter>>;
  payingCustomerId?: InputMaybe<StringFieldComparison>;
  paymentCollection?: InputMaybe<PaymentCollectionFilterComparison>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  resourceId?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<SubscriptionStatusFilterComparison>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  trialEndDate?: InputMaybe<DateFieldComparison>;
};

export type CustomerFilterPromotionalEntitlementFilter = {
  and?: InputMaybe<Array<CustomerFilterPromotionalEntitlementFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerFilterPromotionalEntitlementFilter>>;
  status?: InputMaybe<PromotionalEntitlementStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomerInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  awsMarketplaceCustomerId?: InputMaybe<Scalars['String']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  billingInformation?: InputMaybe<CustomerBillingInfo>;
  couponRefId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  crmId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['String']['input']>;
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  shouldSyncFree?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerMaxAggregate = {
  __typename?: 'CustomerMaxAggregate';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crmHubspotCompanyId?: Maybe<Scalars['String']['output']>;
  crmHubspotCompanyUrl?: Maybe<Scalars['String']['output']>;
  crmId?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  searchQuery?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerMinAggregate = {
  __typename?: 'CustomerMinAggregate';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crmHubspotCompanyId?: Maybe<Scalars['String']['output']>;
  crmHubspotCompanyUrl?: Maybe<Scalars['String']['output']>;
  crmId?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  searchQuery?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerNoBillingId = {
  __typename?: 'CustomerNoBillingId';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  refId: Scalars['String']['output'];
};

export type CustomerNotFoundError = {
  __typename?: 'CustomerNotFoundError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  refId: Scalars['String']['output'];
};

export type CustomerPortal = {
  __typename?: 'CustomerPortal';
  billingInformation: CustomerPortalBillingInformation;
  billingPortalUrl?: Maybe<Scalars['String']['output']>;
  canUpgradeSubscription: Scalars['Boolean']['output'];
  configuration?: Maybe<CustomerPortalConfiguration>;
  entitlements: Array<Entitlement>;
  promotionalEntitlements: Array<CustomerPortalPromotionalEntitlement>;
  resource?: Maybe<CustomerResource>;
  showWatermark: Scalars['Boolean']['output'];
  subscriptions: Array<CustomerPortalSubscription>;
};

export type CustomerPortalAddon = {
  __typename?: 'CustomerPortalAddon';
  addonId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type CustomerPortalBillingInformation = {
  __typename?: 'CustomerPortalBillingInformation';
  defaultPaymentExpirationMonth?: Maybe<Scalars['Int']['output']>;
  defaultPaymentExpirationYear?: Maybe<Scalars['Int']['output']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']['output']>;
  defaultPaymentMethodLast4Digits?: Maybe<Scalars['String']['output']>;
  defaultPaymentMethodType?: Maybe<PaymentMethodType>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CustomerPortalColorsPalette = {
  __typename?: 'CustomerPortalColorsPalette';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  borderColor?: Maybe<Scalars['String']['output']>;
  currentPlanBackground?: Maybe<Scalars['String']['output']>;
  iconsColor?: Maybe<Scalars['String']['output']>;
  paywallBackgroundColor?: Maybe<Scalars['String']['output']>;
  primary?: Maybe<Scalars['String']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
};

export type CustomerPortalColorsPaletteInput = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  borderColor?: InputMaybe<Scalars['String']['input']>;
  currentPlanBackground?: InputMaybe<Scalars['String']['input']>;
  iconsColor?: InputMaybe<Scalars['String']['input']>;
  paywallBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['String']['input']>;
  textColor?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerPortalConfiguration = {
  __typename?: 'CustomerPortalConfiguration';
  customCss?: Maybe<Scalars['String']['output']>;
  palette?: Maybe<CustomerPortalColorsPalette>;
  typography?: Maybe<TypographyConfiguration>;
};

export type CustomerPortalConfigurationInput = {
  customCss?: InputMaybe<Scalars['String']['input']>;
  palette?: InputMaybe<CustomerPortalColorsPaletteInput>;
  typography?: InputMaybe<TypographyConfigurationInput>;
};

export type CustomerPortalInput = {
  customerId: Scalars['String']['input'];
  productId?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerPortalPricingFeature = {
  __typename?: 'CustomerPortalPricingFeature';
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  featureType: FeatureType;
  featureUnits?: Maybe<Scalars['String']['output']>;
  featureUnitsPlural?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  meterType?: Maybe<MeterType>;
  refId: Scalars['String']['output'];
};

export type CustomerPortalPromotionalEntitlement = {
  __typename?: 'CustomerPortalPromotionalEntitlement';
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  hasSoftLimit?: Maybe<Scalars['Boolean']['output']>;
  hasUnlimitedUsage?: Maybe<Scalars['Boolean']['output']>;
  period: PromotionalEntitlementPeriod;
  startDate: Scalars['DateTime']['output'];
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

export type CustomerPortalSubscription = {
  __typename?: 'CustomerPortalSubscription';
  addons: Array<CustomerPortalAddon>;
  billingPeriodRange?: Maybe<DateRange>;
  planId: Scalars['String']['output'];
  planName: Scalars['String']['output'];
  prices: Array<CustomerPortalSubscriptionPrice>;
  /** @deprecated use prices field instead */
  pricing: CustomerPortalSubscriptionPricing;
  pricingType: PricingType;
  scheduledUpdates?: Maybe<Array<SubscriptionScheduledUpdate>>;
  status: SubscriptionStatus;
  subscriptionId: Scalars['String']['output'];
  totalPrice?: Maybe<CustomerSubscriptionTotalPrice>;
  trialRemainingDays?: Maybe<Scalars['Int']['output']>;
};

export type CustomerPortalSubscriptionPrice = {
  __typename?: 'CustomerPortalSubscriptionPrice';
  billingModel?: Maybe<BillingModel>;
  billingPeriod?: Maybe<BillingPeriod>;
  /** The number of units per block */
  blockSize?: Maybe<Scalars['Float']['output']>;
  feature?: Maybe<CustomerPortalPricingFeature>;
  price?: Maybe<Money>;
};

export type CustomerPortalSubscriptionPricing = {
  __typename?: 'CustomerPortalSubscriptionPricing';
  billingCountryCode?: Maybe<Scalars['String']['output']>;
  billingModel?: Maybe<BillingModel>;
  billingPeriod?: Maybe<BillingPeriod>;
  feature?: Maybe<CustomerPortalPricingFeature>;
  price?: Maybe<Money>;
  pricingType: PricingType;
  unitQuantity?: Maybe<Scalars['Int']['output']>;
  usageBasedEstimatedBill?: Maybe<Scalars['Float']['output']>;
};

export type CustomerResource = {
  __typename?: 'CustomerResource';
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  environmentId: Scalars['String']['output'];
  resourceId: Scalars['String']['output'];
  subscriptions: Array<CustomerSubscription>;
};

export type CustomerResourceSubscriptionsArgs = {
  filter?: InputMaybe<CustomerSubscriptionFilter>;
  sorting?: InputMaybe<Array<CustomerSubscriptionSort>>;
};

export type CustomerResourceAggregateGroupBy = {
  __typename?: 'CustomerResourceAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
};

export type CustomerResourceConnection = {
  __typename?: 'CustomerResourceConnection';
  /** Array of edges. */
  edges: Array<CustomerResourceEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CustomerResourceCountAggregate = {
  __typename?: 'CustomerResourceCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  resourceId?: Maybe<Scalars['Int']['output']>;
};

export type CustomerResourceEdge = {
  __typename?: 'CustomerResourceEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the CustomerResource */
  node: CustomerResource;
};

export type CustomerResourceFilter = {
  and?: InputMaybe<Array<CustomerResourceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customer?: InputMaybe<CustomerResourceFilterCustomerFilter>;
  environmentId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerResourceFilter>>;
  resourceId?: InputMaybe<StringFieldComparison>;
  subscriptions?: InputMaybe<CustomerResourceFilterCustomerSubscriptionFilter>;
};

export type CustomerResourceFilterCustomerFilter = {
  and?: InputMaybe<Array<CustomerResourceFilterCustomerFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmHubspotCompanyId?: InputMaybe<StringFieldComparison>;
  crmHubspotCompanyUrl?: InputMaybe<StringFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerResourceFilterCustomerFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  searchQuery?: InputMaybe<CustomerSearchQueryFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomerResourceFilterCustomerSubscriptionFilter = {
  and?: InputMaybe<Array<CustomerResourceFilterCustomerSubscriptionFilter>>;
  billingCycleAnchor?: InputMaybe<DateFieldComparison>;
  billingId?: InputMaybe<StringFieldComparison>;
  cancelReason?: InputMaybe<SubscriptionCancelReasonFilterComparison>;
  cancellationDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  crmLinkUrl?: InputMaybe<StringFieldComparison>;
  currentBillingPeriodEnd?: InputMaybe<DateFieldComparison>;
  currentBillingPeriodStart?: InputMaybe<DateFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  effectiveEndDate?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  oldBillingId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerResourceFilterCustomerSubscriptionFilter>>;
  payingCustomerId?: InputMaybe<StringFieldComparison>;
  paymentCollection?: InputMaybe<PaymentCollectionFilterComparison>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  resourceId?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<SubscriptionStatusFilterComparison>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  trialEndDate?: InputMaybe<DateFieldComparison>;
};

export type CustomerResourceMaxAggregate = {
  __typename?: 'CustomerResourceMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
};

export type CustomerResourceMinAggregate = {
  __typename?: 'CustomerResourceMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
};

export type CustomerResourceSort = {
  direction: SortDirection;
  field: CustomerResourceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CustomerResourceSortFields {
  CreatedAt = 'createdAt',
  EnvironmentId = 'environmentId',
  ResourceId = 'resourceId',
}

export type CustomerSearchQueryFilterComparison = {
  iLike?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerSort = {
  direction: SortDirection;
  field: CustomerSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CustomerSortFields {
  BillingId = 'billingId',
  CreatedAt = 'createdAt',
  CrmHubspotCompanyId = 'crmHubspotCompanyId',
  CrmHubspotCompanyUrl = 'crmHubspotCompanyUrl',
  CrmId = 'crmId',
  CustomerId = 'customerId',
  DeletedAt = 'deletedAt',
  Email = 'email',
  EnvironmentId = 'environmentId',
  Id = 'id',
  Name = 'name',
  RefId = 'refId',
  SearchQuery = 'searchQuery',
  UpdatedAt = 'updatedAt',
}

export type CustomerStatistics = {
  __typename?: 'CustomerStatistics';
  activeSubscriptionsByPricingType: Array<SubscriptionPricingTypeStatistics>;
};

export type CustomerSubscription = {
  __typename?: 'CustomerSubscription';
  additionalMetaData?: Maybe<Scalars['JSON']['output']>;
  addons?: Maybe<Array<SubscriptionAddon>>;
  /** Billing cycle anchor date */
  billingCycleAnchor?: Maybe<Scalars['DateTime']['output']>;
  billingId?: Maybe<Scalars['String']['output']>;
  billingLinkUrl?: Maybe<Scalars['String']['output']>;
  billingSyncError?: Maybe<Scalars['String']['output']>;
  /** Budget configuration */
  budget?: Maybe<BudgetConfiguration>;
  /** Indicates if the budget has been exceeded */
  budgetExceeded?: Maybe<Scalars['Boolean']['output']>;
  cancelReason?: Maybe<SubscriptionCancelReason>;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  /** Active coupon for this subscription */
  coupon?: Maybe<SubscriptionCoupon>;
  /** List of coupons for this subscription */
  coupons?: Maybe<Array<SubscriptionCoupon>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crmId?: Maybe<Scalars['String']['output']>;
  crmLinkUrl?: Maybe<Scalars['String']['output']>;
  currentBillingPeriodEnd?: Maybe<Scalars['DateTime']['output']>;
  currentBillingPeriodStart?: Maybe<Scalars['DateTime']['output']>;
  customer: Customer;
  customerId?: Maybe<Scalars['String']['output']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  environment: Environment;
  environmentId: Scalars['String']['output'];
  experiment?: Maybe<Experiment>;
  experimentInfo?: Maybe<ExperimentInfo>;
  freeItems?: Maybe<Array<FreeSubscriptionItem>>;
  futureUpdates: Array<SubscriptionFutureUpdate>;
  id: Scalars['String']['output'];
  /** @deprecated Use paymentCollectionMethod field and look for 'None' value */
  isCustomPriceSubscription?: Maybe<Scalars['Boolean']['output']>;
  lastUsageInvoice?: Maybe<SubscriptionInvoice>;
  latestInvoice?: Maybe<SubscriptionInvoice>;
  /** Minimum spend configuration */
  minimumSpend?: Maybe<SubscriptionMinimumSpend>;
  oldBillingId?: Maybe<Scalars['String']['output']>;
  outdatedPricePackages?: Maybe<Array<Scalars['String']['output']>>;
  payingCustomer?: Maybe<Customer>;
  payingCustomerId?: Maybe<Scalars['String']['output']>;
  paymentCollection: PaymentCollection;
  /** Payment collection method of the subscription */
  paymentCollectionMethod?: Maybe<PaymentCollectionMethod>;
  plan: Plan;
  prices?: Maybe<Array<SubscriptionPrice>>;
  pricingType: PricingType;
  /** @deprecated Renamed to subscriptionId */
  refId: Scalars['String']['output'];
  resource?: Maybe<CustomerResource>;
  resourceId?: Maybe<Scalars['String']['output']>;
  salesforceId?: Maybe<Scalars['String']['output']>;
  scheduledUpdates?: Maybe<Array<SubscriptionScheduledUpdate>>;
  startDate: Scalars['DateTime']['output'];
  status: SubscriptionStatus;
  subscriptionEntitlements?: Maybe<Array<SubscriptionEntitlement>>;
  subscriptionId: Scalars['String']['output'];
  syncStates?: Maybe<Array<SyncState>>;
  totalPrice?: Maybe<CustomerSubscriptionTotalPrice>;
  /** Trial configuration */
  trialConfiguration?: Maybe<TrialConfiguration>;
  trialEndDate?: Maybe<Scalars['DateTime']['output']>;
  wasInTrial?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomerSubscriptionAddonsArgs = {
  filter?: InputMaybe<SubscriptionAddonFilter>;
  sorting?: InputMaybe<Array<SubscriptionAddonSort>>;
};

export type CustomerSubscriptionPricesArgs = {
  filter?: InputMaybe<SubscriptionPriceFilter>;
  sorting?: InputMaybe<Array<SubscriptionPriceSort>>;
};

export type CustomerSubscriptionSubscriptionEntitlementsArgs = {
  filter?: InputMaybe<SubscriptionEntitlementFilter>;
  sorting?: InputMaybe<Array<SubscriptionEntitlementSort>>;
};

export type CustomerSubscriptionAggregateGroupBy = {
  __typename?: 'CustomerSubscriptionAggregateGroupBy';
  billingCycleAnchor?: Maybe<Scalars['DateTime']['output']>;
  billingId?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<SubscriptionCancelReason>;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crmId?: Maybe<Scalars['String']['output']>;
  crmLinkUrl?: Maybe<Scalars['String']['output']>;
  currentBillingPeriodEnd?: Maybe<Scalars['DateTime']['output']>;
  currentBillingPeriodStart?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  oldBillingId?: Maybe<Scalars['String']['output']>;
  payingCustomerId?: Maybe<Scalars['String']['output']>;
  paymentCollection?: Maybe<PaymentCollection>;
  pricingType?: Maybe<PricingType>;
  refId?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  trialEndDate?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerSubscriptionConnection = {
  __typename?: 'CustomerSubscriptionConnection';
  /** Array of edges. */
  edges: Array<CustomerSubscriptionEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CustomerSubscriptionCountAggregate = {
  __typename?: 'CustomerSubscriptionCountAggregate';
  billingCycleAnchor?: Maybe<Scalars['Int']['output']>;
  billingId?: Maybe<Scalars['Int']['output']>;
  cancelReason?: Maybe<Scalars['Int']['output']>;
  cancellationDate?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  crmId?: Maybe<Scalars['Int']['output']>;
  crmLinkUrl?: Maybe<Scalars['Int']['output']>;
  currentBillingPeriodEnd?: Maybe<Scalars['Int']['output']>;
  currentBillingPeriodStart?: Maybe<Scalars['Int']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  effectiveEndDate?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  oldBillingId?: Maybe<Scalars['Int']['output']>;
  payingCustomerId?: Maybe<Scalars['Int']['output']>;
  paymentCollection?: Maybe<Scalars['Int']['output']>;
  pricingType?: Maybe<Scalars['Int']['output']>;
  refId?: Maybe<Scalars['Int']['output']>;
  resourceId?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  subscriptionId?: Maybe<Scalars['Int']['output']>;
  trialEndDate?: Maybe<Scalars['Int']['output']>;
};

export type CustomerSubscriptionEdge = {
  __typename?: 'CustomerSubscriptionEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the CustomerSubscription */
  node: CustomerSubscription;
};

export type CustomerSubscriptionFilter = {
  addons?: InputMaybe<CustomerSubscriptionFilterSubscriptionAddonFilter>;
  and?: InputMaybe<Array<CustomerSubscriptionFilter>>;
  billingCycleAnchor?: InputMaybe<DateFieldComparison>;
  billingId?: InputMaybe<StringFieldComparison>;
  cancelReason?: InputMaybe<SubscriptionCancelReasonFilterComparison>;
  cancellationDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  crmLinkUrl?: InputMaybe<StringFieldComparison>;
  currentBillingPeriodEnd?: InputMaybe<DateFieldComparison>;
  currentBillingPeriodStart?: InputMaybe<DateFieldComparison>;
  customer?: InputMaybe<CustomerSubscriptionFilterCustomerFilter>;
  customerId?: InputMaybe<StringFieldComparison>;
  effectiveEndDate?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  oldBillingId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerSubscriptionFilter>>;
  payingCustomer?: InputMaybe<CustomerSubscriptionFilterCustomerFilter>;
  payingCustomerId?: InputMaybe<StringFieldComparison>;
  paymentCollection?: InputMaybe<PaymentCollectionFilterComparison>;
  plan?: InputMaybe<CustomerSubscriptionFilterPlanFilter>;
  prices?: InputMaybe<CustomerSubscriptionFilterSubscriptionPriceFilter>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  resource?: InputMaybe<CustomerSubscriptionFilterCustomerResourceFilter>;
  resourceId?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<SubscriptionStatusFilterComparison>;
  subscriptionEntitlements?: InputMaybe<CustomerSubscriptionFilterSubscriptionEntitlementFilter>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  trialEndDate?: InputMaybe<DateFieldComparison>;
};

export type CustomerSubscriptionFilterCustomerFilter = {
  and?: InputMaybe<Array<CustomerSubscriptionFilterCustomerFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmHubspotCompanyId?: InputMaybe<StringFieldComparison>;
  crmHubspotCompanyUrl?: InputMaybe<StringFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerSubscriptionFilterCustomerFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  searchQuery?: InputMaybe<CustomerSearchQueryFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomerSubscriptionFilterCustomerResourceFilter = {
  and?: InputMaybe<Array<CustomerSubscriptionFilterCustomerResourceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerSubscriptionFilterCustomerResourceFilter>>;
  resourceId?: InputMaybe<StringFieldComparison>;
};

export type CustomerSubscriptionFilterPlanFilter = {
  and?: InputMaybe<Array<CustomerSubscriptionFilterPlanFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<CustomerSubscriptionFilterPlanFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type CustomerSubscriptionFilterSubscriptionAddonFilter = {
  and?: InputMaybe<Array<CustomerSubscriptionFilterSubscriptionAddonFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerSubscriptionFilterSubscriptionAddonFilter>>;
  quantity?: InputMaybe<NumberFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomerSubscriptionFilterSubscriptionEntitlementFilter = {
  and?: InputMaybe<Array<CustomerSubscriptionFilterSubscriptionEntitlementFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerSubscriptionFilterSubscriptionEntitlementFilter>>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomerSubscriptionFilterSubscriptionPriceFilter = {
  and?: InputMaybe<Array<CustomerSubscriptionFilterSubscriptionPriceFilter>>;
  billingModel?: InputMaybe<BillingModelFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  featureId?: InputMaybe<StringFieldComparison>;
  hasSoftLimit?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerSubscriptionFilterSubscriptionPriceFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  usageLimit?: InputMaybe<NumberFieldComparison>;
};

export type CustomerSubscriptionMaxAggregate = {
  __typename?: 'CustomerSubscriptionMaxAggregate';
  billingCycleAnchor?: Maybe<Scalars['DateTime']['output']>;
  billingId?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<SubscriptionCancelReason>;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crmId?: Maybe<Scalars['String']['output']>;
  crmLinkUrl?: Maybe<Scalars['String']['output']>;
  currentBillingPeriodEnd?: Maybe<Scalars['DateTime']['output']>;
  currentBillingPeriodStart?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  oldBillingId?: Maybe<Scalars['String']['output']>;
  payingCustomerId?: Maybe<Scalars['String']['output']>;
  paymentCollection?: Maybe<PaymentCollection>;
  pricingType?: Maybe<PricingType>;
  refId?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  trialEndDate?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerSubscriptionMinAggregate = {
  __typename?: 'CustomerSubscriptionMinAggregate';
  billingCycleAnchor?: Maybe<Scalars['DateTime']['output']>;
  billingId?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<SubscriptionCancelReason>;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crmId?: Maybe<Scalars['String']['output']>;
  crmLinkUrl?: Maybe<Scalars['String']['output']>;
  currentBillingPeriodEnd?: Maybe<Scalars['DateTime']['output']>;
  currentBillingPeriodStart?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  oldBillingId?: Maybe<Scalars['String']['output']>;
  payingCustomerId?: Maybe<Scalars['String']['output']>;
  paymentCollection?: Maybe<PaymentCollection>;
  pricingType?: Maybe<PricingType>;
  refId?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  trialEndDate?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerSubscriptionSort = {
  direction: SortDirection;
  field: CustomerSubscriptionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CustomerSubscriptionSortFields {
  BillingCycleAnchor = 'billingCycleAnchor',
  BillingId = 'billingId',
  CancelReason = 'cancelReason',
  CancellationDate = 'cancellationDate',
  CreatedAt = 'createdAt',
  CrmId = 'crmId',
  CrmLinkUrl = 'crmLinkUrl',
  CurrentBillingPeriodEnd = 'currentBillingPeriodEnd',
  CurrentBillingPeriodStart = 'currentBillingPeriodStart',
  CustomerId = 'customerId',
  EffectiveEndDate = 'effectiveEndDate',
  EndDate = 'endDate',
  EnvironmentId = 'environmentId',
  Id = 'id',
  OldBillingId = 'oldBillingId',
  PayingCustomerId = 'payingCustomerId',
  PaymentCollection = 'paymentCollection',
  PricingType = 'pricingType',
  RefId = 'refId',
  ResourceId = 'resourceId',
  StartDate = 'startDate',
  Status = 'status',
  SubscriptionId = 'subscriptionId',
  TrialEndDate = 'trialEndDate',
}

export type CustomerSubscriptionTotalPrice = {
  __typename?: 'CustomerSubscriptionTotalPrice';
  addonsTotal: Money;
  subTotal: Money;
  total: Money;
};

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DateRange = {
  __typename?: 'DateRange';
  end?: Maybe<Scalars['DateTime']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
};

export type DefaultTrialConfig = {
  __typename?: 'DefaultTrialConfig';
  /** Budget configuration */
  budget?: Maybe<BudgetConfiguration>;
  duration: Scalars['Float']['output'];
  /** Trial end behavior */
  trialEndBehavior?: Maybe<TrialEndBehavior>;
  units: TrialPeriodUnits;
};

export type DefaultTrialConfigChange = {
  __typename?: 'DefaultTrialConfigChange';
  /** The value after the change */
  after?: Maybe<DefaultTrialConfig>;
  /** The value before the change */
  before?: Maybe<DefaultTrialConfig>;
  /** The change type */
  changeType?: Maybe<ChangeType>;
};

export type DefaultTrialConfigInputDto = {
  /** Budget configuration */
  budget?: InputMaybe<BudgetConfigurationInput>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** Trial end behavior */
  trialEndBehavior?: InputMaybe<TrialEndBehavior>;
  units?: InputMaybe<TrialPeriodUnits>;
};

/** The input for delegating a subscription to a customer */
export type DelegateSubscriptionToCustomerInput = {
  /** The customer ID which will manage the subscription (the paying customer of the subscription will not change) */
  destinationCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** The environment ID of the subscription to delegate */
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the subscription to delegate */
  subscriptionId: Scalars['String']['input'];
};

export type DeleteFeatureInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type DeleteOneHookInput = {
  /** The id of the record to delete. */
  id: Scalars['String']['input'];
};

export type DeleteOneIntegrationInput = {
  /** The id of the record to delete. */
  id: Scalars['String']['input'];
};

export type DeleteOnePackageEntitlementInput = {
  /** The id of the record to delete. */
  id: Scalars['String']['input'];
};

export type DeleteOnePriceInput = {
  /** The id of the record to delete. */
  id: Scalars['String']['input'];
};

export type DeleteOneProductInput = {
  /** The id of the record to delete. */
  id: Scalars['String']['input'];
};

export type DeleteOnePromotionalEntitlementInput = {
  /** The id of the record to delete. */
  id: Scalars['String']['input'];
};

export enum Department {
  CeoOrFounder = 'CEO_OR_FOUNDER',
  Engineering = 'ENGINEERING',
  Growth = 'GROWTH',
  Marketing = 'MARKETING',
  Monetization = 'MONETIZATION',
  Other = 'OTHER',
  Product = 'PRODUCT',
}

export type DetachCustomerPaymentMethodInput = {
  customerId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
};

export type DimensionsMappingInput = {
  key: Scalars['String']['input'];
  planName: Scalars['String']['input'];
  planRefId: Scalars['String']['input'];
};

export type DiscardPackageDraftInput = {
  environmentId: Scalars['String']['input'];
  refId: Scalars['String']['input'];
};

/** The type of the discount duration */
export enum DiscountDurationType {
  Forever = 'FOREVER',
  Once = 'ONCE',
  Repeating = 'REPEATING',
}

/** The type of the discount */
export enum DiscountType {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE',
}

export type DoesFeatureExist = {
  environmentId: Scalars['String']['input'];
  refId: Scalars['String']['input'];
};

export type DowngradeChangeVariables = {
  __typename?: 'DowngradeChangeVariables';
  /** @deprecated Use addons instead */
  addonRefIds?: Maybe<Scalars['String']['output']>;
  addons?: Maybe<Array<PlanChangeAddon>>;
  billableFeatures?: Maybe<Array<BillableFeature>>;
  billingPeriod?: Maybe<BillingPeriod>;
  downgradePlanRefId: Scalars['String']['output'];
  priceOverrides?: Maybe<Array<PriceOverrideChangeVariables>>;
};

/** Input type for dumpEnvironmentForMergeComparison query. */
export type DumpEnvironmentForForMergeComparisonInput = {
  /** The slug of the environment to copy to. */
  destinationEnvironmentSlug: Scalars['String']['input'];
  /** The slug of the environment to copy from. */
  sourceEnvironmentSlug: Scalars['String']['input'];
};

export type DumpEnvironmentForMergeComparison = {
  __typename?: 'DumpEnvironmentForMergeComparison';
  postMergeDump: Scalars['JSON']['output'];
  preMergeDump: Scalars['JSON']['output'];
};

/** Input type for dumpEnvironmentProductCatalog mutation. */
export type DumpEnvironmentProductCatalogInput = {
  /** The slug of the environment to dump. */
  environmentSlug: Scalars['String']['input'];
};

export type DuplicateProductInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  environmentId: Scalars['String']['input'];
  refId: Scalars['String']['input'];
  sourceProductId: Scalars['String']['input'];
};

export type DuplicatedEntityNotAllowedError = {
  __typename?: 'DuplicatedEntityNotAllowedError';
  code: Scalars['String']['output'];
  entityName: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type EditAllowedOnDraftPackageOnlyError = {
  __typename?: 'EditAllowedOnDraftPackageOnlyError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

/** The input type for editing package group details */
export type EditPackageGroupDetailsInput = {
  /** The description of the package group */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The display name of the package group */
  displayName: Scalars['String']['input'];
  /** The environment id of the package group to be edited */
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the package group */
  packageGroupId: Scalars['String']['input'];
};

export type EligibleForTrial = {
  __typename?: 'EligibleForTrial';
  eligible: Scalars['Boolean']['output'];
  productId?: Maybe<Scalars['String']['output']>;
  productRefId?: Maybe<Scalars['String']['output']>;
};

export type Entitlement = {
  __typename?: 'Entitlement';
  accessDeniedReason?: Maybe<AccessDeniedReason>;
  currentUsage?: Maybe<Scalars['Float']['output']>;
  /** @deprecated No longer in use */
  customerId?: Maybe<Scalars['String']['output']>;
  displayNameOverride?: Maybe<Scalars['String']['output']>;
  entitlementUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  feature?: Maybe<EntitlementFeature>;
  hasSoftLimit?: Maybe<Scalars['Boolean']['output']>;
  hasUnlimitedUsage: Scalars['Boolean']['output'];
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  isGranted: Scalars['Boolean']['output'];
  meterId?: Maybe<Scalars['String']['output']>;
  /** @deprecated renamed to usagePeriodEnd */
  nextResetDate?: Maybe<Scalars['DateTime']['output']>;
  requestedUsage?: Maybe<Scalars['Float']['output']>;
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  /** @deprecated No longer in use */
  resourceId?: Maybe<Scalars['String']['output']>;
  usageLimit?: Maybe<Scalars['Float']['output']>;
  /** The anchor for calculating the usage period for metered entitlements with a reset period configured */
  usagePeriodAnchor?: Maybe<Scalars['DateTime']['output']>;
  /** The end date of the usage period for metered entitlements with a reset period configured */
  usagePeriodEnd?: Maybe<Scalars['DateTime']['output']>;
  /** The start date of the usage period for metered entitlements with a reset period configured */
  usagePeriodStart?: Maybe<Scalars['DateTime']['output']>;
  usageUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** The behavior of the entitlement */
export enum EntitlementBehavior {
  /** Increment the value of the entitlement */
  Increment = 'Increment',
  /** Override the value of the entitlement */
  Override = 'Override',
}

export type EntitlementCheckRequested = {
  customerId: Scalars['String']['input'];
  entitlementCheckResult: EntitlementCheckResult;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  featureId: Scalars['String']['input'];
  requestedUsage?: InputMaybe<Scalars['Float']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
};

export type EntitlementCheckResult = {
  accessDeniedReason?: InputMaybe<AccessDeniedReason>;
  currentUsage?: InputMaybe<Scalars['Float']['input']>;
  hasAccess: Scalars['Boolean']['input'];
  hasSoftLimit?: InputMaybe<Scalars['Boolean']['input']>;
  hasUnlimitedUsage?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  nextResetDate?: InputMaybe<Scalars['DateTime']['input']>;
  requestedUsage?: InputMaybe<Scalars['Float']['input']>;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars['Float']['input']>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
  yearlyResetPeriodConfiguration?: InputMaybe<YearlyResetPeriodConfigInput>;
};

export type EntitlementFeature = {
  __typename?: 'EntitlementFeature';
  additionalMetaData?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  featureStatus: FeatureStatus;
  featureType: FeatureType;
  featureUnits?: Maybe<Scalars['String']['output']>;
  featureUnitsPlural?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  meterType?: Maybe<MeterType>;
  refId: Scalars['String']['output'];
  /** Unit transformation to be applied to the reported usage */
  unitTransformation?: Maybe<UnitTransformation>;
};

export type EntitlementLimitExceededError = {
  __typename?: 'EntitlementLimitExceededError';
  code: Scalars['String']['output'];
  feature: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type EntitlementOptions = {
  requestedUsage?: InputMaybe<Scalars['Float']['input']>;
  shouldTrack?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Entitlement reset period. */
export enum EntitlementResetPeriod {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type EntitlementSummary = {
  __typename?: 'EntitlementSummary';
  addonQuantity?: Maybe<Scalars['Float']['output']>;
  featurePackageEntitlement?: Maybe<PackageEntitlement>;
  featurePromotionalEntitlement?: Maybe<PromotionalEntitlement>;
  isEffectiveEntitlement: Scalars['Boolean']['output'];
  plan?: Maybe<Plan>;
  priceEntitlement?: Maybe<PriceEntitlement>;
  subscription?: Maybe<CustomerSubscription>;
};

export type EntitlementWithSummary = {
  __typename?: 'EntitlementWithSummary';
  accessDeniedReason?: Maybe<AccessDeniedReason>;
  currentUsage?: Maybe<Scalars['Float']['output']>;
  /** @deprecated No longer in use */
  customerId?: Maybe<Scalars['String']['output']>;
  displayNameOverride?: Maybe<Scalars['String']['output']>;
  entitlementUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  feature?: Maybe<EntitlementFeature>;
  hasSoftLimit?: Maybe<Scalars['Boolean']['output']>;
  hasUnlimitedUsage: Scalars['Boolean']['output'];
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  isGranted: Scalars['Boolean']['output'];
  meterId?: Maybe<Scalars['String']['output']>;
  /** @deprecated renamed to usagePeriodEnd */
  nextResetDate?: Maybe<Scalars['DateTime']['output']>;
  requestedUsage?: Maybe<Scalars['Float']['output']>;
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  /** @deprecated No longer in use */
  resourceId?: Maybe<Scalars['String']['output']>;
  summaries?: Maybe<Array<EntitlementSummary>>;
  usageLimit?: Maybe<Scalars['Float']['output']>;
  /** The anchor for calculating the usage period for metered entitlements with a reset period configured */
  usagePeriodAnchor?: Maybe<Scalars['DateTime']['output']>;
  /** The end date of the usage period for metered entitlements with a reset period configured */
  usagePeriodEnd?: Maybe<Scalars['DateTime']['output']>;
  /** The start date of the usage period for metered entitlements with a reset period configured */
  usagePeriodStart?: Maybe<Scalars['DateTime']['output']>;
  usageUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EntitlementsUpdated = {
  __typename?: 'EntitlementsUpdated';
  accountId: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  entitlements: Array<Entitlement>;
  environmentId: Scalars['String']['output'];
  resourceId?: Maybe<Scalars['String']['output']>;
};

export enum EntitySelectionMode {
  BlackList = 'BLACK_LIST',
  WhiteList = 'WHITE_LIST',
}

/** An environment object */
export type Environment = {
  __typename?: 'Environment';
  account?: Maybe<Account>;
  apiKeys: Array<ApiKey>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  hardenClientAccessEnabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isSandbox: Scalars['Boolean']['output'];
  permanentDeletionDate?: Maybe<Scalars['DateTime']['output']>;
  provisionStatus?: Maybe<EnvironmentProvisionStatus>;
  signingToken: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  type: EnvironmentType;
};

/** An environment object */
export type EnvironmentApiKeysArgs = {
  filter?: InputMaybe<ApiKeyFilter>;
  sorting?: InputMaybe<Array<ApiKeySort>>;
};

/** Environment access role of the user */
export enum EnvironmentAccessRole {
  Admin = 'ADMIN',
  None = 'NONE',
  Support = 'SUPPORT',
  Viewer = 'VIEWER',
}

export type EnvironmentAggregateGroupBy = {
  __typename?: 'EnvironmentAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  permanentDeletionDate?: Maybe<Scalars['DateTime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type EnvironmentConnection = {
  __typename?: 'EnvironmentConnection';
  /** Array of edges. */
  edges: Array<EnvironmentEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type EnvironmentCountAggregate = {
  __typename?: 'EnvironmentCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  displayName?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  permanentDeletionDate?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['Int']['output']>;
};

export type EnvironmentEdge = {
  __typename?: 'EnvironmentEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Environment */
  node: Environment;
};

export type EnvironmentFilter = {
  and?: InputMaybe<Array<EnvironmentFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<EnvironmentFilter>>;
  permanentDeletionDate?: InputMaybe<DateFieldComparison>;
  slug?: InputMaybe<StringFieldComparison>;
};

export type EnvironmentInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  hardenClientAccessEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  provisionStatus?: InputMaybe<EnvironmentProvisionStatus>;
};

export type EnvironmentMaxAggregate = {
  __typename?: 'EnvironmentMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  permanentDeletionDate?: Maybe<Scalars['DateTime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type EnvironmentMinAggregate = {
  __typename?: 'EnvironmentMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  permanentDeletionDate?: Maybe<Scalars['DateTime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type EnvironmentMissingError = {
  __typename?: 'EnvironmentMissingError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

/** EnvironmentProvisionStatus. */
export enum EnvironmentProvisionStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  NotProvisioned = 'NOT_PROVISIONED',
}

export type EnvironmentSort = {
  direction: SortDirection;
  field: EnvironmentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EnvironmentSortFields {
  CreatedAt = 'createdAt',
  DisplayName = 'displayName',
  Id = 'id',
  PermanentDeletionDate = 'permanentDeletionDate',
  Slug = 'slug',
}

/** EnvironmentType. */
export enum EnvironmentType {
  Development = 'DEVELOPMENT',
  Production = 'PRODUCTION',
  Sandbox = 'SANDBOX',
}

/** error codes */
export enum ErrorCode {
  AccessDeniedError = 'AccessDeniedError',
  AccountNotFoundError = 'AccountNotFoundError',
  /** Addon dependency missing error */
  AddonDependencyMissingError = 'AddonDependencyMissingError',
  AddonHasToHavePriceError = 'AddonHasToHavePriceError',
  /** Cannot delete an addon that is compatible with an addon group */
  AddonIsCompatibleWithGroup = 'AddonIsCompatibleWithGroup',
  /** Cannot delete an addon that is compatible with a plan */
  AddonIsCompatibleWithPlan = 'AddonIsCompatibleWithPlan',
  AddonNotFound = 'AddonNotFound',
  /** Addon quantity exceeds limit error */
  AddonQuantityExceedsLimitError = 'AddonQuantityExceedsLimitError',
  AddonWithDraftCannotBeDeletedError = 'AddonWithDraftCannotBeDeletedError',
  /** Multiple addons not found */
  AddonsNotFound = 'AddonsNotFound',
  AmountTooLarge = 'AmountTooLarge',
  ArchivedCouponCantBeApplied = 'ArchivedCouponCantBeApplied',
  /** The authenticated customer does not match the customer in the request */
  AuthCustomerMismatch = 'AuthCustomerMismatch',
  /** The authenticated customer has read-only permissions and cannot perform this operation */
  AuthCustomerReadonly = 'AuthCustomerReadonly',
  AwsMarketplaceIntegrationError = 'AwsMarketplaceIntegrationError',
  AwsMarketplaceIntegrationValidationError = 'AwsMarketplaceIntegrationValidationError',
  BadUserInput = 'BadUserInput',
  BillingIntegrationAlreadyExistsError = 'BillingIntegrationAlreadyExistsError',
  BillingIntegrationMissing = 'BillingIntegrationMissing',
  BillingPeriodMissingError = 'BillingPeriodMissingError',
  /** Cannot add override entitlement to a plan */
  CannotAddOverrideEntitlementToPlan = 'CannotAddOverrideEntitlementToPlan',
  CannotArchiveFeatureError = 'CannotArchiveFeatureError',
  CannotDeleteCustomerError = 'CannotDeleteCustomerError',
  CannotDeleteFeatureError = 'CannotDeleteFeatureError',
  CannotDeleteProductError = 'CannotDeleteProductError',
  CannotEditPackageInNonDraftMode = 'CannotEditPackageInNonDraftMode',
  CannotRemovePaymentMethodFromCustomerError = 'CannotRemovePaymentMethodFromCustomerError',
  CannotReportUsageForEntitlementWithMeterError = 'CannotReportUsageForEntitlementWithMeterError',
  /** Can not update unit transformation since this feature has connected active subscriptions */
  CannotUpdateUnitTransformationError = 'CannotUpdateUnitTransformationError',
  CannotUpsertToPackageThatHasDraft = 'CannotUpsertToPackageThatHasDraft',
  /** Changing paying customer is not supported */
  ChangingPayingCustomerIsNotSupportedError = 'ChangingPayingCustomerIsNotSupportedError',
  CheckoutIsNotSupported = 'CheckoutIsNotSupported',
  CouponNotFound = 'CouponNotFound',
  CustomerAlreadyHaveCustomerCoupon = 'CustomerAlreadyHaveCustomerCoupon',
  CustomerAlreadyUsesCoupon = 'CustomerAlreadyUsesCoupon',
  CustomerHasNoEmailAddress = 'CustomerHasNoEmailAddress',
  CustomerNoBillingId = 'CustomerNoBillingId',
  CustomerNotFound = 'CustomerNotFound',
  CustomerResourceNotFound = 'CustomerResourceNotFound',
  DowngradeBillingPeriodNotSupportedError = 'DowngradeBillingPeriodNotSupportedError',
  /** Draft addon cannot be archived */
  DraftAddonCantBeArchived = 'DraftAddonCantBeArchived',
  DraftPlanCantBeArchived = 'DraftPlanCantBeArchived',
  /** Duplicate addons provisioned error */
  DuplicateAddonProvisionedError = 'DuplicateAddonProvisionedError',
  DuplicateProductValidationError = 'DuplicateProductValidationError',
  DuplicatedEntityNotAllowed = 'DuplicatedEntityNotAllowed',
  EditAllowedOnDraftPackageOnlyError = 'EditAllowedOnDraftPackageOnlyError',
  EntitlementLimitExceededError = 'EntitlementLimitExceededError',
  EntitlementUsageOutOfRangeError = 'EntitlementUsageOutOfRangeError',
  EntitlementsMustBelongToSamePackage = 'EntitlementsMustBelongToSamePackage',
  EntityIdDifferentFromRefIdError = 'EntityIdDifferentFromRefIdError',
  EntityIsArchivedError = 'EntityIsArchivedError',
  EnvironmentMissing = 'EnvironmentMissing',
  ExperimentAlreadyRunning = 'ExperimentAlreadyRunning',
  ExperimentNotFoundError = 'ExperimentNotFoundError',
  ExperimentStatusError = 'ExperimentStatusError',
  FailedToCreateCheckoutSessionError = 'FailedToCreateCheckoutSessionError',
  FailedToImportCustomer = 'FailedToImportCustomer',
  FeatureNotFound = 'FeatureNotFound',
  FetchAllCountriesPricesNotAllowed = 'FetchAllCountriesPricesNotAllowed',
  /** Free plan can't have compatible package groups error */
  FreePlanCantHaveCompatiblePackageGroupError = 'FreePlanCantHaveCompatiblePackageGroupError',
  HubspotIntegrationError = 'HubspotIntegrationError',
  IdentityForbidden = 'IdentityForbidden',
  ImportAlreadyInProgress = 'ImportAlreadyInProgress',
  ImportSubscriptionsBulkError = 'ImportSubscriptionsBulkError',
  IncompatibleSubscriptionAddon = 'IncompatibleSubscriptionAddon',
  InitStripePaymentMethodError = 'InitStripePaymentMethodError',
  IntegrationNotFound = 'IntegrationNotFound',
  IntegrationValidationError = 'IntegrationValidationError',
  IntegrityViolation = 'IntegrityViolation',
  InvalidAddressError = 'InvalidAddressError',
  InvalidArgumentError = 'InvalidArgumentError',
  InvalidCancellationDate = 'InvalidCancellationDate',
  InvalidEntitlementResetPeriod = 'InvalidEntitlementResetPeriod',
  InvalidMemberDelete = 'InvalidMemberDelete',
  InvalidMetadataError = 'InvalidMetadataError',
  InvalidQuantity = 'InvalidQuantity',
  InvalidSubscriptionStatus = 'InvalidSubscriptionStatus',
  InvalidUpdatePriceUnitAmountError = 'InvalidUpdatePriceUnitAmountError',
  MemberInvitationError = 'MemberInvitationError',
  MemberNotFound = 'MemberNotFound',
  MergeEnvironmentValidationError = 'MergeEnvironmentValidationError',
  MeterMustBeAssociatedToMeteredFeature = 'MeterMustBeAssociatedToMeteredFeature',
  MeteringNotAvailableForFeatureType = 'MeteringNotAvailableForFeatureType',
  MissingEntityIdError = 'MissingEntityIdError',
  MissingSubscriptionInvoiceError = 'MissingSubscriptionInvoiceError',
  /** Multi subscription product cannot be a source of auto-cancellation rules */
  MultiSubscriptionCantBeAutoCancellationSourceError = 'MultiSubscriptionCantBeAutoCancellationSourceError',
  /** This account has no access to the requested feature */
  NoFeatureEntitlementError = 'NoFeatureEntitlementError',
  NoFeatureEntitlementInSubscription = 'NoFeatureEntitlementInSubscription',
  NoProductsAvailable = 'NoProductsAvailable',
  OperationNotAllowedDuringInProgressExperiment = 'OperationNotAllowedDuringInProgressExperiment',
  PackageAlreadyPublished = 'PackageAlreadyPublished',
  /** Package group min items error */
  PackageGroupMinItemsError = 'PackageGroupMinItemsError',
  PackageGroupNotFound = 'PackageGroupNotFound',
  PackagePricingTypeNotSet = 'PackagePricingTypeNotSet',
  PaymentMethodNotFoundError = 'PaymentMethodNotFoundError',
  PlanCannotBePublishWhenBasePlanIsDraft = 'PlanCannotBePublishWhenBasePlanIsDraft',
  PlanCannotBePublishWhenCompatibleAddonIsDraft = 'PlanCannotBePublishWhenCompatibleAddonIsDraft',
  PlanIsUsedAsDefaultStartPlan = 'PlanIsUsedAsDefaultStartPlan',
  PlanIsUsedAsDowngradePlan = 'PlanIsUsedAsDowngradePlan',
  PlanNotFound = 'PlanNotFound',
  PlanWithChildCantBeDeleted = 'PlanWithChildCantBeDeleted',
  PlansCircularDependencyError = 'PlansCircularDependencyError',
  PreparePaymentMethodFormError = 'PreparePaymentMethodFormError',
  PriceNotFound = 'PriceNotFound',
  ProductNotFoundError = 'ProductNotFoundError',
  PromotionCodeCustomerNotFirstPurchase = 'PromotionCodeCustomerNotFirstPurchase',
  PromotionCodeMaxRedemptionsReached = 'PromotionCodeMaxRedemptionsReached',
  PromotionCodeMinimumAmountNotReached = 'PromotionCodeMinimumAmountNotReached',
  PromotionCodeNotActive = 'PromotionCodeNotActive',
  PromotionCodeNotForCustomer = 'PromotionCodeNotForCustomer',
  PromotionCodeNotFound = 'PromotionCodeNotFound',
  PromotionalEntitlementNotFoundError = 'PromotionalEntitlementNotFoundError',
  RateLimitExceeded = 'RateLimitExceeded',
  RecalculateEntitlementsError = 'RecalculateEntitlementsError',
  RequiredSsoAuthenticationError = 'RequiredSsoAuthenticationError',
  ResyncAlreadyInProgress = 'ResyncAlreadyInProgress',
  ScheduledMigrationAlreadyExistsError = 'ScheduledMigrationAlreadyExistsError',
  SelectedBillingModelDoesntMatchImportedItemError = 'SelectedBillingModelDoesntMatchImportedItemError',
  /** Single subscription product cannot be a target of auto-cancellation rules */
  SingleSubscriptionCantBeAutoCancellationTargetError = 'SingleSubscriptionCantBeAutoCancellationTargetError',
  StripeCustomerIsDeleted = 'StripeCustomerIsDeleted',
  StripeError = 'StripeError',
  SubscriptionAlreadyCanceledOrExpired = 'SubscriptionAlreadyCanceledOrExpired',
  SubscriptionAlreadyOnLatestPlanError = 'SubscriptionAlreadyOnLatestPlanError',
  SubscriptionDoesNotHaveBillingPeriod = 'SubscriptionDoesNotHaveBillingPeriod',
  SubscriptionInvoiceStatusError = 'SubscriptionInvoiceStatusError',
  SubscriptionMustHaveSinglePlanError = 'SubscriptionMustHaveSinglePlanError',
  SubscriptionNoBillingId = 'SubscriptionNoBillingId',
  SubscriptionNotFound = 'SubscriptionNotFound',
  TooManySubscriptionsPerCustomer = 'TooManySubscriptionsPerCustomer',
  TrialMustBeCancelledImmediately = 'TrialMustBeCancelledImmediately',
  UnPublishedPackage = 'UnPublishedPackage',
  Unauthenticated = 'Unauthenticated',
  UnexpectedError = 'UnexpectedError',
  UnsupportedFeatureType = 'UnsupportedFeatureType',
  UnsupportedSubscriptionScheduleType = 'UnsupportedSubscriptionScheduleType',
  UnsupportedVendorIdentifier = 'UnsupportedVendorIdentifier',
  UsageMeasurementDiffOutOfRangeError = 'UsageMeasurementDiffOutOfRangeError',
}

export type EstimateSubscriptionInput = {
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  /** Coupon input */
  appliedCoupon?: InputMaybe<SubscriptionCouponInput>;
  billableFeatures?: InputMaybe<Array<BillableFeatureInput>>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  billingInformation?: InputMaybe<SubscriptionBillingInfo>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  customerId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The customer that will pay for the subscription */
  payingCustomerId?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['String']['input'];
  priceUnitAmount?: InputMaybe<Scalars['Float']['input']>;
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  skipTrial?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** trial configuration to override the default values from plan if exists */
  trialOverrideConfiguration?: InputMaybe<TrialOverrideConfigurationInput>;
  unitQuantity?: InputMaybe<Scalars['Float']['input']>;
};

export type EstimateSubscriptionUpdateInput = {
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  /** Coupon input */
  appliedCoupon?: InputMaybe<SubscriptionCouponInput>;
  billableFeatures?: InputMaybe<Array<BillableFeatureInput>>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['String']['input'];
  unitQuantity?: InputMaybe<Scalars['Float']['input']>;
};

/** Event actor type */
export enum EventActor {
  /** Client SDK with hardened authentication */
  AppCustomer = 'APP_CUSTOMER',
  /** Client SDK */
  AppPublic = 'APP_PUBLIC',
  /** Backend SDK */
  AppServer = 'APP_SERVER',
  /** AWS triggered event */
  Aws = 'AWS',
  /** Import triggered event */
  Import = 'IMPORT',
  /** Migration triggered event */
  Migration = 'MIGRATION',
  /** Salesforce API key */
  Salesforce = 'SALESFORCE',
  /** Stigg scheduler */
  Scheduler = 'SCHEDULER',
  /** Stigg user service key */
  Service = 'SERVICE',
  /** Stripe triggered event */
  Stripe = 'STRIPE',
  /** Stigg customer support */
  Support = 'SUPPORT',
  /** Stigg internal system */
  System = 'SYSTEM',
  /** Web application user */
  User = 'USER',
}

/** Event actor information */
export type EventActorInfo = {
  __typename?: 'EventActorInfo';
  /** Actor email */
  email?: Maybe<Scalars['String']['output']>;
  /** Actor IP address */
  ipAddress?: Maybe<Scalars['String']['output']>;
  /** Actor name */
  name?: Maybe<Scalars['String']['output']>;
  /** Actor type */
  type?: Maybe<EventActor>;
};

/** Event entity type */
export enum EventEntityType {
  /** Add-on entity */
  Addon = 'ADDON',
  /** Coupon entity */
  Coupon = 'COUPON',
  /** Customer entity */
  Customer = 'CUSTOMER',
  /** Entitlement entity */
  Entitlement = 'ENTITLEMENT',
  /** Feature entity */
  Feature = 'FEATURE',
  /** Import entity */
  Import = 'IMPORT',
  /** Measurement entity */
  Measurement = 'MEASUREMENT',
  /** Package entity */
  Package = 'PACKAGE',
  /** Package group entity */
  PackageGroup = 'PACKAGE_GROUP',
  /** Plan entity */
  Plan = 'PLAN',
  /** Product entity */
  Product = 'PRODUCT',
  /** Promotional entitlement entity */
  PromotionalEntitlement = 'PROMOTIONAL_ENTITLEMENT',
  /** Subscription entity */
  Subscription = 'SUBSCRIPTION',
}

export type EventLog = {
  __typename?: 'EventLog';
  /** The account ID */
  accountId: Scalars['String']['output'];
  /** Actor information */
  actor?: Maybe<EventActorInfo>;
  /** The timestamp the event was created at */
  createdAt: Scalars['DateTime']['output'];
  /** The entity id of this event */
  entityId?: Maybe<Scalars['String']['output']>;
  /** The event entity type */
  entityType?: Maybe<EventEntityType>;
  /** The environment ID */
  environmentId: Scalars['String']['output'];
  /** The type of the event */
  eventLogType: EventLogType;
  /** The ID of the event */
  id: Scalars['String']['output'];
  /** The parent entity id of this events entity */
  parentEntityId?: Maybe<Scalars['String']['output']>;
  /** The payload of the event */
  payload: Scalars['JSON']['output'];
  /** Request information */
  request?: Maybe<EventRequest>;
  /** List of webhooks endpoints this event was configured to be sent to */
  webhooks?: Maybe<Array<EventWebhook>>;
};

export type EventLogAggregateGroupBy = {
  __typename?: 'EventLogAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  eventLogType?: Maybe<EventLogType>;
  id?: Maybe<Scalars['String']['output']>;
  parentEntityId?: Maybe<Scalars['String']['output']>;
};

export type EventLogConnection = {
  __typename?: 'EventLogConnection';
  /** Array of edges. */
  edges: Array<EventLogEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type EventLogCountAggregate = {
  __typename?: 'EventLogCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  entityId?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  eventLogType?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  parentEntityId?: Maybe<Scalars['Int']['output']>;
};

export type EventLogCreatedAtFilterComparison = {
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EventLogEdge = {
  __typename?: 'EventLogEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the EventLog */
  node: EventLog;
};

export type EventLogEntityIdFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EventLogEnvironmentIdFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
};

export type EventLogEventLogTypeFilterComparison = {
  eq?: InputMaybe<EventLogType>;
  in?: InputMaybe<Array<EventLogType>>;
  neq?: InputMaybe<EventLogType>;
  notIn?: InputMaybe<Array<EventLogType>>;
};

export type EventLogFilter = {
  and?: InputMaybe<Array<EventLogFilter>>;
  createdAt?: InputMaybe<EventLogCreatedAtFilterComparison>;
  entityId?: InputMaybe<EventLogEntityIdFilterComparison>;
  environmentId: EventLogEnvironmentIdFilterComparison;
  eventLogType?: InputMaybe<EventLogEventLogTypeFilterComparison>;
  id?: InputMaybe<EventLogIdFilterComparison>;
  or?: InputMaybe<Array<EventLogFilter>>;
  parentEntityId?: InputMaybe<EventLogParentEntityIdFilterComparison>;
};

export type EventLogIdFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
};

export type EventLogMaxAggregate = {
  __typename?: 'EventLogMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  eventLogType?: Maybe<EventLogType>;
  id?: Maybe<Scalars['String']['output']>;
  parentEntityId?: Maybe<Scalars['String']['output']>;
};

export type EventLogMinAggregate = {
  __typename?: 'EventLogMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  eventLogType?: Maybe<EventLogType>;
  id?: Maybe<Scalars['String']['output']>;
  parentEntityId?: Maybe<Scalars['String']['output']>;
};

export type EventLogParentEntityIdFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EventLogSort = {
  direction: SortDirection;
  field: EventLogSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EventLogSortFields {
  CreatedAt = 'createdAt',
  EntityId = 'entityId',
  EnvironmentId = 'environmentId',
  EventLogType = 'eventLogType',
  Id = 'id',
  ParentEntityId = 'parentEntityId',
}

/** EventLogType */
export enum EventLogType {
  AddonCreated = 'ADDON_CREATED',
  AddonDeleted = 'ADDON_DELETED',
  AddonUpdated = 'ADDON_UPDATED',
  CouponArchived = 'COUPON_ARCHIVED',
  CouponCreated = 'COUPON_CREATED',
  CouponUpdated = 'COUPON_UPDATED',
  CreateSubscriptionFailed = 'CREATE_SUBSCRIPTION_FAILED',
  CustomerCreated = 'CUSTOMER_CREATED',
  CustomerDeleted = 'CUSTOMER_DELETED',
  CustomerEntitlementCalculationTriggered = 'CUSTOMER_ENTITLEMENT_CALCULATION_TRIGGERED',
  CustomerPaymentFailed = 'CUSTOMER_PAYMENT_FAILED',
  CustomerResourceEntitlementCalculationTriggered = 'CUSTOMER_RESOURCE_ENTITLEMENT_CALCULATION_TRIGGERED',
  CustomerUpdated = 'CUSTOMER_UPDATED',
  EdgeApiCustomerDataResync = 'EDGE_API_CUSTOMER_DATA_RESYNC',
  EdgeApiDataResync = 'EDGE_API_DATA_RESYNC',
  EdgeApiDoggoResync = 'EDGE_API_DOGGO_RESYNC',
  EdgeApiPackageEntitlementsDataResync = 'EDGE_API_PACKAGE_ENTITLEMENTS_DATA_RESYNC',
  EdgeApiSubscriptionsDataResync = 'EDGE_API_SUBSCRIPTIONS_DATA_RESYNC',
  EntitlementsUpdated = 'ENTITLEMENTS_UPDATED',
  EntitlementDenied = 'ENTITLEMENT_DENIED',
  EntitlementGranted = 'ENTITLEMENT_GRANTED',
  EntitlementRequested = 'ENTITLEMENT_REQUESTED',
  EntitlementUsageExceeded = 'ENTITLEMENT_USAGE_EXCEEDED',
  EnvironmentDeleted = 'ENVIRONMENT_DELETED',
  FeatureArchived = 'FEATURE_ARCHIVED',
  FeatureCreated = 'FEATURE_CREATED',
  FeatureDeleted = 'FEATURE_DELETED',
  FeatureUpdated = 'FEATURE_UPDATED',
  ImportIntegrationCatalogTriggered = 'IMPORT_INTEGRATION_CATALOG_TRIGGERED',
  ImportIntegrationCustomersTriggered = 'IMPORT_INTEGRATION_CUSTOMERS_TRIGGERED',
  ImportSubscriptionsBulkTriggered = 'IMPORT_SUBSCRIPTIONS_BULK_TRIGGERED',
  MeasurementReported = 'MEASUREMENT_REPORTED',
  PackageGroupCreated = 'PACKAGE_GROUP_CREATED',
  PackageGroupUpdated = 'PACKAGE_GROUP_UPDATED',
  PackagePublished = 'PACKAGE_PUBLISHED',
  PlanCreated = 'PLAN_CREATED',
  PlanDeleted = 'PLAN_DELETED',
  PlanUpdated = 'PLAN_UPDATED',
  ProductCreated = 'PRODUCT_CREATED',
  ProductDeleted = 'PRODUCT_DELETED',
  ProductUpdated = 'PRODUCT_UPDATED',
  PromotionalEntitlementExpired = 'PROMOTIONAL_ENTITLEMENT_EXPIRED',
  PromotionalEntitlementGranted = 'PROMOTIONAL_ENTITLEMENT_GRANTED',
  PromotionalEntitlementRevoked = 'PROMOTIONAL_ENTITLEMENT_REVOKED',
  PromotionalEntitlementUpdated = 'PROMOTIONAL_ENTITLEMENT_UPDATED',
  RecalculateEntitlementsTriggered = 'RECALCULATE_ENTITLEMENTS_TRIGGERED',
  ResyncIntegrationTriggered = 'RESYNC_INTEGRATION_TRIGGERED',
  SubscriptionsMigrated = 'SUBSCRIPTIONS_MIGRATED',
  SubscriptionsMigrationTriggered = 'SUBSCRIPTIONS_MIGRATION_TRIGGERED',
  SubscriptionBillingMonthEndsSoon = 'SUBSCRIPTION_BILLING_MONTH_ENDS_SOON',
  SubscriptionCanceled = 'SUBSCRIPTION_CANCELED',
  SubscriptionCreated = 'SUBSCRIPTION_CREATED',
  SubscriptionExpired = 'SUBSCRIPTION_EXPIRED',
  SubscriptionSpentLimitExceeded = 'SUBSCRIPTION_SPENT_LIMIT_EXCEEDED',
  SubscriptionTrialConverted = 'SUBSCRIPTION_TRIAL_CONVERTED',
  SubscriptionTrialEndsSoon = 'SUBSCRIPTION_TRIAL_ENDS_SOON',
  SubscriptionTrialExpired = 'SUBSCRIPTION_TRIAL_EXPIRED',
  SubscriptionTrialStarted = 'SUBSCRIPTION_TRIAL_STARTED',
  SubscriptionUpdated = 'SUBSCRIPTION_UPDATED',
  SubscriptionUsageChargeTriggered = 'SUBSCRIPTION_USAGE_CHARGE_TRIGGERED',
  SubscriptionUsageUpdated = 'SUBSCRIPTION_USAGE_UPDATED',
  SyncFailed = 'SYNC_FAILED',
  WidgetConfigurationUpdated = 'WIDGET_CONFIGURATION_UPDATED',
}

/** Event request properties */
export type EventRequest = {
  __typename?: 'EventRequest';
  /** The request body */
  body?: Maybe<Scalars['JSON']['output']>;
  /** The request response */
  response?: Maybe<Scalars['JSON']['output']>;
  /** The request trace ID */
  traceId?: Maybe<Scalars['String']['output']>;
};

/** An event webhook entity */
export type EventWebhook = {
  __typename?: 'EventWebhook';
  /** The endpoint of the webhook */
  endpoint: Scalars['String']['output'];
  /** The ID of the webhook entity */
  id: Scalars['String']['output'];
};

export type EventsFields = {
  __typename?: 'EventsFields';
  fields: Scalars['JSON']['output'];
};

export type EventsFieldsInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  environmentId: Scalars['String']['input'];
  featureId?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<MeterFilterDefinitionInput>>;
  meterId?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  /** Omit fields that have more than this number of unique values. */
  uniqueValuesLimit?: InputMaybe<Scalars['Float']['input']>;
};

export type Experiment = {
  __typename?: 'Experiment';
  controlGroupName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customers?: Maybe<Customer>;
  description?: Maybe<Scalars['String']['output']>;
  environment?: Maybe<Environment>;
  environmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  initialProductSettings?: Maybe<ProductSettings>;
  name: Scalars['String']['output'];
  product?: Maybe<Product>;
  productId: Scalars['String']['output'];
  productSettings: ProductSettings;
  refId: Scalars['String']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: ExperimentStatus;
  stoppedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  variantGroupName: Scalars['String']['output'];
  variantPercentage: Scalars['Float']['output'];
};

export type ExperimentAggregateGroupBy = {
  __typename?: 'ExperimentAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ExperimentStatus>;
};

export type ExperimentAvgAggregate = {
  __typename?: 'ExperimentAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type ExperimentConnection = {
  __typename?: 'ExperimentConnection';
  /** Array of edges. */
  edges: Array<ExperimentEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ExperimentCountAggregate = {
  __typename?: 'ExperimentCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  refId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type ExperimentEdge = {
  __typename?: 'ExperimentEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Experiment */
  node: Experiment;
};

export type ExperimentFilter = {
  and?: InputMaybe<Array<ExperimentFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customers?: InputMaybe<ExperimentFilterCustomerFilter>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ExperimentFilter>>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ExperimentStatusFilterComparison>;
};

export type ExperimentFilterCustomerFilter = {
  and?: InputMaybe<Array<ExperimentFilterCustomerFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmHubspotCompanyId?: InputMaybe<StringFieldComparison>;
  crmHubspotCompanyUrl?: InputMaybe<StringFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ExperimentFilterCustomerFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  searchQuery?: InputMaybe<CustomerSearchQueryFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type ExperimentMaxAggregate = {
  __typename?: 'ExperimentMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ExperimentStatus>;
};

export type ExperimentMinAggregate = {
  __typename?: 'ExperimentMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ExperimentStatus>;
};

export type ExperimentSort = {
  direction: SortDirection;
  field: ExperimentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ExperimentSortFields {
  CreatedAt = 'createdAt',
  EnvironmentId = 'environmentId',
  Id = 'id',
  Name = 'name',
  ProductId = 'productId',
  RefId = 'refId',
  Status = 'status',
}

export type ExperimentStats = {
  __typename?: 'ExperimentStats';
  controlPaidSubscriptions: Scalars['Float']['output'];
  controlSubscriptions: Scalars['Float']['output'];
  variantPaidSubscriptions: Scalars['Float']['output'];
  variantSubscriptions: Scalars['Float']['output'];
};

export type ExperimentStatsQuery = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
  experimentRefId: Scalars['String']['input'];
};

/** The status of the EXPERIMENT */
export enum ExperimentStatus {
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
}

export type ExperimentStatusFilterComparison = {
  eq?: InputMaybe<ExperimentStatus>;
  gt?: InputMaybe<ExperimentStatus>;
  gte?: InputMaybe<ExperimentStatus>;
  iLike?: InputMaybe<ExperimentStatus>;
  in?: InputMaybe<Array<ExperimentStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ExperimentStatus>;
  lt?: InputMaybe<ExperimentStatus>;
  lte?: InputMaybe<ExperimentStatus>;
  neq?: InputMaybe<ExperimentStatus>;
  notILike?: InputMaybe<ExperimentStatus>;
  notIn?: InputMaybe<Array<ExperimentStatus>>;
  notLike?: InputMaybe<ExperimentStatus>;
};

export type ExperimentSumAggregate = {
  __typename?: 'ExperimentSumAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type FailedToImportCustomerError = {
  __typename?: 'FailedToImportCustomerError';
  billingId: Scalars['String']['output'];
  code: Scalars['String']['output'];
  failedBillingIds: Array<Scalars['String']['output']>;
  failedCustomerIds: Array<Scalars['String']['output']>;
  isValidationError: Scalars['Boolean']['output'];
};

export type Feature = {
  __typename?: 'Feature';
  account?: Maybe<Account>;
  additionalMetaData?: Maybe<Scalars['JSON']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  environment?: Maybe<Environment>;
  environmentId: Scalars['String']['output'];
  featureStatus: FeatureStatus;
  featureType: FeatureType;
  featureUnits?: Maybe<Scalars['String']['output']>;
  featureUnitsPlural?: Maybe<Scalars['String']['output']>;
  hasEntitlements?: Maybe<Scalars['Boolean']['output']>;
  hasMeter?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  meter?: Maybe<Meter>;
  meterType?: Maybe<MeterType>;
  refId: Scalars['String']['output'];
  /** Unit transformation to be applied to the reported usage */
  unitTransformation?: Maybe<UnitTransformation>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FeatureAggregateGroupBy = {
  __typename?: 'FeatureAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  featureStatus?: Maybe<FeatureStatus>;
  featureType?: Maybe<FeatureType>;
  id?: Maybe<Scalars['String']['output']>;
  meterType?: Maybe<MeterType>;
  refId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FeatureAssociatedLatestPackages = {
  environmentId: Scalars['String']['input'];
  featureId: Scalars['String']['input'];
};

export type FeatureConnection = {
  __typename?: 'FeatureConnection';
  /** Array of edges. */
  edges: Array<FeatureEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type FeatureCountAggregate = {
  __typename?: 'FeatureCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['Int']['output']>;
  displayName?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  featureStatus?: Maybe<Scalars['Int']['output']>;
  featureType?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  meterType?: Maybe<Scalars['Int']['output']>;
  refId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type FeatureEdge = {
  __typename?: 'FeatureEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Feature */
  node: Feature;
};

export type FeatureFilter = {
  and?: InputMaybe<Array<FeatureFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  featureStatus?: InputMaybe<FeatureStatusFilterComparison>;
  featureType?: InputMaybe<FeatureTypeFilterComparison>;
  id?: InputMaybe<StringFieldComparison>;
  meterType?: InputMaybe<MeterTypeFilterComparison>;
  or?: InputMaybe<Array<FeatureFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type FeatureInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  environmentId: Scalars['String']['input'];
  featureStatus?: InputMaybe<FeatureStatus>;
  featureType: FeatureType;
  featureUnits?: InputMaybe<Scalars['String']['input']>;
  featureUnitsPlural?: InputMaybe<Scalars['String']['input']>;
  meter?: InputMaybe<CreateMeter>;
  meterType?: InputMaybe<MeterType>;
  refId: Scalars['String']['input'];
  /** Unit transformation to be applied to the reported usage */
  unitTransformation?: InputMaybe<UnitTransformationInput>;
};

export type FeatureMaxAggregate = {
  __typename?: 'FeatureMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  featureStatus?: Maybe<FeatureStatus>;
  featureType?: Maybe<FeatureType>;
  id?: Maybe<Scalars['String']['output']>;
  meterType?: Maybe<MeterType>;
  refId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FeatureMinAggregate = {
  __typename?: 'FeatureMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  featureStatus?: Maybe<FeatureStatus>;
  featureType?: Maybe<FeatureType>;
  id?: Maybe<Scalars['String']['output']>;
  meterType?: Maybe<MeterType>;
  refId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FeatureNotFoundError = {
  __typename?: 'FeatureNotFoundError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  refId: Scalars['String']['output'];
};

export type FeatureSort = {
  direction: SortDirection;
  field: FeatureSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FeatureSortFields {
  CreatedAt = 'createdAt',
  Description = 'description',
  DisplayName = 'displayName',
  EnvironmentId = 'environmentId',
  FeatureStatus = 'featureStatus',
  FeatureType = 'featureType',
  Id = 'id',
  MeterType = 'meterType',
  RefId = 'refId',
  UpdatedAt = 'updatedAt',
}

/** Feature status. */
export enum FeatureStatus {
  Active = 'ACTIVE',
  New = 'NEW',
  Suspended = 'SUSPENDED',
}

export type FeatureStatusFilterComparison = {
  eq?: InputMaybe<FeatureStatus>;
  gt?: InputMaybe<FeatureStatus>;
  gte?: InputMaybe<FeatureStatus>;
  iLike?: InputMaybe<FeatureStatus>;
  in?: InputMaybe<Array<FeatureStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<FeatureStatus>;
  lt?: InputMaybe<FeatureStatus>;
  lte?: InputMaybe<FeatureStatus>;
  neq?: InputMaybe<FeatureStatus>;
  notILike?: InputMaybe<FeatureStatus>;
  notIn?: InputMaybe<Array<FeatureStatus>>;
  notLike?: InputMaybe<FeatureStatus>;
};

/** The type of the feature */
export enum FeatureType {
  Boolean = 'BOOLEAN',
  Number = 'NUMBER',
}

export type FeatureTypeFilterComparison = {
  eq?: InputMaybe<FeatureType>;
  gt?: InputMaybe<FeatureType>;
  gte?: InputMaybe<FeatureType>;
  iLike?: InputMaybe<FeatureType>;
  in?: InputMaybe<Array<FeatureType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<FeatureType>;
  lt?: InputMaybe<FeatureType>;
  lte?: InputMaybe<FeatureType>;
  neq?: InputMaybe<FeatureType>;
  notILike?: InputMaybe<FeatureType>;
  notIn?: InputMaybe<Array<FeatureType>>;
  notLike?: InputMaybe<FeatureType>;
};

export type FetchEntitlementQuery = {
  customerId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
  featureId: Scalars['String']['input'];
  options?: InputMaybe<EntitlementOptions>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
};

export type FetchEntitlementsQuery = {
  customerId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
};

export type FontVariant = {
  __typename?: 'FontVariant';
  fontSize?: Maybe<Scalars['Float']['output']>;
  fontWeight?: Maybe<FontWeight>;
};

export type FontVariantInput = {
  fontSize?: InputMaybe<Scalars['Float']['input']>;
  fontWeight?: InputMaybe<FontWeight>;
};

/** Font weight */
export enum FontWeight {
  Bold = 'BOLD',
  Normal = 'NORMAL',
}

/** Free subscription item */
export type FreeSubscriptionItem = {
  __typename?: 'FreeSubscriptionItem';
  /** The add-on id */
  addonId: Scalars['String']['output'];
  /** The quantity of free items */
  quantity: Scalars['Float']['output'];
};

export type GetActiveSubscriptionsInput = {
  customerId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  resourceIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetAuth0ApplicationsInput = {
  clientDomain: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
};

export type GetAwsExternalIdResult = {
  __typename?: 'GetAwsExternalIdResult';
  externalId: Scalars['String']['output'];
};

export type GetCustomerByRefIdInput = {
  customerId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
};

export type GetPackageByRefIdInput = {
  environmentId: Scalars['String']['input'];
  refId: Scalars['String']['input'];
  versionNumber?: InputMaybe<Scalars['Float']['input']>;
};

/** The input type for getting an package group */
export type GetPackageGroup = {
  /** The environment id to get the package group in */
  environmentId: Scalars['String']['input'];
  /** The id of the package group */
  packageGroupId: Scalars['String']['input'];
  /** The version number of the package group */
  versionNumber?: InputMaybe<Scalars['Float']['input']>;
};

export type GetPaywallInput = {
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  context?: InputMaybe<WidgetType>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  fetchAllCountriesPrices?: InputMaybe<Scalars['Boolean']['input']>;
  includeHiddenPlans?: InputMaybe<Scalars['Boolean']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
};

/** Get subscription input */
export type GetSubscriptionInput = {
  /** The environment ID the subscription belongs to */
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The subscription ID to retrieve */
  subscriptionId: Scalars['String']['input'];
};

export type GetWidgetConfigurationInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
};

export type GrantPromotionalEntitlementInput = {
  customEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureId: Scalars['String']['input'];
  hasSoftLimit?: InputMaybe<Scalars['Boolean']['input']>;
  hasUnlimitedUsage?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  period: PromotionalEntitlementPeriod;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars['Float']['input']>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
  yearlyResetPeriodConfiguration?: InputMaybe<YearlyResetPeriodConfigInput>;
};

export type GrantPromotionalEntitlementsInput = {
  customerId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
  promotionalEntitlements: Array<GrantPromotionalEntitlementInput>;
};

export type GroupInfo = {
  __typename?: 'GroupInfo';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GroupUsageHistory = {
  __typename?: 'GroupUsageHistory';
  groupInfo: Array<GroupInfo>;
  usageMeasurements: Array<UsageMeasurementPoint>;
};

export type HiddenFromWidgetsChange = {
  __typename?: 'HiddenFromWidgetsChange';
  after?: Maybe<Array<WidgetType>>;
  before?: Maybe<Array<WidgetType>>;
  changeType?: Maybe<ChangeType>;
};

export type Hook = {
  __typename?: 'Hook';
  account?: Maybe<Account>;
  /** A JSON containing the configuration for each event log type */
  configuration?: Maybe<Scalars['JSON']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endpoint: Scalars['String']['output'];
  environment?: Maybe<Environment>;
  environmentId: Scalars['String']['output'];
  eventLogTypes: Array<EventLogType>;
  id: Scalars['String']['output'];
  secretKey?: Maybe<Scalars['String']['output']>;
  status: HookStatus;
};

export type HookAggregateGroupBy = {
  __typename?: 'HookAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<HookStatus>;
};

export type HookConnection = {
  __typename?: 'HookConnection';
  /** Array of edges. */
  edges: Array<HookEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type HookCountAggregate = {
  __typename?: 'HookCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  endpoint?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type HookDeleteResponse = {
  __typename?: 'HookDeleteResponse';
  /** A JSON containing the configuration for each event log type */
  configuration?: Maybe<Scalars['JSON']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  eventLogTypes?: Maybe<Array<EventLogType>>;
  id?: Maybe<Scalars['String']['output']>;
  secretKey?: Maybe<Scalars['String']['output']>;
  status?: Maybe<HookStatus>;
};

export type HookEdge = {
  __typename?: 'HookEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Hook */
  node: Hook;
};

export type HookFilter = {
  and?: InputMaybe<Array<HookFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  endpoint?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<HookFilter>>;
  status?: InputMaybe<HookStatusFilterComparison>;
};

export type HookMaxAggregate = {
  __typename?: 'HookMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<HookStatus>;
};

export type HookMinAggregate = {
  __typename?: 'HookMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<HookStatus>;
};

export type HookSort = {
  direction: SortDirection;
  field: HookSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum HookSortFields {
  CreatedAt = 'createdAt',
  Endpoint = 'endpoint',
  EnvironmentId = 'environmentId',
  Id = 'id',
  Status = 'status',
}

/** HookStatus. */
export enum HookStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type HookStatusFilterComparison = {
  eq?: InputMaybe<HookStatus>;
  gt?: InputMaybe<HookStatus>;
  gte?: InputMaybe<HookStatus>;
  iLike?: InputMaybe<HookStatus>;
  in?: InputMaybe<Array<HookStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<HookStatus>;
  lt?: InputMaybe<HookStatus>;
  lte?: InputMaybe<HookStatus>;
  neq?: InputMaybe<HookStatus>;
  notILike?: InputMaybe<HookStatus>;
  notIn?: InputMaybe<Array<HookStatus>>;
  notLike?: InputMaybe<HookStatus>;
};

export type HubspotCredentials = {
  __typename?: 'HubspotCredentials';
  hubDomain: Scalars['String']['output'];
};

export type HubspotCredentialsInput = {
  authorizationCode: Scalars['String']['input'];
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};

export type IdentityForbiddenError = {
  __typename?: 'IdentityForbiddenError';
  accessedField: Scalars['String']['output'];
  code: Scalars['String']['output'];
  currentIdentityType: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  requiredIdentityType: Scalars['String']['output'];
};

export type ImmediateSubscriptionPreviewInvoice = {
  __typename?: 'ImmediateSubscriptionPreviewInvoice';
  credits?: Maybe<SubscriptionPreviewCredits>;
  discount?: Maybe<Money>;
  discountDetails?: Maybe<SubscriptionPreviewDiscount>;
  /** The minimum spend adjustment applied to the invoice */
  minimumSpendAdjustment: Money;
  proration?: Maybe<SubscriptionPreviewProrations>;
  subTotal: Money;
  tax?: Maybe<Money>;
  taxDetails?: Maybe<SubscriptionPreviewTaxDetails>;
  total: Money;
  totalExcludingTax: Money;
};

export type ImportAlreadyInProgressError = {
  __typename?: 'ImportAlreadyInProgressError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type ImportCustomerBulkInput = {
  customers: Array<ImportCustomerInput>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
};

export type ImportCustomerInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['String']['input']>;
  /** Indicates the last update of the customer. In case there's already existing customer that was updated after that date, it will skip import this customer */
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ImportIntegrationCatalogInput = {
  billingModel?: InputMaybe<BillingModel>;
  entitySelectionMode: EntitySelectionMode;
  environmentId: Scalars['String']['input'];
  featureUnitName?: InputMaybe<Scalars['String']['input']>;
  featureUnitPluralName?: InputMaybe<Scalars['String']['input']>;
  plansSelectionBlacklist?: InputMaybe<Array<Scalars['String']['input']>>;
  plansSelectionWhitelist?: InputMaybe<Array<Scalars['String']['input']>>;
  productId: Scalars['String']['input'];
  selectedAddonBillingIds: Array<Scalars['String']['input']>;
  vendorIdentifier: VendorIdentifier;
};

export type ImportIntegrationCustomersInput = {
  customersSelectionBlacklist?: InputMaybe<Array<Scalars['String']['input']>>;
  customersSelectionWhitelist?: InputMaybe<Array<Scalars['String']['input']>>;
  entitySelectionMode: EntitySelectionMode;
  environmentId: Scalars['String']['input'];
  productId: Scalars['String']['input'];
  vendorIdentifier: VendorIdentifier;
};

export type ImportIntegrationTask = {
  __typename?: 'ImportIntegrationTask';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customersCount: Scalars['Int']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  environmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  importErrors: Array<ImportSubTaskError>;
  productsCount: Scalars['Int']['output'];
  progress: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: TaskStatus;
  taskType: TaskType;
  totalSubtasksCount: Scalars['Int']['output'];
};

export type ImportIntegrationTaskAggregateGroupBy = {
  __typename?: 'ImportIntegrationTaskAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TaskStatus>;
  taskType?: Maybe<TaskType>;
};

export type ImportIntegrationTaskConnection = {
  __typename?: 'ImportIntegrationTaskConnection';
  /** Array of edges. */
  edges: Array<ImportIntegrationTaskEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ImportIntegrationTaskCountAggregate = {
  __typename?: 'ImportIntegrationTaskCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  taskType?: Maybe<Scalars['Int']['output']>;
};

export type ImportIntegrationTaskEdge = {
  __typename?: 'ImportIntegrationTaskEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the ImportIntegrationTask */
  node: ImportIntegrationTask;
};

export type ImportIntegrationTaskFilter = {
  and?: InputMaybe<Array<ImportIntegrationTaskFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ImportIntegrationTaskFilter>>;
  status?: InputMaybe<TaskStatusFilterComparison>;
  taskType?: InputMaybe<TaskTypeFilterComparison>;
};

export type ImportIntegrationTaskMaxAggregate = {
  __typename?: 'ImportIntegrationTaskMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TaskStatus>;
  taskType?: Maybe<TaskType>;
};

export type ImportIntegrationTaskMinAggregate = {
  __typename?: 'ImportIntegrationTaskMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TaskStatus>;
  taskType?: Maybe<TaskType>;
};

export type ImportIntegrationTaskSort = {
  direction: SortDirection;
  field: ImportIntegrationTaskSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ImportIntegrationTaskSortFields {
  CreatedAt = 'createdAt',
  EnvironmentId = 'environmentId',
  Id = 'id',
  Status = 'status',
  TaskType = 'taskType',
}

export type ImportSubTaskError = {
  __typename?: 'ImportSubTaskError';
  error: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type ImportSubscriptionInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  billableFeatures?: InputMaybe<Array<BillableFeatureInput>>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  customerId: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  planId: Scalars['String']['input'];
  resourceId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  unitQuantity?: InputMaybe<Scalars['Float']['input']>;
  /** Indicates the last update of the subscription. In case there's already existing subscription that was updated after that date, it will skip import this subscription */
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ImportSubscriptionsBulkInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
  subscriptions: Array<ImportSubscriptionInput>;
};

export type IncompatibleSubscriptionAddonError = {
  __typename?: 'IncompatibleSubscriptionAddonError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  nonCompatibleAddons?: Maybe<Array<Scalars['String']['output']>>;
  planDisplayName: Scalars['String']['output'];
};

export type InitAddStripeCustomerPaymentMethod = {
  __typename?: 'InitAddStripeCustomerPaymentMethod';
  paymentIntentClientSecret: Scalars['String']['output'];
};

export type InitAddStripeCustomerPaymentMethodInput = {
  customerRefId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
};

export type InitStripePaymentMethodError = {
  __typename?: 'InitStripePaymentMethodError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type IntFieldComparison = {
  between?: InputMaybe<IntFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  notBetween?: InputMaybe<IntFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars['Int']['input'];
  upper: Scalars['Int']['input'];
};

export type Integration = {
  __typename?: 'Integration';
  account?: Maybe<Account>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  credentials?: Maybe<Credentials>;
  environment?: Maybe<Environment>;
  environmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  vendorIdentifier: VendorIdentifier;
};

export type IntegrationAggregateGroupBy = {
  __typename?: 'IntegrationAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  vendorIdentifier?: Maybe<VendorIdentifier>;
};

export type IntegrationConnection = {
  __typename?: 'IntegrationConnection';
  /** Array of edges. */
  edges: Array<IntegrationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type IntegrationCountAggregate = {
  __typename?: 'IntegrationCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  vendorIdentifier?: Maybe<Scalars['Int']['output']>;
};

export type IntegrationDeleteResponse = {
  __typename?: 'IntegrationDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  credentials?: Maybe<Credentials>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  vendorIdentifier?: Maybe<VendorIdentifier>;
};

export type IntegrationEdge = {
  __typename?: 'IntegrationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Integration */
  node: Integration;
};

export type IntegrationFilter = {
  and?: InputMaybe<Array<IntegrationFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<IntegrationFilter>>;
  vendorIdentifier?: InputMaybe<VendorIdentifierFilterComparison>;
};

export type IntegrationMaxAggregate = {
  __typename?: 'IntegrationMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  vendorIdentifier?: Maybe<VendorIdentifier>;
};

export type IntegrationMinAggregate = {
  __typename?: 'IntegrationMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  vendorIdentifier?: Maybe<VendorIdentifier>;
};

export type IntegrationSort = {
  direction: SortDirection;
  field: IntegrationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum IntegrationSortFields {
  CreatedAt = 'createdAt',
  EnvironmentId = 'environmentId',
  Id = 'id',
  VendorIdentifier = 'vendorIdentifier',
}

export type InvalidArgumentError = {
  __typename?: 'InvalidArgumentError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type InvalidCancellationDate = {
  __typename?: 'InvalidCancellationDate';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  refId: Scalars['String']['output'];
};

export type InvalidEntitlementResetPeriodError = {
  __typename?: 'InvalidEntitlementResetPeriodError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type InvalidMemberDeleteError = {
  __typename?: 'InvalidMemberDeleteError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type InvalidSubscriptionStatus = {
  __typename?: 'InvalidSubscriptionStatus';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type InviteMembersInput = {
  /** Account level access of the user, defaults to member role */
  accountRole?: InputMaybe<AccountAccessRole>;
  /** List of emails to invite */
  invites: Array<Scalars['String']['input']>;
  /** Environment level access of the user, defaults to read-only role */
  nonProductionRole?: InputMaybe<EnvironmentAccessRole>;
  /** Environment level access of the user, defaults to read-only role */
  productionRole?: InputMaybe<EnvironmentAccessRole>;
};

export type InvoiceLine = {
  __typename?: 'InvoiceLine';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  proration: Scalars['Boolean']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** The type of the invoice line item */
export enum InvoiceLineItemType {
  AddonCharge = 'AddonCharge',
  BaseCharge = 'BaseCharge',
  InAdvanceCommitmentCharge = 'InAdvanceCommitmentCharge',
  MinimumSpendAdjustmentCharge = 'MinimumSpendAdjustmentCharge',
  MinimumSpendCharge = 'MinimumSpendCharge',
  Other = 'Other',
  OverageCharge = 'OverageCharge',
  PayAsYouGoCharge = 'PayAsYouGoCharge',
  TierCharge = 'TierCharge',
  ZeroAmountBaseCharge = 'ZeroAmountBaseCharge',
}

export type ListAwsProductDimensionsDto = {
  __typename?: 'ListAwsProductDimensionsDTO';
  dimensions: Array<AwsDimension>;
};

export type ListAwsProductDimensionsInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
};

export type ListAwsProductsInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
};

export type ListAwsProductsResult = {
  __typename?: 'ListAwsProductsResult';
  products: Array<AwsProduct>;
};

/** Input for triggering the subscription invoice as paid */
export type MarkInvoiceAsPaidInput = {
  /** Environment ID */
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The subscription entity id to trigger mark invoice as paid */
  subscriptionId: Scalars['String']['input'];
};

export type Member = {
  __typename?: 'Member';
  /** Get the access roles assigned to the member */
  accessRoles?: Maybe<AccessRoles>;
  account: Account;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  cubejsToken?: Maybe<Scalars['String']['output']>;
  customerToken?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  hideGettingStartedPage?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  memberStatus: MemberStatus;
  serviceApiKey?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  /** The id of the user associated with this member */
  userId: Scalars['String']['output'];
};

export type MemberAggregateGroupBy = {
  __typename?: 'MemberAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type MemberConnection = {
  __typename?: 'MemberConnection';
  /** Array of edges. */
  edges: Array<MemberEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type MemberCountAggregate = {
  __typename?: 'MemberCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type MemberEdge = {
  __typename?: 'MemberEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Member */
  node: Member;
};

export type MemberFilter = {
  and?: InputMaybe<Array<MemberFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MemberFilter>>;
  user?: InputMaybe<MemberFilterUserFilter>;
};

export type MemberFilterUserFilter = {
  and?: InputMaybe<Array<MemberFilterUserFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MemberFilterUserFilter>>;
};

export type MemberInvitationError = {
  __typename?: 'MemberInvitationError';
  code: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export type MemberMaxAggregate = {
  __typename?: 'MemberMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type MemberMinAggregate = {
  __typename?: 'MemberMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type MemberNotFoundError = {
  __typename?: 'MemberNotFoundError';
  code: Scalars['String']['output'];
};

export type MemberSort = {
  direction: SortDirection;
  field: MemberSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MemberSortFields {
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
}

/** Member Status. */
export enum MemberStatus {
  Invited = 'INVITED',
  Registered = 'REGISTERED',
}

export type MembersInviteResponse = {
  __typename?: 'MembersInviteResponse';
  failedInvites: Array<Scalars['String']['output']>;
  skippedInvites: Array<Scalars['String']['output']>;
  successInvites: Array<Scalars['String']['output']>;
};

export type MergeEnvironment = {
  __typename?: 'MergeEnvironment';
  environmentSlug: Scalars['String']['output'];
  taskIds: Array<Scalars['String']['output']>;
};

/** Input type for mergeEnvironment mutation. */
export type MergeEnvironmentInput = {
  /** The name of the new environment. Only relevant when `destinationEnvironmentSlug` is not passed. */
  destinationEnvironmentName?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the environment to merge into. Not passing this will create a new environment and requires passing `destinationEnvironmentName`. */
  destinationEnvironmentSlug?: InputMaybe<Scalars['String']['input']>;
  /** The type of the new environment. only relevant when `destinationEnvironmentSlug` is not passed. */
  destinationEnvironmentType?: InputMaybe<EnvironmentType>;
  /** How to migrate customers to newer plan version of updated plans. Default is not to migrate existing customers. */
  migrationType?: InputMaybe<PublishMigrationType>;
  /** The slug of the environment to copy from. If missing `sourceTemplate` will be used. */
  sourceEnvironmentSlug?: InputMaybe<Scalars['String']['input']>;
  /** The product catalog template to apply. Only relevant when `sourceEnvironmentSlug` is not passed. */
  sourceTemplate?: InputMaybe<Scalars['JSON']['input']>;
};

export type Meter = {
  __typename?: 'Meter';
  aggregation: Aggregation;
  createdAt: Scalars['DateTime']['output'];
  environmentId: Scalars['String']['output'];
  filters: Array<MeterFilterDefinition>;
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MeterAggregation = {
  field?: InputMaybe<Scalars['String']['input']>;
  function: AggregationFunction;
};

export type MeterCondition = {
  __typename?: 'MeterCondition';
  field: Scalars['String']['output'];
  operation: ConditionOperation;
  value?: Maybe<Scalars['String']['output']>;
};

export type MeterConditionInput = {
  field: Scalars['String']['input'];
  operation: ConditionOperation;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MeterFilterDefinition = {
  __typename?: 'MeterFilterDefinition';
  conditions: Array<MeterCondition>;
};

export type MeterFilterDefinitionInput = {
  conditions: Array<MeterConditionInput>;
};

/** The meter type of the feature */
export enum MeterType {
  Fluctuating = 'Fluctuating',
  Incremental = 'Incremental',
  None = 'None',
}

export type MeterTypeFilterComparison = {
  eq?: InputMaybe<MeterType>;
  gt?: InputMaybe<MeterType>;
  gte?: InputMaybe<MeterType>;
  iLike?: InputMaybe<MeterType>;
  in?: InputMaybe<Array<MeterType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<MeterType>;
  lt?: InputMaybe<MeterType>;
  lte?: InputMaybe<MeterType>;
  neq?: InputMaybe<MeterType>;
  notILike?: InputMaybe<MeterType>;
  notIn?: InputMaybe<Array<MeterType>>;
  notLike?: InputMaybe<MeterType>;
};

export type MeteringNotAvailableForFeatureTypeError = {
  __typename?: 'MeteringNotAvailableForFeatureTypeError';
  code: Scalars['String']['output'];
  featureType: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

/** Minimum spend configuration */
export type MinimumSpend = {
  __typename?: 'MinimumSpend';
  /** Minimum spend billing period */
  billingPeriod: BillingPeriod;
  /** The minimum spend limit */
  minimum: Money;
};

/** Minimum spend change */
export type MinimumSpendChange = {
  __typename?: 'MinimumSpendChange';
  /** The value after the change */
  after?: Maybe<MinimumSpend>;
  /** The value before the change */
  before?: Maybe<MinimumSpend>;
  /** The change type */
  changeType?: Maybe<ChangeType>;
};

/** Minimum spend input */
export type MinimumSpendInput = {
  /** The billing period of minimum spend */
  billingPeriod: BillingPeriod;
  /** The minimum spend value */
  minimum: MoneyInputDto;
};

export type MockPaywall = {
  __typename?: 'MockPaywall';
  configuration?: Maybe<PaywallConfiguration>;
  plans: Array<PaywallPlan>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Float']['output'];
  currency: Currency;
};

export type MoneyInputDto = {
  amount: Scalars['Float']['input'];
  currency?: InputMaybe<Currency>;
};

/** Monthly reset period according to configuration */
export enum MonthlyAccordingTo {
  StartOfTheMonth = 'StartOfTheMonth',
  SubscriptionStart = 'SubscriptionStart',
}

export type MonthlyResetPeriodConfig = {
  __typename?: 'MonthlyResetPeriodConfig';
  monthlyAccordingTo?: Maybe<MonthlyAccordingTo>;
};

export type MonthlyResetPeriodConfigInput = {
  accordingTo: MonthlyAccordingTo;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCompatibleAddonsToPlan: Plan;
  applySubscription: ApplySubscription;
  /** Archive an addon by id */
  archiveAddon: Addon;
  archiveCustomer: Customer;
  archiveEnvironment: Environment;
  archiveFeature: Feature;
  archiveOneCoupon: Coupon;
  archivePackageGroup: PackageGroup;
  archivePlan: Plan;
  attachCustomerPaymentMethod: Customer;
  cancelSchedule: Scalars['String']['output'];
  cancelSubscription: CustomerSubscription;
  /** Immediately charges usage in the given subscription */
  chargeSubscriptionUsage: ChargeSubscriptionUsage;
  createAccount: Member;
  createAddonDraft: Addon;
  createEmptyAddonDraft: Addon;
  createEmptyPlanDraft: Plan;
  createFeature: Feature;
  createManyPackageEntitlements: Array<PackageEntitlement>;
  createManyPromotionalEntitlements: Array<PromotionalEntitlement>;
  createOneAddon: Addon;
  createOneCoupon: Coupon;
  /** @deprecated Use 'provisionCustomer' instead to create both customer and subscription in single operation. See 'provisionCustomer' for more info. */
  createOneCustomer: Customer;
  createOneEnvironment: Environment;
  createOneExperiment: Experiment;
  createOneHook: Hook;
  createOneIntegration: Integration;
  createOnePlan: Plan;
  createOneProduct: Product;
  createOrUpdateAwsMarketplaceProduct: Product;
  createPackageGroup: PackageGroup;
  createPlanDraft: Plan;
  createSubscription: CustomerSubscription;
  /** @deprecated Deprecated query, use 'reportUsage' instead */
  createUsageMeasurement: UsageMeasurementWithCurrentUsage;
  /** Delegates a subscription to a different customer (the paying customer of the subscription will not change) */
  delegateSubscriptionToCustomer: CustomerSubscription;
  deleteFeature: Feature;
  deleteOneFeature: Feature;
  deleteOneHook: HookDeleteResponse;
  deleteOneIntegration: IntegrationDeleteResponse;
  deleteOnePackageEntitlement: PackageEntitlementDeleteResponse;
  deleteOnePrice: PriceDeleteResponse;
  deleteOneProduct: ProductDeleteResponse;
  deleteOnePromotionalEntitlement: PromotionalEntitlementDeleteResponse;
  detachCustomerPaymentMethod: Customer;
  duplicateProduct: Product;
  editPackageGroup: PackageGroup;
  estimateSubscription: SubscriptionPreview;
  estimateSubscriptionUpdate: SubscriptionPreview;
  grantPromotionalEntitlements: Array<PromotionalEntitlement>;
  hideGettingStartedPage: Scalars['String']['output'];
  importCustomersBulk?: Maybe<Scalars['String']['output']>;
  importOneCustomer: Customer;
  importSubscriptionsBulk?: Maybe<Scalars['String']['output']>;
  initAddStripeCustomerPaymentMethod: InitAddStripeCustomerPaymentMethod;
  inviteMembers: MembersInviteResponse;
  /** Triggers mark invoice as paid for the given subscription */
  markInvoiceAsPaid?: Maybe<Scalars['String']['output']>;
  /** Merge data from an existing environment to another environment or to a new environment. */
  mergeEnvironment: MergeEnvironment;
  migrateSubscriptionToLatest: CustomerSubscription;
  preparePaymentMethodForm: PreparedPaymentMethodForm;
  previewNextInvoice: SubscriptionInvoicePreview;
  previewSubscription: SubscriptionPreviewV2;
  provisionCustomer: ProvisionedCustomer;
  provisionSandbox: Environment;
  provisionSubscription: ProvisionSubscriptionResult;
  provisionSubscriptionV2: ProvisionSubscriptionResult;
  publishAddon: PublishPackageResult;
  publishPlan: PublishPackageResult;
  purgeCustomerCache: Scalars['String']['output'];
  recalculateEntitlements: RecalculateEntitlementsResult;
  registerMember: User;
  removeAddonDraft: Scalars['String']['output'];
  removeBasePlanFromPlan: Plan;
  removeCompatibleAddonsFromPlan: Plan;
  removeCouponFromCustomer: Customer;
  removeExperimentFromCustomer: Customer;
  removeExperimentFromCustomerSubscription: CustomerSubscription;
  removeMember: Scalars['String']['output'];
  removePlanDraft: Scalars['String']['output'];
  reportEntitlementCheckRequested: Scalars['Boolean']['output'];
  reportEvent?: Maybe<Scalars['String']['output']>;
  reportUsage: UsageMeasurementWithCurrentUsage;
  reportUsageBulk: Array<UsageMeasurementWithCurrentUsage>;
  resendEmailVerification: Scalars['String']['output'];
  resyncIntegration: ResyncIntegrationResult;
  revokePromotionalEntitlement: PromotionalEntitlement;
  setAccessRoles?: Maybe<Scalars['String']['output']>;
  setBasePlanOnPlan: Plan;
  setCompatibleAddonsOnPlan: Plan;
  /** Set compatible package groups for a plan */
  setCompatiblePackageGroups: Plan;
  setCouponOnCustomer: Customer;
  setExperimentOnCustomer: Customer;
  setExperimentOnCustomerSubscription: CustomerSubscription;
  setPackageGroupAddons: PackageGroup;
  setPackagePricing: PackagePrice;
  setWidgetConfiguration?: Maybe<Scalars['String']['output']>;
  startExperiment: Scalars['String']['output'];
  stopExperiment: Scalars['String']['output'];
  subscriptionMaximumSpend?: Maybe<SubscriptionMaximumSpend>;
  syncTaxRates?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated mutation, use 'transferSubscriptionToResource' instead */
  transferSubscription: CustomerSubscription;
  /** Transfers a subscription to a different resource */
  transferSubscriptionToResource: CustomerSubscription;
  triggerImportCatalog: AsyncTaskResult;
  triggerImportCustomers: AsyncTaskResult;
  triggerPlanSubscriptionMigration: TriggerSubscriptionMigrationResult;
  /** Triggers the subscription billing month ends soon webhook for the given subscription */
  triggerSubscriptionBillingMonthEndsSoonWebhook: Scalars['String']['output'];
  /** Triggers the subscription usage sync for the given subscription */
  triggerSubscriptionUsageSync: Scalars['Boolean']['output'];
  unarchiveCustomer: Customer;
  unarchiveEnvironment: Environment;
  updateAccount: Account;
  updateEntitlementsOrder: Array<UpdateEntitlementsOrderDto>;
  updateFeature: Feature;
  updateOneAddon: Addon;
  updateOneCoupon: Coupon;
  updateOneCustomer: Customer;
  updateOneEnvironment: Environment;
  updateOneExperiment: Experiment;
  updateOneHook: Hook;
  updateOneIntegration: Integration;
  updateOnePackageEntitlement: PackageEntitlement;
  updateOnePlan: Plan;
  updateOneProduct: Product;
  updateOnePromotionalEntitlement: PromotionalEntitlement;
  updateOneSubscription: CustomerSubscription;
  updateUser: User;
};

export type MutationAddCompatibleAddonsToPlanArgs = {
  input: AddCompatibleAddonsToPlanInput;
};

export type MutationApplySubscriptionArgs = {
  input: ApplySubscriptionInput;
};

export type MutationArchiveAddonArgs = {
  input: AddonArchiveInput;
};

export type MutationArchiveCustomerArgs = {
  input: ArchiveCustomerInput;
};

export type MutationArchiveEnvironmentArgs = {
  input: ArchiveEnvironmentInput;
};

export type MutationArchiveFeatureArgs = {
  input: ArchiveFeatureInput;
};

export type MutationArchiveOneCouponArgs = {
  input: ArchiveCouponInput;
};

export type MutationArchivePackageGroupArgs = {
  input: ArchivePackageGroup;
};

export type MutationArchivePlanArgs = {
  input: ArchivePlanInput;
};

export type MutationAttachCustomerPaymentMethodArgs = {
  input: AttachCustomerPaymentMethodInput;
};

export type MutationCancelScheduleArgs = {
  input: SubscriptionUpdateScheduleCancellationInput;
};

export type MutationCancelSubscriptionArgs = {
  input: SubscriptionCancellationInput;
};

export type MutationChargeSubscriptionUsageArgs = {
  input: ChargeSubscriptionUsageInput;
};

export type MutationCreateAccountArgs = {
  accountName: Scalars['String']['input'];
};

export type MutationCreateAddonDraftArgs = {
  id: Scalars['String']['input'];
};

export type MutationCreateEmptyAddonDraftArgs = {
  id: Scalars['String']['input'];
};

export type MutationCreateEmptyPlanDraftArgs = {
  id: Scalars['String']['input'];
};

export type MutationCreateFeatureArgs = {
  input: FeatureInput;
};

export type MutationCreateManyPackageEntitlementsArgs = {
  input: CreateManyPackageEntitlementsInput;
};

export type MutationCreateManyPromotionalEntitlementsArgs = {
  input: CreateManyPromotionalEntitlementsInput;
};

export type MutationCreateOneAddonArgs = {
  input: AddonCreateInput;
};

export type MutationCreateOneCouponArgs = {
  input: CreateCouponInput;
};

export type MutationCreateOneCustomerArgs = {
  input: CustomerInput;
};

export type MutationCreateOneEnvironmentArgs = {
  input: CreateOneEnvironmentInput;
};

export type MutationCreateOneExperimentArgs = {
  input: CreateExperimentInput;
};

export type MutationCreateOneHookArgs = {
  input: CreateOneHookInput;
};

export type MutationCreateOneIntegrationArgs = {
  input: CreateOneIntegrationInput;
};

export type MutationCreateOnePlanArgs = {
  input: PlanCreateInput;
};

export type MutationCreateOneProductArgs = {
  input: CreateOneProductInput;
};

export type MutationCreateOrUpdateAwsMarketplaceProductArgs = {
  input: CreateOrUpdateAwsMarketplaceProductInput;
};

export type MutationCreatePackageGroupArgs = {
  input: CreatePackageGroup;
};

export type MutationCreatePlanDraftArgs = {
  id: Scalars['String']['input'];
};

export type MutationCreateSubscriptionArgs = {
  subscription: SubscriptionInput;
};

export type MutationCreateUsageMeasurementArgs = {
  usageMeasurement: UsageMeasurementCreateInput;
};

export type MutationDelegateSubscriptionToCustomerArgs = {
  input: DelegateSubscriptionToCustomerInput;
};

export type MutationDeleteFeatureArgs = {
  input: DeleteFeatureInput;
};

export type MutationDeleteOneFeatureArgs = {
  input: DeleteFeatureInput;
};

export type MutationDeleteOneHookArgs = {
  input: DeleteOneHookInput;
};

export type MutationDeleteOneIntegrationArgs = {
  input: DeleteOneIntegrationInput;
};

export type MutationDeleteOnePackageEntitlementArgs = {
  input: DeleteOnePackageEntitlementInput;
};

export type MutationDeleteOnePriceArgs = {
  input: DeleteOnePriceInput;
};

export type MutationDeleteOneProductArgs = {
  input: DeleteOneProductInput;
};

export type MutationDeleteOnePromotionalEntitlementArgs = {
  input: DeleteOnePromotionalEntitlementInput;
};

export type MutationDetachCustomerPaymentMethodArgs = {
  input: DetachCustomerPaymentMethodInput;
};

export type MutationDuplicateProductArgs = {
  input: DuplicateProductInput;
};

export type MutationEditPackageGroupArgs = {
  input: EditPackageGroupDetailsInput;
};

export type MutationEstimateSubscriptionArgs = {
  input: EstimateSubscriptionInput;
};

export type MutationEstimateSubscriptionUpdateArgs = {
  input: EstimateSubscriptionUpdateInput;
};

export type MutationGrantPromotionalEntitlementsArgs = {
  input: GrantPromotionalEntitlementsInput;
};

export type MutationHideGettingStartedPageArgs = {
  memberId: Scalars['String']['input'];
};

export type MutationImportCustomersBulkArgs = {
  input: ImportCustomerBulkInput;
};

export type MutationImportOneCustomerArgs = {
  input: ImportCustomerInput;
};

export type MutationImportSubscriptionsBulkArgs = {
  input: ImportSubscriptionsBulkInput;
};

export type MutationInitAddStripeCustomerPaymentMethodArgs = {
  input: InitAddStripeCustomerPaymentMethodInput;
};

export type MutationInviteMembersArgs = {
  input: InviteMembersInput;
};

export type MutationMarkInvoiceAsPaidArgs = {
  input: MarkInvoiceAsPaidInput;
};

export type MutationMergeEnvironmentArgs = {
  input: MergeEnvironmentInput;
};

export type MutationMigrateSubscriptionToLatestArgs = {
  input: SubscriptionMigrationInput;
};

export type MutationPreparePaymentMethodFormArgs = {
  input: PreparePaymentMethodFormInput;
};

export type MutationPreviewNextInvoiceArgs = {
  input: PreviewNextInvoiceInput;
};

export type MutationPreviewSubscriptionArgs = {
  input: PreviewSubscriptionInput;
};

export type MutationProvisionCustomerArgs = {
  input: ProvisionCustomerInput;
};

export type MutationProvisionSandboxArgs = {
  input: ProvisionSandboxInput;
};

export type MutationProvisionSubscriptionArgs = {
  input: ProvisionSubscription;
};

export type MutationProvisionSubscriptionV2Args = {
  input: ProvisionSubscriptionInput;
};

export type MutationPublishAddonArgs = {
  input: PackagePublishInput;
};

export type MutationPublishPlanArgs = {
  input: PackagePublishInput;
};

export type MutationPurgeCustomerCacheArgs = {
  input: ClearCustomerPersistentCacheInput;
};

export type MutationRecalculateEntitlementsArgs = {
  input: RecalculateEntitlementsInput;
};

export type MutationRemoveAddonDraftArgs = {
  input: DiscardPackageDraftInput;
};

export type MutationRemoveBasePlanFromPlanArgs = {
  input: RemoveBasePlanFromPlanInput;
};

export type MutationRemoveCompatibleAddonsFromPlanArgs = {
  input: RemoveCompatibleAddonsFromPlanInput;
};

export type MutationRemoveCouponFromCustomerArgs = {
  input: RemoveCouponFromCustomerInput;
};

export type MutationRemoveExperimentFromCustomerArgs = {
  input: RemoveExperimentFromCustomerInput;
};

export type MutationRemoveExperimentFromCustomerSubscriptionArgs = {
  input: RemoveExperimentFromCustomerSubscriptionInput;
};

export type MutationRemoveMemberArgs = {
  memberId: Scalars['String']['input'];
};

export type MutationRemovePlanDraftArgs = {
  input: DiscardPackageDraftInput;
};

export type MutationReportEntitlementCheckRequestedArgs = {
  entitlementCheckRequested: EntitlementCheckRequested;
};

export type MutationReportEventArgs = {
  events: UsageEventsReportInput;
};

export type MutationReportUsageArgs = {
  input: ReportUsageInput;
};

export type MutationReportUsageBulkArgs = {
  input: ReportUsageBulkInput;
};

export type MutationResyncIntegrationArgs = {
  input: ResyncIntegrationInput;
};

export type MutationRevokePromotionalEntitlementArgs = {
  input: RevokePromotionalEntitlementInput;
};

export type MutationSetAccessRolesArgs = {
  setAccessRolesInput: SetAccessRolesInput;
};

export type MutationSetBasePlanOnPlanArgs = {
  input: SetBasePlanOnPlanInput;
};

export type MutationSetCompatibleAddonsOnPlanArgs = {
  input: SetCompatibleAddonsOnPlanInput;
};

export type MutationSetCompatiblePackageGroupsArgs = {
  input: SetPlanCompatiblePackageGroups;
};

export type MutationSetCouponOnCustomerArgs = {
  input: SetCouponOnCustomerInput;
};

export type MutationSetExperimentOnCustomerArgs = {
  input: SetExperimentOnCustomerInput;
};

export type MutationSetExperimentOnCustomerSubscriptionArgs = {
  input: SetExperimentOnCustomerSubscriptionInput;
};

export type MutationSetPackageGroupAddonsArgs = {
  input: SetPackageGroupAddons;
};

export type MutationSetPackagePricingArgs = {
  input: PackagePricingInput;
};

export type MutationSetWidgetConfigurationArgs = {
  input: WidgetConfigurationUpdateInput;
};

export type MutationStartExperimentArgs = {
  input: StartExperimentInput;
};

export type MutationStopExperimentArgs = {
  input: StopExperimentInput;
};

export type MutationSubscriptionMaximumSpendArgs = {
  input: PreviewNextInvoiceInput;
};

export type MutationSyncTaxRatesArgs = {
  input: SyncTaxRatesInput;
};

export type MutationTransferSubscriptionArgs = {
  input: TransferSubscriptionInput;
};

export type MutationTransferSubscriptionToResourceArgs = {
  input: TransferSubscriptionToResourceInput;
};

export type MutationTriggerImportCatalogArgs = {
  input: ImportIntegrationCatalogInput;
};

export type MutationTriggerImportCustomersArgs = {
  input: ImportIntegrationCustomersInput;
};

export type MutationTriggerPlanSubscriptionMigrationArgs = {
  input: TriggerSubscriptionMigrationInput;
};

export type MutationTriggerSubscriptionBillingMonthEndsSoonWebhookArgs = {
  input: TriggerSubscriptionBillingMonthEndsSoonWebhookInput;
};

export type MutationTriggerSubscriptionUsageSyncArgs = {
  input: TriggerSubscriptionUsageSyncInput;
};

export type MutationUnarchiveCustomerArgs = {
  input: UnarchiveCustomerInput;
};

export type MutationUnarchiveEnvironmentArgs = {
  input: UnarchiveEnvironmentInput;
};

export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};

export type MutationUpdateEntitlementsOrderArgs = {
  input: UpdatePackageEntitlementOrderInput;
};

export type MutationUpdateFeatureArgs = {
  input: UpdateFeatureInput;
};

export type MutationUpdateOneAddonArgs = {
  input: AddonUpdateInput;
};

export type MutationUpdateOneCouponArgs = {
  input: UpdateCouponInput;
};

export type MutationUpdateOneCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateOneEnvironmentArgs = {
  input: UpdateOneEnvironmentInput;
};

export type MutationUpdateOneExperimentArgs = {
  input: UpdateExperimentInput;
};

export type MutationUpdateOneHookArgs = {
  input: UpdateOneHookInput;
};

export type MutationUpdateOneIntegrationArgs = {
  input: UpdateOneIntegrationInput;
};

export type MutationUpdateOnePackageEntitlementArgs = {
  input: UpdateOnePackageEntitlementInput;
};

export type MutationUpdateOnePlanArgs = {
  input: PlanUpdateInput;
};

export type MutationUpdateOneProductArgs = {
  input: UpdateOneProductInput;
};

export type MutationUpdateOnePromotionalEntitlementArgs = {
  input: UpdateOnePromotionalEntitlementInput;
};

export type MutationUpdateOneSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type NumberChange = {
  __typename?: 'NumberChange';
  /** The value after the change */
  after?: Maybe<Scalars['Float']['output']>;
  /** The value before the change */
  before?: Maybe<Scalars['Float']['output']>;
  /** The change type */
  changeType?: Maybe<ChangeType>;
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

export type OpenFgaCredentials = {
  __typename?: 'OpenFGACredentials';
  apiAudience: Scalars['String']['output'];
  apiTokenIssuer: Scalars['String']['output'];
  apiUrl: Scalars['String']['output'];
  clientId: Scalars['String']['output'];
  modelId: Scalars['String']['output'];
  storeId: Scalars['String']['output'];
};

export type OpenFgaCredentialsInput = {
  apiAudience: Scalars['String']['input'];
  apiTokenIssuer: Scalars['String']['input'];
  apiUrl: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  storeId?: InputMaybe<Scalars['String']['input']>;
};

/** Configures when the overage charge is billed. */
export enum OverageBillingPeriod {
  Monthly = 'MONTHLY',
  OnSubscriptionRenewal = 'ON_SUBSCRIPTION_RENEWAL',
}

export type OverageBillingPeriodChange = {
  __typename?: 'OverageBillingPeriodChange';
  after?: Maybe<OverageBillingPeriod>;
  before?: Maybe<OverageBillingPeriod>;
  changeType?: Maybe<ChangeType>;
};

export type OverageEntitlementCreateInput = {
  /** The behavior of the entitlement */
  behavior?: InputMaybe<EntitlementBehavior>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayNameOverride?: InputMaybe<Scalars['String']['input']>;
  featureId: Scalars['String']['input'];
  hasSoftLimit?: InputMaybe<Scalars['Boolean']['input']>;
  hasUnlimitedUsage?: InputMaybe<Scalars['Boolean']['input']>;
  hiddenFromWidgets?: InputMaybe<Array<WidgetType>>;
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  order?: InputMaybe<Scalars['Float']['input']>;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars['Float']['input']>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
  yearlyResetPeriodConfiguration?: InputMaybe<YearlyResetPeriodConfigInput>;
};

export type OveragePricingModelCreateInput = {
  billingCadence?: InputMaybe<BillingCadence>;
  billingModel: BillingModel;
  entitlement?: InputMaybe<OverageEntitlementCreateInput>;
  featureId?: InputMaybe<Scalars['String']['input']>;
  pricePeriods: Array<PricePeriodInput>;
};

export type PackageAlreadyPublishedError = {
  __typename?: 'PackageAlreadyPublishedError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type PackageChanges = {
  __typename?: 'PackageChanges';
  additionalMetaData?: Maybe<AdditionalMetaDataChange>;
  basePlan?: Maybe<BasePlanChange>;
  compatibleAddons?: Maybe<Array<PlanCompatibleAddonChange>>;
  /** Package groups */
  compatiblePackageGroups?: Maybe<Array<PlanCompatiblePackageGroupChange>>;
  defaultTrialConfig?: Maybe<DefaultTrialConfigChange>;
  /** Addon dependencies */
  dependencies?: Maybe<Array<AddonDependencyChange>>;
  description?: Maybe<StringChangeDto>;
  displayName?: Maybe<StringChangeDto>;
  entitlements: Array<PackageEntitlementChange>;
  hiddenFromWidgets?: Maybe<HiddenFromWidgetsChange>;
  /** Max quantity for an addon */
  maxQuantity?: Maybe<NumberChange>;
  /** Minimum spend limit */
  minimumSpend?: Maybe<Array<MinimumSpendChange>>;
  /** Configures when the overage charge is billed. */
  overageBillingPeriod?: Maybe<OverageBillingPeriodChange>;
  overagePrices: Array<PackagePriceChange>;
  prices: Array<PackagePriceChange>;
  pricingType?: Maybe<PricingTypeChange>;
  totalChanges: Scalars['Int']['output'];
};

export type PackageDto = {
  __typename?: 'PackageDTO';
  additionalMetaData?: Maybe<Scalars['JSON']['output']>;
  billingId?: Maybe<Scalars['String']['output']>;
  billingLinkUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  draftDetails?: Maybe<PackageDraftDetails>;
  draftSummary?: Maybe<PackageDraftSummary>;
  entitlements?: Maybe<Array<PackageEntitlement>>;
  environmentId: Scalars['String']['output'];
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  id: Scalars['String']['output'];
  isLatest?: Maybe<Scalars['Boolean']['output']>;
  overageBillingPeriod?: Maybe<OverageBillingPeriod>;
  overagePrices?: Maybe<Array<Price>>;
  prices?: Maybe<Array<Price>>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars['String']['output']>;
  refId: Scalars['String']['output'];
  status: PackageStatus;
  syncStates: Array<SyncState>;
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionNumber: Scalars['Int']['output'];
};

export type PackageDtoFilter = {
  and?: InputMaybe<Array<PackageDtoFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PackageDtoFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type PackageDtoSort = {
  direction: SortDirection;
  field: PackageDtoSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PackageDtoSortFields {
  BillingId = 'billingId',
  CreatedAt = 'createdAt',
  Description = 'description',
  DisplayName = 'displayName',
  EnvironmentId = 'environmentId',
  Id = 'id',
  IsLatest = 'isLatest',
  PricingType = 'pricingType',
  ProductId = 'productId',
  RefId = 'refId',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  VersionNumber = 'versionNumber',
}

export type PackageDraftDetails = {
  __typename?: 'PackageDraftDetails';
  affectedChildPlans?: Maybe<Array<Plan>>;
  changes?: Maybe<PackageChanges>;
  childPlansWithDraft?: Maybe<Array<Plan>>;
  customersAffected?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};

export type PackageDraftSummary = {
  __typename?: 'PackageDraftSummary';
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};

export type PackageEntitlement = {
  __typename?: 'PackageEntitlement';
  /** The behavior of the entitlement */
  behavior: EntitlementBehavior;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayNameOverride?: Maybe<Scalars['String']['output']>;
  environmentId: Scalars['String']['output'];
  feature: Feature;
  featureId: Scalars['String']['output'];
  hasSoftLimit?: Maybe<Scalars['Boolean']['output']>;
  hasUnlimitedUsage?: Maybe<Scalars['Boolean']['output']>;
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  id: Scalars['String']['output'];
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  meter?: Maybe<Meter>;
  order?: Maybe<Scalars['Float']['output']>;
  package?: Maybe<PackageDto>;
  packageId: Scalars['String']['output'];
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

export type PackageEntitlementAggregateGroupBy = {
  __typename?: 'PackageEntitlementAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PackageEntitlementChange = {
  __typename?: 'PackageEntitlementChange';
  /** The value after the change */
  after?: Maybe<PackageEntitlement>;
  /** The value before the change */
  before?: Maybe<PackageEntitlement>;
  /** The change type */
  changeType?: Maybe<ChangeType>;
};

export type PackageEntitlementConnection = {
  __typename?: 'PackageEntitlementConnection';
  /** Array of edges. */
  edges: Array<PackageEntitlementEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type PackageEntitlementCountAggregate = {
  __typename?: 'PackageEntitlementCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  packageId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type PackageEntitlementDeleteResponse = {
  __typename?: 'PackageEntitlementDeleteResponse';
  /** The behavior of the entitlement */
  behavior?: Maybe<EntitlementBehavior>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayNameOverride?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  featureId?: Maybe<Scalars['String']['output']>;
  hasSoftLimit?: Maybe<Scalars['Boolean']['output']>;
  hasUnlimitedUsage?: Maybe<Scalars['Boolean']['output']>;
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  id?: Maybe<Scalars['String']['output']>;
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

export type PackageEntitlementEdge = {
  __typename?: 'PackageEntitlementEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the PackageEntitlement */
  node: PackageEntitlement;
};

export type PackageEntitlementFilter = {
  and?: InputMaybe<Array<PackageEntitlementFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  feature?: InputMaybe<PackageEntitlementFilterFeatureFilter>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PackageEntitlementFilter>>;
  package?: InputMaybe<PackageEntitlementFilterPackageDtoFilter>;
  packageId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PackageEntitlementFilterFeatureFilter = {
  and?: InputMaybe<Array<PackageEntitlementFilterFeatureFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  featureStatus?: InputMaybe<FeatureStatusFilterComparison>;
  featureType?: InputMaybe<FeatureTypeFilterComparison>;
  id?: InputMaybe<StringFieldComparison>;
  meterType?: InputMaybe<MeterTypeFilterComparison>;
  or?: InputMaybe<Array<PackageEntitlementFilterFeatureFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PackageEntitlementFilterPackageDtoFilter = {
  and?: InputMaybe<Array<PackageEntitlementFilterPackageDtoFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PackageEntitlementFilterPackageDtoFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type PackageEntitlementInput = {
  /** The behavior of the entitlement */
  behavior?: InputMaybe<EntitlementBehavior>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayNameOverride?: InputMaybe<Scalars['String']['input']>;
  environmentId: Scalars['String']['input'];
  featureId: Scalars['String']['input'];
  hasSoftLimit?: InputMaybe<Scalars['Boolean']['input']>;
  hasUnlimitedUsage?: InputMaybe<Scalars['Boolean']['input']>;
  hiddenFromWidgets?: InputMaybe<Array<WidgetType>>;
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  order?: InputMaybe<Scalars['Float']['input']>;
  packageId: Scalars['String']['input'];
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars['Float']['input']>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
  yearlyResetPeriodConfiguration?: InputMaybe<YearlyResetPeriodConfigInput>;
};

export type PackageEntitlementMaxAggregate = {
  __typename?: 'PackageEntitlementMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PackageEntitlementMinAggregate = {
  __typename?: 'PackageEntitlementMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PackageEntitlementSort = {
  direction: SortDirection;
  field: PackageEntitlementSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PackageEntitlementSortFields {
  CreatedAt = 'createdAt',
  EnvironmentId = 'environmentId',
  Id = 'id',
  PackageId = 'packageId',
  UpdatedAt = 'updatedAt',
}

export type PackageEntitlementUpdateInput = {
  /** The behavior of the entitlement */
  behavior?: InputMaybe<EntitlementBehavior>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayNameOverride?: InputMaybe<Scalars['String']['input']>;
  hasSoftLimit?: InputMaybe<Scalars['Boolean']['input']>;
  hasUnlimitedUsage?: InputMaybe<Scalars['Boolean']['input']>;
  hiddenFromWidgets?: InputMaybe<Array<WidgetType>>;
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  order?: InputMaybe<Scalars['Float']['input']>;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars['Float']['input']>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
  yearlyResetPeriodConfiguration?: InputMaybe<YearlyResetPeriodConfigInput>;
};

/** Package group */
export type PackageGroup = {
  __typename?: 'PackageGroup';
  addons?: Maybe<Array<Addon>>;
  /** The date the package group was created */
  createdAt: Scalars['DateTime']['output'];
  /** The description of the package group */
  description?: Maybe<Scalars['String']['output']>;
  /** The display name of the package group */
  displayName: Scalars['String']['output'];
  /** The environment id of the package group in */
  environmentId: Scalars['String']['output'];
  /** Indicates if this is the latest version of the package group */
  isLatest: Scalars['Boolean']['output'];
  /** The id of the package group */
  packageGroupId: Scalars['String']['output'];
  product: Product;
  productId?: Maybe<Scalars['String']['output']>;
  /** The status of the package group */
  status: PackageGroupStatus;
  /** The date the package group was last updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The version number of the package group */
  versionNumber: Scalars['Int']['output'];
};

/** Package group */
export type PackageGroupAddonsArgs = {
  filter?: InputMaybe<AddonFilter>;
  sorting?: InputMaybe<Array<AddonSort>>;
};

export type PackageGroupAggregateGroupBy = {
  __typename?: 'PackageGroupAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  isLatest?: Maybe<Scalars['Boolean']['output']>;
  packageGroupId?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PackageGroupStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type PackageGroupAvgAggregate = {
  __typename?: 'PackageGroupAvgAggregate';
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type PackageGroupConnection = {
  __typename?: 'PackageGroupConnection';
  /** Array of edges. */
  edges: Array<PackageGroupEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type PackageGroupCountAggregate = {
  __typename?: 'PackageGroupCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  displayName?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  isLatest?: Maybe<Scalars['Int']['output']>;
  packageGroupId?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type PackageGroupEdge = {
  __typename?: 'PackageGroupEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the PackageGroup */
  node: PackageGroup;
};

export type PackageGroupFilter = {
  and?: InputMaybe<Array<PackageGroupFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PackageGroupFilter>>;
  packageGroupId?: InputMaybe<StringFieldComparison>;
  product?: InputMaybe<PackageGroupFilterProductFilter>;
  productId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageGroupStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type PackageGroupFilterProductFilter = {
  and?: InputMaybe<Array<PackageGroupFilterProductFilter>>;
  awsMarketplaceProductCode?: InputMaybe<StringFieldComparison>;
  awsMarketplaceProductId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isDefaultProduct?: InputMaybe<BooleanFieldComparison>;
  multipleSubscriptions?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PackageGroupFilterProductFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PackageGroupMaxAggregate = {
  __typename?: 'PackageGroupMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  packageGroupId?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PackageGroupStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type PackageGroupMinAggregate = {
  __typename?: 'PackageGroupMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  packageGroupId?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PackageGroupStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type PackageGroupSort = {
  direction: SortDirection;
  field: PackageGroupSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PackageGroupSortFields {
  CreatedAt = 'createdAt',
  DisplayName = 'displayName',
  EnvironmentId = 'environmentId',
  IsLatest = 'isLatest',
  PackageGroupId = 'packageGroupId',
  ProductId = 'productId',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  VersionNumber = 'versionNumber',
}

/** Package group status. */
export enum PackageGroupStatus {
  /** The package group is archived. */
  Archived = 'ARCHIVED',
  /** The package group is a draft. */
  Draft = 'DRAFT',
  /** The package group is published. */
  Published = 'PUBLISHED',
}

export type PackageGroupStatusFilterComparison = {
  eq?: InputMaybe<PackageGroupStatus>;
  gt?: InputMaybe<PackageGroupStatus>;
  gte?: InputMaybe<PackageGroupStatus>;
  iLike?: InputMaybe<PackageGroupStatus>;
  in?: InputMaybe<Array<PackageGroupStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<PackageGroupStatus>;
  lt?: InputMaybe<PackageGroupStatus>;
  lte?: InputMaybe<PackageGroupStatus>;
  neq?: InputMaybe<PackageGroupStatus>;
  notILike?: InputMaybe<PackageGroupStatus>;
  notIn?: InputMaybe<Array<PackageGroupStatus>>;
  notLike?: InputMaybe<PackageGroupStatus>;
};

export type PackageGroupSumAggregate = {
  __typename?: 'PackageGroupSumAggregate';
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type PackagePrice = {
  __typename?: 'PackagePrice';
  packageId: Scalars['String']['output'];
  pricingType: PricingType;
};

export type PackagePriceChange = {
  __typename?: 'PackagePriceChange';
  /** The value after the change */
  after?: Maybe<Price>;
  /** The value before the change */
  before?: Maybe<Price>;
  /** The change type */
  changeType?: Maybe<ChangeType>;
};

export type PackagePricingInput = {
  environmentId: Scalars['String']['input'];
  /** The minimum spend configuration per each billing period */
  minimumSpend?: InputMaybe<Array<MinimumSpendInput>>;
  overageBillingPeriod?: InputMaybe<OverageBillingPeriod>;
  overagePricingModels?: InputMaybe<Array<OveragePricingModelCreateInput>>;
  packageId: Scalars['String']['input'];
  pricingModel?: InputMaybe<PricingModelCreateInput>;
  pricingModels?: InputMaybe<Array<PricingModelCreateInput>>;
  pricingType: PricingType;
};

export type PackagePricingTypeNotSetError = {
  __typename?: 'PackagePricingTypeNotSetError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  refId: Scalars['String']['output'];
};

export type PackagePublishInput = {
  id: Scalars['ID']['input'];
  migrationType: PublishMigrationType;
};

export type PackagePublished = {
  __typename?: 'PackagePublished';
  accountId: Scalars['String']['output'];
  environmentId: Scalars['String']['output'];
  migrationType: PublishMigrationType;
  packageRefId: Scalars['String']['output'];
  packageType: Scalars['String']['output'];
  packageVersion: Scalars['Int']['output'];
};

/** Package status. */
export enum PackageStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type PackageStatusFilterComparison = {
  eq?: InputMaybe<PackageStatus>;
  gt?: InputMaybe<PackageStatus>;
  gte?: InputMaybe<PackageStatus>;
  iLike?: InputMaybe<PackageStatus>;
  in?: InputMaybe<Array<PackageStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<PackageStatus>;
  lt?: InputMaybe<PackageStatus>;
  lte?: InputMaybe<PackageStatus>;
  neq?: InputMaybe<PackageStatus>;
  notILike?: InputMaybe<PackageStatus>;
  notIn?: InputMaybe<Array<PackageStatus>>;
  notLike?: InputMaybe<PackageStatus>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
};

/** Payment collection */
export enum PaymentCollection {
  ActionRequired = 'ACTION_REQUIRED',
  Failed = 'FAILED',
  NotRequired = 'NOT_REQUIRED',
  Processing = 'PROCESSING',
}

export type PaymentCollectionFilterComparison = {
  eq?: InputMaybe<PaymentCollection>;
  gt?: InputMaybe<PaymentCollection>;
  gte?: InputMaybe<PaymentCollection>;
  iLike?: InputMaybe<PaymentCollection>;
  in?: InputMaybe<Array<PaymentCollection>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<PaymentCollection>;
  lt?: InputMaybe<PaymentCollection>;
  lte?: InputMaybe<PaymentCollection>;
  neq?: InputMaybe<PaymentCollection>;
  notILike?: InputMaybe<PaymentCollection>;
  notIn?: InputMaybe<Array<PaymentCollection>>;
  notLike?: InputMaybe<PaymentCollection>;
};

/** The method used to collect payments for a subscription */
export enum PaymentCollectionMethod {
  /** Automatically charge the payment method on file */
  Charge = 'CHARGE',
  /** Generate an invoice to the customer to pay manually */
  Invoice = 'INVOICE',
  /** Do not leverage Stigg to manage payments for this subscription */
  None = 'NONE',
}

export type PaymentMethodForm = StripePaymentMethodForm | ZuoraPaymentMethodForm;

/** Type of a payment method */
export enum PaymentMethodType {
  Bank = 'BANK',
  Card = 'CARD',
}

export type Paywall = {
  __typename?: 'Paywall';
  activeSubscriptions?: Maybe<Array<CustomerSubscription>>;
  configuration?: Maybe<PaywallConfiguration>;
  currency: PaywallCurrency;
  customer?: Maybe<Customer>;
  paywallCalculatedPricePoints?: Maybe<Array<PaywallPricePoint>>;
  plans: Array<Plan>;
  resource?: Maybe<CustomerResource>;
};

export type PaywallAddon = {
  __typename?: 'PaywallAddon';
  additionalMetaData?: Maybe<Scalars['JSON']['output']>;
  billingId?: Maybe<Scalars['String']['output']>;
  /** List of addons this addon is dependant on */
  dependencies?: Maybe<Array<PaywallAddon>>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  entitlements: Array<Entitlement>;
  /** The maximum quantity of this addon that can be added to a subscription */
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  prices: Array<PaywallPrice>;
  pricingType?: Maybe<PricingType>;
  refId: Scalars['String']['output'];
};

export type PaywallBasePlan = {
  __typename?: 'PaywallBasePlan';
  displayName: Scalars['String']['output'];
  refId: Scalars['String']['output'];
};

export type PaywallColorsPalette = {
  __typename?: 'PaywallColorsPalette';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  borderColor?: Maybe<Scalars['String']['output']>;
  currentPlanBackground?: Maybe<Scalars['String']['output']>;
  primary?: Maybe<Scalars['String']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
};

export type PaywallColorsPaletteInput = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  borderColor?: InputMaybe<Scalars['String']['input']>;
  currentPlanBackground?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['String']['input']>;
  textColor?: InputMaybe<Scalars['String']['input']>;
};

export type PaywallConfiguration = {
  __typename?: 'PaywallConfiguration';
  customCss?: Maybe<Scalars['String']['output']>;
  layout?: Maybe<PaywallLayoutConfiguration>;
  palette?: Maybe<PaywallColorsPalette>;
  typography?: Maybe<TypographyConfiguration>;
};

export type PaywallConfigurationInput = {
  customCss?: InputMaybe<Scalars['String']['input']>;
  layout?: InputMaybe<PaywallLayoutConfigurationInput>;
  palette?: InputMaybe<PaywallColorsPaletteInput>;
  typography?: InputMaybe<TypographyConfigurationInput>;
};

export type PaywallCurrency = {
  __typename?: 'PaywallCurrency';
  code: Currency;
  symbol: Scalars['String']['output'];
};

export type PaywallLayoutConfiguration = {
  __typename?: 'PaywallLayoutConfiguration';
  alignment?: Maybe<Alignment>;
  planMargin?: Maybe<Scalars['Float']['output']>;
  planPadding?: Maybe<Scalars['Float']['output']>;
  planWidth?: Maybe<Scalars['Float']['output']>;
};

export type PaywallLayoutConfigurationInput = {
  alignment?: InputMaybe<Alignment>;
  planMargin?: InputMaybe<Scalars['Float']['input']>;
  planPadding?: InputMaybe<Scalars['Float']['input']>;
  planWidth?: InputMaybe<Scalars['Float']['input']>;
};

export type PaywallPlan = {
  __typename?: 'PaywallPlan';
  additionalMetaData?: Maybe<Scalars['JSON']['output']>;
  basePlan?: Maybe<PaywallBasePlan>;
  billingId?: Maybe<Scalars['String']['output']>;
  compatibleAddons?: Maybe<Array<PaywallAddon>>;
  /** Plan's compatible package groups */
  compatiblePackageGroups?: Maybe<Array<PaywallPlanCompatiblePackageGroup>>;
  defaultTrialConfig?: Maybe<DefaultTrialConfig>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  entitlements?: Maybe<Array<Entitlement>>;
  inheritedEntitlements?: Maybe<Array<Entitlement>>;
  /** Minimum spend configuration */
  minimumSpend?: Maybe<Array<MinimumSpend>>;
  prices: Array<PaywallPrice>;
  pricingType?: Maybe<PricingType>;
  product: PaywallProduct;
  refId: Scalars['String']['output'];
};

export type PaywallPlanCompatiblePackageGroup = {
  __typename?: 'PaywallPlanCompatiblePackageGroup';
  /** Included addons in the package group */
  addons?: Maybe<Array<PaywallAddon>>;
  /** The description of the package group */
  description?: Maybe<Scalars['String']['output']>;
  /** The display name of the package group */
  displayName: Scalars['String']['output'];
  /** Plan compatible package group options */
  options: PaywallPlanCompatiblePackageGroupOptions;
  /** The id of the package group */
  packageGroupId: Scalars['String']['output'];
};

/** Plan compatible package group options */
export type PaywallPlanCompatiblePackageGroupOptions = {
  __typename?: 'PaywallPlanCompatiblePackageGroupOptions';
  /** Number of free items from package group */
  freeItems?: Maybe<Scalars['Float']['output']>;
  /** Number of required items from packageGroup */
  minItems?: Maybe<Scalars['Float']['output']>;
};

export type PaywallPrice = {
  __typename?: 'PaywallPrice';
  billingCountryCode?: Maybe<Scalars['String']['output']>;
  billingId?: Maybe<Scalars['String']['output']>;
  billingModel: BillingModel;
  billingPeriod: BillingPeriod;
  /** The number of units per block. Defaults to 1 unit. */
  blockSize?: Maybe<Scalars['Float']['output']>;
  feature?: Maybe<EntitlementFeature>;
  featureId?: Maybe<Scalars['String']['output']>;
  maxUnitQuantity?: Maybe<Scalars['Float']['output']>;
  minUnitQuantity?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Money>;
  tiers?: Maybe<Array<PriceTier>>;
  tiersMode?: Maybe<TiersMode>;
};

export type PaywallPricePoint = {
  __typename?: 'PaywallPricePoint';
  additionalChargesMayApply?: Maybe<Scalars['Boolean']['output']>;
  amount: Scalars['Float']['output'];
  billingCountryCode?: Maybe<Scalars['String']['output']>;
  billingPeriod: BillingPeriod;
  currency: Currency;
  feature?: Maybe<Feature>;
  planId: Scalars['String']['output'];
};

export type PaywallProduct = {
  __typename?: 'PaywallProduct';
  additionalMetaData?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  refId: Scalars['String']['output'];
};

export type Plan = {
  __typename?: 'Plan';
  additionalMetaData?: Maybe<Scalars['JSON']['output']>;
  awsMarketplacePlanDimension?: Maybe<Scalars['String']['output']>;
  basePlan?: Maybe<Plan>;
  billingId?: Maybe<Scalars['String']['output']>;
  billingLinkUrl?: Maybe<Scalars['String']['output']>;
  compatibleAddons?: Maybe<Array<Addon>>;
  compatiblePackageGroups?: Maybe<Array<PlanCompatiblePackageGroups>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  defaultTrialConfig?: Maybe<DefaultTrialConfig>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  draftDetails?: Maybe<PackageDraftDetails>;
  draftSummary?: Maybe<PackageDraftSummary>;
  entitlements?: Maybe<Array<PackageEntitlement>>;
  environment: Environment;
  environmentId: Scalars['String']['output'];
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  id: Scalars['String']['output'];
  inheritedEntitlements?: Maybe<Array<PackageEntitlement>>;
  isLatest?: Maybe<Scalars['Boolean']['output']>;
  isParent: Scalars['Boolean']['output'];
  /** Minimum spend configuration */
  minimumSpend?: Maybe<Array<MinimumSpend>>;
  overageBillingPeriod?: Maybe<OverageBillingPeriod>;
  overagePrices?: Maybe<Array<Price>>;
  prices?: Maybe<Array<Price>>;
  pricingType?: Maybe<PricingType>;
  product: Product;
  productId?: Maybe<Scalars['String']['output']>;
  refId: Scalars['String']['output'];
  status: PackageStatus;
  syncStates?: Maybe<Array<SyncState>>;
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionNumber: Scalars['Int']['output'];
};

export type PlanCompatibleAddonsArgs = {
  filter?: InputMaybe<AddonFilter>;
  sorting?: InputMaybe<Array<AddonSort>>;
};

export type PlanInheritedEntitlementsArgs = {
  includeOverridden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PlanAggregateGroupBy = {
  __typename?: 'PlanAggregateGroupBy';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isLatest?: Maybe<Scalars['Boolean']['output']>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type PlanAvgAggregate = {
  __typename?: 'PlanAvgAggregate';
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type PlanChangeAddon = {
  __typename?: 'PlanChangeAddon';
  addonRefId: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
};

/** Type of the change between two plans of the same subscription */
export enum PlanChangeType {
  /** Subscription downgrade to lower plan */
  Downgrade = 'DOWNGRADE',
  /** Subscription remains on the same plan */
  None = 'NONE',
  /** Subscription upgrade to higher plan */
  Upgrade = 'UPGRADE',
}

export type PlanChangeVariables = {
  __typename?: 'PlanChangeVariables';
  addons?: Maybe<Array<PlanChangeAddon>>;
  billableFeatures?: Maybe<Array<BillableFeature>>;
  billingPeriod?: Maybe<BillingPeriod>;
  changeType: PlanChangeType;
  planRefId: Scalars['String']['output'];
  priceOverrides?: Maybe<Array<PriceOverrideChangeVariables>>;
};

export type PlanCompatibleAddonChange = {
  __typename?: 'PlanCompatibleAddonChange';
  after?: Maybe<Addon>;
  before?: Maybe<Addon>;
  changeType?: Maybe<ChangeType>;
};

/** Package group changed */
export type PlanCompatiblePackageGroupChange = {
  __typename?: 'PlanCompatiblePackageGroupChange';
  /** Package group after change */
  after?: Maybe<PackageGroup>;
  /** Package group before change */
  before?: Maybe<PackageGroup>;
  /** Type of change */
  changeType?: Maybe<ChangeType>;
};

/** Plan compatible package group options */
export type PlanCompatiblePackageGroupOptions = {
  __typename?: 'PlanCompatiblePackageGroupOptions';
  /** Number of free items from package group */
  freeItems?: Maybe<Scalars['Float']['output']>;
  /** Number of required items from package group */
  minItems?: Maybe<Scalars['Float']['output']>;
};

/** Plan compatible package group */
export type PlanCompatiblePackageGroups = {
  __typename?: 'PlanCompatiblePackageGroups';
  /** The addons that are a part of this group */
  addons?: Maybe<Array<Addon>>;
  /** The date the package group was created */
  createdAt: Scalars['DateTime']['output'];
  /** The description of the package group */
  description?: Maybe<Scalars['String']['output']>;
  /** The display name of the package group */
  displayName: Scalars['String']['output'];
  /** The environment id of the package group in */
  environmentId: Scalars['String']['output'];
  /** Indicates if this is the latest version of the package group */
  isLatest: Scalars['Boolean']['output'];
  /** Plan compatible package group options */
  options: PlanCompatiblePackageGroupOptions;
  /** The id of the package group */
  packageGroupId: Scalars['String']['output'];
  product: Product;
  productId?: Maybe<Scalars['String']['output']>;
  /** The status of the package group */
  status: PackageGroupStatus;
  /** The date the package group was last updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The version number of the package group */
  versionNumber: Scalars['Int']['output'];
};

export type PlanConnection = {
  __typename?: 'PlanConnection';
  /** Array of edges. */
  edges: Array<PlanEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type PlanCountAggregate = {
  __typename?: 'PlanCountAggregate';
  billingId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['Int']['output']>;
  displayName?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isLatest?: Maybe<Scalars['Int']['output']>;
  pricingType?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  refId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type PlanCreateInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  awsMarketplacePlanDimension?: InputMaybe<Scalars['String']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
  hiddenFromWidgets?: InputMaybe<Array<WidgetType>>;
  parentPlanId?: InputMaybe<Scalars['String']['input']>;
  pricingType?: InputMaybe<PricingType>;
  productId: Scalars['String']['input'];
  refId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PackageStatus>;
};

export type PlanEdge = {
  __typename?: 'PlanEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Plan */
  node: Plan;
};

export type PlanFilter = {
  and?: InputMaybe<Array<PlanFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  compatibleAddons?: InputMaybe<PlanFilterAddonFilter>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PlanFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  product?: InputMaybe<PlanFilterProductFilter>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type PlanFilterAddonFilter = {
  and?: InputMaybe<Array<PlanFilterAddonFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PlanFilterAddonFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type PlanFilterProductFilter = {
  and?: InputMaybe<Array<PlanFilterProductFilter>>;
  awsMarketplaceProductCode?: InputMaybe<StringFieldComparison>;
  awsMarketplaceProductId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isDefaultProduct?: InputMaybe<BooleanFieldComparison>;
  multipleSubscriptions?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PlanFilterProductFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PlanMaxAggregate = {
  __typename?: 'PlanMaxAggregate';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type PlanMinAggregate = {
  __typename?: 'PlanMinAggregate';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type PlanNotFoundError = {
  __typename?: 'PlanNotFoundError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  refId: Scalars['String']['output'];
};

export type PlanPriceOverrideChangeVariables = {
  __typename?: 'PlanPriceOverrideChangeVariables';
  featureId?: Maybe<Scalars['String']['output']>;
  planRefId: Scalars['String']['output'];
};

export type PlanSort = {
  direction: SortDirection;
  field: PlanSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PlanSortFields {
  BillingId = 'billingId',
  CreatedAt = 'createdAt',
  Description = 'description',
  DisplayName = 'displayName',
  EnvironmentId = 'environmentId',
  Id = 'id',
  IsLatest = 'isLatest',
  PricingType = 'pricingType',
  ProductId = 'productId',
  RefId = 'refId',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  VersionNumber = 'versionNumber',
}

export type PlanSumAggregate = {
  __typename?: 'PlanSumAggregate';
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type PlanUpdateInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  defaultTrialConfig?: InputMaybe<DefaultTrialConfigInputDto>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  hiddenFromWidgets?: InputMaybe<Array<WidgetType>>;
  id: Scalars['String']['input'];
  /** The minimum spend configuration per each billing period */
  minimumSpend?: InputMaybe<Array<MinimumSpendInput>>;
  parentPlanId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PackageStatus>;
};

export type PreparePaymentMethodFormInput = {
  customerId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
};

export type PreparedPaymentMethodForm = {
  __typename?: 'PreparedPaymentMethodForm';
  paymentMethodForm: PaymentMethodForm;
  vendorIdentifier: VendorIdentifier;
};

/** Input to preview the next invoice for a subscription */
export type PreviewNextInvoiceInput = {
  /** The environment id of the subscription to preview */
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The subscription id to preview */
  subscriptionId: Scalars['String']['input'];
};

export type PreviewSubscriptionInput = {
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  /** Coupon input */
  appliedCoupon?: InputMaybe<SubscriptionCouponInput>;
  billableFeatures?: InputMaybe<Array<BillableFeatureInput>>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  billingInformation?: InputMaybe<SubscriptionBillingInfo>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  customerId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The customer that will pay for the subscription */
  payingCustomerId?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['String']['input'];
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  scheduleStrategy?: InputMaybe<ScheduleStrategy>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** trial configuration to override the default values from plan if exists */
  trialOverrideConfiguration?: InputMaybe<TrialOverrideConfigurationInput>;
  unitQuantity?: InputMaybe<Scalars['Float']['input']>;
};

export type Price = {
  __typename?: 'Price';
  billingCadence: BillingCadence;
  billingCountryCode?: Maybe<Scalars['String']['output']>;
  billingId?: Maybe<Scalars['String']['output']>;
  billingModel: BillingModel;
  billingPeriod: BillingPeriod;
  /** The number of units per block. Defaults to 1 unit. */
  blockSize?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crmId?: Maybe<Scalars['String']['output']>;
  crmLinkUrl?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  feature?: Maybe<Feature>;
  featureId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Indication whether the subscription price is an override price */
  isOverridePrice: Scalars['Boolean']['output'];
  maxUnitQuantity?: Maybe<Scalars['Float']['output']>;
  minUnitQuantity?: Maybe<Scalars['Float']['output']>;
  package: PackageDto;
  packageId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Money>;
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  tiers?: Maybe<Array<PriceTier>>;
  tiersMode?: Maybe<TiersMode>;
  usedInSubscriptions?: Maybe<Scalars['Boolean']['output']>;
};

export type PriceAggregateGroupBy = {
  __typename?: 'PriceAggregateGroupBy';
  billingCadence?: Maybe<BillingCadence>;
  billingId?: Maybe<Scalars['String']['output']>;
  billingModel?: Maybe<BillingModel>;
  billingPeriod?: Maybe<BillingPeriod>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  tiersMode?: Maybe<TiersMode>;
};

export type PriceCountAggregate = {
  __typename?: 'PriceCountAggregate';
  billingCadence?: Maybe<Scalars['Int']['output']>;
  billingId?: Maybe<Scalars['Int']['output']>;
  billingModel?: Maybe<Scalars['Int']['output']>;
  billingPeriod?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  tiersMode?: Maybe<Scalars['Int']['output']>;
};

export type PriceDeleteResponse = {
  __typename?: 'PriceDeleteResponse';
  billingCadence?: Maybe<BillingCadence>;
  billingCountryCode?: Maybe<Scalars['String']['output']>;
  billingId?: Maybe<Scalars['String']['output']>;
  billingModel?: Maybe<BillingModel>;
  billingPeriod?: Maybe<BillingPeriod>;
  /** The number of units per block. Defaults to 1 unit. */
  blockSize?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crmId?: Maybe<Scalars['String']['output']>;
  crmLinkUrl?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  feature?: Maybe<Feature>;
  featureId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxUnitQuantity?: Maybe<Scalars['Float']['output']>;
  minUnitQuantity?: Maybe<Scalars['Float']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Money>;
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  tiers?: Maybe<Array<PriceTier>>;
  tiersMode?: Maybe<TiersMode>;
  usedInSubscriptions?: Maybe<Scalars['Boolean']['output']>;
};

export type PriceEdge = {
  __typename?: 'PriceEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Price */
  node: Price;
};

export type PriceEntitlement = {
  __typename?: 'PriceEntitlement';
  description?: Maybe<Scalars['String']['output']>;
  feature: Feature;
  featureId: Scalars['String']['output'];
  hasSoftLimit?: Maybe<Scalars['Boolean']['output']>;
  hasUnlimitedUsage?: Maybe<Scalars['Boolean']['output']>;
  package: PackageDto;
  packageId: Scalars['String']['output'];
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

export type PriceFilter = {
  and?: InputMaybe<Array<PriceFilter>>;
  billingCadence?: InputMaybe<BillingCadenceFilterComparison>;
  billingId?: InputMaybe<StringFieldComparison>;
  billingModel?: InputMaybe<BillingModelFilterComparison>;
  billingPeriod?: InputMaybe<BillingPeriodFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PriceFilter>>;
  package?: InputMaybe<PriceFilterPackageDtoFilter>;
  tiersMode?: InputMaybe<TiersModeFilterComparison>;
};

export type PriceFilterPackageDtoFilter = {
  and?: InputMaybe<Array<PriceFilterPackageDtoFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PriceFilterPackageDtoFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type PriceMaxAggregate = {
  __typename?: 'PriceMaxAggregate';
  billingCadence?: Maybe<BillingCadence>;
  billingId?: Maybe<Scalars['String']['output']>;
  billingModel?: Maybe<BillingModel>;
  billingPeriod?: Maybe<BillingPeriod>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  tiersMode?: Maybe<TiersMode>;
};

export type PriceMinAggregate = {
  __typename?: 'PriceMinAggregate';
  billingCadence?: Maybe<BillingCadence>;
  billingId?: Maybe<Scalars['String']['output']>;
  billingModel?: Maybe<BillingModel>;
  billingPeriod?: Maybe<BillingPeriod>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  tiersMode?: Maybe<TiersMode>;
};

export type PriceNotFoundError = {
  __typename?: 'PriceNotFoundError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type PriceOverrideChangeVariables = {
  __typename?: 'PriceOverrideChangeVariables';
  addonRefId?: Maybe<Scalars['String']['output']>;
  featureId?: Maybe<Scalars['String']['output']>;
  planRefId?: Maybe<Scalars['String']['output']>;
};

export type PriceOverrideInput = {
  /** The corresponding addon id of the price */
  addonId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the price is a base charge */
  baseCharge?: InputMaybe<Scalars['Boolean']['input']>;
  /** The number of units per block. Defaults to 1 unit. */
  blockSize?: InputMaybe<Scalars['Float']['input']>;
  /** The corresponding feature id of the price */
  featureId?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<MoneyInputDto>;
  tiers?: InputMaybe<Array<PriceTierInput>>;
};

export type PricePeriodInput = {
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  billingPeriod: BillingPeriod;
  /** The number of units per block. Defaults to 1 unit. */
  blockSize?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<MoneyInputDto>;
  tiers?: InputMaybe<Array<PriceTierInput>>;
};

export type PriceSort = {
  direction: SortDirection;
  field: PriceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PriceSortFields {
  BillingCadence = 'billingCadence',
  BillingId = 'billingId',
  BillingModel = 'billingModel',
  BillingPeriod = 'billingPeriod',
  CreatedAt = 'createdAt',
  Id = 'id',
  TiersMode = 'tiersMode',
}

export type PriceTier = {
  __typename?: 'PriceTier';
  flatPrice?: Maybe<Money>;
  unitPrice?: Maybe<Money>;
  upTo?: Maybe<Scalars['Float']['output']>;
};

export type PriceTierInput = {
  flatPrice?: InputMaybe<MoneyInputDto>;
  unitPrice?: InputMaybe<MoneyInputDto>;
  upTo?: InputMaybe<Scalars['Float']['input']>;
};

export type PricingModelCreateInput = {
  billingCadence?: InputMaybe<BillingCadence>;
  billingModel: BillingModel;
  featureId?: InputMaybe<Scalars['String']['input']>;
  maxUnitQuantity?: InputMaybe<Scalars['Float']['input']>;
  minUnitQuantity?: InputMaybe<Scalars['Float']['input']>;
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  pricePeriods: Array<PricePeriodInput>;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  tiersMode?: InputMaybe<TiersMode>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
  yearlyResetPeriodConfiguration?: InputMaybe<YearlyResetPeriodConfigInput>;
};

/** Pricing Type. */
export enum PricingType {
  Custom = 'CUSTOM',
  Free = 'FREE',
  Paid = 'PAID',
}

export type PricingTypeChange = {
  __typename?: 'PricingTypeChange';
  after?: Maybe<PricingType>;
  before?: Maybe<PricingType>;
  changeType?: Maybe<ChangeType>;
};

export type PricingTypeFilterComparison = {
  eq?: InputMaybe<PricingType>;
  gt?: InputMaybe<PricingType>;
  gte?: InputMaybe<PricingType>;
  iLike?: InputMaybe<PricingType>;
  in?: InputMaybe<Array<PricingType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<PricingType>;
  lt?: InputMaybe<PricingType>;
  lte?: InputMaybe<PricingType>;
  neq?: InputMaybe<PricingType>;
  notILike?: InputMaybe<PricingType>;
  notIn?: InputMaybe<Array<PricingType>>;
  notLike?: InputMaybe<PricingType>;
};

/** A product object */
export type Product = {
  __typename?: 'Product';
  additionalMetaData?: Maybe<Scalars['JSON']['output']>;
  addons: Array<Addon>;
  autoCancellationRules: Array<AutoCancellationRule>;
  awsMarketplaceProductCode?: Maybe<Scalars['String']['output']>;
  awsMarketplaceProductId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  downgradePlan?: Maybe<Plan>;
  environment?: Maybe<Environment>;
  environmentId: Scalars['String']['output'];
  hasSubscriptions: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isDefaultProduct: Scalars['Boolean']['output'];
  multipleSubscriptions: Scalars['Boolean']['output'];
  plans: Array<Plan>;
  productSettings: ProductSettings;
  refId: Scalars['String']['output'];
  subscriptionStartPlan?: Maybe<Plan>;
  subscriptionUpdateUsageResetCutoffRule: SubscriptionUpdateUsageResetCutoffRule;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductAggregateGroupBy = {
  __typename?: 'ProductAggregateGroupBy';
  awsMarketplaceProductCode?: Maybe<Scalars['String']['output']>;
  awsMarketplaceProductId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isDefaultProduct?: Maybe<Scalars['Boolean']['output']>;
  multipleSubscriptions?: Maybe<Scalars['Boolean']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductCatalogDump = {
  __typename?: 'ProductCatalogDump';
  dump: Scalars['JSON']['output'];
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** Array of edges. */
  edges: Array<ProductEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ProductCountAggregate = {
  __typename?: 'ProductCountAggregate';
  awsMarketplaceProductCode?: Maybe<Scalars['Int']['output']>;
  awsMarketplaceProductId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['Int']['output']>;
  displayName?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDefaultProduct?: Maybe<Scalars['Int']['output']>;
  multipleSubscriptions?: Maybe<Scalars['Int']['output']>;
  refId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type ProductCreateInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  environmentId: Scalars['String']['input'];
  multipleSubscriptions?: InputMaybe<Scalars['Boolean']['input']>;
  refId: Scalars['String']['input'];
};

export type ProductDeleteResponse = {
  __typename?: 'ProductDeleteResponse';
  additionalMetaData?: Maybe<Scalars['JSON']['output']>;
  addons?: Maybe<Array<Addon>>;
  awsMarketplaceProductCode?: Maybe<Scalars['String']['output']>;
  awsMarketplaceProductId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isDefaultProduct?: Maybe<Scalars['Boolean']['output']>;
  multipleSubscriptions?: Maybe<Scalars['Boolean']['output']>;
  plans?: Maybe<Array<Plan>>;
  productSettings?: Maybe<ProductSettings>;
  refId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Product */
  node: Product;
};

export type ProductFilter = {
  and?: InputMaybe<Array<ProductFilter>>;
  awsMarketplaceProductCode?: InputMaybe<StringFieldComparison>;
  awsMarketplaceProductId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isDefaultProduct?: InputMaybe<BooleanFieldComparison>;
  multipleSubscriptions?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<ProductFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type ProductMaxAggregate = {
  __typename?: 'ProductMaxAggregate';
  awsMarketplaceProductCode?: Maybe<Scalars['String']['output']>;
  awsMarketplaceProductId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductMinAggregate = {
  __typename?: 'ProductMinAggregate';
  awsMarketplaceProductCode?: Maybe<Scalars['String']['output']>;
  awsMarketplaceProductId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductSettings = {
  __typename?: 'ProductSettings';
  downgradePlan?: Maybe<Plan>;
  downgradePlanId?: Maybe<Scalars['String']['output']>;
  prorateAtEndOfBillingPeriod?: Maybe<Scalars['Boolean']['output']>;
  subscriptionCancellationTime: SubscriptionCancellationTime;
  subscriptionEndSetup: SubscriptionEndSetup;
  subscriptionStartPlan?: Maybe<Plan>;
  subscriptionStartPlanId?: Maybe<Scalars['String']['output']>;
  subscriptionStartSetup: SubscriptionStartSetup;
};

export type ProductSettingsInput = {
  downgradeAtEndOfBillingPeriod?: InputMaybe<Scalars['String']['input']>;
  downgradePlanId?: InputMaybe<Scalars['String']['input']>;
  prorateAtEndOfBillingPeriod?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionCancellationTime: SubscriptionCancellationTime;
  subscriptionEndSetup: SubscriptionEndSetup;
  subscriptionStartPlanId?: InputMaybe<Scalars['String']['input']>;
  subscriptionStartSetup: SubscriptionStartSetup;
};

export type ProductSort = {
  direction: SortDirection;
  field: ProductSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProductSortFields {
  AwsMarketplaceProductCode = 'awsMarketplaceProductCode',
  AwsMarketplaceProductId = 'awsMarketplaceProductId',
  CreatedAt = 'createdAt',
  Description = 'description',
  DisplayName = 'displayName',
  EnvironmentId = 'environmentId',
  Id = 'id',
  IsDefaultProduct = 'isDefaultProduct',
  MultipleSubscriptions = 'multipleSubscriptions',
  RefId = 'refId',
  UpdatedAt = 'updatedAt',
}

export type ProductUpdateInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  /** Auto cancellation rules */
  autoCancellationRules?: InputMaybe<Array<AutoCancellationRuleInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  multipleSubscriptions?: InputMaybe<Scalars['Boolean']['input']>;
  productSettings?: InputMaybe<ProductSettingsInput>;
  /** Usage reset cutoff rule */
  usageResetCutoffRule?: InputMaybe<SubscriptionUpdateUsageResetCutoffRuleInput>;
};

export type PromotionCodeCustomerNotFirstPurchase = {
  __typename?: 'PromotionCodeCustomerNotFirstPurchase';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type PromotionCodeMaxRedemptionsReached = {
  __typename?: 'PromotionCodeMaxRedemptionsReached';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type PromotionCodeMinimumAmountNotReached = {
  __typename?: 'PromotionCodeMinimumAmountNotReached';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type PromotionCodeNotActive = {
  __typename?: 'PromotionCodeNotActive';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type PromotionCodeNotForCustomer = {
  __typename?: 'PromotionCodeNotForCustomer';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type PromotionCodeNotFound = {
  __typename?: 'PromotionCodeNotFound';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type PromotionalEntitlement = {
  __typename?: 'PromotionalEntitlement';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customer: Customer;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  environmentId: Scalars['String']['output'];
  feature: Feature;
  featureId: Scalars['String']['output'];
  hasSoftLimit?: Maybe<Scalars['Boolean']['output']>;
  hasUnlimitedUsage?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  isVisible: Scalars['Boolean']['output'];
  meter?: Maybe<Meter>;
  period: PromotionalEntitlementPeriod;
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  startDate: Scalars['DateTime']['output'];
  status: PromotionalEntitlementStatus;
  unlimited?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

export type PromotionalEntitlementAggregateGroupBy = {
  __typename?: 'PromotionalEntitlementAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PromotionalEntitlementStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionalEntitlementConnection = {
  __typename?: 'PromotionalEntitlementConnection';
  /** Array of edges. */
  edges: Array<PromotionalEntitlementEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type PromotionalEntitlementCountAggregate = {
  __typename?: 'PromotionalEntitlementCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type PromotionalEntitlementDeleteResponse = {
  __typename?: 'PromotionalEntitlementDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  featureId?: Maybe<Scalars['String']['output']>;
  hasSoftLimit?: Maybe<Scalars['Boolean']['output']>;
  hasUnlimitedUsage?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  period?: Maybe<PromotionalEntitlementPeriod>;
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<PromotionalEntitlementStatus>;
  unlimited?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

export type PromotionalEntitlementEdge = {
  __typename?: 'PromotionalEntitlementEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the PromotionalEntitlement */
  node: PromotionalEntitlement;
};

export type PromotionalEntitlementFilter = {
  and?: InputMaybe<Array<PromotionalEntitlementFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PromotionalEntitlementFilter>>;
  status?: InputMaybe<PromotionalEntitlementStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PromotionalEntitlementInput = {
  customerId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  environmentId: Scalars['String']['input'];
  featureId: Scalars['String']['input'];
  hasSoftLimit?: InputMaybe<Scalars['Boolean']['input']>;
  hasUnlimitedUsage?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible: Scalars['Boolean']['input'];
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  period: PromotionalEntitlementPeriod;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars['Float']['input']>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
  yearlyResetPeriodConfiguration?: InputMaybe<YearlyResetPeriodConfigInput>;
};

export type PromotionalEntitlementMaxAggregate = {
  __typename?: 'PromotionalEntitlementMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PromotionalEntitlementStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionalEntitlementMinAggregate = {
  __typename?: 'PromotionalEntitlementMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PromotionalEntitlementStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Promotional entitlement duration */
export enum PromotionalEntitlementPeriod {
  Custom = 'CUSTOM',
  Lifetime = 'LIFETIME',
  OneMonth = 'ONE_MONTH',
  OneWeek = 'ONE_WEEK',
  OneYear = 'ONE_YEAR',
  SixMonth = 'SIX_MONTH',
}

export type PromotionalEntitlementSort = {
  direction: SortDirection;
  field: PromotionalEntitlementSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PromotionalEntitlementSortFields {
  CreatedAt = 'createdAt',
  EnvironmentId = 'environmentId',
  Id = 'id',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

/** Promotional entitlement status */
export enum PromotionalEntitlementStatus {
  Active = 'Active',
  Expired = 'Expired',
  Paused = 'Paused',
}

export type PromotionalEntitlementStatusFilterComparison = {
  eq?: InputMaybe<PromotionalEntitlementStatus>;
  gt?: InputMaybe<PromotionalEntitlementStatus>;
  gte?: InputMaybe<PromotionalEntitlementStatus>;
  iLike?: InputMaybe<PromotionalEntitlementStatus>;
  in?: InputMaybe<Array<PromotionalEntitlementStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<PromotionalEntitlementStatus>;
  lt?: InputMaybe<PromotionalEntitlementStatus>;
  lte?: InputMaybe<PromotionalEntitlementStatus>;
  neq?: InputMaybe<PromotionalEntitlementStatus>;
  notILike?: InputMaybe<PromotionalEntitlementStatus>;
  notIn?: InputMaybe<Array<PromotionalEntitlementStatus>>;
  notLike?: InputMaybe<PromotionalEntitlementStatus>;
};

export type PromotionalEntitlementUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  hasSoftLimit?: InputMaybe<Scalars['Boolean']['input']>;
  hasUnlimitedUsage?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible: Scalars['Boolean']['input'];
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  period: PromotionalEntitlementPeriod;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars['Float']['input']>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
};

export enum ProrationBehavior {
  CreateProrations = 'CREATE_PRORATIONS',
  InvoiceImmediately = 'INVOICE_IMMEDIATELY',
}

export type ProvisionCustomerInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  awsMarketplaceCustomerId?: InputMaybe<Scalars['String']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  billingInformation?: InputMaybe<CustomerBillingInfo>;
  couponRefId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  crmId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  excludeFromExperiment?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['String']['input']>;
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  shouldSyncFree?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionParams?: InputMaybe<ProvisionCustomerSubscriptionInput>;
};

export type ProvisionCustomerSubscriptionInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  /** Coupon input */
  appliedCoupon?: InputMaybe<SubscriptionCouponInput>;
  awaitPaymentConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  billableFeatures?: InputMaybe<Array<BillableFeatureInput>>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  billingInformation?: InputMaybe<SubscriptionBillingInfo>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  /** Budget configuration */
  budget?: InputMaybe<BudgetConfigurationInput>;
  /** The minimum spend configuration */
  minimumSpend?: InputMaybe<SubscriptionMinimumSpendValueInput>;
  /** Subscription payment collection method */
  paymentCollectionMethod?: InputMaybe<PaymentCollectionMethod>;
  planId: Scalars['String']['input'];
  /** Override the price of the subscription */
  priceOverrides?: InputMaybe<Array<PriceOverrideInput>>;
  priceUnitAmount?: InputMaybe<Scalars['Float']['input']>;
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subscriptionEntitlements?: InputMaybe<Array<SubscriptionEntitlementInput>>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  trialOverrideConfiguration?: InputMaybe<TrialOverrideConfigurationInput>;
  unitQuantity?: InputMaybe<Scalars['Float']['input']>;
};

export type ProvisionSandboxInput = {
  billingModel: BillingModel;
  displayName: Scalars['String']['input'];
};

export type ProvisionSubscription = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  /** Coupon input */
  appliedCoupon?: InputMaybe<SubscriptionCouponInput>;
  awaitPaymentConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  billableFeatures?: InputMaybe<Array<BillableFeatureInput>>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  billingInformation?: InputMaybe<SubscriptionBillingInfo>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  /** Budget configuration */
  budget?: InputMaybe<BudgetConfigurationInput>;
  checkoutOptions?: InputMaybe<CheckoutOptions>;
  customerId: Scalars['String']['input'];
  /** The minimum spend configuration */
  minimumSpend?: InputMaybe<SubscriptionMinimumSpendValueInput>;
  payingCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** Subscription payment collection method */
  paymentCollectionMethod?: InputMaybe<PaymentCollectionMethod>;
  planId: Scalars['String']['input'];
  /** Override the price of the subscription */
  priceOverrides?: InputMaybe<Array<PriceOverrideInput>>;
  priceUnitAmount?: InputMaybe<Scalars['Float']['input']>;
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  scheduleStrategy?: InputMaybe<ScheduleStrategy>;
  skipTrial?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subscriptionEntitlements?: InputMaybe<Array<SubscriptionEntitlementInput>>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  trialOverrideConfiguration?: InputMaybe<TrialOverrideConfigurationInput>;
  unitQuantity?: InputMaybe<Scalars['Float']['input']>;
};

export type ProvisionSubscriptionInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  /** Coupon input */
  appliedCoupon?: InputMaybe<SubscriptionCouponInput>;
  awaitPaymentConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  billableFeatures?: InputMaybe<Array<BillableFeatureInput>>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  billingInformation?: InputMaybe<SubscriptionBillingInfo>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  /** Budget configuration */
  budget?: InputMaybe<BudgetConfigurationInput>;
  checkoutOptions?: InputMaybe<CheckoutOptions>;
  customerId: Scalars['String']['input'];
  /** The minimum spend configuration */
  minimumSpend?: InputMaybe<SubscriptionMinimumSpendValueInput>;
  payingCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** Subscription payment collection method */
  paymentCollectionMethod?: InputMaybe<PaymentCollectionMethod>;
  planId: Scalars['String']['input'];
  /** Override the price of the subscription */
  priceOverrides?: InputMaybe<Array<PriceOverrideInput>>;
  priceUnitAmount?: InputMaybe<Scalars['Float']['input']>;
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  scheduleStrategy?: InputMaybe<ScheduleStrategy>;
  skipTrial?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subscriptionEntitlements?: InputMaybe<Array<SubscriptionEntitlementInput>>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  trialOverrideConfiguration?: InputMaybe<TrialOverrideConfigurationInput>;
  unitQuantity?: InputMaybe<Scalars['Float']['input']>;
};

export type ProvisionSubscriptionResult = {
  __typename?: 'ProvisionSubscriptionResult';
  checkoutBillingId?: Maybe<Scalars['String']['output']>;
  checkoutUrl?: Maybe<Scalars['String']['output']>;
  entitlements?: Maybe<Array<Entitlement>>;
  id: Scalars['String']['output'];
  isScheduled?: Maybe<Scalars['Boolean']['output']>;
  status: ProvisionSubscriptionStatus;
  subscription?: Maybe<CustomerSubscription>;
};

/** Provision subscription status */
export enum ProvisionSubscriptionStatus {
  PaymentRequired = 'PAYMENT_REQUIRED',
  Success = 'SUCCESS',
}

export type ProvisionedCustomer = {
  __typename?: 'ProvisionedCustomer';
  customer: Customer;
  entitlements?: Maybe<Array<Entitlement>>;
  subscription?: Maybe<CustomerSubscription>;
  subscriptionDecisionStrategy: SubscriptionDecisionStrategy;
  /** @deprecated this field was renamed to 'subscriptionDecisionStrategy' */
  subscriptionStrategyDecision: SubscriptionDecisionStrategy;
};

/** PublishMigrationType */
export enum PublishMigrationType {
  AllCustomers = 'ALL_CUSTOMERS',
  NewCustomers = 'NEW_CUSTOMERS',
}

export type PublishPackageResult = {
  __typename?: 'PublishPackageResult';
  taskId?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Get the latest associated entities of an addon */
  addonAssociatedEntities: AddonAssociatedEntities;
  addons: AddonConnection;
  aggregatedEventsByCustomer: AggregatedEventsByCustomer;
  cachedEntitlements: Array<Entitlement>;
  checkoutState: CheckoutState;
  coupon?: Maybe<Coupon>;
  coupons: CouponConnection;
  currentEnvironment: Scalars['String']['output'];
  currentUser: User;
  customerPortal: CustomerPortal;
  customerResources: CustomerResourceConnection;
  customerSubscriptions: CustomerSubscriptionConnection;
  customers: CustomerConnection;
  doesFeatureExist: Scalars['Boolean']['output'];
  dumpEnvironmentForMergeComparison: DumpEnvironmentForMergeComparison;
  /** Dump environment product catalog. */
  dumpEnvironmentProductCatalog: ProductCatalogDump;
  entitlement: Entitlement;
  entitlements: Array<EntitlementWithSummary>;
  environments: EnvironmentConnection;
  eventLogs: EventLogConnection;
  eventsFields: EventsFields;
  experiment?: Maybe<Experiment>;
  experiments: ExperimentConnection;
  featureAssociatedLatestPackages: Array<PackageDto>;
  features: FeatureConnection;
  fetchAccount?: Maybe<Account>;
  getActiveSubscriptions: Array<CustomerSubscription>;
  getAddonByRefId?: Maybe<Addon>;
  getAuth0Applications: Array<Auth0ApplicationDto>;
  getAwsExternalId: GetAwsExternalIdResult;
  getCustomerByRefId?: Maybe<Customer>;
  getExperimentStats: ExperimentStats;
  getPackageGroup: PackageGroup;
  /** @deprecated Deprecated query, use paywall query instead */
  getPaywall: Array<Plan>;
  getPlanByRefId?: Maybe<Plan>;
  /** Get subscription */
  getSubscription: CustomerSubscription;
  hook?: Maybe<Hook>;
  hooks: HookConnection;
  importIntegrationTasks: ImportIntegrationTaskConnection;
  integrations: IntegrationConnection;
  listAwsProductDimensions: ListAwsProductDimensionsDto;
  listAwsProducts: ListAwsProductsResult;
  members: MemberConnection;
  mockPaywall: MockPaywall;
  packageEntitlements: PackageEntitlementConnection;
  packageGroup?: Maybe<PackageGroup>;
  packageGroups: PackageGroupConnection;
  paywall: Paywall;
  plans: PlanConnection;
  products: ProductConnection;
  promotionalEntitlements: PromotionalEntitlementConnection;
  sdkConfiguration?: Maybe<SdkConfiguration>;
  sendTestHook: TestHookResult;
  stripeCustomers: StripeCustomerSearchResult;
  stripeProducts: StripeProductSearchResult;
  stripeSubscriptions: StripeSubscriptionSearchResult;
  subscriptionEntitlements: SubscriptionEntitlementConnection;
  subscriptionMigrationTasks: SubscriptionMigrationTaskConnection;
  testHookData: TestHook;
  usageEvents: UsageEventsPreview;
  /** @deprecated usage usageHistoryV2 instead */
  usageHistory: UsageHistory;
  /** Get customer's usage history for a feature */
  usageHistoryV2: UsageHistoryV2;
  usageMeasurements: UsageMeasurementConnection;
  /** Validate that one environment can be merged into another. */
  validateMergeEnvironment: ValidateMergeEnvironment;
  widgetConfiguration: WidgetConfiguration;
};

export type QueryAddonAssociatedEntitiesArgs = {
  input: AddonAssociatedEntitiesInput;
};

export type QueryAddonsArgs = {
  filter?: InputMaybe<AddonFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<AddonSort>>;
};

export type QueryAggregatedEventsByCustomerArgs = {
  input: AggregatedEventsByCustomerInput;
};

export type QueryCachedEntitlementsArgs = {
  query: FetchEntitlementsQuery;
};

export type QueryCheckoutStateArgs = {
  input: CheckoutStateInput;
};

export type QueryCouponArgs = {
  id: Scalars['String']['input'];
};

export type QueryCouponsArgs = {
  filter?: InputMaybe<CouponFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CouponSort>>;
};

export type QueryCustomerPortalArgs = {
  input: CustomerPortalInput;
};

export type QueryCustomerResourcesArgs = {
  filter?: InputMaybe<CustomerResourceFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CustomerResourceSort>>;
};

export type QueryCustomerSubscriptionsArgs = {
  filter?: InputMaybe<CustomerSubscriptionFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CustomerSubscriptionSort>>;
};

export type QueryCustomersArgs = {
  filter?: InputMaybe<CustomerFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CustomerSort>>;
};

export type QueryDoesFeatureExistArgs = {
  input: DoesFeatureExist;
};

export type QueryDumpEnvironmentForMergeComparisonArgs = {
  input: DumpEnvironmentForForMergeComparisonInput;
};

export type QueryDumpEnvironmentProductCatalogArgs = {
  input: DumpEnvironmentProductCatalogInput;
};

export type QueryEntitlementArgs = {
  query: FetchEntitlementQuery;
};

export type QueryEntitlementsArgs = {
  query: FetchEntitlementsQuery;
};

export type QueryEnvironmentsArgs = {
  filter?: InputMaybe<EnvironmentFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<EnvironmentSort>>;
};

export type QueryEventLogsArgs = {
  filter: EventLogFilter;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<EventLogSort>>;
};

export type QueryEventsFieldsArgs = {
  input: EventsFieldsInput;
};

export type QueryExperimentArgs = {
  id: Scalars['String']['input'];
};

export type QueryExperimentsArgs = {
  filter?: InputMaybe<ExperimentFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ExperimentSort>>;
};

export type QueryFeatureAssociatedLatestPackagesArgs = {
  input: FeatureAssociatedLatestPackages;
};

export type QueryFeaturesArgs = {
  filter?: InputMaybe<FeatureFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<FeatureSort>>;
};

export type QueryGetActiveSubscriptionsArgs = {
  input: GetActiveSubscriptionsInput;
};

export type QueryGetAddonByRefIdArgs = {
  input: GetPackageByRefIdInput;
};

export type QueryGetAuth0ApplicationsArgs = {
  input: GetAuth0ApplicationsInput;
};

export type QueryGetCustomerByRefIdArgs = {
  input: GetCustomerByRefIdInput;
};

export type QueryGetExperimentStatsArgs = {
  query: ExperimentStatsQuery;
};

export type QueryGetPackageGroupArgs = {
  input: GetPackageGroup;
};

export type QueryGetPaywallArgs = {
  input: GetPaywallInput;
};

export type QueryGetPlanByRefIdArgs = {
  input: GetPackageByRefIdInput;
};

export type QueryGetSubscriptionArgs = {
  input: GetSubscriptionInput;
};

export type QueryHookArgs = {
  id: Scalars['String']['input'];
};

export type QueryHooksArgs = {
  filter?: InputMaybe<HookFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<HookSort>>;
};

export type QueryImportIntegrationTasksArgs = {
  filter?: InputMaybe<ImportIntegrationTaskFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ImportIntegrationTaskSort>>;
};

export type QueryIntegrationsArgs = {
  filter?: InputMaybe<IntegrationFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<IntegrationSort>>;
};

export type QueryListAwsProductDimensionsArgs = {
  input: ListAwsProductDimensionsInput;
};

export type QueryListAwsProductsArgs = {
  input: ListAwsProductsInput;
};

export type QueryMembersArgs = {
  filter?: InputMaybe<MemberFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<MemberSort>>;
};

export type QueryMockPaywallArgs = {
  input: GetPaywallInput;
};

export type QueryPackageEntitlementsArgs = {
  filter?: InputMaybe<PackageEntitlementFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PackageEntitlementSort>>;
};

export type QueryPackageGroupArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPackageGroupsArgs = {
  filter?: InputMaybe<PackageGroupFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PackageGroupSort>>;
};

export type QueryPaywallArgs = {
  input: GetPaywallInput;
};

export type QueryPlansArgs = {
  filter?: InputMaybe<PlanFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PlanSort>>;
};

export type QueryProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ProductSort>>;
};

export type QueryPromotionalEntitlementsArgs = {
  filter?: InputMaybe<PromotionalEntitlementFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PromotionalEntitlementSort>>;
};

export type QuerySendTestHookArgs = {
  testHookInput: TestHookInput;
};

export type QueryStripeCustomersArgs = {
  input: StripeCustomerSearchInput;
};

export type QueryStripeProductsArgs = {
  input: StripeProductSearchInput;
};

export type QueryStripeSubscriptionsArgs = {
  input: StripeSubscriptionSearchInput;
};

export type QuerySubscriptionEntitlementsArgs = {
  filter?: InputMaybe<SubscriptionEntitlementFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<SubscriptionEntitlementSort>>;
};

export type QuerySubscriptionMigrationTasksArgs = {
  filter?: InputMaybe<SubscriptionMigrationTaskFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<SubscriptionMigrationTaskSort>>;
};

export type QueryTestHookDataArgs = {
  eventLogType: EventLogType;
};

export type QueryUsageEventsArgs = {
  input: UsageEventsInput;
};

export type QueryUsageHistoryArgs = {
  usageHistoryInput: UsageHistoryInput;
};

export type QueryUsageHistoryV2Args = {
  input: UsageHistoryV2Input;
};

export type QueryUsageMeasurementsArgs = {
  filter?: InputMaybe<UsageMeasurementFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<UsageMeasurementSort>>;
};

export type QueryValidateMergeEnvironmentArgs = {
  input: ValidateMergeEnvironmentInput;
};

export type QueryWidgetConfigurationArgs = {
  input: GetWidgetConfigurationInput;
};

export type RecalculateEntitlementsInput = {
  customerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  environmentId: Scalars['String']['input'];
  forAllCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  sideEffectsOptions?: InputMaybe<RecalculateEntitlementsSideEffectsOptionsInput>;
};

export type RecalculateEntitlementsResult = {
  __typename?: 'RecalculateEntitlementsResult';
  taskId: Scalars['String']['output'];
};

export type RecalculateEntitlementsSideEffectsOptionsInput = {
  skipPublishToSubscribers?: InputMaybe<Scalars['Boolean']['input']>;
  skipWriteToCache?: InputMaybe<Scalars['Boolean']['input']>;
  skipWriteToDataCatalog?: InputMaybe<Scalars['Boolean']['input']>;
  skipWriteToEventLog?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RemoveBasePlanFromPlanInput = {
  /** The id of the record. */
  id: Scalars['String']['input'];
  /** The id of relation. */
  relationId: Scalars['String']['input'];
};

export type RemoveCompatibleAddonsFromPlanInput = {
  /** The id of the record. */
  id: Scalars['String']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['String']['input']>;
};

export type RemoveCouponFromCustomerInput = {
  /** The id of the record. */
  id: Scalars['String']['input'];
  /** The id of relation. */
  relationId: Scalars['String']['input'];
};

export type RemoveExperimentFromCustomerInput = {
  /** The id of the record. */
  id: Scalars['String']['input'];
  /** The id of relation. */
  relationId: Scalars['String']['input'];
};

export type RemoveExperimentFromCustomerSubscriptionInput = {
  /** The id of the record. */
  id: Scalars['String']['input'];
  /** The id of relation. */
  relationId: Scalars['String']['input'];
};

export type ReportUsageBaseInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId: Scalars['String']['input'];
  dimensions?: InputMaybe<Scalars['JSON']['input']>;
  featureId: Scalars['String']['input'];
  resourceId?: InputMaybe<Scalars['String']['input']>;
  updateBehavior?: InputMaybe<UsageUpdateBehavior>;
  value: Scalars['Float']['input'];
};

export type ReportUsageBulkInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
  usages: Array<ReportUsageBaseInput>;
};

export type ReportUsageInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId: Scalars['String']['input'];
  dimensions?: InputMaybe<Scalars['JSON']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  featureId: Scalars['String']['input'];
  resourceId?: InputMaybe<Scalars['String']['input']>;
  updateBehavior?: InputMaybe<UsageUpdateBehavior>;
  value: Scalars['Float']['input'];
};

export type ResetPeriodConfiguration = MonthlyResetPeriodConfig | WeeklyResetPeriodConfig | YearlyResetPeriodConfig;

export type ResyncIntegrationInput = {
  environmentId: Scalars['String']['input'];
  /** Allow skipping entitlement recalculation */
  recalculateEntitlements?: InputMaybe<Scalars['Boolean']['input']>;
  /** Type of integration to re-sync */
  vendorIdentifier: VendorIdentifier;
};

export type ResyncIntegrationResult = {
  __typename?: 'ResyncIntegrationResult';
  taskId?: Maybe<Scalars['String']['output']>;
};

export type RevokePromotionalEntitlementInput = {
  customerId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
  featureId: Scalars['String']['input'];
};

export type SalesforceCredentials = {
  __typename?: 'SalesforceCredentials';
  domain?: Maybe<Scalars['String']['output']>;
};

export type SalesforceCredentialsInput = {
  domain?: InputMaybe<Scalars['String']['input']>;
};

/** Options for when a change for a paid subscription should take effect */
export enum ScheduleStrategy {
  /** Schedule the change to the end of the billing month (for subscriptions whose billing period is larger then month) */
  EndOfBillingMonth = 'END_OF_BILLING_MONTH',
  /** Schedule the change to the end of the billing period */
  EndOfBillingPeriod = 'END_OF_BILLING_PERIOD',
  /** Apply the change immediately */
  Immediate = 'IMMEDIATE',
}

/** Schedule variables by the type of the schedule */
export type ScheduleVariables =
  | AddonChangeVariables
  | AddonPriceOverrideChangeVariables
  | BillingPeriodChangeVariables
  | CouponChangeVariables
  | DowngradeChangeVariables
  | PlanChangeVariables
  | PlanPriceOverrideChangeVariables
  | UnitAmountChangeVariables;

export type SdkConfiguration = {
  __typename?: 'SdkConfiguration';
  isWidgetWatermarkEnabled?: Maybe<Scalars['Boolean']['output']>;
  sentryDsn?: Maybe<Scalars['String']['output']>;
};

export type SetAccessRolesInput = {
  /** Account level access of the user */
  accountRole: AccountAccessRole;
  /** Environment level access of the user */
  nonProductionRole: EnvironmentAccessRole;
  /** Environment level access of the user */
  productionRole: EnvironmentAccessRole;
  /** The id of the user associated with this member */
  userId: Scalars['String']['input'];
};

export type SetBasePlanOnPlanInput = {
  /** The id of the record. */
  id: Scalars['String']['input'];
  /** The id of relation. */
  relationId: Scalars['String']['input'];
};

export type SetCompatibleAddonsOnPlanInput = {
  /** The id of the record. */
  id: Scalars['String']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['String']['input']>;
};

export type SetCouponOnCustomerInput = {
  /** The id of the record. */
  id: Scalars['String']['input'];
  /** The id of relation. */
  relationId: Scalars['String']['input'];
};

export type SetExperimentOnCustomerInput = {
  /** The id of the record. */
  id: Scalars['String']['input'];
  /** The id of relation. */
  relationId: Scalars['String']['input'];
};

export type SetExperimentOnCustomerSubscriptionInput = {
  /** The id of the record. */
  id: Scalars['String']['input'];
  /** The id of relation. */
  relationId: Scalars['String']['input'];
};

/** The input type for setting the addons of an package group */
export type SetPackageGroupAddons = {
  /** The addons to set on the package group */
  addons: Array<Scalars['String']['input']>;
  /** The environment id to set the addons of the package group in */
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the package group */
  packageGroupId: Scalars['String']['input'];
};

/** Set plan compatible package group input */
export type SetPlanCompatiblePackageGroup = {
  /** The options for the package group */
  options?: InputMaybe<SetPlanCompatiblePackageGroupOptions>;
  /** The package group id */
  packageGroupId: Scalars['String']['input'];
};

/** Set plan compatible package group options input */
export type SetPlanCompatiblePackageGroupOptions = {
  /** Number of free items from packageGroup */
  freeItems?: InputMaybe<Scalars['Float']['input']>;
  /** Number of required items from packageGroup */
  minItems?: InputMaybe<Scalars['Float']['input']>;
};

/** Set plan compatible package groups input */
export type SetPlanCompatiblePackageGroups = {
  /** The environment id */
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the plan */
  id: Scalars['String']['input'];
  /** The package groups with optional options */
  packageGroups: Array<SetPlanCompatiblePackageGroup>;
};

export type SnowflakeCredentials = {
  __typename?: 'SnowflakeCredentials';
  airbyteConnectionId?: Maybe<Scalars['String']['output']>;
  airbyteDestinationId?: Maybe<Scalars['String']['output']>;
  database: Scalars['String']['output'];
  host: Scalars['String']['output'];
  role: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  username: Scalars['String']['output'];
  warehouse: Scalars['String']['output'];
};

export type SnowflakeCredentialsInput = {
  database: Scalars['String']['input'];
  host: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: Scalars['String']['input'];
  schemaName: Scalars['String']['input'];
  username: Scalars['String']['input'];
  warehouse: Scalars['String']['input'];
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST',
}

export enum SourceType {
  JsClientSdk = 'JS_CLIENT_SDK',
  NodeServerSdk = 'NODE_SERVER_SDK',
  PersistentCacheService = 'PERSISTENT_CACHE_SERVICE',
}

export type StartExperimentInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
  refId: Scalars['String']['input'];
};

export type StopExperimentInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
  refId: Scalars['String']['input'];
};

export type StringChangeDto = {
  __typename?: 'StringChangeDTO';
  /** The value after the change */
  after?: Maybe<Scalars['String']['output']>;
  /** The value before the change */
  before?: Maybe<Scalars['String']['output']>;
  /** The change type */
  changeType?: Maybe<ChangeType>;
};

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  iLike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notILike?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type StripeCredentials = {
  __typename?: 'StripeCredentials';
  accountDisplayName: Scalars['String']['output'];
  accountId: Scalars['String']['output'];
  isTestMode: Scalars['Boolean']['output'];
};

export type StripeCredentialsInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  authorizationCode: Scalars['String']['input'];
  isTestMode: Scalars['Boolean']['input'];
};

export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  environmentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isSynced: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  subscriptionPlanName?: Maybe<Scalars['String']['output']>;
  subscriptionsCount: Scalars['Float']['output'];
};

export type StripeCustomerIsDeleted = {
  __typename?: 'StripeCustomerIsDeleted';
  billingId: Scalars['String']['output'];
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
};

export type StripeCustomerSearchInput = {
  customerName?: InputMaybe<Scalars['String']['input']>;
  environmentId: Scalars['String']['input'];
  nextPage?: InputMaybe<Scalars['String']['input']>;
};

export type StripeCustomerSearchResult = {
  __typename?: 'StripeCustomerSearchResult';
  customers: Array<StripeCustomer>;
  nextPage?: Maybe<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type StripePaymentMethodForm = {
  __typename?: 'StripePaymentMethodForm';
  clientSecret: Scalars['String']['output'];
  stripePublishableKey?: Maybe<Scalars['String']['output']>;
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  environmentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isSynced: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  notSupportedForImport: Scalars['Boolean']['output'];
  prices: Array<StripeProductPrice>;
  updatedAt: Scalars['DateTime']['output'];
};

export type StripeProductPrice = {
  __typename?: 'StripeProductPrice';
  amount: Scalars['Float']['output'];
  billingPeriod?: Maybe<BillingPeriod>;
};

export type StripeProductSearchInput = {
  environmentId: Scalars['String']['input'];
  nextPage?: InputMaybe<Scalars['String']['input']>;
  productName?: InputMaybe<Scalars['String']['input']>;
};

export type StripeProductSearchResult = {
  __typename?: 'StripeProductSearchResult';
  nextPage?: Maybe<Scalars['String']['output']>;
  products: Array<StripeProduct>;
  totalCount: Scalars['Int']['output'];
  usageBasedProductPresent?: Maybe<Scalars['Boolean']['output']>;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  id: Scalars['String']['output'];
};

export type StripeSubscriptionSearchInput = {
  environmentId: Scalars['String']['input'];
  nextPage?: InputMaybe<Scalars['String']['input']>;
};

export type StripeSubscriptionSearchResult = {
  __typename?: 'StripeSubscriptionSearchResult';
  nextPage?: Maybe<Scalars['String']['output']>;
  subscriptions: Array<StripeSubscription>;
  totalCount: Scalars['Int']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  entitlementsUpdated: EntitlementsUpdated;
  packagePublished: PackagePublished;
  usageUpdated: UsageUpdated;
};

export type SubscriptionAddon = {
  __typename?: 'SubscriptionAddon';
  /** Addon object */
  addon: Addon;
  /** The id of the addon */
  addonId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  price?: Maybe<Price>;
  quantity: Scalars['Float']['output'];
  subscription: CustomerSubscription;
  updatedAt: Scalars['DateTime']['output'];
};

export type SubscriptionAddonAggregateGroupBy = {
  __typename?: 'SubscriptionAddonAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubscriptionAddonAvgAggregate = {
  __typename?: 'SubscriptionAddonAvgAggregate';
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionAddonCountAggregate = {
  __typename?: 'SubscriptionAddonCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionAddonEdge = {
  __typename?: 'SubscriptionAddonEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the SubscriptionAddon */
  node: SubscriptionAddon;
};

export type SubscriptionAddonFilter = {
  and?: InputMaybe<Array<SubscriptionAddonFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionAddonFilter>>;
  price?: InputMaybe<SubscriptionAddonFilterPriceFilter>;
  quantity?: InputMaybe<NumberFieldComparison>;
  subscription?: InputMaybe<SubscriptionAddonFilterCustomerSubscriptionFilter>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SubscriptionAddonFilterCustomerSubscriptionFilter = {
  and?: InputMaybe<Array<SubscriptionAddonFilterCustomerSubscriptionFilter>>;
  billingCycleAnchor?: InputMaybe<DateFieldComparison>;
  billingId?: InputMaybe<StringFieldComparison>;
  cancelReason?: InputMaybe<SubscriptionCancelReasonFilterComparison>;
  cancellationDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  crmLinkUrl?: InputMaybe<StringFieldComparison>;
  currentBillingPeriodEnd?: InputMaybe<DateFieldComparison>;
  currentBillingPeriodStart?: InputMaybe<DateFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  effectiveEndDate?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  oldBillingId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionAddonFilterCustomerSubscriptionFilter>>;
  payingCustomerId?: InputMaybe<StringFieldComparison>;
  paymentCollection?: InputMaybe<PaymentCollectionFilterComparison>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  resourceId?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<SubscriptionStatusFilterComparison>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  trialEndDate?: InputMaybe<DateFieldComparison>;
};

export type SubscriptionAddonFilterPriceFilter = {
  and?: InputMaybe<Array<SubscriptionAddonFilterPriceFilter>>;
  billingCadence?: InputMaybe<BillingCadenceFilterComparison>;
  billingId?: InputMaybe<StringFieldComparison>;
  billingModel?: InputMaybe<BillingModelFilterComparison>;
  billingPeriod?: InputMaybe<BillingPeriodFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionAddonFilterPriceFilter>>;
  tiersMode?: InputMaybe<TiersModeFilterComparison>;
};

export type SubscriptionAddonInput = {
  addonId: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type SubscriptionAddonMaxAggregate = {
  __typename?: 'SubscriptionAddonMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubscriptionAddonMinAggregate = {
  __typename?: 'SubscriptionAddonMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubscriptionAddonSort = {
  direction: SortDirection;
  field: SubscriptionAddonSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SubscriptionAddonSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Quantity = 'quantity',
  UpdatedAt = 'updatedAt',
}

export type SubscriptionAddonSumAggregate = {
  __typename?: 'SubscriptionAddonSumAggregate';
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionAlreadyCanceledOrExpired = {
  __typename?: 'SubscriptionAlreadyCanceledOrExpired';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  refId: Scalars['String']['output'];
  status: SubscriptionStatus;
};

export type SubscriptionBillingInfo = {
  billingAddress?: InputMaybe<BillingAddress>;
  chargeOnBehalfOfAccount?: InputMaybe<Scalars['String']['input']>;
  couponId?: InputMaybe<Scalars['String']['input']>;
  invoiceDaysUntilDue?: InputMaybe<Scalars['Float']['input']>;
  isBackdated?: InputMaybe<Scalars['Boolean']['input']>;
  isInvoicePaid?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  taxPercentage?: InputMaybe<Scalars['Float']['input']>;
  taxRateIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Subscription cancellation status */
export enum SubscriptionCancelReason {
  AutoCancellationRule = 'AutoCancellationRule',
  CancelledByBilling = 'CancelledByBilling',
  CustomerArchived = 'CustomerArchived',
  DetachBilling = 'DetachBilling',
  Expired = 'Expired',
  Immediate = 'Immediate',
  PendingPaymentExpired = 'PendingPaymentExpired',
  ScheduledCancellation = 'ScheduledCancellation',
  TrialConverted = 'TrialConverted',
  TrialEnded = 'TrialEnded',
  UpgradeOrDowngrade = 'UpgradeOrDowngrade',
}

export type SubscriptionCancelReasonFilterComparison = {
  eq?: InputMaybe<SubscriptionCancelReason>;
  gt?: InputMaybe<SubscriptionCancelReason>;
  gte?: InputMaybe<SubscriptionCancelReason>;
  iLike?: InputMaybe<SubscriptionCancelReason>;
  in?: InputMaybe<Array<SubscriptionCancelReason>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<SubscriptionCancelReason>;
  lt?: InputMaybe<SubscriptionCancelReason>;
  lte?: InputMaybe<SubscriptionCancelReason>;
  neq?: InputMaybe<SubscriptionCancelReason>;
  notILike?: InputMaybe<SubscriptionCancelReason>;
  notIn?: InputMaybe<Array<SubscriptionCancelReason>>;
  notLike?: InputMaybe<SubscriptionCancelReason>;
};

export enum SubscriptionCancellationAction {
  Default = 'DEFAULT',
  RevokeEntitlements = 'REVOKE_ENTITLEMENTS',
}

export type SubscriptionCancellationInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** If set, enables or disables prorating of credits on subscription cancellation. */
  prorate?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionCancellationAction?: InputMaybe<SubscriptionCancellationAction>;
  subscriptionCancellationTime?: InputMaybe<SubscriptionCancellationTime>;
  subscriptionRefId: Scalars['String']['input'];
};

export enum SubscriptionCancellationTime {
  EndOfBillingPeriod = 'END_OF_BILLING_PERIOD',
  Immediate = 'IMMEDIATE',
  SpecificDate = 'SPECIFIC_DATE',
}

export type SubscriptionCoupon = {
  __typename?: 'SubscriptionCoupon';
  /** Discount amounts off */
  amountsOff?: Maybe<Array<Money>>;
  /** @deprecated Use percentOff or amountsOff fields instead */
  discountValue: Scalars['Float']['output'];
  /** The duration of the coupon in months */
  durationInMonths?: Maybe<Scalars['Float']['output']>;
  environmentId: Scalars['String']['output'];
  /** The expiration date of this coupon */
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** Discount percent off */
  percentOff?: Maybe<Scalars['Float']['output']>;
  refId: Scalars['String']['output'];
  /** The start date of this coupon */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** The status of this coupon */
  status: SubscriptionCouponStatus;
  type: CouponType;
};

/** Subscription coupon configuration input */
export type SubscriptionCouponConfigurationInput = {
  /** The date to start the coupon from */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Inline subscription coupon input */
export type SubscriptionCouponDiscountInput = {
  /** Discount flat fee amounts off */
  amountsOff?: InputMaybe<Array<MoneyInputDto>>;
  /** Description of the coupon that will be created */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The duration in months for which the coupon remains active. */
  durationInMonths?: InputMaybe<Scalars['Float']['input']>;
  /** Name of the coupon that will be created */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Discount percent off */
  percentOff?: InputMaybe<Scalars['Float']['input']>;
};

/** Subscription coupon input */
export type SubscriptionCouponInput = {
  /** Billing integration coupon id */
  billingCouponId?: InputMaybe<Scalars['String']['input']>;
  /** Coupon configuration input */
  configuration?: InputMaybe<SubscriptionCouponConfigurationInput>;
  /** Stigg coupon id */
  couponId?: InputMaybe<Scalars['String']['input']>;
  /** An inline discount */
  discount?: InputMaybe<SubscriptionCouponDiscountInput>;
  /** Promotion code */
  promotionCode?: InputMaybe<Scalars['String']['input']>;
};

/** The status of the subscription coupon */
export enum SubscriptionCouponStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Removed = 'REMOVED',
}

/** Subscription decision strategy */
export enum SubscriptionDecisionStrategy {
  PredefinedFreePlan = 'PREDEFINED_FREE_PLAN',
  PredefinedTrialPlan = 'PREDEFINED_TRIAL_PLAN',
  RequestedPlan = 'REQUESTED_PLAN',
  SkippedSubscriptionCreation = 'SKIPPED_SUBSCRIPTION_CREATION',
}

export enum SubscriptionEndSetup {
  CancelSubscription = 'CANCEL_SUBSCRIPTION',
  DowngradeToFree = 'DOWNGRADE_TO_FREE',
}

export type SubscriptionEntitlement = {
  __typename?: 'SubscriptionEntitlement';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  environmentId: Scalars['String']['output'];
  feature: Feature;
  featureId: Scalars['String']['output'];
  hasSoftLimit?: Maybe<Scalars['Boolean']['output']>;
  hasUnlimitedUsage?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  meter?: Maybe<Meter>;
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  subscription: CustomerSubscription;
  subscriptionId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionEntitlementAggregateGroupBy = {
  __typename?: 'SubscriptionEntitlementAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubscriptionEntitlementConnection = {
  __typename?: 'SubscriptionEntitlementConnection';
  /** Array of edges. */
  edges: Array<SubscriptionEntitlementEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type SubscriptionEntitlementCountAggregate = {
  __typename?: 'SubscriptionEntitlementCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  subscriptionId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionEntitlementEdge = {
  __typename?: 'SubscriptionEntitlementEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the SubscriptionEntitlement */
  node: SubscriptionEntitlement;
};

export type SubscriptionEntitlementFilter = {
  and?: InputMaybe<Array<SubscriptionEntitlementFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  feature?: InputMaybe<SubscriptionEntitlementFilterFeatureFilter>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionEntitlementFilter>>;
  subscription?: InputMaybe<SubscriptionEntitlementFilterCustomerSubscriptionFilter>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SubscriptionEntitlementFilterCustomerSubscriptionFilter = {
  and?: InputMaybe<Array<SubscriptionEntitlementFilterCustomerSubscriptionFilter>>;
  billingCycleAnchor?: InputMaybe<DateFieldComparison>;
  billingId?: InputMaybe<StringFieldComparison>;
  cancelReason?: InputMaybe<SubscriptionCancelReasonFilterComparison>;
  cancellationDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  crmLinkUrl?: InputMaybe<StringFieldComparison>;
  currentBillingPeriodEnd?: InputMaybe<DateFieldComparison>;
  currentBillingPeriodStart?: InputMaybe<DateFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  effectiveEndDate?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  oldBillingId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionEntitlementFilterCustomerSubscriptionFilter>>;
  payingCustomerId?: InputMaybe<StringFieldComparison>;
  paymentCollection?: InputMaybe<PaymentCollectionFilterComparison>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  resourceId?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<SubscriptionStatusFilterComparison>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  trialEndDate?: InputMaybe<DateFieldComparison>;
};

export type SubscriptionEntitlementFilterFeatureFilter = {
  and?: InputMaybe<Array<SubscriptionEntitlementFilterFeatureFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  featureStatus?: InputMaybe<FeatureStatusFilterComparison>;
  featureType?: InputMaybe<FeatureTypeFilterComparison>;
  id?: InputMaybe<StringFieldComparison>;
  meterType?: InputMaybe<MeterTypeFilterComparison>;
  or?: InputMaybe<Array<SubscriptionEntitlementFilterFeatureFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SubscriptionEntitlementInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  featureId: Scalars['String']['input'];
  hasSoftLimit?: InputMaybe<Scalars['Boolean']['input']>;
  hasUnlimitedUsage?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars['Float']['input']>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
  yearlyResetPeriodConfiguration?: InputMaybe<YearlyResetPeriodConfigInput>;
};

export type SubscriptionEntitlementMaxAggregate = {
  __typename?: 'SubscriptionEntitlementMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubscriptionEntitlementMinAggregate = {
  __typename?: 'SubscriptionEntitlementMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubscriptionEntitlementSort = {
  direction: SortDirection;
  field: SubscriptionEntitlementSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SubscriptionEntitlementSortFields {
  CreatedAt = 'createdAt',
  EnvironmentId = 'environmentId',
  Id = 'id',
  SubscriptionId = 'subscriptionId',
  UpdatedAt = 'updatedAt',
}

export type SubscriptionFutureUpdate = {
  __typename?: 'SubscriptionFutureUpdate';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  scheduleStatus: SubscriptionScheduleStatus;
  /** The schedule variables by the type of the schedule */
  scheduleVariables?: Maybe<ScheduleVariables>;
  scheduledExecutionTime: Scalars['DateTime']['output'];
  subscriptionScheduleType: SubscriptionScheduleType;
  targetPackage?: Maybe<PackageDto>;
};

export type SubscriptionInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  /** Coupon input */
  appliedCoupon?: InputMaybe<SubscriptionCouponInput>;
  awaitPaymentConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  billableFeatures?: InputMaybe<Array<BillableFeatureInput>>;
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  billingInformation?: InputMaybe<SubscriptionBillingInfo>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  /** Budget configuration */
  budget?: InputMaybe<BudgetConfigurationInput>;
  crmId?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  isCustomPriceSubscription?: InputMaybe<Scalars['Boolean']['input']>;
  isOverridingTrialConfig?: InputMaybe<Scalars['Boolean']['input']>;
  isTrial?: InputMaybe<Scalars['Boolean']['input']>;
  /** The minimum spend configuration */
  minimumSpend?: InputMaybe<SubscriptionMinimumSpendValueInput>;
  payingCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** Subscription payment collection method */
  paymentCollectionMethod?: InputMaybe<PaymentCollectionMethod>;
  planId: Scalars['String']['input'];
  /** Override the price of the subscription */
  priceOverrides?: InputMaybe<Array<PriceOverrideInput>>;
  priceUnitAmount?: InputMaybe<Scalars['Float']['input']>;
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  scheduleStrategy?: InputMaybe<ScheduleStrategy>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subscriptionEntitlements?: InputMaybe<Array<SubscriptionEntitlementInput>>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  /** Indicates the behavior of the subscription when the trial is expired. */
  trialEndBehavior?: InputMaybe<TrialEndBehavior>;
  unitQuantity?: InputMaybe<Scalars['Float']['input']>;
};

export type SubscriptionInvoice = {
  __typename?: 'SubscriptionInvoice';
  amountDue?: Maybe<Scalars['Float']['output']>;
  appliedBalance?: Maybe<Scalars['Float']['output']>;
  billingId: Scalars['String']['output'];
  billingReason?: Maybe<SubscriptionInvoiceBillingReason>;
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  endingBalance?: Maybe<Scalars['Float']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  lines?: Maybe<Array<InvoiceLine>>;
  paymentSecret?: Maybe<Scalars['String']['output']>;
  paymentUrl?: Maybe<Scalars['String']['output']>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
  requiresAction: Scalars['Boolean']['output'];
  startingBalance?: Maybe<Scalars['Float']['output']>;
  status: SubscriptionInvoiceStatus;
  subTotal?: Maybe<Scalars['Float']['output']>;
  subTotalExcludingTax?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalExcludingTax?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** The reason the billing event was created. */
export enum SubscriptionInvoiceBillingReason {
  /** A subscription advanced into a new billing cycle. */
  BillingCycle = 'BILLING_CYCLE',
  /** An invoice was created manually. */
  Manual = 'MANUAL',
  /** A subscription passed the minimum invoice amount */
  MinimumInvoiceAmountExceeded = 'MINIMUM_INVOICE_AMOUNT_EXCEEDED',
  /** An invoice was created for another reason. */
  Other = 'OTHER',
  /** A subscription was created. */
  SubscriptionCreation = 'SUBSCRIPTION_CREATION',
  /** A subscription was updated. */
  SubscriptionUpdate = 'SUBSCRIPTION_UPDATE',
}

/** The preview of the next upcoming invoice for the given subscription */
export type SubscriptionInvoicePreview = {
  __typename?: 'SubscriptionInvoicePreview';
  /** The amount due for the invoice after taxes, discounts and credits */
  amountDue: Money;
  /** Credits information (initial, used, remaining) */
  credits?: Maybe<SubscriptionPreviewCredits>;
  /** The total discount amount of the invoice */
  discount?: Maybe<Money>;
  /** The applied discount details */
  discountDetails?: Maybe<SubscriptionPreviewDiscount>;
  /** The date when the invoice was last updated */
  lastUpdatedAt: Scalars['DateTime']['output'];
  /** The line items of the invoice */
  lines: Array<SubscriptionInvoicePreviewLineItem>;
  /** The minimum spend adjustment applied to the invoice */
  minimumSpendAdjustment: Money;
  /** The sub total amount of the invoice excluding  discounts */
  subTotal: Money;
  /** The sub total amount of the invoice excluding taxes and discounts */
  subTotalExcludingTax: Money;
  /** The total tax amount of the invoice */
  tax?: Maybe<Money>;
  /** The applied tax details */
  taxDetails?: Maybe<SubscriptionPreviewTaxDetails>;
  /** The total amount of the invoice including taxes and discounts */
  total: Money;
  /** The total amount of the invoice excluding taxes */
  totalExcludingTax: Money;
};

/** The invoice line item */
export type SubscriptionInvoicePreviewLineItem = {
  __typename?: 'SubscriptionInvoicePreviewLineItem';
  /** The total amount of the invoice line item */
  amount: Money;
  /** The cost description of the invoice line item */
  costDescription: Scalars['String']['output'];
  /** The description of the invoice line item */
  description: Scalars['String']['output'];
  /** Whether the price connected to the invoice line item has a soft limit */
  hasSoftLimit?: Maybe<Scalars['Boolean']['output']>;
  /** The nested line items of the invoice line item */
  lines?: Maybe<Array<SubscriptionInvoicePreviewLineItemData>>;
  period: SubscriptionInvoicePreviewLineItemPeriod;
  /** The price connected to the invoice line item */
  price?: Maybe<Price>;
  /** Whether the line item is prorated */
  proration: Scalars['Boolean']['output'];
  /** The quantity of the invoice line item */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** The charge type of the invoice line item */
  type: InvoiceLineItemType;
  /** The unit price of the invoice line item */
  unitPrice?: Maybe<Money>;
  /** The usage limit of the invoice line item */
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

/** The invoice line item */
export type SubscriptionInvoicePreviewLineItemData = {
  __typename?: 'SubscriptionInvoicePreviewLineItemData';
  /** The total amount of the invoice line item */
  amount: Money;
  /** The cost description of the invoice line item */
  costDescription: Scalars['String']['output'];
  /** The description of the invoice line item */
  description: Scalars['String']['output'];
  /** Whether the price connected to the invoice line item has a soft limit */
  hasSoftLimit?: Maybe<Scalars['Boolean']['output']>;
  period: SubscriptionInvoicePreviewLineItemPeriod;
  /** The price connected to the invoice line item */
  price?: Maybe<Price>;
  /** Whether the line item is prorated */
  proration: Scalars['Boolean']['output'];
  /** The quantity of the invoice line item */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** The charge type of the invoice line item */
  type: InvoiceLineItemType;
  /** The unit price of the invoice line item */
  unitPrice?: Maybe<Money>;
  /** The usage limit of the invoice line item */
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

/** The invoice line item period */
export type SubscriptionInvoicePreviewLineItemPeriod = {
  __typename?: 'SubscriptionInvoicePreviewLineItemPeriod';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
};

/** SubscriptionInvoice status */
export enum SubscriptionInvoiceStatus {
  Canceled = 'CANCELED',
  Open = 'OPEN',
  Paid = 'PAID',
}

export type SubscriptionMaximumSpend = {
  __typename?: 'SubscriptionMaximumSpend';
  discount?: Maybe<Money>;
  discountDetails?: Maybe<SubscriptionMaximumSpendDiscount>;
  lastUpdatedAt: Scalars['DateTime']['output'];
  lines?: Maybe<Array<SubscriptionInvoicePreviewLineItem>>;
  /** The maximum spending limit set */
  maximumSpend: Money;
  subTotal: Money;
  total: Money;
};

export type SubscriptionMaximumSpendDiscount = {
  __typename?: 'SubscriptionMaximumSpendDiscount';
  /** Coupon duration in month */
  durationInMonths?: Maybe<Scalars['Float']['output']>;
  /** Coupon duration type */
  durationType?: Maybe<DiscountDurationType>;
  /** Coupon name */
  name?: Maybe<Scalars['String']['output']>;
  /** Coupon start date */
  start?: Maybe<Scalars['DateTime']['output']>;
  /** Discount type */
  type?: Maybe<DiscountType>;
  /** Coupon value */
  value?: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionMigrationInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['String']['input'];
  subscriptionMigrationTime?: InputMaybe<SubscriptionMigrationTime>;
};

export type SubscriptionMigrationTask = {
  __typename?: 'SubscriptionMigrationTask';
  affectedCustomersCount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  environmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  initiatedPackageId?: Maybe<Scalars['String']['output']>;
  migratedCustomersCount?: Maybe<Scalars['Int']['output']>;
  packages: Array<PackageDto>;
  progress: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: TaskStatus;
  taskType: TaskType;
};

export type SubscriptionMigrationTaskPackagesArgs = {
  filter?: InputMaybe<PackageDtoFilter>;
  sorting?: InputMaybe<Array<PackageDtoSort>>;
};

export type SubscriptionMigrationTaskAggregateGroupBy = {
  __typename?: 'SubscriptionMigrationTaskAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TaskStatus>;
  taskType?: Maybe<TaskType>;
};

export type SubscriptionMigrationTaskConnection = {
  __typename?: 'SubscriptionMigrationTaskConnection';
  /** Array of edges. */
  edges: Array<SubscriptionMigrationTaskEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type SubscriptionMigrationTaskCountAggregate = {
  __typename?: 'SubscriptionMigrationTaskCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  taskType?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionMigrationTaskEdge = {
  __typename?: 'SubscriptionMigrationTaskEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the SubscriptionMigrationTask */
  node: SubscriptionMigrationTask;
};

export type SubscriptionMigrationTaskFilter = {
  and?: InputMaybe<Array<SubscriptionMigrationTaskFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionMigrationTaskFilter>>;
  status?: InputMaybe<TaskStatusFilterComparison>;
  taskType?: InputMaybe<TaskTypeFilterComparison>;
};

export type SubscriptionMigrationTaskMaxAggregate = {
  __typename?: 'SubscriptionMigrationTaskMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TaskStatus>;
  taskType?: Maybe<TaskType>;
};

export type SubscriptionMigrationTaskMinAggregate = {
  __typename?: 'SubscriptionMigrationTaskMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TaskStatus>;
  taskType?: Maybe<TaskType>;
};

export type SubscriptionMigrationTaskSort = {
  direction: SortDirection;
  field: SubscriptionMigrationTaskSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SubscriptionMigrationTaskSortFields {
  CreatedAt = 'createdAt',
  EnvironmentId = 'environmentId',
  Id = 'id',
  Status = 'status',
  TaskType = 'taskType',
}

/** Set non immediate cancellation time (atm supported only for stripe integration) */
export enum SubscriptionMigrationTime {
  EndOfBillingPeriod = 'END_OF_BILLING_PERIOD',
  Immediate = 'IMMEDIATE',
}

/** Subscription minimum spend */
export type SubscriptionMinimumSpend = {
  __typename?: 'SubscriptionMinimumSpend';
  /** Is the minimum spend an override on the subscription level */
  isOverride?: Maybe<Scalars['Boolean']['output']>;
  /** The minimum spend limit */
  minimum?: Maybe<Money>;
};

/** Subscription minimum spend */
export type SubscriptionMinimumSpendValueInput = {
  /** The minimum spend */
  minimum?: InputMaybe<MoneyInputDto>;
};

export type SubscriptionMustHaveSinglePlanError = {
  __typename?: 'SubscriptionMustHaveSinglePlanError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  refIds: Array<Scalars['String']['output']>;
};

export type SubscriptionNoBillingId = {
  __typename?: 'SubscriptionNoBillingId';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  refId: Scalars['String']['output'];
};

export type SubscriptionPreview = {
  __typename?: 'SubscriptionPreview';
  billingPeriodRange: DateRange;
  credits?: Maybe<SubscriptionPreviewCredits>;
  discount?: Maybe<SubscriptionPreviewDiscount>;
  discountAmount?: Maybe<Money>;
  hasScheduledUpdates?: Maybe<Scalars['Boolean']['output']>;
  isPlanDowngrade?: Maybe<Scalars['Boolean']['output']>;
  /** The minimum spend adjustment applied to the invoice */
  minimumSpendAdjustment: Money;
  proration?: Maybe<SubscriptionPreviewProrations>;
  subTotal: Money;
  subscription?: Maybe<SubscriptionPricePreviewDto>;
  tax?: Maybe<Money>;
  taxDetails?: Maybe<SubscriptionPreviewTaxDetails>;
  total: Money;
  totalExcludingTax: Money;
};

export type SubscriptionPreviewCredits = {
  __typename?: 'SubscriptionPreviewCredits';
  initial: Money;
  remaining: Money;
  used: Money;
};

export type SubscriptionPreviewDiscount = {
  __typename?: 'SubscriptionPreviewDiscount';
  /** Coupon duration in month */
  durationInMonths?: Maybe<Scalars['Float']['output']>;
  /** Coupon duration type */
  durationType: DiscountDurationType;
  /** Coupon end date */
  end?: Maybe<Scalars['DateTime']['output']>;
  /** Coupon name */
  name?: Maybe<Scalars['String']['output']>;
  /** Coupon start date */
  start: Scalars['DateTime']['output'];
  /** Discount type */
  type: DiscountType;
  /** Coupon value */
  value: Scalars['Float']['output'];
};

export type SubscriptionPreviewInvoice = {
  __typename?: 'SubscriptionPreviewInvoice';
  discount?: Maybe<Money>;
  discountDetails?: Maybe<SubscriptionPreviewDiscount>;
  /** The minimum spend adjustment applied to the invoice */
  minimumSpendAdjustment: Money;
  subTotal: Money;
  tax?: Maybe<Money>;
  taxDetails?: Maybe<SubscriptionPreviewTaxDetails>;
  total: Money;
  totalExcludingTax: Money;
};

export type SubscriptionPreviewProrations = {
  __typename?: 'SubscriptionPreviewProrations';
  credit: Money;
  debit: Money;
  hasProrations?: Maybe<Scalars['Boolean']['output']>;
  netAmount: Money;
  prorationDate: Scalars['DateTime']['output'];
};

export type SubscriptionPreviewTaxDetails = {
  __typename?: 'SubscriptionPreviewTaxDetails';
  displayName: Scalars['String']['output'];
  inclusive: Scalars['Boolean']['output'];
  percentage: Scalars['Float']['output'];
};

export type SubscriptionPreviewV2 = {
  __typename?: 'SubscriptionPreviewV2';
  billingPeriodRange: DateRange;
  /** Free items of the subscription */
  freeItems?: Maybe<Array<FreeSubscriptionItem>>;
  hasScheduledUpdates?: Maybe<Scalars['Boolean']['output']>;
  immediateInvoice: ImmediateSubscriptionPreviewInvoice;
  isPlanDowngrade?: Maybe<Scalars['Boolean']['output']>;
  recurringInvoice?: Maybe<SubscriptionPreviewInvoice>;
};

export type SubscriptionPrice = {
  __typename?: 'SubscriptionPrice';
  billingModel?: Maybe<BillingModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  featureId?: Maybe<Scalars['String']['output']>;
  hasSoftLimit?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  price?: Maybe<Price>;
  subscription: CustomerSubscription;
  updatedAt: Scalars['DateTime']['output'];
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionPriceAggregateGroupBy = {
  __typename?: 'SubscriptionPriceAggregateGroupBy';
  billingModel?: Maybe<BillingModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  featureId?: Maybe<Scalars['String']['output']>;
  hasSoftLimit?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionPriceAvgAggregate = {
  __typename?: 'SubscriptionPriceAvgAggregate';
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionPriceCountAggregate = {
  __typename?: 'SubscriptionPriceCountAggregate';
  billingModel?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  featureId?: Maybe<Scalars['Int']['output']>;
  hasSoftLimit?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
  usageLimit?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionPriceEdge = {
  __typename?: 'SubscriptionPriceEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the SubscriptionPrice */
  node: SubscriptionPrice;
};

export type SubscriptionPriceFilter = {
  and?: InputMaybe<Array<SubscriptionPriceFilter>>;
  billingModel?: InputMaybe<BillingModelFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  featureId?: InputMaybe<StringFieldComparison>;
  hasSoftLimit?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionPriceFilter>>;
  price?: InputMaybe<SubscriptionPriceFilterPriceFilter>;
  subscription?: InputMaybe<SubscriptionPriceFilterCustomerSubscriptionFilter>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  usageLimit?: InputMaybe<NumberFieldComparison>;
};

export type SubscriptionPriceFilterCustomerSubscriptionFilter = {
  and?: InputMaybe<Array<SubscriptionPriceFilterCustomerSubscriptionFilter>>;
  billingCycleAnchor?: InputMaybe<DateFieldComparison>;
  billingId?: InputMaybe<StringFieldComparison>;
  cancelReason?: InputMaybe<SubscriptionCancelReasonFilterComparison>;
  cancellationDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  crmLinkUrl?: InputMaybe<StringFieldComparison>;
  currentBillingPeriodEnd?: InputMaybe<DateFieldComparison>;
  currentBillingPeriodStart?: InputMaybe<DateFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  effectiveEndDate?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  oldBillingId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionPriceFilterCustomerSubscriptionFilter>>;
  payingCustomerId?: InputMaybe<StringFieldComparison>;
  paymentCollection?: InputMaybe<PaymentCollectionFilterComparison>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  resourceId?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<SubscriptionStatusFilterComparison>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  trialEndDate?: InputMaybe<DateFieldComparison>;
};

export type SubscriptionPriceFilterPriceFilter = {
  and?: InputMaybe<Array<SubscriptionPriceFilterPriceFilter>>;
  billingCadence?: InputMaybe<BillingCadenceFilterComparison>;
  billingId?: InputMaybe<StringFieldComparison>;
  billingModel?: InputMaybe<BillingModelFilterComparison>;
  billingPeriod?: InputMaybe<BillingPeriodFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionPriceFilterPriceFilter>>;
  tiersMode?: InputMaybe<TiersModeFilterComparison>;
};

export type SubscriptionPriceMaxAggregate = {
  __typename?: 'SubscriptionPriceMaxAggregate';
  billingModel?: Maybe<BillingModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  featureId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionPriceMinAggregate = {
  __typename?: 'SubscriptionPriceMinAggregate';
  billingModel?: Maybe<BillingModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  featureId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionPricePreviewDto = {
  __typename?: 'SubscriptionPricePreviewDTO';
  discount?: Maybe<SubscriptionPreviewDiscount>;
  discountAmount?: Maybe<Money>;
  subTotal: Money;
  tax: Money;
  taxDetails?: Maybe<SubscriptionPreviewTaxDetails>;
  total: Money;
  totalExcludingTax: Money;
};

export type SubscriptionPriceSort = {
  direction: SortDirection;
  field: SubscriptionPriceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SubscriptionPriceSortFields {
  BillingModel = 'billingModel',
  CreatedAt = 'createdAt',
  FeatureId = 'featureId',
  HasSoftLimit = 'hasSoftLimit',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UsageLimit = 'usageLimit',
}

export type SubscriptionPriceSumAggregate = {
  __typename?: 'SubscriptionPriceSumAggregate';
  usageLimit?: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionPricingTypeStatistics = {
  __typename?: 'SubscriptionPricingTypeStatistics';
  pricingType: PricingType;
  totalCount: Scalars['Float']['output'];
};

/** Subscription scheduled schedule status */
export enum SubscriptionScheduleStatus {
  Canceled = 'Canceled',
  Done = 'Done',
  Failed = 'Failed',
  PendingPayment = 'PendingPayment',
  Scheduled = 'Scheduled',
}

/** Subscription scheduled schedule type */
export enum SubscriptionScheduleType {
  /** Addon change */
  Addon = 'Addon',
  /** Billing period change */
  BillingPeriod = 'BillingPeriod',
  /** Coupon change */
  Coupon = 'Coupon',
  /** Downgrade change */
  Downgrade = 'Downgrade',
  /** Migrate to latest */
  MigrateToLatest = 'MigrateToLatest',
  /** Plan change */
  Plan = 'Plan',
  /** Custom price change */
  PriceOverride = 'PriceOverride',
  /** Unit amount change */
  UnitAmount = 'UnitAmount',
}

export type SubscriptionScheduledUpdate = {
  __typename?: 'SubscriptionScheduledUpdate';
  billingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  scheduleStatus: SubscriptionScheduleStatus;
  /** The schedule variables by the type of the schedule */
  scheduleVariables?: Maybe<ScheduleVariables>;
  scheduledExecutionTime: Scalars['DateTime']['output'];
  subscriptionScheduleType: SubscriptionScheduleType;
  targetPackage?: Maybe<PackageDto>;
};

export enum SubscriptionStartSetup {
  FreePlan = 'FREE_PLAN',
  PlanSelection = 'PLAN_SELECTION',
  TrialPeriod = 'TRIAL_PERIOD',
}

/** Subscription status */
export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  InTrial = 'IN_TRIAL',
  NotStarted = 'NOT_STARTED',
  PaymentPending = 'PAYMENT_PENDING',
}

export type SubscriptionStatusFilterComparison = {
  eq?: InputMaybe<SubscriptionStatus>;
  gt?: InputMaybe<SubscriptionStatus>;
  gte?: InputMaybe<SubscriptionStatus>;
  iLike?: InputMaybe<SubscriptionStatus>;
  in?: InputMaybe<Array<SubscriptionStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<SubscriptionStatus>;
  lt?: InputMaybe<SubscriptionStatus>;
  lte?: InputMaybe<SubscriptionStatus>;
  neq?: InputMaybe<SubscriptionStatus>;
  notILike?: InputMaybe<SubscriptionStatus>;
  notIn?: InputMaybe<Array<SubscriptionStatus>>;
  notLike?: InputMaybe<SubscriptionStatus>;
};

export type SubscriptionUpdateScheduleCancellationInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SubscriptionScheduleStatus>;
  subscriptionId: Scalars['String']['input'];
};

/** Decide whether feature usage will be reset upon creation on new subscription */
export enum SubscriptionUpdateUsageCutoffBehavior {
  /** Always reset */
  AlwaysReset = 'ALWAYS_RESET',
  /** Billing period changed */
  BillingPeriodChange = 'BILLING_PERIOD_CHANGE',
  /** Never reset */
  NeverReset = 'NEVER_RESET',
}

/** Usage reset cutoff rule - when does the usage for a feature should be reset */
export type SubscriptionUpdateUsageResetCutoffRule = {
  __typename?: 'SubscriptionUpdateUsageResetCutoffRule';
  /** The behavior to reset according to */
  behavior: SubscriptionUpdateUsageCutoffBehavior;
};

/** The input of the usage reset cutoff rule. */
export type SubscriptionUpdateUsageResetCutoffRuleInput = {
  /** The behavior of the usage reset cutoff rule. */
  behavior: SubscriptionUpdateUsageCutoffBehavior;
};

export type SyncState = {
  __typename?: 'SyncState';
  error?: Maybe<Scalars['String']['output']>;
  status: SyncStatus;
  vendorIdentifier: VendorIdentifier;
};

/** Status of the integration sync */
export enum SyncStatus {
  Error = 'ERROR',
  NoSyncRequired = 'NO_SYNC_REQUIRED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
}

export type SyncTaxRatesInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
};

export enum TaskStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  PartiallyFailed = 'PARTIALLY_FAILED',
  Pending = 'PENDING',
}

export type TaskStatusFilterComparison = {
  eq?: InputMaybe<TaskStatus>;
  gt?: InputMaybe<TaskStatus>;
  gte?: InputMaybe<TaskStatus>;
  iLike?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<TaskStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<TaskStatus>;
  lt?: InputMaybe<TaskStatus>;
  lte?: InputMaybe<TaskStatus>;
  neq?: InputMaybe<TaskStatus>;
  notILike?: InputMaybe<TaskStatus>;
  notIn?: InputMaybe<Array<TaskStatus>>;
  notLike?: InputMaybe<TaskStatus>;
};

export enum TaskType {
  ImportIntegrationCatalog = 'IMPORT_INTEGRATION_CATALOG',
  ImportIntegrationCustomers = 'IMPORT_INTEGRATION_CUSTOMERS',
  ImportSubscriptionsBulk = 'IMPORT_SUBSCRIPTIONS_BULK',
  RecalculateBatchEntitlements = 'RECALCULATE_BATCH_ENTITLEMENTS',
  RecalculateEntitlements = 'RECALCULATE_ENTITLEMENTS',
  ResyncIntegration = 'RESYNC_INTEGRATION',
  SubscriptionMigration = 'SUBSCRIPTION_MIGRATION',
  SubscriptionMigrationV2 = 'SUBSCRIPTION_MIGRATION_V2',
}

export type TaskTypeFilterComparison = {
  eq?: InputMaybe<TaskType>;
  gt?: InputMaybe<TaskType>;
  gte?: InputMaybe<TaskType>;
  iLike?: InputMaybe<TaskType>;
  in?: InputMaybe<Array<TaskType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<TaskType>;
  lt?: InputMaybe<TaskType>;
  lte?: InputMaybe<TaskType>;
  neq?: InputMaybe<TaskType>;
  notILike?: InputMaybe<TaskType>;
  notIn?: InputMaybe<Array<TaskType>>;
  notLike?: InputMaybe<TaskType>;
};

export type TaxExempt = {
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TestHook = {
  __typename?: 'TestHook';
  testHookEventType: Scalars['String']['output'];
  testHookPayload: Scalars['String']['output'];
};

export type TestHookInput = {
  endpointUrl: Scalars['String']['input'];
  environmentId: Scalars['String']['input'];
  hookEventType: EventLogType;
};

export type TestHookResult = {
  __typename?: 'TestHookResult';
  responseStatusCode?: Maybe<Scalars['Float']['output']>;
  responseStatusText?: Maybe<Scalars['String']['output']>;
  responseSuccess: Scalars['Boolean']['output'];
};

/** Tiers mode. */
export enum TiersMode {
  Graduated = 'GRADUATED',
  Volume = 'VOLUME',
}

export type TiersModeFilterComparison = {
  eq?: InputMaybe<TiersMode>;
  gt?: InputMaybe<TiersMode>;
  gte?: InputMaybe<TiersMode>;
  iLike?: InputMaybe<TiersMode>;
  in?: InputMaybe<Array<TiersMode>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<TiersMode>;
  lt?: InputMaybe<TiersMode>;
  lte?: InputMaybe<TiersMode>;
  neq?: InputMaybe<TiersMode>;
  notILike?: InputMaybe<TiersMode>;
  notIn?: InputMaybe<Array<TiersMode>>;
  notLike?: InputMaybe<TiersMode>;
};

export type TransferSubscriptionInput = {
  customerId: Scalars['String']['input'];
  destinationResourceId: Scalars['String']['input'];
  sourceResourceId: Scalars['String']['input'];
};

/** The input for transferring a subscription to a resource */
export type TransferSubscriptionToResourceInput = {
  /** The resource ID to transfer the subscription to */
  destinationResourceId?: InputMaybe<Scalars['String']['input']>;
  /** The environment ID of the subscription to transfer */
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the subscription to transfer */
  subscriptionId: Scalars['String']['input'];
};

/** Trial configuration */
export type TrialConfiguration = {
  __typename?: 'TrialConfiguration';
  /** Indicates the behavior of the subscription when the trial is expired. */
  trialEndBehavior: TrialEndBehavior;
};

/** Indicates the behavior of the subscription when the trial is expired. */
export enum TrialEndBehavior {
  /** cancel the subscription when the trial is expired */
  CancelSubscription = 'CANCEL_SUBSCRIPTION',
  /** convert the trial subscription to a paid subscription */
  ConvertToPaid = 'CONVERT_TO_PAID',
}

export type TrialOverrideConfigurationInput = {
  isTrial: Scalars['Boolean']['input'];
  /** Indicates the behavior of the subscription when the trial is expired. */
  trialEndBehavior?: InputMaybe<TrialEndBehavior>;
  trialEndDate?: InputMaybe<Scalars['DateTime']['input']>;
};

/** day or month. */
export enum TrialPeriodUnits {
  Day = 'DAY',
  Month = 'MONTH',
}

export type TrialedPlan = {
  __typename?: 'TrialedPlan';
  planId?: Maybe<Scalars['String']['output']>;
  planRefId?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  productRefId?: Maybe<Scalars['String']['output']>;
};

/** Input for triggering the subscription billing month ends soon webhook */
export type TriggerSubscriptionBillingMonthEndsSoonWebhookInput = {
  /** The subscription reference id to trigger the webhook for */
  subscriptionId: Scalars['String']['input'];
};

export type TriggerSubscriptionMigrationInput = {
  environmentId: Scalars['String']['input'];
  refId: Scalars['String']['input'];
  versionNumber: Scalars['Float']['input'];
};

export type TriggerSubscriptionMigrationResult = {
  __typename?: 'TriggerSubscriptionMigrationResult';
  taskId?: Maybe<Scalars['String']['output']>;
};

/** Input for triggering usage sync */
export type TriggerSubscriptionUsageSyncInput = {
  /** The customer id to trigger usage sync for */
  customerId: Scalars['String']['input'];
  /** The environment id to trigger usage sync for */
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The resource id to trigger usage sync for */
  resourceId?: InputMaybe<Scalars['String']['input']>;
};

export type TypographyConfiguration = {
  __typename?: 'TypographyConfiguration';
  body?: Maybe<FontVariant>;
  fontFamily?: Maybe<Scalars['String']['output']>;
  h1?: Maybe<FontVariant>;
  h2?: Maybe<FontVariant>;
  h3?: Maybe<FontVariant>;
};

export type TypographyConfigurationInput = {
  body?: InputMaybe<FontVariantInput>;
  fontFamily?: InputMaybe<Scalars['String']['input']>;
  h1?: InputMaybe<FontVariantInput>;
  h2?: InputMaybe<FontVariantInput>;
  h3?: InputMaybe<FontVariantInput>;
};

export type UnPublishedPackageError = {
  __typename?: 'UnPublishedPackageError';
  code: Scalars['String']['output'];
  isValidationError: Scalars['Boolean']['output'];
  nonPublishedPackageIds?: Maybe<Array<Scalars['String']['output']>>;
  packageType: Scalars['String']['output'];
};

export type UnarchiveCustomerInput = {
  customerId: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['String']['input']>;
};

export type UnarchiveEnvironmentInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type UnitAmountChangeVariables = {
  __typename?: 'UnitAmountChangeVariables';
  featureId?: Maybe<Scalars['String']['output']>;
  newUnitAmount?: Maybe<Scalars['Float']['output']>;
};

/** Transformation to the reported usage */
export type UnitTransformation = {
  __typename?: 'UnitTransformation';
  /** Divide usage by this number */
  divide: Scalars['Float']['output'];
  /** Singular feature units after the transformation */
  featureUnits?: Maybe<Scalars['String']['output']>;
  /** Plural feature units after the transformation */
  featureUnitsPlural?: Maybe<Scalars['String']['output']>;
  /** After division, either round the result up or down */
  round: UnitTransformationRound;
};

/** Input for unit transformation to be applied to the reported usage */
export type UnitTransformationInput = {
  /** Divide usage by this number */
  divide: Scalars['Int']['input'];
  /** Singular feature units after the transformation */
  featureUnits?: InputMaybe<Scalars['String']['input']>;
  /** Plural feature units after the transformation */
  featureUnitsPlural?: InputMaybe<Scalars['String']['input']>;
  /** After division, either round the result up or down */
  round?: InputMaybe<UnitTransformationRound>;
};

/** Unit transformation round type */
export enum UnitTransformationRound {
  /** Apply round down after transformation */
  Down = 'DOWN',
  /** Apply round up after transformation */
  Up = 'UP',
}

export type UnsupportedFeatureTypeError = {
  __typename?: 'UnsupportedFeatureTypeError';
  code: Scalars['String']['output'];
  featureType: Scalars['String']['output'];
};

export type UnsupportedVendorIdentifierError = {
  __typename?: 'UnsupportedVendorIdentifierError';
  code: Scalars['String']['output'];
  vendorIdentifier: Scalars['String']['output'];
};

export type UpdateAccountInput = {
  displayName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  subscriptionBillingAnchor?: InputMaybe<BillingAnchor>;
  subscriptionProrationBehavior?: InputMaybe<ProrationBehavior>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCouponInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  refId: Scalars['String']['input'];
};

export type UpdateCustomerInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  awsMarketplaceCustomerId?: InputMaybe<Scalars['String']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  billingInformation?: InputMaybe<CustomerBillingInfo>;
  couponRefId?: InputMaybe<Scalars['String']['input']>;
  crmId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['String']['input']>;
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  shouldWaitSync?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateEntitlementsOrderDto = {
  __typename?: 'UpdateEntitlementsOrderDTO';
  id: Scalars['String']['output'];
  order?: Maybe<Scalars['Float']['output']>;
};

export type UpdateExperimentInput = {
  controlGroupName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  productSettings?: InputMaybe<ProductSettingsInput>;
  refId: Scalars['String']['input'];
  variantGroupName?: InputMaybe<Scalars['String']['input']>;
  variantPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateFeatureInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  environmentId: Scalars['String']['input'];
  featureUnits?: InputMaybe<Scalars['String']['input']>;
  featureUnitsPlural?: InputMaybe<Scalars['String']['input']>;
  meter?: InputMaybe<CreateMeter>;
  refId: Scalars['String']['input'];
  /** Unit transformation to be applied to the reported usage */
  unitTransformation?: InputMaybe<UnitTransformationInput>;
};

export type UpdateHook = {
  /** A JSON containing the configuration for each event log type */
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  eventLogTypes?: InputMaybe<Array<EventLogType>>;
  id?: InputMaybe<Scalars['String']['input']>;
  secretKey?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<HookStatus>;
};

export type UpdateIntegrationInput = {
  auth0Credentials?: InputMaybe<Auth0CredentialsInput>;
  openFGACredentials?: InputMaybe<OpenFgaCredentialsInput>;
  /** Salesforce integration configuration */
  salesforceCredentials?: InputMaybe<SalesforceCredentialsInput>;
  stripeCredentials?: InputMaybe<StripeCredentialsInput>;
  vendorIdentifier: VendorIdentifier;
  zuoraCredentials?: InputMaybe<ZuoraCredentialsInput>;
};

export type UpdateOneEnvironmentInput = {
  /** The id of the record to update */
  id: Scalars['String']['input'];
  /** The update to apply. */
  update: EnvironmentInput;
};

export type UpdateOneHookInput = {
  /** The id of the record to update */
  id: Scalars['String']['input'];
  /** The update to apply. */
  update: UpdateHook;
};

export type UpdateOneIntegrationInput = {
  /** The id of the record to update */
  id: Scalars['String']['input'];
  /** The update to apply. */
  update: UpdateIntegrationInput;
};

export type UpdateOnePackageEntitlementInput = {
  /** The id of the record to update */
  id: Scalars['String']['input'];
  /** The update to apply. */
  update: PackageEntitlementUpdateInput;
};

export type UpdateOneProductInput = {
  /** The id of the record to update */
  id: Scalars['String']['input'];
  /** The update to apply. */
  update: ProductUpdateInput;
};

export type UpdateOnePromotionalEntitlementInput = {
  /** The id of the record to update */
  id: Scalars['String']['input'];
  /** The update to apply. */
  update: PromotionalEntitlementUpdateInput;
};

export type UpdatePackageEntitlementOrderInput = {
  entitlements: Array<UpdatePackageEntitlementOrderItemInput>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['String']['input'];
};

export type UpdatePackageEntitlementOrderItemInput = {
  id: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateSubscriptionEntitlementInput = {
  featureId?: InputMaybe<Scalars['String']['input']>;
  hasSoftLimit?: InputMaybe<Scalars['Boolean']['input']>;
  hasUnlimitedUsage?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars['Float']['input']>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
  yearlyResetPeriodConfiguration?: InputMaybe<YearlyResetPeriodConfigInput>;
};

export type UpdateSubscriptionInput = {
  additionalMetaData?: InputMaybe<Scalars['JSON']['input']>;
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  /** Coupon input */
  appliedCoupon?: InputMaybe<SubscriptionCouponInput>;
  awaitPaymentConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  billableFeatures?: InputMaybe<Array<BillableFeatureInput>>;
  billingInformation?: InputMaybe<SubscriptionBillingInfo>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  /** Budget configuration */
  budget?: InputMaybe<BudgetConfigurationInput>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** The minimum spend configuration */
  minimumSpend?: InputMaybe<SubscriptionMinimumSpendValueInput>;
  /** Override the price of the subscription */
  priceOverrides?: InputMaybe<Array<PriceOverrideInput>>;
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['String']['input']>;
  scheduleStrategy?: InputMaybe<ScheduleStrategy>;
  subscriptionEntitlements?: InputMaybe<Array<UpdateSubscriptionEntitlementInput>>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  trialEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  unitQuantity?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateUserInput = {
  department?: InputMaybe<Department>;
  name: Scalars['String']['input'];
};

/** An object representing the usage charged */
export type UsageCharged = {
  __typename?: 'UsageCharged';
  /** The id of the feature */
  featureId?: Maybe<Scalars['String']['output']>;
  /** The amount of units charged for usage usage */
  usageAmount: Scalars['Float']['output'];
};

export type UsageEvent = {
  __typename?: 'UsageEvent';
  /** The customer object reported */
  customer?: Maybe<Customer>;
  /** The customer id reported */
  customerId: Scalars['String']['output'];
  /** The dimensions reported */
  dimensions?: Maybe<Scalars['JSON']['output']>;
  /** The event name reported */
  eventName: Scalars['String']['output'];
  /** The id of the event */
  id: Scalars['String']['output'];
  /** The idempotency key reported */
  idempotencyKey: Scalars['String']['output'];
  /** The resource id reported */
  resourceId?: Maybe<Scalars['String']['output']>;
  /** The timestamp reported */
  timestamp: Scalars['String']['output'];
};

export type UsageEventReportInput = {
  customerId: Scalars['String']['input'];
  dimensions?: InputMaybe<Scalars['JSON']['input']>;
  eventName: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  resourceId?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UsageEventsInput = {
  /** Customer id filter */
  customerId?: InputMaybe<Scalars['String']['input']>;
  /** Environment id filter */
  environmentId: Scalars['String']['input'];
  /** List of event filters */
  filters?: InputMaybe<Array<MeterFilterDefinitionInput>>;
};

export type UsageEventsPreview = {
  __typename?: 'UsageEventsPreview';
  /** List of events */
  events: Array<UsageEvent>;
};

export type UsageEventsReportInput = {
  environmentId?: InputMaybe<Scalars['String']['input']>;
  usageEvents: Array<UsageEventReportInput>;
};

export type UsageHistory = {
  __typename?: 'UsageHistory';
  endDate?: Maybe<Scalars['DateTime']['output']>;
  groups?: Maybe<Array<GroupUsageHistory>>;
  /** Markers for events that affecting feature usage */
  markers: Array<UsageMarker>;
  startDate: Scalars['DateTime']['output'];
  usageMeasurements: Array<UsageMeasurementPoint>;
};

export type UsageHistoryInput = {
  customerRefId: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  featureRefId: Scalars['String']['input'];
  groupBy?: InputMaybe<Array<Scalars['String']['input']>>;
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  resourceRefId?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
  yearlyResetPeriodConfiguration?: InputMaybe<YearlyResetPeriodConfigInput>;
};

/** Point in the usage history */
export type UsageHistoryPoint = {
  __typename?: 'UsageHistoryPoint';
  /** Indicates whether there was usage reset in this point, see `markers` for details */
  isResetPoint: Scalars['Boolean']['output'];
  /** Timestamp of the usage history point */
  timestamp: Scalars['DateTime']['output'];
  /** Value of the usage history point */
  value: Scalars['Float']['output'];
};

/** Series of usage history */
export type UsageHistorySeries = {
  __typename?: 'UsageHistorySeries';
  /** Points in the usage history series */
  points: Array<UsageHistoryPoint>;
  /** Tags for the usage history series */
  tags: Array<UsageHistorySeriesTag>;
};

/** Tag for the usage history series */
export type UsageHistorySeriesTag = {
  __typename?: 'UsageHistorySeriesTag';
  /** Key of the tag */
  key: Scalars['String']['output'];
  /** Value of the tag */
  value: Scalars['String']['output'];
};

/** Usage history */
export type UsageHistoryV2 = {
  __typename?: 'UsageHistoryV2';
  /** Markers for events that affecting feature usage */
  markers: Array<UsageMarker>;
  /** Series of usage history */
  series: Array<UsageHistorySeries>;
};

/** Input for fetching usage history */
export type UsageHistoryV2Input = {
  /** Customer ID */
  customerId: Scalars['String']['input'];
  /** End date of the usage history */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  /** Feature ID */
  featureId: Scalars['String']['input'];
  /** Group usage per keys */
  groupBy?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Resource ID */
  resourceId?: InputMaybe<Scalars['String']['input']>;
  /** Start date of the usage history */
  startDate: Scalars['DateTime']['input'];
};

/** Marker for a event affecting usage */
export type UsageMarker = {
  __typename?: 'UsageMarker';
  /** Timestamp of the marker */
  timestamp: Scalars['DateTime']['output'];
  /** Type of marker */
  type: UsageMarkerType;
};

/** Type of marker for a usage history point */
export enum UsageMarkerType {
  /** Usage was reset due to reaching the end of a usage period */
  PeriodicReset = 'PERIODIC_RESET',
  /** Usage was reset due to a subscription change */
  SubscriptionChangeReset = 'SUBSCRIPTION_CHANGE_RESET',
}

export type UsageMeasurement = {
  __typename?: 'UsageMeasurement';
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  environment: Environment;
  environmentId: Scalars['String']['output'];
  feature: Feature;
  featureId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type UsageMeasurementAggregateGroupBy = {
  __typename?: 'UsageMeasurementAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type UsageMeasurementAvgAggregate = {
  __typename?: 'UsageMeasurementAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type UsageMeasurementConnection = {
  __typename?: 'UsageMeasurementConnection';
  /** Array of edges. */
  edges: Array<UsageMeasurementEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type UsageMeasurementCountAggregate = {
  __typename?: 'UsageMeasurementCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  environmentId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type UsageMeasurementCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId: Scalars['String']['input'];
  dimensions?: InputMaybe<Scalars['JSON']['input']>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  featureId: Scalars['String']['input'];
  resourceId?: InputMaybe<Scalars['String']['input']>;
  updateBehavior?: InputMaybe<UsageUpdateBehavior>;
  value: Scalars['Float']['input'];
};

export type UsageMeasurementEdge = {
  __typename?: 'UsageMeasurementEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the UsageMeasurement */
  node: UsageMeasurement;
};

export type UsageMeasurementFilter = {
  and?: InputMaybe<Array<UsageMeasurementFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customer?: InputMaybe<UsageMeasurementFilterCustomerFilter>;
  environmentId?: InputMaybe<StringFieldComparison>;
  feature?: InputMaybe<UsageMeasurementFilterFeatureFilter>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UsageMeasurementFilter>>;
};

export type UsageMeasurementFilterCustomerFilter = {
  and?: InputMaybe<Array<UsageMeasurementFilterCustomerFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmHubspotCompanyId?: InputMaybe<StringFieldComparison>;
  crmHubspotCompanyUrl?: InputMaybe<StringFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UsageMeasurementFilterCustomerFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  searchQuery?: InputMaybe<CustomerSearchQueryFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type UsageMeasurementFilterFeatureFilter = {
  and?: InputMaybe<Array<UsageMeasurementFilterFeatureFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  featureStatus?: InputMaybe<FeatureStatusFilterComparison>;
  featureType?: InputMaybe<FeatureTypeFilterComparison>;
  id?: InputMaybe<StringFieldComparison>;
  meterType?: InputMaybe<MeterTypeFilterComparison>;
  or?: InputMaybe<Array<UsageMeasurementFilterFeatureFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type UsageMeasurementMaxAggregate = {
  __typename?: 'UsageMeasurementMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type UsageMeasurementMinAggregate = {
  __typename?: 'UsageMeasurementMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type UsageMeasurementPoint = {
  __typename?: 'UsageMeasurementPoint';
  /** Timestamp of the measurement point */
  date: Scalars['DateTime']['output'];
  /** Indicates whether there was usage reset in this point, see `markers` for details */
  isResetPoint: Scalars['Boolean']['output'];
  /** Value of the measurement point */
  value: Scalars['Float']['output'];
};

export type UsageMeasurementSort = {
  direction: SortDirection;
  field: UsageMeasurementSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UsageMeasurementSortFields {
  CreatedAt = 'createdAt',
  EnvironmentId = 'environmentId',
  Id = 'id',
}

export type UsageMeasurementSumAggregate = {
  __typename?: 'UsageMeasurementSumAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type UsageMeasurementUpdated = {
  __typename?: 'UsageMeasurementUpdated';
  accountId: Scalars['String']['output'];
  currentUsage: Scalars['Float']['output'];
  customerId: Scalars['String']['output'];
  environmentId: Scalars['String']['output'];
  featureId: Scalars['String']['output'];
  /** @deprecated renamed to usagePeriodEnd */
  nextResetDate?: Maybe<Scalars['Float']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  /** The end date of the usage period that the measurement resides in (for entitlement with reset period) */
  usagePeriodEnd?: Maybe<Scalars['Float']['output']>;
  /** The start date of the usage period that the measurement resides in (for entitlement with reset period) */
  usagePeriodStart?: Maybe<Scalars['Float']['output']>;
};

export type UsageMeasurementWithCurrentUsage = {
  __typename?: 'UsageMeasurementWithCurrentUsage';
  createdAt: Scalars['DateTime']['output'];
  currentUsage?: Maybe<Scalars['Float']['output']>;
  customerId: Scalars['String']['output'];
  environmentId: Scalars['String']['output'];
  featureId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** @deprecated renamed to usagePeriodEnd */
  nextResetDate?: Maybe<Scalars['DateTime']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  /** The end date of the usage period that the measurement resides in (for entitlement with reset period) */
  usagePeriodEnd?: Maybe<Scalars['DateTime']['output']>;
  /** The start date of the usage period that the measurement resides in (for entitlement with reset period) */
  usagePeriodStart?: Maybe<Scalars['DateTime']['output']>;
  value: Scalars['Float']['output'];
};

export enum UsageUpdateBehavior {
  Delta = 'DELTA',
  Set = 'SET',
}

export type UsageUpdated = {
  __typename?: 'UsageUpdated';
  entitlement: Entitlement;
  usage: UsageMeasurementUpdated;
};

export type User = {
  __typename?: 'User';
  department?: Maybe<Department>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Intercom user identity token */
  intercomToken?: Maybe<Scalars['String']['output']>;
  lastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  memberships: Array<Member>;
  name?: Maybe<Scalars['String']['output']>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
};

export type ValidateMergeEnvironment = {
  __typename?: 'ValidateMergeEnvironment';
  errors: Array<Scalars['String']['output']>;
  isValid: Scalars['Boolean']['output'];
};

/** Input type for validateMergeEnvironment query. */
export type ValidateMergeEnvironmentInput = {
  /** The slug of the environment to copy to. */
  destinationEnvironmentSlug: Scalars['String']['input'];
  /** The slug of the environment to copy from. */
  sourceEnvironmentSlug: Scalars['String']['input'];
};

export enum VendorIdentifier {
  Auth0 = 'AUTH0',
  AwsMarketplace = 'AWS_MARKETPLACE',
  BigQuery = 'BIG_QUERY',
  Hubspot = 'HUBSPOT',
  OpenFga = 'OPEN_FGA',
  Salesforce = 'SALESFORCE',
  Snowflake = 'SNOWFLAKE',
  Stripe = 'STRIPE',
  Zuora = 'ZUORA',
}

export type VendorIdentifierFilterComparison = {
  eq?: InputMaybe<VendorIdentifier>;
  gt?: InputMaybe<VendorIdentifier>;
  gte?: InputMaybe<VendorIdentifier>;
  iLike?: InputMaybe<VendorIdentifier>;
  in?: InputMaybe<Array<VendorIdentifier>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<VendorIdentifier>;
  lt?: InputMaybe<VendorIdentifier>;
  lte?: InputMaybe<VendorIdentifier>;
  neq?: InputMaybe<VendorIdentifier>;
  notILike?: InputMaybe<VendorIdentifier>;
  notIn?: InputMaybe<Array<VendorIdentifier>>;
  notLike?: InputMaybe<VendorIdentifier>;
};

/** Weekly reset period according to configuration */
export enum WeeklyAccordingTo {
  EveryFriday = 'EveryFriday',
  EveryMonday = 'EveryMonday',
  EverySaturday = 'EverySaturday',
  EverySunday = 'EverySunday',
  EveryThursday = 'EveryThursday',
  EveryTuesday = 'EveryTuesday',
  EveryWednesday = 'EveryWednesday',
  SubscriptionStart = 'SubscriptionStart',
}

export type WeeklyResetPeriodConfig = {
  __typename?: 'WeeklyResetPeriodConfig';
  weeklyAccordingTo?: Maybe<WeeklyAccordingTo>;
};

export type WeeklyResetPeriodConfigInput = {
  accordingTo: WeeklyAccordingTo;
};

export type WidgetConfiguration = {
  __typename?: 'WidgetConfiguration';
  checkout?: Maybe<CheckoutConfiguration>;
  customerPortal?: Maybe<CustomerPortalConfiguration>;
  paywall?: Maybe<PaywallConfiguration>;
};

export type WidgetConfigurationUpdateInput = {
  checkoutConfiguration?: InputMaybe<CheckoutConfigurationInput>;
  customerPortalConfiguration?: InputMaybe<CustomerPortalConfigurationInput>;
  environmentId?: InputMaybe<Scalars['String']['input']>;
  paywallConfiguration?: InputMaybe<PaywallConfigurationInput>;
};

/** The widget type */
export enum WidgetType {
  Checkout = 'CHECKOUT',
  CustomerPortal = 'CUSTOMER_PORTAL',
  Paywall = 'PAYWALL',
}

/** Yearly reset period according to configuration */
export enum YearlyAccordingTo {
  SubscriptionStart = 'SubscriptionStart',
}

export type YearlyResetPeriodConfig = {
  __typename?: 'YearlyResetPeriodConfig';
  yearlyAccordingTo?: Maybe<YearlyAccordingTo>;
};

export type YearlyResetPeriodConfigInput = {
  accordingTo: YearlyAccordingTo;
};

export type ZuoraCredentials = {
  __typename?: 'ZuoraCredentials';
  baseUrl: Scalars['String']['output'];
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  stripePublishableKey?: Maybe<Scalars['String']['output']>;
  stripeSecretKey?: Maybe<Scalars['String']['output']>;
};

export type ZuoraCredentialsInput = {
  baseUrl: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  stripePublishableKey?: InputMaybe<Scalars['String']['input']>;
  stripeSecretKey?: InputMaybe<Scalars['String']['input']>;
};

export type ZuoraPaymentMethodForm = {
  __typename?: 'ZuoraPaymentMethodForm';
  key: Scalars['String']['output'];
  pageId: Scalars['String']['output'];
  pageUrl: Scalars['String']['output'];
  signature: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/** The group of the experiment */
export enum ExperimentGroupType {
  Control = 'CONTROL',
  Variant = 'VARIANT',
}

export type ExperimentInfo = {
  __typename?: 'experimentInfo';
  groupName: Scalars['String']['output'];
  groupType: ExperimentGroupType;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: ExperimentStatus;
};

export type CreateAccountMutationVariables = Exact<{
  input: Scalars['String']['input'];
}>;

export type CreateAccountMutation = {
  __typename?: 'Mutation';
  createAccount: {
    __typename?: 'Member';
    id: string;
    memberStatus: MemberStatus;
    email: string;
    account: { __typename?: 'Account'; id: string; displayName: string };
  };
};

export type CreateEnvironmentMutationVariables = Exact<{
  input: CreateOneEnvironmentInput;
}>;

export type CreateEnvironmentMutation = {
  __typename?: 'Mutation';
  createOneEnvironment: {
    __typename?: 'Environment';
    id: string;
    displayName: string;
    description?: string | null;
    slug: string;
  };
};

export type ArchiveEnvironmentMutationVariables = Exact<{
  input: ArchiveEnvironmentInput;
}>;

export type ArchiveEnvironmentMutation = {
  __typename?: 'Mutation';
  archiveEnvironment: { __typename?: 'Environment'; id: string };
};

export type HideGettingStartedPageMutationVariables = Exact<{
  memberId: Scalars['String']['input'];
}>;

export type HideGettingStartedPageMutation = { __typename?: 'Mutation'; hideGettingStartedPage: string };

export type InviteMembersMutationVariables = Exact<{
  input: InviteMembersInput;
}>;

export type InviteMembersMutation = {
  __typename?: 'Mutation';
  inviteMembers: {
    __typename?: 'MembersInviteResponse';
    successInvites: Array<string>;
    skippedInvites: Array<string>;
    failedInvites: Array<string>;
  };
};

export type MergeEnvironmentMutationVariables = Exact<{
  input: MergeEnvironmentInput;
}>;

export type MergeEnvironmentMutation = {
  __typename?: 'Mutation';
  mergeEnvironment: { __typename?: 'MergeEnvironment'; environmentSlug: string; taskIds: Array<string> };
};

export type ProvisionSandboxMutationVariables = Exact<{
  input: ProvisionSandboxInput;
}>;

export type ProvisionSandboxMutation = {
  __typename?: 'Mutation';
  provisionSandbox: {
    __typename?: 'Environment';
    id: string;
    displayName: string;
    description?: string | null;
    slug: string;
  };
};

export type RegisterMemberMutationVariables = Exact<{ [key: string]: never }>;

export type RegisterMemberMutation = {
  __typename?: 'Mutation';
  registerMember: {
    __typename?: 'User';
    id: string;
    email?: string | null;
    memberships: Array<{
      __typename?: 'Member';
      email: string;
      id: string;
      memberStatus: MemberStatus;
      cubejsToken?: string | null;
      account: { __typename?: 'Account'; id: string; displayName: string };
    }>;
  };
};

export type RemoveMemberMutationVariables = Exact<{
  memberId: Scalars['String']['input'];
}>;

export type RemoveMemberMutation = { __typename?: 'Mutation'; removeMember: string };

export type SetAccessRolesMutationVariables = Exact<{
  input: SetAccessRolesInput;
}>;

export type SetAccessRolesMutation = { __typename?: 'Mutation'; setAccessRoles?: string | null };

export type UpdateAccountMutationVariables = Exact<{
  input: UpdateAccountInput;
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  updateAccount: { __typename?: 'Account'; id: string; displayName: string; timezone?: string | null };
};

export type UpdateOneEnvironmentMutationVariables = Exact<{
  input: UpdateOneEnvironmentInput;
}>;

export type UpdateOneEnvironmentMutation = {
  __typename?: 'Mutation';
  updateOneEnvironment: { __typename?: 'Environment'; id: string; description?: string | null; displayName: string };
};

export type DumpEnvironmentForMergeComparisonQueryVariables = Exact<{
  input: DumpEnvironmentForForMergeComparisonInput;
}>;

export type DumpEnvironmentForMergeComparisonQuery = {
  __typename?: 'Query';
  dumpEnvironmentForMergeComparison: {
    __typename?: 'DumpEnvironmentForMergeComparison';
    preMergeDump: any;
    postMergeDump: any;
  };
};

export type DumpEnvironmentProductCatalogQueryVariables = Exact<{
  input: DumpEnvironmentProductCatalogInput;
}>;

export type DumpEnvironmentProductCatalogQuery = {
  __typename?: 'Query';
  dumpEnvironmentProductCatalog: { __typename?: 'ProductCatalogDump'; dump: any };
};

export type FetchAccountQueryVariables = Exact<{ [key: string]: never }>;

export type FetchAccountQuery = {
  __typename?: 'Query';
  fetchAccount?: {
    __typename?: 'Account';
    id: string;
    displayName: string;
    timezone?: string | null;
    subscriptionBillingAnchor?: BillingAnchor | null;
    subscriptionProrationBehavior?: ProrationBehavior | null;
    accountEmailDomain?: string | null;
    samlEnabled?: boolean | null;
  } | null;
};

export type FetchAccountMembersQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<MemberSort> | MemberSort>;
  filter?: InputMaybe<MemberFilter>;
}>;

export type FetchAccountMembersQuery = {
  __typename?: 'Query';
  members: {
    __typename?: 'MemberConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: any | null;
      startCursor?: any | null;
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
    };
    edges: Array<{
      __typename?: 'MemberEdge';
      cursor: any;
      node: {
        __typename?: 'Member';
        id: string;
        memberStatus: MemberStatus;
        email: string;
        userId: string;
        user?: {
          __typename?: 'User';
          name?: string | null;
          lastSeenAt?: any | null;
          profileImageUrl?: string | null;
        } | null;
        accessRoles?: {
          __typename?: 'AccessRoles';
          accountRole: AccountAccessRole;
          productionRole: EnvironmentAccessRole;
          nonProductionRole: EnvironmentAccessRole;
        } | null;
      };
    }>;
  };
};

export type EnvironmentFragment = {
  __typename?: 'Environment';
  id: string;
  displayName: string;
  description?: string | null;
  type: EnvironmentType;
  slug: string;
  hardenClientAccessEnabled: boolean;
  signingToken: string;
  isSandbox: boolean;
  color?: string | null;
  apiKeys: Array<{ __typename?: 'ApiKey'; id: string; keyType: ApiKeyType; token: string }>;
  account?: { __typename?: 'Account'; id: string; displayName: string } | null;
};

export type FetchEnvironmentsQueryVariables = Exact<{
  environmentSlug: Scalars['String']['input'];
}>;

export type FetchEnvironmentsQuery = {
  __typename?: 'Query';
  focusedEnvironment: {
    __typename?: 'EnvironmentConnection';
    edges: Array<{ __typename?: 'EnvironmentEdge'; node: { __typename?: 'Environment' } & EnvironmentFragment }>;
  };
  otherEnvironments: {
    __typename?: 'EnvironmentConnection';
    edges: Array<{ __typename?: 'EnvironmentEdge'; node: { __typename?: 'Environment' } & EnvironmentFragment }>;
  };
};

export type ValidateMergeEnvironmentQueryVariables = Exact<{
  input: ValidateMergeEnvironmentInput;
}>;

export type ValidateMergeEnvironmentQuery = {
  __typename?: 'Query';
  validateMergeEnvironment: { __typename?: 'ValidateMergeEnvironment'; errors: Array<string>; isValid: boolean };
};

export type FetchEnvironmentProvisionStatusQueryVariables = Exact<{
  filter: EnvironmentFilter;
}>;

export type FetchEnvironmentProvisionStatusQuery = {
  __typename?: 'Query';
  environments: {
    __typename?: 'EnvironmentConnection';
    edges: Array<{
      __typename?: 'EnvironmentEdge';
      node: { __typename?: 'Environment'; provisionStatus?: EnvironmentProvisionStatus | null };
    }>;
  };
};

export type FetchSubscriptionMigrationTasksQueryVariables = Exact<{
  filter?: InputMaybe<SubscriptionMigrationTaskFilter>;
}>;

export type FetchSubscriptionMigrationTasksQuery = {
  __typename?: 'Query';
  subscriptionMigrationTasks: {
    __typename?: 'SubscriptionMigrationTaskConnection';
    edges: Array<{
      __typename?: 'SubscriptionMigrationTaskEdge';
      node: { __typename?: 'SubscriptionMigrationTask' } & SubscriptionMigrationTaskFragment;
    }>;
  };
};

export type SubscriptionMigrationTaskFragment = {
  __typename?: 'SubscriptionMigrationTask';
  id: string;
  status: TaskStatus;
  taskType: TaskType;
  progress: number;
  initiatedPackageId?: string | null;
  createdAt?: any | null;
  startDate?: any | null;
  affectedCustomersCount?: number | null;
  packages: Array<{
    __typename?: 'PackageDTO';
    id: string;
    displayName: string;
    versionNumber: number;
    type: string;
    refId: string;
  }>;
};

export type ResendEmailVerificationMutationVariables = Exact<{ [key: string]: never }>;

export type ResendEmailVerificationMutation = { __typename?: 'Mutation'; resendEmailVerification: string };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation'; updateUser: { __typename?: 'User'; id: string } };

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = { __typename?: 'Query'; currentUser: { __typename?: 'User' } & UserFragment };

export type UserFragment = {
  __typename?: 'User';
  id: string;
  email?: string | null;
  name?: string | null;
  department?: Department | null;
  intercomToken?: string | null;
  memberships: Array<{
    __typename?: 'Member';
    id: string;
    createdAt?: any | null;
    email: string;
    serviceApiKey?: string | null;
    memberStatus: MemberStatus;
    hideGettingStartedPage?: boolean | null;
    cubejsToken?: string | null;
    customerToken?: string | null;
    account: { __typename?: 'Account'; id: string; displayName: string; accountStatus?: AccountStatus | null };
    accessRoles?: {
      __typename?: 'AccessRoles';
      accountRole: AccountAccessRole;
      nonProductionRole: EnvironmentAccessRole;
      productionRole: EnvironmentAccessRole;
    } | null;
  }>;
};

export type ArchiveOneCouponMutationVariables = Exact<{
  input: ArchiveCouponInput;
}>;

export type ArchiveOneCouponMutation = {
  __typename?: 'Mutation';
  archiveOneCoupon: { __typename?: 'Coupon' } & CouponFragment;
};

export type CreateOneCouponMutationVariables = Exact<{
  input: CreateCouponInput;
}>;

export type CreateOneCouponMutation = {
  __typename?: 'Mutation';
  createOneCoupon: { __typename?: 'Coupon' } & CouponFragment;
};

export type UpdateOneCouponMutationVariables = Exact<{
  input: UpdateCouponInput;
}>;

export type UpdateOneCouponMutation = {
  __typename?: 'Mutation';
  updateOneCoupon: { __typename?: 'Coupon' } & CouponFragment;
};

export type FetchCouponsByRefIdQueryVariables = Exact<{
  filter?: InputMaybe<CouponFilter>;
  sorting?: InputMaybe<Array<CouponSort> | CouponSort>;
  paging?: InputMaybe<CursorPaging>;
}>;

export type FetchCouponsByRefIdQuery = {
  __typename?: 'Query';
  coupons: {
    __typename?: 'CouponConnection';
    edges: Array<{ __typename?: 'CouponEdge'; node: { __typename?: 'Coupon' } & CouponFragment }>;
  };
};

export type FetchSlimCouponsQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  filter: CouponFilter;
}>;

export type FetchSlimCouponsQuery = {
  __typename?: 'Query';
  coupons: {
    __typename?: 'CouponConnection';
    edges: Array<{ __typename?: 'CouponEdge'; node: { __typename?: 'Coupon'; refId: string; name: string } }>;
  };
};

export type SubscriptionCouponDataFragment = {
  __typename?: 'SubscriptionCoupon';
  id: string;
  name: string;
  discountValue: number;
  percentOff?: number | null;
  type: CouponType;
  refId: string;
  durationInMonths?: number | null;
  startDate?: any | null;
  expirationDate?: any | null;
  amountsOff?: Array<{ __typename?: 'Money'; amount: number; currency: Currency }> | null;
};

export type CouponFragment = {
  __typename?: 'Coupon';
  id: string;
  discountValue: number;
  percentOff?: number | null;
  type: CouponType;
  additionalMetaData?: any | null;
  refId: string;
  name: string;
  description?: string | null;
  createdAt: any;
  updatedAt: any;
  billingId?: string | null;
  billingLinkUrl?: string | null;
  status: CouponStatus;
  source: CouponSource;
  durationInMonths?: number | null;
  amountsOff?: Array<{ __typename?: 'Money'; amount: number; currency: Currency }> | null;
  syncStates?: Array<{
    __typename?: 'SyncState';
    vendorIdentifier: VendorIdentifier;
    status: SyncStatus;
    error?: string | null;
  }> | null;
};

export type FetchCouponsQueryVariables = Exact<{
  filter?: InputMaybe<CouponFilter>;
  sorting?: InputMaybe<Array<CouponSort> | CouponSort>;
  paging?: InputMaybe<CursorPaging>;
}>;

export type FetchCouponsQuery = {
  __typename?: 'Query';
  coupons: {
    __typename?: 'CouponConnection';
    totalCount: number;
    edges: Array<{ __typename?: 'CouponEdge'; node: { __typename?: 'Coupon' } & CouponFragment }>;
  };
};

export type FetchCouponSyncStatesQueryVariables = Exact<{
  filter: CouponFilter;
}>;

export type FetchCouponSyncStatesQuery = {
  __typename?: 'Query';
  coupons: {
    __typename?: 'CouponConnection';
    edges: Array<{
      __typename?: 'CouponEdge';
      node: {
        __typename?: 'Coupon';
        billingId?: string | null;
        billingLinkUrl?: string | null;
        syncStates?: Array<{
          __typename?: 'SyncState';
          vendorIdentifier: VendorIdentifier;
          status: SyncStatus;
          error?: string | null;
        }> | null;
      };
    }>;
  };
};

export type ArchiveCustomerMutationVariables = Exact<{
  input: ArchiveCustomerInput;
}>;

export type ArchiveCustomerMutation = {
  __typename?: 'Mutation';
  archiveCustomer: { __typename?: 'Customer'; customerId: string };
};

export type AttachCustomerPaymentMethodMutationVariables = Exact<{
  input: AttachCustomerPaymentMethodInput;
}>;

export type AttachCustomerPaymentMethodMutation = {
  __typename?: 'Mutation';
  attachCustomerPaymentMethod: {
    __typename?: 'Customer';
    id: string;
    name?: string | null;
    email?: string | null;
    billingId?: string | null;
    billingLinkUrl?: string | null;
    updatedAt: any;
    additionalMetaData?: any | null;
    defaultPaymentMethodId?: string | null;
    defaultPaymentMethodType?: PaymentMethodType | null;
    defaultPaymentMethodLast4Digits?: string | null;
  };
};

export type CancelSubscriptionMutationVariables = Exact<{
  input: SubscriptionCancellationInput;
}>;

export type CancelSubscriptionMutation = {
  __typename?: 'Mutation';
  cancelSubscription: { __typename?: 'CustomerSubscription'; subscriptionId: string };
};

export type CancelSubscriptionUpdatesMutationVariables = Exact<{
  input: SubscriptionUpdateScheduleCancellationInput;
}>;

export type CancelSubscriptionUpdatesMutation = { __typename?: 'Mutation'; cancelSchedule: string };

export type CreateCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;

export type CreateCustomerMutation = {
  __typename?: 'Mutation';
  createOneCustomer: { __typename?: 'Customer'; id: string; customerId: string; name?: string | null; updatedAt: any };
};

export type CreateManyPromotionalEntitlementsMutationVariables = Exact<{
  input: CreateManyPromotionalEntitlementsInput;
}>;

export type CreateManyPromotionalEntitlementsMutation = {
  __typename?: 'Mutation';
  createManyPromotionalEntitlements: Array<{ __typename?: 'PromotionalEntitlement'; id: string }>;
};

export type CreateSubscriptionMutationVariables = Exact<{
  input: SubscriptionInput;
}>;

export type CreateSubscriptionMutation = {
  __typename?: 'Mutation';
  createSubscription: { __typename?: 'CustomerSubscription'; subscriptionId: string };
};

export type DetachCustomerPaymentMethodMutationVariables = Exact<{
  input: DetachCustomerPaymentMethodInput;
}>;

export type DetachCustomerPaymentMethodMutation = {
  __typename?: 'Mutation';
  detachCustomerPaymentMethod: { __typename?: 'Customer'; customerId: string; environmentId: string };
};

export type DeleteOnePromotionalEntitlementMutationVariables = Exact<{
  input: DeleteOnePromotionalEntitlementInput;
}>;

export type DeleteOnePromotionalEntitlementMutation = {
  __typename?: 'Mutation';
  deleteOnePromotionalEntitlement: { __typename?: 'PromotionalEntitlementDeleteResponse'; id?: string | null };
};

export type MarkInvoiceAsPaidMutationVariables = Exact<{
  input: MarkInvoiceAsPaidInput;
}>;

export type MarkInvoiceAsPaidMutation = { __typename?: 'Mutation'; markInvoiceAsPaid?: string | null };

export type MigrateSubscriptionToLatestMutationVariables = Exact<{
  input: SubscriptionMigrationInput;
}>;

export type MigrateSubscriptionToLatestMutation = {
  __typename?: 'Mutation';
  migrateSubscriptionToLatest: { __typename?: 'CustomerSubscription'; id: string };
};

export type PreparePaymentMethodFormMutationVariables = Exact<{
  input: PreparePaymentMethodFormInput;
}>;

export type PreparePaymentMethodFormMutation = {
  __typename?: 'Mutation';
  preparePaymentMethodForm: {
    __typename?: 'PreparedPaymentMethodForm';
    vendorIdentifier: VendorIdentifier;
    paymentMethodForm:
      | { __typename?: 'StripePaymentMethodForm'; clientSecret: string; stripePublishableKey?: string | null }
      | {
          __typename?: 'ZuoraPaymentMethodForm';
          signature: string;
          token: string;
          tenantId: string;
          key: string;
          pageId: string;
          pageUrl: string;
        };
  };
};

export type PreviewSubscriptionMutationVariables = Exact<{
  input: PreviewSubscriptionInput;
}>;

export type PreviewSubscriptionMutation = {
  __typename?: 'Mutation';
  previewSubscription: { __typename?: 'SubscriptionPreviewV2' } & SubscriptionPreviewV2Fragment;
};

export type SubscriptionPreviewV2Fragment = {
  __typename?: 'SubscriptionPreviewV2';
  isPlanDowngrade?: boolean | null;
  hasScheduledUpdates?: boolean | null;
  immediateInvoice: {
    __typename?: 'ImmediateSubscriptionPreviewInvoice';
    total: { __typename?: 'Money'; amount: number; currency: Currency };
    subTotal: { __typename?: 'Money'; amount: number; currency: Currency };
    totalExcludingTax: { __typename?: 'Money'; amount: number; currency: Currency };
    minimumSpendAdjustment: { __typename?: 'Money'; amount: number; currency: Currency };
    tax?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
    discount?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
    taxDetails?: {
      __typename?: 'SubscriptionPreviewTaxDetails';
      displayName: string;
      percentage: number;
      inclusive: boolean;
    } | null;
    discountDetails?: {
      __typename?: 'SubscriptionPreviewDiscount';
      type: DiscountType;
      value: number;
      durationType: DiscountDurationType;
      durationInMonths?: number | null;
    } | null;
    credits?: {
      __typename?: 'SubscriptionPreviewCredits';
      initial: { __typename?: 'Money'; amount: number; currency: Currency };
      used: { __typename?: 'Money'; amount: number; currency: Currency };
      remaining: { __typename?: 'Money'; amount: number; currency: Currency };
    } | null;
    proration?: {
      __typename?: 'SubscriptionPreviewProrations';
      prorationDate: any;
      hasProrations?: boolean | null;
      credit: { __typename?: 'Money'; amount: number; currency: Currency };
      debit: { __typename?: 'Money'; amount: number; currency: Currency };
      netAmount: { __typename?: 'Money'; amount: number; currency: Currency };
    } | null;
  };
  recurringInvoice?: {
    __typename?: 'SubscriptionPreviewInvoice';
    total: { __typename?: 'Money'; amount: number; currency: Currency };
    subTotal: { __typename?: 'Money'; amount: number; currency: Currency };
    totalExcludingTax: { __typename?: 'Money'; amount: number; currency: Currency };
    minimumSpendAdjustment: { __typename?: 'Money'; amount: number; currency: Currency };
    tax?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
    discount?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
    taxDetails?: {
      __typename?: 'SubscriptionPreviewTaxDetails';
      displayName: string;
      percentage: number;
      inclusive: boolean;
    } | null;
    discountDetails?: {
      __typename?: 'SubscriptionPreviewDiscount';
      type: DiscountType;
      value: number;
      durationType: DiscountDurationType;
      durationInMonths?: number | null;
    } | null;
  } | null;
  billingPeriodRange: { __typename?: 'DateRange'; start?: any | null; end?: any | null };
  freeItems?: Array<{ __typename?: 'FreeSubscriptionItem'; addonId: string; quantity: number }> | null;
};

export type ReportUsageMutationVariables = Exact<{
  input: ReportUsageInput;
}>;

export type ReportUsageMutation = {
  __typename?: 'Mutation';
  reportUsage: { __typename?: 'UsageMeasurementWithCurrentUsage'; id: string };
};

export type TriggerSubscriptionUsageSyncMutationVariables = Exact<{
  input: TriggerSubscriptionUsageSyncInput;
}>;

export type TriggerSubscriptionUsageSyncMutation = { __typename?: 'Mutation'; triggerSubscriptionUsageSync: boolean };

export type UpdateOneCustomerMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;

export type UpdateOneCustomerMutation = {
  __typename?: 'Mutation';
  updateOneCustomer: {
    __typename?: 'Customer';
    id: string;
    name?: string | null;
    email?: string | null;
    billingId?: string | null;
    billingLinkUrl?: string | null;
    updatedAt: any;
    additionalMetaData?: any | null;
    defaultPaymentMethodId?: string | null;
    defaultPaymentMethodType?: PaymentMethodType | null;
    defaultPaymentMethodLast4Digits?: string | null;
    crmHubspotCompanyId?: string | null;
    crmHubspotCompanyUrl?: string | null;
    syncStates?: Array<{
      __typename?: 'SyncState';
      status: SyncStatus;
      vendorIdentifier: VendorIdentifier;
      error?: string | null;
    }> | null;
  };
};

export type UpdateOnePromotionalEntitlementMutationVariables = Exact<{
  input: UpdateOnePromotionalEntitlementInput;
}>;

export type UpdateOnePromotionalEntitlementMutation = {
  __typename?: 'Mutation';
  updateOnePromotionalEntitlement: { __typename?: 'PromotionalEntitlement'; id: string };
};

export type UpdateOneSubscriptionMutationVariables = Exact<{
  input: UpdateSubscriptionInput;
}>;

export type UpdateOneSubscriptionMutation = {
  __typename?: 'Mutation';
  updateOneSubscription: {
    __typename?: 'CustomerSubscription';
    subscriptionId: string;
    additionalMetaData?: any | null;
  };
};

export type CustomerResourceFragment = { __typename?: 'CustomerResource'; resourceId: string };

export type ActiveSubscriptionsFragment = {
  __typename?: 'CustomerSubscription';
  status: SubscriptionStatus;
  pricingType: PricingType;
  resource?: { __typename?: 'CustomerResource'; resourceId: string } | null;
  plan: { __typename?: 'Plan'; refId: string };
};

export type GetActiveSubscriptionsQueryVariables = Exact<{
  input: GetActiveSubscriptionsInput;
}>;

export type GetActiveSubscriptionsQuery = {
  __typename?: 'Query';
  getActiveSubscriptions: Array<{ __typename?: 'CustomerSubscription' } & ActiveSubscriptionsFragment>;
};

export type FetchCustomerByCustomerIdQueryVariables = Exact<{
  input: GetCustomerByRefIdInput;
}>;

export type FetchCustomerByCustomerIdQuery = {
  __typename?: 'Query';
  getCustomerByRefId?: ({ __typename?: 'Customer' } & CustomerResponseFragment) | null;
};

export type CustomerResponseFragment = {
  __typename?: 'Customer';
  additionalMetaData?: any | null;
  id: string;
  customerId: string;
  name?: string | null;
  email?: string | null;
  updatedAt: any;
  billingCurrency?: Currency | null;
  hasActiveSubscription: boolean;
  billingId?: string | null;
  awsMarketplaceCustomerId?: string | null;
  defaultPaymentMethodId?: string | null;
  defaultPaymentMethodType?: PaymentMethodType | null;
  defaultPaymentMethodLast4Digits?: string | null;
  defaultPaymentExpirationMonth?: number | null;
  defaultPaymentExpirationYear?: number | null;
  billingLinkUrl?: string | null;
  crmHubspotCompanyId?: string | null;
  crmHubspotCompanyUrl?: string | null;
  salesforceId?: string | null;
  promotionalEntitlements: Array<
    { __typename?: 'PromotionalEntitlement' } & CustomerPromotionalEntitlementsDataFragment
  >;
  coupon?: ({ __typename?: 'Coupon' } & CouponFragment) | null;
  syncStates?: Array<{
    __typename?: 'SyncState';
    vendorIdentifier: VendorIdentifier;
    status: SyncStatus;
    error?: string | null;
  }> | null;
  experimentInfo?: {
    __typename?: 'experimentInfo';
    id: string;
    name: string;
    status: ExperimentStatus;
    groupType: ExperimentGroupType;
    groupName: string;
  } | null;
};

export type CustomerPromotionalEntitlementsDataFragment = {
  __typename?: 'PromotionalEntitlement';
  id: string;
  featureId: string;
  usageLimit?: number | null;
  hasUnlimitedUsage?: boolean | null;
  hasSoftLimit?: boolean | null;
  resetPeriod?: EntitlementResetPeriod | null;
  status: PromotionalEntitlementStatus;
  isVisible: boolean;
  period: PromotionalEntitlementPeriod;
  endDate?: any | null;
  resetPeriodConfiguration?:
    | ({ __typename: 'MonthlyResetPeriodConfig' } & MonthlyResetPeriodConfigFragment)
    | ({ __typename: 'WeeklyResetPeriodConfig' } & WeeklyResetPeriodConfigFragment)
    | ({ __typename: 'YearlyResetPeriodConfig' } & YearlyResetPeriodConfigFragment)
    | null;
  feature: {
    __typename?: 'Feature';
    id: string;
    displayName: string;
    refId: string;
    description?: string | null;
    featureStatus: FeatureStatus;
    updatedAt: any;
    environmentId: string;
    featureType: FeatureType;
    meterType?: MeterType | null;
    featureUnits?: string | null;
    featureUnitsPlural?: string | null;
  };
};

export type FetchCustomerSubscriptionsQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CustomerSubscriptionSort> | CustomerSubscriptionSort>;
  filter?: InputMaybe<CustomerSubscriptionFilter>;
}>;

export type FetchCustomerSubscriptionsQuery = {
  __typename?: 'Query';
  customerSubscriptions: {
    __typename?: 'CustomerSubscriptionConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: any | null;
      startCursor?: any | null;
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
    };
    edges: Array<{
      __typename?: 'CustomerSubscriptionEdge';
      cursor: any;
      node: { __typename?: 'CustomerSubscription' } & CustomerSubscriptionDataFragment;
    }>;
  };
};

export type CustomerSubscriptionDataFragment = {
  __typename?: 'CustomerSubscription';
  id: string;
  startDate: any;
  endDate?: any | null;
  trialEndDate?: any | null;
  cancellationDate?: any | null;
  effectiveEndDate?: any | null;
  currentBillingPeriodEnd?: any | null;
  billingCycleAnchor?: any | null;
  wasInTrial?: boolean | null;
  outdatedPricePackages?: Array<string> | null;
  subscriptionId: string;
  additionalMetaData?: any | null;
  budgetExceeded?: boolean | null;
  pricingType: PricingType;
  status: SubscriptionStatus;
  billingId?: string | null;
  oldBillingId?: string | null;
  billingLinkUrl?: string | null;
  paymentCollection: PaymentCollection;
  paymentCollectionMethod?: PaymentCollectionMethod | null;
  payingCustomer?: {
    __typename?: 'Customer';
    id: string;
    customerId: string;
    email?: string | null;
    name?: string | null;
    billingId?: string | null;
    billingLinkUrl?: string | null;
  } | null;
  trialConfiguration?: { __typename?: 'TrialConfiguration'; trialEndBehavior: TrialEndBehavior } | null;
  budget?: { __typename?: 'BudgetConfiguration'; limit: number; hasSoftLimit: boolean } | null;
  freeItems?: Array<{ __typename?: 'FreeSubscriptionItem'; addonId: string; quantity: number }> | null;
  resource?: ({ __typename?: 'CustomerResource' } & CustomerResourceFragment) | null;
  subscriptionEntitlements?: Array<{
    __typename?: 'SubscriptionEntitlement';
    id: string;
    usageLimit?: number | null;
    featureId: string;
    hasUnlimitedUsage?: boolean | null;
    hasSoftLimit?: boolean | null;
    resetPeriod?: EntitlementResetPeriod | null;
    resetPeriodConfiguration?:
      | ({ __typename: 'MonthlyResetPeriodConfig' } & MonthlyResetPeriodConfigFragment)
      | ({ __typename: 'WeeklyResetPeriodConfig' } & WeeklyResetPeriodConfigFragment)
      | ({ __typename: 'YearlyResetPeriodConfig' } & YearlyResetPeriodConfigFragment)
      | null;
    feature: {
      __typename?: 'Feature';
      id: string;
      displayName: string;
      description?: string | null;
      updatedAt: any;
      refId: string;
      featureStatus: FeatureStatus;
      environmentId: string;
      featureType: FeatureType;
      meterType?: MeterType | null;
      featureUnits?: string | null;
      featureUnitsPlural?: string | null;
    };
  }> | null;
  futureUpdates: Array<{ __typename?: 'SubscriptionFutureUpdate' } & SubscriptionFutureUpdateDataFragment>;
  plan: {
    __typename?: 'Plan';
    id: string;
    refId: string;
    displayName: string;
    status: PackageStatus;
    awsMarketplacePlanDimension?: string | null;
    versionNumber: number;
    isLatest?: boolean | null;
    entitlements?: Array<{
      __typename?: 'PackageEntitlement';
      id: string;
      usageLimit?: number | null;
      packageId: string;
      featureId: string;
      hasUnlimitedUsage?: boolean | null;
      hasSoftLimit?: boolean | null;
      isCustom?: boolean | null;
      resetPeriod?: EntitlementResetPeriod | null;
      resetPeriodConfiguration?:
        | ({ __typename: 'MonthlyResetPeriodConfig' } & MonthlyResetPeriodConfigFragment)
        | ({ __typename: 'WeeklyResetPeriodConfig' } & WeeklyResetPeriodConfigFragment)
        | ({ __typename: 'YearlyResetPeriodConfig' } & YearlyResetPeriodConfigFragment)
        | null;
      feature: {
        __typename?: 'Feature';
        id: string;
        displayName: string;
        description?: string | null;
        updatedAt: any;
        refId: string;
        featureStatus: FeatureStatus;
        environmentId: string;
        featureType: FeatureType;
        meterType?: MeterType | null;
        featureUnits?: string | null;
        featureUnitsPlural?: string | null;
      };
    }> | null;
    product: {
      __typename?: 'Product';
      refId: string;
      displayName?: string | null;
      productSettings: {
        __typename?: 'ProductSettings';
        subscriptionEndSetup: SubscriptionEndSetup;
        subscriptionCancellationTime: SubscriptionCancellationTime;
      };
    };
    prices?: Array<{ __typename?: 'Price' } & PriceFragment> | null;
    overagePrices?: Array<{ __typename?: 'Price' } & OveragePriceFragment> | null;
    minimumSpend?: Array<{ __typename?: 'MinimumSpend' } & MinimumSpendFragment> | null;
  };
  addons?: Array<{
    __typename?: 'SubscriptionAddon';
    id: string;
    quantity: number;
    addon: {
      __typename?: 'Addon';
      id: string;
      refId: string;
      displayName: string;
      versionNumber: number;
      isLatest?: boolean | null;
      maxQuantity?: number | null;
      prices?: Array<{ __typename?: 'Price' } & PriceFragment> | null;
    };
    price?: ({ __typename?: 'Price' } & PriceFragment) | null;
  }> | null;
  prices?: Array<{
    __typename?: 'SubscriptionPrice';
    usageLimit?: number | null;
    featureId?: string | null;
    billingModel?: BillingModel | null;
    price?: ({ __typename?: 'Price' } & PriceFragment) | null;
  }> | null;
  coupon?: ({ __typename?: 'SubscriptionCoupon' } & SubscriptionCouponDataFragment) | null;
  coupons?: Array<{ __typename?: 'SubscriptionCoupon' } & SubscriptionCouponDataFragment> | null;
  experimentInfo?: { __typename?: 'experimentInfo'; status: ExperimentStatus } | null;
  minimumSpend?: {
    __typename?: 'SubscriptionMinimumSpend';
    isOverride?: boolean | null;
    minimum?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
  } | null;
  latestInvoice?: { __typename?: 'SubscriptionInvoice'; errorMessage?: string | null } | null;
};

export type FetchCustomerSubscriptionsResourcesQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CustomerSubscriptionSort> | CustomerSubscriptionSort>;
  filter?: InputMaybe<CustomerSubscriptionFilter>;
}>;

export type FetchCustomerSubscriptionsResourcesQuery = {
  __typename?: 'Query';
  customerSubscriptions: {
    __typename?: 'CustomerSubscriptionConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: any | null;
      startCursor?: any | null;
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
    };
    edges: Array<{
      __typename?: 'CustomerSubscriptionEdge';
      cursor: any;
      node: { __typename?: 'CustomerSubscription' } & CustomerSubscriptionResourceFragment;
    }>;
  };
};

export type CustomerSubscriptionResourceFragment = {
  __typename?: 'CustomerSubscription';
  status: SubscriptionStatus;
  resource?: { __typename?: 'CustomerResource'; resourceId: string } | null;
  plan: {
    __typename?: 'Plan';
    refId: string;
    displayName: string;
    product: { __typename?: 'Product'; refId: string; displayName?: string | null };
  };
};

export type CustomerListFragment = {
  __typename?: 'Customer';
  id: string;
  customerId: string;
  name?: string | null;
  email?: string | null;
  updatedAt: any;
  totalActiveSubscription: number;
  totalActivePromotionalEntitlements: number;
  billingId?: string | null;
  billingLinkUrl?: string | null;
  defaultPaymentMethodId?: string | null;
  defaultPaymentMethodType?: PaymentMethodType | null;
  defaultPaymentMethodLast4Digits?: string | null;
};

export type FetchCustomersQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CustomerSort> | CustomerSort>;
  filter: CustomerFilter;
}>;

export type FetchCustomersQuery = {
  __typename?: 'Query';
  customers: {
    __typename?: 'CustomerConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: any | null;
      startCursor?: any | null;
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
    };
    edges: Array<{
      __typename?: 'CustomerEdge';
      cursor: any;
      node: { __typename?: 'Customer' } & CustomerListFragment;
    }>;
  };
};

export type FetchEffectiveEntitlementsByCustomerRefIdQueryVariables = Exact<{
  query: FetchEntitlementsQuery;
}>;

export type FetchEffectiveEntitlementsByCustomerRefIdQuery = {
  __typename?: 'Query';
  entitlements: Array<{ __typename?: 'EntitlementWithSummary' } & EntitlementDataFragment>;
};

export type EntitlementDataFragment = {
  __typename?: 'EntitlementWithSummary';
  usageLimit?: number | null;
  hasUnlimitedUsage: boolean;
  hasSoftLimit?: boolean | null;
  currentUsage?: number | null;
  resetPeriod?: EntitlementResetPeriod | null;
  usagePeriodEnd?: any | null;
  requestedUsage?: number | null;
  accessDeniedReason?: AccessDeniedReason | null;
  isGranted: boolean;
  meterId?: string | null;
  resetPeriodConfiguration?:
    | ({ __typename: 'MonthlyResetPeriodConfig' } & MonthlyResetPeriodConfigFragment)
    | ({ __typename: 'WeeklyResetPeriodConfig' } & WeeklyResetPeriodConfigFragment)
    | ({ __typename: 'YearlyResetPeriodConfig' } & YearlyResetPeriodConfigFragment)
    | null;
  feature?: ({ __typename?: 'EntitlementFeature' } & FeatureDataFragment) | null;
  summaries?: Array<{ __typename?: 'EntitlementSummary' } & EntitlementSummaryFragment> | null;
};

export type FeatureDataFragment = {
  __typename?: 'EntitlementFeature';
  id: string;
  description?: string | null;
  displayName: string;
  featureType: FeatureType;
  meterType?: MeterType | null;
  featureUnits?: string | null;
  featureUnitsPlural?: string | null;
  refId: string;
  unitTransformation?: {
    __typename?: 'UnitTransformation';
    divide: number;
    round: UnitTransformationRound;
    featureUnits?: string | null;
    featureUnitsPlural?: string | null;
  } | null;
};

export type EntitlementSummaryFragment = {
  __typename?: 'EntitlementSummary';
  isEffectiveEntitlement: boolean;
  addonQuantity?: number | null;
  plan?: {
    __typename?: 'Plan';
    refId: string;
    displayName: string;
    versionNumber: number;
    isLatest?: boolean | null;
    basePlan?: {
      __typename?: 'Plan';
      id: string;
      displayName: string;
      versionNumber: number;
      isLatest?: boolean | null;
    } | null;
    product: { __typename?: 'Product'; refId: string; displayName?: string | null };
  } | null;
  featurePackageEntitlement?: {
    __typename?: 'PackageEntitlement';
    createdAt?: any | null;
    usageLimit?: number | null;
    packageId: string;
    hasUnlimitedUsage?: boolean | null;
    hasSoftLimit?: boolean | null;
    package?: {
      __typename?: 'PackageDTO';
      type: string;
      refId: string;
      displayName: string;
      versionNumber: number;
      isLatest?: boolean | null;
    } | null;
  } | null;
  featurePromotionalEntitlement?: {
    __typename?: 'PromotionalEntitlement';
    createdAt?: any | null;
    usageLimit?: number | null;
    hasUnlimitedUsage?: boolean | null;
    hasSoftLimit?: boolean | null;
  } | null;
  priceEntitlement?: {
    __typename?: 'PriceEntitlement';
    usageLimit?: number | null;
    hasUnlimitedUsage?: boolean | null;
  } | null;
  subscription?: { __typename?: 'CustomerSubscription'; status: SubscriptionStatus } | null;
};

export type FetchNonActiveSubscriptionsCountQueryVariables = Exact<{
  filter?: InputMaybe<CustomerSubscriptionFilter>;
}>;

export type FetchNonActiveSubscriptionsCountQuery = {
  __typename?: 'Query';
  customerSubscriptions: { __typename?: 'CustomerSubscriptionConnection'; totalCount: number };
};

export type FetchSubscriptionByRefIdQueryVariables = Exact<{
  filter: CustomerSubscriptionFilter;
}>;

export type FetchSubscriptionByRefIdQuery = {
  __typename?: 'Query';
  customerSubscriptions: {
    __typename?: 'CustomerSubscriptionConnection';
    edges: Array<{
      __typename?: 'CustomerSubscriptionEdge';
      node: { __typename?: 'CustomerSubscription' } & SubscriptionDataFragment;
    }>;
  };
};

export type SubscriptionDataFragment = {
  __typename?: 'CustomerSubscription';
  id: string;
  refId: string;
  startDate: any;
  endDate?: any | null;
  trialEndDate?: any | null;
  cancellationDate?: any | null;
  effectiveEndDate?: any | null;
  currentBillingPeriodEnd?: any | null;
  billingCycleAnchor?: any | null;
  status: SubscriptionStatus;
  cancelReason?: SubscriptionCancelReason | null;
  subscriptionId: string;
  wasInTrial?: boolean | null;
  outdatedPricePackages?: Array<string> | null;
  additionalMetaData?: any | null;
  salesforceId?: string | null;
  budgetExceeded?: boolean | null;
  billingId?: string | null;
  oldBillingId?: string | null;
  billingLinkUrl?: string | null;
  crmId?: string | null;
  crmLinkUrl?: string | null;
  pricingType: PricingType;
  paymentCollection: PaymentCollection;
  paymentCollectionMethod?: PaymentCollectionMethod | null;
  payingCustomer?: {
    __typename?: 'Customer';
    id: string;
    customerId: string;
    email?: string | null;
    name?: string | null;
    billingId?: string | null;
    billingLinkUrl?: string | null;
  } | null;
  trialConfiguration?: { __typename?: 'TrialConfiguration'; trialEndBehavior: TrialEndBehavior } | null;
  budget?: { __typename?: 'BudgetConfiguration'; limit: number; hasSoftLimit: boolean } | null;
  resource?: ({ __typename?: 'CustomerResource' } & CustomerResourceFragment) | null;
  futureUpdates: Array<{ __typename?: 'SubscriptionFutureUpdate' } & SubscriptionFutureUpdateDataFragment>;
  coupon?: ({ __typename?: 'SubscriptionCoupon' } & SubscriptionCouponDataFragment) | null;
  freeItems?: Array<{ __typename?: 'FreeSubscriptionItem'; addonId: string; quantity: number }> | null;
  minimumSpend?: {
    __typename?: 'SubscriptionMinimumSpend';
    isOverride?: boolean | null;
    minimum?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
  } | null;
  subscriptionEntitlements?: Array<{
    __typename?: 'SubscriptionEntitlement';
    id: string;
    usageLimit?: number | null;
    featureId: string;
    hasUnlimitedUsage?: boolean | null;
    hasSoftLimit?: boolean | null;
    resetPeriod?: EntitlementResetPeriod | null;
    resetPeriodConfiguration?:
      | ({ __typename: 'MonthlyResetPeriodConfig' } & MonthlyResetPeriodConfigFragment)
      | ({ __typename: 'WeeklyResetPeriodConfig' } & WeeklyResetPeriodConfigFragment)
      | ({ __typename: 'YearlyResetPeriodConfig' } & YearlyResetPeriodConfigFragment)
      | null;
    feature: {
      __typename?: 'Feature';
      id: string;
      displayName: string;
      description?: string | null;
      updatedAt: any;
      refId: string;
      featureStatus: FeatureStatus;
      environmentId: string;
      featureType: FeatureType;
      meterType?: MeterType | null;
      featureUnits?: string | null;
      featureUnitsPlural?: string | null;
    };
  }> | null;
  plan: {
    __typename?: 'Plan';
    id: string;
    refId: string;
    displayName: string;
    awsMarketplacePlanDimension?: string | null;
    versionNumber: number;
    isLatest?: boolean | null;
    entitlements?: Array<{
      __typename?: 'PackageEntitlement';
      id: string;
      usageLimit?: number | null;
      packageId: string;
      featureId: string;
      hasUnlimitedUsage?: boolean | null;
      hasSoftLimit?: boolean | null;
      isCustom?: boolean | null;
      resetPeriod?: EntitlementResetPeriod | null;
      resetPeriodConfiguration?:
        | ({ __typename: 'MonthlyResetPeriodConfig' } & MonthlyResetPeriodConfigFragment)
        | ({ __typename: 'WeeklyResetPeriodConfig' } & WeeklyResetPeriodConfigFragment)
        | ({ __typename: 'YearlyResetPeriodConfig' } & YearlyResetPeriodConfigFragment)
        | null;
      feature: {
        __typename?: 'Feature';
        id: string;
        displayName: string;
        description?: string | null;
        updatedAt: any;
        refId: string;
        featureStatus: FeatureStatus;
        environmentId: string;
        featureType: FeatureType;
        meterType?: MeterType | null;
        featureUnits?: string | null;
        featureUnitsPlural?: string | null;
      };
    }> | null;
    product: {
      __typename?: 'Product';
      refId: string;
      displayName?: string | null;
      productSettings: {
        __typename?: 'ProductSettings';
        subscriptionEndSetup: SubscriptionEndSetup;
        subscriptionCancellationTime: SubscriptionCancellationTime;
        downgradePlan?: { __typename?: 'Plan'; refId: string; displayName: string } | null;
      };
    };
    prices?: Array<{ __typename?: 'Price' } & PriceFragment> | null;
    overagePrices?: Array<{ __typename?: 'Price' } & OveragePriceFragment> | null;
    compatibleAddons?: Array<{ __typename?: 'Addon'; refId: string; displayName: string }> | null;
    minimumSpend?: Array<{ __typename?: 'MinimumSpend' } & MinimumSpendFragment> | null;
  };
  addons?: Array<{ __typename?: 'SubscriptionAddon' } & SubscriptionAddonDataFragment> | null;
  customer: {
    __typename?: 'Customer';
    id: string;
    customerId: string;
    coupon?: ({ __typename?: 'Coupon' } & CouponFragment) | null;
  };
  prices?: Array<{
    __typename?: 'SubscriptionPrice';
    usageLimit?: number | null;
    featureId?: string | null;
    billingModel?: BillingModel | null;
    price?: ({ __typename?: 'Price' } & PriceFragment) | null;
  }> | null;
  syncStates?: Array<{
    __typename?: 'SyncState';
    vendorIdentifier: VendorIdentifier;
    status: SyncStatus;
    error?: string | null;
  }> | null;
  experimentInfo?: { __typename?: 'experimentInfo'; id: string; status: ExperimentStatus } | null;
  latestInvoice?: ({ __typename?: 'SubscriptionInvoice' } & SubscriptionInvoiceFragment) | null;
};

export type SubscriptionAddonDataFragment = {
  __typename?: 'SubscriptionAddon';
  updatedAt: any;
  quantity: number;
  addon: {
    __typename?: 'Addon';
    id: string;
    refId: string;
    displayName: string;
    description?: string | null;
    versionNumber: number;
    maxQuantity?: number | null;
    isLatest?: boolean | null;
    entitlements?: Array<{
      __typename?: 'PackageEntitlement';
      id: string;
      usageLimit?: number | null;
      feature: {
        __typename?: 'Feature';
        refId: string;
        displayName: string;
        featureUnits?: string | null;
        featureUnitsPlural?: string | null;
        featureType: FeatureType;
        meterType?: MeterType | null;
      };
    }> | null;
    prices?: Array<{ __typename?: 'Price' } & PriceFragment> | null;
  };
  price?: ({ __typename?: 'Price' } & PriceFragment) | null;
};

export type UsageHistoryQueryVariables = Exact<{
  input: UsageHistoryV2Input;
}>;

export type UsageHistoryQuery = {
  __typename?: 'Query';
  usageHistoryV2: { __typename?: 'UsageHistoryV2' } & UsageHistoryFragment;
};

export type UsageHistoryFragment = {
  __typename?: 'UsageHistoryV2';
  series: Array<{
    __typename?: 'UsageHistorySeries';
    tags: Array<{ __typename?: 'UsageHistorySeriesTag'; key: string; value: string }>;
    points: Array<{ __typename?: 'UsageHistoryPoint' } & UsageHistoryPointFragment>;
  }>;
  markers: Array<{ __typename?: 'UsageMarker' } & UsageHistoryMarkerFragment>;
};

export type UsageHistoryPointFragment = { __typename?: 'UsageHistoryPoint'; timestamp: any; value: number };

export type UsageHistoryMarkerFragment = { __typename?: 'UsageMarker'; type: UsageMarkerType; timestamp: any };

export type UsageMeasurementsQueryVariables = Exact<{
  filter?: InputMaybe<UsageMeasurementFilter>;
  sorting?: InputMaybe<Array<UsageMeasurementSort> | UsageMeasurementSort>;
}>;

export type UsageMeasurementsQuery = {
  __typename?: 'Query';
  usageMeasurements: {
    __typename?: 'UsageMeasurementConnection';
    edges: Array<{
      __typename?: 'UsageMeasurementEdge';
      node: { __typename?: 'UsageMeasurement' } & UsageMeasurementDataFragment;
    }>;
  };
};

export type UsageMeasurementDataFragment = { __typename?: 'UsageMeasurement'; createdAt: any; value: number };

export type PreviewNextInvoiceMutationVariables = Exact<{
  input: PreviewNextInvoiceInput;
}>;

export type PreviewNextInvoiceMutation = {
  __typename?: 'Mutation';
  previewNextInvoice: { __typename?: 'SubscriptionInvoicePreview' } & PreviewNextInvoiceFragment;
};

export type PreviewNextInvoiceFragment = {
  __typename?: 'SubscriptionInvoicePreview';
  lastUpdatedAt: any;
  amountDue: { __typename?: 'Money'; amount: number; currency: Currency };
  total: { __typename?: 'Money'; amount: number; currency: Currency };
  totalExcludingTax: { __typename?: 'Money'; amount: number; currency: Currency };
  minimumSpendAdjustment: { __typename?: 'Money'; amount: number; currency: Currency };
  subTotal: { __typename?: 'Money'; amount: number; currency: Currency };
  subTotalExcludingTax: { __typename?: 'Money'; amount: number; currency: Currency };
  tax?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
  taxDetails?: {
    __typename?: 'SubscriptionPreviewTaxDetails';
    displayName: string;
    percentage: number;
    inclusive: boolean;
  } | null;
  discount?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
  discountDetails?: {
    __typename?: 'SubscriptionPreviewDiscount';
    name?: string | null;
    type: DiscountType;
    value: number;
    durationType: DiscountDurationType;
    durationInMonths?: number | null;
    start: any;
    end?: any | null;
  } | null;
  credits?: {
    __typename?: 'SubscriptionPreviewCredits';
    initial: { __typename?: 'Money'; amount: number; currency: Currency };
    used: { __typename?: 'Money'; amount: number; currency: Currency };
    remaining: { __typename?: 'Money'; amount: number; currency: Currency };
  } | null;
  lines: Array<{
    __typename?: 'SubscriptionInvoicePreviewLineItem';
    type: InvoiceLineItemType;
    description: string;
    costDescription: string;
    quantity?: number | null;
    proration: boolean;
    usageLimit?: number | null;
    hasSoftLimit?: boolean | null;
    amount: { __typename?: 'Money'; amount: number; currency: Currency };
    unitPrice?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
    period: { __typename?: 'SubscriptionInvoicePreviewLineItemPeriod'; start: any; end: any };
    lines?: Array<{
      __typename?: 'SubscriptionInvoicePreviewLineItemData';
      type: InvoiceLineItemType;
      description: string;
      costDescription: string;
      quantity?: number | null;
      amount: { __typename?: 'Money'; amount: number; currency: Currency };
      unitPrice?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
    }> | null;
    price?: {
      __typename?: 'Price';
      id: string;
      billingPeriod: BillingPeriod;
      billingModel: BillingModel;
      billingCadence: BillingCadence;
      tiersMode?: TiersMode | null;
      billingId?: string | null;
      blockSize?: number | null;
      billingCountryCode?: string | null;
      minUnitQuantity?: number | null;
      maxUnitQuantity?: number | null;
      tiers?: Array<{
        __typename?: 'PriceTier';
        upTo?: number | null;
        unitPrice?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
        flatPrice?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
      }> | null;
      price?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
      feature?: ({ __typename?: 'Feature' } & FeatureFragment) | null;
    } | null;
  }>;
};

export type SubscriptionMaximumSpendMutationVariables = Exact<{
  input: PreviewNextInvoiceInput;
}>;

export type SubscriptionMaximumSpendMutation = {
  __typename?: 'Mutation';
  subscriptionMaximumSpend?: ({ __typename?: 'SubscriptionMaximumSpend' } & SubscriptionMaximumSpendFragment) | null;
};

export type SubscriptionMaximumSpendFragment = {
  __typename?: 'SubscriptionMaximumSpend';
  lastUpdatedAt: any;
  maximumSpend: { __typename?: 'Money'; amount: number; currency: Currency };
  total: { __typename?: 'Money'; amount: number; currency: Currency };
  subTotal: { __typename?: 'Money'; amount: number; currency: Currency };
  discount?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
  discountDetails?: {
    __typename?: 'SubscriptionMaximumSpendDiscount';
    name?: string | null;
    type?: DiscountType | null;
    value?: number | null;
    durationType?: DiscountDurationType | null;
    durationInMonths?: number | null;
    start?: any | null;
  } | null;
  lines?: Array<{
    __typename?: 'SubscriptionInvoicePreviewLineItem';
    type: InvoiceLineItemType;
    description: string;
    costDescription: string;
    quantity?: number | null;
    proration: boolean;
    usageLimit?: number | null;
    hasSoftLimit?: boolean | null;
    amount: { __typename?: 'Money'; amount: number; currency: Currency };
    unitPrice?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
    lines?: Array<{
      __typename?: 'SubscriptionInvoicePreviewLineItemData';
      type: InvoiceLineItemType;
      description: string;
      costDescription: string;
      quantity?: number | null;
      amount: { __typename?: 'Money'; amount: number; currency: Currency };
      unitPrice?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
    }> | null;
    price?: {
      __typename?: 'Price';
      id: string;
      billingPeriod: BillingPeriod;
      billingModel: BillingModel;
      billingCadence: BillingCadence;
      tiersMode?: TiersMode | null;
      billingId?: string | null;
      blockSize?: number | null;
      billingCountryCode?: string | null;
      minUnitQuantity?: number | null;
      maxUnitQuantity?: number | null;
      tiers?: Array<{
        __typename?: 'PriceTier';
        upTo?: number | null;
        unitPrice?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
        flatPrice?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
      }> | null;
      price?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
      feature?: ({ __typename?: 'Feature' } & FeatureFragment) | null;
    } | null;
  }> | null;
};

export type SearchSubscriptionsQueryVariables = Exact<{
  filter: CustomerSubscriptionFilter;
  paging?: InputMaybe<CursorPaging>;
}>;

export type SearchSubscriptionsQuery = {
  __typename?: 'Query';
  customerSubscriptions: {
    __typename?: 'CustomerSubscriptionConnection';
    edges: Array<{
      __typename?: 'CustomerSubscriptionEdge';
      node: {
        __typename?: 'CustomerSubscription';
        subscriptionId: string;
        customer: { __typename?: 'Customer'; customerId: string; name?: string | null; email?: string | null };
      };
    }>;
  };
};

export type SubscriptionFutureUpdateDataFragment = {
  __typename?: 'SubscriptionFutureUpdate';
  subscriptionScheduleType: SubscriptionScheduleType;
  scheduleStatus: SubscriptionScheduleStatus;
  scheduledExecutionTime: any;
  scheduleVariables?:
    | { __typename?: 'AddonChangeVariables'; addonRefId: string; newQuantity: number }
    | { __typename?: 'AddonPriceOverrideChangeVariables'; addonRefId: string; featureId?: string | null }
    | { __typename?: 'BillingPeriodChangeVariables'; billingPeriod?: BillingPeriod | null }
    | { __typename?: 'CouponChangeVariables'; couponId: string }
    | {
        __typename?: 'DowngradeChangeVariables';
        addonRefIds?: string | null;
        billingPeriod?: BillingPeriod | null;
        downgradePlanRefId: string;
        billableFeatures?: Array<{ __typename?: 'BillableFeature'; featureId: string; quantity: number }> | null;
        addons?: Array<{ __typename?: 'PlanChangeAddon'; addonRefId: string; quantity: number }> | null;
        priceOverrides?: Array<{
          __typename?: 'PriceOverrideChangeVariables';
          planRefId?: string | null;
          addonRefId?: string | null;
          featureId?: string | null;
        }> | null;
      }
    | {
        __typename?: 'PlanChangeVariables';
        planRefId: string;
        changeType: PlanChangeType;
        billingPeriod?: BillingPeriod | null;
        billableFeatures?: Array<{ __typename?: 'BillableFeature'; featureId: string; quantity: number }> | null;
        addons?: Array<{ __typename?: 'PlanChangeAddon'; addonRefId: string; quantity: number }> | null;
        priceOverrides?: Array<{
          __typename?: 'PriceOverrideChangeVariables';
          planRefId?: string | null;
          addonRefId?: string | null;
          featureId?: string | null;
        }> | null;
      }
    | { __typename?: 'PlanPriceOverrideChangeVariables'; planRefId: string; featureId?: string | null }
    | { __typename?: 'UnitAmountChangeVariables'; newUnitAmount?: number | null; featureId?: string | null }
    | null;
};

export type SubscriptionInvoiceFragment = {
  __typename?: 'SubscriptionInvoice';
  status: SubscriptionInvoiceStatus;
  errorMessage?: string | null;
  paymentUrl?: string | null;
  pdfUrl?: string | null;
  createdAt: any;
  updatedAt: any;
  amountDue?: number | null;
  currency?: string | null;
};

export type FetchCustomerSyncStatesQueryVariables = Exact<{
  filter: CustomerFilter;
}>;

export type FetchCustomerSyncStatesQuery = {
  __typename?: 'Query';
  customers: {
    __typename?: 'CustomerConnection';
    edges: Array<{
      __typename?: 'CustomerEdge';
      node: {
        __typename?: 'Customer';
        billingId?: string | null;
        defaultPaymentMethodId?: string | null;
        defaultPaymentMethodType?: PaymentMethodType | null;
        defaultPaymentMethodLast4Digits?: string | null;
        defaultPaymentExpirationMonth?: number | null;
        defaultPaymentExpirationYear?: number | null;
        billingLinkUrl?: string | null;
        crmHubspotCompanyId?: string | null;
        crmHubspotCompanyUrl?: string | null;
        syncStates?: Array<{
          __typename?: 'SyncState';
          vendorIdentifier: VendorIdentifier;
          status: SyncStatus;
          error?: string | null;
        }> | null;
      };
    }>;
  };
};

export type FetchSubscriptionSyncStatesQueryVariables = Exact<{
  filter: CustomerSubscriptionFilter;
}>;

export type FetchSubscriptionSyncStatesQuery = {
  __typename?: 'Query';
  customerSubscriptions: {
    __typename?: 'CustomerSubscriptionConnection';
    edges: Array<{
      __typename?: 'CustomerSubscriptionEdge';
      node: {
        __typename?: 'CustomerSubscription';
        billingId?: string | null;
        billingLinkUrl?: string | null;
        crmId?: string | null;
        crmLinkUrl?: string | null;
        status: SubscriptionStatus;
        paymentCollection: PaymentCollection;
        syncStates?: Array<{
          __typename?: 'SyncState';
          vendorIdentifier: VendorIdentifier;
          status: SyncStatus;
          error?: string | null;
        }> | null;
        latestInvoice?: ({ __typename?: 'SubscriptionInvoice' } & SubscriptionInvoiceFragment) | null;
      };
    }>;
  };
};

export type CustomerSubscriptionStatusByIdsQueryVariables = Exact<{
  filter: CustomerSubscriptionFilter;
}>;

export type CustomerSubscriptionStatusByIdsQuery = {
  __typename?: 'Query';
  customerSubscriptions: {
    __typename?: 'CustomerSubscriptionConnection';
    edges: Array<{
      __typename?: 'CustomerSubscriptionEdge';
      node: { __typename?: 'CustomerSubscription'; id: string };
    }>;
  };
};

export type FetchFeatureInspectorFiltersQueryVariables = Exact<{
  plansFilter?: InputMaybe<PlanFilter>;
  productsFilter?: InputMaybe<ProductFilter>;
  productsSorting?: InputMaybe<Array<ProductSort> | ProductSort>;
}>;

export type FetchFeatureInspectorFiltersQuery = {
  __typename?: 'Query';
  plans: {
    __typename?: 'PlanConnection';
    edges: Array<{
      __typename?: 'PlanEdge';
      node: {
        __typename?: 'Plan';
        id: string;
        refId: string;
        displayName: string;
        productId?: string | null;
        basePlan?: { __typename?: 'Plan'; id: string } | null;
        inheritedEntitlements?: Array<{
          __typename?: 'PackageEntitlement';
          featureId: string;
          resetPeriod?: EntitlementResetPeriod | null;
          usageLimit?: number | null;
        }> | null;
        entitlements?: Array<{
          __typename?: 'PackageEntitlement';
          featureId: string;
          resetPeriod?: EntitlementResetPeriod | null;
          usageLimit?: number | null;
        }> | null;
      };
    }>;
  };
  products: {
    __typename?: 'ProductConnection';
    edges: Array<{
      __typename?: 'ProductEdge';
      node: { __typename?: 'Product' } & FeatureInspectorProductFilterFragment;
    }>;
  };
};

export type FeatureInspectorProductFilterFragment = {
  __typename?: 'Product';
  id: string;
  refId: string;
  displayName?: string | null;
  multipleSubscriptions: boolean;
};

export type FetchSubscriptionsOverviewFiltersQueryVariables = Exact<{
  productsFilter?: InputMaybe<ProductFilter>;
}>;

export type FetchSubscriptionsOverviewFiltersQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'ProductConnection';
    edges: Array<{
      __typename?: 'ProductEdge';
      node: { __typename?: 'Product'; id: string; refId: string; displayName?: string | null };
    }>;
  };
};

export type CreateManyPackageEntitlementMutationVariables = Exact<{
  input: CreateManyPackageEntitlementsInput;
}>;

export type CreateManyPackageEntitlementMutation = {
  __typename?: 'Mutation';
  createManyPackageEntitlements: Array<{ __typename?: 'PackageEntitlement'; id: string }>;
};

export type DeleteOnePackageEntitlementMutationVariables = Exact<{
  input: DeleteOnePackageEntitlementInput;
}>;

export type DeleteOnePackageEntitlementMutation = {
  __typename?: 'Mutation';
  deleteOnePackageEntitlement: { __typename?: 'PackageEntitlementDeleteResponse'; id?: string | null };
};

export type UpdateEntitlementsOrderMutationVariables = Exact<{
  input: UpdatePackageEntitlementOrderInput;
}>;

export type UpdateEntitlementsOrderMutation = {
  __typename?: 'Mutation';
  updateEntitlementsOrder: Array<{ __typename?: 'UpdateEntitlementsOrderDTO'; id: string; order?: number | null }>;
};

export type UpdateOnePackageEntitlementMutationVariables = Exact<{
  input: UpdateOnePackageEntitlementInput;
}>;

export type UpdateOnePackageEntitlementMutation = {
  __typename?: 'Mutation';
  updateOnePackageEntitlement: { __typename?: 'PackageEntitlement'; id: string };
};

export type FetchPackageEntitlementsQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<PackageEntitlementFilter>;
}>;

export type FetchPackageEntitlementsQuery = {
  __typename?: 'Query';
  packageEntitlements: { __typename?: 'PackageEntitlementConnection' } & PackageEntitlementsFragment;
};

export type PackageEntitlementsFragment = {
  __typename?: 'PackageEntitlementConnection';
  totalCount: number;
  edges: Array<{
    __typename?: 'PackageEntitlementEdge';
    cursor: any;
    node: { __typename?: 'PackageEntitlement' } & PackageEntitlementFragment;
  }>;
  pageInfo: {
    __typename?: 'PageInfo';
    endCursor?: any | null;
    startCursor?: any | null;
    hasNextPage?: boolean | null;
    hasPreviousPage?: boolean | null;
  };
};

export type PackageEntitlementFragment = {
  __typename?: 'PackageEntitlement';
  id: string;
  packageId: string;
  featureId: string;
  usageLimit?: number | null;
  hasUnlimitedUsage?: boolean | null;
  hasSoftLimit?: boolean | null;
  resetPeriod?: EntitlementResetPeriod | null;
  createdAt?: any | null;
  order?: number | null;
  environmentId: string;
  resetPeriodConfiguration?:
    | ({ __typename: 'MonthlyResetPeriodConfig' } & MonthlyResetPeriodConfigFragment)
    | ({ __typename: 'WeeklyResetPeriodConfig' } & WeeklyResetPeriodConfigFragment)
    | ({ __typename: 'YearlyResetPeriodConfig' } & YearlyResetPeriodConfigFragment)
    | null;
  feature: {
    __typename?: 'Feature';
    id: string;
    displayName: string;
    refId: string;
    description?: string | null;
    featureStatus: FeatureStatus;
    updatedAt: any;
    environmentId: string;
    featureType: FeatureType;
    meterType?: MeterType | null;
    featureUnits?: string | null;
    featureUnitsPlural?: string | null;
    hasMeter?: boolean | null;
  };
};

export type YearlyResetPeriodConfigFragment = {
  __typename?: 'YearlyResetPeriodConfig';
  yearlyAccordingTo?: YearlyAccordingTo | null;
};

export type MonthlyResetPeriodConfigFragment = {
  __typename?: 'MonthlyResetPeriodConfig';
  monthlyAccordingTo?: MonthlyAccordingTo | null;
};

export type WeeklyResetPeriodConfigFragment = {
  __typename?: 'WeeklyResetPeriodConfig';
  weeklyAccordingTo?: WeeklyAccordingTo | null;
};

export type EventLogsQueryVariables = Exact<{
  filter: EventLogFilter;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<EventLogSort> | EventLogSort>;
}>;

export type EventLogsQuery = {
  __typename?: 'Query';
  eventLogs: {
    __typename?: 'EventLogConnection';
    edges: Array<{
      __typename?: 'EventLogEdge';
      node: {
        __typename?: 'EventLog';
        id: string;
        createdAt: any;
        accountId: string;
        environmentId: string;
        eventLogType: EventLogType;
        entityType?: EventEntityType | null;
        entityId?: string | null;
        parentEntityId?: string | null;
        payload: any;
        actor?: {
          __typename?: 'EventActorInfo';
          type?: EventActor | null;
          name?: string | null;
          email?: string | null;
          ipAddress?: string | null;
        } | null;
        webhooks?: Array<{ __typename?: 'EventWebhook'; id: string; endpoint: string }> | null;
        request?: {
          __typename?: 'EventRequest';
          traceId?: string | null;
          body?: any | null;
          response?: any | null;
        } | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  };
};

export type CreateOneExperimentMutationVariables = Exact<{
  input: CreateExperimentInput;
}>;

export type CreateOneExperimentMutation = {
  __typename?: 'Mutation';
  createOneExperiment: { __typename?: 'Experiment'; refId: string };
};

export type StartExperimentMutationVariables = Exact<{
  input: StartExperimentInput;
}>;

export type StartExperimentMutation = { __typename?: 'Mutation'; startExperiment: string };

export type StopExperimentMutationVariables = Exact<{
  input: StopExperimentInput;
}>;

export type StopExperimentMutation = { __typename?: 'Mutation'; stopExperiment: string };

export type UpdateOneExperimentMutationVariables = Exact<{
  input: UpdateExperimentInput;
}>;

export type UpdateOneExperimentMutation = {
  __typename?: 'Mutation';
  updateOneExperiment: { __typename?: 'Experiment'; refId: string };
};

export type ExperimentSlimFragment = {
  __typename?: 'Experiment';
  refId: string;
  name: string;
  product?: { __typename?: 'Product'; refId: string } | null;
};

export type FetchActiveExperimentsQueryVariables = Exact<{
  filter: ExperimentFilter;
  paging?: InputMaybe<CursorPaging>;
}>;

export type FetchActiveExperimentsQuery = {
  __typename?: 'Query';
  experiments: {
    __typename?: 'ExperimentConnection';
    edges: Array<{ __typename?: 'ExperimentEdge'; node: { __typename?: 'Experiment' } & ExperimentSlimFragment }>;
  };
};

export type ExperimentFragment = {
  __typename?: 'Experiment';
  id: string;
  refId: string;
  name: string;
  description?: string | null;
  status: ExperimentStatus;
  startedAt?: any | null;
  stoppedAt?: any | null;
  controlGroupName: string;
  variantGroupName: string;
  variantPercentage: number;
  productSettings: { __typename?: 'ProductSettings' } & ProductSettingsFragment;
  initialProductSettings?: ({ __typename?: 'ProductSettings' } & ProductSettingsFragment) | null;
  product?: ({ __typename?: 'Product' } & ProductFragment) | null;
};

export type FetchExperimentByRefIdQueryVariables = Exact<{
  filter: ExperimentFilter;
}>;

export type FetchExperimentByRefIdQuery = {
  __typename?: 'Query';
  experiments: {
    __typename?: 'ExperimentConnection';
    edges: Array<{ __typename?: 'ExperimentEdge'; node: { __typename?: 'Experiment' } & ExperimentFragment }>;
  };
};

export type GetExperimentStatsQueryVariables = Exact<{
  query: ExperimentStatsQuery;
}>;

export type GetExperimentStatsQuery = {
  __typename?: 'Query';
  getExperimentStats: {
    __typename?: 'ExperimentStats';
    controlSubscriptions: number;
    controlPaidSubscriptions: number;
    variantSubscriptions: number;
    variantPaidSubscriptions: number;
  };
};

export type ExperimentListFragment = {
  __typename?: 'Experiment';
  id: string;
  refId: string;
  name: string;
  status: ExperimentStatus;
  startedAt?: any | null;
  stoppedAt?: any | null;
  variantPercentage: number;
  controlGroupName: string;
  variantGroupName: string;
  description?: string | null;
};

export type FetchExperimentsQueryVariables = Exact<{
  activeExperimentsFilter: ExperimentFilter;
  nonActiveExperimentsCountFilter: ExperimentFilter;
}>;

export type FetchExperimentsQuery = {
  __typename?: 'Query';
  activeExperiments: {
    __typename?: 'ExperimentConnection';
    edges: Array<{ __typename?: 'ExperimentEdge'; node: { __typename?: 'Experiment' } & ExperimentFragment }>;
  };
  nonActiveExperimentsCount: { __typename?: 'ExperimentConnection'; totalCount: number };
};

export type FetchNonActiveExperimentsQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ExperimentSort> | ExperimentSort>;
  filter: ExperimentFilter;
}>;

export type FetchNonActiveExperimentsQuery = {
  __typename?: 'Query';
  experiments: {
    __typename?: 'ExperimentConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: any | null;
      startCursor?: any | null;
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
    };
    edges: Array<{
      __typename?: 'ExperimentEdge';
      cursor: any;
      node: { __typename?: 'Experiment' } & ExperimentListFragment;
    }>;
  };
};

export type IsExperimentNameUniqueQueryVariables = Exact<{
  paging: CursorPaging;
  filter: ExperimentFilter;
}>;

export type IsExperimentNameUniqueQuery = {
  __typename?: 'Query';
  experiments: { __typename?: 'ExperimentConnection'; totalCount: number };
};

export type ArchiveFeatureMutationVariables = Exact<{
  input: ArchiveFeatureInput;
}>;

export type ArchiveFeatureMutation = {
  __typename?: 'Mutation';
  archiveFeature: { __typename?: 'Feature'; id: string };
};

export type CreateFeatureMutationVariables = Exact<{
  input: FeatureInput;
}>;

export type CreateFeatureMutation = {
  __typename?: 'Mutation';
  createFeature: { __typename?: 'Feature' } & FeatureCreatedFragment;
};

export type FeatureCreatedFragment = {
  __typename?: 'Feature';
  id: string;
  displayName: string;
  description?: string | null;
  updatedAt: any;
  refId: string;
  featureStatus: FeatureStatus;
  environmentId: string;
  featureType: FeatureType;
  meterType?: MeterType | null;
  featureUnits?: string | null;
  featureUnitsPlural?: string | null;
  hasMeter?: boolean | null;
};

export type DeleteFeatureMutationVariables = Exact<{
  input: DeleteFeatureInput;
}>;

export type DeleteFeatureMutation = { __typename?: 'Mutation'; deleteFeature: { __typename?: 'Feature'; id: string } };

export type UpdateFeatureMutationVariables = Exact<{
  input: UpdateFeatureInput;
}>;

export type UpdateFeatureMutation = { __typename?: 'Mutation'; updateFeature: { __typename?: 'Feature'; id: string } };

export type FeatureFragment = {
  __typename?: 'Feature';
  displayName: string;
  id: string;
  refId: string;
  createdAt?: any | null;
  updatedAt: any;
  description?: string | null;
  featureStatus: FeatureStatus;
  environmentId: string;
  featureType: FeatureType;
  meterType?: MeterType | null;
  featureUnits?: string | null;
  featureUnitsPlural?: string | null;
  additionalMetaData?: any | null;
  hasEntitlements?: boolean | null;
  hasMeter?: boolean | null;
  unitTransformation?: {
    __typename?: 'UnitTransformation';
    divide: number;
    round: UnitTransformationRound;
    featureUnits?: string | null;
    featureUnitsPlural?: string | null;
  } | null;
  meter?: {
    __typename?: 'Meter';
    id: string;
    filters: Array<{
      __typename?: 'MeterFilterDefinition';
      conditions: Array<{
        __typename?: 'MeterCondition';
        field: string;
        operation: ConditionOperation;
        value?: string | null;
      }>;
    }>;
    aggregation: { __typename?: 'Aggregation'; function: AggregationFunction; field?: string | null };
  } | null;
};

export type FeatureListFragment = {
  __typename?: 'Feature';
  displayName: string;
  id: string;
  refId: string;
  createdAt?: any | null;
  updatedAt: any;
  description?: string | null;
  featureStatus: FeatureStatus;
  environmentId: string;
  featureType: FeatureType;
  meterType?: MeterType | null;
  featureUnits?: string | null;
  featureUnitsPlural?: string | null;
  additionalMetaData?: any | null;
  hasMeter?: boolean | null;
};

export type FetchAggregatedUsageByCustomerQueryVariables = Exact<{
  input: AggregatedEventsByCustomerInput;
}>;

export type FetchAggregatedUsageByCustomerQuery = {
  __typename?: 'Query';
  aggregatedEventsByCustomer: {
    __typename?: 'AggregatedEventsByCustomer';
    aggregatedUsage: Array<{ __typename?: 'CustomerAggregatedUsage' } & AggregatedUsageByCustomerFragment>;
  };
};

export type AggregatedUsageByCustomerFragment = {
  __typename?: 'CustomerAggregatedUsage';
  customerId: string;
  usage: number;
};

export type EventsFieldsQueryVariables = Exact<{
  input: EventsFieldsInput;
}>;

export type EventsFieldsQuery = { __typename?: 'Query'; eventsFields: { __typename?: 'EventsFields'; fields: any } };

export type FeatureAssociatedLatestPackagesQueryVariables = Exact<{
  input: FeatureAssociatedLatestPackages;
}>;

export type FeatureAssociatedLatestPackagesQuery = {
  __typename?: 'Query';
  featureAssociatedLatestPackages: Array<{ __typename?: 'PackageDTO' } & SlimPackageFragment>;
};

export type SlimPackageFragment = { __typename?: 'PackageDTO'; refId: string; displayName: string; type: string };

export type FetchFeatureByRefIdQueryVariables = Exact<{
  featureFilter: FeatureFilter;
}>;

export type FetchFeatureByRefIdQuery = {
  __typename?: 'Query';
  features: {
    __typename?: 'FeatureConnection';
    edges: Array<{ __typename?: 'FeatureEdge'; node: { __typename?: 'Feature' } & FeatureFragment }>;
  };
};

export type FetchSlimFeaturesQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  filter: FeatureFilter;
}>;

export type FetchSlimFeaturesQuery = {
  __typename?: 'Query';
  features: {
    __typename?: 'FeatureConnection';
    edges: Array<{ __typename?: 'FeatureEdge'; node: { __typename?: 'Feature'; refId: string; displayName: string } }>;
  };
};

export type FetchFeaturesQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<FeatureSort> | FeatureSort>;
  filter: FeatureFilter;
}>;

export type FetchFeaturesQuery = {
  __typename?: 'Query';
  features: { __typename?: 'FeatureConnection' } & FeaturesFragment;
};

export type FeaturesFragment = {
  __typename?: 'FeatureConnection';
  totalCount: number;
  pageInfo: {
    __typename?: 'PageInfo';
    endCursor?: any | null;
    startCursor?: any | null;
    hasNextPage?: boolean | null;
    hasPreviousPage?: boolean | null;
  };
  edges: Array<{ __typename?: 'FeatureEdge'; cursor: any; node: { __typename?: 'Feature' } & FeatureListFragment }>;
};

export type UsageEventsQueryVariables = Exact<{
  input: UsageEventsInput;
}>;

export type UsageEventsQuery = {
  __typename?: 'Query';
  usageEvents: { __typename?: 'UsageEventsPreview'; events: Array<{ __typename?: 'UsageEvent' } & UsageEventFragment> };
};

export type UsageEventFragment = {
  __typename?: 'UsageEvent';
  id: string;
  customerId: string;
  resourceId?: string | null;
  eventName: string;
  timestamp: string;
  dimensions?: any | null;
};

export type ValidateFeatureIdQueryVariables = Exact<{
  featureFilter: FeatureFilter;
}>;

export type ValidateFeatureIdQuery = {
  __typename?: 'Query';
  features: {
    __typename?: 'FeatureConnection';
    edges: Array<{ __typename?: 'FeatureEdge'; node: { __typename?: 'Feature'; featureStatus: FeatureStatus } }>;
  };
};

export type CreateIntegrationMutationVariables = Exact<{
  input: CreateOneIntegrationInput;
}>;

export type CreateIntegrationMutation = {
  __typename?: 'Mutation';
  createOneIntegration: { __typename?: 'Integration'; id: string };
};

export type DeleteOneIntegrationMutationVariables = Exact<{
  input: DeleteOneIntegrationInput;
}>;

export type DeleteOneIntegrationMutation = {
  __typename?: 'Mutation';
  deleteOneIntegration: { __typename?: 'IntegrationDeleteResponse'; id?: string | null };
};

export type TriggerImportCatalogMutationVariables = Exact<{
  input: ImportIntegrationCatalogInput;
}>;

export type TriggerImportCatalogMutation = {
  __typename?: 'Mutation';
  triggerImportCatalog: { __typename?: 'AsyncTaskResult'; taskId: string };
};

export type TriggerImportCustomersMutationVariables = Exact<{
  input: ImportIntegrationCustomersInput;
}>;

export type TriggerImportCustomersMutation = {
  __typename?: 'Mutation';
  triggerImportCustomers: { __typename?: 'AsyncTaskResult'; taskId: string };
};

export type UpdateIntegrationMutationVariables = Exact<{
  input: UpdateOneIntegrationInput;
}>;

export type UpdateIntegrationMutation = {
  __typename?: 'Mutation';
  updateOneIntegration: { __typename?: 'Integration'; id: string };
};

export type GetAwsExternalIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetAwsExternalIdQuery = {
  __typename?: 'Query';
  getAwsExternalId: { __typename?: 'GetAwsExternalIdResult'; externalId: string };
};

export type ListAwsProductDimensionsQueryVariables = Exact<{
  input: ListAwsProductDimensionsInput;
}>;

export type ListAwsProductDimensionsQuery = {
  __typename?: 'Query';
  listAwsProductDimensions: {
    __typename?: 'ListAwsProductDimensionsDTO';
    dimensions: Array<{
      __typename?: 'AwsDimension';
      name: string;
      description: string;
      key: string;
      unit: string;
      type: string;
      stiggPlanName?: string | null;
      stiggPlanId?: string | null;
    }>;
  };
};

export type ListAwsProductsQueryVariables = Exact<{
  input: ListAwsProductsInput;
}>;

export type ListAwsProductsQuery = {
  __typename?: 'Query';
  listAwsProducts: {
    __typename?: 'ListAwsProductsResult';
    products: Array<{
      __typename?: 'AwsProduct';
      stiggProductId?: string | null;
      stiggProductRefId?: string | null;
      productId: string;
      title: string;
      productCode: string;
      description: string;
      visibility: string;
      logoUrl?: string | null;
    }>;
  };
};

export type GetAuth0ApplicationsQueryVariables = Exact<{
  input: GetAuth0ApplicationsInput;
}>;

export type GetAuth0ApplicationsQuery = {
  __typename?: 'Query';
  getAuth0Applications: Array<{ __typename?: 'Auth0ApplicationDTO' } & Auth0ApplicationFragment>;
};

export type Auth0ApplicationFragment = {
  __typename?: 'Auth0ApplicationDTO';
  appId: string;
  name: string;
  type?: Auth0ApplicationType | null;
};

export type FetchIntegrationByVendorQueryVariables = Exact<{
  filter: IntegrationFilter;
}>;

export type FetchIntegrationByVendorQuery = {
  __typename?: 'Query';
  integrations: {
    __typename?: 'IntegrationConnection';
    edges: Array<{ __typename?: 'IntegrationEdge'; node: { __typename?: 'Integration' } & IntegrationFragment }>;
  };
};

export type IntegrationFragment = {
  __typename?: 'Integration';
  id: string;
  vendorIdentifier: VendorIdentifier;
  credentials?:
    | ({ __typename?: 'Auth0Credentials' } & Auth0CredentialsFragment)
    | ({ __typename?: 'AwsMarketplaceCredentials' } & AwsMarketplaceCredentialsFragment)
    | ({ __typename?: 'BigQueryCredentials' } & BigQueryCredentialsFragment)
    | ({ __typename?: 'HubspotCredentials' } & HubspotCredentialsFragment)
    | ({ __typename?: 'OpenFGACredentials' } & OpenFgaCredentialsFragment)
    | ({ __typename?: 'SalesforceCredentials' } & SalesforceCredentialsFragment)
    | ({ __typename?: 'SnowflakeCredentials' } & SnowflakeCredentialsFragment)
    | ({ __typename?: 'StripeCredentials' } & StripeCredentialsFragment)
    | ({ __typename?: 'ZuoraCredentials' } & ZouraCredentialsFragment)
    | null;
};

export type ZouraCredentialsFragment = {
  __typename?: 'ZuoraCredentials';
  baseUrl: string;
  clientId: string;
  clientSecret: string;
  stripePublishableKey?: string | null;
  stripeSecretKey?: string | null;
};

export type StripeCredentialsFragment = {
  __typename?: 'StripeCredentials';
  accountId: string;
  accountDisplayName: string;
  isTestMode: boolean;
};

export type HubspotCredentialsFragment = { __typename?: 'HubspotCredentials'; hubDomain: string };

export type AwsMarketplaceCredentialsFragment = { __typename?: 'AwsMarketplaceCredentials'; awsRoleArn: string };

export type SnowflakeCredentialsFragment = {
  __typename?: 'SnowflakeCredentials';
  database: string;
  host: string;
  role: string;
  schemaName: string;
  username: string;
  warehouse: string;
};

export type Auth0CredentialsFragment = {
  __typename?: 'Auth0Credentials';
  clientDomain: string;
  clientId: string;
  clientSecret: string;
  applicationId: string;
  applicationType: Auth0ApplicationType;
  applicationName: string;
  individualInitialPlanId?: string | null;
  individualSubscriptionStartSetup?: SubscriptionStartSetup | null;
  organizationInitialPlanId?: string | null;
  organizationSubscriptionStartSetup?: SubscriptionStartSetup | null;
};

export type SalesforceCredentialsFragment = { __typename?: 'SalesforceCredentials'; domain?: string | null };

export type BigQueryCredentialsFragment = {
  __typename?: 'BigQueryCredentials';
  projectId: string;
  datasetLocation: string;
  datasetId: string;
  hmacKeyAccessId: string;
  hmacKeySecret: string;
  gcsBucketName: string;
  gcsBucketPath: string;
  credentialsJson: string;
};

export type OpenFgaCredentialsFragment = {
  __typename?: 'OpenFGACredentials';
  apiUrl: string;
  apiAudience: string;
  apiTokenIssuer: string;
  clientId: string;
  storeId: string;
  modelId: string;
};

export type FetchIntegrationsQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<IntegrationSort> | IntegrationSort>;
  filter: IntegrationFilter;
}>;

export type FetchIntegrationsQuery = {
  __typename?: 'Query';
  integrations: {
    __typename?: 'IntegrationConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'IntegrationEdge';
      node: { __typename?: 'Integration' } & IntegrationListItemFragment;
    }>;
  };
};

export type IntegrationListItemFragment = {
  __typename?: 'Integration';
  id: string;
  vendorIdentifier: VendorIdentifier;
  credentials?:
    | {
        __typename?: 'Auth0Credentials';
        clientDomain: string;
        clientId: string;
        clientSecret: string;
        applicationId: string;
        applicationType: Auth0ApplicationType;
        applicationName: string;
        individualInitialPlanId?: string | null;
        individualSubscriptionStartSetup?: SubscriptionStartSetup | null;
        organizationInitialPlanId?: string | null;
        organizationSubscriptionStartSetup?: SubscriptionStartSetup | null;
      }
    | { __typename?: 'AwsMarketplaceCredentials' }
    | { __typename?: 'BigQueryCredentials' }
    | { __typename?: 'HubspotCredentials' }
    | { __typename?: 'OpenFGACredentials' }
    | { __typename?: 'SalesforceCredentials'; domain?: string | null }
    | { __typename?: 'SnowflakeCredentials' }
    | { __typename?: 'StripeCredentials' }
    | { __typename?: 'ZuoraCredentials' }
    | null;
};

export type StripeCustomersQueryVariables = Exact<{
  input: StripeCustomerSearchInput;
}>;

export type StripeCustomersQuery = {
  __typename?: 'Query';
  stripeCustomers: { __typename?: 'StripeCustomerSearchResult' } & StripeCustomerSearchResultFragment;
};

export type StripeCustomerSearchResultFragment = {
  __typename?: 'StripeCustomerSearchResult';
  totalCount: number;
  nextPage?: string | null;
  customers: Array<{
    __typename?: 'StripeCustomer';
    id: string;
    name: string;
    email: string;
    createdAt: any;
    subscriptionPlanName?: string | null;
    subscriptionsCount: number;
    isSynced: boolean;
    environmentId?: string | null;
  }>;
};

export type StripeProductsQueryVariables = Exact<{
  input: StripeProductSearchInput;
}>;

export type StripeProductsQuery = {
  __typename?: 'Query';
  stripeProducts: { __typename?: 'StripeProductSearchResult' } & StripeProductSearchResultFragment;
};

export type StripeProductSearchResultFragment = {
  __typename?: 'StripeProductSearchResult';
  usageBasedProductPresent?: boolean | null;
  totalCount: number;
  nextPage?: string | null;
  products: Array<{
    __typename?: 'StripeProduct';
    name: string;
    id: string;
    updatedAt: any;
    isSynced: boolean;
    notSupportedForImport: boolean;
    environmentId?: string | null;
    prices: Array<{ __typename?: 'StripeProductPrice'; amount: number; billingPeriod?: BillingPeriod | null }>;
  }>;
};

export type StripeSubscriptionsQueryVariables = Exact<{
  input: StripeSubscriptionSearchInput;
}>;

export type StripeSubscriptionsQuery = {
  __typename?: 'Query';
  stripeSubscriptions: { __typename?: 'StripeSubscriptionSearchResult' } & StripeSubscriptionSearchResultFragment;
};

export type StripeSubscriptionSearchResultFragment = {
  __typename?: 'StripeSubscriptionSearchResult';
  totalCount: number;
  nextPage?: string | null;
  subscriptions: Array<{ __typename?: 'StripeSubscription'; id: string }>;
};

export type ImportIntegrationTasksQueryVariables = Exact<{
  filter?: InputMaybe<ImportIntegrationTaskFilter>;
  sorting?: InputMaybe<Array<ImportIntegrationTaskSort> | ImportIntegrationTaskSort>;
}>;

export type ImportIntegrationTasksQuery = {
  __typename?: 'Query';
  importIntegrationTasks: {
    __typename?: 'ImportIntegrationTaskConnection';
    edges: Array<{
      __typename?: 'ImportIntegrationTaskEdge';
      node: { __typename?: 'ImportIntegrationTask' } & ImportIntegrationTaskResultFragment;
    }>;
  };
};

export type ImportIntegrationTaskResultFragment = {
  __typename?: 'ImportIntegrationTask';
  id: string;
  progress: number;
  status: TaskStatus;
  taskType: TaskType;
  productsCount: number;
  customersCount: number;
  totalSubtasksCount: number;
  createdAt?: any | null;
  importErrors: Array<{ __typename?: 'ImportSubTaskError'; id: string; error: string }>;
};

export type ArchiveAddonGroupMutationVariables = Exact<{
  input: ArchivePackageGroup;
}>;

export type ArchiveAddonGroupMutation = {
  __typename?: 'Mutation';
  archivePackageGroup: { __typename?: 'PackageGroup'; packageGroupId: string };
};

export type CreateAddonMutationVariables = Exact<{
  input: AddonCreateInput;
}>;

export type CreateAddonMutation = {
  __typename?: 'Mutation';
  createOneAddon: { __typename?: 'Addon'; id: string; refId: string };
};

export type CreateAddonDraftMutationVariables = Exact<{
  input: Scalars['String']['input'];
}>;

export type CreateAddonDraftMutation = {
  __typename?: 'Mutation';
  createAddonDraft: { __typename?: 'Addon'; id: string; refId: string; versionNumber: number };
};

export type CreateAddonGroupMutationVariables = Exact<{
  input: CreatePackageGroup;
}>;

export type CreateAddonGroupMutation = {
  __typename?: 'Mutation';
  createPackageGroup: { __typename?: 'PackageGroup'; packageGroupId: string };
};

export type RemoveAddonDraftMutationVariables = Exact<{
  input: DiscardPackageDraftInput;
}>;

export type RemoveAddonDraftMutation = { __typename?: 'Mutation'; removeAddonDraft: string };

export type EditAddonGroupMutationVariables = Exact<{
  input: EditPackageGroupDetailsInput;
}>;

export type EditAddonGroupMutation = {
  __typename?: 'Mutation';
  editPackageGroup: { __typename?: 'PackageGroup'; packageGroupId: string };
};

export type PublishAddonMutationVariables = Exact<{
  input: PackagePublishInput;
}>;

export type PublishAddonMutation = {
  __typename?: 'Mutation';
  publishAddon: { __typename?: 'PublishPackageResult'; taskId?: string | null };
};

export type SetPackageGroupAddonsMutationVariables = Exact<{
  input: SetPackageGroupAddons;
}>;

export type SetPackageGroupAddonsMutation = {
  __typename?: 'Mutation';
  setPackageGroupAddons: { __typename?: 'PackageGroup'; productId?: string | null };
};

export type UpdateOneAddonMutationVariables = Exact<{
  input: AddonUpdateInput;
}>;

export type UpdateOneAddonMutation = {
  __typename?: 'Mutation';
  updateOneAddon: {
    __typename?: 'Addon';
    id: string;
    description?: string | null;
    displayName: string;
    billingId?: string | null;
    billingLinkUrl?: string | null;
    updatedAt?: any | null;
    versionNumber: number;
    hiddenFromWidgets?: Array<WidgetType> | null;
    prices?: Array<{ __typename?: 'Price'; id: string; crmLinkUrl?: string | null; crmId?: string | null }> | null;
    syncStates?: Array<{
      __typename?: 'SyncState';
      vendorIdentifier: VendorIdentifier;
      status: SyncStatus;
      error?: string | null;
    }> | null;
  };
};

export type ArchiveAddonMutationVariables = Exact<{
  input: AddonArchiveInput;
}>;

export type ArchiveAddonMutation = { __typename?: 'Mutation'; archiveAddon: { __typename?: 'Addon'; id: string } };

export type AddonFieldsFragment = {
  __typename?: 'Addon';
  id: string;
  refId: string;
  displayName: string;
  description?: string | null;
  billingId?: string | null;
  billingLinkUrl?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: PackageStatus;
  type: string;
  versionNumber: number;
  isLatest?: boolean | null;
  hiddenFromWidgets?: Array<WidgetType> | null;
  additionalMetaData?: any | null;
  maxQuantity?: number | null;
  hasSubscriptions: boolean;
  overageBillingPeriod?: OverageBillingPeriod | null;
  pricingType?: PricingType | null;
  syncStates?: Array<{
    __typename?: 'SyncState';
    vendorIdentifier: VendorIdentifier;
    status: SyncStatus;
    error?: string | null;
  }> | null;
  draftSummary?: {
    __typename?: 'PackageDraftSummary';
    version: number;
    updatedAt: any;
    updatedBy?: string | null;
  } | null;
  draftDetails?: {
    __typename?: 'PackageDraftDetails';
    customersAffected?: number | null;
    changes?: ({ __typename?: 'PackageChanges' } & PackageChangesFragment) | null;
  } | null;
  entitlements?: Array<{
    __typename?: 'PackageEntitlement';
    packageId: string;
    id: string;
    featureId: string;
    environmentId: string;
    usageLimit?: number | null;
    hasUnlimitedUsage?: boolean | null;
    hasSoftLimit?: boolean | null;
    hiddenFromWidgets?: Array<WidgetType> | null;
    displayNameOverride?: string | null;
    resetPeriod?: EntitlementResetPeriod | null;
    behavior: EntitlementBehavior;
    resetPeriodConfiguration?:
      | ({ __typename: 'MonthlyResetPeriodConfig' } & MonthlyResetPeriodConfigFragment)
      | ({ __typename: 'WeeklyResetPeriodConfig' } & WeeklyResetPeriodConfigFragment)
      | ({ __typename: 'YearlyResetPeriodConfig' } & YearlyResetPeriodConfigFragment)
      | null;
    feature: {
      __typename?: 'Feature';
      id: string;
      displayName: string;
      refId: string;
      description?: string | null;
      featureStatus: FeatureStatus;
      updatedAt: any;
      environmentId: string;
      featureType: FeatureType;
      meterType?: MeterType | null;
      featureUnits?: string | null;
      featureUnitsPlural?: string | null;
      hasMeter?: boolean | null;
    };
  }> | null;
  product?: {
    __typename?: 'Product';
    id: string;
    displayName?: string | null;
    refId: string;
    awsMarketplaceProductId?: string | null;
    awsMarketplaceProductCode?: string | null;
    productSettings: {
      __typename?: 'ProductSettings';
      subscriptionEndSetup: SubscriptionEndSetup;
      subscriptionCancellationTime: SubscriptionCancellationTime;
    };
  } | null;
  prices?: Array<{ __typename?: 'Price' } & PriceFragment> | null;
  overagePrices?: Array<{ __typename?: 'Price' } & OveragePriceFragment> | null;
};

export type AddonFragment = {
  __typename?: 'Addon';
  dependencies?: Array<{ __typename?: 'Addon' } & AddonFieldsFragment> | null;
} & AddonFieldsFragment;

export type AddonListFieldsFragment = {
  __typename?: 'Addon';
  id: string;
  refId: string;
  displayName: string;
  description?: string | null;
  billingId?: string | null;
  billingLinkUrl?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: PackageStatus;
  type: string;
  versionNumber: number;
  isLatest?: boolean | null;
  hiddenFromWidgets?: Array<WidgetType> | null;
  maxQuantity?: number | null;
  hasSubscriptions: boolean;
  pricingType?: PricingType | null;
  draftSummary?: {
    __typename?: 'PackageDraftSummary';
    version: number;
    updatedAt: any;
    updatedBy?: string | null;
  } | null;
  entitlements?: Array<{
    __typename?: 'PackageEntitlement';
    packageId: string;
    id: string;
    featureId: string;
    environmentId: string;
    usageLimit?: number | null;
    hasUnlimitedUsage?: boolean | null;
    hasSoftLimit?: boolean | null;
    behavior: EntitlementBehavior;
    resetPeriod?: EntitlementResetPeriod | null;
    resetPeriodConfiguration?:
      | ({ __typename: 'MonthlyResetPeriodConfig' } & MonthlyResetPeriodConfigFragment)
      | ({ __typename: 'WeeklyResetPeriodConfig' } & WeeklyResetPeriodConfigFragment)
      | ({ __typename: 'YearlyResetPeriodConfig' } & YearlyResetPeriodConfigFragment)
      | null;
    feature: {
      __typename?: 'Feature';
      id: string;
      displayName: string;
      refId: string;
      description?: string | null;
      featureStatus: FeatureStatus;
      updatedAt: any;
      environmentId: string;
      featureType: FeatureType;
      meterType?: MeterType | null;
      featureUnits?: string | null;
      featureUnitsPlural?: string | null;
    };
  }> | null;
  product?: {
    __typename?: 'Product';
    id: string;
    displayName?: string | null;
    refId: string;
    awsMarketplaceProductId?: string | null;
    awsMarketplaceProductCode?: string | null;
    productSettings: {
      __typename?: 'ProductSettings';
      subscriptionEndSetup: SubscriptionEndSetup;
      subscriptionCancellationTime: SubscriptionCancellationTime;
    };
  } | null;
  prices?: Array<{ __typename?: 'Price' } & PriceFragment> | null;
};

export type AddonListFragment = {
  __typename?: 'Addon';
  dependencies?: Array<{ __typename?: 'Addon' } & AddonListFieldsFragment> | null;
} & AddonListFieldsFragment;

export type FetchAddonByRefIdQueryVariables = Exact<{
  input: GetPackageByRefIdInput;
}>;

export type FetchAddonByRefIdQuery = {
  __typename?: 'Query';
  getAddonByRefId?: ({ __typename?: 'Addon' } & AddonFragment) | null;
};

export type FetchAddonGroupByRefIdQueryVariables = Exact<{
  input: GetPackageGroup;
}>;

export type FetchAddonGroupByRefIdQuery = {
  __typename?: 'Query';
  getPackageGroup: { __typename?: 'PackageGroup' } & AddonGroupFragment;
};

export type AddonGroupFragment = {
  __typename?: 'PackageGroup';
  displayName: string;
  description?: string | null;
  packageGroupId: string;
  addons?: Array<{ __typename?: 'Addon' } & AddonGroupAddonFragment> | null;
  product: { __typename?: 'Product'; id: string; displayName?: string | null; refId: string };
};

export type AddonGroupAddonFragment = {
  __typename?: 'Addon';
  id: string;
  displayName: string;
  refId: string;
  maxQuantity?: number | null;
  pricingType?: PricingType | null;
  prices?: Array<{
    __typename?: 'Price';
    id: string;
    billingModel: BillingModel;
    billingPeriod: BillingPeriod;
    billingCadence: BillingCadence;
  }> | null;
};

export type PackageGroupsFragment = {
  __typename?: 'PackageGroupConnection';
  totalCount: number;
  pageInfo: {
    __typename?: 'PageInfo';
    endCursor?: any | null;
    startCursor?: any | null;
    hasNextPage?: boolean | null;
    hasPreviousPage?: boolean | null;
  };
  edges: Array<{
    __typename?: 'PackageGroupEdge';
    cursor: any;
    node: { __typename?: 'PackageGroup' } & PackageGroupFragment;
  }>;
};

export type PackageGroupFragment = {
  __typename?: 'PackageGroup';
  displayName: string;
  status: PackageGroupStatus;
  updatedAt: any;
  packageGroupId: string;
  product: { __typename?: 'Product'; displayName?: string | null };
  addons?: Array<{ __typename?: 'Addon'; id: string }> | null;
};

export type FetchPackageGroupsQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PackageGroupSort> | PackageGroupSort>;
  filter?: InputMaybe<PackageGroupFilter>;
}>;

export type FetchPackageGroupsQuery = {
  __typename?: 'Query';
  packageGroups: { __typename?: 'PackageGroupConnection' } & PackageGroupsFragment;
};

export type FetchSlimAddonsQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  filter: AddonFilter;
}>;

export type FetchSlimAddonsQuery = {
  __typename?: 'Query';
  addons: {
    __typename?: 'AddonConnection';
    edges: Array<{ __typename?: 'AddonEdge'; node: { __typename?: 'Addon'; refId: string; displayName: string } }>;
  };
};

export type FetchAddonsQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<AddonSort> | AddonSort>;
  filter: AddonFilter;
}>;

export type FetchAddonsQuery = { __typename?: 'Query'; addons: { __typename?: 'AddonConnection' } & AddonsFragment };

export type AddonsFragment = {
  __typename?: 'AddonConnection';
  totalCount: number;
  pageInfo: {
    __typename?: 'PageInfo';
    endCursor?: any | null;
    startCursor?: any | null;
    hasNextPage?: boolean | null;
    hasPreviousPage?: boolean | null;
  };
  edges: Array<{ __typename?: 'AddonEdge'; cursor: any; node: { __typename?: 'Addon' } & AddonListFragment }>;
};

export type AddonAssociatedEntitiesQueryVariables = Exact<{
  input: AddonAssociatedEntitiesInput;
}>;

export type AddonAssociatedEntitiesQuery = {
  __typename?: 'Query';
  addonAssociatedEntities: {
    __typename?: 'AddonAssociatedEntities';
    plans: Array<{
      __typename?: 'AddonAssociatedPlan';
      refId: string;
      status: PackageStatus;
      versionNumber: number;
      displayName: string;
    }>;
    packageGroups: Array<{ __typename?: 'AddonAssociatedPackageGroup'; packageGroupId: string; displayName: string }>;
  };
};

export type FetchAddonSyncStatesQueryVariables = Exact<{
  filter: AddonFilter;
}>;

export type FetchAddonSyncStatesQuery = {
  __typename?: 'Query';
  addons: {
    __typename?: 'AddonConnection';
    edges: Array<{
      __typename?: 'AddonEdge';
      node: {
        __typename?: 'Addon';
        id: string;
        billingId?: string | null;
        billingLinkUrl?: string | null;
        prices?: Array<{ __typename?: 'Price'; id: string; crmId?: string | null; crmLinkUrl?: string | null }> | null;
        syncStates?: Array<{
          __typename?: 'SyncState';
          vendorIdentifier: VendorIdentifier;
          status: SyncStatus;
          error?: string | null;
        }> | null;
      };
    }>;
  };
};

export type AddCompatibleAddonsToPlanMutationVariables = Exact<{
  input: AddCompatibleAddonsToPlanInput;
}>;

export type AddCompatibleAddonsToPlanMutation = {
  __typename?: 'Mutation';
  addCompatibleAddonsToPlan: { __typename?: 'Plan'; id: string };
};

export type CreatePlanMutationVariables = Exact<{
  input: PlanCreateInput;
}>;

export type CreatePlanMutation = {
  __typename?: 'Mutation';
  createOnePlan: { __typename?: 'Plan'; id: string; refId: string };
};

export type CreatePlanDraftMutationVariables = Exact<{
  input: Scalars['String']['input'];
}>;

export type CreatePlanDraftMutation = {
  __typename?: 'Mutation';
  createPlanDraft: { __typename?: 'Plan'; id: string; refId: string; versionNumber: number };
};

export type RemovePlanDraftMutationVariables = Exact<{
  input: DiscardPackageDraftInput;
}>;

export type RemovePlanDraftMutation = { __typename?: 'Mutation'; removePlanDraft: string };

export type PublishPlanMutationVariables = Exact<{
  input: PackagePublishInput;
}>;

export type PublishPlanMutation = {
  __typename?: 'Mutation';
  publishPlan: { __typename?: 'PublishPackageResult'; taskId?: string | null };
};

export type RemoveCompatibleAddonsFromPlanMutationVariables = Exact<{
  input: RemoveCompatibleAddonsFromPlanInput;
}>;

export type RemoveCompatibleAddonsFromPlanMutation = {
  __typename?: 'Mutation';
  removeCompatibleAddonsFromPlan: { __typename?: 'Plan'; id: string };
};

export type SetPlanCompatiblePackageGroupsMutationVariables = Exact<{
  input: SetPlanCompatiblePackageGroups;
}>;

export type SetPlanCompatiblePackageGroupsMutation = {
  __typename?: 'Mutation';
  setCompatiblePackageGroups: { __typename?: 'Plan'; id: string };
};

export type UpdateOnePlanMutationVariables = Exact<{
  input: PlanUpdateInput;
}>;

export type UpdateOnePlanMutation = {
  __typename?: 'Mutation';
  updateOnePlan: {
    __typename?: 'Plan';
    id: string;
    description?: string | null;
    displayName: string;
    billingId?: string | null;
    billingLinkUrl?: string | null;
    updatedAt?: any | null;
    versionNumber: number;
    hiddenFromWidgets?: Array<WidgetType> | null;
    prices?: Array<{ __typename?: 'Price'; id: string; crmLinkUrl?: string | null; crmId?: string | null }> | null;
    syncStates?: Array<{
      __typename?: 'SyncState';
      vendorIdentifier: VendorIdentifier;
      status: SyncStatus;
      error?: string | null;
    }> | null;
  };
};

export type ArchivePlanMutationVariables = Exact<{
  input: ArchivePlanInput;
}>;

export type ArchivePlanMutation = { __typename?: 'Mutation'; archivePlan: { __typename?: 'Plan'; id: string } };

export type FetchPlanByRefIdQueryVariables = Exact<{
  input: GetPackageByRefIdInput;
}>;

export type FetchPlanByRefIdQuery = {
  __typename?: 'Query';
  getPlanByRefId?: ({ __typename?: 'Plan' } & PlanFragment) | null;
};

export type FetchSlimPlansQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PlanSort> | PlanSort>;
  filter: PlanFilter;
}>;

export type FetchSlimPlansQuery = {
  __typename?: 'Query';
  plans: {
    __typename?: 'PlanConnection';
    edges: Array<{
      __typename?: 'PlanEdge';
      cursor: any;
      node: { __typename?: 'Plan'; refId: string; displayName: string };
    }>;
  };
};

export type FetchPlansQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PlanSort> | PlanSort>;
  filter: PlanFilter;
}>;

export type FetchPlansQuery = { __typename?: 'Query'; plans: { __typename?: 'PlanConnection' } & PlansFragment };

export type PlansFragment = {
  __typename?: 'PlanConnection';
  totalCount: number;
  pageInfo: {
    __typename?: 'PageInfo';
    endCursor?: any | null;
    startCursor?: any | null;
    hasNextPage?: boolean | null;
    hasPreviousPage?: boolean | null;
  };
  edges: Array<{ __typename?: 'PlanEdge'; cursor: any; node: { __typename?: 'Plan' } & PlanListFragment }>;
};

export type MinimumSpendFragment = {
  __typename?: 'MinimumSpend';
  billingPeriod: BillingPeriod;
  minimum: { __typename?: 'Money'; amount: number; currency: Currency };
};

export type PriceFeatureFragment = {
  __typename?: 'Feature';
  id: string;
  displayName: string;
  refId: string;
  featureUnits?: string | null;
  featureUnitsPlural?: string | null;
  meterType?: MeterType | null;
  featureType: FeatureType;
  environmentId: string;
  updatedAt: any;
  featureStatus: FeatureStatus;
  hasMeter?: boolean | null;
};

export type OveragePriceFragment = {
  __typename?: 'Price';
  id: string;
  billingModel: BillingModel;
  billingPeriod: BillingPeriod;
  billingCadence: BillingCadence;
  billingCountryCode?: string | null;
  blockSize?: number | null;
  tiersMode?: TiersMode | null;
  featureId?: string | null;
  packageId?: string | null;
  crmId?: string | null;
  crmLinkUrl?: string | null;
  isOverridePrice: boolean;
  price?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
  tiers?: Array<{ __typename?: 'PriceTier' } & PriceTierFragment> | null;
  feature?: ({ __typename?: 'Feature' } & PriceFeatureFragment) | null;
};

export type PackageChangesFragment = {
  __typename?: 'PackageChanges';
  totalChanges: number;
  description?: {
    __typename?: 'StringChangeDTO';
    before?: string | null;
    after?: string | null;
    changeType?: ChangeType | null;
  } | null;
  displayName?: {
    __typename?: 'StringChangeDTO';
    before?: string | null;
    after?: string | null;
    changeType?: ChangeType | null;
  } | null;
  pricingType?: {
    __typename?: 'PricingTypeChange';
    before?: PricingType | null;
    after?: PricingType | null;
    changeType?: ChangeType | null;
  } | null;
  hiddenFromWidgets?: {
    __typename?: 'HiddenFromWidgetsChange';
    before?: Array<WidgetType> | null;
    after?: Array<WidgetType> | null;
    changeType?: ChangeType | null;
  } | null;
  entitlements: Array<{
    __typename?: 'PackageEntitlementChange';
    changeType?: ChangeType | null;
    before?: ({ __typename?: 'PackageEntitlement' } & PackageEntitlementChangeFragment) | null;
    after?: ({ __typename?: 'PackageEntitlement' } & PackageEntitlementChangeFragment) | null;
  }>;
  prices: Array<{
    __typename?: 'PackagePriceChange';
    changeType?: ChangeType | null;
    before?: ({ __typename?: 'Price' } & PriceFragment) | null;
    after?: ({ __typename?: 'Price' } & PriceFragment) | null;
  }>;
  overagePrices: Array<{
    __typename?: 'PackagePriceChange';
    changeType?: ChangeType | null;
    before?: ({ __typename?: 'Price' } & PriceFragment) | null;
    after?: ({ __typename?: 'Price' } & PriceFragment) | null;
  }>;
  minimumSpend?: Array<{
    __typename?: 'MinimumSpendChange';
    changeType?: ChangeType | null;
    before?: ({ __typename?: 'MinimumSpend' } & MinimumSpendFragment) | null;
    after?: ({ __typename?: 'MinimumSpend' } & MinimumSpendFragment) | null;
  }> | null;
  compatibleAddons?: Array<{
    __typename?: 'PlanCompatibleAddonChange';
    changeType?: ChangeType | null;
    before?: ({ __typename?: 'Addon' } & CompatibleAddonChangeFragment) | null;
    after?: ({ __typename?: 'Addon' } & CompatibleAddonChangeFragment) | null;
  }> | null;
  compatiblePackageGroups?: Array<{
    __typename?: 'PlanCompatiblePackageGroupChange';
    changeType?: ChangeType | null;
    before?: ({ __typename?: 'PackageGroup' } & CompatiblePackageGroupChangeFragment) | null;
    after?: ({ __typename?: 'PackageGroup' } & CompatiblePackageGroupChangeFragment) | null;
  }> | null;
  basePlan?: {
    __typename?: 'BasePlanChange';
    changeType?: ChangeType | null;
    before?: {
      __typename?: 'Addon';
      displayName: string;
      product?: { __typename?: 'Product'; displayName?: string | null } | null;
    } | null;
    after?: {
      __typename?: 'Addon';
      displayName: string;
      product?: { __typename?: 'Product'; displayName?: string | null } | null;
    } | null;
  } | null;
  defaultTrialConfig?: {
    __typename?: 'DefaultTrialConfigChange';
    changeType?: ChangeType | null;
    before?: ({ __typename?: 'DefaultTrialConfig' } & DefaultTrialConfigChangeFragment) | null;
    after?: ({ __typename?: 'DefaultTrialConfig' } & DefaultTrialConfigChangeFragment) | null;
  } | null;
  additionalMetaData?: {
    __typename?: 'AdditionalMetaDataChange';
    before?: any | null;
    after?: any | null;
    changeType?: ChangeType | null;
  } | null;
  overageBillingPeriod?: {
    __typename?: 'OverageBillingPeriodChange';
    before?: OverageBillingPeriod | null;
    after?: OverageBillingPeriod | null;
    changeType?: ChangeType | null;
  } | null;
  maxQuantity?: {
    __typename?: 'NumberChange';
    before?: number | null;
    after?: number | null;
    changeType?: ChangeType | null;
  } | null;
  dependencies?: Array<{
    __typename?: 'AddonDependencyChange';
    changeType?: ChangeType | null;
    before?: ({ __typename?: 'Addon' } & AddonDependencyChangeFragment) | null;
    after?: ({ __typename?: 'Addon' } & AddonDependencyChangeFragment) | null;
  }> | null;
};

export type PackageEntitlementChangeFragment = {
  __typename?: 'PackageEntitlement';
  usageLimit?: number | null;
  hasUnlimitedUsage?: boolean | null;
  hasSoftLimit?: boolean | null;
  resetPeriod?: EntitlementResetPeriod | null;
  hiddenFromWidgets?: Array<WidgetType> | null;
  displayNameOverride?: string | null;
  isCustom?: boolean | null;
  behavior: EntitlementBehavior;
  feature: {
    __typename?: 'Feature';
    displayName: string;
    refId: string;
    featureType: FeatureType;
    meterType?: MeterType | null;
    featureUnits?: string | null;
    featureUnitsPlural?: string | null;
  };
  resetPeriodConfiguration?:
    | ({ __typename: 'MonthlyResetPeriodConfig' } & MonthlyResetPeriodConfigFragment)
    | ({ __typename: 'WeeklyResetPeriodConfig' } & WeeklyResetPeriodConfigFragment)
    | ({ __typename: 'YearlyResetPeriodConfig' } & YearlyResetPeriodConfigFragment)
    | null;
};

export type CompatibleAddonChangeFragment = {
  __typename?: 'Addon';
  displayName: string;
  refId: string;
  description?: string | null;
  maxQuantity?: number | null;
};

export type CompatiblePackageGroupChangeFragment = {
  __typename?: 'PackageGroup';
  displayName: string;
  packageGroupId: string;
  description?: string | null;
};

export type AddonDependencyChangeFragment = { __typename?: 'Addon'; displayName: string; refId: string };

export type DefaultTrialConfigChangeFragment = {
  __typename?: 'DefaultTrialConfig';
  units: TrialPeriodUnits;
  duration: number;
  trialEndBehavior?: TrialEndBehavior | null;
  budget?: { __typename?: 'BudgetConfiguration'; limit: number } | null;
};

export type PlanCompatiblePackageGroupFragment = {
  __typename?: 'PlanCompatiblePackageGroups';
  packageGroupId: string;
  displayName: string;
  options: { __typename?: 'PlanCompatiblePackageGroupOptions'; minItems?: number | null; freeItems?: number | null };
  addons?: Array<{ __typename?: 'Addon'; refId: string }> | null;
};

export type PlanCompatibleAddonFragment = {
  __typename?: 'Addon';
  id: string;
  description?: string | null;
  displayName: string;
  refId: string;
  status: PackageStatus;
  createdAt?: any | null;
  updatedAt?: any | null;
  pricingType?: PricingType | null;
  maxQuantity?: number | null;
  prices?: Array<{ __typename?: 'Price' } & PriceFragment> | null;
  overagePrices?: Array<{ __typename?: 'Price' } & OveragePriceFragment> | null;
};

export type PlanFragment = {
  __typename?: 'Plan';
  id: string;
  refId: string;
  displayName: string;
  description?: string | null;
  billingId?: string | null;
  billingLinkUrl?: string | null;
  awsMarketplacePlanDimension?: string | null;
  status: PackageStatus;
  type: string;
  createdAt?: any | null;
  updatedAt?: any | null;
  versionNumber: number;
  hiddenFromWidgets?: Array<WidgetType> | null;
  isLatest?: boolean | null;
  additionalMetaData?: any | null;
  overageBillingPeriod?: OverageBillingPeriod | null;
  pricingType?: PricingType | null;
  syncStates?: Array<{
    __typename?: 'SyncState';
    vendorIdentifier: VendorIdentifier;
    status: SyncStatus;
    error?: string | null;
  }> | null;
  draftSummary?: {
    __typename?: 'PackageDraftSummary';
    version: number;
    updatedAt: any;
    updatedBy?: string | null;
  } | null;
  draftDetails?: {
    __typename?: 'PackageDraftDetails';
    customersAffected?: number | null;
    childPlansWithDraft?: Array<{
      __typename?: 'Plan';
      refId: string;
      displayName: string;
      product: { __typename?: 'Product'; displayName?: string | null };
    }> | null;
    affectedChildPlans?: Array<{
      __typename?: 'Plan';
      displayName: string;
      product: { __typename?: 'Product'; displayName?: string | null };
    }> | null;
    changes?: ({ __typename?: 'PackageChanges' } & PackageChangesFragment) | null;
  } | null;
  defaultTrialConfig?: {
    __typename?: 'DefaultTrialConfig';
    units: TrialPeriodUnits;
    duration: number;
    trialEndBehavior?: TrialEndBehavior | null;
    budget?: { __typename?: 'BudgetConfiguration'; limit: number } | null;
  } | null;
  entitlements?: Array<{ __typename?: 'PackageEntitlement' } & PlanEntitlementFragment> | null;
  inheritedEntitlements?: Array<{ __typename?: 'PackageEntitlement' } & PlanEntitlementFragment> | null;
  product: {
    __typename?: 'Product';
    id: string;
    displayName?: string | null;
    refId: string;
    multipleSubscriptions: boolean;
    awsMarketplaceProductId?: string | null;
    awsMarketplaceProductCode?: string | null;
    productSettings: {
      __typename?: 'ProductSettings';
      subscriptionEndSetup: SubscriptionEndSetup;
      subscriptionCancellationTime: SubscriptionCancellationTime;
      subscriptionStartSetup: SubscriptionStartSetup;
      downgradePlan?: { __typename?: 'Plan'; refId: string; displayName: string } | null;
      subscriptionStartPlan?: { __typename?: 'Plan'; id: string; refId: string; displayName: string } | null;
    };
  };
  basePlan?: {
    __typename?: 'Plan';
    id: string;
    refId: string;
    displayName: string;
    isLatest?: boolean | null;
    versionNumber: number;
    status: PackageStatus;
    overagePrices?: Array<{ __typename?: 'Price' } & OveragePriceFragment> | null;
  } | null;
  compatibleAddons?: Array<{ __typename?: 'Addon' } & PlanCompatibleAddonFragment> | null;
  compatiblePackageGroups?: Array<
    { __typename?: 'PlanCompatiblePackageGroups' } & PlanCompatiblePackageGroupFragment
  > | null;
  prices?: Array<
    {
      __typename?: 'Price';
      resetPeriod?: EntitlementResetPeriod | null;
      resetPeriodConfiguration?:
        | ({ __typename: 'MonthlyResetPeriodConfig' } & MonthlyResetPeriodConfigFragment)
        | ({ __typename: 'WeeklyResetPeriodConfig' } & WeeklyResetPeriodConfigFragment)
        | ({ __typename: 'YearlyResetPeriodConfig' } & YearlyResetPeriodConfigFragment)
        | null;
    } & PriceFragment
  > | null;
  overagePrices?: Array<{ __typename?: 'Price' } & OveragePriceFragment> | null;
  minimumSpend?: Array<{ __typename?: 'MinimumSpend' } & MinimumSpendFragment> | null;
};

export type PlanEntitlementFragment = {
  __typename?: 'PackageEntitlement';
  packageId: string;
  id: string;
  featureId: string;
  usageLimit?: number | null;
  hasUnlimitedUsage?: boolean | null;
  hasSoftLimit?: boolean | null;
  resetPeriod?: EntitlementResetPeriod | null;
  hiddenFromWidgets?: Array<WidgetType> | null;
  displayNameOverride?: string | null;
  isCustom?: boolean | null;
  behavior: EntitlementBehavior;
  environmentId: string;
  createdAt?: any | null;
  order?: number | null;
  resetPeriodConfiguration?:
    | ({ __typename: 'MonthlyResetPeriodConfig' } & MonthlyResetPeriodConfigFragment)
    | ({ __typename: 'WeeklyResetPeriodConfig' } & WeeklyResetPeriodConfigFragment)
    | ({ __typename: 'YearlyResetPeriodConfig' } & YearlyResetPeriodConfigFragment)
    | null;
  feature: {
    __typename?: 'Feature';
    id: string;
    displayName: string;
    refId: string;
    description?: string | null;
    featureStatus: FeatureStatus;
    updatedAt: any;
    environmentId: string;
    featureType: FeatureType;
    meterType?: MeterType | null;
    featureUnits?: string | null;
    featureUnitsPlural?: string | null;
    hasMeter?: boolean | null;
  };
};

export type PlanListFragment = {
  __typename?: 'Plan';
  id: string;
  refId: string;
  displayName: string;
  description?: string | null;
  status: PackageStatus;
  billingId?: string | null;
  billingLinkUrl?: string | null;
  type: string;
  updatedAt?: any | null;
  versionNumber: number;
  hiddenFromWidgets?: Array<WidgetType> | null;
  awsMarketplacePlanDimension?: string | null;
  isLatest?: boolean | null;
  isParent: boolean;
  pricingType?: PricingType | null;
  draftSummary?: {
    __typename?: 'PackageDraftSummary';
    version: number;
    updatedAt: any;
    updatedBy?: string | null;
  } | null;
  defaultTrialConfig?: {
    __typename?: 'DefaultTrialConfig';
    units: TrialPeriodUnits;
    duration: number;
    trialEndBehavior?: TrialEndBehavior | null;
    budget?: { __typename?: 'BudgetConfiguration'; limit: number } | null;
  } | null;
  basePlan?: {
    __typename?: 'Plan';
    id: string;
    displayName: string;
    refId: string;
    isLatest?: boolean | null;
    versionNumber: number;
  } | null;
  product: {
    __typename?: 'Product';
    id: string;
    displayName?: string | null;
    refId: string;
    multipleSubscriptions: boolean;
    awsMarketplaceProductId?: string | null;
    awsMarketplaceProductCode?: string | null;
    productSettings: {
      __typename?: 'ProductSettings';
      subscriptionEndSetup: SubscriptionEndSetup;
      subscriptionCancellationTime: SubscriptionCancellationTime;
      downgradePlan?: { __typename?: 'Plan'; refId: string; displayName: string } | null;
      subscriptionStartPlan?: { __typename?: 'Plan'; refId: string } | null;
    };
  };
  compatibleAddons?: Array<{ __typename?: 'Addon'; id: string; refId: string }> | null;
  compatiblePackageGroups?: Array<{
    __typename?: 'PlanCompatiblePackageGroups';
    packageGroupId: string;
    addons?: Array<{ __typename?: 'Addon'; id: string; refId: string }> | null;
  }> | null;
  entitlements?: Array<{ __typename?: 'PackageEntitlement' } & PlanListEntitlementFragment> | null;
  inheritedEntitlements?: Array<{ __typename?: 'PackageEntitlement' } & PlanListEntitlementFragment> | null;
  prices?: Array<{ __typename?: 'Price' } & PriceFragment> | null;
  overagePrices?: Array<{ __typename?: 'Price' } & OveragePriceFragment> | null;
  minimumSpend?: Array<{ __typename?: 'MinimumSpend' } & MinimumSpendFragment> | null;
};

export type PlanListEntitlementFragment = {
  __typename?: 'PackageEntitlement';
  id: string;
  usageLimit?: number | null;
  packageId: string;
  featureId: string;
  hasUnlimitedUsage?: boolean | null;
  hasSoftLimit?: boolean | null;
  isCustom?: boolean | null;
  resetPeriod?: EntitlementResetPeriod | null;
  resetPeriodConfiguration?:
    | ({ __typename: 'MonthlyResetPeriodConfig' } & MonthlyResetPeriodConfigFragment)
    | ({ __typename: 'WeeklyResetPeriodConfig' } & WeeklyResetPeriodConfigFragment)
    | ({ __typename: 'YearlyResetPeriodConfig' } & YearlyResetPeriodConfigFragment)
    | null;
  feature: {
    __typename?: 'Feature';
    id: string;
    displayName: string;
    description?: string | null;
    updatedAt: any;
    refId: string;
    featureStatus: FeatureStatus;
    environmentId: string;
    featureType: FeatureType;
    meterType?: MeterType | null;
    featureUnits?: string | null;
    featureUnitsPlural?: string | null;
  };
};

export type PriceTierFragment = {
  __typename?: 'PriceTier';
  upTo?: number | null;
  unitPrice?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
  flatPrice?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
};

export type PriceFragment = {
  __typename?: 'Price';
  id: string;
  billingModel: BillingModel;
  billingPeriod: BillingPeriod;
  billingCadence: BillingCadence;
  billingCountryCode?: string | null;
  blockSize?: number | null;
  tiersMode?: TiersMode | null;
  featureId?: string | null;
  packageId?: string | null;
  crmId?: string | null;
  crmLinkUrl?: string | null;
  minUnitQuantity?: number | null;
  maxUnitQuantity?: number | null;
  usedInSubscriptions?: boolean | null;
  isOverridePrice: boolean;
  price?: { __typename?: 'Money'; amount: number; currency: Currency } | null;
  tiers?: Array<{ __typename?: 'PriceTier' } & PriceTierFragment> | null;
  feature?: ({ __typename?: 'Feature' } & PriceFeatureFragment) | null;
};

export type FetchPlanSyncStatesQueryVariables = Exact<{
  filter: PlanFilter;
}>;

export type FetchPlanSyncStatesQuery = {
  __typename?: 'Query';
  plans: {
    __typename?: 'PlanConnection';
    edges: Array<{
      __typename?: 'PlanEdge';
      node: {
        __typename?: 'Plan';
        id: string;
        billingId?: string | null;
        billingLinkUrl?: string | null;
        prices?: Array<{ __typename?: 'Price'; id: string; crmId?: string | null; crmLinkUrl?: string | null }> | null;
        syncStates?: Array<{
          __typename?: 'SyncState';
          vendorIdentifier: VendorIdentifier;
          status: SyncStatus;
          error?: string | null;
        }> | null;
      };
    }>;
  };
};

export type SetPackagePricingMutationVariables = Exact<{
  input: PackagePricingInput;
}>;

export type SetPackagePricingMutation = {
  __typename?: 'Mutation';
  setPackagePricing: { __typename?: 'PackagePrice'; packageId: string; pricingType: PricingType };
};

export type CreateOrUpdateAwsMarketplaceProductMutationVariables = Exact<{
  input: CreateOrUpdateAwsMarketplaceProductInput;
}>;

export type CreateOrUpdateAwsMarketplaceProductMutation = {
  __typename?: 'Mutation';
  createOrUpdateAwsMarketplaceProduct: {
    __typename?: 'Product';
    id: string;
    refId: string;
    displayName?: string | null;
  };
};

export type CreateProductMutationVariables = Exact<{
  input: CreateOneProductInput;
}>;

export type CreateProductMutation = {
  __typename?: 'Mutation';
  createOneProduct: { __typename?: 'Product'; displayName?: string | null; id: string; refId: string };
};

export type DeleteOneProductMutationVariables = Exact<{
  input: DeleteOneProductInput;
}>;

export type DeleteOneProductMutation = {
  __typename?: 'Mutation';
  deleteOneProduct: { __typename?: 'ProductDeleteResponse'; id?: string | null };
};

export type DuplicateProductMutationVariables = Exact<{
  input: DuplicateProductInput;
}>;

export type DuplicateProductMutation = {
  __typename?: 'Mutation';
  duplicateProduct: { __typename?: 'Product'; displayName?: string | null; id: string; refId: string };
};

export type UpdateOneProductMutationVariables = Exact<{
  input: UpdateOneProductInput;
}>;

export type UpdateOneProductMutation = {
  __typename?: 'Mutation';
  updateOneProduct: {
    __typename?: 'Product';
    id: string;
    displayName?: string | null;
    updatedAt: any;
    description?: string | null;
  };
};

export type ProductFragment = {
  __typename?: 'Product';
  id: string;
  createdAt: any;
  description?: string | null;
  displayName?: string | null;
  refId: string;
  environmentId: string;
  updatedAt: any;
  isDefaultProduct: boolean;
  additionalMetaData?: any | null;
  multipleSubscriptions: boolean;
  awsMarketplaceProductId?: string | null;
  awsMarketplaceProductCode?: string | null;
  productSettings: { __typename?: 'ProductSettings' } & ProductSettingsFragment;
  plans: Array<{ __typename?: 'Plan' } & ProductPlanFragment>;
  addons: Array<{ __typename?: 'Addon' } & ProductAddonFragment>;
  autoCancellationRules: Array<{ __typename?: 'AutoCancellationRule' } & AutoCancellationRuleFragment>;
  subscriptionUpdateUsageResetCutoffRule: {
    __typename?: 'SubscriptionUpdateUsageResetCutoffRule';
    behavior: SubscriptionUpdateUsageCutoffBehavior;
  };
};

export type ProductPlanFragment = {
  __typename?: 'Plan';
  id: string;
  refId: string;
  displayName: string;
  type: string;
  status: PackageStatus;
  updatedAt?: any | null;
  isLatest?: boolean | null;
  hiddenFromWidgets?: Array<WidgetType> | null;
  versionNumber: number;
  pricingType?: PricingType | null;
  product: { __typename?: 'Product'; id: string; displayName?: string | null };
  draftSummary?: {
    __typename?: 'PackageDraftSummary';
    version: number;
    updatedAt: any;
    updatedBy?: string | null;
  } | null;
  basePlan?: { __typename?: 'Plan'; id: string; refId: string; displayName: string } | null;
  entitlements?: Array<{ __typename?: 'PackageEntitlement' } & ProductPackageEntitlementFragment> | null;
  inheritedEntitlements?: Array<{ __typename?: 'PackageEntitlement' } & ProductPackageEntitlementFragment> | null;
  prices?: Array<{ __typename?: 'Price' } & PriceFragment> | null;
  overagePrices?: Array<{ __typename?: 'Price' } & OveragePriceFragment> | null;
  defaultTrialConfig?: {
    __typename?: 'DefaultTrialConfig';
    duration: number;
    units: TrialPeriodUnits;
    trialEndBehavior?: TrialEndBehavior | null;
    budget?: { __typename?: 'BudgetConfiguration'; limit: number } | null;
  } | null;
  minimumSpend?: Array<{
    __typename?: 'MinimumSpend';
    billingPeriod: BillingPeriod;
    minimum: { __typename?: 'Money'; currency: Currency; amount: number };
  }> | null;
};

export type ProductAddonFragment = {
  __typename?: 'Addon';
  id: string;
  refId: string;
  displayName: string;
  type: string;
  status: PackageStatus;
  updatedAt?: any | null;
  entitlements?: Array<{ __typename?: 'PackageEntitlement' } & ProductPackageEntitlementFragment> | null;
};

export type ProductPackageEntitlementFragment = {
  __typename?: 'PackageEntitlement';
  id: string;
  usageLimit?: number | null;
  hasUnlimitedUsage?: boolean | null;
  hasSoftLimit?: boolean | null;
  resetPeriod?: EntitlementResetPeriod | null;
  packageId: string;
  hiddenFromWidgets?: Array<WidgetType> | null;
  displayNameOverride?: string | null;
  isCustom?: boolean | null;
  featureId: string;
  feature: {
    __typename?: 'Feature';
    displayName: string;
    description?: string | null;
    refId: string;
    featureType: FeatureType;
    meterType?: MeterType | null;
    featureUnits?: string | null;
    featureUnitsPlural?: string | null;
    hasMeter?: boolean | null;
  };
};

export type AutoCancellationRuleFragment = {
  __typename?: 'AutoCancellationRule';
  sourcePlan: { __typename?: 'Plan'; refId: string; displayName: string };
  targetPlan: { __typename?: 'Plan'; refId: string; displayName: string };
};

export type ProductSettingsFragment = {
  __typename?: 'ProductSettings';
  subscriptionEndSetup: SubscriptionEndSetup;
  subscriptionCancellationTime: SubscriptionCancellationTime;
  prorateAtEndOfBillingPeriod?: boolean | null;
  subscriptionStartSetup: SubscriptionStartSetup;
  downgradePlan?: {
    __typename?: 'Plan';
    id: string;
    refId: string;
    displayName: string;
    status: PackageStatus;
    pricingType?: PricingType | null;
    defaultTrialConfig?: {
      __typename?: 'DefaultTrialConfig';
      units: TrialPeriodUnits;
      duration: number;
      trialEndBehavior?: TrialEndBehavior | null;
      budget?: { __typename?: 'BudgetConfiguration'; limit: number } | null;
    } | null;
  } | null;
  subscriptionStartPlan?: {
    __typename?: 'Plan';
    id: string;
    refId: string;
    displayName: string;
    status: PackageStatus;
    pricingType?: PricingType | null;
    defaultTrialConfig?: {
      __typename?: 'DefaultTrialConfig';
      units: TrialPeriodUnits;
      duration: number;
      trialEndBehavior?: TrialEndBehavior | null;
      budget?: { __typename?: 'BudgetConfiguration'; limit: number } | null;
    } | null;
  } | null;
};

export type FetchProductByRefIdQueryVariables = Exact<{
  filter: ProductFilter;
}>;

export type FetchProductByRefIdQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'ProductConnection';
    edges: Array<{ __typename?: 'ProductEdge'; node: { __typename?: 'Product' } & ProductFragment }>;
  };
};

export type ProductForEditFragment = {
  __typename?: 'Product';
  id: string;
  refId: string;
  description?: string | null;
  displayName?: string | null;
  multipleSubscriptions: boolean;
  hasSubscriptions: boolean;
  awsMarketplaceProductCode?: string | null;
  awsMarketplaceProductId?: string | null;
};

export type FetchProductForEditByRefIdQueryVariables = Exact<{
  filter: ProductFilter;
}>;

export type FetchProductForEditByRefIdQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'ProductConnection';
    edges: Array<{ __typename?: 'ProductEdge'; node: { __typename?: 'Product' } & ProductForEditFragment }>;
  };
};

export type FetchSlimProductsQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  filter: ProductFilter;
}>;

export type FetchSlimProductsQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'ProductConnection';
    edges: Array<{
      __typename?: 'ProductEdge';
      node: { __typename?: 'Product'; refId: string; displayName?: string | null };
    }>;
  };
};

export type FetchProductsQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ProductSort> | ProductSort>;
  filter: ProductFilter;
}>;

export type FetchProductsQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'ProductConnection';
    totalCount: number;
    edges: Array<{ __typename?: 'ProductEdge'; node: { __typename?: 'Product' } & ProductListItemFragment }>;
  };
};

export type ProductListItemFragment = {
  __typename?: 'Product';
  createdAt: any;
  description?: string | null;
  id: string;
  displayName?: string | null;
  refId: string;
  isDefaultProduct: boolean;
  environmentId: string;
  updatedAt: any;
  multipleSubscriptions: boolean;
  awsMarketplaceProductId?: string | null;
  awsMarketplaceProductCode?: string | null;
  productSettings: {
    __typename?: 'ProductSettings';
    subscriptionEndSetup: SubscriptionEndSetup;
    subscriptionCancellationTime: SubscriptionCancellationTime;
    downgradePlan?: { __typename?: 'Plan'; refId: string; id: string } | null;
  };
  plans: Array<{
    __typename?: 'Plan';
    id: string;
    refId: string;
    displayName: string;
    description?: string | null;
    pricingType?: PricingType | null;
    type: string;
    status: PackageStatus;
    defaultTrialConfig?: {
      __typename?: 'DefaultTrialConfig';
      duration: number;
      units: TrialPeriodUnits;
      trialEndBehavior?: TrialEndBehavior | null;
      budget?: { __typename?: 'BudgetConfiguration'; limit: number } | null;
    } | null;
    product: { __typename?: 'Product'; id: string; displayName?: string | null };
    prices?: Array<{
      __typename?: 'Price';
      billingModel: BillingModel;
      feature?: { __typename?: 'Feature'; refId: string } | null;
    }> | null;
    entitlements?: Array<{
      __typename?: 'PackageEntitlement';
      id: string;
      hasUnlimitedUsage?: boolean | null;
      hasSoftLimit?: boolean | null;
      feature: { __typename?: 'Feature'; refId: string };
    }> | null;
    inheritedEntitlements?: Array<{
      __typename?: 'PackageEntitlement';
      id: string;
      hasUnlimitedUsage?: boolean | null;
      hasSoftLimit?: boolean | null;
      feature: { __typename?: 'Feature'; refId: string };
    }> | null;
  }>;
  addons: Array<{
    __typename?: 'Addon';
    id: string;
    refId: string;
    displayName: string;
    description?: string | null;
    type: string;
    status: PackageStatus;
    entitlements?: Array<{
      __typename?: 'PackageEntitlement';
      id: string;
      hasUnlimitedUsage?: boolean | null;
      hasSoftLimit?: boolean | null;
      feature: { __typename?: 'Feature'; refId: string };
    }> | null;
  }>;
};

export type AutoCancellationTargetQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PlanSort> | PlanSort>;
  filter: PlanFilter;
}>;

export type AutoCancellationTargetQuery = {
  __typename?: 'Query';
  plans: {
    __typename?: 'PlanConnection';
    edges: Array<{
      __typename?: 'PlanEdge';
      node: {
        __typename?: 'Plan';
        refId: string;
        displayName: string;
        product: { __typename?: 'Product'; displayName?: string | null };
      };
    }>;
  };
};

export type UsageEventsWithCustomerQueryVariables = Exact<{
  input: UsageEventsInput;
}>;

export type UsageEventsWithCustomerQuery = {
  __typename?: 'Query';
  usageEvents: {
    __typename?: 'UsageEventsPreview';
    events: Array<{ __typename?: 'UsageEvent' } & UsageEventWithCustomerFragment>;
  };
};

export type UsageEventWithCustomerFragment = {
  __typename?: 'UsageEvent';
  id: string;
  customerId: string;
  resourceId?: string | null;
  eventName: string;
  timestamp: string;
  dimensions?: any | null;
  idempotencyKey: string;
  customer?: { __typename?: 'Customer'; name?: string | null; email?: string | null; createdAt?: any | null } | null;
};

export type CreateHookMutationVariables = Exact<{
  input: CreateOneHookInput;
}>;

export type CreateHookMutation = { __typename?: 'Mutation'; createOneHook: { __typename?: 'Hook'; id: string } };

export type DeleteOneHookMutationVariables = Exact<{
  input: DeleteOneHookInput;
}>;

export type DeleteOneHookMutation = {
  __typename?: 'Mutation';
  deleteOneHook: { __typename?: 'HookDeleteResponse'; id?: string | null };
};

export type UpdateOneHookMutationVariables = Exact<{
  input: UpdateOneHookInput;
}>;

export type UpdateOneHookMutation = {
  __typename?: 'Mutation';
  updateOneHook: { __typename?: 'Hook' } & HookDataFragment;
};

export type HookQueryVariables = Exact<{
  hookId: Scalars['String']['input'];
}>;

export type HookQuery = { __typename?: 'Query'; hook?: ({ __typename?: 'Hook' } & HookDataFragment) | null };

export type FetchHooksQueryVariables = Exact<{
  filter?: InputMaybe<HookFilter>;
  sorting?: InputMaybe<Array<HookSort> | HookSort>;
}>;

export type FetchHooksQuery = {
  __typename?: 'Query';
  hooks: {
    __typename?: 'HookConnection';
    edges: Array<{ __typename?: 'HookEdge'; node: { __typename?: 'Hook' } & HookDataFragment }>;
  };
};

export type FetchTestHookDataQueryVariables = Exact<{
  eventLogType: EventLogType;
}>;

export type FetchTestHookDataQuery = {
  __typename?: 'Query';
  testHookData: { __typename?: 'TestHook' } & TestHookDataFragment;
};

export type TestHookDataFragment = { __typename?: 'TestHook'; testHookEventType: string; testHookPayload: string };

export type HookDataFragment = {
  __typename?: 'Hook';
  id: string;
  description?: string | null;
  endpoint: string;
  status: HookStatus;
  environmentId: string;
  eventLogTypes: Array<EventLogType>;
  secretKey?: string | null;
  configuration?: any | null;
};

export type TestHookQueryVariables = Exact<{
  testHookInput: TestHookInput;
}>;

export type TestHookQuery = {
  __typename?: 'Query';
  sendTestHook: { __typename?: 'TestHookResult' } & TestHookResultFragment;
};

export type TestHookResultFragment = {
  __typename?: 'TestHookResult';
  responseStatusCode?: number | null;
  responseStatusText?: string | null;
  responseSuccess: boolean;
};

export type SetWidgetConfigurationMutationVariables = Exact<{
  input: WidgetConfigurationUpdateInput;
}>;

export type SetWidgetConfigurationMutation = { __typename?: 'Mutation'; setWidgetConfiguration?: string | null };

export type FetchCheckoutConfigurationQueryVariables = Exact<{
  input: GetWidgetConfigurationInput;
}>;

export type FetchCheckoutConfigurationQuery = {
  __typename?: 'Query';
  widgetConfiguration: {
    __typename?: 'WidgetConfiguration';
    checkout?: ({ __typename?: 'CheckoutConfiguration' } & CheckoutConfigurationFragment) | null;
  };
};

export type CheckoutConfigurationFragment = {
  __typename?: 'CheckoutConfiguration';
  customCss?: string | null;
  palette?: {
    __typename?: 'CheckoutColorPalette';
    primary?: string | null;
    textColor?: string | null;
    backgroundColor?: string | null;
    borderColor?: string | null;
    summaryBackgroundColor?: string | null;
  } | null;
  typography?: ({ __typename?: 'TypographyConfiguration' } & TypographyConfigurationFragment) | null;
  content?: { __typename?: 'CheckoutContent'; collectPhoneNumber?: boolean | null } | null;
};

export type FetchCustomerPortalConfigurationQueryVariables = Exact<{
  input: GetWidgetConfigurationInput;
}>;

export type FetchCustomerPortalConfigurationQuery = {
  __typename?: 'Query';
  widgetConfiguration: {
    __typename?: 'WidgetConfiguration';
    customerPortal?: ({ __typename?: 'CustomerPortalConfiguration' } & CustomerPortalConfigurationFragment) | null;
  };
};

export type CustomerPortalConfigurationFragment = {
  __typename?: 'CustomerPortalConfiguration';
  customCss?: string | null;
  palette?: {
    __typename?: 'CustomerPortalColorsPalette';
    primary?: string | null;
    textColor?: string | null;
    iconsColor?: string | null;
    backgroundColor?: string | null;
    borderColor?: string | null;
    currentPlanBackground?: string | null;
    paywallBackgroundColor?: string | null;
  } | null;
  typography?: ({ __typename?: 'TypographyConfiguration' } & TypographyConfigurationFragment) | null;
};

export type FetchPaywallConfigurationQueryVariables = Exact<{
  input: GetWidgetConfigurationInput;
}>;

export type FetchPaywallConfigurationQuery = {
  __typename?: 'Query';
  widgetConfiguration: {
    __typename?: 'WidgetConfiguration';
    paywall?: ({ __typename?: 'PaywallConfiguration' } & PaywallConfigurationFragment) | null;
  };
};

export type PaywallConfigurationFragment = {
  __typename?: 'PaywallConfiguration';
  customCss?: string | null;
  palette?: {
    __typename?: 'PaywallColorsPalette';
    primary?: string | null;
    textColor?: string | null;
    backgroundColor?: string | null;
    borderColor?: string | null;
    currentPlanBackground?: string | null;
  } | null;
  typography?: ({ __typename?: 'TypographyConfiguration' } & TypographyConfigurationFragment) | null;
  layout?: {
    __typename?: 'PaywallLayoutConfiguration';
    alignment?: Alignment | null;
    planWidth?: number | null;
    planMargin?: number | null;
    planPadding?: number | null;
  } | null;
};

export type TypographyConfigurationFragment = {
  __typename?: 'TypographyConfiguration';
  fontFamily?: string | null;
  h1?: ({ __typename?: 'FontVariant' } & FontVariantFragment) | null;
  h2?: ({ __typename?: 'FontVariant' } & FontVariantFragment) | null;
  h3?: ({ __typename?: 'FontVariant' } & FontVariantFragment) | null;
  body?: ({ __typename?: 'FontVariant' } & FontVariantFragment) | null;
};

export type FontVariantFragment = {
  __typename?: 'FontVariant';
  fontSize?: number | null;
  fontWeight?: FontWeight | null;
};
