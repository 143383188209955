export const getListCursors = <T extends { id: string }>(
  items?: T[] | null,
  currentItem?: T | null,
  itemSetter?: (item: T) => void,
) => {
  if (!currentItem || !items || !items.length || !itemSetter) {
    return {};
  }

  const currentItemIdx = items.findIndex((event) => event.id === currentItem.id);

  const previousItem = items[currentItemIdx - 1];
  const nextItem = items[currentItemIdx + 1];

  return {
    setPrevItem: previousItem ? () => itemSetter(previousItem) : undefined,
    setNextItem: nextItem ? () => itemSetter(nextItem) : undefined,
  };
};
