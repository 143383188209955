import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import remove from 'lodash/remove';
import { Environment, MemberConnection, Account } from '@stigg-types/apiTypes';
import { addAccount } from './mutations/addAccount';
import { addEnvironment } from './mutations/addEnvironment';
import { updateEnvironment } from './mutations/updateEnvironment';
import { updateAccount } from './mutations/updateAccount';
import { fetchAccount } from './queries/fetchAccount';
import { fetchAccountMembers } from './queries/fetchAccountMembers';
import { fetchEnvironments } from './queries/fetchEnvironments';
import { inviteMembers } from './mutations/inviteMembers';
import { removeMember } from './mutations/removeMember';
import { archiveEnvironment } from './mutations/archiveEnvironment';
import { hideGettingStartedPage } from './mutations/hideGettingStartedPage';
import { provisionSandbox } from './mutations/provisionSandbox';
import { mergeEnvironment } from './mutations/mergeEnvironment';

import { createAppAsyncThunk } from '../../redux/createAppAsyncThunk';
import { dumpEnvironmentForMergeComparison } from './queries/dumpEnvironmentForMergeComparison';
import { validateMergeEnvironment } from './queries/validateMergeEnvironment';
import { dumpEnvironmentProductCatalog } from './queries/dumpEnvironmentProductCatalog';
import { setCurrentAccountId } from '../../ApolloClient';
import { setAccessRoles } from './mutations/setAccessRoles';

export interface AccountsState {
  isLoading: boolean;
  account: Account | null;
  currentAccountId?: string;
  members: MemberConnection;
  isLoadingEnvironments: boolean;
  isLoadingAccountMembers: boolean;
  environments: Environment[];
  currentEnvironmentId?: string;
  isMergingEnvironment: boolean;
  environmentComparison: {
    isLoading: boolean;
    preMergeDump: any;
    postMergeDump: any;
  };
  mergeEnvironmentValidation: {
    isLoading: boolean;
    isLoaded: boolean;
    isValid: boolean;
    errors: string[];
  };
}

export const initialState: AccountsState = {
  isLoading: false,
  account: null,
  members: {} as MemberConnection,
  isLoadingEnvironments: false,
  isLoadingAccountMembers: false,
  environments: [],
  isMergingEnvironment: false,
  environmentComparison: {
    isLoading: false,
    preMergeDump: null,
    postMergeDump: null,
  },
  mergeEnvironmentValidation: {
    isLoading: false,
    isLoaded: false,
    isValid: true,
    errors: [],
  },
};

const setAccessRolesAction = createAppAsyncThunk('setAccessRoles', setAccessRoles);
const addAccountAction = createAppAsyncThunk('addAccount', addAccount);
const removeMemberAction = createAppAsyncThunk('removeMember', removeMember);
const fetchAccountAction = createAppAsyncThunk('fetchAccount', fetchAccount);
const fetchAccountMembersAction = createAppAsyncThunk('fetchAccountMembers', fetchAccountMembers);
const fetchEnvironmentsAction = createAppAsyncThunk('fetchEnvironments', fetchEnvironments);
const addEnvironmentAction = createAppAsyncThunk('addEnvironment', addEnvironment);
const updateEnvironmentAction = createAppAsyncThunk('updateEnvironment', updateEnvironment);
const updateAccountAction = createAppAsyncThunk('updateAccount', updateAccount);
const inviteNewMembersAction = createAppAsyncThunk('inviteNewMembers', inviteMembers);
const archiveEnvironmentAction = createAppAsyncThunk('archiveEnvironment', archiveEnvironment);
const hideGettingStartedPageAction = createAppAsyncThunk('hideGettingStartedPage', hideGettingStartedPage);
const provisionSandboxAction = createAppAsyncThunk('provisionSandbox', provisionSandbox);
const mergeEnvironmentAction = createAppAsyncThunk('mergeEnvironment', mergeEnvironment);
const dumpEnvironmentForMergeComparisonAction = createAppAsyncThunk(
  'dumpEnvironmentForMergeComparison',
  dumpEnvironmentForMergeComparison,
);
const validateMergeEnvironmentAction = createAppAsyncThunk('validateMergeEnvironment', validateMergeEnvironment);
const dumpEnvironmentProductCatalogAction = createAppAsyncThunk(
  'dumpEnvironmentProductCatalog',
  dumpEnvironmentProductCatalog,
);

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    selectAccount: (state, action: PayloadAction<string>) => {
      state.currentAccountId = action.payload;
      setCurrentAccountId(state.currentAccountId);
    },
    selectEnvironment: (state, action: PayloadAction<string>) => {
      state.currentEnvironmentId = action.payload;
    },
    selectEnvironmentBySlug: (state, action: PayloadAction<string>) => {
      const env = state.environments.find(({ slug }) => slug === action.payload);
      if (env) {
        state.currentEnvironmentId = env.id;
      }
    },
    resetEnvironmentComparison: (state) => {
      state.environmentComparison = initialState.environmentComparison;
    },
    resetMergeEnvironmentValidation: (state) => {
      state.mergeEnvironmentValidation = initialState.mergeEnvironmentValidation;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addAccountAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addAccountAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addAccountAction.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchAccountAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAccountAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchAccountAction.fulfilled, (state, action) => {
      if (action.payload) {
        state.account = action.payload;
      }
      state.isLoading = false;
    });
    builder.addCase(fetchAccountMembersAction.pending, (state, action) => {
      state.isLoadingAccountMembers = !action.meta.arg.silentFetch;
    });
    builder.addCase(fetchAccountMembersAction.rejected, (state) => {
      state.isLoadingAccountMembers = false;
    });
    builder.addCase(fetchAccountMembersAction.fulfilled, (state, action) => {
      if (action.payload) {
        state.members = action.payload;
      }
      state.isLoadingAccountMembers = false;
    });

    builder.addCase(fetchEnvironmentsAction.pending, (state, action) => {
      if (isEmpty(state.environments) && !action?.meta?.arg?.silentFetch) {
        state.isLoadingEnvironments = true;
      }
    });
    builder.addCase(fetchEnvironmentsAction.rejected, (state) => {
      state.isLoadingEnvironments = false;
    });
    builder.addCase(fetchEnvironmentsAction.fulfilled, (state, action) => {
      state.environments = action.payload;
      if (
        isEmpty(state.currentEnvironmentId) ||
        !state.environments.find((env) => env.id === state.currentEnvironmentId)
      ) {
        state.currentEnvironmentId = head(state.environments)?.id;
      }
      state.isLoadingEnvironments = false;
    });

    builder.addCase(provisionSandboxAction.fulfilled, (state, action) => {
      if (action.payload?.id) {
        state.currentEnvironmentId = action.payload?.id;
      }
    });

    builder.addCase(addEnvironmentAction.fulfilled, (state, action) => {
      if (action.payload?.id) {
        state.currentEnvironmentId = action.payload?.id;
      }
    });

    builder.addCase(removeMemberAction.fulfilled, (state, action) => {
      remove(state.members.edges, (member) => member.node.id === action.payload.id);
    });
    builder.addCase(mergeEnvironmentAction.pending, (state) => {
      state.isMergingEnvironment = true;
    });
    builder.addCase(mergeEnvironmentAction.rejected, (state) => {
      state.isMergingEnvironment = false;
    });
    builder.addCase(mergeEnvironmentAction.fulfilled, (state) => {
      state.isMergingEnvironment = false;
    });
    builder.addCase(dumpEnvironmentForMergeComparisonAction.pending, (state) => {
      state.environmentComparison.isLoading = true;
    });
    builder.addCase(dumpEnvironmentForMergeComparisonAction.rejected, (state) => {
      state.environmentComparison.isLoading = false;
    });
    builder.addCase(dumpEnvironmentForMergeComparisonAction.fulfilled, (state, action) => {
      state.environmentComparison.isLoading = false;
      state.environmentComparison.preMergeDump = action.payload.preMergeDump;
      state.environmentComparison.postMergeDump = action.payload.postMergeDump;
    });
    builder.addCase(validateMergeEnvironmentAction.pending, (state) => {
      state.mergeEnvironmentValidation.isLoading = true;
    });
    builder.addCase(validateMergeEnvironmentAction.rejected, (state) => {
      state.mergeEnvironmentValidation.isLoading = false;
    });
    builder.addCase(validateMergeEnvironmentAction.fulfilled, (state, action) => {
      state.mergeEnvironmentValidation.isLoading = false;
      state.mergeEnvironmentValidation.isLoaded = true;
      state.mergeEnvironmentValidation.isValid = action.payload.isValid;
      state.mergeEnvironmentValidation.errors = action.payload.errors;
    });
  },
});

const {
  selectAccount,
  selectEnvironment,
  selectEnvironmentBySlug,
  resetEnvironmentComparison,
  resetMergeEnvironmentValidation,
} = accountsSlice.actions;

export {
  addAccountAction,
  fetchAccountAction,
  fetchAccountMembersAction,
  fetchEnvironmentsAction,
  addEnvironmentAction,
  updateEnvironmentAction,
  updateAccountAction,
  selectAccount,
  selectEnvironment,
  selectEnvironmentBySlug,
  inviteNewMembersAction,
  removeMemberAction,
  hideGettingStartedPageAction,
  archiveEnvironmentAction,
  provisionSandboxAction,
  mergeEnvironmentAction,
  resetEnvironmentComparison,
  dumpEnvironmentForMergeComparisonAction,
  validateMergeEnvironmentAction,
  resetMergeEnvironmentValidation,
  dumpEnvironmentProductCatalogAction,
  setAccessRolesAction,
};
export default accountsSlice.reducer;
