import styled from '@emotion/styled';
import { Box, Button, Icon, InformationTooltip, Text } from '@stigg-components';
import { t } from 'i18next';

type ClientSideSecurityIndicatorProps = { enabled: boolean; allowChange: boolean; onClick: () => void };

const StyledButton = styled(Button)`
  border: none;
`;

export function ClientSideSecurityIndicator({ enabled, allowChange, onClick }: ClientSideSecurityIndicatorProps) {
  const button = (
    <StyledButton
      size="small"
      sx={{ paddingX: 2 }}
      variant="text"
      color={enabled ? 'success' : 'warning'}
      startIcon={<Icon size={20} type="reactFeather" icon={enabled ? 'Lock' : 'AlertTriangle'} />}
      onClick={onClick}
      disabled={!allowChange}
      endIcon={allowChange ? <Icon size={18} type="reactFeather" icon="ChevronRight" /> : null}>
      {enabled ? t('accounts.enforced') : t('accounts.notEnforced')}
    </StyledButton>
  );

  if (allowChange) {
    return button;
  }

  return (
    <InformationTooltip
      arrow
      $padding={2}
      placement="top"
      title={<Text.B2>{t('permissions.changeClientSideSecurity')}</Text.B2>}>
      <Box width="fit-content">{button}</Box>
    </InformationTooltip>
  );
}
