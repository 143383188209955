import { t } from 'i18next';
import { GridFlex, Icon } from '@stigg-components';
import DetailButtons from './DetailButtons';

type DrawerDetailsFooterProps = {
  onClose?: () => void;
  onPrevious?: () => void;
  onNext?: () => void;
};

export default function DrawerDetailsFooter({ onClose, onPrevious, onNext }: DrawerDetailsFooterProps) {
  return (
    <GridFlex.RowSpaceBetween>
      {onPrevious || onNext ? (
        <GridFlex.Row gap={2}>
          <DetailButtons onClick={onPrevious} component={<Icon icon="ChevronLeft" color="active" />} />
          <DetailButtons onClick={onNext} component={<Icon icon="ChevronRight" color="active" />} />
        </GridFlex.Row>
      ) : null}
      {onClose && (
        <DetailButtons onClick={onClose} component={t('eventsLog.detailsDrawer.close')} style={{ paddingX: 4 }} />
      )}
    </GridFlex.RowSpaceBetween>
  );
}
