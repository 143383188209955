import { useMemo, useCallback } from 'react';
import { GetActiveSubscriptions } from '@stigg/js-client-sdk';
import { useStiggContext } from './useStiggContext';
import { useFetch } from './useFetch';

export function useActiveSubscriptions(params?: GetActiveSubscriptions) {
  const stiggContext = useStiggContext({ optional: true });
  const stigg = stiggContext?.stigg;

  const func = useCallback(() => (stigg ? stigg.getActiveSubscriptions(params) : Promise.resolve([])), [params, stigg]);
  const { data, error, isLoaded } = useFetch({ func });

  return useMemo(
    () => ({
      activeSubscriptions: data,
      isLoaded,
      error,
    }),
    [data, error, isLoaded],
  );
}
