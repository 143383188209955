import { useMemo, useEffect } from 'react';
import {
  Auth0ApplicationType,
  IntegrationFragment,
  Auth0CredentialsFragment,
  VendorIdentifier,
  SubscriptionStartSetup,
  Auth0CredentialsInput,
} from '@stigg-types/apiTypes';
import { WizardAccordion, PageCard, Text } from '@stigg-components';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import styled from 'styled-components/macro';
import { useAuth0IntegrationWizardSteps } from './useAuth0IntegrationWizardSteps';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { fetchProductsAction } from '../../../products/productsSlice';
import { Auth0IntegrationFormFields } from './useAuth0Form';
import { createIntegrationAction, updateIntegrationAction } from '../../integrationsSlice';
import { isAuth0IntegrationCompleted } from './isAuth0IntegrationCompleted';

type Auth0IntegrationPageProps = {
  integration: IntegrationFragment | null;
  headerComponent: React.ReactNode;
  isReadOnly: boolean;
};

const StyledPageCard = styled(PageCard)`
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
`;

export function Auth0IntegrationPage({ integration, headerComponent, isReadOnly }: Auth0IntegrationPageProps) {
  const dispatch = useAppDispatch();
  const credentials = integration?.credentials as Auth0CredentialsFragment;
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const products = useSelector((root: RootState) => root.productReducer.products);
  const allPlans = useMemo(() => products.flatMap((product) => product.plans), [products]);

  const { isSetupCompleted, isClientDetailsStepCompleted, isApplicationStepCompleted } =
    isAuth0IntegrationCompleted(credentials);

  const onAddOrUpdateIntegration = async (values: Auth0IntegrationFormFields) => {
    const { individual, organization, applicationType } = values;
    const individualInitialPlanId =
      individual.subscriptionStartSetup === SubscriptionStartSetup.FreePlan
        ? individual.subscriptionStartFreePlanId
        : individual.subscriptionStartSetup === SubscriptionStartSetup.TrialPeriod
        ? individual.subscriptionStartTrialPlanId
        : undefined;
    const organizationInitialPlanId =
      organization.subscriptionStartSetup === SubscriptionStartSetup.FreePlan
        ? organization.subscriptionStartFreePlanId
        : organization.subscriptionStartSetup === SubscriptionStartSetup.TrialPeriod
        ? organization.subscriptionStartTrialPlanId
        : undefined;
    const individualInitialPlanRefId = allPlans.find((plan) => plan.id === individualInitialPlanId)?.refId;
    const organizationInitialPlanRefId = allPlans.find((plan) => plan.id === organizationInitialPlanId)?.refId;

    const isIndividualApp = [Auth0ApplicationType.Individual, Auth0ApplicationType.Both].includes(applicationType);
    const isOrganizationApp = [Auth0ApplicationType.Organization, Auth0ApplicationType.Both].includes(applicationType);
    const auth0Credentials: Auth0CredentialsInput = {
      clientId: values.clientId,
      clientSecret: values.clientSecret,
      clientDomain: values.clientDomain,
      applicationId: values.applicationId,
      applicationType: values.applicationType,
      applicationName: values.applicationName,
      individualInitialPlanId: isIndividualApp ? individualInitialPlanRefId : undefined,
      individualSubscriptionStartSetup: isIndividualApp ? individual.subscriptionStartSetup : undefined,
      organizationInitialPlanId: isOrganizationApp ? organizationInitialPlanRefId : undefined,
      organizationSubscriptionStartSetup: isOrganizationApp ? organization.subscriptionStartSetup : undefined,
    };
    const { isSetupCompleted: isExistingIntegrationAlreadyCompleted } = isAuth0IntegrationCompleted(credentials);
    const { isSetupCompleted: isNewIntegrationCompleted } = isAuth0IntegrationCompleted(auth0Credentials);

    if (integration) {
      await dispatch(
        updateIntegrationAction({
          integrationId: integration.id,
          integrationData: {
            vendorIdentifier: VendorIdentifier.Auth0,
            auth0Credentials,
          },
          skipFetch: !isNewIntegrationCompleted,
          noSuccessMessage: !isNewIntegrationCompleted,
          successMessage: isNewIntegrationCompleted
            ? isExistingIntegrationAlreadyCompleted
              ? t('integrations.api.successUpdate')
              : t('integrations.api.successCreate')
            : undefined,
        }),
      ).unwrap();
    } else {
      await dispatch(
        createIntegrationAction({
          vendorIdentifier: VendorIdentifier.Auth0,
          auth0Credentials,
          noSuccessMessage: true,
        }),
      ).unwrap();
    }
  };

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(
        fetchProductsAction({
          environmentId: currentEnvironmentId,
        }),
      );
    }
  }, [dispatch, currentEnvironmentId]);

  const steps = useAuth0IntegrationWizardSteps({
    isReadOnly,
    isSetupCompleted,
    credentials,
    onAddOrUpdateIntegration,
  });

  const initialFocusedStep = isSetupCompleted
    ? undefined
    : isApplicationStepCompleted
    ? 'auth0-initial-customer-access'
    : isClientDetailsStepCompleted
    ? 'auth0-application'
    : undefined;

  return (
    <>
      <StyledPageCard cardProps={{ p: 4, marginBottom: 6 }}>{headerComponent}</StyledPageCard>

      {isReadOnly && !isSetupCompleted ? null : (
        <>
          <Text.H6 mb={4}>{t('integrations.auth0.integrateWithStiggTitle')}</Text.H6>

          <WizardAccordion
            layoutProps={{ maxWidth: '100%' }}
            uniqueFlowId="auth0-stigg"
            steps={steps}
            initialFocusedStep={initialFocusedStep}
            isStepByStep={!isSetupCompleted}
            showContinueButtonForLastStep
            alwaysShowContinueButton={isSetupCompleted}
            onlySingleStepExpanded
          />
        </>
      )}
    </>
  );
}
