import { ChevronRight, Check } from 'react-feather';
import {
  Grid,
  Box,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Select,
  Text,
  GridFlex,
  LongText,
  InformationTooltip,
  ExternalLink,
  Divider,
  CollapsableSection,
  Switch,
  useMount,
} from '@stigg-components';
import { FormikHandlers, FormikState, getIn } from 'formik';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import lowerCase from 'lodash/lowerCase';
import map from 'lodash/map';
import { getIconColor, styled } from '@stigg-theme';
import {
  EntitlementResetPeriod,
  FeatureType,
  YearlyAccordingTo,
  MonthlyAccordingTo,
  WeeklyAccordingTo,
  MeterType,
} from '@stigg-types/apiTypes';
import { EntitlementFields, EntitlementFormFields } from '../../../entitlements/components/entitlementSettings/types';
import { SelectableIconButton } from '../../../widgets/components/leftSidePanel/SelectableIconButton';

const StyledGridColumn = styled(GridFlex.Column)`
  & label + .MuiInput-root {
    margin-top: 2px;
  }
`;

const resetPeriods = () => [
  { text: t('entitlements.entitlementYearResetPeriod'), value: EntitlementResetPeriod.Year },
  { text: t('entitlements.entitlementMonthResetPeriod'), value: EntitlementResetPeriod.Month },
  { text: t('entitlements.entitlementWeekResetPeriod'), value: EntitlementResetPeriod.Week },
  { text: t('entitlements.entitlementDayResetPeriod'), value: EntitlementResetPeriod.Day },
  { text: t('entitlements.entitlementHourResetPeriod'), value: EntitlementResetPeriod.Hour },
];

const yearlyAccordingTo = () => [
  { text: t('entitlements.yearlyAccordingToSubscription'), value: YearlyAccordingTo.SubscriptionStart },
];

const monthlyAccordingTo = () => [
  { text: t('entitlements.monthlyAccordingToSubscription'), value: MonthlyAccordingTo.SubscriptionStart },
  { text: t('entitlements.monthlyAccordingToStartOfMonth'), value: MonthlyAccordingTo.StartOfTheMonth },
];

const weeklyAccordingTo = () => [
  { text: t('entitlements.weeklyAccordingToSubscription'), value: WeeklyAccordingTo.SubscriptionStart },
  { text: t('entitlements.weeklyAccordingToSunday'), value: WeeklyAccordingTo.EverySunday },
  { text: t('entitlements.weeklyAccordingToMonday'), value: WeeklyAccordingTo.EveryMonday },
  { text: t('entitlements.weeklyAccordingToTuesday'), value: WeeklyAccordingTo.EveryTuesday },
  { text: t('entitlements.weeklyAccordingToWednesday'), value: WeeklyAccordingTo.EveryWednesday },
  { text: t('entitlements.weeklyAccordingToThursday'), value: WeeklyAccordingTo.EveryThursday },
  { text: t('entitlements.weeklyAccordingToFriday'), value: WeeklyAccordingTo.EveryFriday },
  { text: t('entitlements.weeklyAccordingToSaturday'), value: WeeklyAccordingTo.EverySaturday },
];

const dailyAccordingTo = () => [{ text: t('entitlements.dailyAccordingTo'), value: null }];

const hourlyAccordingTo = () => [{ text: t('entitlements.hourlyAccordingTo'), value: null }];

export function getAccordingToOptions(resetPeriod: EntitlementResetPeriod | null | undefined) {
  switch (resetPeriod) {
    case EntitlementResetPeriod.Year:
      return yearlyAccordingTo();
    case EntitlementResetPeriod.Month:
      return monthlyAccordingTo();
    case EntitlementResetPeriod.Week:
      return weeklyAccordingTo();
    case EntitlementResetPeriod.Day:
      return dailyAccordingTo();
    case EntitlementResetPeriod.Hour:
      return hourlyAccordingTo();
    default:
      return [];
  }
}

export const StyledChevronDown = styled(ChevronRight)<{ $isUp: boolean }>`
  position: absolute;
  right: 30px;
  color: ${({ theme }) => theme.itamar.palette.iconDefault};
  transition: all 0.2s ease-in;
  ${({ $isUp }) =>
    $isUp &&
    `
    transform: rotate(90deg)
  `}
`;

export function IncrementalEntitlementConfigurationRow({
  entitlement,
  handleChange,
  onResetPeriodChanged,
  toggleResetPeriod,
  index,
  toggleIsOpen,
  isOpen,
}: {
  entitlement: EntitlementFields;
  handleChange: any;
  onResetPeriodChanged: any;
  toggleResetPeriod: any;
  index: number;
  toggleIsOpen: () => void;
  isOpen: boolean;
}) {
  const accordingToOptions = entitlement.resetPeriod ? getAccordingToOptions(entitlement.resetPeriod) : [];
  const showSelect =
    !entitlement.resetPeriod ||
    entitlement.resetPeriod === EntitlementResetPeriod.Month ||
    entitlement.resetPeriod === EntitlementResetPeriod.Week;

  return (
    <TableRow key={index}>
      <TableCell sx={{ padding: 2 }} colSpan={3}>
        <Box sx={{ borderRadius: '10px', padding: 2, backgroundColor: (theme) => theme.itamar.palette.action.hover }}>
          <CollapsableSection
            gap={0}
            buttonSx={{ padding: 0 }}
            isSectionOpen={isOpen}
            onClick={() => {
              if (entitlement.resetPeriod) {
                toggleIsOpen();
              }
            }}
            hideIcon
            title={
              <GridFlex.RowCenter sx={{ width: '100%' }}>
                <Switch checked={!!entitlement.resetPeriod} onChange={toggleResetPeriod} />
                <Text.B2>{t('entitlements.resetPeriodSettings')}</Text.B2>
                {!isOpen && (
                  <Text.B2 ml={8} color="secondary">
                    {!entitlement.resetPeriod
                      ? 'Not reset'
                      : `Every ${lowerCase(t(`entitlements.entitlementResetPeriod.${entitlement.resetPeriod}`))}`}
                  </Text.B2>
                )}
                {entitlement.resetPeriod ? (
                  <StyledChevronDown $isUp={isOpen} data-testid="expand-entitlement-reset-period-settings" />
                ) : null}
              </GridFlex.RowCenter>
            }
            content={
              <Grid container flexDirection="column" px={4} wrap="nowrap">
                <Divider my={2} />
                <Grid item container alignItems="flex-end" justifyContent="space-between" wrap="nowrap">
                  <StyledGridColumn item sm={6} mr={4}>
                    <Select
                      label={t('entitlements.resetEveryLabel')}
                      name={`entitlements[${index}].resetPeriod`}
                      fullWidth
                      labelId="reset-usage-period"
                      onChange={onResetPeriodChanged}
                      value={entitlement.resetPeriod}
                      dataTestId="reset-period-select-input"
                      renderValue={(value: any) => {
                        const resetPeriod = resetPeriods().find((v) => v.value === value);
                        return <Text.B2>{resetPeriod?.text}</Text.B2>;
                      }}>
                      {map(resetPeriods(), (resetPeriod: { text: string; value: EntitlementResetPeriod }) => (
                        <MenuItem key={resetPeriod.value} value={resetPeriod.value}>
                          <Text.B2
                            color="primary"
                            data-testid={`reset-period-select-${resetPeriod.text.toLowerCase()}`}>
                            {resetPeriod.text}
                          </Text.B2>
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledGridColumn>
                  <StyledGridColumn item sm={6}>
                    {showSelect ? (
                      <GridFlex.Item mt={6}>
                        <Select
                          fullWidth
                          onChange={handleChange}
                          value={entitlement.resetPeriodConfiguration}
                          name={`entitlements[${index}].resetPeriodConfiguration`}
                          renderValue={(value: any) => {
                            // @ts-ignore
                            const displayValue = accordingToOptions.find((v: any) => v.value === value)?.text;
                            return <LongText color="primary">{displayValue}</LongText>;
                          }}>
                          {map(accordingToOptions, (resetPeriod: any) => (
                            <MenuItem key={resetPeriod.value} value={resetPeriod.value} sx={{ whiteSpace: 'pre-wrap' }}>
                              <Text.B2 color="primary">{resetPeriod.text}</Text.B2>
                            </MenuItem>
                          ))}
                        </Select>
                      </GridFlex.Item>
                    ) : (
                      <GridFlex.Item mt={6}>
                        <TextField
                          touched={false}
                          error={false}
                          disabled
                          fullWidth
                          value={
                            entitlement.resetPeriod === EntitlementResetPeriod.Year
                              ? t('entitlements.yearlyAccordingToSubscription')
                              : entitlement.resetPeriod === EntitlementResetPeriod.Day
                              ? t('entitlements.dailyAccordingTo')
                              : entitlement.resetPeriod === EntitlementResetPeriod.Hour
                              ? t('entitlements.hourlyAccordingTo')
                              : ''
                          }
                        />
                      </GridFlex.Item>
                    )}
                  </StyledGridColumn>
                </Grid>
              </Grid>
            }
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}

type EntitlementValueTextFieldProps = {
  name: string;
  units: string;
  value: any;
  handleBlur: FormikHandlers['handleBlur'];
  handleChange: FormikHandlers['handleChange'];
  touched: FormikState<EntitlementFormFields>['touched'];
  errors: FormikState<EntitlementFormFields>['errors'];
  hasUnlimitedUsage?: boolean;
  isCustom?: boolean;
  captionText?: string;
};

function EntitlementValueTextField({
  value,
  name,
  handleBlur,
  handleChange,
  touched,
  errors,
  units,
  hasUnlimitedUsage,
  isCustom,
  captionText,
}: EntitlementValueTextFieldProps) {
  const isDisabled = hasUnlimitedUsage || isCustom;
  const placeholder = isDisabled
    ? `${hasUnlimitedUsage ? t('entitlements.unlimitedUsage') : t('entitlements.custom')} ${units?.toLowerCase()}`
    : undefined;

  return (
    <GridFlex.Item sx={{ width: 180, mr: 2 }}>
      <TextField
        autoFocus
        type={isDisabled ? 'text' : 'number'}
        key={`${String(hasUnlimitedUsage)}-${String(isCustom)}`}
        disabled={isDisabled}
        name={name}
        placeholder={placeholder}
        value={isDisabled ? null : value}
        onChange={handleChange}
        touched={getIn(touched, name)}
        error={!!getIn(errors, name)}
        errorText={getIn(errors, name)}
        onBlur={handleBlur}
        captionText={captionText}
        withAbsoluteErrorMessage
      />
    </GridFlex.Item>
  );
}

type EntitlementRowProps = {
  entitlement: EntitlementFields;
  index: number;
  handleBlur: any;
  touched: any;
  errors: any;
  handleChange: any;
  isAdd: boolean;
  remove?: (index: number) => void;
  setFieldValue: any;
  setFieldTouched: any;
  withCustomEntitlementOption?: boolean;
};

export async function onSwitchChange({
  currentValue,
  switchField,
  setFieldValue,
  setFieldTouched,
  index,
  usageLimitValue,
  isCustom,
}: {
  currentValue: boolean | null | undefined;
  switchField: keyof EntitlementFields;
  setFieldValue: any;
  setFieldTouched: any;
  index: number;
  usageLimitValue?: number | null;
  isCustom?: boolean;
}) {
  setFieldValue(`entitlements[${index}].isCustom`, isCustom ?? false, false);
  setFieldValue(`entitlements[${index}].hasUnlimitedUsage`, false, false);
  await setFieldValue(`entitlements[${index}].${switchField}`, !currentValue, false);
  await setFieldValue(`entitlements[${index}].usageLimit`, usageLimitValue, false);

  // Using setTimeout here because otherwise Formik doesn't revalidate the usageLimit field
  // See discussion about it here: https://github.com/jaredpalmer/formik/issues/2059
  setTimeout(() => setFieldTouched(`entitlements[${index}].usageLimit`, true));
}

export type EntitlementValueProps = Omit<EntitlementRowProps, 'isAdd' | 'remove'> & {
  setEntitlementPreviousValue: (id: string, value?: number | null) => void;
  previousValue?: number | null;
  captionText?: string;
};

export function EntitlementValue({
  entitlement,
  index,
  handleBlur,
  touched,
  errors,
  handleChange,
  setFieldValue,
  setFieldTouched,
  withCustomEntitlementOption,
  previousValue,
  setEntitlementPreviousValue,
  captionText,
}: EntitlementValueProps) {
  const isMeteredEntitlement = entitlement.feature?.meterType !== MeterType.None;
  let units =
    entitlement?.usageLimit && entitlement.usageLimit > 1
      ? entitlement.feature?.featureUnitsPlural || ''
      : entitlement.feature?.featureUnits || '';

  if (entitlement.hasUnlimitedUsage) {
    units = entitlement.feature?.featureUnitsPlural || '';
  }

  useMount(() => {
    if (entitlement.feature) {
      setEntitlementPreviousValue(entitlement.feature.id, entitlement.usageLimit);
    }
  });

  return entitlement.feature?.featureType === FeatureType.Boolean ? (
    <Check color={getIconColor('active')} />
  ) : (
    <GridFlex.RowCenter
      container
      wrap="nowrap"
      alignItems="flex-start"
      key={entitlement?.hasUnlimitedUsage?.toString()}>
      <Grid item>
        <EntitlementValueTextField
          value={entitlement.usageLimit}
          name={`entitlements[${index}].usageLimit`}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          handleChange={handleChange}
          units={units}
          hasUnlimitedUsage={!!entitlement.hasUnlimitedUsage}
          isCustom={!!entitlement.isCustom}
          captionText={captionText}
        />
      </Grid>
      <Grid item>
        <Grid item>
          <InformationTooltip
            arrow
            placement="top"
            $padding={2}
            title={
              <Text.B2>
                {t('entitlements.unlimitedUsage')} {entitlement.feature?.featureUnitsPlural}
              </Text.B2>
            }>
            <Box>
              <SelectableIconButton
                isSelected={!!entitlement.hasUnlimitedUsage}
                onClick={async () => {
                  if (entitlement.feature) {
                    setEntitlementPreviousValue(entitlement.feature.id, entitlement.usageLimit);
                    await onSwitchChange({
                      currentValue: entitlement.hasUnlimitedUsage,
                      switchField: 'hasUnlimitedUsage',
                      setFieldValue,
                      setFieldTouched,
                      index,
                      usageLimitValue: !entitlement.hasUnlimitedUsage ? undefined : previousValue,
                    });
                    if (!entitlement.hasUnlimitedUsage) {
                      setFieldValue(`entitlements[${index}].hasSoftLimit`, false, true);
                    }
                  }
                }}
                overrideStroke
                icon="InfinityIcon"
              />
            </Box>
          </InformationTooltip>
        </Grid>
        {withCustomEntitlementOption && (
          <Grid item ml={1}>
            <InformationTooltip
              arrow
              placement="top"
              $padding={4}
              title={
                <>
                  <Text.B2 mb={2} whiteSpace="pre-line">
                    <Trans
                      i18nKey="entitlements.customToggleTooltip"
                      t={t}
                      values={{ unitsPlural: entitlement.feature?.featureUnitsPlural }}
                    />
                  </Text.B2>
                  <ExternalLink
                    label="Learn more"
                    url="https://docs.stigg.io/docs/plans#defining-variable-entitlement-values"
                  />
                </>
              }>
              <Box>
                <SelectableIconButton
                  isSelected={!!entitlement.isCustom}
                  onClick={async () => {
                    if (entitlement.feature) {
                      setEntitlementPreviousValue(entitlement.feature.id, entitlement.usageLimit);
                      await onSwitchChange({
                        currentValue: entitlement.isCustom,
                        switchField: 'isCustom',
                        setFieldValue,
                        setFieldTouched,
                        index,
                        usageLimitValue: !entitlement.isCustom ? undefined : previousValue,
                      });
                    }
                  }}
                  overrideStroke
                  icon="CustomEntitlement"
                />
              </Box>
            </InformationTooltip>
          </Grid>
        )}
      </Grid>

      {isMeteredEntitlement ? (
        <Grid item ml={1}>
          <InformationTooltip
            arrow
            placement="top"
            $padding={2}
            title={<Text.B2>{t('entitlements.hasSoftLimit')}</Text.B2>}>
            <Box>
              <SelectableIconButton
                isSelected={!!entitlement.hasSoftLimit}
                onClick={async () => {
                  const hasSoftLimitValue = !entitlement.hasSoftLimit;
                  setFieldValue(`entitlements[${index}].hasSoftLimit`, hasSoftLimitValue, false);
                  if (hasSoftLimitValue && entitlement.hasUnlimitedUsage) {
                    await onSwitchChange({
                      currentValue: true,
                      switchField: 'hasUnlimitedUsage',
                      setFieldValue,
                      setFieldTouched,
                      index,
                      usageLimitValue: previousValue,
                    });
                  }
                }}
                overrideStroke
                icon="SoftLimitEntitlement"
              />
            </Box>
          </InformationTooltip>
        </Grid>
      ) : null}
    </GridFlex.RowCenter>
  );
}
