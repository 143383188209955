import { t } from 'i18next';
import { CouponType, Currency } from '@stigg-types/apiTypes';
import { FormRenderProps, GridFlex } from '@stigg-components';
import { SubscriptionFormFields } from '../../SubscriptionForm.types';
import { CouponDuration } from '../../../../../../../coupons/components/createCoupon/CreateCouponForm';
import { AdditionalInputLayout } from '../AdditionalInputLayout';
import { DEFAULT_SUBSCRIPTION_FORM_COMPONENT_WIDTH } from '../consts';
import { SEARCH_COMPONENT_WIDTH } from './consts';
import { getCurrencySymbol } from '../../../../../../../packages/pricing/components/currency/currencyUtils';
import { getCouponTypes } from '../../../../../../../coupons/components/createCoupon/getCouponTypes';

type CustomCouponInputProps = {
  currency: Currency;
  formRenderProps: FormRenderProps<SubscriptionFormFields>;
};

function CustomCouponType({ currency, formRenderProps }: CustomCouponInputProps) {
  const handleTypeChange = ({ values, setFieldValue }: FormRenderProps<SubscriptionFormFields>) => {
    return (e: any) => {
      if (!e.target.value) {
        return;
      }

      const type = e.target.value as CouponType;
      setFieldValue('customCoupon.type', e.target.value);

      const { percentOff, amountsOff } = values.customCoupon || {};

      if (type === CouponType.Fixed && percentOff) {
        setFieldValue('customCoupon.amountsOff', [{ amount: percentOff, currency }], false);
        setFieldValue('customCoupon.percentOff', undefined, false);
      } else if (type === CouponType.Percentage && amountsOff) {
        setFieldValue('customCoupon.percentOff', amountsOff[0].amount, false);
        setFieldValue('customCoupon.amountsOff', undefined, false);
      }
    };
  };

  return (
    <AdditionalInputLayout
      isSet
      setLabel="Type"
      formRenderProps={formRenderProps}
      fields={[
        {
          id: 'customCoupon.type',
          type: 'select',
          values: getCouponTypes(),
          restProps: {
            width: DEFAULT_SUBSCRIPTION_FORM_COMPONENT_WIDTH,
          },
          handleChange: handleTypeChange,
        },
      ]}
    />
  );
}

function CustomCouponValue({ currency, formRenderProps }: CustomCouponInputProps) {
  const { values } = formRenderProps;
  const isFixedCoupon = values.customCoupon?.type === CouponType.Fixed;

  const handleValueChange = ({ values, setFieldValue }: FormRenderProps<SubscriptionFormFields>) => {
    return (e: any) => {
      if (!e?.target?.value) {
        return;
      }

      const value = parseFloat(e.target.value);

      setFieldValue('customCoupon.discountValue', value);
      if (values.customCoupon?.type === CouponType.Fixed) {
        setFieldValue('customCoupon.amountsOff', [{ amount: value, currency }]);
        setFieldValue('customCoupon.percentOff', undefined);
      } else {
        setFieldValue('customCoupon.percentOff', value);
        setFieldValue('customCoupon.amountsOff', undefined);
      }
    };
  };

  return (
    <AdditionalInputLayout
      isSet
      hideErrorText
      setLabel="Value"
      formRenderProps={formRenderProps}
      fields={[
        {
          id: 'customCoupon.discountValue',
          type: 'text',
          textFieldType: 'number',
          placeholder: '0',
          endAdornment: isFixedCoupon ? undefined : '%',
          startAdornment: isFixedCoupon ? getCurrencySymbol(currency) : undefined,
          isNumberWithoutSigns: true,
          hideErrorText: true,
          gridProps: {
            width: DEFAULT_SUBSCRIPTION_FORM_COMPONENT_WIDTH,
          },
          handleChange: handleValueChange,
        },
      ]}
    />
  );
}

function CustomCouponDuration({ formRenderProps }: Pick<CustomCouponInputProps, 'formRenderProps'>) {
  const { values } = formRenderProps;
  const isDurationForever = values.customCoupon?.duration !== CouponDuration.MultipleMonths;

  return (
    <AdditionalInputLayout
      isSet
      setLabel={t('coupons.duration.label')}
      formRenderProps={formRenderProps}
      gridProps={{ width: SEARCH_COMPONENT_WIDTH, gap: 10 }} // keep all form elements aligned
      inputGridProps={{ gap: 2, justifyContent: 'flex-start', width: '100%' }}
      fields={[
        {
          id: 'customCoupon.duration',
          type: 'select',
          values: [
            {
              value: CouponDuration.Forever,
              displayValue: t('coupons.duration.options.forever'),
            },
            {
              value: CouponDuration.MultipleMonths,
              displayValue: t('coupons.duration.options.months'),
            },
          ],
          restProps: {
            width: DEFAULT_SUBSCRIPTION_FORM_COMPONENT_WIDTH,
          },
        },
        {
          id: 'customCoupon.durationInMonths',
          type: 'text',
          textFieldType: 'number',
          placeholder: '1',
          isNumberWithoutSigns: true,
          endAdornment: t('coupons.duration.postfix.months', { count: values.customCoupon?.durationInMonths || 1 }),
          hide: () => isDurationForever,
          gridProps: {
            flex: 1,
          },
          hideErrorText: true,
        },
      ]}
    />
  );
}

export function CustomCouponInput({ currency, formRenderProps }: CustomCouponInputProps) {
  return (
    <GridFlex.Column $fullWidth gap={2}>
      <CustomCouponType formRenderProps={formRenderProps} currency={currency} />
      <CustomCouponValue currency={currency} formRenderProps={formRenderProps} />
      <CustomCouponDuration formRenderProps={formRenderProps} />
    </GridFlex.Column>
  );
}
