import { t } from 'i18next';
import { AccountAccessRole, EnvironmentAccessRole } from '@stigg-types/apiTypes';
import { Icon, GridFlex, Text, Field } from '@stigg-components';
import { TooltipFields } from '../../../components/InformationTooltip';

const ENVIRONMENT_ACCESS_ROLES = [
  EnvironmentAccessRole.Admin,
  EnvironmentAccessRole.Support,
  EnvironmentAccessRole.Viewer,
  EnvironmentAccessRole.None,
];

const AccountRoleIcon = ({ role }: { role: AccountAccessRole }) => {
  return (
    <Icon
      type="custom"
      icon={role === AccountAccessRole.Member ? 'MemberRole' : 'OwnerRole'}
      color="active"
      overrideStroke
    />
  );
};

const EnvironmentRoleSelectOption = ({ role }: { role: EnvironmentAccessRole }) => {
  return (
    <GridFlex.Column>
      <Text.B1 color="primary">{t(`accounts.environmentRole.${role}`)}</Text.B1>
      <Text.B2 color="secondary" sx={{ textWrap: 'auto' }}>
        {t(`accounts.environmentRoleDescription.${role}`)}
      </Text.B2>
    </GridFlex.Column>
  );
};

export function setRolesFields<FormValues>({
  hide,
  accountRoleDisabled,
  environmentRoleDisabled,
  accountTooltip,
  environmentTooltip,
}: {
  hide: boolean;
  accountRoleDisabled?: boolean;
  environmentRoleDisabled?: boolean;
  accountTooltip?: TooltipFields;
  environmentTooltip?: TooltipFields;
}): Field<FormValues>[] {
  return [
    {
      type: 'select',
      id: 'accountRole',
      hide: () => hide,
      disabled: accountRoleDisabled,
      tooltip: accountTooltip,
      label: t('accounts.accountRole'),
      placeholder: t('accounts.accountRolePlaceholder'),
      values: [AccountAccessRole.Owner, AccountAccessRole.Member].map((role) => ({
        value: role,
        overrideDisplayValue: (
          <GridFlex.RowCenter columnGap={2}>
            <AccountRoleIcon role={role} />
            <Text.B2 color="primary">{t(`accounts.accountRoleType.${role}`)}</Text.B2>
          </GridFlex.RowCenter>
        ),
        displayValue: (
          <GridFlex.RowCenter columnGap={2}>
            <AccountRoleIcon role={role} />
            <GridFlex.Column>
              <Text.B1 color="primary">{t(`accounts.accountRoleType.${role}`)}</Text.B1>
              <Text.B2 color="secondary" sx={{ textWrap: 'auto' }}>
                {t(`accounts.accountRoleDescription.${role}`)}
              </Text.B2>
            </GridFlex.Column>
          </GridFlex.RowCenter>
        ),
      })),
    },
    {
      type: 'select',
      id: 'productionAccess',
      hide: () => hide,
      label: t('accounts.productionRole'),
      disabled: environmentRoleDisabled,
      tooltip: environmentTooltip,
      placeholder: t('accounts.environmentAccessPlaceholder'),
      values: ENVIRONMENT_ACCESS_ROLES.map((role) => ({
        value: role,
        overrideDisplayValue: t(`accounts.environmentRole.${role}`),
        displayValue: <EnvironmentRoleSelectOption role={role} />,
      })),
    },
    {
      type: 'select',
      id: 'nonProductionAccess',
      hide: () => hide,
      disabled: environmentRoleDisabled,
      tooltip: environmentTooltip,
      label: t('accounts.nonProductionRole'),
      placeholder: t('accounts.environmentAccessPlaceholder'),
      values: ENVIRONMENT_ACCESS_ROLES.map((role) => ({
        value: role,
        overrideDisplayValue: t(`accounts.environmentRole.${role}`),
        displayValue: <EnvironmentRoleSelectOption role={role} />,
      })),
    },
  ];
}
