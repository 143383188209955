import { GridFlex, LongText, Text, OptionsDropdown, LocalDate } from '@stigg-components';
import { CustomerListFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { Edit2, Archive } from 'react-feather';
import { HeadCell } from '../../../components/table/Table';
import { CustomerIcon } from './CustomerIcon';

export const headCells = (
  onEditClick: (customer: CustomerListFragment) => void,
  onDeleteClick: (customer: CustomerListFragment) => void,
  isReadyOnly = true,
): Array<HeadCell<CustomerListFragment, any>> => [
  {
    id: 'icon',
    alignment: 'left',
    label: t('customers.customerId'),
    render: (customer) => <CustomerIcon size={24} customer={customer} />,
    width: 32,
  },
  {
    id: 'refId',
    alignment: 'left',
    render: (customer) => (
      <GridFlex.Row gap={2} data-testid="customer-item">
        <LongText variant="body2" className="fs-mask" color="primary" maxLines={2} tooltipPlacement="top" wordBreak>
          {customer.customerId}
        </LongText>
      </GridFlex.Row>
    ),
  },
  {
    id: 'name',
    alignment: 'left',
    label: t('customers.name'),
    render: (customer) => <Text.B2 className="fs-mask">{customer.name}</Text.B2>,
  },
  {
    id: 'subscriptionsCount',
    alignment: 'left',
    label: t('customers.activeSubscriptions'),
    render: (customer) => <Text.B2>{customer.totalActiveSubscription || '-'}</Text.B2>,
  },
  {
    id: 'promotionalEntitlementsCount',
    alignment: 'left',
    label: t('customers.activePromotionalEntitlements'),
    render: (customer) => <Text.B2>{customer.totalActivePromotionalEntitlements || '-'}</Text.B2>,
  },
  {
    id: 'updatedAt',
    alignment: 'left',
    label: t('customers.updatedAt'),
    render: (customer) => <LocalDate date={customer.updatedAt} formatOptions={{ withTime: true }} />,
  },
  {
    id: 'options',
    alignment: 'right',
    label: '',
    disableClick: true,
    width: 72,
    maxWidth: 36 + 8,
    visible: !isReadyOnly,
    render: (customer: CustomerListFragment) => (
      <OptionsDropdown
        options={[
          {
            icon: Edit2,
            text: t('customers.edit'),
            onClick: () => onEditClick(customer),
          },
          {
            icon: Archive,
            text: t('customers.archive'),
            onClick: () => onDeleteClick(customer),
          },
        ]}
      />
    ),
  },
];
