import { merge } from 'lodash';
import React, { PropsWithChildren } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { TypographyProps } from 'styled-typography';
import { css, ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { createTheme, ThemeProvider as MuiThemeProvider, THEME_ID } from '@mui/material/styles';

import { DeepPartial } from '../types';
import { Fonts } from './Fonts';
import { getResolvedTheme } from './getResolvedTheme';
import { StiggTheme } from './types';
import { useStiggContext } from '../hooks/useStiggContext';

export type CustomizedTheme = DeepPartial<StiggTheme>;

const CustomCssGlobal = styled.div<{ customCss?: string }>`
  ${({ customCss }) =>
    customCss &&
    css`
      ${customCss}
    `}
`;

type SdkThemeProviderProps = {
  componentTheme?: CustomizedTheme;
};

/*
  This merge the theme from 3 places:
  1. External theme that we got from the user in StiggProvider
  2. Remote theme from api
  3. Default theme
*/
export function useStiggTheme(remoteThemeOverride?: CustomizedTheme): StiggTheme {
  const { theme: externalTheme } = useStiggContext();
  const mergedTheme = merge(remoteThemeOverride, externalTheme);
  const resolvedTheme = getResolvedTheme(mergedTheme);

  return resolvedTheme;
}

function createTypographyTheme(theme: StiggTheme): Partial<TypographyProps> {
  const { typography } = theme;
  const htmlFontSize = 16;
  const coef = Number(typography.body.fontSize.replace('px', '')) / 14;

  return {
    // Hack to fix theming issues
    // @ts-ignore
    pxToRem: (size) => `${(size / htmlFontSize) * coef}rem`,
    bodyFontFamily: typography.fontFamily,
    headingFontFamily: typography.fontFamily,
    fontSizes: [
      typography.h1.fontSize,
      typography.h2.fontSize,
      typography.h3.fontSize,
      typography.body.fontSize,
      '12px',
      '10px',
    ],
  };
}

const createMuiPalette = (theme: StiggTheme) => {
  return {
    primary: {
      main: theme.palette.primary,
    },
  };
};

export const SdkThemeProvider: React.FC<PropsWithChildren<SdkThemeProviderProps>> = ({ children, componentTheme }) => {
  const theme = useStiggTheme(componentTheme);
  const muiPalette = createMuiPalette(theme);
  const materialTheme = createTheme({ palette: muiPalette, stigg: theme });

  return (
    // We are using styled-components theme here only because we are using styled-typography
    // which depends on styled-components for the typography theming
    <MuiThemeProvider theme={{ [THEME_ID]: materialTheme }}>
      <ThemeProvider theme={{ stigg: theme }}>
        <CustomCssGlobal customCss={theme.customCss}>
          <Fonts externalFontUrl={theme.typography.fontFamilyUrl} />
          <StyledThemeProvider theme={{ typography: createTypographyTheme(theme) }}>{children}</StyledThemeProvider>
        </CustomCssGlobal>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
