import { gql } from '@apollo/client';
import { t } from 'i18next';
import { executeOperationSafely } from '@stigg-common';
import {
  ActiveSubscriptionsFragment,
  GetActiveSubscriptionsQuery,
  GetActiveSubscriptionsQueryVariables,
} from '@stigg-types/apiTypes';
import { AppDispatch, RootState } from '../../../redux/store';
import { apolloClient } from '../../../ApolloClient';

const ACTIVE_SUBSCRIPTIONS_FRAGMENT = gql`
  fragment ActiveSubscriptionsFragment on CustomerSubscription {
    status
    resource {
      resourceId
    }
    plan {
      refId
    }
    pricingType
  }
`;

const GET_ACTIVE_SUBSCRIPTIONS = gql`
  query GetActiveSubscriptions($input: GetActiveSubscriptionsInput!) {
    getActiveSubscriptions(input: $input) {
      ...ActiveSubscriptionsFragment
    }
  }
  ${ACTIVE_SUBSCRIPTIONS_FRAGMENT}
`;

type FetchActiveSubscriptionsProps = {
  customerId: string;
  resourceId: string | undefined;
};

async function fetchActiveSubscriptions(
  { customerId, resourceId }: FetchActiveSubscriptionsProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<ActiveSubscriptionsFragment[]> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      const res = await apolloClient.query<GetActiveSubscriptionsQuery, GetActiveSubscriptionsQueryVariables>({
        query: GET_ACTIVE_SUBSCRIPTIONS,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            customerId,
            resourceId,
            environmentId: accountReducer.currentEnvironmentId || '',
          },
        },
      });

      return res.data.getActiveSubscriptions as ActiveSubscriptionsFragment[];
    },
    { failureMessageHandler: () => t('customers.api.failSubscriptionFetch') },
    dispatch,
  );
}

export { fetchActiveSubscriptions };
