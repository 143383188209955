import { ApiKey } from '@stigg-types/apiTypes';
import { useStiggTheme } from '@stigg-theme';
import { Box, ClipboardChip, CodeTypographyText } from '@stigg-components';
import styled from 'styled-components/macro';
import { EnvironmentPermissionActions, useEnvironmentPermissionCheck } from '@stigg-permissions';
import { truncateApiKey as truncate } from '../../utils/truncate';
import { CodeTypographyCard } from '../../../../components/clipboard/ClipboardChip';

type EnvironmentApiKeyProps = {
  apiKey: ApiKey;
  truncateApiKey?: boolean;
};

const ApiKeyBox = styled(Box)`
  width: fit-content;
`;

function CopyApiKeyComponent({ apiKey, text }: { apiKey: ApiKey; text: string }) {
  const { theme } = useStiggTheme();

  return (
    <ClipboardChip
      copy={apiKey.token}
      text={text}
      variant="code"
      iconLocation="right"
      backgroundColor={theme.itamar.palette.action.hover}
    />
  );
}

function ShowApiKeyComponent({ text }: { text: string }) {
  const { theme } = useStiggTheme();

  return (
    <CodeTypographyCard padding={1} $backgroundColor={theme.itamar.palette.action.hover} $disableCopy>
      <CodeTypographyText color="secondary">{text}</CodeTypographyText>
    </CodeTypographyCard>
  );
}

export function EnvironmentApiKey({ apiKey, truncateApiKey }: EnvironmentApiKeyProps) {
  const allowCopyKey = useEnvironmentPermissionCheck(EnvironmentPermissionActions.ReadApiKeys);

  const text = truncateApiKey ? truncate(apiKey.token) : apiKey.token;

  return (
    <ApiKeyBox>
      {allowCopyKey ? <CopyApiKeyComponent apiKey={apiKey} text={text} /> : <ShowApiKeyComponent text={text} />}
    </ApiKeyBox>
  );
}
