import { Text, GridFlex, ConfirmationDialog } from '@stigg-components';
import { CustomerResponseFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { detachCustomerPaymentMethodAction } from '../../../customersSlice';
import { useAppDispatch } from '../../../../../redux/store';

export type DetachPaymentMethodDialogProps = {
  customer: CustomerResponseFragment;
  hasActivePaidSubscriptions: boolean;
  onClose: () => void;
  isOpen: boolean;
};

export function DetachPaymentMethodDialog({
  customer,
  hasActivePaidSubscriptions,
  onClose,
  isOpen,
}: DetachPaymentMethodDialogProps) {
  const dispatch = useAppDispatch();
  const handleDeleteDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete) {
      await dispatch(detachCustomerPaymentMethodAction({ customerId: customer.customerId }));
    }
    onClose();
  };

  return (
    <ConfirmationDialog
      width={500}
      title={t('customers.paymentMethod.detachPaymentMethod')}
      open={isOpen}
      handleClose={handleDeleteDialogClose}
      content={
        <GridFlex.Column gap={4}>
          {hasActivePaidSubscriptions && (
            <Text.B2 color="primary">
              {t('customers.paymentMethod.detachPaymentMethodWarning.paidSubscriptions')}
            </Text.B2>
          )}
          <GridFlex.RowCenter>
            <Text.B2 color="primary">{t('customers.paymentMethod.detachPaymentMethodWarning.main')}</Text.B2>
          </GridFlex.RowCenter>
        </GridFlex.Column>
      }
      confirmationButtonText={t('sharedComponents.remove')}
    />
  );
}
