import { t } from 'i18next';
import { useMediaQuery, Text, PageCard, GridFlex, Grid, ArticleCard, Carousel, CarouselCard } from '@stigg-components';
import { ItamarTheme } from '@stigg-theme';
import { useTheme } from '@mui/material/styles';
import { useAppDispatch } from '../../../redux/store';
import { hideGettingStartedPageAction } from '../../accounts/accountsSlice';
import { useConfirmationDialog } from '../../common';
import { useNavigation } from '../../navigation/useNavigation';
import { TipsCard } from './tipsCard';
import { HideGettingStartedPage } from './hideGettingStartedPage';
import { getArticleCardsData } from './getArticleCardsData';
import { useGettingStartedCarouselCards } from './useGettingStartedCarouselCards';
import { useCurrentMember } from '../../auth/hooks/useCurrentMember';
import { useInviteMembersDrawer } from '../../accounts/components/InviteMembersDrawer/useInviteMembersDrawer';

const CAROUSEL_AUTO_SLIDE_INTERVAL = 6000;

export function GettingStarted() {
  const theme = useTheme();
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const currentMember = useCurrentMember();
  const isScreenXL = useMediaQuery<ItamarTheme>((theme) => theme.breakpoints.up('xl'));

  const [HideGettingStartedDialog, setHideGettingStartedDialogOpen, hideGettingStartedProps] = useConfirmationDialog({
    title: t('gettingStarted.hideConfirmation.title'),
    content: (
      <>
        <Text.B2>{t('gettingStarted.hideConfirmation.content1')}</Text.B2>
        <Text.B2>{t('gettingStarted.hideConfirmation.content2')}</Text.B2>
      </>
    ),
    confirmButtonText: t('gettingStarted.hideConfirmation.confirmText'),
    confirmButtonColor: 'primary',
    handleConfirm: async () => {
      if (currentMember) {
        await dispatch(hideGettingStartedPageAction(currentMember.id));
        navigation.navigateTo('/products');
      }
    },
  });

  const { inviteMembersDrawer, setIsInviteDrawerOpen } = useInviteMembersDrawer({
    shouldNavigateToAccountsMembersPageOnSubmit: true,
  });
  const { carouselProps, carouselCardsProps } = useGettingStartedCarouselCards(
    theme.isLightTheme,
    setIsInviteDrawerOpen,
  );
  const articleCardsData = getArticleCardsData(theme.isLightTheme);

  const carouselElements = carouselCardsProps.map((cardProps) => <CarouselCard key={cardProps.title} {...cardProps} />);
  const articleElements = articleCardsData.map((article) => {
    return (
      <Grid item key={article.id}>
        <ArticleCard {...article} />
      </Grid>
    );
  });

  return (
    <>
      <GridFlex.Column container marginX="auto" sx={{ maxWidth: '1200px !important' }}>
        <GridFlex.RowCenter container alignItems="stretch" spacing={4}>
          <GridFlex.Column item flex={3} minWidth={800}>
            <PageCard
              cardProps={{ padding: 0 }}
              cardContentProps={{ sx: { padding: 0, ':last-child': { padding: 0 } } }}>
              <Carousel {...carouselProps} withPadding={false} autoPlay interval={CAROUSEL_AUTO_SLIDE_INTERVAL}>
                {carouselElements}
              </Carousel>
            </PageCard>
          </GridFlex.Column>

          <Grid item flex={1} minWidth={207}>
            <TipsCard setIsInviteDrawerOpen={setIsInviteDrawerOpen} />
          </Grid>
        </GridFlex.RowCenter>

        <GridFlex.Column item my={10}>
          <Text.H3>{t('gettingStarted.articlesTitle')}</Text.H3>
        </GridFlex.Column>

        <GridFlex.Column item>
          <Grid container justifyContent="flex-start" spacing={4} wrap={isScreenXL ? 'nowrap' : 'wrap'}>
            {articleElements}
          </Grid>
        </GridFlex.Column>

        {!currentMember?.hideGettingStartedPage && (
          <Grid item justifyContent="flex-end" my={10}>
            <div>
              <HideGettingStartedPage onHide={() => setHideGettingStartedDialogOpen(true)} />
            </div>
          </Grid>
        )}
        <HideGettingStartedDialog {...hideGettingStartedProps} />
        {inviteMembersDrawer}
      </GridFlex.Column>
    </>
  );
}
