import { Subscription } from '@stigg/js-client-sdk';
import { useEffect, useMemo, useState } from 'react';
import { useStiggContext } from '../../hooks/useStiggContext';

function useQueryParams(paramName?: string) {
  return useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return paramName ? urlSearchParams.get(paramName) : urlSearchParams;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramName, window.location.search]);
}

export enum ProvisionStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export const useWaitForCheckoutCompleted = ({
  onProvisionStart,
  onProvisionSucceeded,
  onProvisionFailed,
}: {
  onProvisionStart?: () => void;
  onProvisionSucceeded?: (subscription?: Subscription | null) => void;
  onProvisionFailed?: (err?: any) => void;
} = {}) => {
  const { stigg } = useStiggContext();
  const [provisionStatus, setProvisionStatus] = useState(ProvisionStatus.IN_PROGRESS);
  const [isAwaitingCheckout, setIsAwaitingCheckout] = useState(false);
  const checkoutCompleted = useQueryParams('checkoutCompleted') as string;

  useEffect(() => {
    const waitForCheckoutToComplete = async () => {
      if (!checkoutCompleted || checkoutCompleted.toLowerCase() === 'false' || isAwaitingCheckout) {
        return;
      }

      setIsAwaitingCheckout(true);
      if (onProvisionStart) {
        onProvisionStart();
      }
      try {
        const subscription = await stigg.waitForCheckoutCompleted();
        setProvisionStatus(ProvisionStatus.SUCCEEDED);
        if (onProvisionSucceeded) {
          onProvisionSucceeded(subscription);
        }
      } catch (err) {
        console.error('Failed to wait for checkout to complete', err);
        setProvisionStatus(ProvisionStatus.FAILED);
        if (onProvisionFailed) {
          onProvisionFailed(err);
        }
      } finally {
        setIsAwaitingCheckout(false);
      }
    };

    void waitForCheckoutToComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stigg, checkoutCompleted]);

  return { isAwaitingCheckout, provisionStatus };
};
