import { ReactNode } from 'react';
import { min, isNumber } from 'lodash';
import { LongText, Box, LinearProgress, GridFlex, LinearProgressProps } from '@stigg-components';
import { EntitlementDataFragment, FeatureDataFragment } from '@stigg-types/apiTypes';
import isNil from 'lodash/isNil';
import { t } from 'i18next';
import { EntitlementResetDate } from '../../../../entitlements/components/EntitlementResetDate';
import { numberFormatter } from '../../../../../utils/numberUtils';

const usagePercentageWarningThreshold = 70;

function EntitlementUsage({
  currentUsage,
  entitlement,
  linearProgressProps,
}: {
  currentUsage: ReactNode;
  entitlement: EntitlementDataFragment;
  linearProgressProps: LinearProgressProps;
}) {
  return (
    <Box>
      <LinearProgress variant="determinate" {...linearProgressProps} />
      <GridFlex.RowSpaceBetween $fullWidth>
        <LongText>{currentUsage}</LongText>
        {entitlement.resetPeriod && (
          <EntitlementResetDate resetDate={entitlement.usagePeriodEnd} resetPeriod={entitlement.resetPeriod} />
        )}
      </GridFlex.RowSpaceBetween>
    </Box>
  );
}

function formatUsage(usage: number, usageLimit?: number | null, feature?: FeatureDataFragment | null) {
  const currentUsageFormatted = numberFormatter(usage);
  const usageLimitFormatted = isNil(usageLimit) ? t('entitlements.unlimitedUsage') : numberFormatter(usageLimit);
  const units = usageLimit === 1 ? feature?.featureUnits : feature?.featureUnitsPlural;

  return `${currentUsageFormatted} / ${usageLimitFormatted} ${units}`;
}

export function EntitlementUsageBox({
  entitlement,
  overrideUsage,
}: {
  entitlement: EntitlementDataFragment;
  overrideUsage?: number;
}) {
  const currentUsage = isNumber(overrideUsage) ? overrideUsage : entitlement.currentUsage || 0;
  const currentUsageFormatted = formatUsage(currentUsage, entitlement.usageLimit, entitlement.feature);

  if (entitlement.hasUnlimitedUsage) {
    return (
      <EntitlementUsage
        currentUsage={currentUsageFormatted}
        entitlement={entitlement}
        linearProgressProps={{
          value: 0,
          color: 'primary',
          sx: { mb: 2 },
        }}
      />
    );
  }

  const currentLimit = entitlement.usageLimit || 0;
  const currentUsagePercentage = currentUsage === 0 ? 0 : min([(currentUsage / currentLimit) * 100, 100]) || 0;

  let barColor: 'primary' | 'warning' | 'error';
  if (currentUsagePercentage < usagePercentageWarningThreshold) {
    barColor = 'primary';
  } else if (currentUsagePercentage < 100) {
    barColor = 'warning';
  } else {
    barColor = 'error';
  }

  return (
    <EntitlementUsage
      currentUsage={currentUsageFormatted}
      entitlement={entitlement}
      linearProgressProps={{
        value: currentUsagePercentage,
        color: barColor,
        sx: { mb: 2 },
      }}
    />
  );
}
