import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Auth0ApplicationType,
  SubscriptionStartSetup,
  Auth0CredentialsInput,
  Auth0CredentialsFragment,
  PricingType,
  PackageStatus,
  FeatureType,
  MeterType,
  FeatureStatus,
  AggregationFunction,
  ConditionOperation,
} from '@stigg-types/apiTypes';
import { SubscriptionStartSetupFormFields } from '../../../products/components/productPage/customerJourney/SubscriptionStartSetupConfiguration';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { createFeatureAction } from '../../../features/featuresSlice';

export const MAU_FEATURE_NAME = 'Active Users';
export const MAU_FEATURE_ID = 'feature-active-users';

const validationSchema = () =>
  Yup.object().shape({
    clientDomain: Yup.string().required(t('fieldValidationMessages.required')),
    clientId: Yup.string().required(t('fieldValidationMessages.required')),
    clientSecret: Yup.string().required(t('fieldValidationMessages.required')),
    applicationId: Yup.string().required(t('fieldValidationMessages.required')),
    individual: Yup.object().when('applicationType', (applicationType: Auth0ApplicationType) => {
      if ([Auth0ApplicationType.Individual, Auth0ApplicationType.Both].includes(applicationType)) {
        return Yup.object()
          .shape({
            subscriptionStartSetup: Yup.mixed<SubscriptionStartSetup>()
              .oneOf(Object.values(SubscriptionStartSetup))
              .required(t('fieldValidationMessages.required')),
            subscriptionStartTrialPlanId: Yup.string().when('subscriptionStartSetup', {
              is: SubscriptionStartSetup.TrialPeriod,
              then: Yup.string().required(t('fieldValidationMessages.required')),
              otherwise: Yup.string().notRequired(),
            }),
            subscriptionStartFreePlanId: Yup.string().when('subscriptionStartSetup', {
              is: SubscriptionStartSetup.FreePlan,
              then: Yup.string().required(t('fieldValidationMessages.required')),
              otherwise: Yup.string().notRequired(),
            }),
          })
          .required(t('fieldValidationMessages.required'));
      }

      return Yup.object().notRequired();
    }),
    organization: Yup.object().when('applicationType', (applicationType: Auth0ApplicationType) => {
      if ([Auth0ApplicationType.Organization, Auth0ApplicationType.Both].includes(applicationType)) {
        return Yup.object()
          .shape({
            subscriptionStartSetup: Yup.mixed<SubscriptionStartSetup>()
              .oneOf(Object.values(SubscriptionStartSetup))
              .required(t('fieldValidationMessages.required')),
            subscriptionStartTrialPlanId: Yup.string().when('subscriptionStartSetup', {
              is: SubscriptionStartSetup.TrialPeriod,
              then: Yup.string().required(t('fieldValidationMessages.required')),
              otherwise: Yup.string().notRequired(),
            }),
            subscriptionStartFreePlanId: Yup.string().when('subscriptionStartSetup', {
              is: SubscriptionStartSetup.FreePlan,
              then: Yup.string().required(t('fieldValidationMessages.required')),
              otherwise: Yup.string().notRequired(),
            }),
          })
          .required(t('fieldValidationMessages.required'));
      }

      return Yup.object().notRequired();
    }),
  });

export type Auth0IntegrationFormFields = Omit<
  Auth0CredentialsInput,
  'individualInitialPlanId' | 'organizationInitialPlanId'
> & {
  individual: SubscriptionStartSetupFormFields;
  organization: SubscriptionStartSetupFormFields;
  applicationName?: string;
};

export function useAuth0Form(
  credentials: Auth0CredentialsFragment | undefined,
  onSubmit: (values: Auth0IntegrationFormFields) => Promise<void>,
) {
  const dispatch = useAppDispatch();
  const products = useSelector((root: RootState) => root.productReducer.products);
  const allPlans = useMemo(() => products.flatMap((product) => product.plans), [products]);
  const initialValues: Auth0IntegrationFormFields = useMemo(() => {
    const allProductPlans = products.flatMap((product) => product.plans);

    const subscriptionStartTrialPlan = allProductPlans
      .filter((plan) => plan.pricingType !== PricingType.Free)
      .filter((plan) => plan.status !== PackageStatus.Draft)
      .find((plan) => plan.defaultTrialConfig);
    const subscriptionStartFreePlan = allProductPlans
      .filter((plan) => plan.pricingType === PricingType.Free)
      .find((plan) => plan.status !== PackageStatus.Draft);

    const individualStartPlan = allProductPlans.find((plan) => plan.refId === credentials?.individualInitialPlanId);
    const organizationStartPlan = allProductPlans.find((plan) => plan.refId === credentials?.organizationInitialPlanId);

    return {
      applicationId: credentials?.applicationId || '',
      applicationType: credentials?.applicationType || Auth0ApplicationType.Individual,
      applicationName: credentials?.applicationName || '',
      clientDomain: credentials?.clientDomain || '',
      clientId: credentials?.clientId || '',
      clientSecret: credentials?.clientSecret || '',
      individual: {
        subscriptionStartSetup: credentials?.individualSubscriptionStartSetup || undefined,
        defaultTrialConfig: individualStartPlan?.defaultTrialConfig || undefined,
        subscriptionStartTrialPlanId:
          credentials?.individualSubscriptionStartSetup === SubscriptionStartSetup.TrialPeriod
            ? individualStartPlan?.id
            : subscriptionStartTrialPlan?.id,
        subscriptionStartFreePlanId:
          credentials?.individualSubscriptionStartSetup === SubscriptionStartSetup.FreePlan
            ? individualStartPlan?.id
            : subscriptionStartFreePlan?.id,
      },
      organization: {
        subscriptionStartSetup: credentials?.organizationSubscriptionStartSetup || undefined,
        defaultTrialConfig: organizationStartPlan?.defaultTrialConfig || undefined,
        subscriptionStartTrialPlanId:
          credentials?.organizationSubscriptionStartSetup === SubscriptionStartSetup.TrialPeriod
            ? organizationStartPlan?.id
            : subscriptionStartTrialPlan?.id,
        subscriptionStartFreePlanId:
          credentials?.organizationSubscriptionStartSetup === SubscriptionStartSetup.FreePlan
            ? organizationStartPlan?.id
            : subscriptionStartFreePlan?.id,
      },
    };
  }, [credentials, products]);

  const formRenderProps = useFormik<Auth0IntegrationFormFields>({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit,
  });
  const { errors, values } = formRenderProps;
  const isClientDetailsValid = !!errors.clientDomain || !!errors.clientId || !!errors.clientSecret;
  const individualStartPlan = values?.individual.subscriptionStartSetup
    ? allPlans.find(
        (plan) =>
          plan.id ===
          (values?.individual.subscriptionStartSetup === SubscriptionStartSetup.FreePlan
            ? values?.individual.subscriptionStartFreePlanId
            : values?.individual.subscriptionStartTrialPlanId),
      )
    : undefined;
  const individualShowMissingMAUEntitlement =
    individualStartPlan &&
    [...(individualStartPlan.entitlements || []), ...(individualStartPlan.inheritedEntitlements || [])].every(
      (x) => x.feature.refId !== MAU_FEATURE_ID,
    );
  const organizationStartPlan = values?.organization.subscriptionStartSetup
    ? allPlans.find(
        (plan) =>
          plan.id ===
          (values?.organization.subscriptionStartSetup === SubscriptionStartSetup.FreePlan
            ? values?.organization.subscriptionStartFreePlanId
            : values?.organization.subscriptionStartTrialPlanId),
      )
    : undefined;
  const organizationShowMissingMAUEntitlement =
    organizationStartPlan &&
    [...(organizationStartPlan.entitlements || []), ...(organizationStartPlan.inheritedEntitlements || [])].every(
      (x) => x.feature.refId !== MAU_FEATURE_ID,
    );

  const isInitialCustomerAccessStepSelectionValid = () => {
    if ([Auth0ApplicationType.Individual, Auth0ApplicationType.Both].includes(values.applicationType)) {
      return !individualShowMissingMAUEntitlement;
    }
    if ([Auth0ApplicationType.Organization, Auth0ApplicationType.Both].includes(values.applicationType)) {
      return !organizationShowMissingMAUEntitlement;
    }

    return false;
  };

  // We are creating the MAU feature in advance before creating the integration
  // so the user can add the feature as entitlement to the initial plan otherwise it won't be possible
  // to complete the integration setup
  const createMAUFeature = async () => {
    try {
      await dispatch(
        createFeatureAction({
          featureData: {
            displayName: MAU_FEATURE_NAME,
            refId: MAU_FEATURE_ID,
            featureType: FeatureType.Number,
            meterType: MeterType.Incremental,
            featureStatus: FeatureStatus.New,
            featureUnits: 'active user',
            featureUnitsPlural: 'active users',
            meter: {
              aggregation: {
                function: AggregationFunction.Unique,
                field: 'user_id',
              },
              filters: [
                {
                  conditions: [
                    {
                      field: 'eventName',
                      operation: ConditionOperation.Equals,
                      value: 'user-login',
                    },
                  ],
                },
              ],
            },
          },
          hideErrorMessages: true,
          withSuccessMessage: false,
          navigateToEntity: false,
        }),
      ).unwrap();
    } catch (err) {
      // Silent error handling
    }
  };

  return {
    ...formRenderProps,
    isClientDetailsValid,
    individualShowMissingMAUEntitlement,
    organizationShowMissingMAUEntitlement,
    isInitialCustomerAccessStepSelectionValid,
    individualStartPlan,
    organizationStartPlan,
    createMAUFeature,
  };
}
