import React, { Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AccountPermissionActions } from '@stigg-permissions';
import { AccountPermissionsProtected, Button, GridFlex, Icon, PageCard, Text } from '@stigg-components';
import { useModal } from '@stigg-common';
import { FeatureFlags } from '@stigg-types/featureFlags';
import Loader from '../../../../components/Loader';
import { RootState } from '../../../../redux/store';
import AccountEnvironmentsTable from './AccountEnvironmentsTable';
import AccountEnvironmentsEmptyState from './AccountEnvironmentsEmptyState';
import { AddEnvironmentDrawer } from './EnvironmentDetailsDrawer';

const GenerateSandbox = React.lazy(() => import('../sandboxGenerator/GenerateSandbox'));

export default function AccountEnvironments() {
  const { allowGeneratingSandbox } = useFlags<FeatureFlags>();
  const [isAddEnvironmentOpen, setIsAddEnvironmentOpen] = useState(false);

  const [GenerateDemoModal, setIsGenerateDemoOpen] = useModal({ title: t('accounts.generateSandboxEnvironment') });
  const environments = useSelector((state: RootState) => state.accountReducer.environments);

  const isLoadingEnvironments = useSelector((state: RootState) => state.accountReducer.isLoadingEnvironments);

  const onCloseAddDialog = () => {
    setIsAddEnvironmentOpen(false);
  };

  return (
    <PageCard>
      <GridFlex.Row alignItems="center" justifyContent="space-between">
        <Text.H3>{t('accounts.environmentsTitle')}</Text.H3>
        <GridFlex.Row>
          {allowGeneratingSandbox && (
            <Button
              data-testid="generate-sandbox-button"
              $outlined
              color="secondary"
              onClick={() => setIsGenerateDemoOpen(true)}
              startIcon={<Icon icon="Add" />}
              sx={{ marginRight: 2 }}>
              {t('accounts.generateSandbox')}
            </Button>
          )}
          <AccountPermissionsProtected
            permissions={[
              AccountPermissionActions.CreateProductionEnvironment,
              AccountPermissionActions.CreateNonProductionEnvironment,
            ]}>
            <Button
              $outlined
              color="primary"
              onClick={() => setIsAddEnvironmentOpen(true)}
              startIcon={<Icon icon="Add" />}>
              {t('accounts.add')}
            </Button>
          </AccountPermissionsProtected>
        </GridFlex.Row>
      </GridFlex.Row>
      <Text.Sub2 mt={3} mb={10}>
        {t('accounts.setApiKey')}
      </Text.Sub2>

      {isLoadingEnvironments ? (
        <Loader />
      ) : isEmpty(environments) ? (
        <AccountEnvironmentsEmptyState onAddEnvironmentClick={() => setIsAddEnvironmentOpen(true)} />
      ) : (
        <AccountEnvironmentsTable />
      )}

      <AddEnvironmentDrawer isOpen={isAddEnvironmentOpen} onClose={onCloseAddDialog} />

      {allowGeneratingSandbox && (
        <GenerateDemoModal>
          <Suspense fallback={<Loader />}>
            <GenerateSandbox environments={environments} />
          </Suspense>
        </GenerateDemoModal>
      )}
    </PageCard>
  );
}
