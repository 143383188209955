import { gql } from '@apollo/client';
import {
  CouponFragment,
  CreateCouponInput,
  CreateOneCouponMutation,
  CreateOneCouponMutationVariables,
  ErrorCode,
} from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import { AppDispatch, RootState } from '../../../redux/store';
import { apolloClient } from '../../../ApolloClient';
import { COUPON_FRAGMENT } from '../queries/fetchCoupons';
import { appRoutes } from '../../navigation/useNavigation';
import { navigateTo } from '../../navigation/actions';

const CREATE_COUPON = gql`
  mutation CreateOneCouponMutation($input: CreateCouponInput!) {
    createOneCoupon(input: $input) {
      ...CouponFragment
    }
  }
  ${COUPON_FRAGMENT}
`;

async function createCoupon(
  couponData: Omit<CreateCouponInput, 'environmentId'>,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<CouponFragment> | undefined | null> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const coupon: CreateCouponInput = {
        ...couponData,
        environmentId: accountReducer.currentEnvironmentId,
      };

      const response = await apolloClient.mutate<CreateOneCouponMutation, CreateOneCouponMutationVariables>({
        mutation: CREATE_COUPON,
        variables: { input: coupon },
      });

      const newCoupon = response.data?.createOneCoupon;
      if (newCoupon) {
        dispatch(navigateTo(appRoutes.couponPage(newCoupon.refId)));
      }

      return newCoupon;
    },
    {
      successMessage: t('coupons.api.successCreate'),
      failureMessageHandler: () => t('coupons.api.failCreate'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('coupons.api.failDupCouponId', {
          couponId: couponData.refId,
        }),
      },
    },
    dispatch,
  );
}

export { createCoupon };
