import React from 'react';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';

import { ReactComponent as PayWallSvgLight } from '@assets/images/widgets/paywall.light.svg';
import { ReactComponent as PayWallSvgDark } from '@assets/images/widgets/paywall.dark.svg';
import { ReactComponent as CustomerPortalSvgLight } from '@assets/images/widgets/customerPortal.light.svg';
import { ReactComponent as CustomerPortalSvgDark } from '@assets/images/widgets/customerPortal.dark.svg';
import { ReactComponent as CheckoutLight } from '@assets/images/widgets/checkout.light.svg';
import { ReactComponent as CheckoutDark } from '@assets/images/widgets/checkout.dark.svg';
import { ReactComponent as FeatureComparisonLight } from '@assets/images/widgets/featureComparison.light.svg';
import { ReactComponent as FeatureComparisonDark } from '@assets/images/widgets/featureComparison.dark.svg';
import { ReactComponent as PowerUpLight } from '@assets/images/widgets/powerUp.light.svg';
import { ReactComponent as PowerUpDark } from '@assets/images/widgets/powerUp.dark.svg';
import { ReactComponent as TrialBannerLight } from '@assets/images/widgets/trialBanner.light.svg';
import { ReactComponent as TrialBannerDark } from '@assets/images/widgets/trialBanner.dark.svg';
import { ReactComponent as UsageEstimatorLight } from '@assets/images/widgets/usageEstimator.light.svg';
import { ReactComponent as UsageEstimatorDark } from '@assets/images/widgets/usageEstimator.dark.svg';

type UseWidgetsDataResult = WidgetProps[];

export enum WidgetIdentifier {
  CustomerPortal = 'CUSTOMERPORTAL',
  Paywall = 'PAYWALL',
  Checkout = 'CHECKOUT',
  MspPortal = 'MSPPORTAL',
  FeatureComparison = 'FEATURECOMPARISON',
  UsagePricingEstimator = 'USAGEPRICINGESTIMATOR',
  TrialBanner = 'TRIALBANNER',
  PowerUpBadge = 'POWERUPBADGE',
}

export type WidgetProps = {
  name: string;
  widgetIdentifier: WidgetIdentifier;
  description: string;
  svg: React.ReactNode;
  isComingSoon?: boolean;
  docsUrl: string;
  enabled?: boolean;
};
export function useWidgetsData(): UseWidgetsDataResult {
  const theme = useTheme();
  const {
    widgetLibraryPaywall: widgetLibraryPaywallEnabled,
    widgetLibraryCustomerPortal: widgetLibraryCustomerPortalEnabled,
    widgetLibraryCheckout: widgetLibraryCheckoutEnabled,
    mspPortalWidget: mspPortalWidgetEnabled,
  } = useFlags<FeatureFlags>();

  const widgets = [
    {
      name: t('widgets.planPicker'),
      description: t('widgets.paywallDescription'),
      widgetIdentifier: WidgetIdentifier.Paywall,
      docsUrl: 'https://docs.stigg.io/docs/pricing-table',
      svg: theme.isLightTheme ? <PayWallSvgLight /> : <PayWallSvgDark />,
      enabled: widgetLibraryPaywallEnabled,
    },
    {
      name: t('widgets.customerPortal'),
      description: t('widgets.customerPortalDescription'),
      docsUrl: 'https://docs.stigg.io/docs/customer-portal',
      widgetIdentifier: WidgetIdentifier.CustomerPortal,
      svg: theme.isLightTheme ? <CustomerPortalSvgLight /> : <CustomerPortalSvgDark />,
      enabled: widgetLibraryCustomerPortalEnabled,
    },
    {
      name: t('widgets.checkout.title'),
      description: t('widgets.checkout.description'),
      isComingSoon: !widgetLibraryCheckoutEnabled,
      docsUrl: 'https://docs.stigg.io/docs/checkout',
      widgetIdentifier: WidgetIdentifier.Checkout,
      svg: theme.isLightTheme ? <CheckoutLight /> : <CheckoutDark />,
      enabled: widgetLibraryCheckoutEnabled,
    },
    {
      name: t('widgets.planComparisonTable'),
      description: t('widgets.comparisonTableDescription'),
      isComingSoon: true,
      widgetIdentifier: WidgetIdentifier.FeatureComparison,
      svg: theme.isLightTheme ? <FeatureComparisonLight /> : <FeatureComparisonDark />,
      docsUrl: 'https://docs.stigg.io/',
    },
    {
      name: t('widgets.usagePriceEstimator'),
      description: t('widgets.usagePricingEstimatorDescription'),
      isComingSoon: true,
      widgetIdentifier: WidgetIdentifier.UsagePricingEstimator,
      svg: theme.isLightTheme ? <UsageEstimatorLight /> : <UsageEstimatorDark />,
      docsUrl: 'https://docs.stigg.io/',
    },
    {
      name: t('widgets.trialBanner'),
      description: t('widgets.trialBannerDescription'),
      isComingSoon: true,
      widgetIdentifier: WidgetIdentifier.TrialBanner,
      svg: theme.isLightTheme ? <TrialBannerLight /> : <TrialBannerDark />,
      docsUrl: 'https://docs.stigg.io/',
    },
    {
      name: t('widgets.powerupBadge'),
      description: t('widgets.powerUpDescription'),
      isComingSoon: true,
      widgetIdentifier: WidgetIdentifier.PowerUpBadge,
      svg: theme.isLightTheme ? <PowerUpLight /> : <PowerUpDark />,
      docsUrl: 'https://docs.stigg.io/',
    },
  ];

  if (mspPortalWidgetEnabled) {
    widgets.splice(3, 0, {
      name: t('widgets.mspPortal.title'),
      description: t('widgets.mspPortal.description'),
      docsUrl: 'https://docs.stigg.io/docs/checkout',
      widgetIdentifier: WidgetIdentifier.MspPortal,
      svg: theme.isLightTheme ? <CheckoutLight /> : <CheckoutDark />,
      enabled: true,
    });
  }

  return widgets;
}
