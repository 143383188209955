import { useEffect, useState } from 'react';

export type FetchResult<T> = {
  isLoaded: boolean;
  error: Error | null;
  data: T | null;
};

export function useFetch<T>({ func }: { func: () => Promise<T> }): FetchResult<T> {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(true);
      try {
        const result = await func();
        setData(result);
      } catch (error) {
        setError(error as Error);
      } finally {
        setIsLoaded(false);
      }
    };

    void fetchData();
  }, [func]);

  return { isLoaded, error, data };
}
