import { t } from 'i18next';
import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import { GridFlex, Switch, Text } from '@stigg-components';
import { has } from 'lodash';
import { WidgetConfig } from '../../hooks/useWidgetConfiguration';

function WidgetContent({ widgetConfig }: { widgetConfig: WidgetConfig }) {
  const { onThemeChanged } = widgetConfig;
  const { content } = widgetConfig.configuration as any;
  const allowWidgetsWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);

  const onChange = (key: string, value: any) => {
    onThemeChanged({
      content: {
        [key]: value,
      },
    });
  };

  return (
    <GridFlex.Column gap={2}>
      {has(content, 'collectPhoneNumber') && (
        <>
          <GridFlex.RowSpaceBetween>
            <Text.B2>{t('widgets.contentSection.collectPhoneNumber')}</Text.B2>
            <Switch
              disabled={!allowWidgetsWrite}
              checked={content.collectPhoneNumber}
              onChange={(e) => onChange('collectPhoneNumber', e.target.checked)}
            />
          </GridFlex.RowSpaceBetween>
          <GridFlex.RowSpaceBetween>
            <Text.Caption color="secondary">{t('widgets.contentSection.collectPhoneNumberCaption')}</Text.Caption>
          </GridFlex.RowSpaceBetween>
        </>
      )}
    </GridFlex.Column>
  );
}

export default WidgetContent;
