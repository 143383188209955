import { useState } from 'react';
import { useNavigation } from '../../../navigation/useNavigation';
import { SettingsPageTabs } from '../../types/settingsPageTabs';
import { InviteMembersDrawer } from './InviteMembersDrawer';

type UseInviteMembersDrawerResult = {
  shouldNavigateToAccountsMembersPageOnSubmit?: boolean;
};

export function useInviteMembersDrawer({
  shouldNavigateToAccountsMembersPageOnSubmit,
}: UseInviteMembersDrawerResult = {}) {
  const [isInviteDrawerOpen, setIsInviteDrawerOpen] = useState(false);
  const { navigateTo, appRoutes } = useNavigation();

  const inviteMembersDrawer = (
    <InviteMembersDrawer
      isOpen={isInviteDrawerOpen}
      onCancel={() => setIsInviteDrawerOpen(false)}
      onSubmit={() => {
        setIsInviteDrawerOpen(false);

        if (shouldNavigateToAccountsMembersPageOnSubmit) {
          navigateTo(appRoutes.settingsPage({ selectedTab: SettingsPageTabs.Members }), {
            isGlobal: true,
          });
        }
      }}
    />
  );

  return { inviteMembersDrawer, isInviteDrawerOpen, setIsInviteDrawerOpen };
}
