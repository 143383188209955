import { Coupon, Currency } from '@stigg-types/apiTypes';
import { GridFlex, LocalDate, LongText, Text, HeadCell } from '@stigg-components';
import { t } from 'i18next';
import head from 'lodash/head';
import { CouponStatusChip } from './CouponStatusChip';
import { formatCouponValue } from './utils';
import { CouponOptionsDropdown } from './couponPage/couponDetails/CouponOptionsDropdown';

export const headCells = (
  onEditClick: (coupon: Coupon) => void,
  onDeleteClick: (coupon: Coupon) => void,
  disableOptions?: boolean,
): Array<HeadCell<Coupon, any>> => [
  {
    id: 'title',
    alignment: 'left',
    label: t('coupons.couponLabel'),
    render: (coupon) => (
      <GridFlex.Column container>
        <Text.B2>{coupon.name}</Text.B2>
        <LongText>{coupon.refId}</LongText>
      </GridFlex.Column>
    ),
  },
  {
    id: 'discount',
    alignment: 'left',
    label: t('coupons.discountDetailsLabel'),
    render: (coupon) => {
      const amountOff = head(coupon.amountsOff) || { amount: coupon.discountValue, currency: Currency.Usd };
      const formattedCoupon = formatCouponValue(
        amountOff.amount,
        coupon.type,
        amountOff.currency,
        coupon.durationInMonths,
        {
          showNegativeSign: false,
        },
      );

      return (
        <>
          <Text.B2 color="primary">{formattedCoupon}</Text.B2>

          {coupon.amountsOff && coupon.amountsOff?.length > 1 ? (
            <Text.B2 color="secondary">
              {t('coupons.additionalCurrencies', { count: coupon.amountsOff.length - 1 })}
            </Text.B2>
          ) : null}
        </>
      );
    },
  },
  {
    id: 'status',
    alignment: 'left',
    label: t('coupons.statusLabel'),
    render: (coupon) => <CouponStatusChip status={coupon.status} />,
  },
  {
    id: 'lastUpdated',
    alignment: 'left',
    label: t('coupons.lastUpdated'),
    render: (entity) => <LocalDate date={entity.updatedAt} formatOptions={{ withTime: true }} />,
  },
  {
    id: 'options',
    alignment: 'right',
    width: 56,
    maxWidth: 44,
    label: '',
    disableClick: true,
    visible: !disableOptions,
    render: (coupon: Coupon) => (
      <CouponOptionsDropdown onDeleteClick={() => onDeleteClick(coupon)} onEditClick={() => onEditClick(coupon)} />
    ),
  },
];
