import { t } from 'i18next';
import { GridFlex, Text, TextFieldProps, LongText } from '@stigg-components';

type FeatureUnitTransformationProps = {
  renderTextField: (id: string, label?: string, props?: Partial<TextFieldProps>) => JSX.Element;
  transformedUnits?: string | null;
  featureUnits?: string | null;
  featureUnitsPlural?: string | null;
  divideBy?: number | null;
  isViewMode?: boolean;
};

export function FeatureUnitTransformation({
  renderTextField,
  transformedUnits,
  featureUnits,
  featureUnitsPlural,
  divideBy,
  isViewMode,
}: FeatureUnitTransformationProps) {
  return (
    <GridFlex.Column $fullWidth>
      <Text.B2 color="secondary">{t('features.unitCalculation')}</Text.B2>
      <GridFlex.RowCenter alignItems="flex-start" $fullWidth justifyContent="space-between" columnGap={1}>
        {renderTextField('unitCalculation', undefined, {
          value: 1,
          disabled: true,
          InputProps: {
            endAdornment: (
              <LongText variant="body2" color="disabled" wordBreak sx={{ textAlign: 'right' }}>
                {transformedUnits}
              </LongText>
            ),
          },
        })}
        <GridFlex.Column $fullHeight>
          <Text.B2 mt={2}>=</Text.B2>
        </GridFlex.Column>
        {renderTextField('divideBy', undefined, {
          placeholder: 'Enter value',
          type: 'number',
          disabled: isViewMode,
          InputProps: {
            endAdornment: (
              <LongText variant="body2" color="disabled" wordBreak sx={{ textAlign: 'right' }}>
                {divideBy && divideBy > 1 ? featureUnitsPlural : featureUnits}
              </LongText>
            ),
          },
        })}
      </GridFlex.RowCenter>
    </GridFlex.Column>
  );
}
