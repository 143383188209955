import { AsyncThunk } from '@reduxjs/toolkit';
import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import { Divider, GridFlex, PageHeader, Grid } from '@stigg-components';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AddonListFragment,
  AddonsFragment,
  PlansFragment,
  PlanListFragment,
  PlanFragment,
  AddonFragment,
} from '@stigg-types/apiTypes';
import Search from '../../../../components/Search';
import createPageChangeFunc from '../../../../components/table/gqlTableHelper';
import Table, { HeadCell } from '../../../../components/table/Table';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { headCells } from './headCells';
import { usePaywallVisibilityControlFeature } from './usePaywallVisibilityControlFeature';

export type PackageFragment = Omit<PlanFragment, 'basePlan' | 'isParent'> | AddonFragment;
export type PackageListFragment = Omit<PlanListFragment, 'basePlan' | 'isParent'> | AddonListFragment;

function Packages({
  title,
  packages,
  onRowClick,
  isLoading,
  onSearch,
  onNew,
  newButtonText,
  fetchAction,
  additionalCells,
  emptyState,
  searchPlaceholder,
  onDeleteClick,
  onEditClick,
  packageType,
}: {
  packages: PlansFragment | AddonsFragment;
  onRowClick: (aPackage: PackageListFragment) => void;
  isLoading: boolean;
  onSearch: (searchTerm: string) => void;
  onNew: () => void;
  title?: string;
  newButtonText: string;
  fetchAction: AsyncThunk<any, any, any>;
  additionalCells?: Array<HeadCell<PackageListFragment, any>>;
  emptyState: (isSearching: boolean) => React.ReactElement;
  searchPlaceholder: string;
  onDeleteClick: (aPackage: PackageListFragment) => void;
  onEditClick: (aPackage: PackageListFragment) => void;
  packageType: 'Plan' | 'Add-on';
}) {
  const dispatch = useAppDispatch();
  const [pageNum, setPageNum] = useState(0);
  const [search, setSearch] = useState('');

  const allowPackageWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);

  const { pageInfo, totalCount, edges } = packages;
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const pageChangeFunc = createPageChangeFunc(dispatch, pageNum, pageInfo, setPageNum, fetchAction, {
    environmentId: currentEnvironmentId,
    search,
  });

  const showPaywallVisibilityControl = usePaywallVisibilityControlFeature(packageType);

  const onEdit = (entity: PackageListFragment) => {
    onEditClick(entity);
  };

  return (
    <>
      {title && (
        <>
          <PageHeader title={title} buttonTitle={newButtonText} buttonClick={allowPackageWrite ? onNew : undefined} />
          <Divider mt={4} />
        </>
      )}
      <GridFlex.Column container mb={10}>
        <Grid item my={5} maxWidth={400}>
          <Search
            searchOnChange
            variant="outlined"
            handleSearchFunc={(searchStr) => {
              setSearch(searchStr);
              if (searchStr) {
                setPageNum(0);
              }
              onSearch(searchStr);
            }}
            searchedStr={search}
            placeholder={searchPlaceholder}
          />
        </Grid>
        {isEmpty(edges) && !isLoading ? (
          emptyState(!isEmpty(search))
        ) : (
          <Table
            isLoading={isLoading}
            label={title}
            headCells={headCells({
              onDeleteClick,
              onEditClick: onEdit,
              packageType,
              additionalCells,
              showPaywallVisibilityControl,
              isReadOnly: !allowPackageWrite,
            })}
            data={(edges || []).map((plan) => plan.node)}
            totalCount={totalCount}
            pageNum={pageNum}
            pageChangeFunc={pageChangeFunc}
            onRowClick={(aPackage: PackageListFragment) => onRowClick(aPackage)}
          />
        )}
      </GridFlex.Column>
    </>
  );
}

export default Packages;
