import { useTheme } from '@mui/material/styles';
import { Box } from '@stigg-components';
import { Checkout, CustomerPortal, HorizontalAlignment, Paywall, StiggProvider, Theme } from '@stigg/react-sdk';
import dottedBackgroundDark from '@assets/images/widgets/dottedPattern.png';
import { DeepPartial } from '@reduxjs/toolkit';
import { Alignment } from '@stigg-types/apiTypes';
import { useCurrentClientApiKey } from '../../hooks/useCurrentClientApiKey';
import { FrameProvider } from './iframeUtils/FrameProvider';
import { WidgetIdentifier } from '../../useWidgetsData';
import { StyledFrame } from './iframeUtils/StyledFrame';
import { GlobalStyle } from './iframeUtils/GlobalStyle';
import { PaywallThemeConfiguration } from '../../configurations/paywall';
import { CustomerPortalThemeConfiguration } from '../../configurations/customerPortal';
import { CheckoutWidgetConfiguration } from '../../configurations/checkout';
import { CustomerPortalSection } from '../previewOptionsPanel/PreviewOptionsSelectBox';
import config from '../../../../env.config';
import { DEFAULT_COUNTRY_CODE } from '../../../packages/pricing/components/currency/currencyUtils';
import { useCustomerToken } from '../../hooks/useCustomerToken';
import { convertFontWeight } from '../../utils/typography';
import { mockCheckoutState } from '../../mocks/checkout/mockCheckoutState';
import { mockPreviewSubscription } from '../../mocks/checkout/mockCheckoutPreview';
import { SubscriptionForm } from '../../../customers/components/customerPage/customerSubscriptions/subscriptionForm/SubscriptionForm';

const renderWidget = (
  widgetIdentifier: WidgetIdentifier,
  customerPortalHiddenSections: CustomerPortalSection[],
  configuration: PaywallThemeConfiguration | CustomerPortalThemeConfiguration | CheckoutWidgetConfiguration,
  countryCode?: string,
  productId?: string,
  planId?: string,
) => {
  switch (widgetIdentifier) {
    case WidgetIdentifier.Paywall:
      return (
        <Box pt={6}>
          <Paywall
            onPlanSelected={() => {}}
            productId={productId}
            highlightedPlanId="plan-revvenu-essentials"
            billingCountryCode={countryCode === DEFAULT_COUNTRY_CODE ? undefined : countryCode}
          />
        </Box>
      );
    case WidgetIdentifier.CustomerPortal: {
      const customerPortalConfiguration = configuration as CustomerPortalThemeConfiguration;
      return (
        <Box pt={6} pb={84} px={30}>
          <CustomerPortal
            hiddenSections={customerPortalHiddenSections}
            paywallComponent={<Paywall onPlanSelected={() => {}} productId={productId} />}
            theme={{
              iconsColor: customerPortalConfiguration.palette.iconsColor,
              borderColor: customerPortalConfiguration.palette.outlinedBorder,
              backgroundColor: customerPortalConfiguration.palette.customerPortalBackground,
              listItemBackgroundColor: customerPortalConfiguration.palette.customerPortalBackground,
            }}
          />
        </Box>
      );
    }
    case WidgetIdentifier.Checkout: {
      const { palette } = configuration as CheckoutWidgetConfiguration;
      return (
        <Box pt={6} pb={84} px={30}>
          <Checkout
            planId={planId || ''}
            theme={palette}
            onCheckout={() => Promise.resolve({ success: true })}
            onCheckoutCompleted={() => Promise.resolve()}
            onMockCheckoutState={mockCheckoutState}
            onMockCheckoutPreview={mockPreviewSubscription}
          />
        </Box>
      );
    }
    case WidgetIdentifier.MspPortal: {
      return (
        <Box pt={6} pb={84} px={30}>
          <SubscriptionForm
            customer={{
              customerId: 'customer-demo-id',
              hasActiveSubscription: false,
              id: 'customer-demo-id',
              promotionalEntitlements: [],
              updatedAt: new Date().toISOString(),
            }}
            onCancel={() => {}}
            handleSubmit={() => Promise.resolve()}
            subscription={null}
            switchToCreateMode={() => {}}
            setHasUnsavedChanges={() => {}}
            fromWidget
          />
        </Box>
      );
    }
    default:
      return <></>;
  }
};

function mapAlignment(alignment: Alignment): HorizontalAlignment {
  switch (alignment) {
    case Alignment.Left:
      return 'left';
    case Alignment.Center:
      return 'center';
    case Alignment.Right:
      return 'right';
    default:
      return 'left';
  }
}

function getProviderTheme(
  configuration: PaywallThemeConfiguration | CustomerPortalThemeConfiguration | CheckoutWidgetConfiguration,
  widgetIdentifier: WidgetIdentifier,
): DeepPartial<Theme> {
  const typographyConfig = configuration.typography;
  const typographyTheme = {
    fontFamilyUrl: typographyConfig.fontFamily,
    h1: {
      fontSize: `${typographyConfig.h1.fontSize}px`,
      fontWeight: convertFontWeight(typographyConfig.h1.fontWeight),
    },
    h2: {
      fontSize: `${typographyConfig.h2.fontSize}px`,
      fontWeight: convertFontWeight(typographyConfig.h2.fontWeight),
    },
    h3: {
      fontSize: `${typographyConfig.h3.fontSize}px`,
      fontWeight: convertFontWeight(typographyConfig.h3.fontWeight),
    },
    body: {
      fontSize: `${typographyConfig.body.fontSize}px`,
      fontWeight: convertFontWeight(typographyConfig.body.fontWeight),
    },
  };
  switch (widgetIdentifier) {
    case WidgetIdentifier.CustomerPortal: {
      const { palette, css } = configuration as CustomerPortalThemeConfiguration;
      return {
        palette,
        typography: typographyTheme,
        layout: {
          planMaxWidth: '280px',
          planMinWidth: '280px',
        },
        customCss: css,
      };
    }
    case WidgetIdentifier.Paywall: {
      const { palette, layout, css } = configuration as PaywallThemeConfiguration;
      return {
        palette,
        typography: typographyTheme,
        layout: {
          ctaAlignment: mapAlignment(layout.alignment),
          headerAlignment: mapAlignment(layout.alignment),
          descriptionAlignment: mapAlignment(layout.alignment),
          planMaxWidth: `${layout.planWidth}px`,
          planMinWidth: `${layout.planWidth}px`,
          planMargin: `${layout.planMargin}px`,
          planPadding: `${layout.planPadding}px`,
        },
        customCss: css,
      };
    }
    case WidgetIdentifier.Checkout: {
      const { palette, css } = configuration as CheckoutWidgetConfiguration;
      return {
        palette: {
          primary: palette?.primary || undefined,
          backgroundPaper: palette?.backgroundColor || undefined,
          outlinedBorder: palette?.borderColor || undefined,
          text: {
            primary: palette?.textColor || undefined,
          },
          backgroundHighlight: palette?.summaryBackgroundColor || undefined,
        },
        typography: typographyTheme,
        customCss: css,
      };
    }
    case WidgetIdentifier.MspPortal: {
      return {};
    }
    default: {
      throw new Error('Unknown widget identifier');
    }
  }
}

export function CanvasPanel({
  widgetIdentifier,
  customerId: customerIdProp,
  configuration,
  productId,
  customerPortalHiddenSections,
  countryCode,
  planId,
}: {
  widgetIdentifier: WidgetIdentifier;
  customerId?: string;
  configuration: PaywallThemeConfiguration | CustomerPortalThemeConfiguration | CheckoutWidgetConfiguration;
  productId?: string;
  customerPortalHiddenSections: CustomerPortalSection[];
  countryCode?: string;
  planId?: string;
}) {
  const theme = useTheme();
  const clientApiKey = useCurrentClientApiKey();
  const customerId = widgetIdentifier !== WidgetIdentifier.Paywall ? customerIdProp : undefined;
  const customerToken = useCustomerToken(customerId);
  if (!clientApiKey) {
    return <></>;
  }

  const themeConfig = getProviderTheme(configuration, widgetIdentifier);

  // <StyledFrame>, <FrameProvider>, <GlobalStyle> are here to help us to inject all the relevant styling and
  // behaviours that we have in the app to the iFrame that is holding the stigg provider in it.
  // we do it in order to have the preview stigg provider not colliding with our real stigg provider (dog fooding)

  if (widgetIdentifier === WidgetIdentifier.MspPortal) {
    return (
      <Box
        width="100%"
        height="100%"
        theme={theme}
        style={{
          colorScheme: 'light',
          display: 'block',
          overflow: 'scroll',
          border: 0,
          backgroundImage: `url(${dottedBackgroundDark})`,
          backgroundColor: theme.isLightTheme ? '#F1F1F1' : 'rgba(151, 150, 150, 0.50)',
        }}>
        {renderWidget(widgetIdentifier, customerPortalHiddenSections, configuration, countryCode, productId, planId)}
      </Box>
    );
  }

  return (
    <StyledFrame width="100%" height="100%" theme={theme}>
      <FrameProvider>
        <GlobalStyle />
        <StiggProvider
          key={customerId}
          apiKey={clientApiKey}
          baseUri={config.graphQLUrl?.split('/graphql')[0]}
          enableEdge={false}
          customerId={customerId}
          customerToken={customerToken}
          theme={themeConfig}>
          {renderWidget(widgetIdentifier, customerPortalHiddenSections, configuration, countryCode, productId, planId)}
        </StiggProvider>
      </FrameProvider>
    </StyledFrame>
  );
}
