import { t } from 'i18next';
import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import { Text, Icon, LoadingButton, Button, GridFlex } from '@stigg-components';
import { WidgetConfig } from './hooks/useWidgetConfiguration';

type WidgetPageHeaderProps = {
  widgetConfig: WidgetConfig;
  onCancel: () => void;
  onSaveChanges: () => void;
};

export function WidgetPageHeader({ widgetConfig, onCancel, onSaveChanges }: WidgetPageHeaderProps) {
  const allowWidgetsWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);

  return (
    <GridFlex.Row alignItems="center" justifyContent="space-between" width="100%">
      <GridFlex.Row alignItems="center">
        <Button onClick={onCancel}>
          <Icon type="reactFeather" icon="ArrowLeft" color="active" size={20} />
          <Text.B2 marginLeft={2}>Back to all widgets</Text.B2>
        </Button>
      </GridFlex.Row>
      <GridFlex.Row gap={2}>
        <Button variant="outlined" onClick={onCancel}>
          {t('widgets.discard')}
        </Button>
        <LoadingButton
          disabled={!allowWidgetsWrite || !widgetConfig.hasChanges}
          loadingPosition="start"
          loading={widgetConfig.isLoadingSetConfig}
          variant="contained"
          onClick={onSaveChanges}>
          {t('widgets.saveAndPublish')}
        </LoadingButton>
      </GridFlex.Row>
    </GridFlex.Row>
  );
}
