import { createSlice } from '@reduxjs/toolkit';
import remove from 'lodash/remove';
import {
  ImportIntegrationTaskResultFragment,
  IntegrationFragment,
  IntegrationListItemFragment,
  AwsProduct,
  AwsDimension,
} from '@stigg-types/apiTypes';
import {
  triggerIntegrationCatalogImport,
  triggerIntegrationCustomersImport,
  createIntegration,
  deleteIntegrationById,
  updateIntegration,
} from './mutations';
import {
  fetchIntegrations,
  fetchIntegrationByVendor,
  fetchAWSExternalId,
  fetchAWSProducts,
  fetchAWSProductDimensions,
  searchStripeProducts,
  searchStripeCustomers,
  searchStripeSubscriptions,
} from './queries';
import { fetchImportIntegrationTasks } from './queries/watchImportIntegrationTasks';

import { createAppAsyncThunk } from '../../redux/createAppAsyncThunk';

export interface IntegrationsState {
  isLoading: boolean;
  isLoadingIntegration: boolean;
  createLoading: boolean;
  updateLoading: boolean;
  integrations: IntegrationListItemFragment[];
  integration: IntegrationFragment | null;
  importTasks: ImportIntegrationTaskResultFragment[];
  awsExternalId: string | null;

  isLoadingAWSProducts: boolean;
  awsProducts: AwsProduct[] | null;
  isLoadingAWSProductDimensions: boolean;
  awsProductDimensions: AwsDimension[] | null;
}

const initialState: IntegrationsState = {
  isLoading: false,
  isLoadingIntegration: false,
  createLoading: false,
  updateLoading: false,
  integrations: [],
  integration: null,
  importTasks: [],
  awsExternalId: null,
  isLoadingAWSProducts: false,
  awsProducts: null,
  isLoadingAWSProductDimensions: false,
  awsProductDimensions: null,
};

const fetchIntegrationsAction = createAppAsyncThunk('fetchIntegrations', fetchIntegrations);
const fetchAWSExternalIdAction = createAppAsyncThunk('fetchAWSExternalId', fetchAWSExternalId);
const fetchAWSProductsAction = createAppAsyncThunk('fetchAWSProducts', fetchAWSProducts);
const fetchAWSProductDimensionsAction = createAppAsyncThunk('fetchAWSProductDimensions', fetchAWSProductDimensions);
const createIntegrationAction = createAppAsyncThunk('createIntegration', createIntegration);
const deleteIntegrationByIdAction = createAppAsyncThunk('deleteIntegrationById', deleteIntegrationById);
const fetchIntegrationByVendorAction = createAppAsyncThunk('fetchIntegrationsByVendor', fetchIntegrationByVendor);
const updateIntegrationAction = createAppAsyncThunk('updateIntegration', updateIntegration);
const searchStripeProductsAction = createAppAsyncThunk('searchStripeProducts', searchStripeProducts);
const searchStripeCustomersAction = createAppAsyncThunk('searchStripeCustomers', searchStripeCustomers);
const searchStripeSubscriptionsAction = createAppAsyncThunk('searchStripeSubscriptions', searchStripeSubscriptions);
const fetchImportIntegrationTasksAction = createAppAsyncThunk(
  'fetchImportIntegrationTasks',
  fetchImportIntegrationTasks,
);
const triggerIntegrationCatalogImportAction = createAppAsyncThunk(
  'triggerIntegrationCatalogImport',
  triggerIntegrationCatalogImport,
);
const triggerIntegrationCustomersImportAction = createAppAsyncThunk(
  'triggerIntegrationCustomersImport',
  triggerIntegrationCustomersImport,
);

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIntegrationsAction.fulfilled, (state, action) => {
        state.integrations = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchIntegrationsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchIntegrationsAction.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchAWSProductsAction.fulfilled, (state, action) => {
        state.awsProducts = action.payload;
        state.isLoadingAWSProducts = false;
      })
      .addCase(fetchAWSProductsAction.pending, (state) => {
        state.isLoadingAWSProducts = true;
      })
      .addCase(fetchAWSProductsAction.rejected, (state) => {
        state.isLoadingAWSProducts = false;
      });

    builder
      .addCase(fetchAWSProductDimensionsAction.fulfilled, (state, action) => {
        state.awsProductDimensions = action.payload;
        state.isLoadingAWSProductDimensions = false;
      })
      .addCase(fetchAWSProductDimensionsAction.pending, (state) => {
        state.isLoadingAWSProductDimensions = true;
      })
      .addCase(fetchAWSProductDimensionsAction.rejected, (state) => {
        state.isLoadingAWSProductDimensions = false;
      });

    builder
      .addCase(fetchAWSExternalIdAction.fulfilled, (state, action) => {
        state.awsExternalId = action.payload;
      })
      .addCase(fetchAWSExternalIdAction.rejected, (state) => {
        state.awsExternalId = null;
      });

    builder
      .addCase(createIntegrationAction.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createIntegrationAction.fulfilled, (state) => {
        state.createLoading = false;
      })
      .addCase(createIntegrationAction.rejected, (state) => {
        state.createLoading = false;
      });

    builder
      .addCase(updateIntegrationAction.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateIntegrationAction.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateIntegrationAction.rejected, (state) => {
        state.updateLoading = false;
      });

    builder
      .addCase(fetchIntegrationByVendorAction.pending, (state, { meta }) => {
        if (!meta?.arg?.silentFetch) {
          state.isLoadingIntegration = true;
        }
      })
      .addCase(fetchIntegrationByVendorAction.fulfilled, (state, { payload }) => {
        state.integration = payload;
        state.isLoadingIntegration = false;
      })
      .addCase(fetchIntegrationByVendorAction.rejected, (state) => {
        state.isLoadingIntegration = false;
      });

    builder
      .addCase(deleteIntegrationByIdAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteIntegrationByIdAction.fulfilled, (state, { payload }) => {
        remove(state.integrations, (integration) => integration.id === payload?.id);
        state.integration = null;
        state.isLoading = false;
      })
      .addCase(deleteIntegrationByIdAction.rejected, (state) => {
        state.isLoading = false;
      });

    builder.addCase(fetchImportIntegrationTasksAction.fulfilled, (state, { payload }) => {
      state.importTasks = payload;
    });
  },
});

export {
  fetchAWSExternalIdAction,
  fetchIntegrationsAction,
  fetchAWSProductsAction,
  fetchAWSProductDimensionsAction,
  createIntegrationAction,
  fetchIntegrationByVendorAction,
  deleteIntegrationByIdAction,
  updateIntegrationAction,
  searchStripeProductsAction,
  triggerIntegrationCatalogImportAction,
  triggerIntegrationCustomersImportAction,
  fetchImportIntegrationTasksAction,
  searchStripeCustomersAction,
  searchStripeSubscriptionsAction,
};

export default integrationsSlice.reducer;
