import { gql } from '@apollo/client';
import {
  FetchIntegrationByVendorQueryVariables,
  FetchIntegrationByVendorQuery,
  Integration,
  VendorIdentifier as VendorIdentifierDTO,
} from '@stigg-types/apiTypes';
import { ExecuteOperationOptions, executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { VendorIdentifier } from '../constants';

const FETCH_INTEGRATION = gql`
  query FetchIntegrationByVendor($filter: IntegrationFilter!) {
    integrations(filter: $filter) {
      edges {
        node {
          ...IntegrationFragment
        }
      }
    }
  }

  fragment IntegrationFragment on Integration {
    id
    vendorIdentifier
    credentials {
      ... on ZuoraCredentials {
        ...ZouraCredentialsFragment
      }
      ... on StripeCredentials {
        ...StripeCredentialsFragment
      }
      ... on HubspotCredentials {
        ...HubspotCredentialsFragment
      }
      ... on AwsMarketplaceCredentials {
        ...AwsMarketplaceCredentialsFragment
      }
      ... on SnowflakeCredentials {
        ...SnowflakeCredentialsFragment
      }
      ... on SalesforceCredentials {
        ...SalesforceCredentialsFragment
      }
      ... on BigQueryCredentials {
        ...BigQueryCredentialsFragment
      }
      ... on Auth0Credentials {
        ...Auth0CredentialsFragment
      }
      ... on OpenFGACredentials {
        ...OpenFGACredentialsFragment
      }
    }
  }

  fragment ZouraCredentialsFragment on ZuoraCredentials {
    baseUrl
    clientId
    clientSecret
    stripePublishableKey
    stripeSecretKey
  }

  fragment StripeCredentialsFragment on StripeCredentials {
    accountId
    accountDisplayName
    isTestMode
  }

  fragment HubspotCredentialsFragment on HubspotCredentials {
    hubDomain
  }

  fragment AwsMarketplaceCredentialsFragment on AwsMarketplaceCredentials {
    awsRoleArn
  }

  fragment SnowflakeCredentialsFragment on SnowflakeCredentials {
    database
    host
    role
    schemaName
    username
    warehouse
  }

  fragment Auth0CredentialsFragment on Auth0Credentials {
    clientDomain
    clientId
    clientSecret
    applicationId
    applicationType
    applicationName
    individualInitialPlanId
    individualSubscriptionStartSetup
    organizationInitialPlanId
    organizationSubscriptionStartSetup
  }

  fragment SalesforceCredentialsFragment on SalesforceCredentials {
    domain
  }

  fragment BigQueryCredentialsFragment on BigQueryCredentials {
    projectId
    datasetLocation
    datasetId
    hmacKeyAccessId
    hmacKeySecret
    gcsBucketName
    gcsBucketPath
    credentialsJson
  }

  fragment OpenFGACredentialsFragment on OpenFGACredentials {
    apiUrl
    apiAudience
    apiTokenIssuer
    clientId
    storeId
    modelId
  }
`;

type FetchIntegrationByVendorProps = {
  vendorIdentifier: VendorIdentifier;
} & ExecuteOperationOptions;

async function fetchIntegrationByVendor(
  { vendorIdentifier }: FetchIntegrationByVendorProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<Integration | any | null> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const res = await apolloClient.query<FetchIntegrationByVendorQuery, FetchIntegrationByVendorQueryVariables>({
        query: FETCH_INTEGRATION,
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            vendorIdentifier: {
              eq: vendorIdentifier as VendorIdentifierDTO,
            },
            environmentId: { eq: accountReducer.currentEnvironmentId || '' },
          },
        },
      });

      const integrations = res.data.integrations.edges;
      return integrations.length > 0 ? integrations[0].node : null;
    },
    {
      failureMessageHandler: () => t('integrations.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchIntegrationByVendor };
