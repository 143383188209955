import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTheme } from '@mui/material/styles';
import { useQueryParam } from '@stigg-common';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { GridFlex, EmptyList } from '@stigg-components';
import { CursorPaging, EventLog as EventLogSDK } from '@stigg-types/apiTypes';
import Table from '../../../components/table/Table';
import { headCells } from './headCells';
import EventLogDetails from './details/EventLogDetails';
import { DEFAULT_PAGE_SIZE, useGetEventLogs } from '../queries/useGetEventLogs';
import { useReactQueryPagination } from '../../../components/table/useReactQueryPagination';
import { useGetEventLog } from '../queries/useGetEventLog';
import { getListCursors } from '../utils/getListCursors';
import useArrowKeyAction from '../../../components/hooks/useArrowKeyNavigation';

export enum EventLogColumns {
  Timestamp,
  EventName,
  Entity,
  Actor,
}

export const EVENT_ID_SEARCH_PARAM = 'eventId';

const DEFAULT_COLUMNS = [EventLogColumns.Timestamp, EventLogColumns.EventName, EventLogColumns.Actor];

export type EventLogProps = {
  entityId?: string;
  parentEntityId?: string;
  columns?: EventLogColumns[];
};

function EmptyState() {
  return <EmptyList title={t('eventsLog.emptyState')} />;
}

function ErrorState() {
  return <EmptyList title={t('eventsLog.errorState')} />;
}

export default function EventLog({ entityId, parentEntityId, columns = DEFAULT_COLUMNS }: EventLogProps) {
  const theme = useTheme();
  const { setQueryParam, removeQueryParam, value: currentEventQueryParam } = useQueryParam(EVENT_ID_SEARCH_PARAM);
  const { eventLogInEntityContext: showEventLog } = useFlags<FeatureFlags>();
  const [cursor, setCursor] = useState<CursorPaging>({ first: DEFAULT_PAGE_SIZE });
  const [currentEvent, setCurrentEvent] = useState<EventLogSDK | null | undefined>(null);
  const searchParamEventId = useRef(currentEventQueryParam).current;

  const { isPending, isError, data } = useGetEventLogs({ entityId, parentEntityId, cursor });
  const { data: searchParamEventFetched } = useGetEventLog({ eventLogId: searchParamEventId }); // filter

  const { events, pageInfo } = data || {};
  const { pageNumber, lastVisitedPage, pageChangeFunc } = useReactQueryPagination(
    pageInfo,
    setCursor,
    DEFAULT_PAGE_SIZE,
  );
  const { setPrevItem, setNextItem } = getListCursors(events, currentEvent, setCurrentEvent);

  const onLeftArrow = setPrevItem
    ? () => setPrevItem()
    : pageInfo?.hasPreviousPage
    ? () => pageChangeFunc(null, pageNumber - 1)
    : undefined;
  const onRightArrow = setNextItem
    ? () => setNextItem()
    : pageInfo?.hasNextPage
    ? () => pageChangeFunc(null, pageNumber + 1)
    : undefined;

  useArrowKeyAction({ onLeftArrow, onRightArrow });

  useEffect(() => {
    if (!isPending && events && currentEvent && !events.find((event) => event.id === currentEvent.id)) {
      // set the current event to the first or last based on the last visited page
      setCurrentEvent(events[lastVisitedPage < pageNumber ? 0 : events?.length - 1]);
    }
  }, [isPending, currentEvent, events, lastVisitedPage, pageNumber]);

  useEffect(() => {
    const event = searchParamEventFetched?.events?.[0];
    if (searchParamEventId && event && searchParamEventId === event.id) {
      setCurrentEvent(event);
    }
  }, [searchParamEventFetched?.events, searchParamEventId]);

  useEffect(() => {
    if (currentEvent) {
      setQueryParam(currentEvent.id);
    }
  }, [currentEvent, setQueryParam]);

  const onClose = () => {
    setCurrentEvent(null);
    removeQueryParam();
  };

  // prevent access to the event log via direct link to the page
  if (!showEventLog) {
    return null;
  }

  const isEmptyState = !isPending && !isError && (!events || events.length === 0);

  return (
    <>
      <EventLogDetails event={currentEvent} onClose={onClose} onPrevious={onLeftArrow} onNext={onRightArrow} />
      <GridFlex.Column gap={4}>
        {isEmptyState && <EmptyState />}
        {isError && <ErrorState />}
        {!isEmptyState && !isError && (
          <Table
            isLoading={isPending}
            skeletonOptions={{ rowsCount: 5 }}
            data={events || []}
            headCells={headCells(columns)}
            pageInfo={pageInfo}
            pageNum={pageNumber}
            pageSize={DEFAULT_PAGE_SIZE}
            pageChangeFunc={pageChangeFunc}
            rowColor={(event) => (currentEvent?.id === event?.id ? theme.itamar.palette.action.hover : undefined)}
            onRowClick={(event: EventLogSDK) => setCurrentEvent(event)}
          />
        )}
      </GridFlex.Column>
    </>
  );
}
