import React from 'react';
import { Launcher } from 'commandbar';
import { styled } from '@stigg-theme';

const LauncherWrapper = styled('div')`
  height: 100%;

  & .commandbar-user-launcher {
    font-family: 'Avenir Next', sans-serif;
    min-width: 200px;
    width: 250px;
    position: relative;
    padding: 4px 12px;
    color: rgba(166, 166, 166);
    background: ${({ theme }) =>
      theme.isLightTheme ? 'rgb(255, 255, 255)' : theme.itamar.palette.background.darkBackground3};
    ${({ theme }) => `border: 1px solid ${theme.itamar.palette.other.outlineBorderLight}`};
    border-radius: 10px;
    transition: border 0.3s ease-in-out;
    font-size: 14px;
    height: 100%;
    align-content: center;
    cursor: text;
  }

  & .commandbar-user-launcher:hover {
    border: 1px solid ${({ theme }) => theme.itamar.palette.primary.main};
  }

  & .commandbar-user-launcher__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .commandbar-user-launcher__prefix {
    display: flex;
    align-items: center;
  }

  .commandbar-user-launcher__prefix svg {
    width: 16px;
    height: 16px;
  }

  .commandbar-user-launcher__suffix {
    font-size: 14px;
  }

  .commandbar-user-launcher__tag {
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0 0 -10px;
    min-width: 1em;
  }
`;

export function CommandBarLauncher() {
  return (
    <LauncherWrapper>
      <Launcher text="Find anything" style={{}} />
    </LauncherWrapper>
  );
}
