import { PropsWithChildren } from 'react';

type HideIfNotAllowedProps = PropsWithChildren<{
  show: boolean;
}>;

export const Hidable = ({ children, show }: HideIfNotAllowedProps) => {
  if (!show) {
    return null;
  }

  return <>{children}</>;
};
