import { t } from 'i18next';
import { Coupon, CouponFragment, CouponType, Currency, SubscriptionCouponDataFragment } from '@stigg-types/apiTypes';
import { currencyPriceFormatter } from '../../../packages/pricing/components/currency/currencyUtils';
import { SubscriptionCouponConfiguration } from '../../../customers/components/customerPage/customerSubscriptions/subscriptionForm/SubscriptionForm.types';
import { formatDiscountDuration } from './formatDiscount';

type CouponFormatInput = Pick<
  Coupon,
  'name' | 'type' | 'discountValue' | 'percentOff' | 'amountsOff' | 'durationInMonths'
>;

type FormatCouponOptions = {
  showNegativeSign?: boolean;
  withOffPostfix?: boolean;
  showDuration?: boolean;
  withCodePostfix?: boolean;
  withoutValue?: boolean;
};

const getCouponAmount = (
  coupon: Pick<CouponFragment, 'percentOff' | 'amountsOff' | 'discountValue'>,
  currency = Currency.Usd,
) => {
  return (
    coupon.percentOff ||
    coupon.amountsOff?.find((amount) => amount.currency === currency)?.amount ||
    coupon.discountValue
  );
};

export const formatCouponValue = (
  discountValue: number,
  discountType: CouponType,
  currency: Currency | undefined = Currency.Usd,
  durationInMonths?: number | null,
  {
    showNegativeSign = true,
    withOffPostfix = true,
    showDuration = true,
    withCodePostfix = true,
  }: FormatCouponOptions = {},
) => {
  let formattedDiscount = '';

  if (discountType === CouponType.Percentage) {
    formattedDiscount = `${discountValue}%${withOffPostfix ? ' off' : ''}`;
  }

  if (discountType === CouponType.Fixed) {
    const amount = showNegativeSign ? discountValue * -1 : discountValue;
    const price = currencyPriceFormatter({ amount, currency, options: { withCodePostfix } });
    formattedDiscount = `${price}${withOffPostfix ? ' off' : ''}`;
  }

  const duration = t('coupons.duration.forMonths', { count: durationInMonths || 0 });

  return showDuration ? `${formattedDiscount} ${duration}` : formattedDiscount;
};

export const formatCoupon = (
  { name, discountValue, percentOff, amountsOff, type, durationInMonths }: CouponFormatInput,
  currency = Currency.Usd,
  {
    showNegativeSign = true,
    withOffPostfix = true,
    showDuration = true,
    withCodePostfix = true,
    withoutValue = false,
  }: FormatCouponOptions = {},
) => {
  const amountOff = getCouponAmount({ percentOff, amountsOff, discountValue }, currency);
  return `${name} ${
    withoutValue
      ? ''
      : `(${formatCouponValue(amountOff, type, currency, durationInMonths, {
          showNegativeSign,
          withOffPostfix,
          showDuration,
          withCodePostfix,
        })})`
  }`;
};

export const formatCouponNameForSubscriptionPreview = (
  coupon: CouponFragment | SubscriptionCouponDataFragment,
  currency = Currency.Usd,
) => {
  const amount = getCouponAmount(coupon, currency);
  return `${coupon.name} (${formatCouponValue(amount, coupon.type, currency, undefined, {
    showNegativeSign: false,
    showDuration: false,
    withCodePostfix: false,
    withOffPostfix: true,
  })})`;
};

export const formatCouponDurationForSubscriptionPreview = (
  coupon: (CouponFragment & { startDate?: Date }) | SubscriptionCouponDataFragment,
  couponConfiguration?: SubscriptionCouponConfiguration,
) => {
  const startDate = coupon.startDate ?? couponConfiguration?.startDate;
  return formatDiscountDuration({ start: startDate, durationInMonths: coupon.durationInMonths });
};
