import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Alert, AlertTitle, DialogActionsButtons, Divider, Flex, Text, CustomDrawer } from '@stigg-components';
import { Environment } from '@stigg-types/apiTypes';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@stigg-theme';
import { useEnvironmentsPermissions } from '@stigg-permissions';
import { EntityTile } from './EntityTile';
import { RootState, useAppDispatch } from '../../../../redux/store';
import {
  ENVIRONMENT_SELECT_WIDTH,
  EnvironmentOptionType,
  EnvironmentsAutocompleteSearch,
} from './EnvironmentsAutocompleteSearch';
import { CompareEnvironmentsDialog } from './CompareEnvironmentsDialog';
import {
  mergeEnvironmentAction,
  resetMergeEnvironmentValidation,
  validateMergeEnvironmentAction,
} from '../../accountsSlice';
import { useEnvironmentDiff } from './useEnvironmentDiff';
import { ChangesAlert } from './ChangesAlert';

const AlertBodyContainer = styled(Flex.Column)`
  gap: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(4)};
  max-height: 200px;
  overflow-y: auto;
  padding-right: ${({ theme }) => theme.spacing(2)};
`;

interface MergeEnvironmentsDrawerProps {
  open: boolean;
  onClose: () => void;
  environment: Environment | null;
}

export const MergeEnvironmentsDrawer = ({ open, onClose, environment }: MergeEnvironmentsDrawerProps) => {
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const environmentPermissions = useEnvironmentsPermissions(environments);
  const envOptions = useMemo(
    () =>
      environments
        .filter((env) => env.id !== environment?.id && environmentPermissions[env.id].WriteEnvironment)
        .map(({ displayName, slug }) => ({ displayName, slug })),
    [environments, environment, environmentPermissions],
  );
  const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentOptionType | null>(null);
  const [isCompareDialogOpen, setIsCompareDialogOpen] = useState(false);
  const { mergeEnvironmentValidation } = useSelector((state: RootState) => state.accountReducer);
  const { changes, isLoadingDiff, diff } = useEnvironmentDiff(
    environment?.slug,
    selectedEnvironment?.slug,
    mergeEnvironmentValidation.isLoaded && mergeEnvironmentValidation.isValid,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSelectedEnvironment(null);
  }, [environment]);

  useEffect(() => {
    dispatch(resetMergeEnvironmentValidation());
    if (selectedEnvironment?.slug && environment?.slug) {
      void dispatch(
        validateMergeEnvironmentAction({
          sourceEnvironmentSlug: environment.slug,
          destinationEnvironmentSlug: selectedEnvironment.slug,
        }),
      );
    }
  }, [selectedEnvironment, environment, dispatch]);

  const closeDrawer = () => {
    dispatch(resetMergeEnvironmentValidation());
    onClose();
  };

  const onClickMainCta = async () => {
    if (selectedEnvironment?.slug) {
      if (changes) {
        setIsCompareDialogOpen(true);
        return;
      }
      await dispatch(
        mergeEnvironmentAction({
          sourceEnvironmentSlug: environment!.slug,
          destinationEnvironmentSlug: selectedEnvironment.slug,
        }),
      );
    } else {
      await dispatch(
        mergeEnvironmentAction({
          sourceEnvironmentSlug: environment!.slug,
          destinationEnvironmentName: selectedEnvironment!.displayName,
        }),
      );
    }

    closeDrawer();
  };

  return (
    <>
      <CustomDrawer isOpen={open} title={t('accounts.copyMergeEnvironments.sidebar.header')} onClose={closeDrawer}>
        {environment && (
          <Flex.Column $fullHeight>
            <Text.B2 mt={2}>
              <Trans
                i18nKey="accounts.copyMergeEnvironments.sidebar.text"
                values={{ environmentName: environment.displayName }}
                components={{ strong: <Text.B2 display="inline" $bold /> }}
              />
            </Text.B2>
            <Text.B2 mt={3}>{t('accounts.copyMergeEnvironments.sidebar.entitiesCopiedHeader')}</Text.B2>
            <Flex.Row sx={{ mt: 2, gap: 2 }}>
              <EntityTile
                icon="Catalog"
                title={t('accounts.copyMergeEnvironments.sidebar.productCatalogTile')}
                description={t('accounts.copyMergeEnvironments.sidebar.productCatalogDescription')}
              />
              <EntityTile
                icon="Colorpalette"
                title={t('accounts.copyMergeEnvironments.sidebar.widgetDesignerTile')}
                description={t('accounts.copyMergeEnvironments.sidebar.widgetDesignerDescription')}
              />
            </Flex.Row>
            <Divider mt={6} />
            <Flex.RowCenter mt={6} py={3}>
              <Text.B2 sx={{ flexGrow: 1 }}>{t('accounts.copyMergeEnvironments.sidebar.sourceEnv')}</Text.B2>
              <Text.B2 sx={{ width: ENVIRONMENT_SELECT_WIDTH }}>{environment.displayName}</Text.B2>
            </Flex.RowCenter>
            <Flex.RowCenter py={3}>
              <Text.B2 sx={{ flexGrow: 1 }}>{t('accounts.copyMergeEnvironments.CTA')}</Text.B2>
              <EnvironmentsAutocompleteSearch
                envOptions={envOptions}
                sourceEnvironmentName={environment.displayName}
                selectedEnvironment={selectedEnvironment}
                setSelectedEnvironment={setSelectedEnvironment}
              />
            </Flex.RowCenter>
            {selectedEnvironment && !mergeEnvironmentValidation.isLoading && !mergeEnvironmentValidation.isValid && (
              <Alert severity="error" sx={{ mt: 3, mb: 4 }}>
                <AlertTitle>
                  <Text.B2 color="error.main">
                    <Trans
                      i18nKey="accounts.copyMergeEnvironments.sidebar.validationError"
                      values={{
                        sourceEnvironment: environment.displayName,
                        destinationEnvironment: selectedEnvironment.displayName,
                      }}
                      components={{ strong: <Text.B2 display="inline" color="error.main" $bold /> }}
                    />
                  </Text.B2>
                </AlertTitle>
                <AlertBodyContainer>
                  {mergeEnvironmentValidation.errors.map((err) => (
                    <Text.B2 key={err}>{err}</Text.B2>
                  ))}
                </AlertBodyContainer>
              </Alert>
            )}
            {selectedEnvironment &&
              mergeEnvironmentValidation.isLoaded &&
              mergeEnvironmentValidation.isValid &&
              !isLoadingDiff &&
              !!diff && <ChangesAlert changes={changes} sx={{ mt: 3, mb: 4 }} />}
            <DialogActionsButtons
              onCancel={closeDrawer}
              saveText={
                selectedEnvironment?.slug && changes && mergeEnvironmentValidation.isValid
                  ? t('accounts.copyMergeEnvironments.sidebar.compareButtonText')
                  : t('accounts.copyMergeEnvironments.sidebar.copyButtonText')
              }
              saveDisabled={
                !selectedEnvironment || !mergeEnvironmentValidation.isValid || (!!selectedEnvironment?.slug && !changes)
              }
              onSave={onClickMainCta}
              isSaveLoading={mergeEnvironmentValidation.isLoading || isLoadingDiff}
            />
          </Flex.Column>
        )}
      </CustomDrawer>
      {isCompareDialogOpen && selectedEnvironment?.slug && environment && (
        <CompareEnvironmentsDialog
          sourceEnv={environment}
          destEnv={selectedEnvironment as Required<EnvironmentOptionType>}
          onClose={() => {
            setIsCompareDialogOpen(false);
          }}
          onMergeSuccess={closeDrawer}
        />
      )}
    </>
  );
};
