import { GetMeteredEntitlement, METERED_DEFAULT_FALLBACK_ENTITLEMENT, MeteredEntitlement } from '@stigg/js-client-sdk';
import { useStiggContext } from './useStiggContext';

type TypedGetMeteredEntitlement<T> = Omit<GetMeteredEntitlement, 'featureId'> & {
  featureId: T;
};

export function useMeteredEntitlement<T extends string>(params: TypedGetMeteredEntitlement<T>): MeteredEntitlement {
  const stiggContext = useStiggContext({ optional: true });
  const stigg = stiggContext?.stigg;

  if (!stigg) {
    return {
      ...METERED_DEFAULT_FALLBACK_ENTITLEMENT,
      ...params.options?.fallback,
    };
  }

  return stigg.getMeteredEntitlement(params);
}
