const config = {
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
    scope: 'openid profile',
  },
  graphQLUrl: process.env.REACT_APP_GRAPHQL_URL,
  restUrl: process.env.REACT_APP_REST_URL,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  devMode: process.env.NODE_ENV !== 'production',
  envName: process.env.REACT_APP_ENV_NAME || 'development',
  stripeClientId: process.env.REACT_APP_STRIPE_CLIENT_ID || '',
  hubspotClientId: process.env.REACT_APP_HUBSPOT_CLIENT_ID || '',
  stripeTestClientId: process.env.REACT_APP_STRIPE_TEST_CLIENT_ID || '',
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
  stripeTestPublishableKey: process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY || '',
  fullstoryOrgId: process.env.REACT_APP_FULLSTORY_ORG_ID || '',
  cubejsApiUrl: process.env.REACT_APP_CUBEJS_API_URL || '',
  stiggdServiceBaseUrl: process.env.REACT_APP_STIGGD_SERVICE_BASE_URL || 'http://localhost:8001',
  demoBaseUrl: process.env.REACT_APP_DEMO_BASE_URL || '',
  sandboxStripeAccountId: process.env.REACT_APP_SANDBOX_STRIPE_ACCOUNT_ID || '',
  launchDarklyKey: process.env.REACT_APP_LAUNCHDARKLY_SDK_CLIENT || '',
  segmentKey: process.env.REACT_APP_SEGMENT_KEY || '',
  hotjarId: process.env.REACT_APP_HOTJAR_ID || '',
  hotjarSnippetVersion: process.env.REACT_APP_HOTJAR_SNIPPET_VERSION || '',
  intercomId: process.env.REACT_APP_INTERCOM_ID || '',
  stiggDoggoBaseUrl: process.env.REACT_APP_STIGG_DOGGO_BASE_URL,
  stiggDoggoApiKey: process.env.REACT_APP_STIGG_DOGGO_API_KEY || '',
  stiggDoggoEnableEdge: process.env.REACT_APP_STIGG_DOGGO_ENABLE_EDGE === 'true',
  commandBarOrgId: process.env.REACT_APP_COMMAND_BAR_ORG_ID,
  salesforceAppVersionId: process.env.REACT_APP_SALESFORCE_APP_VERSION_ID,
};

export default config;
