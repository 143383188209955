import { useState } from 'react';
import { Close } from '@mui/icons-material';
import { t } from 'i18next';
import { TwoLinesLayout } from '@stigg-components';
import {
  EntitlementResetPeriod,
  MonthlyAccordingTo,
  WeeklyAccordingTo,
  YearlyAccordingTo,
} from '@stigg-types/apiTypes';
import { ResetPeriodConfiguration } from '@stigg-common/types';
import { EntitlementValue, IncrementalEntitlementConfigurationRow } from './EntitlementRow';
import Table from '../../../../components/table/Table';
import { isMetered } from '../../../features/utils/isMetered';
import { EntitlementFields } from '../../../entitlements/components/entitlementSettings/types';

type EntitlementsTableProps = {
  entitlements: EntitlementFields[];
  isAdd?: boolean;
  fromWidget?: boolean;
  remove?: (index: number) => void;
  errors: any;
  handleChange: any;
  height?: number;
  touched: any;
  handleBlur: any;
  setFieldValue: any;
  setFieldTouched: any;
  withCustomEntitlementOption?: boolean;
};

export function EntitlementsTable({
  entitlements,
  handleChange,
  height,
  touched,
  errors,
  handleBlur,
  remove,
  setFieldValue,
  setFieldTouched,
  isAdd = false,
  withCustomEntitlementOption,
  fromWidget,
}: EntitlementsTableProps) {
  const [optionsByEntitlementFeatureId, setOptionsByEntitlementFeatureId] = useState<{
    [key: string]: { isOpen?: boolean; previousValue?: number | null };
  }>({});

  const toggleEntitlementIsOpen = (featureId: string) =>
    setOptionsByEntitlementFeatureId((prevState) => ({
      ...prevState,
      [featureId]: { ...prevState[featureId], isOpen: !prevState[featureId]?.isOpen },
    }));

  const getEntitlementPreviousValue = (featureId: string) => optionsByEntitlementFeatureId[featureId]?.previousValue;

  const setEntitlementPreviousValue = (featureId: string, value?: number | null) =>
    setOptionsByEntitlementFeatureId((prevState) => ({
      ...prevState,
      [featureId]: { ...prevState[featureId], previousValue: value },
    }));

  return (
    <Table
      maxHeight={height}
      rowHeight={85}
      withRowHover={false}
      label=""
      data={entitlements}
      headCells={[
        {
          width: '75%',
          alignment: 'left',
          id: 'feature-name',
          label: t('entitlements.feature'),
          render: (entitlement) => {
            return (
              <TwoLinesLayout firstRow={entitlement.feature?.displayName} secondRow={entitlement.feature?.refId} />
            );
          },
        },
        {
          width: '100%',
          alignment: 'left',
          id: 'feature-value',
          label: t('entitlements.entitlementValue'),
          render: (entitlement, index) => {
            const { feature } = entitlement;
            const allocated = 10000;
            const left = allocated - (entitlement?.usageLimit || 0);
            let captionText: string | undefined;
            // TODO: clean it up when we remove the MSP portal widget
            if (fromWidget && feature?.refId === 'professional') {
              captionText = `${Math.max(left, 0)} ${feature.featureUnits} left`;
            }

            return (
              <EntitlementValue
                captionText={captionText}
                entitlement={entitlement}
                index={index}
                key={index}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                withCustomEntitlementOption={withCustomEntitlementOption}
                setEntitlementPreviousValue={setEntitlementPreviousValue}
                previousValue={entitlement.feature && getEntitlementPreviousValue(entitlement.feature.id)}
              />
            );
          },
        },
        {
          id: 'feature-remove',
          alignment: 'center',
          width: 65,
          visible: isAdd,
          render: (_, index) => (
            <Close
              sx={{ cursor: 'pointer', color: (theme) => theme.itamar.palette.action.active }}
              onClick={() => remove && remove(index)}
            />
          ),
        },
        {
          alignment: 'left',
          id: 'feature-extra',
          label: '',
          inNewRow: true,
          render: (entitlement, index) => {
            if (!entitlement.feature || !isMetered(entitlement.feature)) {
              return null;
            }

            const onResetPeriodChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any, index: number) => {
              const resetPeriod = e.target.value as EntitlementResetPeriod;
              let resetPeriodConfiguration: ResetPeriodConfiguration | undefined;
              if (resetPeriod === EntitlementResetPeriod.Year) {
                resetPeriodConfiguration = YearlyAccordingTo.SubscriptionStart;
              } else if (resetPeriod === EntitlementResetPeriod.Month) {
                resetPeriodConfiguration = MonthlyAccordingTo.SubscriptionStart;
              } else if (resetPeriod === EntitlementResetPeriod.Week) {
                resetPeriodConfiguration = WeeklyAccordingTo.SubscriptionStart;
              }
              setFieldValue(`entitlements[${index}].resetPeriodConfiguration`, resetPeriodConfiguration);
              // Using setTimeout here because otherwise Formik doesn't revalidate the resetPeriodConfiguration field
              // See discussion about it here: https://github.com/jaredpalmer/formik/issues/2059
              setTimeout(() => {
                handleChange(e);
              }, 0);
            };

            const toggleResetPeriod = (e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                setFieldValue(`entitlements[${index}].resetPeriod`, EntitlementResetPeriod.Month);
                setFieldValue(`entitlements[${index}].resetPeriodConfiguration`, MonthlyAccordingTo.SubscriptionStart);
              } else {
                setFieldValue(`entitlements[${index}].resetPeriod`, null);
                setFieldValue(`entitlements[${index}].resetPeriodConfiguration`, null);
              }

              if (entitlement.feature) {
                const featureId = entitlement.feature.id;
                setOptionsByEntitlementFeatureId((prevState) => ({
                  ...prevState,
                  [featureId]: { ...prevState[featureId], isOpen: e.target.checked },
                }));
              }

              setTimeout(() => {
                setFieldTouched(`entitlements[${index}].resetPeriod`, true);
                setFieldTouched(`entitlements[${index}].resetPeriodConfiguration`, true);
              }, 0);
            };

            return (
              <IncrementalEntitlementConfigurationRow
                toggleIsOpen={() => entitlement.feature && toggleEntitlementIsOpen(entitlement.feature.id)}
                isOpen={!!optionsByEntitlementFeatureId[entitlement.feature.id]?.isOpen}
                index={index}
                entitlement={entitlement}
                handleChange={handleChange}
                toggleResetPeriod={(e: any) => toggleResetPeriod(e)}
                onResetPeriodChanged={(e: any) => onResetPeriodChange(e, setFieldValue, index)}
                // Hack to force re-rendering when switching from unlimited to not
                key={entitlement?.hasUnlimitedUsage?.toString()}
              />
            );
          },
        },
      ]}
    />
  );
}
