import React, { useState, FC } from 'react';
import isNil from 'lodash/isNil';
import {
  Icon,
  Text,
  Button,
  IconButton,
  InformationTooltip,
  TooltipProps,
  Box,
  CodeTypographyText,
} from '@stigg-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components/macro';

type ClipboardChipProps = {
  backgroundColor?: string;
  text?: string;
  copy?: string;
  iconSize?: number;
  iconLocation?: 'left' | 'right';
  tooltipPlacement?: TooltipProps['placement'];
  variant?: 'button' | 'code';
  tooltipSuffix?: string;
  spaceIcon?: boolean;
  padding?: number;
  copyPadding?: number;
};

export const CodeTypographyCard = styled(Box)<{
  $backgroundColor?: string;
  $spaceIcon?: boolean;
  $disableCopy?: boolean;
}>`
  cursor: ${({ $disableCopy }) => ($disableCopy ? 'default' : 'pointer')};
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor || theme.isLightTheme ? theme.itamar.palette.grey[50] : theme.itamar.palette.action.hover};
  border-radius: 4px;
  gap: 4px;
  ${(props) => props.$spaceIcon && 'justify-content: space-between'}
`;

const CODE_VARIANT_DEFAULT_ICON_SIZE = 16;

const CopyIcon: FC<Pick<ClipboardChipProps, 'iconSize' | 'text' | 'variant'>> = ({ iconSize, text, variant }) => (
  <Icon
    icon="Copy"
    type="reactFeather"
    size={variant === 'code' ? iconSize ?? CODE_VARIANT_DEFAULT_ICON_SIZE : iconSize}
    color={variant === 'code' ? 'secondary' : text ? undefined : 'active'}
  />
);

export function ClipboardChip({
  text,
  copy,
  tooltipPlacement = 'top',
  iconSize,
  iconLocation,
  variant = 'button',
  tooltipSuffix,
  backgroundColor,
  spaceIcon,
  padding = 1,
  copyPadding,
}: ClipboardChipProps) {
  const [copyTooltipOpen, setCopyTooltipOpenOpen] = useState(false);

  const onCopy = () => {
    setCopyTooltipOpenOpen(true);
    setTimeout(() => {
      setCopyTooltipOpenOpen(false);
    }, 1500);
  };

  return (
    <CopyToClipboard text={copy || ''} onCopy={onCopy}>
      <InformationTooltip
        arrow
        $padding={2}
        placement={tooltipPlacement}
        onClose={() => setCopyTooltipOpenOpen(false)}
        title={<Text.B2>{copyTooltipOpen ? 'Copied!' : `Copy${tooltipSuffix ? ` ${tooltipSuffix}` : ''}`}</Text.B2>}>
        {text ? (
          variant === 'code' ? (
            <CodeTypographyCard p={padding} $backgroundColor={backgroundColor} $spaceIcon={spaceIcon}>
              {iconLocation !== 'right' && <CopyIcon variant={variant} iconSize={iconSize} text={text} />}
              <CodeTypographyText color="secondary">{text}</CodeTypographyText>
              {iconLocation === 'right' && <CopyIcon variant={variant} iconSize={iconSize} text={text} />}
            </CodeTypographyCard>
          ) : (
            <Button
              color="primary"
              $outlined
              endIcon={
                iconLocation === 'right' ? <CopyIcon variant={variant} iconSize={iconSize} text={text} /> : undefined
              }
              startIcon={
                iconLocation !== 'right' ? <CopyIcon variant={variant} iconSize={iconSize} text={text} /> : undefined
              }>
              {text}
            </Button>
          )
        ) : (
          <IconButton {...(!isNil(copyPadding) ? { sx: { padding: copyPadding } } : {})}>
            <CopyIcon variant={variant} iconSize={iconSize} text={text} />
          </IconButton>
        )}
      </InformationTooltip>
    </CopyToClipboard>
  );
}
