import { t } from 'i18next';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import { useToggleNavbarControls } from '@stigg-common';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { CustomDrawer, Grid } from '@stigg-components';
import { AddonFragment, PackageStatus, UpdatePackageEntitlementOrderInput } from '@stigg-types/apiTypes';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { updateAddonAction } from '../../addonSlice';
import { PackageDetails } from '../../../common/components/PackageDetails';
import { EditPackageForm, EditPackageFormFields } from '../../../common/components/EditPackageForm';
import { PackageGrantedEntitlements } from '../../../common/components/packageGrantedEntitlements/PackageGrantedEntitlements';
import { PackagePricing } from '../../../common/components/PackagePricing';
import { PackageEditTopBar } from '../../../common/components/packageEditTopBar/PackageEditTopBar';
import { EntityMetadataCard } from '../../../../../components/entityMetadata/EntityMetadataCard';
import { updateEntitlementsOrderAction } from '../../../../entitlements/entitlementsSlice';
import { usePriceWizard } from '../../../pricing/hooks/usePriceWizard';
import { AddonType } from '../AddonTypeValue';
import { AddonDependencies } from '../AddonDependencies';

type AddonOverviewProps = {
  addon: AddonFragment;
  silentReloadAddonData: () => Promise<void>;
  onDiscardChanges: () => void;
};

export default function AddonOverview({ addon, silentReloadAddonData, onDiscardChanges }: AddonOverviewProps) {
  const { addonDependency: isAddonDependencyEnabled } = useFlags<FeatureFlags>();
  const [isReorderingEntitlements, setIsReorderingEntitlements] = useState(false);
  const dispatch = useAppDispatch();
  const isSilentLoadingAddon = useSelector((root: RootState) => root.addonReducer.isSilentLoadingAddon);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [hasPublishValidationError, setHasPublishValidationError] = useState(false);
  const { setNavbarControls } = useToggleNavbarControls();

  const allowAddonWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);
  const isReadOnly = !allowAddonWrite;

  const { PriceWizard, openSetPriceWizard } = usePriceWizard(setHasPublishValidationError, addon);

  useEffect(() => {
    setNavbarControls({
      editDeleteControls: {
        aPackage: addon,
        title: 'add-on',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addon]);

  const onEditDetails = () => {
    setEditDialogOpen(true);
  };

  const onUpdate = async (updatePayload: EditPackageFormFields) => {
    const maxQuantity = updatePayload.addonType === AddonType.MULTI ? null : 1;
    await dispatch(
      updateAddonAction({
        addonRefId: addon.refId,
        updatePayload: {
          ...(omit(updatePayload, ['refId', 'parentPlanId', 'productId', 'addonType']) as EditPackageFormFields),
          id: addon.id,
          maxQuantity,
        },
      }),
    );
    setEditDialogOpen(false);
  };
  const onSubmitSetMetaData = async (metadata: Record<string, string>) => {
    await dispatch(
      updateAddonAction({
        addonRefId: addon.refId,
        updatePayload: { id: addon.id, additionalMetaData: metadata, displayName: addon.displayName },
      }),
    );
  };

  const handleEntitlementsReorder = async (entitlements: UpdatePackageEntitlementOrderInput['entitlements']) => {
    setIsReorderingEntitlements(true);
    await dispatch(updateEntitlementsOrderAction({ packageId: addon.id, entitlements }));
    await silentReloadAddonData();
    setIsReorderingEntitlements(false);
  };

  const readonly = isReadOnly || addon.status !== PackageStatus.Draft;

  return (
    <Grid container flexWrap="nowrap" flexDirection="column" gap={6}>
      <PackageEditTopBar
        aPackage={addon}
        setHasPublishValidationError={setHasPublishValidationError}
        onDiscardChanges={onDiscardChanges}
        isLoading={isSilentLoadingAddon || isReorderingEntitlements}
      />
      <PackageDetails aPackage={addon} onEditDetails={onEditDetails} readonly={readonly} />
      <PackagePricing
        aPackage={addon}
        readonly={readonly}
        hasPublishValidationError={hasPublishValidationError}
        openSetPriceWizard={openSetPriceWizard}
      />
      <PackageGrantedEntitlements
        withReordering
        onEntitlementsReorder={handleEntitlementsReorder}
        readonly={readonly}
        aPackage={addon}
        onEntitlementsChanged={silentReloadAddonData}
        openSetPriceWizard={openSetPriceWizard}
      />
      {isAddonDependencyEnabled ? <AddonDependencies addon={addon} readonly={readonly} /> : null}
      <EntityMetadataCard metaData={addon.additionalMetaData} onSubmit={onSubmitSetMetaData} entityTypeName="addon" />
      <CustomDrawer
        title={t('addons.editAddonDialogTitle')}
        isOpen={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}>
        <EditPackageForm
          packageType="Add-on"
          aPackage={addon}
          onCancel={() => setEditDialogOpen(false)}
          onUpdate={onUpdate}
        />
      </CustomDrawer>
      <PriceWizard />
    </Grid>
  );
}
