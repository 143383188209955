import { Card, OptionsDropdown, Chip, Grid, Text } from '@stigg-components';
import { t } from 'i18next';
import { useState } from 'react';
import { Trash2 } from 'react-feather';
import styled, { css } from 'styled-components/macro';
import { HookDataFragment, IntegrationListItemFragment } from '@stigg-types/apiTypes';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { isIntegrationActive } from './isIntegrationActive';
import { useAppDispatch } from '../../../redux/store';
import { useNavigation } from '../../navigation/useNavigation';
import { deleteIntegrationByIdAction } from '../integrationsSlice';
import { AvailableIntegration } from '../models';
import { LongText } from '../../../components/LongText';
import { deleteHookByIdAction } from '../../webhooks/hooksSlice';

const CardWrapper = styled(Card)<{ $isComingSoon?: boolean }>`
  padding: 32px;
  min-height: 230px;
  cursor: auto;

  ${({ $isComingSoon, theme }) =>
    $isComingSoon
      ? css`
          border-color: ${theme.itamar.palette.action.disabledBackground} !important;
          background-color: ${theme.itamar.palette.action.disabled};
        `
      : css`
          cursor: pointer;
          &:hover {
            box-shadow: ${theme.itamar.palette.shadow.lightShadow};
          }
        `}
`;

export const IntegrationIcon = styled.img`
  width: 50px;
  height: 50px;
`;

type IntegrationCardProps = {
  availableIntegration: AvailableIntegration;
  integration?: IntegrationListItemFragment;
  webhookIntegration?: HookDataFragment;
  hideOptions?: boolean;
};

export function IntegrationCard({
  availableIntegration: { getDescription, icon, isComingSoon, name, beta, recommended, vendorIdentifier, isDisabled },
  integration,
  webhookIntegration,
  hideOptions,
}: IntegrationCardProps) {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDialogClose = async (shouldDelete: boolean) => {
    if (!shouldDelete) {
      setDeleteDialogOpen(false);
      return;
    }
    if (integration) {
      await dispatch(deleteIntegrationByIdAction(integration.id));
    }
    if (webhookIntegration) {
      await dispatch(deleteHookByIdAction({ hookId: webhookIntegration.id, redirectUrl: 'integrations' }));
    }
    setDeleteDialogOpen(false);
  };

  return (
    <CardWrapper
      onClick={() =>
        !isDisabled && !isComingSoon && navigation.navigateTo(`/integrations/${vendorIdentifier.toLowerCase()}`)
      }
      $isComingSoon={isDisabled || isComingSoon}>
      <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
        <Grid item>
          <IntegrationIcon src={icon} alt={name} />
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item mr={2}>
              {isDisabled ? (
                <Chip size="small" label={t('integrations.disabled')} color="disabled" textColor="white" />
              ) : isIntegrationActive(integration) || webhookIntegration ? (
                <Chip size="small" label={t('integrations.active')} color="primary" textColor="white" />
              ) : isComingSoon ? (
                <Chip size="small" label={t('sharedComponents.comingSoon')} color="disabled" textColor="white" />
              ) : null}
            </Grid>

            {!hideOptions && !isDisabled && (integration || webhookIntegration) && (
              <Grid item>
                <OptionsDropdown
                  options={[
                    {
                      icon: Trash2,
                      text: t('integrations.deleteButtonText'),
                      onClick: () => setDeleteDialogOpen(true),
                    },
                  ]}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid alignItems="center" my={2}>
        <Text.H3>{name}</Text.H3>
        {recommended && <Text.B2 ml={2}>{t('integrations.recommended')}</Text.B2>}
        {beta && <Text.B2 ml={2}>{t('integrations.beta')}</Text.B2>}
      </Grid>

      <LongText variant="body2" maxLines={3} tooltipPlacement="bottom">
        {getDescription()}
      </LongText>

      <ConfirmationDialog
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        title={t('integrations.delete')}
        content={t('integrations.deleteConfirmation')}
      />
    </CardWrapper>
  );
}
