import { t } from 'i18next';
import {
  PreviewNextInvoiceFragment,
  SubscriptionPreviewDiscount,
  SubscriptionMaximumSpendDiscount,
} from '@stigg-types/apiTypes';
import { SectionTitle } from './SectionTitle';
import { ChargeRow } from './ChargeRow';
import {
  formatDiscountDetails,
  formatDiscountDuration,
} from '../../../../../../coupons/components/utils/formatDiscount';

type DiscountsSectionProps = Pick<PreviewNextInvoiceFragment, 'discount'> & {
  discountDetails: SubscriptionPreviewDiscount | SubscriptionMaximumSpendDiscount | null | undefined;
};

export function DiscountsSection({ discount, discountDetails }: DiscountsSectionProps) {
  if (!discount || discount?.amount === 0 || !discountDetails) {
    return null;
  }

  return (
    <>
      <SectionTitle title={t('subscriptions.breakdown.discountSectionTitle')} />
      <ChargeRow
        description={formatDiscountDetails(discountDetails, discount.currency)}
        descriptionCaption={formatDiscountDuration(discountDetails)}
        charge={discount}
      />
    </>
  );
}
