import { EnvironmentPermissionActions } from '@stigg-permissions';
import styled from 'styled-components';
import { t } from 'i18next';
import { Trash2 } from 'react-feather';
import { GridFlex, Icon, IconButton, InformationTooltip, PermissionProtected, Text } from '..';
import { CustomerResponseFragment, PaymentMethodType } from '../../types/apiTypes';
import { GridProps } from '../Grids';

type IconProps = { iconSize?: number };

export const PaymentMethodIconByType = {
  [PaymentMethodType.Card]: ({ iconSize }: IconProps = {}) => (
    <Icon icon="CreditCard" type="materialIcons" color="default" size={iconSize} />
  ),
  [PaymentMethodType.Bank]: ({ iconSize }: IconProps = {}) => (
    <Icon icon="AccountBalance" type="materialIcons" color="default" size={iconSize} />
  ),
};

type PaymentDetailsProps = {
  paymentMethodType: CustomerResponseFragment['defaultPaymentMethodType'];
  last4Digits: CustomerResponseFragment['defaultPaymentMethodLast4Digits'];
  onDetachPaymentClick: () => void;
  expirationMonth?: CustomerResponseFragment['defaultPaymentExpirationMonth'];
  expirationYear?: CustomerResponseFragment['defaultPaymentExpirationYear'];
  iconSize?: number;
  iconWrapperProps?: GridProps;
};

const DeleteIcon = styled(Trash2)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
  height: 20px;
  width: 20px;
`;

const MethodDeleteButton = styled(IconButton)`
  transition: 0.2s ease-in-out;
  opacity: 0;
`;

const HoverableGridFlex = styled(GridFlex.RowCenter)`
  :hover,
  :active,
  :focus {
    ${MethodDeleteButton} {
      opacity: 1;
    }
  }
`;

export function PaymentDetails({
  paymentMethodType,
  last4Digits,
  expirationMonth,
  expirationYear,
  iconSize,
  iconWrapperProps = {},
  onDetachPaymentClick,
}: PaymentDetailsProps) {
  const resolvedPaymentMethodType = paymentMethodType || PaymentMethodType.Card;
  const paymentMethodText = t(`customers.paymentMethod.${resolvedPaymentMethodType}`);
  const formattedMonth = expirationMonth != null ? expirationMonth.toString().padStart(2, '0') : null;
  return (
    <GridFlex.RowCenter sx={{ pr: 2 }}>
      <GridFlex.RowCenter sx={{ mx: '8px' }}>
        <InformationTooltip arrow placement="top" $padding={2} title={<Text.B2>{paymentMethodText}</Text.B2>}>
          <GridFlex.RowCenter {...iconWrapperProps}>
            {PaymentMethodIconByType[resolvedPaymentMethodType]({ iconSize })}
          </GridFlex.RowCenter>
        </InformationTooltip>
        <Text.B2 color="secondary" ml={2} className="fs-mask">
          {last4Digits
            ? t(`customers.paymentMethod.endingWith.${resolvedPaymentMethodType}`, { last4Digits })
            : paymentMethodText}
        </Text.B2>
        {!!(expirationMonth && expirationYear) && resolvedPaymentMethodType === PaymentMethodType.Card && (
          <Text.B2 color="secondary" ml={4}>
            {`${t('customers.paymentMethod.expiration')} ${formattedMonth}/${expirationYear}`}
          </Text.B2>
        )}
      </GridFlex.RowCenter>
      <PermissionProtected permission={EnvironmentPermissionActions.CustomerWrite}>
        <HoverableGridFlex>
          <MethodDeleteButton onClick={onDetachPaymentClick}>
            <DeleteIcon />
          </MethodDeleteButton>
        </HoverableGridFlex>
      </PermissionProtected>
    </GridFlex.RowCenter>
  );
}
