import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormikState } from 'formik';
import { t } from 'i18next';
import { styled } from '@stigg-theme';
import { Box, Grid, GridFlex, Icon, Text, LongText } from '@stigg-components';
import {
  BillingPeriod,
  CustomerResponseFragment,
  PaymentCollectionMethod,
  PlanFragment,
  PlanListFragment,
  PriceFragment,
  PricingType,
  SubscriptionCouponDataFragment,
} from '@stigg-types/apiTypes';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { fetchAddonsAction } from '../../../../packages/addons/addonSlice';
import { SubscriptionFormFields } from './subscriptionForm/SubscriptionForm.types';
import { SubscriptionPriceBreakdown, SubscriptionPriceBreakdownProps } from './SubscriptionPriceBreakdown';
import { useSubscriptionPriceBreakdown } from './priceBreakdown';
import { currencyPriceFormatter } from '../../../../packages/pricing/components/currency/currencyUtils';
import { getCouponForPreview } from './subscriptionForm/components/coupon/utils';

const SummaryBox = styled(Box)`
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  background-color: ${({ theme }) => theme.itamar.palette.background.default};
`;

function shouldShowMonthlyPriceVariation(currentBillingPeriod: BillingPeriod, planPrices?: PriceFragment[] | null) {
  const hasMonthlyPrice = planPrices?.some((price) => price.billingPeriod === BillingPeriod.Monthly);
  return hasMonthlyPrice && currentBillingPeriod === BillingPeriod.Annually;
}

export type SubscriptionPreviewProps = {
  plan?: PlanListFragment | PlanFragment;
  values: FormikState<SubscriptionFormFields>['values'];
  customer: CustomerResponseFragment;
  subscriptionCoupon?: SubscriptionCouponDataFragment | null;
};

export function SubscriptionPreview({ plan, values, customer }: SubscriptionPreviewProps) {
  const dispatch = useAppDispatch();
  const previewSubscriptionResult = useSelector((state: RootState) => state.customersReducer.previewSubscriptionResult);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const planName = plan?.displayName || '';

  const {
    startDate,
    endDate,
    addons,
    paidOneOffAddons,
    resourceId,
    paymentCollectionMethod,
    billingPeriod,
    billingCountryCode,
    billableFeatures,
    billableFeaturesPriceOverride,
    addonsPriceOverride,
    minimumSpend,
    coupon: subscriptionCoupon,
    customCoupon,
    couponConfiguration,
    budget,
    isTrial,
    trialEndBehavior,
  } = values;

  const coupon = getCouponForPreview(customer.coupon, subscriptionCoupon, customCoupon);

  const planPrices = plan?.prices;
  const { priceBreakdown } = useSubscriptionPriceBreakdown({
    plan,
    addons,
    paidOneOffAddons,
    billingPeriod,
    billableFeatures,
    billableFeaturesPriceOverride,
    addonsPriceOverride,
    coupon,
    couponConfiguration,
    billingCountryCode,
    withZeroQuantityPrices: true,
    freeItems: previewSubscriptionResult?.freeItems,
    minimumSpend: minimumSpend?.minimum?.amount,
  });

  useEffect(() => {
    void dispatch(
      fetchAddonsAction({
        environmentId: currentEnvironmentId,
        addonIds: (plan?.compatibleAddons || []).map((x) => x.refId),
      }),
    );
  }, [plan, dispatch, currentEnvironmentId]);

  const showMonthlyPriceVariation =
    priceBreakdown?.billingPeriod && shouldShowMonthlyPriceVariation(priceBreakdown?.billingPeriod, planPrices);

  const trialConfiguration: SubscriptionPriceBreakdownProps['trialConfiguration'] =
    isTrial && endDate ? { startDate: new Date(startDate), endDate, trialEndBehavior } : undefined;

  return (
    <GridFlex.Column container $fullWidth>
      <SummaryBox p={6}>
        <GridFlex.RowCenter mt={1} mb={6}>
          <Icon icon="FileText" type="reactFeather" color="default" size={32} />
          <Text.H6 ml={2}>{t('subscriptionForm.subscriptionPreview')}</Text.H6>
        </GridFlex.RowCenter>

        {resourceId && (
          <GridFlex.Column mb={4}>
            <Text.B2 fontWeight="bolder" whiteSpace="nowrap" mr={2}>
              {t('sharedComponents.resourceId')}
            </Text.B2>
            <GridFlex.RowCenter gap={1}>
              <Icon icon="Layers" type="reactFeather" color="active" size={16} />
              <LongText variant="body2" wordBreak>
                {resourceId}
              </LongText>
            </GridFlex.RowCenter>
          </GridFlex.Column>
        )}
        {plan && (
          <>
            <Grid item>
              {plan.pricingType === PricingType.Free && (
                <Grid container flexDirection="column">
                  <GridFlex.RowSpaceBetween item>
                    <Text.B2 $bold>{t('priceBreakdown.total')}</Text.B2>
                    <Text.B2 $bold textAlign="right">
                      {currencyPriceFormatter({
                        amount: 0,
                        currency: customer.billingCurrency || undefined,
                        options: { withCodePostfix: true },
                      })}
                    </Text.B2>
                  </GridFlex.RowSpaceBetween>
                </Grid>
              )}
              {(paymentCollectionMethod === PaymentCollectionMethod.None ||
                plan.pricingType === PricingType.Custom) && (
                <Text.B2 $bold>{t('subscriptionForm.customPrice')}</Text.B2>
              )}
              {paymentCollectionMethod !== PaymentCollectionMethod.None &&
                plan.pricingType === PricingType.Paid &&
                priceBreakdown && (
                  <SubscriptionPriceBreakdown
                    priceBreakdown={priceBreakdown}
                    coupon={coupon}
                    couponConfiguration={couponConfiguration}
                    showMonthlyPriceVariation={!!showMonthlyPriceVariation}
                    showOneOffCharges
                    planName={planName}
                    trialConfiguration={trialConfiguration}
                    budget={budget}
                  />
                )}
            </Grid>
          </>
        )}
      </SummaryBox>
    </GridFlex.Column>
  );
}
