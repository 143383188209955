import { BooleanEntitlement, GetBooleanEntitlement, BOOLEAN_DEFAULT_FALLBACK_ENTITLEMENT } from '@stigg/js-client-sdk';
import { useStiggContext } from './useStiggContext';

export type TypedGetBooleanEntitlement<T> = Omit<GetBooleanEntitlement, 'featureId'> & {
  featureId: T;
};

export function useBooleanEntitlement<T extends string>(params: TypedGetBooleanEntitlement<T>): BooleanEntitlement {
  const stiggContext = useStiggContext({ optional: true });
  const stigg = stiggContext?.stigg;

  if (!stigg) {
    return {
      ...BOOLEAN_DEFAULT_FALLBACK_ENTITLEMENT,
      ...params.options?.fallback,
    };
  }

  return stigg.getBooleanEntitlement(params);
}
