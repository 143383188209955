import { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { BillingAddress, PreviewSubscription, StiggClient, SubscriptionPreviewV2 } from '@stigg/js-client-sdk';
import { useCheckoutContext } from '../CheckoutProvider';
import { useCheckoutModel } from './useCheckoutModel';
import { SubscriptionState, useSubscriptionModel } from './useSubscriptionModel';
import { MockCheckoutPreviewCallback } from '../types';
import { useStiggContext } from '../../../hooks/useStiggContext';

function mapBillingInformation({
  billingAddress,
  taxPercentage,
}: {
  billingAddress?: BillingAddress;
  taxPercentage?: number;
}): Pick<PreviewSubscription, 'billingInformation'> {
  if (!billingAddress && !taxPercentage) {
    return {};
  }

  return {
    billingInformation: {
      ...(billingAddress ? { billingAddress } : {}),
      ...(taxPercentage ? { taxPercentage } : {}),
    },
  };
}

type UsePreviewSubscriptionProps = {
  onMockCheckoutPreview?: MockCheckoutPreviewCallback;
};

export type PreviewSubscriptionProps = {
  customerId?: string;
  planId?: string;
  resourceId?: string;
  stigg: StiggClient;
} & SubscriptionState &
  UsePreviewSubscriptionProps;

const previewSubscription = async ({
  stigg,
  customerId,
  planId,
  resourceId,
  promotionCode,
  addons,
  billableFeatures,
  billingCountryCode,
  billingPeriod,
  billingAddress,
  taxPercentage,
  onMockCheckoutPreview,
}: PreviewSubscriptionProps) => {
  const estimateAddons = addons.map(({ addon, quantity }) => ({ addonId: addon.id, quantity }));
  let subscriptionPreview: SubscriptionPreviewV2 | null = null;
  let errorMessage: string | null = null;

  try {
    if (customerId && planId) {
      const previewSubscriptionProps: PreviewSubscription = {
        customerId,
        planId,
        resourceId,
        billingCountryCode,
        addons: estimateAddons,
        billingPeriod,
        promotionCode,
        billableFeatures: isEmpty(billableFeatures) ? undefined : billableFeatures,
        ...mapBillingInformation({ billingAddress, taxPercentage }),
      };

      subscriptionPreview = onMockCheckoutPreview
        ? onMockCheckoutPreview(previewSubscriptionProps)
        : await stigg.previewSubscription(previewSubscriptionProps);
    }
  } catch (error) {
    const [, errorMsg] = (error as any)?.message?.split('Error:') || [];

    errorMessage = errorMsg?.trim();
  }

  return { subscriptionPreview, errorMessage };
};

export const usePreviewSubscriptionAction = ({ onMockCheckoutPreview }: UsePreviewSubscriptionProps = {}) => {
  const { stigg } = useStiggContext();
  const subscription = useSubscriptionModel();
  const [{ resourceId }] = useCheckoutContext();
  const { checkoutState, widgetState } = useCheckoutModel();
  const { plan, customer } = checkoutState || {};

  const previewSubscriptionAction = useCallback(
    async ({ promotionCode }: { promotionCode?: string | null } = {}) => {
      if (!widgetState.isValid) {
        return { subscriptionPreview: null, errorMessage: null };
      }

      return previewSubscription({
        stigg,
        customerId: customer?.id,
        planId: plan?.id,
        resourceId,
        addons: subscription.addons,
        billableFeatures: subscription.billableFeatures,
        billingCountryCode: subscription.billingCountryCode,
        billingPeriod: subscription.billingPeriod,
        billingAddress: subscription.billingAddress,
        taxPercentage: subscription.taxPercentage,
        promotionCode: promotionCode ?? subscription.promotionCode,
        onMockCheckoutPreview,
      });
    },
    [
      stigg,
      customer?.id,
      plan?.id,
      resourceId,
      subscription.addons,
      subscription.billableFeatures,
      subscription.billingCountryCode,
      subscription.billingPeriod,
      subscription.billingAddress,
      subscription.taxPercentage,
      subscription.promotionCode,
      widgetState.isValid,
      onMockCheckoutPreview,
    ],
  );

  return { previewSubscriptionAction };
};

const SUBSCRIPTION_PREVIEW_DEBOUNCE_TIME = 500;

export const usePreviewSubscription = ({ onMockCheckoutPreview }: UsePreviewSubscriptionProps = {}) => {
  const [subscriptionPreview, setSubscriptionPreview] = useState<SubscriptionPreviewV2 | null>(null);
  const [isFetchingSubscriptionPreview, setIsFetchingSubscriptionPreview] = useState(false);

  const { previewSubscriptionAction } = usePreviewSubscriptionAction({ onMockCheckoutPreview });

  useEffect(() => {
    const estimateSubscription = async () => {
      const { subscriptionPreview } = await previewSubscriptionAction();
      if (subscriptionPreview) {
        setSubscriptionPreview(subscriptionPreview);
      }

      setIsFetchingSubscriptionPreview(false);
    };

    setIsFetchingSubscriptionPreview(true);

    const timer = setTimeout(() => {
      setIsFetchingSubscriptionPreview(true);
      void estimateSubscription();
    }, SUBSCRIPTION_PREVIEW_DEBOUNCE_TIME);

    return () => {
      clearTimeout(timer);
    };
  }, [previewSubscriptionAction]);

  return { subscriptionPreview, isFetchingSubscriptionPreview };
};
