import { Auth0CredentialsFragment, Auth0ApplicationType } from '@stigg-types/apiTypes';

export function isAuth0IntegrationCompleted(credentials: Auth0CredentialsFragment | undefined) {
  const isClientDetailsStepCompleted =
    !!credentials?.clientDomain && !!credentials?.clientId && !!credentials?.clientSecret;
  const isApplicationStepCompleted =
    !!credentials?.applicationId && !!credentials?.applicationType && !!credentials?.applicationName;

  const isIndividualApp =
    credentials?.applicationType &&
    [Auth0ApplicationType.Individual, Auth0ApplicationType.Both].includes(credentials.applicationType);
  const isOrganizationApp =
    credentials?.applicationType &&
    [Auth0ApplicationType.Organization, Auth0ApplicationType.Both].includes(credentials.applicationType);
  const isIndividualStepCompleted =
    !isIndividualApp || (!!credentials?.individualInitialPlanId && !!credentials?.individualSubscriptionStartSetup);
  const isOrganizationStepCompleted =
    !isOrganizationApp ||
    (!!credentials?.organizationInitialPlanId && !!credentials?.organizationSubscriptionStartSetup);
  const isInitialCustomerAccessStepCompleted = isIndividualStepCompleted && isOrganizationStepCompleted;
  const isSetupCompleted =
    isClientDetailsStepCompleted && isApplicationStepCompleted && isInitialCustomerAccessStepCompleted;

  return {
    isSetupCompleted,
    isInitialCustomerAccessStepCompleted,
    isApplicationStepCompleted,
    isClientDetailsStepCompleted,
  };
}
