import React, { useState } from 'react';
import { Trash2, Edit2, Eye, EyeOff } from 'react-feather';
import { styled } from '@stigg-theme';
import { t } from 'i18next';
import { EnvironmentPermissionActions, useEnvironmentPermissionCheck } from '@stigg-permissions';
import {
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Divider,
  StyledSwitch,
  Text,
  OptionsDropdown,
  GridFlex,
  DetailsLayout,
  Hidable,
} from '@stigg-components';
import { HookDataFragment, HookStatus } from '@stigg-types/apiTypes';
import { PageCard } from '../../../../components/PageCard';
import { useAppDispatch } from '../../../../redux/store';
import { updateHookAction } from '../../hooksSlice';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { ClipboardText } from '../../../../components/clipboard/ClipboardText';

const StyledEyeIcon = styled(Eye)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;

const StyledEyeOffIcon = styled(EyeOff)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;

const HookDetails: React.FC<{
  hook: HookDataFragment;
  onDeleteClick: () => void;
  onEditClick: () => void;
}> = ({ hook, onDeleteClick, onEditClick }) => {
  const dispatch = useAppDispatch();
  const [disableDialogOpen, setDisableDialogOpen] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  const hookIsActive = hook.status === HookStatus.Active;
  const allowHooksWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);

  const updateHookStatus = async () => {
    await dispatch(
      updateHookAction({
        hookId: hook.id || '',
        updatePayload: { status: hookIsActive ? HookStatus.Inactive : HookStatus.Active },
      }),
    );
  };

  const handleStatusChange = async () => {
    if (hookIsActive) {
      setDisableDialogOpen(true);
    } else {
      await updateHookStatus();
    }
  };

  const handleDisableDialogClose = async (shouldDisable: boolean) => {
    if (shouldDisable && hook?.id) {
      await updateHookStatus();
    }
    setDisableDialogOpen(false);
  };

  return (
    <PageCard>
      <GridFlex.RowSpaceBetweenTop container wrap="nowrap">
        <GridFlex.Column item>
          <Text.Sub2 mb={1}>{t('hooks.hookDetails')}</Text.Sub2>
          <Text.H3 mr={3}>{hook.description}</Text.H3>
        </GridFlex.Column>
        <Grid item container width="auto" justifyContent="flex-end" wrap="nowrap">
          <Grid item alignItems="baseline">
            <FormControlLabel
              control={
                <StyledSwitch
                  disabled={!allowHooksWrite}
                  checked={hookIsActive}
                  name="hookStatus"
                  onChange={handleStatusChange}
                  size="medium"
                />
              }
              label={hookIsActive ? t('hooks.active') : t('hooks.disabled')}
            />
          </Grid>
          <Hidable show={allowHooksWrite}>
            <Grid item alignItems="flex-start">
              <OptionsDropdown
                options={[
                  {
                    icon: Edit2,
                    text: t('hooks.edit'),
                    onClick: onEditClick,
                  },
                  {
                    icon: Trash2,
                    text: t('sharedComponents.delete'),
                    onClick: onDeleteClick,
                  },
                ]}
              />
            </Grid>
          </Hidable>
        </Grid>
      </GridFlex.RowSpaceBetweenTop>

      <Divider my={4} />

      <DetailsLayout
        details={[
          {
            title: t('hooks.endpointUrl'),
            content: hook.endpoint,
          },
          {
            title: t('hooks.secret'),
            content: (
              <GridFlex.RowCenter>
                <Grid item mr={2} mt={showSecret ? 0 : 1}>
                  <ClipboardText
                    text={showSecret ? hook.secretKey || '' : '•••••••••••••••••••••••••'}
                    tooltipPlacement="top"
                    copy={hook.secretKey || ''}
                    textColor="primary"
                  />
                </Grid>
                <Grid item>
                  <IconButton onClick={() => setShowSecret(!showSecret)}>
                    {showSecret ? (
                      <Tooltip arrow placement="top" title="Conceal">
                        <StyledEyeOffIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip arrow placement="top" title="Reveal">
                        <StyledEyeIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </Grid>
              </GridFlex.RowCenter>
            ),
            shouldPrintLine: !!hook.secretKey,
            alignItems: 'center',
          },
        ]}
      />
      <ConfirmationDialog
        open={disableDialogOpen}
        handleClose={handleDisableDialogClose}
        title={t('hooks.disable')}
        content={t('hooks.disablingConfirmation')}
        confirmationButtonText={t('hooks.disableCta')}
      />
    </PageCard>
  );
};

export default HookDetails;
