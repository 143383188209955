import {
  UsageHistoryFragment,
  UsageHistoryMarkerFragment,
  UsageHistoryPointFragment,
  UsageMarkerType,
} from '@stigg-types/apiTypes';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import moment from 'moment';

export type Series = {
  label: string;
  data: UsageHistoryPointFragment[];
};

function enhancePointDates(points: UsageHistoryPointFragment[]) {
  return points.map((x) => ({ ...x, timestamp: new Date(x.timestamp) }));
}

export function getChartData(usageHistory: UsageHistoryFragment): Series[] {
  const { series } = usageHistory;
  const firstSeries = head(series);
  const hasTags = !isEmpty(firstSeries?.tags);

  if (hasTags) {
    return series.map(({ tags, points }) => ({
      label: tags.map(({ value }) => value || 'none').join(' / '),
      data: enhancePointDates(points),
    }));
  }

  return [
    {
      label: 'Total',
      data: enhancePointDates(firstSeries?.points || []),
    },
  ];
}

export function getPointToLastMarkerType(
  points: UsageHistoryPointFragment[],
  markers: UsageHistoryMarkerFragment[],
): Map<Date, UsageMarkerType> {
  const result = new Map<Date, UsageMarkerType>();

  if (isEmpty(points) || isEmpty(markers)) {
    return result;
  }

  let markerIndex = 0;

  for (let i = 0; i < points.length; i += 1) {
    const currentPoint = points[i];
    const nextPoint = points[i + 1];
    const currentTimestamp = moment.utc(currentPoint.timestamp);
    const nextTimestamp = nextPoint ? moment.utc(nextPoint.timestamp) : null;

    let lastMarkerType: UsageMarkerType | undefined;

    // Move the markerIndex to the first marker within the range
    while (markerIndex < markers.length && moment.utc(markers[markerIndex].timestamp).isBefore(currentTimestamp)) {
      markerIndex += 1;
    }

    // Find the last marker within the range
    while (
      markerIndex < markers.length &&
      (!nextTimestamp || moment.utc(markers[markerIndex].timestamp).isBefore(nextTimestamp))
    ) {
      lastMarkerType = markers[markerIndex].type;
      markerIndex += 1;
    }

    if (lastMarkerType) {
      result.set(currentPoint.timestamp, lastMarkerType);
    }
  }

  return result;
}

export function getMaxDataPointValue(series: Series[], usageLimit?: number | null): number {
  const allValues = series.flatMap(({ data }) => data).map(({ value }) => value);
  const maxValue = Math.max(...allValues);
  return maxValue || usageLimit || 0;
}
