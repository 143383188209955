import { t } from 'i18next';
import { AccountPermissionActions } from '@stigg-permissions';
import { AccountPermissionsProtected, EmptyCardContent, Link, Text } from '@stigg-components';

type AccountEnvironmentsEmptyStateProps = {
  onAddEnvironmentClick: () => void;
};

export default function AccountEnvironmentsEmptyState({ onAddEnvironmentClick }: AccountEnvironmentsEmptyStateProps) {
  return (
    <EmptyCardContent>
      <Text.Sub2>{t('accounts.environmentSectionEmpty')}</Text.Sub2>
      <AccountPermissionsProtected
        permissions={[
          AccountPermissionActions.CreateProductionEnvironment,
          AccountPermissionActions.CreateNonProductionEnvironment,
        ]}>
        <Link variant="body2" ml={1} onClick={onAddEnvironmentClick}>
          {t('accounts.environmentSectionEmptyCTA')}
        </Link>
      </AccountPermissionsProtected>
    </EmptyCardContent>
  );
}
