import { t } from 'i18next';
import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { Currency, CustomerResponseFragment } from '@stigg-types/apiTypes';
import { Box, Button, Field, FormRenderProps, GridFlex, Icon, InformationTooltip, Text } from '@stigg-components';
import { AdditionalInputActionParams, AdditionalInputLayout } from '../index';
import { SubscriptionFormFields } from '../../SubscriptionForm.types';
import { formatCoupon } from '../../../../../../../coupons/components/utils';
import { useCouponOptions } from './useCouponOptions';
import { FutureStartInput } from './FutureStartInput';
import { VerticalBorderBox } from '../../../../../../../../components/boundedSearchableSelect/BoundedSearchableSelect.style';
import { SEARCH_COMPONENT_WIDTH } from './consts';
import { CustomCouponInput } from './CustomCouponInput';
import { copyCustomCouponToState, resetCouponFields } from './utils';

type SubscriptionDiscountConfigurationProps = {
  customer: CustomerResponseFragment;
  currency: Currency;
  formRenderProps: FormRenderProps<SubscriptionFormFields>;
};

export function SubscriptionDiscountConfiguration({
  customer,
  currency,
  formRenderProps,
}: SubscriptionDiscountConfigurationProps) {
  const { showCouponFutureStartDate } = useFlags<FeatureFlags>();
  const {
    values: { couponRefId, coupon: existingSubscriptionCoupon },
  } = formRenderProps;
  const [showSearch, setShowSearch] = useState(!!couponRefId);
  const [showCustomCoupon, setShowCustomCoupon] = useState(false);

  const isCouponSet = couponRefId || showCustomCoupon;

  const onCustomCouponClick = (closeSearch: () => void) => {
    resetCouponFields(formRenderProps);
    copyCustomCouponToState(formRenderProps);
    setShowCustomCoupon(true);
    closeSearch();
  };

  const discountFields: Field<SubscriptionFormFields>[] = [
    {
      type: 'custom',
      hide: () => !customer.coupon,
      render: () => (
        <InformationTooltip
          arrow
          placement="top"
          title={<Text.B2>{t('subscriptionForm.discount.customerCouponTooltip')}</Text.B2>}>
          <Box>
            <Text.B2>{formatCoupon(customer.coupon!, currency, { showNegativeSign: false })}</Text.B2>
          </Box>
        </InformationTooltip>
      ),
    },
    {
      id: 'couponRefId',
      hide: () => !!customer.coupon || showCustomCoupon,
      type: 'dynamicDataSearchableSelect',
      gridProps: {
        minWidth: SEARCH_COMPONENT_WIDTH,
      },
      placeholder: t('subscriptionForm.discount.couponPlaceholder'),
      useDataLoader: (search) => useCouponOptions(search, currency, existingSubscriptionCoupon),
      dataTestId: 'select-coupon',
      footerComponent: (closeSelect) => (
        <VerticalBorderBox $isBorderTop>
          <Button onClick={() => onCustomCouponClick(closeSelect)} fullWidth>
            <GridFlex.Row gap={2} px={3} py={2} $fullWidth>
              <Icon icon="DiscountCustom" color="primary.main" size={18} overrideFill overrideStroke />
              <Text.B2 color="primary.main">{t('subscriptionForm.discount.customButton')}</Text.B2>
            </GridFlex.Row>
          </Button>
        </VerticalBorderBox>
      ),
    },
  ];

  const customDiscountCaption: Field<SubscriptionFormFields>[] = [
    {
      type: 'custom',
      hide: () => !showCustomCoupon,
      render: () => <Text.B2>{t('subscriptionForm.discount.custom')}</Text.B2>,
    },
  ];

  const onSetCoupon = () => {
    setShowSearch(true);
  };

  const onUnsetCoupon = ({ setFieldValue, setFieldTouched }: AdditionalInputActionParams) => {
    setShowSearch(false);
    setShowCustomCoupon(false);
    resetCouponFields({ setFieldValue, setFieldTouched });
  };

  return (
    <GridFlex.Column $fullWidth gap={2}>
      <Text.H6>{t('subscriptionForm.discount.title')}</Text.H6>
      <AdditionalInputLayout
        isSet={!!customer.coupon || showSearch}
        setIcon={{ icon: 'Discount', color: 'default', overrideFill: true, overrideStroke: true }}
        unsetIcon={{ icon: 'Discount', color: 'disabled', overrideFill: true, overrideStroke: true }}
        setLabel={t('subscriptionForm.discount.coupon')}
        buttonLabel={t('sharedComponents.add')}
        fields={showCustomCoupon ? customDiscountCaption : discountFields}
        onSet={onSetCoupon}
        onUnset={!customer.coupon ? onUnsetCoupon : undefined}
        formRenderProps={formRenderProps}
      />
      {showCustomCoupon && <CustomCouponInput currency={currency} formRenderProps={formRenderProps} />}
      {isCouponSet && showCouponFutureStartDate && <FutureStartInput formRenderProps={formRenderProps} />}
    </GridFlex.Column>
  );
}
