import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { permitState } from 'permit-fe-sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Environment } from '@stigg-types/apiTypes';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { RootState } from '../../redux/store';
import {
  EnvironmentPermissionActions,
  AccountPermissionActions,
  PermissionAction,
  PermissionResource,
  EnvironmentPermissions,
} from './consts';

export const checkPermission = (
  rbacRollout: boolean,
  action: PermissionAction,
  resourceType: PermissionResource = PermissionResource.Account,
  resourceKey?: string,
) => {
  if (!rbacRollout) {
    return true;
  }

  if (!resourceKey) {
    return permitState.check(action, resourceType, {});
  }

  return permitState.check(action, { type: resourceType, key: resourceKey }, {});
};

function usePermissionCheck(
  action: PermissionAction,
  resourceType: PermissionResource = PermissionResource.Account,
  resourceKey?: string,
) {
  const { rbacRollout } = useFlags<FeatureFlags>();

  return useMemo(() => {
    return checkPermission(rbacRollout, action, resourceType, resourceKey);
  }, [rbacRollout, action, resourceType, resourceKey]);
}

function usePermissionsCheck(
  actions: PermissionAction[],
  resourceType: PermissionResource = PermissionResource.Account,
  resourceKey?: string,
) {
  const { rbacRollout } = useFlags<FeatureFlags>();

  return useMemo(() => {
    return actions.map((action) => checkPermission(rbacRollout, action, resourceType, resourceKey));
  }, [rbacRollout, actions, resourceType, resourceKey]);
}

export function useAccountPermissionCheck(action: AccountPermissionActions) {
  return usePermissionCheck(action, PermissionResource.Account);
}

export function useAccountPermissionsCheck(actions: AccountPermissionActions[]) {
  return usePermissionsCheck(actions, PermissionResource.Account);
}

export function useEnvironmentPermissionCheck(action: EnvironmentPermissionActions, environmentId?: string) {
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);

  return usePermissionCheck(action, PermissionResource.Environment, environmentId || currentEnvironmentId);
}

function getEnvironmentPermissions(environmentId: string, rbacRollout?: boolean) {
  return Object.entries(EnvironmentPermissionActions).reduce((map, [actionKey, actionValue]) => {
    map[actionKey] = rbacRollout
      ? permitState.check(actionValue, { type: PermissionResource.Environment, key: environmentId }, {})
      : true;
    return map;
  }, {} as EnvironmentPermissions);
}

export function useEnvironmentsPermissions(environments: Environment[]) {
  const { rbacRollout } = useFlags<FeatureFlags>();

  return environments.reduce((acc, environment) => {
    acc[environment.id] = getEnvironmentPermissions(environment.id, rbacRollout);
    return acc;
  }, {} as Record<string, EnvironmentPermissions>);
}
