import { useEffect, useState } from 'react';
import parseInt from 'lodash/parseInt';
import isNaN from 'lodash/isNaN';
import { t } from 'i18next';
import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import { FontWeight } from '@stigg-types/apiTypes';
import { CollapsableSection, GridFlex, Text, TextField } from '@stigg-components';
import { WidgetConfig } from '../../hooks/useWidgetConfiguration';
import { SelectableIconButton } from './SelectableIconButton';
import { WidgetIdentifier } from '../../useWidgetsData';
import { convertBooleanToFontWeight } from '../../utils/typography';

function FontVariantConfiguration({
  label,
  initialValue,
  disabled,
  onChange,
}: {
  label: string;
  initialValue: { fontSize: number; bold: boolean };
  disabled?: boolean;
  onChange: (fontVariant: { fontSize: number; bold: boolean }) => void;
}) {
  const [fontVariantConfig, setFontVariantConfig] = useState(initialValue);

  useEffect(() => {
    setFontVariantConfig(initialValue);
  }, [initialValue]);

  const toggleBold = () => {
    setFontVariantConfig((currentState) => ({ ...currentState, bold: !currentState.bold }));
    onChange({
      ...fontVariantConfig,
      bold: !fontVariantConfig.bold,
    });
  };

  const onFontSizeChange = (fontSize: string) => {
    const parsedNumber = parseInt(fontSize);
    setFontVariantConfig((currentState) => ({ ...currentState, fontSize: parsedNumber }));
    if (!isNaN(parsedNumber)) {
      onChange({
        ...fontVariantConfig,
        fontSize: parsedNumber,
      });
    }
  };

  return (
    <GridFlex.Row alignItems="center" justifyContent="space-between">
      <Text.B1 color="primary">{label}</Text.B1>
      <GridFlex.Row gap={2}>
        <SelectableIconButton
          onClick={toggleBold}
          icon="Bold"
          isSelected={fontVariantConfig.bold}
          isDisabled={disabled}
        />
        <TextField
          type="number"
          disabled={disabled}
          value={fontVariantConfig.fontSize}
          width={100}
          onChange={(e) => {
            onFontSizeChange(e.target.value);
          }}
          error={false}
          touched={false}
          endAdornment="px"
        />
      </GridFlex.Row>
    </GridFlex.Row>
  );
}

function TypographyTheme({
  widgetConfig,
  widgetIdentifier,
  isReadOnly,
}: {
  widgetConfig: WidgetConfig;
  widgetIdentifier: WidgetIdentifier;
  isReadOnly: boolean;
}) {
  const {
    configuration: { typography },
    onThemeChanged,
  } = widgetConfig;
  const [fontFamily, setFontFamily] = useState(typography.fontFamily);
  const shouldShowBodyText = widgetIdentifier !== WidgetIdentifier.Paywall;

  const onFontFamilyChange = (fontFamilyUrl: string) => {
    setFontFamily(fontFamilyUrl);
    onThemeChanged({ typography: { fontFamily: fontFamilyUrl } });
  };

  useEffect(() => {
    setFontFamily(typography.fontFamily);
  }, [typography.fontFamily]);

  return (
    <GridFlex.Column gap={4}>
      <TextField
        disabled={isReadOnly}
        label="Font family"
        value={fontFamily}
        placeholder="https://"
        onChange={(e) => onFontFamilyChange(e.target.value)}
        error={false}
        errorText="Invalid url"
        touched={false}
        captionText={t('widgets.fontFamilyDescription')}
      />
      <GridFlex.Column gap={2}>
        <FontVariantConfiguration
          disabled={isReadOnly}
          label={t('widgets.h1')}
          initialValue={{ fontSize: typography.h1.fontSize, bold: typography.h1.fontWeight === FontWeight.Bold }}
          onChange={({ fontSize, bold }) => {
            onThemeChanged({
              typography: {
                h1: {
                  fontSize,
                  fontWeight: convertBooleanToFontWeight(bold),
                },
              },
            });
          }}
        />
        <FontVariantConfiguration
          disabled={isReadOnly}
          label={t('widgets.h2')}
          initialValue={{ fontSize: typography.h2.fontSize, bold: typography.h2.fontWeight === FontWeight.Bold }}
          onChange={({ fontSize, bold }) => {
            onThemeChanged({
              typography: { h2: { fontSize, fontWeight: convertBooleanToFontWeight(bold) } },
            });
          }}
        />
        <FontVariantConfiguration
          disabled={isReadOnly}
          label={t('widgets.h3')}
          initialValue={{ fontSize: typography.h3.fontSize, bold: typography.h3.fontWeight === FontWeight.Bold }}
          onChange={({ fontSize, bold }) => {
            onThemeChanged({
              typography: {
                h3: { fontSize, fontWeight: convertBooleanToFontWeight(bold) },
              },
            });
          }}
        />
        {shouldShowBodyText && (
          <FontVariantConfiguration
            disabled={isReadOnly}
            label={t('widgets.bodyText')}
            initialValue={{ fontSize: typography.body.fontSize, bold: typography.body.fontWeight === FontWeight.Bold }}
            onChange={({ fontSize, bold }) => {
              onThemeChanged({
                typography: {
                  body: {
                    fontSize,
                    fontWeight: convertBooleanToFontWeight(bold),
                  },
                },
              });
            }}
          />
        )}
      </GridFlex.Column>
    </GridFlex.Column>
  );
}

export function TypographyThemeSection({
  widgetConfig,
  widgetIdentifier,
}: {
  widgetConfig: WidgetConfig;
  widgetIdentifier: WidgetIdentifier;
}) {
  const [isSectionOpen, setIsSectionOpen] = useState(true);
  const allowWidgetsWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);

  return (
    <CollapsableSection
      title={t('widgets.typographySectionTitle')}
      sx={{
        color: (theme) => theme.itamar.palette.text.primary,
      }}
      $fullWidth
      isSectionOpen={isSectionOpen}
      onClick={() => setIsSectionOpen(!isSectionOpen)}
      content={
        <TypographyTheme
          widgetConfig={widgetConfig}
          widgetIdentifier={widgetIdentifier}
          isReadOnly={!allowWidgetsWrite}
        />
      }
    />
  );
}
