import { AccountAccessRole, Member } from '@stigg-types/apiTypes';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { t } from 'i18next';
import { Icon, Box, Avatar, Chip, Grid, GridFlex, Text } from '@stigg-components';
import { styled } from '@stigg-theme';
import { useFlags } from 'launchdarkly-react-client-sdk';

const AvatarWrapper = styled(Box)`
  width: 18px;
  height: 18px;
  position: absolute;
  right: -4px;
  bottom: -4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.itamar.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    stroke-width: 3;
  }
`;

const OwnerAvatar = () => {
  return (
    <AvatarWrapper>
      <Icon type="custom" icon="OwnerRole" color="white" overrideStroke size={14} />
    </AvatarWrapper>
  );
};

export function MemberWithAvatar({ member, isCurrentMember }: { member: Member; isCurrentMember: boolean }) {
  const { rbacRollout } = useFlags<FeatureFlags>();
  const isOwner = member.accessRoles?.accountRole === AccountAccessRole.Owner;
  return (
    <Grid container flexDirection="row" alignItems="center">
      <Box position="relative">
        <Avatar
          sx={{ bgcolor: (theme) => theme.itamar.palette.action.active }}
          alt="user-avatar"
          src={(member.user && (member.user.profileImageUrl || member.user.name)) || ''}
        />
        {isOwner && rbacRollout && <OwnerAvatar />}
      </Box>
      <GridFlex.Column item ml={4} mr={4}>
        {member.user && (
          <Grid item>
            <Text.B2>
              {member.user && member.user.name}
              {isCurrentMember ? ` ${t('accounts.currentMemberIndication')}` : ''}
            </Text.B2>
            {isOwner && rbacRollout && (
              <Chip
                sx={{ ml: 2 }}
                label="Owner"
                color="disabled"
                textColor="disabled"
                variant="outlined"
                size="small"
              />
            )}
          </Grid>
        )}
        <Grid item>
          <Text.Sub2>{member.email}</Text.Sub2>
        </Grid>
      </GridFlex.Column>
    </Grid>
  );
}
