import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import omit from 'lodash/omit';
import { t } from 'i18next';
import { PlusSquare } from 'react-feather';
import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import { GridFlex, Button, CustomDrawer, EmptyList, Icon, PermissionProtected } from '@stigg-components';
import { useQueryParam } from '@stigg-common';
import { createAddonAction, createAddonDraftAction, fetchAddonsAction, setArchiveDialog } from '../addonSlice';
import { RootState, useAppDispatch } from '../../../../redux/store';
import Packages, { PackageListFragment } from '../../common/components/Packages';
import { useNavigation } from '../../../navigation/useNavigation';
import { CreatePackageForm, CreatePackageFormFields } from '../../common/components/CreatePackage';
import { AddonType } from './AddonTypeValue';
import { ArchiveAddonModal } from './ArchiveAddonModal';

export function AddonsList({ title }: { title?: string }) {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const [createAddonOpen, setCreateAddonOpen] = useState(false);
  const addons = useSelector((state: RootState) => state.addonReducer.addons);
  const isLoading = useSelector((state: RootState) => state.addonReducer.isLoading);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const { value: createQueryParam } = useQueryParam('create');
  const allowAddonWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);

  const onNewAddonClick = () => {
    setCreateAddonOpen(true);
  };

  const onSubmit = (values: CreatePackageFormFields) => {
    const addonMaxQuantity = values.addonType === AddonType.SINGLE ? 1 : undefined;
    void dispatch(createAddonAction({ ...omit(values, 'addonType'), maxQuantity: addonMaxQuantity }));
    setCreateAddonOpen(false);
  };

  const onSearch = useCallback(
    (searchStr: string) => {
      void dispatch(fetchAddonsAction({ search: searchStr, environmentId: currentEnvironmentId }));
    },
    [dispatch, currentEnvironmentId],
  );

  const onEditClick = (addon: PackageListFragment) => {
    if (addon.draftSummary) {
      navigation.navigateTo(navigation.appRoutes.addonPage(addon.refId, addon.draftSummary.version));
    } else {
      void dispatch(createAddonDraftAction(addon.id));
    }
  };

  const onDeleteClick = (addon: PackageListFragment) => {
    dispatch(setArchiveDialog({ addon, isOpen: true }));
  };

  useEffect(() => {
    setCreateAddonOpen(!!createQueryParam);
  }, [createQueryParam]);

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(
        fetchAddonsAction({
          environmentId: currentEnvironmentId,
        }),
      );
    }
  }, [dispatch, currentEnvironmentId]);

  return (
    <GridFlex.Column position="relative">
      {!title && (
        <PermissionProtected permission={EnvironmentPermissionActions.WriteEnvironment}>
          <GridFlex.RowCenter item justifyContent="flex-end" sx={{ position: 'absolute', top: 24, right: 0 }}>
            <Button
              variant="contained"
              color="primary"
              textColor="white"
              onClick={onNewAddonClick}
              data-testid="button-create-new"
              startIcon={<Icon icon="Add" color="white" />}>
              {t('addons.new')}
            </Button>
          </GridFlex.RowCenter>
        </PermissionProtected>
      )}
      <Packages
        title={title}
        isLoading={isLoading}
        packages={addons}
        onNew={onNewAddonClick}
        onRowClick={(addon) => navigation.navigateTo(navigation.appRoutes.addonPage(addon.refId, addon.versionNumber))}
        onSearch={onSearch}
        newButtonText={t('addons.new')}
        fetchAction={fetchAddonsAction}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        emptyState={(isSearching) => (
          <EmptyList
            title={isSearching ? t('addons.emptySearchText') : t('addons.emptyStateText')}
            linkText={isSearching ? '' : t('addons.emptyStateTextAddAddonLink')}
            onLinkClick={allowAddonWrite ? onNewAddonClick : undefined}
            icon={PlusSquare}
          />
        )}
        searchPlaceholder={t('addons.searchPlaceholder')}
        packageType="Add-on"
      />
      <CustomDrawer title={t('addons.create')} isOpen={createAddonOpen} onClose={() => setCreateAddonOpen(false)}>
        <CreatePackageForm
          onCancel={() => setCreateAddonOpen(false)}
          onSubmit={onSubmit}
          refIdPrefix={t('addons.refIdPrefix')}
          packageType="Add-on"
        />
      </CustomDrawer>
      <ArchiveAddonModal />
    </GridFlex.Column>
  );
}
