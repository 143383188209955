import { t } from 'i18next';
import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import { Divider, Grid, GridFlex, PageHeader, Box, Text, Icon } from '@stigg-components';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Experiment } from '@stigg-types/apiTypes';
import { useCallback, useEffect, useState } from 'react';
import { RootState, useAppDispatch } from '../../../redux/store';
import Search from '../../../components/Search';
import Table from '../../../components/table/Table';
import { headCells } from './headCells';
import createPageChangeFunc from '../../../components/table/gqlTableHelper';
import { fetchExperimentsAction, fetchNonActiveExperimentsAction } from '../experimentsSlice';
import { useNavigation } from '../../navigation/useNavigation';
import { ExperimentsEmptyState } from './ExperimentsEmptyState';
import { EmptyList } from '../../../components/EmptyList';
import { Dialog } from '../../../components/Dialog';
import { ExperimentWizard } from './editExperimentWizard/ExperimentWizard';
import { ExperimentGeneralInfo } from './commom/generalInfo/ExperimentGeneralInfo';
import Loader from '../../../components/Loader';
import { ExperimentType } from './commom/ExperimentType';

export function Experiments() {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const [search, setSearch] = useState('');
  const [pageNum, setPageNum] = useState(0);
  const [isExperimentEditingDialogOpen, setIsExperimentWizardOpen] = useState(false);
  const [requestedExperimentType, setRequestedExperimentType] = useState<ExperimentType | undefined>();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const { isLoading, isLoadingNonActiveExperiments, hasNonActiveExperiments, activeExperiments } = useSelector(
    (state: RootState) => state.experimentsReducer.experimentsPage,
  );
  const { pageInfo, totalCount, edges } = useSelector(
    (state: RootState) => state.experimentsReducer.experimentsPage.nonActiveExperiments,
  );
  const allowExperimentWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);

  const pageChangeFunc = createPageChangeFunc(
    dispatch,
    pageNum,
    pageInfo,
    setPageNum,
    fetchNonActiveExperimentsAction,
    {
      environmentId: currentEnvironmentId,
      search,
    },
  );

  const onCreateExperimentClick = (experimentType: ExperimentType | undefined) => {
    setRequestedExperimentType(experimentType);
    setIsExperimentWizardOpen(true);
  };

  const closeExperimentEditingDialog = () => {
    setIsExperimentWizardOpen(false);
    setRequestedExperimentType(undefined);
  };
  const onSearch = useCallback(
    (searchTerm: string) => {
      setSearch(searchTerm);
      if (searchTerm) {
        setPageNum(0);
      }
      void dispatch(fetchNonActiveExperimentsAction({ search: searchTerm }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(fetchExperimentsAction({}));
      void dispatch(fetchNonActiveExperimentsAction({}));
    }
  }, [dispatch, currentEnvironmentId]);

  if (isLoading) {
    return <Loader />;
  }

  const showFirstTimeEmptyState = !hasNonActiveExperiments && activeExperiments.length === 0;

  return (
    <>
      <PageHeader
        title={t('experiments.experiments')}
        buttonTitle={t('experiments.new')}
        buttonClick={() => onCreateExperimentClick(undefined)}
        hideButton={!allowExperimentWrite || showFirstTimeEmptyState}
      />
      <Divider my={4} />
      {activeExperiments.length > 0 && (
        <>
          {activeExperiments.map((experiment) => (
            <Box key={experiment.id} mt={8}>
              <ExperimentGeneralInfo experiment={experiment} linkToExperimentPage />
            </Box>
          ))}
          {hasNonActiveExperiments && <Text.H3 mt={8}>{t('experiments.additionalExperiments')}</Text.H3>}
        </>
      )}
      <GridFlex.Column container mb={10}>
        {allowExperimentWrite && showFirstTimeEmptyState && (
          <ExperimentsEmptyState onCreateClick={onCreateExperimentClick} />
        )}
        {(!allowExperimentWrite || hasNonActiveExperiments) && (
          <>
            <Grid my={4} maxWidth={400}>
              <Search
                variant="outlined"
                placeholder={t('experiments.searchPlaceholder')}
                handleSearchFunc={onSearch}
                searchedStr={search}
                searchOnChange
              />
            </Grid>
            {isEmpty(edges) && !isLoadingNonActiveExperiments ? (
              <EmptyList
                title={t('experiments.emptySearchText')}
                icon={() => <Icon type="materialIcons" icon="ScienceOutlined" color="default" />}
              />
            ) : (
              <Table
                isLoading={isLoadingNonActiveExperiments}
                headCells={headCells()}
                label={t('experiments.table')}
                pageChangeFunc={pageChangeFunc}
                rowHeight={75}
                data={(edges || []).map((item) => item.node)}
                pageNum={pageNum}
                totalCount={totalCount}
                onRowClick={(experiment: Experiment) =>
                  navigation.navigateTo(navigation.appRoutes.experimentPage(experiment.refId))
                }
              />
            )}
          </>
        )}
      </GridFlex.Column>
      <Dialog
        fullScreen
        isOpen={isExperimentEditingDialogOpen}
        onCancel={closeExperimentEditingDialog}
        content={
          <ExperimentWizard
            closeWizard={closeExperimentEditingDialog}
            requestedExperimentType={requestedExperimentType}
          />
        }
        padding={0}
      />
    </>
  );
}
