import { LoadingButton, Divider, GridFlex, Icon } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';

export type StepActionButtonsProps = {
  hideContinueButton: boolean;
  onContinue: () => Promise<void>;
  hasErrors?: boolean;
  additionalActionButtons?: React.ReactNode;
  nextButtonText?: string;
  nextDisabled?: boolean;
  withNextIcon?: boolean;
};

export function StepActionButtons({
  hideContinueButton,
  onContinue,
  hasErrors,
  additionalActionButtons,
  nextButtonText,
  nextDisabled,
  withNextIcon,
}: StepActionButtonsProps) {
  const [loading, setLoading] = React.useState(false);
  if (hideContinueButton && !additionalActionButtons) {
    return null;
  }

  const onClick = async () => {
    setLoading(true);
    await onContinue();
    setLoading(false);
  };

  return (
    <>
      <Divider mt={5} mb={6} />
      <GridFlex.RowSpaceBetween
        {...(hideContinueButton && additionalActionButtons ? { justifyContent: 'flex-end' } : {})}>
        <GridFlex.Row>{additionalActionButtons}</GridFlex.Row>
        {!hideContinueButton && (
          <LoadingButton
            variant="contained"
            color="primary"
            data-testid="button-continue"
            onClick={onClick}
            loading={loading}
            disabled={hasErrors || nextDisabled}
            endIcon={withNextIcon ? <Icon icon="ArrowForward" /> : undefined}>
            {nextButtonText || t('subscriptions.schedules.continue')}
          </LoadingButton>
        )}
      </GridFlex.RowSpaceBetween>
    </>
  );
}
