import axios from 'axios';
import { useEffect } from 'react';
import { GridFlex } from '@stigg-components';
import { Account } from '@stigg-types/apiTypes';
import { useQueryParam, useModal } from '@stigg-common';
import AccountEnvironments from './environments/AccountEnvironments';
import { AccountSettings } from './AccountSettings';
import SingleSignOnSettings from './SingleSignOnSettings';
import { useCurrentMember } from '../../auth/hooks/useCurrentMember';

export function AccountLayout({ account }: { account: Account | null }) {
  const currentMember = useCurrentMember();
  const [CliAuthModal, setCliAuthModalOpen, cliAuthModalOpen] = useModal({});
  const { value: shouldPerformCliAuthAuth } = useQueryParam('auth');

  useEffect(() => {
    if (shouldPerformCliAuthAuth === 'true') {
      setCliAuthModalOpen(true);
    }
  }, [shouldPerformCliAuthAuth, setCliAuthModalOpen]);
  // we are using this to preauthenticate our stiggd CLI with the specific members serviceApiKey.
  useEffect(() => {
    if (cliAuthModalOpen && currentMember) {
      void axios.post(
        `http://localhost:8131/authcallback?apikey=${currentMember.serviceApiKey}`,
        {},
        {
          headers: { 'Content-type': 'application/json', Connection: 'keep-alive', Accept: '*/*' },
        },
      );

      setTimeout(() => setCliAuthModalOpen(false), 500);
    }
  }, [cliAuthModalOpen, currentMember, setCliAuthModalOpen]);

  return (
    <GridFlex.Column wrap="nowrap" gap={4}>
      <AccountSettings account={account} />
      <AccountEnvironments />
      <SingleSignOnSettings />
      <CliAuthModal>Authenticating CLI........</CliAuthModal>
    </GridFlex.Column>
  );
}
