import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { drawFormFields, Field, GridFlex, Icon, Text } from '@stigg-components';
import { PlanFragment, PricingType, TrialEndBehavior } from '@stigg-types/apiTypes';
import { SetTrialReferenceToCustomerJourney } from './SetTrialReferenceToCustomerJourney';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { SetPriceWizardFormFields } from '../SetPriceWizardForm.types';
import { TrialSpendLimit } from './TrialSpendLimit';
import { DEFAULT_SUBSCRIPTION_FORM_COMPONENT_WIDTH } from '../../../../../../customers/components/customerPage/customerSubscriptions/subscriptionForm/components/consts';

const GRID_WIDTH = 450;

export function FreeTrialStep() {
  const { formRenderProps, aPackage } = useSetPriceWizardFormContext();
  const { trialConvertToPaidRollout: isTrialConvertToPaidEnabled, budgetCap: isBudgetCapEnabled } =
    useFlags<FeatureFlags>();

  const {
    values: { pricingType },
  } = formRenderProps;

  const shouldShowFreeTrial =
    aPackage.type === 'Plan' && (pricingType === PricingType.Paid || pricingType === PricingType.Custom);
  if (!shouldShowFreeTrial) {
    return null;
  }

  const plan = aPackage as PlanFragment;

  const fields: Field<SetPriceWizardFormFields>[] = [
    {
      type: 'switch',
      id: 'freeTrial.enabled',
      label: t('pricing.trials.startInFreeTrial'),
    },
    {
      type: 'idle',
      component: <Text.B2 color="disabled">{t('pricing.trials.startInFreeTrialNote')}</Text.B2>,
      gridProps: {
        ml: 12,
      },
    },
    {
      type: 'layout',
      hide: (values) => !values.freeTrial.enabled,
      gridProps: {
        mt: 6,
        ml: 11,
        width: GRID_WIDTH,
        gap: 9,
      },
      contentGridProps: {
        alignItems: 'center',
      },
      labelGridProps: {
        width: 140,
        mr: 8,
      },
      label: (
        <GridFlex.RowCenter gap={2}>
          <Icon type="custom" icon="HourglassEmpty" size={24} color="default" overrideStroke />
          <Text.B2>{t('pricing.trials.trialPeriod')}</Text.B2>
        </GridFlex.RowCenter>
      ),
      fields: [
        {
          type: 'text',
          id: 'freeTrial.durationDays',
          textFieldType: 'number',
          isNumberWithoutSigns: true,
          isNumberWithoutFraction: true,
          endAdornment: t('pricing.trials.days'),
          textFieldProps: {
            InputProps: {
              sx: {
                width: DEFAULT_SUBSCRIPTION_FORM_COMPONENT_WIDTH,
              },
            },
          },
        },
      ],
    },
    {
      type: 'layout',
      hide: (values) => !values.freeTrial.enabled || !isTrialConvertToPaidEnabled,
      gridProps: {
        mt: 6,
        ml: 11,
        width: GRID_WIDTH,
        gap: 9,
      },
      contentGridProps: {
        alignItems: 'center',
      },
      labelGridProps: {
        width: 140,
        mr: 8,
      },
      label: (
        <GridFlex.RowCenter gap={2} ml={8}>
          <Text.B2>{t('subscriptionForm.freeTrial.trialEndBehavior.label')}</Text.B2>
        </GridFlex.RowCenter>
      ),
      fields: [
        {
          id: 'freeTrial.trialEndBehavior',
          type: 'select',
          values: [TrialEndBehavior.CancelSubscription, TrialEndBehavior.ConvertToPaid].map((value) => ({
            key: value,
            value,
            displayValue: t(`subscriptionForm.freeTrial.trialEndBehavior.options.${value}`),
          })),
          restProps: {
            width: DEFAULT_SUBSCRIPTION_FORM_COMPONENT_WIDTH,
          },
        },
      ],
    },
    {
      type: 'layout',
      hide: (values) => !isBudgetCapEnabled || !values.freeTrial.enabled,
      gridProps: {
        mt: 6,
        ml: 11,
        gap: 9,
      },
      contentGridProps: {
        alignItems: 'center',
      },
      labelGridProps: {
        width: 140,
        mr: 8,
      },
      label: (
        <GridFlex.RowCenter gap={2}>
          <Icon icon="Charge" type="custom" size={24} color="default" overrideStroke />
          <Text.B2>{t('pricing.trials.trialSpendLimit')}</Text.B2>
        </GridFlex.RowCenter>
      ),
      fields: [
        {
          type: 'custom',
          render: () => <TrialSpendLimit />,
        },
      ],
    },
    {
      type: 'idle',
      hide: (values) => !values.freeTrial.enabled || isTrialConvertToPaidEnabled,
      component: <SetTrialReferenceToCustomerJourney plan={plan} />,
      gridProps: {
        mt: 8,
      },
    },
  ];

  return <>{drawFormFields(fields, formRenderProps)}</>;
}
