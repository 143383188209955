import { capitalize, isEmpty, partition } from 'lodash';
import { t } from 'i18next';
import { TextColor } from '@stigg-theme';
import {
  BoldDivider,
  Box,
  Divider,
  DottedText,
  Grid,
  GridFlex,
  Icon,
  InformationTooltip,
  Text,
} from '@stigg-components';
import {
  BillingCadence,
  BudgetConfigurationInput,
  CouponFragment,
  SubscriptionCouponDataFragment,
  TrialEndBehavior,
} from '@stigg-types/apiTypes';
import moment from 'moment';
import { formatDate } from '@stigg-common';
import { getAddonPriceBreakdownString, PriceBreakdown, useTotalPrice } from './priceBreakdown';
import {
  formatCouponDurationForSubscriptionPreview,
  formatCouponNameForSubscriptionPreview,
  formatCouponValue,
} from '../../../../coupons/components/utils';
import { PlanPriceBreakdownItem } from './priceBreakdown/PlanPriceBreakdownItem';
import { currencyPriceFormatter } from '../../../../packages/pricing/components/currency/currencyUtils';
import { SubscriptionCouponConfiguration } from './subscriptionForm/SubscriptionForm.types';
import { CouponStartOption } from './subscriptionForm/components/coupon/FutureStartInput';

export const SUBSCRIPTION_PREVIEW_MIN_WIDTH = 400;
export const SUBSCRIPTION_PREVIEW_MAX_WIDTH = 550;

export type SubscriptionPriceBreakdownProps = {
  priceBreakdown: PriceBreakdown;
  showMonthlyPriceVariation: boolean;
  textColor?: TextColor;
  spacing?: number;
  coupon?: CouponFragment | SubscriptionCouponDataFragment | undefined | null;
  couponConfiguration?: SubscriptionCouponConfiguration;
  showOneOffCharges?: boolean;
  planName?: string;
  budget?: Partial<BudgetConfigurationInput>;
  trialConfiguration?: {
    startDate: Date;
    endDate: Date;
    trialEndBehavior?: TrialEndBehavior;
  };
};

export function SubscriptionPriceBreakdown({
  priceBreakdown,
  showMonthlyPriceVariation,
  textColor,
  spacing = 2,
  coupon,
  couponConfiguration,
  showOneOffCharges = false,
  planName,
  trialConfiguration,
  budget,
}: SubscriptionPriceBreakdownProps) {
  const { total, totalDiscount, minimumSpend, currency, oneOffTotal, oneOffTotalDiscount } = useTotalPrice({
    priceBreakdown,
    withCodePostfix: true,
  });

  const previewPlanName = planName ? `${planName} ${t('priceBreakdown.planChargesTitleSuffix')}` : undefined;

  const { hasPayAsYouGoPrice, planBreakdown, addonsBreakDown } = priceBreakdown;
  const [recurringAddonBreakdown, oneOffAddonBreakdown] = partition(
    addonsBreakDown,
    (addonBreakdown) => addonBreakdown?.price.billingCadence === BillingCadence.Recurring,
  );
  const hasOneOffCharges = !isEmpty(oneOffAddonBreakdown);
  const isTrial = !!trialConfiguration;
  const showOneOffSection = showOneOffCharges && hasOneOffCharges;
  const showRecurringTotal = !isTrial || showOneOffSection;

  return (
    <Grid container flexDirection="column" rowSpacing={spacing}>
      {showOneOffSection && (
        <GridFlex.Row item mb={2}>
          <Text.B1>{capitalize(t('priceBreakdown.recurringCharges'))}</Text.B1>
        </GridFlex.Row>
      )}

      <GridFlex.RowMiddle item>
        <Text.Caption pr={2}>
          {t('priceBreakdown.planChargesTitle', { planName: previewPlanName ?? 'Plan' })}
        </Text.Caption>
        <Divider my={2} sx={{ flex: 1 }} />
      </GridFlex.RowMiddle>

      <GridFlex.Column item gap={spacing}>
        {planBreakdown.prices?.map((priceWithQuantity) => (
          <PlanPriceBreakdownItem
            key={priceWithQuantity.price.featureId || priceWithQuantity.price.id}
            priceWithQuantity={priceWithQuantity}
            textColor={textColor}
            showMonthlyPriceVariation={showMonthlyPriceVariation}
            spacing={spacing}
          />
        ))}
      </GridFlex.Column>

      {minimumSpend && (
        <GridFlex.RowSpaceBetween item>
          <GridFlex.RowCenter item mr={4} minWidth={105} gap={2} flexWrap="wrap">
            <Text.B2 color={textColor || 'secondary'}>{t('priceBreakdown.minimumSpend')}</Text.B2>
          </GridFlex.RowCenter>
          <Text.B2
            color={textColor || 'secondary'}
            textAlign="right"
            whiteSpace="nowrap"
            data-testid="subscription-price-minimum-spend">
            {minimumSpend}
          </Text.B2>
        </GridFlex.RowSpaceBetween>
      )}

      {!isEmpty(recurringAddonBreakdown) && (
        <>
          <GridFlex.RowMiddle item mt={2}>
            <Text.Caption pr={2}>{t('priceBreakdown.addonsTitle')}</Text.Caption>
            <Divider my={2} sx={{ flex: 1 }} />
          </GridFlex.RowMiddle>
          <GridFlex.Column item gap={spacing}>
            {recurringAddonBreakdown.map((addonBreakdown) =>
              addonBreakdown ? (
                <GridFlex.RowSpaceBetween alignItems="flex-start" key={addonBreakdown.addon.price?.id}>
                  <Text.B2 color={textColor || 'secondary'} mr={4}>
                    {addonBreakdown.addon.displayName}
                  </Text.B2>
                  <Text.B2 color={textColor || 'secondary'} textAlign="right">
                    {getAddonPriceBreakdownString(addonBreakdown, showMonthlyPriceVariation)}
                  </Text.B2>
                </GridFlex.RowSpaceBetween>
              ) : null,
            )}
          </GridFlex.Column>
        </>
      )}

      {coupon && (
        <GridFlex.Column gap={2}>
          <GridFlex.RowMiddle mt={2}>
            <Text.Caption pr={2}>{t('priceBreakdown.discount')}</Text.Caption>
            <Divider my={2} sx={{ flex: 1 }} />
          </GridFlex.RowMiddle>
          <GridFlex.RowSpaceBetween alignItems="flex-start">
            <GridFlex.Column gap={1}>
              <Text.B2 color={textColor || 'secondary'} mr={4}>
                {formatCouponNameForSubscriptionPreview(coupon, currency)}
              </Text.B2>
              <Text.Sub2 color={textColor || 'secondary'} mr={2}>
                {formatCouponDurationForSubscriptionPreview(coupon, couponConfiguration)}
              </Text.Sub2>
            </GridFlex.Column>
            <Text.B2 color={textColor || 'secondary'} textAlign="right">
              {couponConfiguration?.startConfiguration === CouponStartOption.Later
                ? t('subscriptionForm.discount.scheduled')
                : totalDiscount}
            </Text.B2>
          </GridFlex.RowSpaceBetween>
        </GridFlex.Column>
      )}

      {showRecurringTotal && (
        <>
          <GridFlex.Column item>
            <Divider my={3} />
          </GridFlex.Column>
          <GridFlex.RowSpaceBetween item>
            <GridFlex.RowCenter item mr={4} minWidth={105} gap={2} flexWrap="wrap">
              <Text.B2 $bold color={textColor || 'primary'}>
                {t('priceBreakdown.total')} {hasOneOffCharges ? t('priceBreakdown.recurringCharges') : ''}
              </Text.B2>
            </GridFlex.RowCenter>
            <Text.B2
              $bold
              color={textColor || 'primary'}
              textAlign="right"
              whiteSpace="nowrap"
              data-testid="subscription-price-total">
              {total}
            </Text.B2>
          </GridFlex.RowSpaceBetween>
        </>
      )}

      {showOneOffSection && (
        <>
          <GridFlex.Column item>
            <BoldDivider my={3} />
          </GridFlex.Column>
          <GridFlex.Column item mb={2}>
            <Text.B1>{capitalize(t('priceBreakdown.oneOffCharges'))}</Text.B1>
          </GridFlex.Column>

          <GridFlex.RowMiddle item>
            <Text.Caption pr={2}>{t('priceBreakdown.addonsTitle')}</Text.Caption>
            <Divider my={2} sx={{ flex: 1 }} />
          </GridFlex.RowMiddle>

          <GridFlex.Column item gap={spacing}>
            {oneOffAddonBreakdown.map((addonBreakdown) =>
              addonBreakdown ? (
                <GridFlex.RowSpaceBetween item alignItems="flex-start" key={addonBreakdown.addon.displayName}>
                  <Text.B2 color={textColor || 'secondary'} mr={4}>
                    {addonBreakdown.addon.displayName}
                  </Text.B2>
                  <Text.B2 color={textColor || 'secondary'} textAlign="right">
                    {getAddonPriceBreakdownString(addonBreakdown, showMonthlyPriceVariation)}
                  </Text.B2>
                </GridFlex.RowSpaceBetween>
              ) : null,
            )}
          </GridFlex.Column>

          {coupon && (
            <>
              <GridFlex.RowMiddle item mt={2}>
                <Text.Caption pr={2}>{t('priceBreakdown.discount')}</Text.Caption>
                <Divider my={2} sx={{ flex: 1 }} />
              </GridFlex.RowMiddle>
              <GridFlex.RowSpaceBetween item>
                <GridFlex.RowCenter item>
                  <Text.B2 color={textColor || 'secondary'} mr={2}>
                    {formatCouponValue(coupon.discountValue, coupon.type, currency, coupon.durationInMonths)}
                  </Text.B2>
                </GridFlex.RowCenter>
                <Text.B2 color={textColor || 'secondary'} textAlign="right">
                  {oneOffTotalDiscount}
                </Text.B2>
              </GridFlex.RowSpaceBetween>
            </>
          )}

          <GridFlex.Column item>
            <Divider my={3} />
          </GridFlex.Column>

          <GridFlex.RowSpaceBetween item>
            <GridFlex.RowCenter item mr={4} minWidth={105} gap={2} flexWrap="wrap">
              <Text.B2 $bold color={textColor || 'primary'}>
                {capitalize(t('priceBreakdown.total'))} {t('priceBreakdown.oneOffCharges')}
              </Text.B2>
            </GridFlex.RowCenter>
            <Text.B2
              $bold
              color={textColor || 'primary'}
              textAlign="right"
              whiteSpace="nowrap"
              data-testid="subscription-price-total-one-off">
              {oneOffTotal}
            </Text.B2>
          </GridFlex.RowSpaceBetween>
        </>
      )}

      {isTrial && (
        <>
          <GridFlex.Column item>{showOneOffSection ? <BoldDivider my={3} /> : <Divider my={3} />}</GridFlex.Column>
          <GridFlex.Column item mb={2}>
            <Text.B1>{t('subscriptionForm.trialSectionTitle')}</Text.B1>
          </GridFlex.Column>
          <GridFlex.RowSpaceBetween item gap={spacing}>
            <Text.B2 color={textColor || 'secondary'}>{t('subscriptionForm.freeTrial.label')}</Text.B2>
            <Text.B2 color={textColor || 'secondary'}>
              <InformationTooltip
                $padding={2}
                arrow
                placement="top"
                title={
                  <Text.B2>
                    {t('priceBreakdown.trialPeriodEndsAt', { date: formatDate(trialConfiguration.endDate) })}
                  </Text.B2>
                }>
                <Box>
                  <DottedText color={textColor || 'secondary'}>
                    {t('priceBreakdown.trialPeriod', {
                      count: moment(trialConfiguration.endDate).diff(trialConfiguration.startDate, 'days'),
                    })}
                  </DottedText>
                </Box>
              </InformationTooltip>
            </Text.B2>
          </GridFlex.RowSpaceBetween>
          {budget?.limit && (
            <GridFlex.RowSpaceBetween item gap={spacing}>
              <Text.B2 color={textColor || 'secondary'}>
                {t('subscriptionForm.budgetSpendingConfiguration.maximumSpend.label')}
              </Text.B2>
              <Text.B2 color={textColor || 'secondary'}>
                {currencyPriceFormatter({ amount: budget.limit, currency })}
              </Text.B2>
            </GridFlex.RowSpaceBetween>
          )}
          {trialConfiguration.trialEndBehavior === TrialEndBehavior.ConvertToPaid && (
            <GridFlex.RowSpaceBetween item gap={spacing}>
              <Text.B2 color={textColor || 'secondary'}>{t('priceBreakdown.totalAfterTrial')}</Text.B2>
              <Text.B2 color={textColor || 'secondary'}>{total}</Text.B2>
            </GridFlex.RowSpaceBetween>
          )}
          <GridFlex.Column item>
            <Divider my={3} />
          </GridFlex.Column>
          <GridFlex.RowSpaceBetween item gap={spacing}>
            <Text.B2 $bold color={textColor || 'primary'}>
              {t('priceBreakdown.totalDueToday')}
            </Text.B2>
            <Text.B2 $bold color={textColor || 'primary'}>
              {currencyPriceFormatter({
                amount: 0,
                currency,
                options: { withCodePostfix: true },
              })}
            </Text.B2>
          </GridFlex.RowSpaceBetween>
        </>
      )}

      <GridFlex.Column item mt={4}>
        {hasPayAsYouGoPrice && (
          <Text.Caption color="secondary" display="inline-flex" alignItems="center" gap={1}>
            <Icon icon="PayAsYouGoCharge" type="custom" />
            {t('priceBreakdown.payAsYouGoText')}
          </Text.Caption>
        )}

        <Text.Caption color="secondary" display="inline-flex" gap={1}>
          <Icon icon="Asterisk" type="custom" />
          {t('priceBreakdown.additionalTaxesMayApplyText')}
        </Text.Caption>
      </GridFlex.Column>
    </Grid>
  );
}

export function SubscriptionPriceBreakdownTooltip({
  children,
  priceBreakdown,
  showMonthlyPriceVariation,
  coupon,
}: {
  children: any;
  priceBreakdown: PriceBreakdown;
  showMonthlyPriceVariation: boolean;
  coupon?: CouponFragment | SubscriptionCouponDataFragment | null;
}) {
  return (
    <InformationTooltip
      PopperProps={{ onClick: (e) => e.stopPropagation() }}
      arrow
      placement="top"
      $maxWidth={600}
      title={
        <Box minWidth={SUBSCRIPTION_PREVIEW_MIN_WIDTH} maxWidth={SUBSCRIPTION_PREVIEW_MAX_WIDTH}>
          <Text.B2 mb={4} fontWeight={500}>
            {t('pricing.pricingBreakdown')}
          </Text.B2>

          <Grid item>
            <SubscriptionPriceBreakdown
              priceBreakdown={priceBreakdown}
              coupon={coupon}
              showMonthlyPriceVariation={!!showMonthlyPriceVariation}
            />
          </Grid>
        </Box>
      }>
      {children}
    </InformationTooltip>
  );
}
