import { Edit2 } from 'react-feather';
import { t } from 'i18next';
import moment from 'moment-timezone';
import { Account, UpdateAccountInput } from '@stigg-types/apiTypes';
import { Button, DetailsLayout, Grid, PageCard, GridFlex, Text } from '@stigg-components';
import { formatTimezone } from '@stigg-common';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useAccountPermissionCheck } from '@stigg-permissions';
import { omit } from 'lodash';
import { useModal } from '../../common';
import { useAppDispatch } from '../../../redux/store';
import { updateAccountAction } from '../accountsSlice';
import { EditAccountSettings } from './EditAccountSettings';
import { AccountPermissionActions } from '../../rbac/consts';

export function AccountSettings({ account }: { account?: Account | null }) {
  const dispatch = useAppDispatch();
  const [EditAccountSettingsModal, setIsEditModalOpen] = useModal({ title: t('accounts.settings') });
  const { subscriptionUpdateProrationBehavior } = useFlags<FeatureFlags>();
  const allowEditAccount = useAccountPermissionCheck(AccountPermissionActions.BillingAccess);

  const onSubmit = async (input: Account) => {
    const inputToSubmit: UpdateAccountInput = omit(input, ['id']);
    await dispatch(updateAccountAction(inputToSubmit));
  };

  return (
    <PageCard>
      <GridFlex.RowSpaceBetween>
        <Text.H3 mb={6}>{t('accounts.settings')}</Text.H3>

        <Grid alignSelf="flex-start" item>
          {allowEditAccount && (
            <Button $outlined color="primary" onClick={() => setIsEditModalOpen(true)} startIcon={<Edit2 size="16" />}>
              {t('accounts.edit')}
            </Button>
          )}
        </Grid>
      </GridFlex.RowSpaceBetween>

      {account && (
        <DetailsLayout
          titleWidth={300}
          details={[
            {
              title: t('accounts.displayName'),
              content: account.displayName,
            },
            {
              title: t('accounts.accountId'),
              content: account.id,
            },
            {
              title: t('accounts.timezone'),
              content: formatTimezone(account.timezone || moment.tz.guess()),
            },
            {
              title: t('accounts.subscriptionBillingAnchor'),
              content: t(`accounts.subscriptionBillingAnchorDescription.${account.subscriptionBillingAnchor}`),
            },
            {
              title: t('accounts.subscriptionUpdateProrationBehavior'),
              content: t(`accounts.subscriptionProrationBehaviorDescription.${account.subscriptionProrationBehavior}`),
              shouldPrintLine: subscriptionUpdateProrationBehavior && !!account.subscriptionProrationBehavior,
            },
          ]}
        />
      )}
      {account && (
        <EditAccountSettingsModal>
          <EditAccountSettings
            account={account}
            onCancel={() => setIsEditModalOpen(false)}
            onSubmit={onSubmit}
            showProrationBehavior={subscriptionUpdateProrationBehavior}
          />
        </EditAccountSettingsModal>
      )}
    </PageCard>
  );
}
