import { t } from 'i18next';
import { EnvironmentPermissionActions } from '@stigg-permissions';
import { EmptyContent, Text, Link, PaymentDetails, PermissionProtected } from '@stigg-components';
import { CustomerResponseFragment } from '@stigg-types/apiTypes';

export type CustomerPaymentMethodProps = {
  customer: CustomerResponseFragment;
  onAddPaymentMethodClick: () => void;
  onDetachPaymentClick: () => void;
};

export function CustomerPaymentMethod({
  customer,
  onAddPaymentMethodClick,
  onDetachPaymentClick,
}: CustomerPaymentMethodProps) {
  const {
    defaultPaymentMethodId,
    defaultPaymentMethodType,
    defaultPaymentMethodLast4Digits,
    defaultPaymentExpirationMonth,
    defaultPaymentExpirationYear,
  } = customer;

  if (!defaultPaymentMethodId) {
    return (
      <EmptyContent $padding={2}>
        <Text.B2>{t('customers.paymentMethod.noValue')}</Text.B2>
        <PermissionProtected permission={EnvironmentPermissionActions.CustomerWrite}>
          <Link ml={2} onClick={onAddPaymentMethodClick}>
            {t('customers.paymentMethod.addPaymentMethod')}
          </Link>
        </PermissionProtected>
      </EmptyContent>
    );
  }

  return (
    <PaymentDetails
      paymentMethodType={defaultPaymentMethodType}
      last4Digits={defaultPaymentMethodLast4Digits}
      expirationMonth={defaultPaymentExpirationMonth}
      expirationYear={defaultPaymentExpirationYear}
      onDetachPaymentClick={onDetachPaymentClick}
    />
  );
}
