import { GridFlex, PermissionProtected, Text } from '@stigg-components';
import { EnvironmentPermissionActions } from '@stigg-permissions';
import { ExperimentFragment } from '@stigg-types/apiTypes';
import { ExperimentChip } from '../ExperimentChip';
import { useNavigation } from '../../../../navigation/useNavigation';
import { ClipboardId } from '../../../../../components/clipboard/ClipboardId';
import { ExperimentsOperationBar } from './operations/ExperimentsOperationBar';

type ExperimentHeaderLayoutProps = {
  experiment: ExperimentFragment;
  linkToExperimentPage?: boolean;
};

export function ExperimentHeaderLayout({ experiment, linkToExperimentPage }: ExperimentHeaderLayoutProps) {
  const navigation = useNavigation();
  const navigateToExperiment = () =>
    linkToExperimentPage && navigation.navigateTo(navigation.appRoutes.experimentPage(experiment.refId));
  return (
    <GridFlex.Row columnGap={2} alignItems="flex-start">
      <GridFlex.Column flex={1} rowGap={2}>
        <GridFlex.RowCenter columnGap={2}>
          <Text.H3 onClick={navigateToExperiment} sx={{ cursor: linkToExperimentPage ? 'pointer' : 'auto' }}>
            {experiment.name}
          </Text.H3>
          <ExperimentChip status={experiment.status} />
        </GridFlex.RowCenter>
        {experiment.description && <Text.Sub2>{experiment.description}</Text.Sub2>}
        <ClipboardId id={experiment.refId} />
      </GridFlex.Column>
      <PermissionProtected permission={EnvironmentPermissionActions.WriteEnvironment}>
        <ExperimentsOperationBar experiment={experiment} />
      </PermissionProtected>
    </GridFlex.Row>
  );
}
