import { t } from 'i18next';
import { omit } from 'lodash';
import { Environment } from '@stigg-types/apiTypes';
import { CustomDrawer } from '@stigg-components';
import EnvironmentDetailsForm, { EnvironmentFormFields } from './EnvironmentDetailsForm';
import { addEnvironmentAction, updateEnvironmentAction } from '../../accountsSlice';
import { useAppDispatch } from '../../../../redux/store';
import { useSwitchEnvironment } from '../../../navigation/useSwitchEnvironment';

interface EnvironmentDetailsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  environment?: Environment;
  onSubmit: (values: EnvironmentFormFields) => Promise<void>;
  title: string;
  description?: string;
}

export function EnvironmentDetailsDrawer({
  isOpen,
  onClose,
  environment,
  onSubmit,
  title,
  description,
}: EnvironmentDetailsDrawerProps) {
  return (
    <CustomDrawer title={title} isOpen={isOpen} onClose={onClose} description={description}>
      <EnvironmentDetailsForm onSubmit={onSubmit} onCancel={onClose} environment={environment} />
    </CustomDrawer>
  );
}

export function AddEnvironmentDrawer({ isOpen, onClose }: Pick<EnvironmentDetailsDrawerProps, 'isOpen' | 'onClose'>) {
  const dispatch = useAppDispatch();
  const switchEnvironment = useSwitchEnvironment();

  const handleAddEnvironment = async (values: EnvironmentFormFields) => {
    try {
      const newEnvironment = await dispatch(addEnvironmentAction({ environment: values })).unwrap();
      onClose();
      switchEnvironment(newEnvironment.id);
    } catch (err) {
      // handle error silently and prevent unhandled promise rejection
    }
  };

  return (
    <EnvironmentDetailsDrawer
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleAddEnvironment}
      title={t('accounts.addEnv')}
      description={t('accounts.addEnvsText')}
    />
  );
}

export function EditEnvironmentDrawer({
  isOpen,
  onClose,
  environment,
}: Pick<EnvironmentDetailsDrawerProps, 'isOpen' | 'onClose' | 'environment'>) {
  const dispatch = useAppDispatch();

  if (!environment) {
    return null;
  }

  const handleEditEnvironment = async (values: EnvironmentFormFields) => {
    await dispatch(updateEnvironmentAction({ environmentId: environment.id, updatePayload: omit(values, 'type') }));
    onClose();
  };

  return (
    <EnvironmentDetailsDrawer
      isOpen={isOpen}
      onClose={onClose}
      environment={environment}
      onSubmit={handleEditEnvironment}
      title={t('accounts.editEnv')}
    />
  );
}
