import { t } from 'i18next';
import { Trans } from 'react-i18next';
import {
  Flex,
  Text,
  Icon,
  ExternalLink,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  DialogActionsButtons,
  Alert,
  CustomDrawer,
} from '@stigg-components';
import { Environment } from '@stigg-types/apiTypes';
import React, { useState, useEffect } from 'react';
import { useModal } from '@stigg-common';
import { updateEnvironmentAction } from '../../accountsSlice';
import { useAppDispatch } from '../../../../redux/store';
import { ClipboardChip } from '../../../../components/clipboard/ClipboardChip';
import { truncateApiKey } from '../../utils/truncate';

interface ClientSideSecurityDrawerProps {
  open: boolean;
  onClose: () => void;
  environment: Environment | null;
}

const DOCS_LINK = 'https://docs.stigg.io/docs/enable-client-side-security';

export const ClientSideSecurityDrawer = ({ open, onClose, environment }: ClientSideSecurityDrawerProps) => {
  const [clientSideSecuritySet, setClientSideSecuritySet] = useState(!!environment?.hardenClientAccessEnabled);
  const [ConfirmationModal, setConfirmationModalOpen] = useModal({
    title: clientSideSecuritySet
      ? t('accounts.clientSideSecurityConfig.enforceModalHeader')
      : t('accounts.clientSideSecurityConfig.disableEnforceModalHeader'),
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    setClientSideSecuritySet(!!environment?.hardenClientAccessEnabled);
  }, [environment?.hardenClientAccessEnabled, open, setClientSideSecuritySet]);

  const hasChanges = clientSideSecuritySet !== environment?.hardenClientAccessEnabled;

  return (
    <CustomDrawer isOpen={open} title={t('accounts.clientSideSecurity')} onClose={onClose} variant="persistent">
      {environment && (
        <>
          <Flex.Column $fullHeight>
            <Text.B2>{t('accounts.clientSideSecurityConfig.description')}</Text.B2>
            <Text.H6 sx={{ mt: 8 }}>{t('accounts.clientSideSecurityConfig.preparationHeader')}</Text.H6>
            <Text.B2 sx={{ my: 4 }}>
              <Trans
                i18nKey="accounts.clientSideSecurityConfig.preparationBody"
                components={{ strong: <Text.B2 display="inline" $medium /> }}
              />
            </Text.B2>
            <ClipboardChip
              copy={environment.signingToken}
              text={truncateApiKey(environment.signingToken)}
              variant="code"
              iconLocation="right"
              iconSize={24}
              spaceIcon
              padding={2}
            />
            <Text.B2 sx={{ mt: 4, mb: 6 }}>
              <Trans
                i18nKey="accounts.clientSideSecurityConfig.token"
                components={{ strong: <Text.B2 display="inline" $medium /> }}
              />
            </Text.B2>
            <Alert severity="info">
              <Text.B2>
                {t('accounts.clientSideSecurityConfig.moreDetails')}{' '}
                <ExternalLink url={DOCS_LINK}>{t('accounts.clientSideSecurityConfig.docsLink')}</ExternalLink>
              </Text.B2>
            </Alert>
            <Text.H6 sx={{ mt: 8 }}>
              <Trans
                i18nKey="accounts.clientSideSecurityConfig.enableHeader"
                values={{ environmentName: environment.displayName }}
                components={{ strong: <Text.H6 display="inline" $bold /> }}
              />
            </Text.H6>
            <Flex.RowSpaceBetween sx={{ mt: 2, mb: 8 }}>
              <Text.B2>{t('accounts.clientSideSecurityConfig.status')}</Text.B2>
              <ToggleButtonGroup
                exclusive
                value={clientSideSecuritySet}
                onChange={(_e, value) => {
                  if (value === null) {
                    return;
                  }
                  setClientSideSecuritySet(value);
                }}>
                <ToggleButton value={false}>
                  <Icon size={16} type="reactFeather" icon="Unlock" />
                  <Text.B2 color="inherit" sx={{ ml: 2 }}>
                    {t('accounts.notEnforced')}
                  </Text.B2>
                </ToggleButton>
                <ToggleButton value>
                  <Icon size={16} type="reactFeather" icon="Lock" />
                  <Text.B2 color="inherit" sx={{ ml: 2 }}>
                    {t('accounts.enforced')}
                  </Text.B2>
                </ToggleButton>
              </ToggleButtonGroup>
            </Flex.RowSpaceBetween>
            <Divider />
            <Flex.Row sx={{ mt: 4 }} direction="row-reverse">
              <DialogActionsButtons
                onCancel={onClose}
                saveText={t('accounts.save')}
                saveDisabled={!hasChanges}
                onSave={() => {
                  setConfirmationModalOpen(true);
                }}
              />
            </Flex.Row>
          </Flex.Column>
          <ConfirmationModal>
            <Text.B2 sx={{ mb: 2 }}>
              <Trans
                i18nKey={
                  clientSideSecuritySet
                    ? 'accounts.clientSideSecurityConfig.enforceModalBodyFirst'
                    : 'accounts.clientSideSecurityConfig.disableEnforceModalBodyFirst'
                }
                values={{ environmentName: environment.displayName }}
                components={{ strong: <Text.B2 display="inline" $medium /> }}
              />
            </Text.B2>
            <Text.B2 sx={{ mb: 2 }}>
              {t(
                clientSideSecuritySet
                  ? 'accounts.clientSideSecurityConfig.enforceModalBodySecond'
                  : 'accounts.clientSideSecurityConfig.disableEnforceModalBodySecond',
              )}
            </Text.B2>
            <DialogActionsButtons
              onCancel={() => {
                setConfirmationModalOpen(false);
              }}
              saveText={t(
                clientSideSecuritySet
                  ? 'accounts.clientSideSecurityConfig.enforce'
                  : 'accounts.clientSideSecurityConfig.disable',
              )}
              saveButtonColor={clientSideSecuritySet ? 'primary' : 'error'}
              onSave={async () => {
                await dispatch(
                  updateEnvironmentAction({
                    environmentId: environment.id,
                    updatePayload: { hardenClientAccessEnabled: clientSideSecuritySet },
                  }),
                );
                setConfirmationModalOpen(false);
                onClose();
              }}
            />
          </ConfirmationModal>
        </>
      )}
    </CustomDrawer>
  );
};
