import { t } from 'i18next';
import {
  Currency,
  DiscountType,
  SubscriptionMaximumSpendDiscount,
  SubscriptionPreviewDiscount,
} from '@stigg-types/apiTypes';
import { formatDate } from '../../../common';
import { currencyPriceFormatter } from '../../../packages/pricing/components/currency/currencyUtils';

export const formatDiscountValue = (
  discountValue: number,
  discountType: DiscountType,
  currency: Currency | undefined = Currency.Usd,
) => {
  if (discountType === DiscountType.Percentage) {
    return `${discountValue}% off`;
  }

  if (discountType === DiscountType.Fixed) {
    return `${currencyPriceFormatter({ amount: discountValue, currency, options: { withCodePostfix: true } })} off`;
  }
  return '';
};

export const formatDiscountDetails = (
  { name, value, type }: SubscriptionPreviewDiscount | SubscriptionMaximumSpendDiscount,
  currency: Currency,
) => {
  if (!value) {
    return name || '';
  }

  return `${name} (${formatDiscountValue(value, type || DiscountType.Fixed, currency)})`;
};

export const formatDiscountDuration = ({
  start,
  durationInMonths,
}: Pick<SubscriptionPreviewDiscount | SubscriptionMaximumSpendDiscount, 'start' | 'durationInMonths'>) => {
  const validFrom = start ? t('coupons.duration.validFrom', { date: formatDate(start, { withYear: true }) }) : null;
  const duration = t('coupons.duration.forMonths', { count: durationInMonths || 0 });

  return `${t('coupons.duration.valid')}${validFrom ? ` ${validFrom}` : ''} ${duration}`;
};
