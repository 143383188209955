import { t } from 'i18next';
import { Alert, Icon, Box, Text, WizardAccordionStep, ExternalLink } from '@stigg-components';
import { Auth0CredentialsFragment } from '@stigg-types/apiTypes';
import { useAppDispatch } from '../../../../redux/store';
import { fetchIntegrationByVendorAction } from '../../integrationsSlice';
import { VendorIdentifier } from '../../constants';
import { Auth0Applications } from './Auth0Applications';
import { Auth0ClientDetailsForm } from './Auth0ClientDetailsForm';
import { useAuth0Form, Auth0IntegrationFormFields } from './useAuth0Form';
import { Auth0InitialCustomerAccess } from './Auth0InitialCustomerAccess';
import { useGetAuth0Applications } from '../../queries/fetchAuth0Applications';

type UseWizardStepsProps = {
  credentials: Auth0CredentialsFragment | undefined;
  isSetupCompleted: boolean;
  onAddOrUpdateIntegration: (credentials: Auth0IntegrationFormFields) => Promise<void>;
  isReadOnly: boolean;
};

export const useAuth0IntegrationWizardSteps = ({
  credentials,
  isSetupCompleted,
  onAddOrUpdateIntegration,
  isReadOnly,
}: UseWizardStepsProps): WizardAccordionStep[] => {
  const dispatch = useAppDispatch();
  const formRenderProps = useAuth0Form(credentials, onAddOrUpdateIntegration);
  const {
    values,
    createMAUFeature,
    isClientDetailsValid,
    isValid,
    errors,
    dirty,
    isInitialCustomerAccessStepSelectionValid,
    individualShowMissingMAUEntitlement,
    organizationShowMissingMAUEntitlement,
    individualStartPlan,
    organizationStartPlan,
  } = formRenderProps;

  const { refetch: fetchAuth0Applications } = useGetAuth0Applications(
    {
      clientId: values.clientId,
      clientSecret: values.clientSecret,
      clientDomain: values.clientDomain,
    },
    { enabled: false },
  );

  return [
    {
      id: 'stigg-client-in-auth0',
      title: t('integrations.auth0.steps.clientSetup.title'),
      hideStepActionsButtons: isSetupCompleted,
      content: (
        <Box>
          <Alert
            icon={<Icon type="custom" icon="InstallPackageIcon" color="primary.main" overrideStroke />}
            sx={{ backgroundColor: (theme) => theme.itamar.palette.grey[25], width: '100%' }}
            severity="info">
            <Text.B2 gap={1}>
              <ExternalLink
                label={t('integrations.auth0.steps.clientSetup.installationUrlLinkText')}
                url={t('integrations.auth0.steps.clientSetup.installationUrl')}
              />
            </Text.B2>
          </Alert>
        </Box>
      ),
      maxWidth: 600,
    },
    {
      id: 'auth0-client-details',
      title: t('integrations.auth0.steps.tenant.title'),
      onStepContinue: async () => {
        await createMAUFeature();
        try {
          await onAddOrUpdateIntegration(values);
          // Validate credentials by fetching auth0 applications
          await fetchAuth0Applications({ throwOnError: true });
        } catch (e: any) {
          // In case we failed to fetch auth0 applications we should not allow to continue
          return { canContinue: false };
        }
        return { canContinue: true };
      },
      hasErrors: isClientDetailsValid,
      nextDisabled: !dirty,
      content: <Auth0ClientDetailsForm formRenderProps={formRenderProps} isSetupCompleted={isSetupCompleted} />,
      maxWidth: 600,
    },
    {
      id: 'auth0-application',
      title: t('integrations.auth0.steps.application.title'),
      hasErrors: !!errors.applicationId,
      nextDisabled: !dirty,
      summary: `${values.applicationName} (${t(`integrations.auth0ApplicationTypes.${values.applicationType}`)})`,
      onStepContinue: async () => {
        await onAddOrUpdateIntegration(values);
      },
      content: <Auth0Applications formRenderProps={formRenderProps} isReadOnly={isReadOnly} />,
    },
    {
      id: 'auth0-initial-customer-access',
      title: t('integrations.auth0.steps.initialCustomerAccess.title'),
      hasErrors: !isInitialCustomerAccessStepSelectionValid() || !isValid,
      nextDisabled: !dirty,
      withNextIcon: !isSetupCompleted,
      nextButtonText: isSetupCompleted
        ? t('sharedComponents.editSaveButton')
        : t('integrations.auth0.completeButtonText'),
      content: (
        <Auth0InitialCustomerAccess
          formRenderProps={formRenderProps}
          isReadOnly={isReadOnly}
          individualShowMissingMAUEntitlement={individualShowMissingMAUEntitlement}
          organizationShowMissingMAUEntitlement={organizationShowMissingMAUEntitlement}
          individualStartPlanRefId={individualStartPlan?.refId}
          organizationStartPlanRefId={organizationStartPlan?.refId}
        />
      ),
      onStepContinue: async () => {
        await onAddOrUpdateIntegration(values);
        await dispatch(
          fetchIntegrationByVendorAction({
            vendorIdentifier: VendorIdentifier.Auth0,
          }),
        );
      },
    },
  ];
};
