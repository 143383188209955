import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import styled from 'styled-components/macro';
import { AccountPermissionActions } from '@stigg-permissions';
import { Button, Icon, Text, CarouselCardProps, AccountPermissionsProtected } from '@stigg-components';

import { ReactComponent as WelcomeToStiggSvgLight } from '@assets/images/welcomeToStigg.light.svg';
import { ReactComponent as ModelYourPricingSvgLight } from '@assets/images/modelYourPricing.light.svg';
import { ReactComponent as IntegrateStiggToYourCodeSvgLight } from '@assets/images/integrateStiggToYourCode.light.svg';
import { ReactComponent as StartWithUseCaseSvgLight } from '@assets/images/startWithUseCase.light.svg';

import { ReactComponent as WelcomeToStiggSvgDark } from '@assets/images/welcomeToStigg.dark.svg';
import { ReactComponent as ModelYourPricingSvgDark } from '@assets/images/modelYourPricing.dark.svg';
import { ReactComponent as IntegrateStiggToYourCodeSvgDark } from '@assets/images/integrateStiggToYourCode.dark.svg';
import { ReactComponent as StartWithUseCaseSvgDark } from '@assets/images/startWithUseCase.dark.svg';

import { externalLinks } from '../../common';
import { useNavigation, appRoutes } from '../../navigation/useNavigation';
import { RootState } from '../../../redux/store';

const StyledStartWithUseCaseSvgLight = styled(StartWithUseCaseSvgLight)`
  position: absolute;
  top: 0;
  right: ${({ theme }) => theme.spacing(8)};
`;

const StyledStartWithUseCaseSvgDark = styled(StartWithUseCaseSvgDark)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: auto;
`;

const StyledModelYourPricingSvgDark = styled(ModelYourPricingSvgDark)`
  position: absolute;
  bottom: 0;
  right: -36px;
`;

const StyledIntegrateStiggToYourCodeSvgDark = styled(IntegrateStiggToYourCodeSvgDark)`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: auto;
`;

const getCustomBackgroundStyle = (isLightTheme: boolean): React.CSSProperties => ({
  background: isLightTheme
    ? 'linear-gradient(225.2deg, rgba(191, 217, 250, 0.1) 3.06%, #D4E9FD 100%)'
    : 'linear-gradient(200deg, rgba(62, 128, 71, 0.00) 0%, #286233 100%)',
  height: '425px',
});

export const useGettingStartedCarouselCards = (
  isLightTheme: boolean,
  setIsInviteDrawerOpen: (isOpen: boolean) => void,
) => {
  const { navigateTo } = useNavigation();
  const products = useSelector((state: RootState) => state.productReducer.products);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  const onModelYourPricingClick = () => {
    let refId;
    if (products.length === 1) {
      refId = products[0].refId;
    }

    navigateTo(appRoutes.productPage(refId));
  };

  const onCarouselChange = (index: number) => {
    if (selectedCardIndex !== index) {
      setSelectedCardIndex(index);
    }
  };

  const carouselProps = {
    selectedItem: selectedCardIndex,
    onChange: onCarouselChange,
  };

  const customBackgroundStyle = getCustomBackgroundStyle(isLightTheme);

  const carouselCardsProps: CarouselCardProps[] = [
    {
      style: customBackgroundStyle,
      title: t('gettingStarted.carouselCards.welcomeCard.title'),
      content: (
        <>
          <Text.B1 color="primary" mb={4}>
            {t('gettingStarted.carouselCards.welcomeCard.content1')}
          </Text.B1>
          <Text.B1 color="primary">{t('gettingStarted.carouselCards.welcomeCard.content2')}</Text.B1>
        </>
      ),
      buttonOnClick: () => setSelectedCardIndex(1),
      buttonIcon: <Icon icon="ArrowForward" />,
      buttonIconPosition: 'end',
      buttonText: t('gettingStarted.carouselCards.welcomeCard.buttonText'),
      svg: isLightTheme ? <WelcomeToStiggSvgLight /> : <WelcomeToStiggSvgDark />,
    },
    {
      style: customBackgroundStyle,
      title: t('gettingStarted.carouselCards.modelYourPricingCard.title'),
      content: (
        <>
          <Text.B1 color="primary">{t('gettingStarted.carouselCards.modelYourPricingCard.content')}</Text.B1>
        </>
      ),
      buttonOnClick: onModelYourPricingClick,
      buttonText: t('gettingStarted.carouselCards.modelYourPricingCard.buttonText'),
      svg: isLightTheme ? (
        <ModelYourPricingSvgLight width={400} height={300} />
      ) : (
        <StyledModelYourPricingSvgDark width={430} height="auto" />
      ),
    },
    {
      style: customBackgroundStyle,
      title: t('gettingStarted.carouselCards.useCasesCard.title'),
      content: (
        <>
          <Text.B1 color="primary">{t('gettingStarted.carouselCards.useCasesCard.content')}</Text.B1>
        </>
      ),
      svg: isLightTheme ? <StyledStartWithUseCaseSvgLight /> : <StyledStartWithUseCaseSvgDark />,
    },
    {
      style: customBackgroundStyle,
      label: t('gettingStarted.labels.developer'),
      title: t('gettingStarted.carouselCards.integrateStiggCard.title'),
      content: (
        <>
          <Text.B1 color="primary">{t('gettingStarted.carouselCards.integrateStiggCard.content')}</Text.B1>
        </>
      ),
      buttonOnClick: () => window.open(externalLinks.integratingStiggSdksIntoYourCodebaseLink, '_blank'),
      buttonText: t('gettingStarted.carouselCards.integrateStiggCard.buttonText'),
      buttonIcon: <Icon icon="FileText" />,
      buttonIconPosition: 'start',
      svg: isLightTheme ? (
        <IntegrateStiggToYourCodeSvgLight width={400} height={300} />
      ) : (
        <StyledIntegrateStiggToYourCodeSvgDark />
      ),
      customActions: (
        <AccountPermissionsProtected permissions={[AccountPermissionActions.InviteUser]}>
          <Button
            $outlined
            color="secondary"
            sx={{ width: '100%', marginTop: 4 }}
            startIcon={<Icon icon="UserPlus" type="reactFeather" />}
            onClick={() => setIsInviteDrawerOpen(true)}>
            {t('gettingStarted.carouselCards.integrateStiggCard.inviteTeamMemberButton')}
          </Button>
        </AccountPermissionsProtected>
      ),
    },
  ];

  return { carouselProps, carouselCardsProps };
};
