import { BooleanEntitlementGuard } from '@stigg/react-sdk';
import { StiggFeature } from '../../../doggo/StiggFeature';
import NoEventLogAccess from '../NoEventLogAccess';
import EventLogTable from './EventLogTable';

export type EventLogProps = {
  entityId?: string;
  parentEntityId?: string;
  columns?: EventLogColumns[];
};

export enum EventLogColumns {
  Timestamp,
  EventName,
  Entity,
  Actor,
}

export const DEFAULT_COLUMNS = [EventLogColumns.Timestamp, EventLogColumns.EventName, EventLogColumns.Actor];

export default function EventLog({ entityId, parentEntityId, columns = DEFAULT_COLUMNS }: EventLogProps) {
  return (
    <BooleanEntitlementGuard
      featureId={StiggFeature.EVENT_LOG}
      noAccessComponent={<NoEventLogAccess columns={columns} />}
      options={{ fallback: { hasAccess: true } }}>
      <EventLogTable entityId={entityId} parentEntityId={parentEntityId} columns={columns} />
    </BooleanEntitlementGuard>
  );
}
