import { useRef, createContext, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Ability } from '@casl/ability';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { AccountPermissionActions, EnvironmentPermissionActions, PermissionResource } from '@stigg-permissions';
import { Permit, permitState } from 'permit-fe-sdk';
import { RootState } from '../redux/store';
import config from '../env.config';
import Loader from '../components/Loader';

export const PermissionsContext = createContext<any>(null); // TODO: add types

export default function PermissionsProvider({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently } = useAuth0();
  const { rbacRollout } = useFlags<FeatureFlags>();
  const [ability, setAbility] = useState<any>(undefined);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(rbacRollout);
  const isLoadingUser = useSelector((state: RootState) => state.authReducer.isLoading);
  const user = useSelector((state: RootState) => state.authReducer.user);
  const shouldShowLoader = useRef(true);

  const environments = useSelector((state: RootState) => state.accountReducer.environments);

  useEffect(() => {
    const getAbility = async (email: string) => {
      const token = await getAccessTokenSilently();
      const permit = Permit({
        loggedInUser: email,
        backendUrl: `${config.restUrl}/permissions/check`,
        customRequestHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      permitState.reset();

      // account level access
      const accountAccess = Object.values(AccountPermissionActions).map((permission) => ({
        action: permission,
        resource: PermissionResource.Account,
      }));

      // environment level access
      // TODO: we should load the permissions only for the selected environment, and refresh it on environment change
      const environmentAccess = environments.flatMap((environment) =>
        Object.values(EnvironmentPermissionActions).map((permission) => ({
          action: permission,
          resource: { type: PermissionResource.Environment, key: environment.id },
        })),
      );

      await permit.loadLocalStateBulk([...accountAccess, ...environmentAccess]);

      const caslConfig = permitState.getCaslJson();

      return caslConfig && caslConfig.length ? new Ability(caslConfig) : undefined;
    };

    if (rbacRollout && !isLoadingUser && user?.email) {
      // Showing the loader only for the first time when the permissions are being loaded
      if (shouldShowLoader.current) {
        setIsLoadingPermissions(true);

        shouldShowLoader.current = false;
      }
      void getAbility(user.email).then((caslAbility) => {
        setAbility(caslAbility);
        setIsLoadingPermissions(false);
      });
    }
  }, [rbacRollout, isLoadingUser, user, getAccessTokenSilently, environments]);

  return isLoadingPermissions ? (
    <Loader useAnimationLoader />
  ) : (
    <PermissionsContext.Provider value={ability}>{children}</PermissionsContext.Provider>
  );
}
