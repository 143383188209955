import { PricingType, Subscription, MeteredEntitlement } from '@stigg/react-sdk';
import { t } from 'i18next';
import { StiggFeature } from '../../../../doggo/StiggFeature';

export function getSeatPrice(paidSubscription: Subscription) {
  const seatPrice = paidSubscription.prices?.find((price) => price.feature?.featureId === StiggFeature.SEATS);
  return { seatPrice: seatPrice?.amount, billingPeriod: seatPrice?.billingPeriod };
}

export function getPaidContext({
  paidSubscription,
  additionalTeamMembers,
}: {
  paidSubscription: Subscription;
  additionalTeamMembers: number;
}): string {
  const { seatPrice, billingPeriod } = getSeatPrice(paidSubscription);
  const billingPeriodString = t(`pricing.shortBillingPeriodPriceDescription.${billingPeriod}`);
  if (additionalTeamMembers > 0) {
    return t('accounts.informMembersPaidPLan', {
      additionalTeamMembers,
      seatPrice,
      billingPeriod: billingPeriodString,
    });
  }

  return t('accounts.informLimitMembersPaidPLan', { seatPrice, billingPeriod: billingPeriodString });
}

export function getFreeContext({ additionalTeamMembers }: { additionalTeamMembers: number }) {
  let text;
  let severityWarning = false;
  if (additionalTeamMembers > 0) {
    text = t('accounts.informMembersFreePLan', { additionalTeamMembers });
  } else {
    text = t('accounts.informLimitMembersFreePLan');
    severityWarning = true;
  }
  return { text, severityWarning };
}

export function disableInvites(
  entitlement: MeteredEntitlement,
  activeSubscriptions: Subscription[] | null,
  invites: string[],
) {
  const hasFreeSubscription = activeSubscriptions?.some((sub) => sub.pricingType === PricingType.Free);
  return hasFreeSubscription && entitlement.usageLimit
    ? entitlement.currentUsage + invites.length >= entitlement.usageLimit
    : false;
}
