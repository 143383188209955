import { GridFlex } from '@stigg-components';
import { t } from 'i18next';
import { WidgetConfig } from '../../hooks/useWidgetConfiguration';
import { ColorPickerInput } from './ColorPickerInput';
import { CheckoutWidgetConfiguration } from '../../configurations/checkout';

type CheckoutColorsThemeProps = {
  widgetConfig: WidgetConfig;
  isReadOnly: boolean;
};

export function CheckoutColorsTheme({ widgetConfig, isReadOnly }: CheckoutColorsThemeProps) {
  const { isLoading, configuration, onThemeChanged } = widgetConfig;
  const checkoutWidgetConfiguration = configuration as CheckoutWidgetConfiguration;
  const { palette } = checkoutWidgetConfiguration;
  return (
    <GridFlex.Column gap={4}>
      <ColorPickerInput
        disabled={isReadOnly}
        isLoading={isLoading}
        label={t('widgets.primaryColor')}
        initialColor={palette?.primary}
        onColorChanged={(color) => {
          onThemeChanged({ palette: { primary: color } });
        }}
      />
      <ColorPickerInput
        disabled={isReadOnly}
        isLoading={isLoading}
        label={t('widgets.textColor')}
        initialColor={palette.textColor}
        onColorChanged={(color) => {
          onThemeChanged({ palette: { textColor: color } });
        }}
      />
      <ColorPickerInput
        disabled={isReadOnly}
        isLoading={isLoading}
        label={t('widgets.backgroundColor')}
        initialColor={palette.backgroundColor}
        onColorChanged={(color) => {
          onThemeChanged({ palette: { backgroundColor: color } });
        }}
      />
      <ColorPickerInput
        disabled={isReadOnly}
        isLoading={isLoading}
        label={t('widgets.borderColor')}
        initialColor={palette.borderColor}
        onColorChanged={(color) => {
          onThemeChanged({ palette: { borderColor: color } });
        }}
      />
      <ColorPickerInput
        disabled={isReadOnly}
        isLoading={isLoading}
        label={t('widgets.summaryBackgroundColor')}
        initialColor={palette.summaryBackgroundColor}
        onColorChanged={(color) => {
          onThemeChanged({ palette: { summaryBackgroundColor: color } });
        }}
      />
    </GridFlex.Column>
  );
}
