import { BillingPeriod, Paywall } from '@stigg/js-client-sdk';
import { useEffect, useState } from 'react';
import logger from '../../../services/logger';
import { PaywallData } from '../types';
import { computeBillingPeriods } from '../utils/computeDefaultBillingPeriod';
import { mapPaywallData } from '../utils/mapPaywallData';
import { useStiggContext } from '../../../hooks/useStiggContext';

type UseLoadPaywallDataProps = {
  productId?: string;
  resourceId?: string;
  showOnlyEligiblePlans?: boolean;
  billingCountryCode?: string;
  preferredBillingPeriod?: BillingPeriod;
};

export function useLoadPaywallData({
  productId,
  resourceId,
  showOnlyEligiblePlans,
  billingCountryCode,
  preferredBillingPeriod,
}: UseLoadPaywallDataProps): PaywallData {
  const { stigg, locale } = useStiggContext();
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState(BillingPeriod.Annually);
  const [availableBillingPeriods, setAvailableBillingPeriods] = useState<BillingPeriod[]>([]);
  const [paywall, setPaywall] = useState<Paywall | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadPaywall = async () => {
      try {
        setIsLoading(true);

        await stigg.waitForInitialization();
        const paywallResult = await stigg.getPaywall({
          productId,
          resourceId,
          billingCountryCode,
        });
        const { availableBillingPeriods, defaultBillingPeriod } = computeBillingPeriods(
          paywallResult.plans,
          paywallResult.activeSubscriptions,
          preferredBillingPeriod,
        );

        setPaywall(paywallResult);
        setAvailableBillingPeriods(availableBillingPeriods);
        setSelectedBillingPeriod(defaultBillingPeriod);
      } catch (err) {
        logger.error(`Failed to load paywall ${(err as any)?.message}`, err as any);
      } finally {
        setIsLoading(false);
      }
    };

    void loadPaywall();
  }, [stigg, productId, stigg.isCustomerLoaded, billingCountryCode, resourceId, preferredBillingPeriod]);

  const paywallData = mapPaywallData(paywall, showOnlyEligiblePlans);

  return {
    customer: paywall?.customer || null,
    isLoading,
    selectedBillingPeriod,
    setSelectedBillingPeriod,
    availableBillingPeriods,
    locale,
    configuration: paywallData.paywallConfiguration,
    ...paywallData,
  };
}
