import { useEffect, useState } from 'react';
import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import { Box, TextField, Slider, ButtonGroup, Text, GridFlex, CollapsableSection } from '@stigg-components';
import { t } from 'i18next';
import { styled } from '@stigg-theme';
import { Alignment } from '@stigg-types/apiTypes';
import { WidgetConfig } from '../../hooks/useWidgetConfiguration';
import { WidgetIdentifier } from '../../useWidgetsData';
import { SelectableIconButton } from './SelectableIconButton';
import { PaywallThemeConfiguration } from '../../configurations/paywall';

const LeftIconButton = styled(SelectableIconButton)`
  border: none;
  border-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: ${({ theme }) => `1px solid ${theme.itamar.palette.other.outlineBorderLight}`};
`;

const RightIconButton = styled(SelectableIconButton)`
  border: none;
  border-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: ${({ theme }) => `1px solid ${theme.itamar.palette.other.outlineBorderLight}`};
`;

function AlignmentPicker({
  initialValue,
  onChange,
  disabled,
}: {
  initialValue: Alignment;
  onChange: (alignment: Alignment) => void;
  disabled?: boolean;
}) {
  const [alignment, setAlignment] = useState(initialValue);
  useEffect(() => {
    setAlignment(initialValue);
  }, [initialValue]);

  const onAlignmentChange = (alignment: Alignment) => {
    setAlignment(alignment);
    onChange(alignment);
  };

  return (
    <GridFlex.RowSpaceBetween>
      <Text.B1 color="primary">{t('widgets.alignment')}</Text.B1>
      <ButtonGroup
        variant="contained"
        sx={{ border: (theme) => `1px solid ${theme.itamar.palette.other.outlineBorderLight}`, borderRadius: '10px' }}>
        <LeftIconButton
          isDisabled={disabled}
          icon="AlignLeft"
          type="reactFeather"
          onClick={() => onAlignmentChange(Alignment.Left)}
          isSelected={alignment === Alignment.Left}
        />
        <SelectableIconButton
          aria-label="align-center"
          icon="AlignCenter"
          type="reactFeather"
          onClick={() => onAlignmentChange(Alignment.Center)}
          isSelected={alignment === Alignment.Center}
          sx={{ borderRadius: 0, border: 'none' }}
          isDisabled={disabled}
        />
        <RightIconButton
          icon="AlignRight"
          type="reactFeather"
          onClick={() => onAlignmentChange(Alignment.Right)}
          isSelected={alignment === Alignment.Right}
          isDisabled={disabled}
        />
      </ButtonGroup>
    </GridFlex.RowSpaceBetween>
  );
}

type SizeSliderPickerProps = {
  label: string;
  maxValue: number;
  initialValue: number;
  onChange: (value: number) => void;
  disabled?: boolean;
};

function SizeSliderPicker({ label, maxValue, initialValue, onChange, disabled }: SizeSliderPickerProps) {
  const [value, setValue] = useState(initialValue);
  const handleChange = (newValue: number) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <GridFlex.Column gap={2}>
      <Text.B1 color="primary">{label}</Text.B1>
      <GridFlex.RowSpaceBetween $fullWidth>
        <Box flexGrow={1} mr={4}>
          <Slider
            disabled={disabled}
            size="small"
            value={value}
            onChange={(_, value) => handleChange(value as number)}
            max={maxValue}
          />
        </Box>
        <TextField
          type="number"
          value={value}
          fullWidth={false}
          width={100}
          disabled={disabled}
          onChange={(e) => {
            handleChange(parseInt(e.target.value, 10));
          }}
          error={false}
          touched={false}
          endAdornment="px"
        />
      </GridFlex.RowSpaceBetween>
    </GridFlex.Column>
  );
}

type LayoutThemeProps = {
  widgetConfig: WidgetConfig;
  isReadOnly: boolean;
};

function LayoutTheme({ widgetConfig, isReadOnly }: LayoutThemeProps) {
  const { onThemeChanged, configuration } = widgetConfig;
  const paywallConfiguration = configuration as PaywallThemeConfiguration;

  return (
    <GridFlex.Column gap={4}>
      <AlignmentPicker
        disabled={isReadOnly}
        initialValue={paywallConfiguration.layout.alignment}
        onChange={(alignment) => {
          onThemeChanged({
            layout: {
              alignment,
            },
          });
        }}
      />
      <SizeSliderPicker
        disabled={isReadOnly}
        initialValue={paywallConfiguration.layout.planWidth}
        onChange={(value) => {
          onThemeChanged({
            layout: {
              planWidth: value,
            },
          });
        }}
        label={t('widgets.planWidth')}
        maxValue={1000}
      />
      <SizeSliderPicker
        disabled={isReadOnly}
        initialValue={paywallConfiguration.layout.planMargin}
        onChange={(value) => {
          onThemeChanged({
            layout: {
              planMargin: value,
            },
          });
        }}
        label={t('widgets.planMargin')}
        maxValue={250}
      />
      <SizeSliderPicker
        disabled={isReadOnly}
        initialValue={paywallConfiguration.layout.planPadding}
        onChange={(value) => {
          onThemeChanged({
            layout: {
              planPadding: value,
            },
          });
        }}
        label={t('widgets.planPadding')}
        maxValue={250}
      />
    </GridFlex.Column>
  );
}

export function LayoutThemeSection({
  widgetIdentifier,
  widgetConfig,
}: {
  widgetIdentifier: WidgetIdentifier;
  widgetConfig: WidgetConfig;
}) {
  const [isSectionOpen, setIsSectionOpen] = useState(true);
  const allowWidgetsWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);

  if (widgetIdentifier !== WidgetIdentifier.Paywall) {
    return null;
  }

  return (
    <CollapsableSection
      title={t('widgets.layoutSectionTitle')}
      sx={{ color: (theme) => theme.itamar.palette.text.primary }}
      $fullWidth
      isSectionOpen={isSectionOpen}
      onClick={() => setIsSectionOpen(!isSectionOpen)}
      content={<LayoutTheme widgetConfig={widgetConfig} isReadOnly={!allowWidgetsWrite} />}
    />
  );
}
