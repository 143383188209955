import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import { Text, LongText, PackageChip, Grid, GridFlex, PackageEditDropdown } from '@stigg-components';
import { AddonFragment, PlanFragment, PackageStatus } from '@stigg-types/apiTypes';
import { AwsChip } from '@stigg-common';
import { isDraftPackage } from '../modules/packages/package.utils';

export function PackageHeaderLayout({
  title,
  displayName,
  aPackage,
}: {
  title: string;
  displayName?: string | null;
  aPackage: PlanFragment | AddonFragment; // package is a reserved word so adding a prefix;
}) {
  const allowPackageWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);
  const isDraft = isDraftPackage(aPackage);
  const shouldShowStatusChip = aPackage.status === PackageStatus.Published || aPackage.isLatest;
  const showEditDropdown = allowPackageWrite && aPackage.status === PackageStatus.Published && aPackage.isLatest;

  return (
    <>
      <GridFlex.RowSpaceBetweenTop container wrap="nowrap">
        <GridFlex.Column item mb={4}>
          <Text.Sub2 mb={1}>{title}</Text.Sub2>
          <GridFlex.RowCenter mb={1} mr={2}>
            <LongText variant="h1" maxLines={1}>
              {displayName}
            </LongText>
            <Grid ml={2} gap={2}>
              {shouldShowStatusChip && <PackageChip status={aPackage.status} />}
              {aPackage.product?.awsMarketplaceProductId ? <AwsChip /> : null}
            </Grid>
          </GridFlex.RowCenter>
        </GridFlex.Column>

        {showEditDropdown && (
          <Grid item alignSelf="center" width="auto">
            <PackageEditDropdown aPackage={aPackage} isDraft={isDraft} />
          </Grid>
        )}
      </GridFlex.RowSpaceBetweenTop>
    </>
  );
}
