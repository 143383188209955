import { curveStepAfter } from '@visx/curve';
import moment from 'moment';
import { useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { useSelector } from 'react-redux';
import { formatDate } from '@stigg-common';
import { useTheme } from '@mui/material/styles';
import { Grid, Skeleton } from '@stigg-components';
import { ResetPeriodConfiguration } from '@stigg-common/types';
import {
  EntitlementResetPeriod,
  UsageHistoryFragment,
  UsageHistoryPointFragment,
  UsageMarkerType,
} from '@stigg-types/apiTypes';
import { ChartOptions } from 'react-charts/types/types';
import { t } from 'i18next';
import { RootState } from '../../../../../redux/store';
import { getMaxDataPointValue, getPointToLastMarkerType, getChartData } from './FluctuatingUsageGraphWrapper.utils';
import { useGetUsageHistory } from '../../../queries/fetchUsageHistory';

function TooltipTitle({ date, lastMarkerType }: { date: Date; lastMarkerType?: UsageMarkerType }) {
  let resetInfoText: string | null;

  switch (lastMarkerType) {
    case UsageMarkerType.PeriodicReset:
      resetInfoText = t('customers.entitlementUsagePeriodicReset');
      break;
    case UsageMarkerType.SubscriptionChangeReset:
      resetInfoText = t('customers.entitlementUsageSubscriptionChange');
      break;
    default:
      resetInfoText = null;
  }

  return (
    <Grid pl={1} container flexDirection="column" alignItems="start">
      <Grid item>{formatDate(date, { formatType: 'longDate', withTime: true })}</Grid>
      {resetInfoText && <Grid item>({resetInfoText})</Grid>}
    </Grid>
  );
}

const FluctuatingUsageGraph = ({
  usageHistory,
  startDate,
  endDate,
  usageLimit,
}: {
  usageHistory: UsageHistoryFragment;
  startDate: Date;
  endDate: Date;
  usageLimit?: number | null;
}) => {
  const theme = useTheme();
  const chartData = getChartData(usageHistory);
  const pointToLastMarkerType = getPointToLastMarkerType(chartData[0].data, usageHistory.markers);
  const maxValue = getMaxDataPointValue(chartData, usageLimit);

  const primaryAxis: AxisOptions<UsageHistoryPointFragment> = useMemo(
    (): AxisOptions<UsageHistoryPointFragment> => ({
      getValue: ({ timestamp }) => timestamp,
      scaleType: 'time',
      min: startDate,
      max: endDate,
      showGrid: false,
      formatters: {
        tooltip: (value: Date) => {
          if (!value) {
            return null;
          }
          const lastMarkerType = pointToLastMarkerType.get(value);
          return <TooltipTitle date={value} lastMarkerType={lastMarkerType} />;
        },
        scale: (value: Date) =>
          value && moment.utc(value).isSame(moment.utc(value).startOf('day'))
            ? formatDate(value, { formatType: 'shortDate' })
            : '',
      },
    }),
    [startDate, endDate, pointToLastMarkerType],
  );

  const secondaryAxes: AxisOptions<UsageHistoryPointFragment>[] = useMemo(
    (): AxisOptions<UsageHistoryPointFragment>[] => [
      {
        getValue: ({ value }) => value,
        min: 0,
        max: maxValue,
        scaleType: 'linear',
        elementType: 'area',
        curve: curveStepAfter,
      },
    ],
    [maxValue],
  );

  const getSeriesStyle = useMemo((): ChartOptions<any>['getSeriesStyle'] => {
    if (chartData.length > 1) {
      return undefined;
    }
    return () => ({
      area: { fill: theme.itamar.palette.primary.containedHoverBackground, stroke: 'none' },
      line: { stroke: theme.itamar.palette.primary.main, strokeWidth: '3px' },
    });
  }, [chartData.length, theme]);

  return (
    <Chart
      options={{
        data: chartData,
        primaryAxis,
        secondaryAxes,
        primaryCursor: {
          showLine: false,
          showLabel: false,
        },
        secondaryCursor: {
          showLine: false,
          showLabel: false,
        },
        getSeriesStyle,
        dark: !theme.isLightTheme,
      }}
    />
  );
};

export type FluctuatingUsageGraphWrapperProps = {
  featureRefId: string;
  customerRefId: string;
  resourceRefId: string | undefined;
  usageLimit?: number | null;
  resetPeriod?: EntitlementResetPeriod | null;
  resetPeriodConfiguration?: ResetPeriodConfiguration | null;
  groupBy?: string[];
};

function getStartDateByPeriod(resetPeriod?: EntitlementResetPeriod | null) {
  switch (resetPeriod) {
    case EntitlementResetPeriod.Year:
      return moment.utc().subtract(1, 'year').startOf('day').toDate();
    case EntitlementResetPeriod.Month:
    case EntitlementResetPeriod.Week:
      return moment.utc().subtract(1, 'month').startOf('day').toDate();
    case EntitlementResetPeriod.Day:
    case EntitlementResetPeriod.Hour:
      return moment.utc().subtract(1, 'week').startOf('day').toDate();
    default:
      return moment.utc().subtract(1, 'month').startOf('day').toDate();
  }
}

export function FluctuatingUsageGraphWrapper({
  featureRefId,
  customerRefId,
  resourceRefId,
  usageLimit,
  resetPeriod,
  groupBy,
}: FluctuatingUsageGraphWrapperProps) {
  const lastUsageReported = useSelector((root: RootState) => root.customersReducer.lastReportedUsage[featureRefId]);
  const today = useMemo(() => new Date(), []);
  const startDate = useMemo(() => getStartDateByPeriod(resetPeriod), [resetPeriod]);

  const { data, isLoading } = useGetUsageHistory({
    featureId: featureRefId,
    customerId: customerRefId,
    resourceId: resourceRefId,
    startDate,
    groupBy,
    lastUsageReported,
  });

  if (isLoading || !data) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={150}
        sx={{
          borderRadius: '10px',
          bgcolor: 'transparent',
        }}
      />
    );
  }

  return <FluctuatingUsageGraph usageHistory={data} startDate={startDate} endDate={today} usageLimit={usageLimit} />;
}
