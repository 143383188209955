import { useRef, useEffect, useState } from 'react';
import { WizardAccordionStep } from '@stigg-components';

export function useWizardAccordionStateManagement({
  uniqueFlowId,
  isStepByStep,
  stepsToShow,
  initialFocusedStep,
  expandOnSingleStep,
  onlySingleStepExpanded,
}: {
  uniqueFlowId: string;
  isStepByStep?: boolean;
  stepsToShow: WizardAccordionStep[];
  initialFocusedStep?: string;
  expandOnSingleStep?: boolean;
  onlySingleStepExpanded?: boolean;
}) {
  const initialExpandedSteps = initialFocusedStep
    ? [initialFocusedStep]
    : expandOnSingleStep && stepsToShow.length === 1
    ? [stepsToShow[0].id]
    : [];

  const [currentIndex, setCurrentIndex] = useState(-1);
  const [expandedSteps, setExpandedSteps] = useState<string[]>(initialExpandedSteps);
  const isFirstRender = useRef(true);

  const onContinue = (currentStepIndex: number) => {
    if (isStepByStep) {
      setCurrentIndex((previousIndex) => Math.min(previousIndex + 1, stepsToShow.length));
    } else {
      const nextStep = stepsToShow[currentStepIndex + 1];
      if (nextStep) {
        setExpandedSteps([nextStep.id]);
      }
    }
  };

  const toggleStep = (id: string) => {
    if (expandedSteps.includes(id)) {
      setExpandedSteps(expandedSteps.filter((stepId) => stepId !== id));
    } else if (onlySingleStepExpanded) {
      setExpandedSteps([id]);
    } else {
      setExpandedSteps([...expandedSteps, id]);
    }
  };

  // Set initial focused step
  useEffect(() => {
    if (isFirstRender.current && initialFocusedStep) {
      const index = stepsToShow.findIndex((step) => step.id === initialFocusedStep);
      setCurrentIndex(isStepByStep ? (index === -1 ? 0 : index) : -1);
    } else {
      setCurrentIndex(isStepByStep ? 0 : -1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueFlowId, isStepByStep]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setExpandedSteps([]);
  }, [uniqueFlowId]);

  return { currentIndex, expandedSteps, toggleStep, onContinue };
}
