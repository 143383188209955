import { useContext } from 'react';
import { StiggContext, StiggContextValue } from '../components/StiggProvider';

export function useStiggContext(options?: { optional?: false }): StiggContextValue;
export function useStiggContext(options: { optional: true }): StiggContextValue | null;
export function useStiggContext(options?: { optional?: boolean }): StiggContextValue | null {
  const { optional } = options || {};
  const ctx = useContext(StiggContext);
  if (!ctx && !optional) {
    throw new Error('Could not find Stigg context; You need to wrap your app in an <StiggProvider> component.');
  }
  return ctx;
}
