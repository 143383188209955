import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  DetachCustomerPaymentMethodMutation,
  DetachCustomerPaymentMethodMutationVariables,
} from '@stigg-types/apiTypes';
import { AppDispatch, RootState } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { apolloClient } from '../../../ApolloClient';
import { fetchCustomerByRefIdAction } from '../customersSlice';

const DELETE_PAYMENT_METHOD = gql`
  mutation detachCustomerPaymentMethod($input: DetachCustomerPaymentMethodInput!) {
    detachCustomerPaymentMethod(input: $input) {
      customerId
      environmentId
    }
  }
`;

function deletePaymentMethod(
  deleteData: { customerId: string },
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const response = await apolloClient.mutate<
        DetachCustomerPaymentMethodMutation,
        DetachCustomerPaymentMethodMutationVariables
      >({
        mutation: DELETE_PAYMENT_METHOD,
        variables: {
          input: {
            customerId: deleteData.customerId,
            environmentId: accountReducer.currentEnvironmentId,
          },
        },
      });

      await dispatch(fetchCustomerByRefIdAction({ customerId: deleteData.customerId }));

      return response.data?.detachCustomerPaymentMethod;
    },
    {
      successMessage: t('customers.api.paymentMethod.deleteSuccess'),
      failureMessageHandler: () => t('customers.api.paymentMethod.deleteFailed'),
    },
    dispatch,
  );
}

export { deletePaymentMethod };
