import { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { TextColor } from '@stigg-theme';
import {
  Box,
  DottedText,
  GridFlex,
  InformationTooltip,
  Text,
  Button,
  CustomDrawer,
  ReloadButton,
} from '@stigg-components';
import { SubscriptionDataFragment } from '@stigg-types/apiTypes';
import { formatDate } from '@stigg-common';
import { previewSubscriptionMaximumSpendAction } from '../../../../customersSlice';
import { RootState, useAppDispatch } from '../../../../../../redux/store';
import { currencyPriceFormatter } from '../../../../../packages/pricing/components/currency/currencyUtils';
import { MaximumSpendBreakdown } from './invoice';
import Loader from '../../../../../../components/Loader';

type SubscriptionNextBillProps = {
  subscription: SubscriptionDataFragment;
};

function getTextColorByBudgetUsage(budgetUsedPercent: number | null, defaultColor: TextColor = 'primary'): TextColor {
  if (!budgetUsedPercent || budgetUsedPercent < 80) {
    return defaultColor;
  }

  if (budgetUsedPercent >= 80 && budgetUsedPercent < 100) {
    return 'warning.main';
  }

  return 'error';
}

export function SubscriptionMaximumSpend({ subscription }: SubscriptionNextBillProps) {
  const { refId, budget } = subscription;

  const dispatch = useAppDispatch();
  const maximumSpendBySubscription = useSelector((root: RootState) => root.customersReducer.maximumSpendBySubscription);
  const { maximumSpend, isLoading } = maximumSpendBySubscription[refId] || {};
  const [isBreakdownOpen, setIsBreakdownOpen] = useState(false);

  const fetchMaximumSpend = useCallback(async () => {
    await dispatch(previewSubscriptionMaximumSpendAction({ subscriptionId: refId }));
  }, [dispatch, refId]);

  useEffect(() => {
    void fetchMaximumSpend();
  }, [fetchMaximumSpend]);

  const handleOpenBreakdown = () => {
    setIsBreakdownOpen(true);
  };

  const handleCloseBreakdown = () => {
    setIsBreakdownOpen(false);
  };

  if ((isLoading && !maximumSpend) || !maximumSpend) {
    return (
      <GridFlex.Row container gap={1}>
        <Loader isInline size={12} />
      </GridFlex.Row>
    );
  }

  const { total } = maximumSpend;
  const { amount, currency } = total;
  const budgetLimit = budget?.limit;
  const budgetUsed = budgetLimit ? (amount / budgetLimit) * 100 : null;

  const breakdownTitle = t('subscriptions.maxSpend.breakdownTitle');
  const breakdownDescription = (
    <GridFlex.RowCenter gap={2}>
      <Text.B2 color="secondary">
        {t('subscriptions.lastUpdatedOn', { date: formatDate(maximumSpend.lastUpdatedAt, { withTime: true }) })}
      </Text.B2>

      <ReloadButton color={undefined} isLoading={isLoading} onClick={fetchMaximumSpend} />
    </GridFlex.RowCenter>
  );

  const footer = (
    <GridFlex.Row justifyContent="flex-end" pb={8}>
      <Button variant="outlined" onClick={handleCloseBreakdown}>
        {t('sharedComponents.close')}
      </Button>
    </GridFlex.Row>
  );

  return (
    <GridFlex.RowMiddle container gap={1}>
      <GridFlex.Column item>
        <InformationTooltip arrow placement="top" title={<Text.B2>{t('subscriptions.viewBreakdownTooltip')}</Text.B2>}>
          <Button sx={{ padding: 0 }} onClick={handleOpenBreakdown}>
            <DottedText color="primary.main">
              {currencyPriceFormatter({ amount, currency, options: { withCodePostfix: true } })}
            </DottedText>
          </Button>
        </InformationTooltip>
      </GridFlex.Column>

      {budgetLimit ? (
        <>
          <GridFlex.Column item>
            <Text.B2>/</Text.B2>
          </GridFlex.Column>
          <GridFlex.Column item>
            <InformationTooltip arrow placement="top" title={<Text.B2>{t('subscriptions.maximumSpend')}</Text.B2>}>
              <Box display="inline-block">
                <DottedText>
                  {currencyPriceFormatter({ amount: budgetLimit, currency, options: { withCodePostfix: true } })}
                </DottedText>
              </Box>
            </InformationTooltip>
          </GridFlex.Column>

          {budgetUsed ? (
            <GridFlex.Column item>
              <Text.Caption color={getTextColorByBudgetUsage(budgetUsed, 'secondary')}>
                ({Math.floor(budgetUsed)}%)
              </Text.Caption>
            </GridFlex.Column>
          ) : null}
        </>
      ) : null}

      <CustomDrawer
        title={breakdownTitle}
        description={breakdownDescription}
        isOpen={isBreakdownOpen}
        onClose={handleCloseBreakdown}
        footer={footer}>
        <GridFlex.Column justifyContent="space-between" height="100%">
          <MaximumSpendBreakdown subscription={subscription} maxSpend={maximumSpend} />
          <Text.B2 my={4}>{t('subscriptions.maxSpend.disclaimerText')}</Text.B2>
        </GridFlex.Column>
      </CustomDrawer>
    </GridFlex.RowMiddle>
  );
}
