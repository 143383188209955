import { Box, BoxProps } from '@stigg-components';
import React, { ReactNode, useMemo, useEffect } from 'react';
import { getShowContinueButton, getStepByStepStatus } from './WizardAccordion.utils';
import { useWizardAccordionStateManagement } from './useWizardAccordionStateManagement';
import { WizardAccordionItem } from './WizardAccordionItem';

export type WizardAccordionStep<T = string> = {
  id: T;
  hide?: boolean;
  title: ReactNode;
  summary?: ReactNode;
  nextButtonText?: string;
  nextDisabled?: boolean;
  withNextIcon?: boolean;
  subTitle?: ReactNode;
  content: ReactNode | ((renderStepActionButtons: (additionalActionButtons: ReactNode) => ReactNode) => ReactNode);
  hasErrors?: boolean;
  hideStepActionsButtons?: boolean;
  alwaysExpanded?: boolean;
  descriptionStep?: boolean;
  maxWidth?: number;
  onStepContinue?: () => Promise<void | { canContinue: boolean }> | void;
};

export enum StepByStepStatus {
  NA = 'NA',
  IN_PROGRESS = 'IN_PROGRESS',
  LAST_STEP = 'LAST_STEP',
  COMPLETED = 'COMPLETED',
}

type WizardAccordionProps = {
  steps: WizardAccordionStep[];
  disableAllSteps?: boolean;
  isStepByStep?: boolean;
  nextDisabled?: boolean;
  onStepByStepStatusChange?: (status: StepByStepStatus) => void;
  uniqueFlowId: string;
  initialFocusedStep?: string;
  expandOnSingleStep?: boolean;
  showContinueButtonForLastStep?: boolean;
  alwaysShowContinueButton?: boolean;
  headerComponent?: React.ReactNode;
  layoutProps?: BoxProps;
  onlySingleStepExpanded?: boolean;
};

function WizardHeader({ headerComponent }: { headerComponent: React.ReactNode }) {
  return (
    <WizardAccordionItem
      index={-999}
      currentIndex={0}
      expandedSteps={[]}
      key="header"
      step={{
        id: 'header',
        title: 'header component',
        content: () => headerComponent,
        alwaysExpanded: true,
        descriptionStep: true,
      }}
      disableAllSteps={false}
      showContinueButton={false}
      onContinue={() => {}}
      toggleStep={() => {}}
    />
  );
}

export function WizardAccordion({
  steps,
  disableAllSteps,
  isStepByStep,
  onStepByStepStatusChange,
  uniqueFlowId,
  initialFocusedStep,
  expandOnSingleStep,
  showContinueButtonForLastStep,
  headerComponent,
  layoutProps,
  alwaysShowContinueButton,
  onlySingleStepExpanded,
}: WizardAccordionProps) {
  const [stepByStepStatus, setStepByStepStatus] = React.useState(StepByStepStatus.NA);
  const stepsToShow = useMemo(() => steps.filter(({ hide }) => !hide), [steps]);
  const { currentIndex, expandedSteps, toggleStep, onContinue } = useWizardAccordionStateManagement({
    uniqueFlowId,
    isStepByStep,
    stepsToShow,
    initialFocusedStep,
    expandOnSingleStep,
    onlySingleStepExpanded,
  });

  useEffect(() => {
    setStepByStepStatus(getStepByStepStatus(currentIndex, stepsToShow));
  }, [currentIndex, stepsToShow]);

  useEffect(() => {
    onStepByStepStatusChange?.(stepByStepStatus);
  }, [onStepByStepStatusChange, stepByStepStatus]);

  return (
    <Box {...layoutProps}>
      {headerComponent ? <WizardHeader headerComponent={headerComponent} /> : null}

      {stepsToShow.map((step, index) => {
        const isCurrentStep = index === currentIndex;
        const showContinueButton = getShowContinueButton(
          isCurrentStep,
          currentIndex,
          stepsToShow,
          showContinueButtonForLastStep,
          alwaysShowContinueButton,
        );

        return (
          <WizardAccordionItem
            key={step.id}
            step={step}
            index={index}
            currentIndex={currentIndex}
            expandedSteps={expandedSteps}
            showContinueButton={showContinueButton}
            disableAllSteps={disableAllSteps}
            onContinue={onContinue}
            toggleStep={toggleStep}
          />
        );
      })}
    </Box>
  );
}
