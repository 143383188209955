import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import {
  Button,
  Text,
  DetailsLayout,
  GridFlex,
  PageCard,
  Link,
  ClipboardChip,
  Icon,
  EmptyCardContent,
  CustomDrawer,
  Hidable,
} from '@stigg-components';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import Table from '../../../../components/table/Table';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useBreadcrumbs, useConfirmationDialog } from '../../../common';
import { useNavigation } from '../../../navigation/useNavigation';
import { fetchAddonGroupByRefIdAction, resetAddonGroup, setAddonsToAddonGroupAction } from '../addonSlice';
import { AddAddonsToAddonGroup } from './AddAddonsToAddonGroup';
import { createHeadCells } from './addonGroupAddonsHeadCells';
import { CreateOrEditAddonGroup } from './CreateOrEditAddonGroup';

type ViewAddonRouteParams = {
  refId: string;
};

export function AddonGroupPage() {
  const { refId } = useParams<ViewAddonRouteParams>();
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const [addAddonsOpen, setAddAddonsOpen] = useState(false);
  const [editAddonGroupOpen, setEditAddonGroupOpen] = useState(false);
  const [addonToRemoveId, setAddonToRemoveId] = useState<string | null>(null);
  const addonGroup = useSelector((state: RootState) => state.addonReducer.addonGroup);
  const isLoading = useSelector((state: RootState) => state.addonReducer.isLoadingAddonGroup);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const allowAddonWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);

  useBreadcrumbs({ to: '/addons?tab=add-on%20groups', title: t('breadCrumbs.addon-groups') }, { title: refId });

  useEffect(() => {
    void dispatch(fetchAddonGroupByRefIdAction({ refId }));

    return () => {
      dispatch(resetAddonGroup());
    };
  }, [dispatch, refId]);

  const setAddonsToAddonGroup = useCallback(
    async (addonIds: string[]) => {
      if (addonGroup) {
        await dispatch(
          setAddonsToAddonGroupAction({
            environmentId: currentEnvironmentId,
            packageGroupId: addonGroup.packageGroupId,
            addonIds,
          }),
        );
      }
    },
    [addonGroup, currentEnvironmentId, dispatch],
  );

  const removeAddonFromAddonGroup = async (addonId: string) => {
    if (addonGroup) {
      const addonIds = addonGroup.addons?.filter((addon) => addon.refId !== addonId).map((addon) => addon.refId) || [];
      await setAddonsToAddonGroup(addonIds);
    }
  };

  const [RemoveAddonConfirmationDialog, setDialogOpen, removeAddonDialogProps] = useConfirmationDialog({
    title: t('addons.addonGroups.removeAddonConfirmationDialogTitle'),
    content: t('addons.addonGroups.removeAddonConfirmationDialogText'),
    confirmButtonColor: 'error',
    confirmButtonText: t('sharedComponents.remove'),
    handleConfirm: async () => {
      if (addonToRemoveId) {
        await removeAddonFromAddonGroup(addonToRemoveId);
      }
    },
  });

  const onRemoveAddon = (addonId: string) => {
    setAddonToRemoveId(addonId);
    setDialogOpen(true);
  };

  const onCancel = useCallback(() => {
    setAddAddonsOpen(false);
    setEditAddonGroupOpen(false);
  }, []);

  const onEditDetails = () => {
    setEditAddonGroupOpen(true);
  };

  const excludedAddonIds = useMemo(() => addonGroup?.addons?.map((addon) => addon.refId) || [], [addonGroup?.addons]);

  if (!addonGroup || isLoading) {
    return <Loader />;
  }

  return (
    <GridFlex.Column>
      <GridFlex.Column mb={4}>
        <Text.Sub2 mb={1}>{t('addons.addonGroups.headerSubtitle')}</Text.Sub2>
        <Text.H1>{addonGroup.displayName}</Text.H1>
      </GridFlex.Column>

      <GridFlex.Column rowGap={4}>
        <PageCard>
          <GridFlex.RowSpaceBetween>
            <Text.H3 mb={4}>{t('addons.addonGroups.groupDetailsTitle')}</Text.H3>
            <Hidable show={allowAddonWrite}>
              <Button
                color="primary"
                $outlined
                onClick={() => onEditDetails()}
                startIcon={<Icon type="reactFeather" icon="Edit2" size={16} color="primary.main" />}>
                {t('sharedComponents.edit')}
              </Button>
            </Hidable>
          </GridFlex.RowSpaceBetween>

          <GridFlex.RowCenter mb={6}>
            <ClipboardChip
              text={addonGroup.packageGroupId}
              copy={addonGroup.packageGroupId}
              iconLocation="right"
              variant="code"
            />
          </GridFlex.RowCenter>

          <DetailsLayout
            details={[
              {
                title: t('sharedComponents.description'),
                content: addonGroup.description,
                shouldPrintLine: !!addonGroup.description,
              },
              {
                title: t('addons.addonGroups.productLabel'),
                content: (
                  <Link variant="body2" onClick={() => navigation.navigateTo(`/products/${addonGroup.product.refId}`)}>
                    {addonGroup.product?.displayName}
                  </Link>
                ),
              },
            ]}
          />
        </PageCard>

        <PageCard>
          <GridFlex.RowSpaceBetween mb={4}>
            <Text.H3 mb={4}>{t('addons.addonGroups.addonGroupAddonsTitle')}</Text.H3>
            <Hidable show={allowAddonWrite}>
              <Button
                color="primary"
                $outlined
                onClick={() => setAddAddonsOpen(true)}
                startIcon={<Icon type="reactFeather" icon="Plus" size={16} color="primary.main" />}>
                {t('sharedComponents.add')}
              </Button>
            </Hidable>
          </GridFlex.RowSpaceBetween>

          {isEmpty(addonGroup.addons) ? (
            <EmptyCardContent>
              <Text.B2>{t('addons.addonGroups.emptyStateAddonsText')}</Text.B2>
              <Hidable show={allowAddonWrite}>
                {' '}
                <Link variant="body2" ml={1} onClick={() => setAddAddonsOpen(true)}>
                  {t('addons.addonGroups.addFirstAddonToAddonGroup')}
                </Link>
              </Hidable>
            </EmptyCardContent>
          ) : (
            <Table
              headCells={createHeadCells(navigation, onRemoveAddon, !allowAddonWrite)}
              data={addonGroup.addons || []}
            />
          )}
        </PageCard>
      </GridFlex.Column>

      <CustomDrawer
        title={addAddonsOpen ? t('addons.addonGroups.addAddonsTitle') : t('addons.addonGroups.editPackageGroupTitle')}
        isOpen={addAddonsOpen || editAddonGroupOpen}
        onClose={onCancel}>
        {addAddonsOpen ? (
          <AddAddonsToAddonGroup
            onSubmit={setAddonsToAddonGroup}
            productId={addonGroup.product.id}
            excludedAddonIds={excludedAddonIds}
            onCancel={onCancel}
          />
        ) : editAddonGroupOpen ? (
          <CreateOrEditAddonGroup addonGroup={addonGroup} onCancel={onCancel} />
        ) : null}
      </CustomDrawer>

      <RemoveAddonConfirmationDialog {...removeAddonDialogProps} />
    </GridFlex.Column>
  );
}
