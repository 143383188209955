import { PropsWithChildren } from 'react';
import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import { Hidable } from './Hidable';

type HideInReadOnlyProps = PropsWithChildren<{ permission: EnvironmentPermissionActions }>;

export const PermissionProtected = ({ permission, ...props }: HideInReadOnlyProps) => {
  const isAllowed = useEnvironmentPermissionCheck(permission);

  return <Hidable show={isAllowed} {...props} />;
};
