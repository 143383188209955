import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Alert, ExternalLink, Link, FormRenderProps, GridFlex } from '@stigg-components';
import { Auth0ApplicationType, SubscriptionStartSetup } from '@stigg-types/apiTypes';
import { SubscriptionStartSetupConfiguration } from '../../../products/components/productPage/customerJourney/SubscriptionStartSetupConfiguration';
import { Auth0IntegrationFormFields } from './useAuth0Form';
import { RootState } from '../../../../redux/store';
import { useNavigation } from '../../../navigation/useNavigation';

type Auth0InitialCustomerAccessProps = {
  individualShowMissingMAUEntitlement: boolean | undefined;
  organizationShowMissingMAUEntitlement: boolean | undefined;
  formRenderProps: FormRenderProps<Auth0IntegrationFormFields>;
  individualStartPlanRefId: string | undefined;
  organizationStartPlanRefId: string | undefined;
  isReadOnly: boolean;
};

function MauFeatureAlert({ planRefId }: { planRefId: string }) {
  const navigation = useNavigation();

  return (
    <Alert severity="error">
      <Trans
        t={t}
        i18nKey="integrations.auth0.steps.initialCustomerAccess.activeUsersFeatureNotIncludedInPlan"
        components={[<Link onClick={() => navigation.navigateTo(navigation.appRoutes.planPage(planRefId))} />]}
      />
      <ExternalLink
        label={t('sharedComponents.learnMore')}
        url={t('integrations.auth0.steps.clientSetup.installationUrl')}
      />
    </Alert>
  );
}

export function Auth0InitialCustomerAccess({
  formRenderProps,
  individualShowMissingMAUEntitlement,
  organizationShowMissingMAUEntitlement,
  individualStartPlanRefId,
  organizationStartPlanRefId,
  isReadOnly,
}: Auth0InitialCustomerAccessProps) {
  const products = useSelector((root: RootState) => root.productReducer.products);
  const allPlans = useMemo(() => products.flatMap((product) => product.plans), [products]);
  const { values } = formRenderProps;
  const showIndividual = [Auth0ApplicationType.Both, Auth0ApplicationType.Individual].includes(values.applicationType);
  const showOrganization = [Auth0ApplicationType.Both, Auth0ApplicationType.Organization].includes(
    values.applicationType,
  );

  const showAlert = showIndividual && individualShowMissingMAUEntitlement && individualStartPlanRefId;

  return (
    <GridFlex.Column rowGap={4}>
      {showIndividual ? (
        <SubscriptionStartSetupConfiguration
          formRenderProps={formRenderProps}
          productPlans={allPlans}
          readonly={isReadOnly}
          showTrialPeriod={false}
          excludedStartSetup={[SubscriptionStartSetup.PlanSelection]}
          fieldNamePrefix="individual"
          label={
            values.applicationType === Auth0ApplicationType.Both
              ? t('integrations.auth0.steps.initialCustomerAccess.individualLabel')
              : undefined
          }
        />
      ) : null}

      {showAlert && <MauFeatureAlert planRefId={individualStartPlanRefId} />}

      {showOrganization ? (
        <SubscriptionStartSetupConfiguration
          formRenderProps={formRenderProps}
          productPlans={allPlans}
          readonly={isReadOnly}
          showTrialPeriod={false}
          excludedStartSetup={[SubscriptionStartSetup.PlanSelection]}
          fieldNamePrefix="organization"
          label={
            values.applicationType === Auth0ApplicationType.Both
              ? t('integrations.auth0.steps.initialCustomerAccess.organizationLabel')
              : undefined
          }
        />
      ) : null}

      {showOrganization && organizationShowMissingMAUEntitlement && organizationStartPlanRefId && (
        <MauFeatureAlert planRefId={organizationStartPlanRefId} />
      )}
    </GridFlex.Column>
  );
}
