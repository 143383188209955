import DataObjectIcon from '@mui/icons-material/DataObject';
import { EnvironmentPermissionActions } from '@stigg-permissions';
import { LongText, Box, Chip, GridFlex, OptionsDropdown, PermissionProtected } from '@stigg-components';
import { FeatureFragment, FeatureStatus } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { useEffect } from 'react';
import { Code, Trash2 } from 'react-feather';
import { ClipboardChip } from '../../../../components/clipboard/ClipboardChip';
import Tabs from '../../../../components/tabs/Tabs';
import { getIconColor } from '../../../../theme';
import { FeatureDetailsTab } from './FeatureDetailsTab';
import { FeatureMetadata } from './FeatureMetadata';
import { IntegrateFeature } from './IntegrateFeatureTab';

const tabs = (feature: FeatureFragment, onClose: () => void) => [
  {
    title: t('features.featureDetails'),
    content: (
      <GridFlex.Column>
        <FeatureDetailsTab feature={feature} onClose={onClose} />
      </GridFlex.Column>
    ),
  },
  {
    title: t('features.integrateToCode'),
    content: (
      <GridFlex.Column>
        <IntegrateFeature feature={feature} />
      </GridFlex.Column>
    ),
    startIcon: (isSelected: boolean) => (
      <Code size={20} color={getIconColor(isSelected ? 'primary.main' : 'default')} />
    ),
  },
  {
    title: t('features.featureMetadata'),
    content: (
      <GridFlex.Column>
        <FeatureMetadata feature={feature} />
      </GridFlex.Column>
    ),
    startIcon: (isSelected: boolean) => (
      <DataObjectIcon sx={{ color: getIconColor(isSelected ? 'primary.main' : 'default') }} />
    ),
  },
];

export function FeatureDetailsTitle({ feature }: { feature: FeatureFragment | null }) {
  if (!feature) {
    return null;
  }

  return (
    <GridFlex.Row alignItems="flex-end" gap={4} mb={1}>
      <LongText variant="h3">{feature.displayName || ''}</LongText>
      <Chip
        $hasPointer
        size="small"
        label={feature.featureStatus === FeatureStatus.New ? 'Active' : 'Draft'}
        color={feature.featureStatus === FeatureStatus.New ? 'primary' : 'success'}
        sx={{ mr: 4 }}
      />
    </GridFlex.Row>
  );
}

export function SideNavFeatureDetails({
  feature,
  onClose,
  onDelete,
  shouldUseFeatureArchiveInsteadOfDelete,
  onArchive,
}: {
  feature: FeatureFragment | null;
  onClose: () => void;
  shouldUseFeatureArchiveInsteadOfDelete: boolean;
  onArchive: (feature: FeatureFragment) => void;
  onDelete: (feature: FeatureFragment) => void;
}) {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  if (!feature) {
    return null;
  }

  return (
    <GridFlex.Column>
      <GridFlex.RowCenter justifyContent="space-between" mb={4}>
        <ClipboardChip
          text={feature.refId}
          copy={feature.refId}
          iconLocation="right"
          variant="code"
          tooltipSuffix={t('features.featureId')}
        />

        <PermissionProtected permission={EnvironmentPermissionActions.WriteEnvironment}>
          <Box sx={{ position: 'relative', top: 58, zIndex: 3 }}>
            <OptionsDropdown
              options={[
                {
                  icon: Trash2,
                  text: shouldUseFeatureArchiveInsteadOfDelete
                    ? t('sharedComponents.archive')
                    : t('sharedComponents.delete'),
                  onClick: () => {
                    return shouldUseFeatureArchiveInsteadOfDelete ? onArchive(feature) : onDelete(feature);
                  },
                },
              ]}
            />
          </Box>
        </PermissionProtected>
      </GridFlex.RowCenter>
      <Tabs data={tabs(feature, onClose)} />
    </GridFlex.Column>
  );
}
