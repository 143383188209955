import { CustomerPortalSection } from '../../../../widgets/components/previewOptionsPanel/PreviewOptionsSelectBox';
import { DEFAULT_COUNTRY_CODE } from '../../../../packages/pricing/components/currency/currencyUtils';

export const paywall = (apiKey: string, productId?: string, countryCode?: string) => {
  const snippetPartsStart = [`<StiggProvider apiKey={'${apiKey}'}>`, `  <Paywall `];
  const extraLines: string[] = [];
  const snippetEnd = [
    `    onPlanSelected={({ plan, customer }) => {`,
    `    // Handle customer intention to subscribe to plan  `,
    `   }} />`,
    `</StiggProvider>`,
  ];
  if (countryCode && countryCode !== DEFAULT_COUNTRY_CODE) {
    extraLines.push(`    billingCountryCode="${countryCode}"`);
  }
  if (productId) {
    extraLines.push(`    productId="${productId}"`);
  }
  return [...snippetPartsStart, ...extraLines, ...snippetEnd].join('\n');
};
export const customerPortal = (
  apiKey: string,
  customerId: string,
  hiddenSections: CustomerPortalSection[],
  productId?: string,
) => {
  const hiddenSectionsStr = hiddenSections.length ? `hiddenSections={['${hiddenSections.join("','")}']}` : '';
  const str = `<StiggProvider customerId="${customerId}" apiKey="${apiKey}">
  <CustomerPortal onContactSupport={() => {}} paywallComponent={
      <Paywall 
          onPlanSelected={() => {}}   
          productId="${productId}" 
      />}
 ${hiddenSectionsStr} />
</StiggProvider>
`;
  return str;
};

export const checkout = (apiKey: string, customerId?: string, planId?: string) => {
  return `<StiggProvider 
  customerId="${customerId}" 
  apiKey="${apiKey}"
>
  <Checkout 
    planId="${planId}"
    // pass a callback that handles checkout completion 
    onCheckoutCompleted={() => Promise.resolve()} 
  />
</StiggProvider>
`;
};

export const mspPortal = (apiKey: string, customerId?: string) => {
  return `<StiggProvider 
  customerId="${customerId}" 
  apiKey="${apiKey}"
>
  <MspPortal
    // pass a callback that handles checkout completion 
    onCheckoutCompleted={() => Promise.resolve()} 
  />
</StiggProvider>
`;
};
