import { Text, GridFlex, TextFieldProps } from '@stigg-components';
import { t } from 'i18next';

type FeatureUnitLabelsProps = {
  renderTextField: (id: string, label?: string, props?: Partial<TextFieldProps>) => JSX.Element;
  handleSingularChange: (e: any) => void;
  isViewMode?: boolean;
};

export function FeatureUnitLabels({ renderTextField, handleSingularChange, isViewMode }: FeatureUnitLabelsProps) {
  return (
    <GridFlex.Column $fullWidth mb={2}>
      <GridFlex.RowCenter mb={2}>
        <Text.B2 color="secondary">{t('features.unitLabel')}</Text.B2>
      </GridFlex.RowCenter>
      <GridFlex.RowCenter alignItems="flex-start" $fullWidth justifyContent="space-between" columnGap={4}>
        {renderTextField('transformedUnits', undefined, {
          placeholder: t('features.unitsLong'),
          onChange: (e) => handleSingularChange(e),
          disabled: isViewMode,
        })}
        {renderTextField('transformedUnitsPlural', undefined, {
          placeholder: t('features.unitsPluralLong'),
          disabled: isViewMode,
        })}
      </GridFlex.RowCenter>
    </GridFlex.Column>
  );
}
