import { PropsWithChildren } from 'react';
import { useAccountPermissionsCheck, AccountPermissionActions } from '@stigg-permissions';
import { Hidable } from './Hidable';

type AccountPermissionsProtectedProps = PropsWithChildren<{
  permissions: AccountPermissionActions | AccountPermissionActions[];
}>;

export const AccountPermissionsProtected = ({ permissions, ...props }: AccountPermissionsProtectedProps) => {
  const isAllowed = useAccountPermissionsCheck(Array.isArray(permissions) ? permissions : [permissions]).some(
    (allowed) => allowed,
  );

  return <Hidable show={isAllowed} {...props} />;
};
