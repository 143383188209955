import { WidgetIdentifier } from '../../useWidgetsData';
import * as reactSnippets from '../../../features/components/featureDetails/snippets/reactCodeSnippets';
import { CustomerPortalSection } from '../previewOptionsPanel/PreviewOptionsSelectBox';

export function getWidgetsCodeSnippets(
  apiKey: string,
  widgetIdentifier: WidgetIdentifier,
  customerPortalHiddenSections: CustomerPortalSection[],
  customerId?: string,
  productId?: string,
  countryCode?: string,
): string {
  switch (widgetIdentifier) {
    case WidgetIdentifier.CustomerPortal:
      return customerId
        ? reactSnippets.customerPortal(apiKey, customerId, customerPortalHiddenSections, productId)
        : '';
    case WidgetIdentifier.Paywall:
      return reactSnippets.paywall(apiKey, productId, countryCode);
    case WidgetIdentifier.Checkout:
      return reactSnippets.checkout(apiKey, customerId, productId);
    case WidgetIdentifier.MspPortal:
      return reactSnippets.mspPortal(apiKey, customerId);
    default:
      return '';
  }
}
