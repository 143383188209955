import { Card, Divider, Text, Grid, DetailsLayout, CardHeaderLayout, LongText, Chip } from '@stigg-components';
import { AwsChip } from '@stigg-common';
import { DetailsLayoutType, OptionsDropdownType } from '@stigg-components/types';
import { t } from 'i18next';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { BillingModel, ProductListItemFragment } from '@stigg-types/apiTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { Dialog } from '../../../components/Dialog';
import { EmptyCardContent } from '../../../components/EmptyCardContent';
import { useAppDispatch } from '../../../redux/store';
import { useNavigation } from '../../navigation/useNavigation';
import { deleteProductByIdAction } from '../productsSlice';
import { EditProductForm } from './productPage/EditProductForm';
import { DuplicateProductForm } from './productPage/DuplicateProductForm';
import { getProductMenuOptions } from './productPage/GetProductMenuOptions';

const CardWrapper = styled(Card)`
  padding: 32px;
  cursor: pointer;
  ${({ theme }) => (theme.isLightTheme ? '' : `background: ${theme.itamar.palette.background.paper}`)};

  &:hover {
    box-shadow: ${({ theme }) => theme.itamar.palette.shadow.lightShadow};
  }
`;

const PlanPreviewBox = styled(Grid)`
  height: 53px;
  background: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.background.default : theme.itamar.palette.background.darkBackground};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
`;

type ProductCardProps = { product: ProductListItemFragment; isReadOnly: boolean };

export function ProductCard({ product, isReadOnly }: ProductCardProps) {
  const navigation = useNavigation();
  const { plans } = product;

  const plansCount = plans?.length || 0;
  const addonsCount = product.addons.length || 0;
  const dispatch = useAppDispatch();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { duplicateProduct: duplicateProductEnabled } = useFlags<FeatureFlags>();

  const plansAndAddonsFeatureRefIds = flatMap([...product.plans, ...product.addons], (aPackage) =>
    map(aPackage.entitlements, (packageEntitlement) => packageEntitlement.feature.refId),
  );

  const filterPrices = (prices) =>
    (prices || []).filter((price) => price.billingModel !== BillingModel.FlatFee && price.feature?.refId);

  const priceFeatureRefIds = flatMap(product.plans, (plan) =>
    filterPrices(plan.prices).map((price) => price.feature?.refId),
  );

  const uniqueFeatureCount = new Set([...plansAndAddonsFeatureRefIds, ...priceFeatureRefIds]).size;

  const handleDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete) {
      await dispatch(deleteProductByIdAction(product.id));
    }
    setDeleteDialogOpen(false);
  };

  const DETAILS_WIDTH = 140;
  const details: DetailsLayoutType = [
    {
      title: t('products.refId'),
      content: product.refId,
    },
  ];
  if (product.description) {
    details.push({
      title: t('products.description'),
      content: product.description,
    });
  }

  const productCardOptions: OptionsDropdownType = getProductMenuOptions({
    setEditDialogOpen,
    setDuplicateDialogOpen,
    setDeleteDialogOpen,
    isAWSMarketplaceProduct: !!product.awsMarketplaceProductId,
    duplicateProductEnabled,
  });

  return (
    <CardWrapper onClick={() => navigation.navigateTo(`/products/${product.refId}`)}>
      <CardHeaderLayout
        title={t('products.cardSubtitle')}
        displayName={product.displayName}
        chip={{
          component: (
            <Grid gap={2}>
              <Chip size="small" label="Active" color="primary" />
              {product.awsMarketplaceProductId ? <AwsChip /> : null}
            </Grid>
          ),
        }}
        options={{
          options: productCardOptions,
        }}
        hideOptions={isReadOnly}
      />

      <DetailsLayout titleWidth={DETAILS_WIDTH} details={details} mt={4} />

      <Divider mb={4} mt={4} />

      {plansCount === 0 && addonsCount === 0 ? (
        <EmptyCardContent mb={5}>
          <Text.Sub2 mr={1}>{t('products.emptyStateText')}</Text.Sub2>
        </EmptyCardContent>
      ) : (
        <Grid container>
          <DetailsLayout
            titleWidth={DETAILS_WIDTH}
            details={[
              {
                title: t('products.productOfferingTitle'),
                content: (
                  <>
                    {uniqueFeatureCount} features, {plansCount} plan{plansCount > 1 ? 's' : ''}
                    {addonsCount > 0 ? `, ${addonsCount} add-on${addonsCount > 1 ? 's' : ''}` : ''}
                  </>
                ),
              },
            ]}
          />
          <Grid item container wrap="nowrap" mt={5}>
            {map(plans, (plan) => (
              <PlanPreviewBox
                display="flex"
                flexDirection="row"
                flexWrap="nowrap"
                gap={2}
                key={plan.id}
                container
                item
                alignItems="center"
                justifyContent="space-between"
                md={4}
                mr={3}
                px={4}>
                <Grid item>
                  <LongText variant="body2" sx={{ fontWeight: 700 }}>
                    {plan.displayName}
                  </LongText>
                </Grid>
                <Grid item minWidth={76}>
                  <Text.Sub2>
                    {
                      new Set(
                        flatMap(
                          [
                            ...(plan.entitlements || []),
                            ...(plan.inheritedEntitlements || []),
                            ...filterPrices(plan.prices),
                          ],
                          (item) => item.feature?.refId,
                        ),
                      ).size
                    }{' '}
                    features
                  </Text.Sub2>
                </Grid>
              </PlanPreviewBox>
            ))}
          </Grid>
        </Grid>
      )}

      <Dialog
        content={<EditProductForm productRefId={product.refId} closeDialog={() => setEditDialogOpen(false)} />}
        titleText={t('products.editProductDialogTitle')}
        isOpen={editDialogOpen}
        onCancel={() => setEditDialogOpen(false)}
        width={730}
      />

      <Dialog
        content={
          <DuplicateProductForm productRefId={product.refId} closeDialog={() => setDuplicateDialogOpen(false)} />
        }
        titleText={t('products.duplicateProductDialogTitle')}
        isOpen={duplicateDialogOpen}
        onCancel={() => setDuplicateDialogOpen(false)}
        width={730}
      />

      <ConfirmationDialog
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        title={t('products.delete')}
        content={t('products.deleteConfirmation')}
      />
    </CardWrapper>
  );
}
