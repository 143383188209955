import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  ProductFilter,
  ProductSortFields,
  FetchProductsQuery,
  FetchProductsQueryVariables,
  SortDirection,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';

export const FETCH_SLIM_PRODUCTS = gql`
  query FetchSlimProducts($paging: CursorPaging, $filter: ProductFilter!) {
    products(paging: $paging, filter: $filter) {
      edges {
        node {
          refId
          displayName
        }
      }
    }
  }
`;

const FETCH_PRODUCTS = gql`
  query FetchProducts($paging: CursorPaging, $sorting: [ProductSort!], $filter: ProductFilter!) {
    products(paging: $paging, sorting: $sorting, filter: $filter) {
      totalCount
      edges {
        node {
          ...ProductListItemFragment
        }
      }
    }
  }
  fragment ProductListItemFragment on Product {
    createdAt
    description
    id
    displayName
    refId
    isDefaultProduct
    environmentId
    updatedAt
    multipleSubscriptions
    awsMarketplaceProductId
    awsMarketplaceProductCode
    productSettings {
      subscriptionEndSetup
      subscriptionCancellationTime
      downgradePlan {
        refId
        id
      }
    }
    plans {
      id
      refId
      displayName
      description
      pricingType
      defaultTrialConfig {
        duration
        units
        budget {
          limit
        }
        trialEndBehavior
      }
      product {
        id
        displayName
      }
      type
      status
      prices {
        billingModel
        feature {
          refId
        }
      }
      entitlements {
        id
        hasUnlimitedUsage
        hasSoftLimit
        feature {
          refId
        }
      }
      inheritedEntitlements {
        id
        hasUnlimitedUsage
        hasSoftLimit
        feature {
          refId
        }
      }
    }
    addons {
      id
      refId
      displayName
      description
      type
      status
      entitlements {
        id
        hasUnlimitedUsage
        hasSoftLimit
        feature {
          refId
        }
      }
    }
  }
`;

export type FetchProductsProps = {
  environmentId: string;
  silentFetch?: boolean;
};

async function fetchProducts(props: FetchProductsProps, { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      const filter: ProductFilter = {
        environmentId: { eq: props.environmentId },
      };
      const response = await apolloClient.query<FetchProductsQuery, FetchProductsQueryVariables>({
        query: FETCH_PRODUCTS,
        fetchPolicy: 'network-only',
        variables: {
          sorting: { field: ProductSortFields.CreatedAt, direction: SortDirection.Asc },
          filter,
        },
      });
      return response.data.products.edges.map((edge) => edge.node);
    },
    {
      failureMessageHandler: () => t('products.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchProducts };
