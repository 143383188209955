import { useEffect, useCallback, useState } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { AwsChip, isActiveSubscriptionUtil } from '@stigg-common';
import {
  Button,
  DateText,
  EmptyCardContent,
  Grid,
  GridFlex,
  HighlightText,
  Icon,
  Link,
  LongText,
  SubscriptionsOptionsDropdown,
  Text,
} from '@stigg-components';
import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import {
  BillingPeriod,
  CustomerResponseFragment,
  CustomerSubscriptionDataFragment,
  PaymentCollection,
  PricingType,
  SubscriptionDataFragment,
  SubscriptionScheduleStatus,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { Dialog } from '../../../../../components/Dialog';
import createPageChangeFunc from '../../../../../components/table/gqlTableHelper';
import Table, { HeadCell } from '../../../../../components/table/Table';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { useNavigation } from '../../../../navigation/useNavigation';
import { SubscriptionAtExperiment } from '../../../../packages/common/components/SubscriptionAtExperiment';
import {
  fetchCustomerByRefIdAction,
  fetchCustomerSubscriptionsAction,
  fetchCustomerSubscriptionsResourcesAction,
  fetchNonActiveSubscriptionsCountAction,
  triggerSubscriptionUsageSyncAction,
} from '../../../customersSlice';
import { OutdatedSubscriptionSign } from '../../subscriptions/subscriptionInfo/components/OutdatedSubscriptionSign';
import { useCancelFutureUpdatesDialog } from '../../subscriptions/hooks';
import { usePollCustomerSubscriptionPaymentProcessing } from '../usePollCustomerSubscriptionPaymentProcessing';
import { CancelSubscriptionForm } from './CancelSubscriptionForm';
import { getPriceBreakdown, mapSubscriptionToBillableFeatures } from './priceBreakdown';
import { SubscriptionPrice } from './SubscriptionPrice';
import { SubscriptionResourceLabel } from './SubscriptionResourceLabel';
import { ExtraSubscriptionStatuses, SubscriptionStatusChip } from './SubscriptionStatusChip';
import { TrialSubscriptionIndicatorIcon } from './TrialSubscriptionIndicatorIcon';
import Search from '../../../../../components/Search';
import { sortCharges } from './useChargeSort';
import { hasFutureUpdates } from '../../subscriptions/utils/futureUpdates.utils';
import { isInvoicePendingPayment } from '../../subscriptions/subscriptionInfo/components/paymentCollection/PaymentCollectionBanner.utils';
import { DelegatedSubscriptionIcon } from './DelegatedSubscriptionIcon';

const isAwsMarketplaceManagedSubscription = (subscription: CustomerSubscriptionDataFragment) =>
  !!subscription?.plan?.awsMarketplacePlanDimension;

const createHeadCells = (
  onCancelSubscriptionClick: (entity: CustomerSubscriptionDataFragment) => void,
  onUpdateSubscriptionClick: (entity: CustomerSubscriptionDataFragment) => void,
  onCancelSubscriptionScheduleClick: (entity: CustomerSubscriptionDataFragment) => void,
  onTriggerSubscriptionUsageSync: (entity: CustomerSubscriptionDataFragment) => void,
  customer: CustomerResponseFragment,
  search: string,
  disableOptions = false,
  showDefaultPricingIcon = false,
): Array<HeadCell<CustomerSubscriptionDataFragment, any>> => [
  {
    id: 'planName',
    alignment: 'left',
    label: t('customers.subscriptionPlanColumn'),
    render: (entity: CustomerSubscriptionDataFragment) => {
      return (
        <GridFlex.RowSpaceBetween justifyItems="center" container wrap="nowrap" py={1}>
          <DelegatedSubscriptionIcon customer={customer} payingCustomer={entity.payingCustomer} />
          <GridFlex.Column data-testid="customer-subscription-item" container wrap="nowrap">
            <GridFlex.RowCenter item container>
              <LongText variant="body2" sx={{ marginRight: 2 }}>
                <HighlightText
                  highlight={search}
                  text={`${entity.plan.product.displayName} / ${entity.plan.displayName}`}
                />{' '}
              </LongText>
              <TrialSubscriptionIndicatorIcon subscription={entity} />
              <OutdatedSubscriptionSign subscription={entity} disabled={!isActiveSubscriptionUtil(entity.status)} />
              {entity.experimentInfo && <SubscriptionAtExperiment status={entity.experimentInfo.status} />}
            </GridFlex.RowCenter>
            <Grid item>
              <Text.Sub2>
                <HighlightText
                  highlight={search}
                  text={`${entity.addons?.length ? `${entity.addons.length} add-ons` : ''}`}
                />
              </Text.Sub2>
            </Grid>
            {entity.resource ? (
              <Grid item mt={2}>
                <SubscriptionResourceLabel resource={entity.resource} highlight={search} />
              </Grid>
            ) : null}
          </GridFlex.Column>

          {isAwsMarketplaceManagedSubscription(entity) ? <AwsChip size="medium" logoOnly /> : null}
        </GridFlex.RowSpaceBetween>
      );
    },
  },
  {
    id: 'subscriptionStatus',
    alignment: 'left',
    label: t('customers.subscriptionStatusColumn'),
    render: (entity: CustomerSubscriptionDataFragment) => {
      return (
        <GridFlex.Column
          my={4}
          gap={2}
          data-testid={`customer-subscriptions-table-statuschip-${entity.status}-planrefid-${entity.plan.refId}${
            entity.resource ? `-resourcerefid-${entity.resource.resourceId}` : ''
          }`}>
          {entity.status !== SubscriptionStatus.PaymentPending && (
            <GridFlex.Item>
              <SubscriptionStatusChip hasPointer status={entity.status} cancellationDate={entity.cancellationDate} />
            </GridFlex.Item>
          )}
          {entity.paymentCollection !== PaymentCollection.NotRequired && (
            <GridFlex.Item>
              <SubscriptionStatusChip
                hasPointer
                status={
                  entity.paymentCollection === PaymentCollection.Processing ||
                  isInvoicePendingPayment(entity.latestInvoice?.errorMessage)
                    ? PaymentCollection.Processing
                    : ExtraSubscriptionStatuses.PaymentError
                }
              />
            </GridFlex.Item>
          )}
          {hasFutureUpdates(entity, SubscriptionScheduleStatus.Scheduled) && (
            <GridFlex.Item>
              <SubscriptionStatusChip
                hasPointer
                status={ExtraSubscriptionStatuses.ScheduledUpdate}
                tooltip={<Text.B2>{t('customers.scheduleLeft')}</Text.B2>}
              />
            </GridFlex.Item>
          )}
        </GridFlex.Column>
      );
    },
  },
  {
    id: 'subscriptionPrice',
    alignment: 'left',
    label: t('customers.subscriptionPriceColumn'),
    render: (entity: CustomerSubscriptionDataFragment) => {
      const planPrices = entity.plan.prices;
      const subscriptionPrices = entity.prices || [];
      const hasPrices =
        !isEmpty(subscriptionPrices) && subscriptionPrices?.some((subscriptionPrice) => subscriptionPrice.price);

      if (entity.pricingType === PricingType.Free) {
        return <Text.B2>{t('subscriptions.freePricing')}</Text.B2>;
      }
      if (entity.pricingType === PricingType.Custom || !hasPrices) {
        return <Text.B2>{t('subscriptions.customPricing')}</Text.B2>;
      }

      const billableFeatures = mapSubscriptionToBillableFeatures(entity);

      const subscriptionAddons = (entity.addons || []).map((addon) => ({
        quantity: addon.quantity,
        displayName: addon.addon.displayName,
        price: addon.price,
        addonId: addon.addon.refId,
      }));
      const priceBreakdown = getPriceBreakdown({
        planPrices: sortCharges(compact(subscriptionPrices.map((sp) => sp.price))),
        addonsPrices: subscriptionAddons,
        billableFeatures,
        coupon: customer.coupon || entity.coupon,
        withZeroQuantityPrices: undefined,
        paidOneOffAddons: undefined,
        freeItems: entity.freeItems,
        minimumSpend: entity.minimumSpend?.minimum?.amount,
      });
      const hasMonthlyPrice = planPrices?.some((price) => price.billingPeriod === BillingPeriod.Monthly);
      const showMonthlyPriceVariation = !!(
        hasMonthlyPrice && subscriptionPrices?.[0]?.price?.billingPeriod === BillingPeriod.Annually
      );

      return (
        hasPrices && (
          <SubscriptionPrice
            priceBreakdown={priceBreakdown}
            coupon={customer.coupon || entity.coupon}
            showMonthlyPriceVariation={showMonthlyPriceVariation}
            showDefaultPricingIcon={showDefaultPricingIcon}
            showBillingPeriod
          />
        )
      );
    },
  },
  {
    id: 'startDate',
    alignment: 'left',
    label: t('customers.subscriptionStartDateColumn'),
    render: (entity: CustomerSubscriptionDataFragment) => <DateText date={entity.startDate} withTime />,
  },
  {
    id: 'endDate',
    alignment: 'left',
    label: t('customers.subscriptionEndDateColumn'),
    render: (entity: CustomerSubscriptionDataFragment) => {
      return entity.effectiveEndDate ? (
        <DateText date={entity.effectiveEndDate} withTime isEndDate={entity.status === SubscriptionStatus.InTrial} />
      ) : null;
    },
  },
  {
    id: 'options',
    alignment: 'right',
    label: '',
    width: 60,
    maxWidth: 36 + 8,
    disablePadding: true,
    disableClick: true,
    visible: !disableOptions,
    render: (entity: CustomerSubscriptionDataFragment) => {
      return (
        <SubscriptionsOptionsDropdown
          subscription={entity}
          isAwsMarketplaceManagedSubscription={isAwsMarketplaceManagedSubscription(entity)}
          onCancelSubscriptionClick={() => onCancelSubscriptionClick(entity)}
          onUpdateSubscriptionClick={() => onUpdateSubscriptionClick(entity)}
          onCancelSubscriptionScheduleClick={() => onCancelSubscriptionScheduleClick(entity)}
          onTriggerSubscriptionUsageSync={() => onTriggerSubscriptionUsageSync(entity)}
        />
      );
    },
  },
];

export const CUSTOMER_SUBSCRIPTIONS_PAGE_SIZE = 5;

export type CustomerSubscriptionProps = {
  customer: CustomerResponseFragment;
  onUpdateSubscriptionClick: (subscription: CustomerSubscriptionDataFragment) => void;
  onCreateSubscriptionClick: () => void;
  hasSubscriptions: boolean;
  isDelegatedSubscriptions?: boolean;
};

export function CustomerSubscriptions({
  customer,
  isDelegatedSubscriptions,
  onCreateSubscriptionClick,
  onUpdateSubscriptionClick,
  hasSubscriptions,
}: CustomerSubscriptionProps) {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const [search, setSearch] = useState('');
  const [loadHistory, setLoadHistory] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const isLoadingCustomerSubscriptions = useSelector((root: RootState) => root.customersReducer.isLoadingSubscriptions);
  const allowSubscriptionWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.SubscriptionWrite);
  const isReadOnly = isDelegatedSubscriptions || !allowSubscriptionWrite;

  const isLoadingNumberOfInactiveSubscriptions = useSelector(({ customersReducer }: RootState) =>
    isDelegatedSubscriptions
      ? customersReducer.isLoadingNonActiveDelegatedSubscriptionsCount
      : customersReducer.isLoadingNonActiveSubscriptionsCount,
  );
  const numberOfInactiveSubscriptions =
    useSelector(({ customersReducer }: RootState) =>
      isDelegatedSubscriptions
        ? customersReducer.nonActiveDelegatedSubscriptionsCount
        : customersReducer.nonActiveSubscriptionsCount,
    ) || 0;

  const { id: customerId, customerId: customerRefId } = customer;
  const products = useSelector((state: RootState) => state.productReducer.products);
  const hasMultiSubscriptionProduct = products.some((product) => product.multipleSubscriptions);
  const { pageInfo, totalCount, edges } = useSelector(({ customersReducer }: RootState) =>
    isDelegatedSubscriptions ? customersReducer.customerDelegatedSubscriptions : customersReducer.customerSubscriptions,
  );
  const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] = useState(false);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState<CustomerSubscriptionDataFragment | null>(null);
  const [subscriptionToCancelScheduledUpdates, setSubscriptionToCancelScheduledUpdates] =
    useState<CustomerSubscriptionDataFragment | null>(null);
  const [CancelScheduledUpdatesDialog, showCancelScheduledUpdatesDialog, CancelScheduledUpdatesDialogProps] =
    useCancelFutureUpdatesDialog({
      subscription: subscriptionToCancelScheduledUpdates as unknown as SubscriptionDataFragment,
      futureUpdateStatus: SubscriptionScheduleStatus.Scheduled,
    });
  const { customerSubscriptionsPageSizeOptions: customerSubscriptionsPageSizeOptionsEnabled } =
    useFlags<FeatureFlags>();
  const [pageSize, setPageSize] = useState(CUSTOMER_SUBSCRIPTIONS_PAGE_SIZE);

  useEffect(() => {
    if (!isEmpty(customerId)) {
      const customerArgs = isDelegatedSubscriptions ? { payingCustomerId: customerId } : { customerId };

      void dispatch(
        fetchCustomerSubscriptionsAction({ ...customerArgs, search, loadHistory, paging: { first: pageSize } }),
      );
      void dispatch(fetchNonActiveSubscriptionsCountAction({ ...customerArgs, search }));
    }
  }, [dispatch, isDelegatedSubscriptions, customerId, search, loadHistory, pageSize]);

  const triggerFetchCustomerSubscriptions = useCallback(() => {
    setPageNum(0);

    void dispatch(
      fetchCustomerSubscriptionsAction({
        ...(isDelegatedSubscriptions ? { payingCustomerId: customerId } : { customerId }),
        isSilentLoading: true,
        paging: { first: pageSize },
      }),
    );
  }, [isDelegatedSubscriptions, customerId, dispatch, pageSize]);
  usePollCustomerSubscriptionPaymentProcessing(triggerFetchCustomerSubscriptions);

  const pageChangeFunc = createPageChangeFunc(
    dispatch,
    pageNum,
    pageInfo,
    setPageNum,
    fetchCustomerSubscriptionsAction,
    {
      ...(isDelegatedSubscriptions ? { payingCustomerId: customerId } : { customerId }),
      search,
      loadHistory,
    },
    pageSize,
  );

  const onCancelSubscriptionClick = (subscription: CustomerSubscriptionDataFragment) => {
    setCancelSubscriptionDialogOpen(true);
    setSubscriptionToDelete(subscription);
  };

  const closeCancelSubscriptionDialog = () => {
    setCancelSubscriptionDialogOpen(false);
  };

  const refetchCustomerOnCancelSubscription = async () => {
    const fetchSubscriptionsArgs = {
      search,
      loadHistory,
      ...(isDelegatedSubscriptions ? { payingCustomerId: customerId } : { customerId }),
    };

    await Promise.all([
      dispatch(fetchCustomerByRefIdAction({ customerId: customerRefId, isSilentLoading: true })),
      dispatch(fetchCustomerSubscriptionsAction({ ...fetchSubscriptionsArgs, isSilentLoading: true })),
      dispatch(fetchNonActiveSubscriptionsCountAction({ ...fetchSubscriptionsArgs, isSilentLoading: true })),
      dispatch(fetchCustomerSubscriptionsResourcesAction({ customerId, isSilentLoading: true })),
    ]);
  };

  const onCancelSubscriptionScheduleClick = (subscription: CustomerSubscriptionDataFragment) => {
    showCancelScheduledUpdatesDialog(true);
    setSubscriptionToCancelScheduledUpdates(subscription);
  };

  const onTriggerSubscriptionUsageSync = (subscription: CustomerSubscriptionDataFragment) => {
    void dispatch(
      triggerSubscriptionUsageSyncAction({
        customerId: customerRefId,
        resourceId: subscription.resource?.resourceId,
      }),
    );
  };

  const shouldHighlightRow = useCallback(
    (current: CustomerSubscriptionDataFragment, prev: CustomerSubscriptionDataFragment) =>
      prev.status !== current.status,
    [],
  );

  const shouldAllowSearch = hasSubscriptions || !isEmpty(search);

  const hasSubscriptionWithBillingCountryCode = edges
    ?.map((x) => x.node)
    .flatMap(({ prices }) => prices)
    .some((subscriptionPrice) => subscriptionPrice?.price?.billingCountryCode);

  return (
    <>
      <GridFlex.Column>
        {shouldAllowSearch || numberOfInactiveSubscriptions > 0 ? (
          <GridFlex.RowSpaceBetween height={48} mb={4}>
            <GridFlex.RowCenter gap={4}>
              <GridFlex.Item flex={1} minWidth={340} maxWidth={420}>
                <Search
                  variant="outlined"
                  handleSearchFunc={(searchTerm) => {
                    setSearch(searchTerm);
                    if (!isEmpty(searchTerm)) {
                      setPageNum(0);
                    }
                  }}
                  placeholder={
                    hasMultiSubscriptionProduct
                      ? t('customers.subscriptionSearchPlaceholder')
                      : t('customers.subscriptionWithoutResourceSearchPlaceholder')
                  }
                  searchedStr={search}
                  isLoading={isLoadingNumberOfInactiveSubscriptions}
                  searchOnChange
                />
              </GridFlex.Item>

              {numberOfInactiveSubscriptions > 0 && (
                <Button
                  variant="text"
                  onClick={() => setLoadHistory((prev) => !prev)}
                  disabled={isLoadingCustomerSubscriptions}
                  data-testid="show-hide-history">
                  <GridFlex.RowCenter gap={1}>
                    {loadHistory && <Icon icon="Clear" />}
                    {t(loadHistory ? 'customers.hideHistory' : 'customers.loadHistory', {
                      numberOfSubscriptions: numberOfInactiveSubscriptions,
                    })}
                  </GridFlex.RowCenter>
                </Button>
              )}
            </GridFlex.RowCenter>

            {!isReadOnly ? (
              <Button
                color="primary"
                $outlined
                onClick={onCreateSubscriptionClick}
                data-testid="button-add-subscription">
                <AddIcon />
                {t('customers.addSubscription')}
              </Button>
            ) : null}
          </GridFlex.RowSpaceBetween>
        ) : undefined}

        {!isLoadingCustomerSubscriptions && isEmpty(edges) ? (
          <EmptyCardContent>
            <Text.Sub2>
              {!hasSubscriptions ? (
                <GridFlex.Row>
                  <Text.Sub2>{t('customers.subscriptionsSectionEmpty')}</Text.Sub2>
                  {!isReadOnly ? (
                    <Link
                      data-testid="empty-state-add-subscription"
                      variant="body2"
                      ml={1}
                      onClick={onCreateSubscriptionClick}>
                      {t('customers.subscriptionsSectionEmptyCTA')}
                    </Link>
                  ) : null}
                </GridFlex.Row>
              ) : (
                <Text.Sub2>{t('customers.noActiveSubscriptions')}</Text.Sub2>
              )}
            </Text.Sub2>
          </EmptyCardContent>
        ) : (
          <Table
            rowHeight={82}
            isLoading={isLoadingCustomerSubscriptions}
            label={t('customers.subscriptionsTableLabel')}
            skeletonOptions={{ rowsCount: 3 }}
            headCells={createHeadCells(
              onCancelSubscriptionClick,
              onUpdateSubscriptionClick,
              onCancelSubscriptionScheduleClick,
              onTriggerSubscriptionUsageSync,
              customer,
              search,
              isReadOnly,
              hasSubscriptionWithBillingCountryCode,
            )}
            data={edges?.map((x) => x.node as CustomerSubscriptionDataFragment) || []}
            pageNum={pageNum}
            pageSize={pageSize}
            totalCount={totalCount}
            pageChangeFunc={pageChangeFunc}
            shouldHighlightRow={shouldHighlightRow}
            onRowClick={(subscription: CustomerSubscriptionDataFragment) =>
              navigation.navigateTo(`/subscriptions/${subscription.subscriptionId}`)
            }
            rowsPerPageOptions={
              customerSubscriptionsPageSizeOptionsEnabled
                ? [CUSTOMER_SUBSCRIPTIONS_PAGE_SIZE, 25, 50]
                : [CUSTOMER_SUBSCRIPTIONS_PAGE_SIZE]
            }
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
        )}
      </GridFlex.Column>
      <CancelScheduledUpdatesDialog {...CancelScheduledUpdatesDialogProps} />

      <Dialog
        content={
          <CancelSubscriptionForm
            subscription={subscriptionToDelete}
            onClose={closeCancelSubscriptionDialog}
            customerId={customerId}
            onSubscriptionCancel={refetchCustomerOnCancelSubscription}
          />
        }
        titleText={t('subscriptions.cancelSubscriptionDialogTitle')}
        isOpen={cancelSubscriptionDialogOpen}
        onCancel={closeCancelSubscriptionDialog}
        aria-labelledby="edit-customer-dialog"
      />
    </>
  );
}
