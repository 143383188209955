import { ApolloQueryResult, gql } from '@apollo/client';
import { t } from 'i18next';
import {
  GetAuth0ApplicationsInput,
  GetAuth0ApplicationsQuery,
  GetAuth0ApplicationsQueryVariables,
} from '@stigg-types/apiTypes';
import { useQuery } from '@tanstack/react-query';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { useAppDispatch } from '../../../redux/store';

const FETCH_AUTH0_APPLICATIONS = gql`
  query GetAuth0Applications($input: GetAuth0ApplicationsInput!) {
    getAuth0Applications(input: $input) {
      ...Auth0ApplicationFragment
    }
  }
  fragment Auth0ApplicationFragment on Auth0ApplicationDTO {
    appId
    name
    type
  }
`;

export const useGetAuth0Applications = (
  input: GetAuth0ApplicationsInput,
  { enabled }: { enabled?: boolean } = { enabled: true },
) => {
  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: ['auth0Applications', input.clientDomain, input.clientSecret, input.clientId],
    enabled,
    queryFn: () =>
      executeOperationSafely(
        () =>
          apolloClient.query<GetAuth0ApplicationsQuery, GetAuth0ApplicationsQueryVariables>({
            fetchPolicy: 'network-only',
            query: FETCH_AUTH0_APPLICATIONS,
            variables: {
              input,
            },
          }),
        {
          failureMessageHandler: () => t('integrations.api.failFetchAuth0Applications'),
        },
        dispatch,
      ),
    select: (result: ApolloQueryResult<GetAuth0ApplicationsQuery>) => result.data?.getAuth0Applications,
    retry: 0, // we automatically retry in apollo client
  });
};
