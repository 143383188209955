import {
  useMeteredEntitlement as useStiggMeteredEntitlement,
  MeteredEntitlementFallback,
  MeteredEntitlement,
} from '@stigg/react-sdk';
import { StiggFeature } from '../StiggFeature';

const METERED_ENTITLEMENT_FALLBACK: MeteredEntitlementFallback = {
  hasAccess: true,
  isUnlimited: true,
  usageLimit: undefined,
};

export function useMeteredEntitlement({
  feature,
  requestedUsage = 0,
}: {
  feature: StiggFeature;
  requestedUsage: number;
}): MeteredEntitlement {
  return useStiggMeteredEntitlement({
    featureId: feature,
    options: {
      requestedUsage,
      fallback: METERED_ENTITLEMENT_FALLBACK,
    },
  });
}
