import {
  DetailsLayout,
  Divider,
  CardHeaderLayout,
  Icon,
  GridFlex,
  PageCard,
  InformationTooltip,
  Grid,
  Chip,
} from '@stigg-components';
import { AwsChip } from '@stigg-common';
import { DetailsLayoutType, OptionsDropdownType } from '@stigg-components/types';
import { ProductFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { SubscriptionTypeTooltipContent } from './ProductSubscriptionType.utils';
import { getProductMenuOptions } from './GetProductMenuOptions';

const multipleSubscriptionsIcon = <Icon icon="MultipleSubscriptions" type="custom" color="active" size={16} />;
const singleSubscriptionsIcon = <Icon icon="FileText" type="reactFeather" color="active" size={16} />;

export type ProductProps = {
  product: ProductFragment;
  setEditDialogOpen: (open: boolean) => void;
  setDuplicateDialogOpen: (open: boolean) => void;
  setDeleteDialogOpen: (open: boolean) => void;
  isReadOnly: boolean;
};

export default function ProductDetails(props: ProductProps) {
  const {
    product: { displayName, description, refId, multipleSubscriptions, awsMarketplaceProductId },
    setEditDialogOpen,
    setDuplicateDialogOpen,
    setDeleteDialogOpen,
    isReadOnly,
  } = props;

  const isAWSMarketplaceProduct = !!awsMarketplaceProductId;

  const { multipleSubscriptionsUi: multipleSubscriptionsUiEnabled, duplicateProduct: duplicateProductEnabled } =
    useFlags<FeatureFlags>();

  const details: DetailsLayoutType = [
    {
      title: t('products.refId'),
      content: refId,
      withLabel: isAWSMarketplaceProduct,
      copyableChip: true,
    },
    {
      title: t('products.awsProductId'),
      content: awsMarketplaceProductId,
      withLabel: true,
      copyableChip: true,
      shouldPrintLine: isAWSMarketplaceProduct,
    },
    {
      title: t('products.description'),
      content: description,
    },
    {
      title: t('products.subscriptionType'),
      content: (
        <InformationTooltip
          arrow
          placement="right"
          title={<SubscriptionTypeTooltipContent multipleSubscriptions={multipleSubscriptions} />}
          $maxWidth={300}>
          <GridFlex.RowCenter gap={1}>
            {multipleSubscriptions ? multipleSubscriptionsIcon : singleSubscriptionsIcon}
            {multipleSubscriptions
              ? t('products.multipleActiveSubscriptions')
              : t('products.singleActiveSubscriptions')}
          </GridFlex.RowCenter>
        </InformationTooltip>
      ),
      shouldPrintLine: multipleSubscriptionsUiEnabled,
    },
  ];

  const productCardOptions: OptionsDropdownType = getProductMenuOptions({
    setEditDialogOpen,
    setDuplicateDialogOpen,
    setDeleteDialogOpen,
    isAWSMarketplaceProduct,
    duplicateProductEnabled,
  });

  return (
    <PageCard>
      <CardHeaderLayout
        title={t('products.cardSubtitle')}
        displayName={displayName}
        chip={{
          component: (
            <Grid gap={2}>
              <Chip size="small" label="Active" color="primary" />
              {awsMarketplaceProductId ? <AwsChip /> : null}
            </Grid>
          ),
        }}
        options={{
          options: productCardOptions,
        }}
        hideOptions={isReadOnly}
      />

      <Divider mb={4} mt={4} />

      <DetailsLayout details={details} />
    </PageCard>
  );
}
