import 'monaco-themes/themes/Cobalt.json';
import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import { styled } from '@stigg-theme';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { GridFlex, Grid, Text, useScrollableAnchor, CollapsableSection } from '@stigg-components';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import Editor, { Monaco } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { WidgetIdentifier } from '../../useWidgetsData';
import { WidgetConfig } from '../../hooks/useWidgetConfiguration';
import { getWidgetMonacoSuggestions } from './editorSuggestions';

const MonacoWrapper = styled(GridFlex.Column)`
  .overflow-guard {
    border-radius: 10px;
  }
  .monaco-editor {
    border-radius: 10px;
    z-index: 9999999;
  }

  .suggest-widget {
    z-index: 9999999;
  }

  .monaco-list {
    z-index: 9999999;
  }
`;

export function CustomCssEditor({
  widgetConfig,
  widgetIdentifier,
}: {
  widgetConfig: WidgetConfig;
  widgetIdentifier: WidgetIdentifier;
}) {
  const theme = useTheme();
  const monacoRef = useRef<Monaco | null>(null);
  const editorRef = useRef<monaco.editor.IStandaloneDiffEditor | null>(null);
  const [isColorsSectionOpen, setIsColorsSectionOpen] = useState(true);
  const [value, setValue] = useState(
    widgetConfig.configuration.css ||
      `/* Add custom CSS to specific 
    Stigg elements */`,
  );

  const allowWidgetsWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);

  const suggestions = useMemo(() => getWidgetMonacoSuggestions(widgetIdentifier), [widgetIdentifier]);

  const handleEditorDidMount = (editor: any, monaco: Monaco) => {
    // here is the editor instance
    // you can store it in `useRef` for further usage
    monacoRef.current = monaco;
    editorRef.current = editor;
    // Open the auto-complete docs by default
    const suggestWidget = editor.getContribution('editor.contrib.suggestController').widget.value;
    if (suggestWidget) {
      suggestWidget._setDetailsVisible(true);
      if (suggestWidget._persistedSize) {
        suggestWidget._persistedSize.store({ width: 300, height: 256 });
      }
    }
    const themePromise = theme.isLightTheme
      ? import('monaco-themes/themes/Cobalt.json')
      : import('monaco-themes/themes/GitHub Dark.json');

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    themePromise
      .then((data: any) => {
        monaco.editor.defineTheme('cobalt', data);
      })
      .then((_) => monaco.editor.setTheme('cobalt'));

    editor?.updateOptions({ readOnly: !allowWidgetsWrite });
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current?.updateOptions({ readOnly: !allowWidgetsWrite });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef.current, allowWidgetsWrite]);

  useEffect(() => {
    if (monacoRef.current) {
      const dispose = monacoRef.current.languages?.registerCompletionItemProvider('css', {
        triggerCharacters: ["'", '"', '.', '/'],
        provideCompletionItems: (model, position) => {
          const word = model.getWordUntilPosition(position);
          const range = {
            startLineNumber: position.lineNumber,
            endLineNumber: position.lineNumber,
            startColumn: word.startColumn,
            endColumn: word.endColumn,
          };

          return {
            suggestions: suggestions.map((suggestion) => ({
              label: suggestion.label,
              detail: suggestion.detail,
              documentation: {
                value: `${suggestion.documentation} \n\n [See docs for more details](https://docs.stigg.io/docs/react-sdk#customization-options)`,
              },
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              kind: monacoRef.current!.languages.CompletionItemKind.Function,
              insertText: suggestion.label,
              range,
            })),
          };
        },
      });

      return () => {
        dispose.dispose();
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monacoRef.current]);
  const { elementRef } = useScrollableAnchor({ anchor: 'customCssEditor' });

  return (
    <Grid ref={elementRef}>
      <CollapsableSection
        title="Custom CSS"
        titleHelperTooltip={t('widgets.customCssTooltip')}
        titleHelperTooltipLink={t('widgets.customCssTooltipLink')}
        isSectionOpen={isColorsSectionOpen}
        sx={{
          color: (theme) => theme.itamar.palette.text.primary,
        }}
        $fullWidth
        onClick={() => setIsColorsSectionOpen(!isColorsSectionOpen)}
        content={
          <MonacoWrapper gap={2} mt={4}>
            <Editor
              key={widgetIdentifier.toString()}
              onMount={handleEditorDidMount}
              height="300px"
              theme="vs-dark"
              defaultLanguage="css"
              value={value}
              options={{ minimap: { enabled: false } }}
              onChange={(value) => {
                if (value) {
                  setValue(value);
                  widgetConfig.onThemeChanged({
                    css: value,
                  });
                }
              }}
            />
            <Text.Caption color="secondary">
              {t('widgets.editorHint', { className: suggestions[0]?.label || '.stigg-plan-offering-container' })}
            </Text.Caption>
          </MonacoWrapper>
        }
      />
    </Grid>
  );
}
