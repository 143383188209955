import React from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Toolbar, GridFlex } from '@stigg-components';
import { FeatureFlags } from '@stigg-types/featureFlags';
import isEmpty from 'lodash/isEmpty';
import { MenuAndBreadcrumbs, TasksProgress, usePollSubscriptionMigrationTasks } from './components';
import * as S from './Navbar.style';
import { useCurrentImportTask } from './components/tasks/useCurrentImportTask';
import { CommandBarLauncher } from './components/commandbar/CommandBarLauncher';
import { ThemeToggle } from '../../ThemeToggle';
import { RootState } from '../../../redux/store';

const TASKS_POLLING_INTERVAL_MS = 1000;

const Navbar = ({
  onDrawerToggle,
  isDrawerExpanded,
  drawerWidth,
  marginTop,
}: {
  isDrawerExpanded: boolean;
  drawerWidth: number;
  marginTop?: number;
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
}) => {
  const {
    migrationTaskPollingInterval,
    commandBar: commandBarEnabled,
    darkMode: isDarkModeEnabled,
  } = useFlags<FeatureFlags>();
  const subscriptionMigrationTasks = usePollSubscriptionMigrationTasks(
    migrationTaskPollingInterval || TASKS_POLLING_INTERVAL_MS,
  );
  const inProgressImportTask = useCurrentImportTask();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);

  return (
    <S.AppBar $drawerWidth={drawerWidth} $isDrawerExpanded={isDrawerExpanded} $top={marginTop}>
      <Toolbar>
        <GridFlex.RowSpaceBetween container>
          <MenuAndBreadcrumbs onDrawerToggle={onDrawerToggle} isDrawerExpanded={isDrawerExpanded} />
          <GridFlex.RowCenter mr={8} gap={2} alignItems="stretch" minHeight={40}>
            <GridFlex.Item>
              {(!isEmpty(subscriptionMigrationTasks) || inProgressImportTask) && (
                <TasksProgress
                  subscriptionMigrationTasks={subscriptionMigrationTasks}
                  importTask={inProgressImportTask}
                />
              )}
            </GridFlex.Item>
            {commandBarEnabled && !!currentEnvironmentId ? (
              <GridFlex.Item>
                <CommandBarLauncher />
              </GridFlex.Item>
            ) : null}

            {isDarkModeEnabled ? (
              <GridFlex.Item display="flex">
                <ThemeToggle />
              </GridFlex.Item>
            ) : null}
          </GridFlex.RowCenter>
        </GridFlex.RowSpaceBetween>
      </Toolbar>
    </S.AppBar>
  );
};

export default Navbar;
