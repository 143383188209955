import React from 'react';
import { t } from 'i18next';
import { FieldArray } from 'formik';
import { CouponType, Currency } from '@stigg-types/apiTypes';
import {
  Button,
  drawFormFields,
  Field,
  FormRenderProps,
  GridFlex,
  HighlightText,
  Icon,
  IconButton,
  OutlinedFormFieldLabel,
  SearchableSelectOption,
  Text,
} from '@stigg-components';
import { getCurrencySymbol } from '../../../packages/pricing/components/currency/currencyUtils';
import { CreateCouponFormFields } from './CreateCouponForm';

const getCurrencyOptions = (existingCurrencies: Currency[]): SearchableSelectOption[] =>
  Object.values(Currency).map((currency) => ({
    id: currency,
    render: (search?: string) => (
      <Text.B2>
        <HighlightText text={currency} highlight={search} />
      </Text.B2>
    ),
    isDisabled: existingCurrencies.includes(currency),
    isInSearch: (search) => currency.toLowerCase().includes(search.toLowerCase()),
  }));

const MAX_OPTIONS_TO_SHOW = 20;

type CouponCurrenciesProps = {
  values: CreateCouponFormFields;
  formRenderProps: FormRenderProps<CreateCouponFormFields>;
};

export function CouponDiscounts({ values, formRenderProps }: CouponCurrenciesProps) {
  const existingCurrencies = values.amountsOff?.map((amountOff) => amountOff.currency) || [];

  return (
    <FieldArray name="amountsOff">
      {({ push, remove }) => {
        const isFixedCoupon = values.type === CouponType.Fixed;
        const amountOffFields: Field<CreateCouponFormFields>[] =
          values.amountsOff?.map((amountOff, index) => {
            return {
              type: 'layout',
              contentGridProps: { gap: 2 },
              fields: [
                {
                  id: `amountsOff[${index}].amount`,
                  type: 'text',
                  textFieldType: 'number',
                  placeholder: '0',
                  endAdornment: isFixedCoupon ? undefined : '%',
                  startAdornment:
                    isFixedCoupon && !!amountOff.currency ? getCurrencySymbol(amountOff.currency) : undefined,
                  isNumberWithoutFraction: true,
                  isNumberWithoutSigns: true,
                  autoFocus: isFixedCoupon,
                },
                {
                  type: 'layout',
                  hide: () => !isFixedCoupon,
                  fields: [
                    {
                      type: 'searchableSelect',
                      id: `amountsOff[${index}].currency`,
                      disable: index === 0,
                      placeholder: t('coupons.selectCurrency'),
                      maxOptionsToShow: MAX_OPTIONS_TO_SHOW,
                      options: getCurrencyOptions(existingCurrencies),
                      dataTestId: 'currency-select',
                    },
                    {
                      gridProps: { width: '40px', flex: 'unset' },
                      type: 'custom',
                      render: () =>
                        index === 0 ? null : (
                          <IconButton onClick={() => remove(index)}>
                            <Icon icon="Close" color="active" />
                          </IconButton>
                        ),
                    },
                  ],
                },
              ],
            };
          }) || [];

        return (
          <GridFlex.Column $fullWidth>
            <OutlinedFormFieldLabel
              label={isFixedCoupon ? t('coupons.discountLabel.fixed') : t('coupons.discountLabel.percentage')}
            />

            <GridFlex.Column gap={2} mb={2}>
              {drawFormFields(amountOffFields, formRenderProps)}
            </GridFlex.Column>

            {isFixedCoupon ? (
              <Button
                style={{ alignSelf: 'flex-start' }}
                onClick={() => push({})}
                variant="text"
                startIcon={<Icon icon="Plus" size={20} />}>
                {t('coupons.addCurrency')}
              </Button>
            ) : null}
          </GridFlex.Column>
        );
      }}
    </FieldArray>
  );
}
