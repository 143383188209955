import React, { useEffect, useState } from 'react';
import delay from 'lodash/delay';
import { useTheme } from '@mui/material/styles';
import { Text, IconButton, Icon, InformationTooltip } from '@stigg-components';
import * as S from './Sidebar.style';
import { SidebarItemsType } from '../../../types/sidebar';
import SidebarNav from './SidebarNav';
import { useNavigation } from '../../../modules/navigation/useNavigation';
import { EnvironmentSwitch } from '../../../modules/accounts/components/environments/EnvironmentSwitch';
import { SidebarFooter } from './SidebarFooter';

export type SidebarProps = {
  marginTop?: number;
  toggleSidebar?: () => void;
  variant?: 'permanent' | 'persistent' | 'temporary';
  open: boolean;
  onClose?: () => void;
  items: {
    pages: SidebarItemsType[];
  }[];
};

const Sidebar: React.FC<SidebarProps> = ({ items, toggleSidebar, marginTop, ...rest }) => {
  const navigation = useNavigation();
  const theme = useTheme();
  const paperProps = {
    style: {
      borderRight: 'none',
      marginTop: marginTop ?? 0,
      height: `calc(100% - ${marginTop}px)`,
    },
  };

  const [openDelayed, setOpenDelayed] = useState(rest.open);

  useEffect(() => {
    const timerId = delay(
      () => {
        setOpenDelayed(rest.open);
      },
      rest.open ? 0 : 500,
    );

    return () => clearTimeout(timerId);
  }, [rest.open, theme]);

  const iconColor = theme.isLightTheme ? 'active' : 'primary.main';

  return (
    <S.Drawer
      anchor="left"
      variant={rest.variant}
      $openDelayed={openDelayed}
      $disableExpand={!toggleSidebar}
      open={rest.open}
      onClose={rest.onClose}
      PaperProps={paperProps}>
      <S.Brand>
        <S.BrandIcon
          className="brand-icon"
          $open={rest.open}
          $sidebar
          onClick={() => navigation.navigateTo('/')}
          data-testid="navbar-stigglogo"
        />
        {toggleSidebar && !openDelayed && (
          <InformationTooltip arrow placement="right" title={<Text.B2>Show navigation</Text.B2>} $padding={2}>
            <IconButton
              sx={{ borderRadius: '10px', position: 'absolute', ml: -1 }}
              className="chevron-icon-button"
              color="faded"
              onClick={() => toggleSidebar && toggleSidebar()}>
              <Icon type="reactFeather" icon="ChevronsRight" color={iconColor} />
            </IconButton>
          </InformationTooltip>
        )}
        {toggleSidebar && rest.open ? (
          <InformationTooltip arrow placement="right" title={<Text.B2>Hide navigation</Text.B2>} $padding={2}>
            <S.IconButtonFaded sx={{ borderRadius: '10px' }} onClick={() => toggleSidebar && toggleSidebar()}>
              <Icon type="reactFeather" icon="ChevronsLeft" color={iconColor} />
            </S.IconButtonFaded>
          </InformationTooltip>
        ) : null}
      </S.Brand>
      <EnvironmentSwitch isSidebarOpen={rest.open} />
      <SidebarNav items={items} isSidebarOpen={rest.open} />
      <SidebarFooter isSidebarOpen={rest.open} />
    </S.Drawer>
  );
};

export default Sidebar;
